<template>
	<!-- 添加弹窗 -->
	
		<!-- 添加订单预约的表单 -->
		<div class="check_main" v-if="isCheckMain">
			<div class="CheckIn_color">
				<!-- 标题部分 -->
				<!-- <div class="order_title">
					<p>订单预定</p>
					<div class="title_icon">
						<i class="el-icon-close" style="color: black; font-size: 25px"> </i>
					</div>
				</div> -->
				<!-- 标题部分 -->

				<!-- 订单信息表单 -->
				<div class="residentMessage">
					<!-- 标题部分 -->
					<div class="resident_title">
						<p><span></span>订单信息</p>
					</div>
					<!-- 标题部分 -->

					<!-- 订单信息表单 -->
					<el-form :inline="true" :model="ruleForm" :rules="rules"
           ref="ruleForms" label-width="90" class="demo-ruleForm">
					
								<el-form-item 
                label-width="110px"
                style="position: relative"  label="预定客人" prop="customerName">
									<el-input clearable @change="nameChange" style="  
              width: 120px;"  v-model.trim="ruleForm.customerName" placeholder="请输入姓名"></el-input>
								</el-form-item>
							
								<el-form-item   label-width="110px" style="position: relative"  label="手机号码" prop="customerMobile">
									<el-input
                  placeholder="请输入手机号"
                  clearable
                  
                    @change="mobileInput"
                    style=" width: 120px;"
										v-model.trim="ruleForm.customerMobile"></el-input>
								</el-form-item>
						
								<el-form-item  label-width="90px" style="position: relative" label="外部单号" prop="outerNum">
									<el-input 
                  clearable
                  style=" width: 180px;"
                  placeholder="请输入外部单号"
                  v-model.trim="ruleForm.outerNum"></el-input>
								</el-form-item>
						
								<el-form-item label="客户类型"  label-width="90px" prop="clientType">
									<el-select   @change='clientTypeChange'
                  style="width: 100px;"
                  clearable
                  v-model="ruleForm.clientType" placeholder="请选择客户类型">
										<el-option disabled label="会员" value="1"></el-option>
										<el-option  label="散客" value="0"></el-option>
										<el-option disabled label="协议" value="2"></el-option>
                    <el-option label="OTA" value="3"></el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="OTA名称" v-if="ruleForm.clientType=='3'" label-width="90px" prop="clientType">
									<el-select  style="width: 100px;" clearable v-model="ruleForm.otaName" placeholder="请选择OTA">
										<el-option label="美团" value="美团"></el-option>
										<el-option label="携程" value="携程"></el-option>
										<el-option label="飞猪" value="飞猪"></el-option>
										<el-option label="抖音" value="抖音"></el-option>
									</el-select>
								</el-form-item>
								<el-form-item  label-width="110px" label="入住类型" prop="occupancyType">
									<el-select clearable style=" width: 110px;"  @change='housetypechange' v-model="ruleForm.occupancyType">
										<el-option label="全天房" value="1"></el-option>
										<el-option label="钟点房" value="0"></el-option>
										<el-option label="自用房" value="2"></el-option>
										<el-option label="免费房" value="3"></el-option>
									</el-select>
								</el-form-item>
						
								<el-form-item  label-width="120px" style="position: relative"  label="预抵时间" prop="preIntoTime">
									<el-date-picker style="width: 140px;"  value-format="yyyy-MM-dd"
										v-model="ruleForm.preIntoTime" type="date" placeholder="选择日期"
                    :picker-options="pickerOptions"
										@blur="preLeaveDate" @change="startData" >
									</el-date-picker>
								</el-form-item>
						
								<el-form-item  label-width="120px" label="预离时间" prop="preLeaveTime"  >
									<el-date-picker style=" width: 140px;"  value-format="yyyy-MM-dd"
										v-model="ruleForm.preLeaveTime" type="date" @blur="preLeaveDate"
										placeholder="选择日期" @change="LeaveDate(ruleForm.preLeaveTime)" :disabled='ruleForm.occupancyType=="0"'>
									</el-date-picker>
								</el-form-item>
						
								<el-form-item  label-width="100px" label="预住天数" prop="date2" v-if='ruleForm.occupancyType=="1"'>
									<el-input 
                  clearable
                  style="width: 80px; "
                  @change="ChangereserveDays(daynum)"
                   v-model="daynum"></el-input>
								</el-form-item>
								
								<el-form-item  label-width="100px" label="钟点时长" prop="date2"  v-if='ruleForm.occupancyType=="0"'>
									<el-input clearable  style=" width: 80px" @input="houseinput" v-model="hourLength"></el-input>
								</el-form-item>
                <el-form-item label-width="110px"  style="position: relative" label="早餐份数"    prop="breakfastNum">
									<el-input clearable style="width: 90px; 
              "  v-model="ruleForm.breakfastNum"></el-input>
								</el-form-item>
								<el-form-item label-width="100px" style="position: relative"  label="会员等级" prop="name">
									<el-input
                  clearable
                  style="width: 120px"
                  :disabled="true" 
										v-model="member.memberGradeName" ></el-input>
								</el-form-item>
               
								<el-form-item label-width="100px" label="会员号"  prop="date2">
									<el-input clearable style="width: 140px" :disabled="true" 
										v-model="member.number"></el-input>
								</el-form-item>
						
								<el-form-item label-width="110px" label="协议单位"  prop="protocolUnitId">
									<el-select 
                  style="width: 150px;"
                  v-model="ruleForm.protocolUnitId"
                  placeholder="协议单位"
                  clearable @change="agreementchange(ruleForm.protocolUnitId)">
										<el-option v-for="item in BargainingUnitList" :key="item.id" :label="item.name"
											:value="item.id"></el-option>
									</el-select>
									<!-- <img style="position: absolute; right: -40px; top: 10px"
										src="../../../assets/icons/编辑.png" alt="" /> -->
								</el-form-item>
						
								<el-form-item label-width="110px"  label="房价码"   prop="housePriceName">
																<!-- <el-input
							  style="font-size: 12px"
							  v-model="ruleForm.housePriceId"
							  :disabled="true"
							></el-input> -->
									<el-select 
                  clearable
                  style="width: 465px;"
                  v-model="ruleForm.housePriceName"   :disabled="true" placeholder="请选择房价码">
										<el-option v-for="item in userinfo.housePriceCodes" :key="item.id"
																		:label="item.name" :value="item.name">
										</el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="预订到店" label-width="110px"    prop="date2">
									<!-- <el-date-picker style=" width: 280px;font-size: 12px;"  value-format="yyyy-MM-dd HH:mm:ss"
										v-model="ruleForm.reserveTime" clearable type="datetime" format="yyyy-MM-dd HH:mm:ss"
										placeholder="选择日期"  >
									</el-date-picker> -->
									<div style="display: flex;align-items: center;">
										<div>{{ruleForm.reserveTime}}</div>
										<el-time-picker
											v-model="time1"
											format="HH:mm:ss" value-format="HH:mm:ss"
											style="width: 175px;margin-left:10px;"
											:picker-options="{
											  selectableRange: '00:00:00 - 23:59:59'
											}"
											placeholder="任意时间点">
										</el-time-picker>
									</div>
								</el-form-item>
                  <el-form-item label-width="110px" label="备注信息"  prop="remark">
									<el-input
                  clearable
                  style="width: 840px;"
                  type="textarea" :rows="2" placeholder="请输入内容" maxlength="200"
										show-word-limit v-model="ruleForm.remark">
									</el-input>
								</el-form-item>
							
							
					</el-form>
					<!-- 客户信息表单 -->
				</div>
			</div>
			<!-- 住客信息表单 -->

			<!-- 房单信息表单 -->
			<div class="checkMessage">
				<!-- 标题 -->
				<div class="checkTitle">
					<p>
						<span></span>
						房间信息
					</p>
				</div>
				<!-- 标题 -->

				<div class="checkMessage_main">
					<!-- 入住信息表单 -->
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
						<el-row>
							<el-col :span="22" :offset="1">
								<el-table
                :cell-style='cellStyle' :data="tabledata"
									:header-row-style="{ backgroundColor: '#f2f2f2' }" border style="width: 100%">
									<el-table-column align="center" prop="houseType" label="房型">
									</el-table-column>
									<el-table-column align="center" prop="gatePrice" label="门市价">
									</el-table-column>
									<el-table-column align="center" prop="totalRoomNum" label="总间数">
									</el-table-column>
									<el-table-column align="center" prop="preorderableRoomNum" label="可订间数">
									</el-table-column>
									<el-table-column align="center" prop="num" :render-header="addRedStar" label="预定间数">
										<template slot-scope="scope">
											<input class="table-input"
                      style="border: 1px solid rgb(219, 216, 216)"
                      @change="oneRoomInput(scope.row)"
                      v-model="scope.row.num"
											></input>
										</template>
									</el-table-column>
									<el-table-column align="center" prop="realPrice" :render-header="addRedStar" color:red label="实际房价">
										<template slot-scope="scope">
											<input type="number"
                      style="border: 1px solid rgb(219, 216, 216)"
                      @change="oneRoomInput(scope.row)"
                      class="table-input" v-model="scope.row.realPrice"></input>
										</template>
									</el-table-column>
									<el-table-column align="center" label="操作" fixed="right" >
										<template slot-scope="scope">
											<el-button 
                      v-if="roomArrActive.includes('wineshop:orderReserve:getCanReserveRoom')"
                      type="primary" @click="rowHouse(scope.row)" size='mini' plain>排房</el-button>
											
										</template>
									</el-table-column>
								</el-table>
							</el-col>
						</el-row>
					</el-form>
					<!-- 入住信息表单 -->
				</div>
			</div>
			<!-- 入住信息表单 -->

			<div class="submit_button" >
				<el-button @click="addRoom('ruleForm')" style="background-color: #5cb5b8; color: #fff">办理预定</el-button>
			</div>
      	<!-- 添加订单预约的表单 -->
		<el-dialog title="排房操作" 
    append-to-body
    :visible.sync="emptyshow" 
     width="600px" class="emptyroom">
			<emptyroom ref="emptyroombox" @closemodel='closemodel' @getroomdata='getroomdata'></emptyroom>
		</el-dialog>
		</div>
	

</template>

<script>
import {
  advanceOrderList,
  advanceOrder,
  AddmayAdvance,
  houseState,
  BargainingUnitAll,
  advanceOrderHoutyType,
} from "_api/advanceOrder";
import { roomStatusView } from "_api/housingSystem";
import { userInfoGet } from "_api/orderFrom";
import emptyroom from "./emptyroom.vue";
export default {
  name: "moreFurther",
  components: {
    emptyroom,
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          //此条为设置禁止用户选择今天之前的日期，包含今天。
          // return time.getTime() <= (Date.now());
          //此条为设置禁止用户选择今天之前的日期，不包含今天。
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
        //如果不生效，把方法格式改为
        //disabledDate:function disabledDate(time) {
        //return time.getTime() < (Date.now()-(24 * 60 * 60 * 1000));
        //}
      },
      ruleForm: {
        breakfastNum: "0", //早餐份数integer(int32)
        clientType: "0", //客户类型 0散户 1会员 2协议integer(int32)
		otaName:undefined,
        customerMobile: null, //客户手机号
        customerName: null, //客户姓名
        housePriceId: null, //房价码IDinteger(int32)
        occupancyType: "1", //入住类型（0 钟点房 1全天房）integer(int32)
        orderStatus: "", //订单状态integer(int32)
        preIntoTime: "", //预计住店时间
        preLeaveTime: "", //预计离店时间
        protocolUnitId: "", //协议单位编号
        protocolUnitName: "", //协议单位名称
        remark: "", //备注
        reserveDeposits: [], //预订单定金明细
        reserveTime: undefined, //预定日期
        rowHouses: [], //预订单排房记录
        state: true,
      },
      mobileNumber: 0,
      daynum: 1,
      tableRoules: [], //排过的排房信息
      member: {
        memberGradeName: "",
        number: "",
      },
      row: [],
      rowHouseList: [],
      houseTypeRoom: {}, //点击排房获取信息
      rules: {
        customerMobile: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern:
              /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        customerName: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          {
            pattern:
              /^(([a-zA-Z+\.?\·?a-zA-Z+]{2,30}$)|([\u4e00-\u9fa5+\·?\u4e00-\u9fa5+]{2,30}$))/,
            message: "请输入正确的姓名",
            trigger: "blur",
          },
        ],
        preIntoTime: [
          { required: true, message: "请输入预抵时间", trigger: "blur" },
        ],
        preLeaveTime: [
          { required: true, message: "请输入预离时间", trigger: "blur" },
        ],
      },
      isCheckMain: true, // 控制并办理入住弹窗的显示
      isReconfirm: false, //控制再次确认弹窗的显示
      tabledata: [],
      BargainingUnitList: [], //所有协议单位
      emptyshow: false,
      roominfo: [], //选中房间数据
      roomArrActive: localStorage.getItem("permission")
        ? localStorage.getItem("permission")
        : [], //功能权限数据
      userinfo: {
        memberGradeName: undefined, //会员等级名称
        number: undefined,
        housePriceCodes: [],
      },
      hourLength: 1, //钟点时长
      time1: "12:00:00", //后半段
	  lablewidth:'90px',
    };
  },
  watch: {
    inHotelDate: {
      handler(newVal, oldVal) {
        console.log(newVal);
      },
      deep: true,
    },
    ruleForm: {
      handler(newVal, oldVal) {
        console.log(newVal);
        this.ruleForm.reserveTime = this.ruleForm.preIntoTime;
        if (
          this.mouseenter(new Date(this.ruleForm.preIntoTime)) ==
          this.mouseenter(new Date())
        ) {
          this.time1 = this.newdatatime(new Date().getTime(), 2);
        }
      },
      deep: true,
    },
  },
  created() {
    // this.todaytime();
    // this.advanceOrdered();
    this.BargainingUnitAlls();
    // this.ruleForm.reserveTime = this.newdatatime(new Date().getTime())
    // console.log(this.mouseenter(new Date()));
	// var width = window.innerWidth;
	// if(width>=2560){
	// 	this.lablewidth='95px';
	// }else{
	// 	this.lablewidth='90px';
	// }
  },
  // mounted() {  
  //   window.addEventListener('resize', this.handleResize);  
  // },  
  // beforeDestroy() {  
  //   window.removeEventListener('resize', this.handleResize);  
  // },
  methods: {
    addRedStar(h, { column }) {
      return [
        h("span", { style: "color: red" }, "*"),
        h("span", " " + column.label),
      ];
    },
    //获取当前时间
    getNowDate(date) {
      var date1 = new Date(date);
      var sign2 = ":";
      var year = date1.getFullYear(); // 年
      var month = date1.getMonth() + 1; // 月
      var day = date1.getDate(); // 日
      // var hour = date1.getHours(); // 时
      // var minutes = date1.getMinutes(); // 分
      // var seconds = date1.getSeconds() //秒
      // 给一位数的数据前面加 “0”
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (day >= 0 && day <= 9) {
        day = "0" + day;
      }
      //    if (hour >= 0 && hour <= 9) {
      //     hour = "0" + hour;
      //     }
      //   if (minutes >= 0 && minutes <= 9) {
      //      minutes = "0" + minutes;
      //    }
      //   if (seconds >= 0 && seconds <= 9) {
      //      seconds = "0" + seconds;
      //  }

      this.ruleForm.preLeaveTime = year + "-" + month + "-" + day;
    },
    //预祝天数变化自动改变预离时间
    ChangereserveDays(data) {
      console.log(parseFloat(data));

      var preIntoTime = new Date(this.ruleForm.preIntoTime).getTime();
      preIntoTime = parseFloat(data) * (1 * 24 * 60 * 60 * 1000) + preIntoTime;
      this.getNowDate(preIntoTime);
      this.daynum = parseFloat(data) + "天";
    },
    //预抵日期有变化自动预离时间自动往后推一天
    startData() {
      console.log(
        Date.parse(this.ruleForm.preIntoTime),
        "------------------------------------"
      );
      if (
        new Date().getTime() >=
        new Date(this.ruleForm.preIntoTime).getTime() + 3600 * 1000 * 24
      ) {
        this.$message({
          type: "warning",
          message: "不可小于当前时间",
        });
        var dateTime = new Date();
      } else {
        var dateTime = new Date(this.ruleForm.preIntoTime);
      }
      console.log("xx", dateTime);
      this.ruleForm.preIntoTime = this.mouseenter(dateTime);
      dateTime = dateTime.setDate(dateTime.getDate() + 1);
      dateTime = new Date(dateTime);
      this.ruleForm.preLeaveTime = this.mouseenter(dateTime);
      this.daynum = 1;
    },

    //预离时间变动自动计算天数
    LeaveDate(val) {
      console.log(new Date(val).getTime());
      if (!this.ruleForm.preIntoTime) {
        this.$message({
          type: "warning",
          message: "请选择预抵时间",
        });
        return false;
      }
      if (
        new Date(val).getTime() < new Date(this.ruleForm.preIntoTime).getTime()
      ) {
        this.$message({
          type: "error",
          message: "预离时间必须大于预抵时间，且不能小于一天",
        });
        var dateTime = new Date(this.ruleForm.preIntoTime);
        dateTime = dateTime.setDate(dateTime.getDate() + 1);
        dateTime = new Date(dateTime);
        this.ruleForm.preLeaveTime = this.mouseenter(dateTime);
        return false;
      } else {
        if (
          new Date(this.ruleForm.preLeaveTime).getTime() -
            new Date(this.ruleForm.preIntoTime).getTime() <
          1000 * 60 * 60 * 24
        ) {
			console.log('时间记录',new Date(val).getTime()-new Date(this.ruleForm.preIntoTime).getTime())
          this.$message({
            type: "error",
            message: "预离时间必须大于预抵时间，且不能小于一天",
          });
          var dateTime = new Date(this.ruleForm.preIntoTime);
          dateTime = dateTime.setDate(dateTime.getDate() + 1);
          dateTime = new Date(dateTime);
          this.ruleForm.preLeaveTime = this.mouseenter(dateTime);
          return false;
        }
      }
      this.getDaysBetween(
        this.ruleForm.preIntoTime,
        this.ruleForm.preLeaveTime
      );
    },
    //现在目前只能排一个房型
    oneRoomInput(row) {
      row.num = parseInt(row.num);
      if (row.num < 0) {
        row.num = -row.num;
      }
      row.realPrice = parseFloat(parseFloat(row.realPrice).toFixed(2));
	  
      console.log("当前行", row);
      if (row.realPrice < 0) {
        row.realPrice = -row.realPrice;
      }
      if (Object.is(row.realPrice, NaN)) {
        row.realPrice = 0;
      }
      if (Object.is(row.num, NaN)) {
        row.num = 0;
      }
      if (row.preorderableRoomNum == 0) {
        this.$message({
          type: "error",
          message: "该房型已无房间",
        });
        this.advanceOrdered();
      } else {
        if (row.preorderableRoomNum < row.num) {
          this.$message({
            type: "error",
            message: "超过最大预定间数",
          });
          row.num = parseInt(row.preorderableRoomNum);
        }
        let a = 0;
        var item = [];
        this.tabledata.forEach((el) => {
          if (el.num != 0 || el.realPrice != 0) {
            a += 1;
            item.push(el);
          }
        });
        this.tableRoules = item;
        // if (a > 1) {
        //   this.$message({
        //     type: "error",
        //     message: "每个用户只能订一间房型",
        //   });
        // }

        // if(parseFloat(row.num) > 1) {
        //   this.tabledata.forEach(el => {
        //     if(el.houseTypeId === row.houseTypeId) {
        //       el.num = 1
        //       this.$message({
        //     type: 'error',
        //     message: '目前只能预定一间房间'
        //   })
        //     }
        //   })

        //   console.log(this.tabledata);
        // }
      }
    },
    //点击排房触发对应房型的排房事件
    rowHouse(row) {
      console.log(row);
      var a = 0;
      this.tabledata.forEach((el, index) => {
        if (el.num != 0 || el.realPrice != 0) {
          a += 1;
        }
      });
      var housetype = [];
      if (this.tableRoules.length) {
        this.tableRoules.forEach(function (value) {
          housetype.push(value.houseTypeId);
        });
      }
      console.log("选中房型", this.tableRoules);
      // if (a > 1) {
      //   this.$message({
      //     type: "error",
      //     message: "每个用户只能订一间房型",
      //   });
      // } else {
      let b = null;
      // this.tabledata.forEach((el) => {
      //   if (el.num != 0 || el.realPrice != 0) {
      //     // if(el.id)
      //     if (el.houseTypeId != row.houseTypeId) {
      //       b = 1;
      //       this.$message({
      //         type: "error",
      //         message: "你预定的房型和要排的房型不一致",
      //       });
      //     }
      //   }
      // });
      if (housetype.indexOf(row.houseTypeId) !== -1) {
        b = 1;
      }
      console.log(row);
      if (b == 1) {
        if (
          this.ruleForm.occupancyType == "3" ||
          this.ruleForm.occupancyType == "2"
        ) {
          if (row.preorderableRoomNum != 0 && row.num != 0) {
            this.rowHouseList = row;
            this.advanceOrderHoutyTypeed();
            this.emptyshow = true;
            this.housetypeid = row.houseTypeId;
            let arr = []
              this.roominfo.forEach(ic => {
                if(ic.houseTypeId==this.housetypeid){
                  arr.push(ic.id)
                }
              })
              this.$nextTick(() => {
                this.$refs.emptyroombox.houseindex.splice(0)
				this.$refs.emptyroombox.houseindex.push(...arr)
				this.$refs.emptyroombox.limit = parseFloat(this.rowHouseList.num);
				this.$refs.emptyroombox.dataorder();
              // this.$refs.emptyroombox.houseindex=arr;
              console.log(this.$refs.emptyroombox.houseindex);
            })
          } else {
            this.$message({
              type: "error",
              message: "请填写预定间数",
            });
          }
        } else {
          if (
            row.preorderableRoomNum != 0 &&
            row.num != 0 &&
            row.realPrice != 0
          ) {
            if (row.num && row.realPrice) {
              this.rowHouseList = row;
              this.advanceOrderHoutyTypeed();
              this.emptyshow = true;
              this.housetypeid = row.houseTypeId;
              let arr = []
              this.roominfo.forEach(ic => {
                if(ic.houseTypeId==this.housetypeid){
                  arr.push(ic.id)
                }
              })
              this.$nextTick(() => {
                this.$refs.emptyroombox.houseindex.splice(0)
                this.$refs.emptyroombox.houseindex.push(...arr);
				this.$refs.emptyroombox.limit = parseFloat(this.rowHouseList.num);
				this.$refs.emptyroombox.dataorder();
              console.log(this.$refs.emptyroombox.houseindex);
            })
            } else {
              this.$message({
                type: "error",
                message: "请填写实际房价和预定间数",
              });
            }
          } else {
            console.log("1122");
            this.$message({
              type: "error",
              message: "请填写实际房价和预定间数",
            });
          }
        }
      } else {
        console.log("3344");
        this.$message({
          type: "error",
          message: "请填写实际房价和预定间数",
        });
      }
      // }

      // console.log("数据", row);
    },

    //协议单位查看
    BargainingUnitAlls() {
      BargainingUnitAll().then((res) => {
        if (res.data.code === 0) {
          this.BargainingUnitList = res.data.data;
        } else {
          this.$message(res.data.errorMsg);
        }
      });
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      // console.log(row);
      if (row.preorderableRoomNum == 0) {
        return "background:#F5FAFA; color: '#4C4C4C'";
      }
    },
    //房态列表
    houseStateed() {
      var that = this;
      const params = {
        statusList: [1, 3],
        // statusList: [],
        houseTypeId: [this.rowHouseList.houseTypeId],
        orgId: localStorage.getItem("orgid"),

        clientList: [],
        codeName: undefined,
        floorId: [],
      };
      //houseState
      roomStatusView(params).then((res) => {
        if (res.data.code === 0) {
          // console.log('数据数据数据',that.$refs.emptyroombox)
          this.$refs.emptyroombox.houselist.splice(0);
		  var ids = [];
		  var roomArr = [];
		  if(res.data.data){
			  res.data.data.forEach((value)=>{
				if(ids.indexOf(value.id)==-1){
					roomArr.push(value);
					ids.push(value.id)
				}
			  });
		  }
          this.$refs.emptyroombox.houselist = roomArr;
		  this.$refs.emptyroombox.dataorder();
        }
      });
    },
    //根据房型id和开始结束时间查到指定房型可预订房间数据
    advanceOrderHoutyTypeed() {
      const params = {
        houseTypeId: this.rowHouseList.houseTypeId,
        endTime: this.ruleForm.preLeaveTime,
        startTime: this.ruleForm.preIntoTime,
      };
      console.log("限制数量", parseFloat(this.rowHouseList.num));
      advanceOrderHoutyType(params).then((res) => {
        if (res.data.code === 0) {
          this.$refs.emptyroombox.houselist.splice(0);
		  var ids = [];
		  var roomArr = [];
		  if(res.data.data){
		    res.data.data.forEach((value)=>{
				if(ids.indexOf(value.id)==-1){
					roomArr.push(value);
					ids.push(value.id)
				}
		  	});
		  }
          this.$refs.emptyroombox.houselist = roomArr;
          // this.$refs.emptyroombox.houseindex.splice(0);
          this.$refs.emptyroombox.limit = parseFloat(this.rowHouseList.num);
		  this.$refs.emptyroombox.dataorder();
        } else {
          this.$message(res.data.errorMsg);
        }
      });
    },
    //当时间 input 失去焦点时触发
    preLeaveDate() {
      if (this.ruleForm.occupancyType == "0") {
        this.ruleForm.preLeaveTime = this.ruleForm.preIntoTime;
      }
      this.advanceOrdered();
    },
    //获取可预定房间
    advanceOrdered() {
      console.log(this.row);
      const params = {
        endTime: this.ruleForm.preLeaveTime,
        startTime: this.ruleForm.preIntoTime,
        orgId: localStorage.getItem("orgid"),
      };
      var that = this;
      advanceOrder(params).then((res) => {
        if (res.data.code === 0) {
          // this.tabledata.splice(0);
          // res.data.data.forEach(function(value){
          // 	value={
          // 		...value,
          // 		num:0,//预定间数
          // 		realPrice:0,
          //     // preorderableRoomNum  可预订间数
          // 	};
          // 	that.tabledata.push(value);
          // });

          // console.log('数据', res.data.data)
          // console.log('数据', this.tabledata)
          //房态日历传过来的数据处理
		  console.log('传来的数据',this.row)
          if (this.row.length > 0) {
            console.log(this.row);
            this.tabledata.splice(0);
            res.data.data.forEach(function (value) {
              value = {
                ...value,
                num: 0, //预定间数
                realPrice: 0,
                // preorderableRoomNum  可预订间数
              };
              that.tabledata.push(value);
            });
            this.row.forEach((item) => {
              that.tabledata.forEach((el, index) => {
                if (el.houseTypeId == item.houseTypeId) {
                  console.log(item, el);
                  that.$set(that.tabledata, index, {
                    ...el,
                    num: parseFloat(el.num) + 1,
                    realPrice: el.gatePrice,
                  });
                }
              });
            });

            that.tabledata.forEach(function (value) {
              if (value.num > 0 && value.realPrice) {
                that.tableRoules.push(value);
              }
            });
            console.log(that.tabledata, "房态日历");
            that.roominfo.splice(0);
            this.row.forEach((el) => {
              that.roominfo.push({
                floorId: el.floorId,
                floorName: el.floorName,
                houseTypeId: el.houseTypeId,
                houseTypeName: el.houseTypeName,
                id: el.id,
                lockBytes: el.lockBytes,
                orgId: localStorage.getItem("orgid"),
                roomNumber: el.roomNumber,
                state: el.state,
                version: el.version,
              });
            });
            console.log(that.roominfo);
          } else {
            this.tabledata.splice(0);
            res.data.data.forEach(function (value) {
              value = {
                ...value,
                num: 0, //预定间数
                realPrice: 0,
                // preorderableRoomNum  可预订间数
              };
              that.tabledata.push(value);
            });
          }
        }
      });
    },
    //光标离开手机号框触发
    mobileInput() {
      console.log("sadada东方时代");
	  if(this.ruleForm.customerMobile){
	    // result = str.replace(/(^\s+)|(\s+$)/g,""); 去除字符串前后空格
	    this.ruleForm.customerMobile = this.ruleForm.customerMobile.replace(/\s/g,""); // 去除字符串全部空格
	  }
      if (this.ruleForm.customerMobile) {
        this.userInfoGeted();
      } else {
        if (!this.ruleForm.protocolUnitId) {
          this.member = "";
          this.userinfo = "";
          this.ruleForm.housePriceId = "";
          this.ruleForm.housePriceName = "";
          this.ruleForm.clientType = "0";
        } else {
          this.member = "";
          this.userinfo = "";
        }
      }
    },
	//名字修改
	nameChange(){
		if(this.ruleForm.customerName){
		  // result = str.replace(/(^\s+)|(\s+$)/g,""); 去除字符串前后空格
		  this.ruleForm.customerName = this.ruleForm.customerName.replace(/\s/g,""); // 去除字符串全部空格
		}
	},

    //根据手机号查询数据
    userInfoGeted() {
      const params = {
        mobile: this.ruleForm.customerMobile,
        // orgId: localStorage.getItem("orgid"),
      };
      var that = this;
      userInfoGet(params)
        .then((res) => {
          if (res.data.code == 0) {
            this.mobileNumber = 2;
            this.ruleForm.customerMobile = res.data.data.number;
			console.log('1111')
            this.member = res.data.data;
            that.userinfo = res.data.data;
            if (res.data.data && res.data.data.housePriceCodes) {
              if (res.data.data.housePriceCodes.length) {
                if (!that.ruleForm.protocolUnitId) {
                  that.ruleForm.housePriceId =
                    res.data.data.housePriceCodes[0].id;
                  that.ruleForm.housePriceName =
                    res.data.data.housePriceCodes[0].name;
                }
              }
            }
            if (res.data.data.housePriceCodes&&res.data.data.housePriceCodes.length == 0) {
              that.ruleForm.housePriceId = "";
              that.ruleForm.housePriceName = "";
            }
            if (this.ruleForm.protocolUnitId) {
              this.ruleForm.clientType = "2";
            } else {
              this.ruleForm.clientType = "1";
            }
			console.log('会员类型',this.ruleForm)
          }
        })
        .catch((info) => {
          if (this.mobileNumber != 2) {
            console.log("sdasdas");
            if (info.errorMsg.indexOf("黑名单") != -1) {
              this.$message({
                type: "error",
                message: info.errorMsg,
              });
            }
            // if(info.response){
            // 	if(info.response.data){

            // 	}
            // }
            if (info.errorMsg.indexOf("客户信息不存在") != -1) {
              this.member = {
                memberGradeName: undefined,
                number: undefined,
              };
              that.userinfo = {
                memberGradeName: undefined, //会员等级名称
                number: undefined,
                housePriceCodes: [],
              };
              that.ruleForm.housePriceId = undefined;
            }
            if (this.ruleForm.protocolUnitId) {
              this.ruleForm.clientType = "2";
            } else {
              this.ruleForm.clientType = "0";
            }
            if (!this.ruleForm.protocolUnitId) {
              this.ruleForm.protocolUnitName = "";
              this.ruleForm.housePriceId = "";
              this.ruleForm.housePriceName = "";
            }
          } else {
            this.mobileNumber = 0;
          }
        });
    },

    DetermineNumber() {
      var roomNums = 0;
      var item = [];
      var typename = "";
	  var typenameid=[];
      console.log("数据", this.tabledata);
      this.tabledata.forEach((el) => {
		if(this.ruleForm.occupancyType=='3'|| this.ruleForm.occupancyType == '2'){
			if (  el.num != 0 && el.preorderableRoomNum != 0) {
			  roomNums++;
			  item.push(el);
			}
			if (el.num != 0 && el.preorderableRoomNum == 0) {
			  if (typename == "") {
				if(typenameid.indexOf(el.houseTypeId)==-1){
					typenameid.push(el.houseTypeId);
					typename = el.houseType;
				}
			  } else {
				if(typenameid.indexOf(el.houseTypeId)==-1){
					typenameid.push(el.houseTypeId);
					typename = typename + "、" + el.houseType;
				}
			  }
			}
			if (
			  el.num != 0 &&
			  el.preorderableRoomNum < el.num
			) {
			  if (typename == "") {
				if(typenameid.indexOf(el.houseTypeId)==-1){
					typenameid.push(el.houseTypeId);
					typename = el.houseType;
				}
			  } else {
				if(typenameid.indexOf(el.houseTypeId)==-1){
					typenameid.push(el.houseTypeId);
					typename = typename + "、" + el.houseType;
				}
			  }
			}
		}else{
			if (el.realPrice != 0 && el.num != 0 && el.preorderableRoomNum != 0) {
			  roomNums++;
			  item.push(el);
			}
			if (el.realPrice != 0 && el.num != 0 && el.preorderableRoomNum == 0) {
			  if (typename == "") {
				if(typenameid.indexOf(el.houseTypeId)==-1){
					typenameid.push(el.houseTypeId);
					typename = el.houseType;
				}
			  } else {
				if(typenameid.indexOf(el.houseTypeId)==-1){
					typenameid.push(el.houseTypeId);
					typename = typename + "、" + el.houseType;
				}
			  }
			}
			if (
			  el.realPrice != 0 &&
			  el.num != 0 &&
			  el.preorderableRoomNum < el.num
			) {
			  if (typename == "") {
				if(typenameid.indexOf(el.houseTypeId)==-1){
					typenameid.push(el.houseTypeId);
					typename = el.houseType;
				}
			  } else {
				if(typenameid.indexOf(el.houseTypeId)==-1){
					typenameid.push(el.houseTypeId);
					typename = typename + "、" + el.houseType;
				}
			  }
			}
		}
      });
	  console.log('记录值',typename)
	  console.log('记录值',typenameid)
      if (typename != "") {
        this.$message({
          type: "error",
          message: "当前" + typename + "房型可预定间数不足",
        });
        return false;
      }
      if (roomNums < 1) {
		  if(this.ruleForm.occupancyType=='3'|| this.ruleForm.occupancyType == '2'){
			  this.$message({
			    type: "error",
			    message: "办理入住前请确定预定房型的预定间数",
			  }); 
		  }else{
			this.$message({
			  type: "error",
			  message: "办理入住前请确定预定房型的预定间数和实际房价",
			});  
		  }
        return false;
      }
      this.tableRoules = item;
	  console.log('提交前数据',this.tableRoules)
	  console.log("房间", this.roominfo);
	  // return false;
	  this.roomDetection();
      // this.AddmayAdvanceed();
      // console.log("检测数据1", this.tableRoules);
    },
	//检测房间数量是否够用
	async roomDetection(){
		const params = {
		  endTime: this.ruleForm.preLeaveTime,
		  startTime: this.ruleForm.preIntoTime,
		  orgId: localStorage.getItem("orgid"),
		};
		var that = this;
		var outTitle = "";
		var outRoom = "";
		var outHouseType = [];
		var res = await advanceOrder(params);
		if (res.data.code === 0) {
			this.tableRoules.forEach(item=>{
				if(item.houseTypeId){
					res.data.data.forEach((value)=>{
						if(value.houseTypeId == item.houseTypeId){
							if(item.num > value.preorderableRoomNum){
								if(outTitle==""){
									outTitle = value.houseType;
								}else{
									outTitle = outTitle +","+ value.houseType;
								}
							}else{
								outHouseType.push(item.houseTypeId);
							}
						}
					});
				}
			});
		}
		
		this.intermediaryRoomOptional(outHouseType).then((value) => {
		  // 异步操作成功的处理逻辑
		  console.log('结果IP',value);
		  outRoom = value;
		  if(outTitle!=''||outRoom!=''){
		  	var tiptitle = '';
		  	if(outTitle){
		  		tiptitle = outTitle+'房型的可预订数量不足'
		  	}
		  	if(outRoom){
		  		tiptitle= tiptitle==''?outRoom+'房间已经被预定':tiptitle+'，'+outRoom+'房间已经被预定';
		  	}
			tiptitle = tiptitle+',请重新排房'
			this.advanceOrdered();
		  	this.$message({
		  	  type: "error",
		  	  message: tiptitle,
		  	});
		  }else{
		  	//继续进行预约订单
		  	this.AddmayAdvanceed();
		  }
		}).catch((error) => {
		  // 异步操作失败的处理逻辑
		  console.log('失败IP',error)
		});
	},
	//检测房间中间过度函数
	async intermediaryRoomOptional(outHouseType) {
		var outRoom = '';
		if(outHouseType&&outHouseType.length){
			for (const value of outHouseType) {
				var str = await this.roomOptional(value);
				console.log(str);
				outRoom = outRoom==''?str:outRoom+','+str;
			} 
			// outHouseType.forEach(async value=>{
			// 	var str = await this.roomOptional(value);
			// 	console.log(str);
			// 	outRoom = outRoom==''?str:outRoom+','+str;
			// });
		}
		return outRoom;
	},
	//检测房间是否可选
	async roomOptional(houseTypeId){
		const params = {
		  houseTypeId: houseTypeId,
		  endTime: this.ruleForm.preLeaveTime,
		  startTime: this.ruleForm.preIntoTime,
		};
		var res = await advanceOrderHoutyType(params);
		if (res.data.code === 0) {
			var roomstr = '';
			this.roominfo.forEach(value=>{
				if(value.houseTypeId==houseTypeId){
					if(res.data.data&&res.data.data.length){
						var linstr = 0;
						res.data.data.forEach(item=>{
							if(value.id == item.id){
								linstr = 1;
							}
						});
						if(linstr!=1){
							roomstr = roomstr==''?value.roomNumber:roomstr +","+ value.roomNumber
						}
					}
				}
			});
			return roomstr;
		} else {
		    return '';
		}
	},
    //创建预订单
    AddmayAdvanceed() {
      console.log("房间", this.roominfo);
      console.log("房型数据", this.tableRoules);
      var that = this;
      var roominfo = [];
      var a = 0;
      var i = 0;
      this.tableRoules.forEach(function (value) {
        a = parseFloat(value.num);
        if (a >= 1) {
          for (i = 0; i < a; i++) {
            roominfo.push({
              houseTypeId: value.houseTypeId, //房型Id
              houseType: value.houseType, //房型
              orderReserveId: 0, //预订单ID
              orgId: localStorage.getItem("orgid"), //部门id
              realPrice: value.realPrice, //实际房价
              roomId: null,
              roomNumber: null,
              roomPrice: value.gatePrice,
              typeRoomNumber: parseFloat(value.num),
            });
          }
        }
      });
      if (this.roominfo.length) {
        var aa = 0;
        roominfo.forEach(function (value) {
			that.roominfo.forEach(function (item) {
              if (value.houseTypeId == item.houseTypeId) {
                aa = 0;
                roominfo.forEach(function (it) {
                  if (it.roomId == item.id) {
                    aa = 1;
                  }
                });
                if (aa != 1) {
                  if (!value.roomId && !value.roomNumber) {
                    value.roomId = item.id;
                    value.roomNumber = item.roomNumber;
                  }
                }
              }
            });
        });
      }
      var params = {
        outerNum: this.ruleForm.outerNum,
        // housePriceId: this.ruleForm.housePriceId,
        breakfastNum: Number(this.ruleForm.breakfastNum), //早餐份数integer(int32)
        clientType: Number(this.ruleForm.clientType), //客户类型 0散户 1会员 2协议integer(int32)
        otaName: this.ruleForm.otaName, //
        customerMobile: this.ruleForm.customerMobile, //客户手机号
        reserveMobile: this.ruleForm.customerMobile, //客户手机号
        customerName: this.ruleForm.customerName, //客户姓名
        housePriceId: this.ruleForm.housePriceId, //房价码IDinteger(int32)
        occupancyType: Number(this.ruleForm.occupancyType), //入住类型（0 钟点房 1全天房）integer(int32)
        hourLength:
          Number(this.ruleForm.occupancyType) != 1 ? this.hourLength : 0,
        orderStatus: 0, //订单状态integer(int32)（0 待入住 1 已入住 2 已取消）
        preIntoTime: this.ruleForm.preIntoTime, //预计住店时间
        preLeaveTime: this.ruleForm.preLeaveTime, //预计离店时间
        protocolUnitId: this.ruleForm.protocolUnitId, //协议单位编号
        protocolUnitName: this.ruleForm.protocolUnitName, //协议单位名称
        remark: this.ruleForm.remark, //备注
        reserveDeposits: [], //预订单定金明细
        reserveTime: this.ruleForm.reserveTime + " " + this.time1, //预定日期
        rowHouses: roominfo,
        state: true,
        reserveType: 0,
        orgId: localStorage.getItem("orgid"),
        version: 0,
        rowhouseStatus: 0,
        memberId: this.member.id ? this.member.id : undefined,
      };
      params.rowHouses.forEach(function (value) {
        if (value.roomId && value.roomNumber) {
          params.rowhouseStatus = 1;
        }
      });
	  // console.log('提交数据',params)
	  // return false;
      AddmayAdvance(params).then((res) => {
        if (res.data.code === 0) {
          this.$emit("offCheck", [false]);
          this.$message({
            type: "success",
            message: "新增预定单成功",
          });
        } else {
        }
      });
    },
    // customerMobile: [
    //       { required: true, message: "请输入手机号码", trigger: "blur" },
    //       {
    //         pattern:
    //           /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
    //         message: "请输入正确的手机号码",
    //         trigger: "blur",
    //       },
    //     ],
    //     customerName: [
    //       { required: true, message: "请输入姓名", trigger: "blur" },
    //       {
    //         pattern:
    //           /^(([a-zA-Z+\.?\·?a-zA-Z+]{2,30}$)|([\u4e00-\u9fa5+\·?\u4e00-\u9fa5+]{2,30}$))/,
    //         message: "请输入正确的姓名",
    //         trigger: "blur",
    //       },
    //     ],
    //办理新增预定事件
    addRoom(formName) {
      this.$refs.ruleForms.validate((valid) => {
        if (valid) {
          if (this.ruleForm.occupancyType == "1") {
            if (!this.daynum) {
              this.$message({
                type: "error",
                message: "请输入预住天数",
              });
            }
          } else if (this.ruleForm.occupancyType == "0") {
            if (!this.hourLength) {
              this.$message({
                type: "error",
                message: "请输入钟点时长",
              });
            }
          }
          this.DetermineNumber();
        } else {
          this.$message({
            type: "error",
            message: "请按要求输入预订人信息",
          });
          return false;
        }
      });
    },
    //点击确认触发事件
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          return false;
        }
      });
    },
    //点击阴影部分和图标、取消按钮隐藏弹窗，点击确认显示再次确认弹窗
    close_page(e) {
      console.log(e.target.className);
      console.log(e.target.nodeName);

      if (e.target.className === "el-icon-close") {
        this.$emit("offCheck", [false]);
      }
    },
    //关闭排房弹窗
    closemodel(value) {
      console.log("展示", value);
      this.emptyshow = value;
    },
    //传输排放数据
    getroomdata(data) {
      console.log(data);
      var housetype = [];
      if(this.row) {
        this.roominfo.forEach((el,index) => {
          data.forEach(it => {
            if(it.houseTypeId == el.houseTypeId) {
              this.roominfo.splice(index,1)
            }
          })
        })
        this.roominfo.push(...data)
        this.emptyshow = false;
      }else{
        
      this.tableRoules.forEach(function (value) {
        housetype.push(value.houseTypeId);
      });
      
      this.emptyshow = false;
      if (data.length >0) {
        data.forEach(function (value) {
          if (housetype.indexOf(value.houseTypeId) !== -1) {
            this.roominfo.push(value);
          }
        });
      }
      }
    
      console.log('选中数据',this.roominfo);
    },
    //计算时间差
    //date1  开始日期 yyyy-MM-dd
    //date2  结束日期 yyyy-MM-dd
    //如果日期相同 返回一天 开始日期大于结束日期，返回0
    getDaysBetween(date1, date2) {
      var startDate = Date.parse(date1);
      var endDate = Date.parse(date2);

      var days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000);
      return (this.daynum = days + "天");
    },
    mouseenter(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + m + "-" + d;
    },
    newdatatime(date, type = 1) {
      var date1 = new Date(date);
      var sign2 = ":";
      var year = date1.getFullYear(); // 年
      var month = date1.getMonth() + 1; // 月
      var day = date1.getDate(); // 日
      var hour = date1.getHours(); // 时
      var minutes = date1.getMinutes(); // 分
      var seconds = date1.getSeconds(); //秒
      // 给一位数的数据前面加 “0”
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (day >= 0 && day <= 9) {
        day = "0" + day;
      }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }
      if (type == 1) {
        return (
          year +
          "-" +
          month +
          "-" +
          day +
          " " +
          hour +
          ":" +
          minutes +
          ":" +
          seconds
        );
      } else {
        return hour + ":" + minutes + ":" + seconds;
      }
    },
    todaytime() {
      // let newDate = new Date().getTime() + (1 * 24 * 60 * 60 * 1000)
      let newDate = new Date().getTime();
      this.ruleForm.preIntoTime = this.mouseenter(new Date(newDate));
      var dateTime = new Date();
      dateTime = dateTime.setDate(dateTime.getDate() + 1);
      dateTime = new Date(dateTime);
      this.ruleForm.preLeaveTime = this.mouseenter(dateTime);
      this.daynum = "1天";
      this.advanceOrdered();
    },
    // counttime(){
    // 	console.log('触发')
    // 	this.daynum=this.getDaysBetween(this.ruleForm.preIntoTime,this.ruleForm.preLeaveTime);
    // 	if(this.daynum==0){
    // 		this.$message({
    // 			message: '请重新选择预离时间',
    // 			type: 'warning'
    // 		});
    // 		this.ruleForm.preLeaveTime=undefined;
    // 	}
    // },
    housetypechange() {
      console.log("shijan", this.ruleForm.occupancyType);
      if (this.ruleForm.occupancyType == "0") {
        var dateTime = new Date();
        dateTime = this.mouseenter(dateTime);
        console.log("时间", dateTime);
        this.ruleForm.preLeaveTime = dateTime;
        this.ruleForm.preIntoTime = dateTime;
      }
    },
    //协议变化
    agreementchange(id) {
      if (this.ruleForm.protocolUnitId) {
        this.ruleForm.clientType = "2";
      } else {
        if (this.member.number) {
          this.ruleForm.clientType = "1";
        } else {
          this.ruleForm.clientType = "0";
        }
      }

      if (!id) {
        this.mobileInput();
      } else {
        this.BargainingUnitList.forEach((el) => {
          if (el.id == id) {
            this.ruleForm.protocolUnitName = el.name;
            this.ruleForm.realPrice = el.weekendPrice;
            this.ruleForm.housePriceId = el.housePriceCodeList
              ? el.housePriceCodeList[0].id
              : "";
            this.ruleForm.housePriceName = el.housePriceCodeName;
          }
        });
      }
    },
    //钟点时间
    houseinput() {
      setTimeout(() => {
        if (this.hourLength) {
          this.hourLength = parseFloat(this.hourLength);
          this.hourLength = Math.abs(this.hourLength);
        } else {
          this.hourLength = undefined;
        }
      }, 1000);
    },
	//屏幕分辨率
	handleResize() {
		  console.log('宽',window.innerWidth);
		  console.log('高',window.innerHeight);
		  var width = window.innerWidth;
		  if(width>=2560){
			  this.lablewidth='95px';
		  }else{
			  this.lablewidth='90px';
		  }
	},
	//客户类型切换
	clientTypeChange(){
		console.log('客户类型更改',this.ruleForm.clientType)
		this.ruleForm.otaName='';
	},
  },
};
</script>

<style lang="scss" scoped>
//办理去住弹窗css
.check_main {
  width: 100%;
  // height: 760px;
  background-color: #fff;
  display: flex;
  // overflow-y: auto;
  flex-direction: column;
  padding-bottom: 15px;
  // padding-right: 15px;
  box-sizing: border-box;
  align-items: center;

  .CheckIn_color {
    width: 100%;
    // background: linear-gradient(177deg, #e8fdfe 0%, #ffffff 100%);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;

    // 标题部分css
    // .order_title {
    //   display: flex;
    //   justify-content: space-between;
    //   align-items: center;
    //   width: 100%;
    //   height: 50px;
    //   border-bottom: 1px solid #ededed;

    //   .title_icon {
    //     width: 50px;
    //     height: 50px;
    //     text-align: center;
    //     line-height: 50px;
    //   }

    //   p {
    //     text-align: left;
    //     color: black;
    //     padding-left: 30px;
    //     box-sizing: border-box;
    //     flex: 1;
    //   }
    // }

    //住客信息表单css
    .residentMessage {
      width: 95%;
      height: 260px;
      margin-top: 10px;
      border-radius: 8px;
      border: 1px solid #ededed;

      //标题css
      .resident_title {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;

        p {
          display: flex;
          align-items: center;

          span {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #5cb5b8;
            margin-right: 5px;
          }
        }
      }

      //用户信息表单css
      .demo-ruleForm {
        padding-left: 10px;
        box-sizing: border-box;
        width: 100%;
        max-height: 200px;
        overflow-y: auto;
        .el-form-item {
          margin-bottom: 15px;
          ::v-deep .el-input__inner {
            height: 35px;
          }
        }
        // .el-form-item:nth-child(1) {
        //   ::v-deep .el-input__inner{
        //     height: 35px;
        //     font-size: 12px;
        //     width: 140px;
        //   }
        // }
        // .el-form-item:nth-child(2) {
        //   ::v-deep .el-input__inner{
        //     height: 35px;
        //     font-size: 12px; width: 180px;
        //   }
        // }
        // .el-form-item:nth-child(3) {
        //   ::v-deep .el-input__inner{
        //     height: 35px;
        //     font-size: 12px; width: 180px;
        //   }
        // }
        // .el-form-item:nth-child(4) {
        //   ::v-deep .el-input__inner{
        //     height: 35px;
        //     width: 130px
        //   }
        // }
        // .el-form-item:nth-child(5) {
        //   ::v-deep .el-input__inner{
        //     height: 35px;
        //     width: 90px
        //   }
        // }
        // .el-form-item:nth-child(6) {
        //   // width: 170px;
        //   ::v-deep .el-date-editor.el-input, .el-date-editor.el-input__inner{
        //     height: 35px;
        //     width: 160px;
        //   }
        // }
        // .el-form-item:nth-child(7) {
        //   // width: 170px;
        //   ::v-deep .el-date-editor.el-input, .el-date-editor.el-input__inner{
        //     height: 35px;
        //     width: 160px;
        //   }
        // }
        // .el-form-item:nth-child(8) {
        //   ::v-deep .el-input__inner{
        //     height: 35px;
        //     width: 70px; font-size: 12px
        //   }
        // }
        // .el-form-item:nth-child(9) {
        //   ::v-deep .el-input__inner{
        //     height: 35px;
        //     font-size: 12px; width: 70px
        //   }
        // }
        // .el-form-item:nth-child(10) {
        //   ::v-deep .el-input__inner{
        //     height: 35px;
        //     width: 150px;
        //     font-size: 12px;
        //   }
        // }

        // .el-form-item:nth-child(11) {
        //   ::v-deep .el-input__inner{
        //     height: 35px;
        //     font-size: 12px;width: 170px
        //   }
        // }
        // .el-form-item:nth-child(12) {
        //   ::v-deep .el-input__inner{
        //     height: 35px;
        //     font-size: 12px;  width: 160px
        //   }
        // }
        // .el-form-item:nth-child(13) {
        //   ::v-deep .el-input__inner{
        //     height: 35px;
        //     width: 160px
        //   }
        // }
        // .el-form-item:nth-child(14) {
        //   ::v-deep .el-input__inner{
        //     height: 35px;
        //     width: 259px
        //   }
        // }
      }
    }
  }

  //入住信息表单css
  .checkMessage {
    width: 95%;
    height: 300px;
    margin-top: 20px;
    border-radius: 8px;
    padding-right: 10px;
    box-sizing: border-box;
    border: 1px solid #ededed;
  }

  //标题css
  .checkTitle {
    width: 100%;
    height: 50px;
    line-height: 50px;
    padding-left: 20px;
    box-sizing: border-box;

    p {
      display: flex;
      align-items: center;

      span {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #5cb5b8;
        margin-right: 5px;
      }
    }
  }

  //表单css
  .checkMessage_main {
    height: 250px;
    padding-bottom: 20px;
    overflow-y: auto;
    box-sizing: border-box;

    //备注框css
    .remark {
      width: 100%;
      padding-right: 10px;
      padding-left: 30px;
      padding-bottom: 20px;
      box-sizing: border-box;
      display: flex;

      .remark_title {
        width: 80px;
        height: 35px;
        font-size: 15px;
        box-sizing: border-box;
      }

      .remark_main {
        flex: 1;
        height: 80px;
        border: 1px solid #ededed;
        border-radius: 10px;
        color: gray;

        span {
          display: block;
          margin-top: 10px;
          margin-left: 20px;
        }

        input {
          width: 99%;
          height: 30px;
          border: none;
        }
      }

      button {
        color: #5cb5b8;
        width: 80px;
        height: 25px;
        margin-top: 10px;
        border: 1px dotted #5cb5b8;
        background-color: #fff;
      }
    }
  }

  .submit_button {
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding-top: 20px;
    padding-right: 40px;
    box-sizing: border-box;
    text-align: right;
    .el-button {
      width: 100px;
      height: 35px;
      border-radius: 4px;
      padding: 0px 0px;
    }
  }
}

.table-input {
  width: 80px;
  padding: 10px;
  border: 1px solid #ffffff;
}
</style>