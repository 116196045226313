<template>
	<div class="roomcardpage">
		<el-row :gutter="20">
			<el-col :span="4">
				<div class="btu" @click="makeshow">
					制新卡
				</div>
			</el-col>
			<el-col :span="20">
				<el-table :data="tableData" style="width: 100%"  :header-cell-style="{ background: '#F7F7F7', color: '#4C4C4C' }">
					<el-table-column prop="date" align="center" label="房号" width="180">
					</el-table-column>
					<el-table-column prop="name" align="center" label="锁号" width="180">
					</el-table-column>
					<el-table-column prop="address" align="center" label="开始时间">
					</el-table-column>
					<el-table-column prop="address" align="center" label="到期时间">
					</el-table-column>
					<el-table-column  label="操作"  align="center" >
						<template slot-scope="scope">
							<el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
							<el-button type="text" size="small">编辑</el-button>
						</template>
					</el-table-column>
				</el-table>
			</el-col>
		</el-row>
		
		<el-dialog
		  title="制房卡"
		  :destroy-on-close="true"
		  :visible.sync="makecardshow"
		  :modal='false'
		  center
		  width="75%"
		  class="MakeCardBOX"
		> 
		<makeCard></makeCard>
		</el-dialog>
	</div>
</template>

<script>
	// import makeCard from "./MakeCard.vue"
	export default {
		name: "roomCard",
		
		data() {
			return {
				tableData: [{
					date: '2016-05-02',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				}],
				makecardshow:false,
			}
		},
		components: {
		  makeCard:()=>import ('./MakeCard.vue'),
		},
		methods: {
			makeshow(){
				this.makecardshow=true;
			},
		},
	}
</script>

<style lang="scss" scoped>
	.btu {
		width: 110px;
		height: 35px;
		line-height: 35px;
		text-align: center;
		margin-bottom: 15px;
		border-radius: 6px;
		background-color: #e4eff0;
		color: #666666;
		cursor: pointer;
	}
	::v-deep .el-dialog__header {
	  // background-color: #e8fdfe;
	}
	
	::v-deep .el-dialog__body {
	  padding-top: 5px;
	  box-sizing: border-box;
	  // background-color: #e8fdfe;
	}
	.MakeCardBOX {
	  ::v-deep .el-dialog__header {
	    // background-color: #e8fdfe;
	    height: 40px;
	    font-size: 16px;
	  }
	  ::v-deep .el-dialog__body {
	    height: 600px;
	    // overflow-y: auto;
	    overflow-y: auto;
	    box-sizing: border-box;
	    // background-color: #e8fdfe;
	  }
	}
</style>