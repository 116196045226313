const isLogin = () => !!localStorage.getItem('token')
const isApplicationId = () => !!localStorage.getItem('ApplicationId')
const getToken = () => localStorage.getItem('token')
const removeUserInfo = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('TenantId')
  localStorage.removeItem('classesId')
  localStorage.removeItem('classes')
  localStorage.removeItem('userClassesId')
  localStorage.removeItem('name')
  localStorage.removeItem('ApplicationId')
  localStorage.removeItem('userId')
  localStorage.removeItem('ApplicationId')
  localStorage.removeItem('orgid')
}
export {
  isLogin,
  getToken,
  isApplicationId,
  removeUserInfo
}
