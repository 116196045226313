<template>
  <!-- 操作日志 -->
  <div class="OperationLog">
    <div class="OperationLog_main">
      <el-timeline
          class="date_time">
        <el-timeline-item
            :color="item.color"
            hide-timestamp
            v-for="(item, index) in activities"
            :key="index"
        >
          <el-card>
            <p><span>{{ item.createdTime }}</span>- {{ item.echoMap.createdBy }}:{{ item.operationContent }}</p>
          </el-card>
        </el-timeline-item>

      </el-timeline>
    </div>
  </div>
</template>

<script>
import {ListJournal} from '_api/orderFrom'

export default {
  name: 'OperationLog',
  props: ['ruleFormId'],
  data() {
    return {
      activities: [],
      current: 1,
      size: 1000,
      total: 0
    }
  },
  mounted() {
    console.log('OperationLog mounted')
    if (this.ruleFormId) {
      this.ListJournaled(this.ruleFormId);
    }
  },
  methods: {
    //分页列表的请求
    ListJournaled(id) {
      console.log('ListJournaled')
      const params = {
        current: this.current,
        size: this.size,
        model: {
          state: true,
          orderId: id,
        }
      }
      ListJournal(params).then(res => {
        if (res.data.code === 0) {
          this.activities = res.data.data.records
          this.total = Number(res.data.data.total)
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.OperationLog {
  width: 100%;
  height: 500px;
  overflow-y: auto;
  padding: 0 20px 20px 40px;
  box-sizing: border-box;

  .OperationLog_main {
    width: 90%;
    border-radius: 8px;
    padding: 20px 20px 0 20px;
    box-sizing: border-box;
    border: 1px solid rgb(240, 239, 239);
    //时间点的样式
    .date_time {
      .el-card {
        background-color: #F7F7F7;

        P {
          color: #555555;

          span {
            color: #272727;
          }
        }
      }
    }

  }
}
</style>