<template>
  <!--查看联房账单  -->
  <div class="RoomBilling">
    <p>
      联单号:{{ connectRoomId }}, &nbsp; 共{{PlateFrom.length}}间 | &nbsp;联房账务统计：&nbsp; 付款合计￥{{ payments }} &nbsp;
      消费合计￥ {{consumption}}
    </p>
    <el-row class="RoomBilling_nav">
      <el-col :span="3" class="left_box">
        <div class="card" v-for="el in PlateFrom" :key="el.id">
          <p class="house-info">{{ el.roomName }} &nbsp; {{ el.houseTypeName }}</p>
          <!-- <p>{{ el.name }}</p> -->
          <p>{{ el.guestName }}</p>
          <p>消费:{{el.consumptionAmount}}, &nbsp; 付款: {{ el.paymentAmount }}</p>
          <p>
            <span></span>
            <span>{{ el.orderState?'在住':'已退房' }}</span>
          </p>
          <div class="radioBox">{{ el.isMasterRoom?'主': '副' }}</div>
        </div>
      </el-col>
      <el-col :span="20" :offset="0.5" class="right_Box">
        <el-table
          :data="tableData"
          height="600px"
          border
          style="width: 100%"
		  :cell-style="cellStyle"
		   :header-cell-style="{ background: '#F7F7F7', color: '#4C4C4C' }"
        >
          <el-table-column prop="roomName" align="center" label="房号">
			  <template slot-scope="scope">
				  <p
			      v-if="scope.row.state ? false : true"
			      style="
			        width: 973px;
			        color: gray;
			        height: 1px;
			        background-color: gray;
			        position: absolute;
			        top: 50%;
			        z-index: 100;
			      "
			    ></p>
				{{scope.row.roomName||''}}
			  </template>
          </el-table-column>
          <el-table-column prop="projectName" align="center" label="项目名称">
          </el-table-column>
          <el-table-column prop="address" align="center" label="消费">
            <template slot-scope="scope">
              {{ scope.row.itemType == "1" ? scope.row.price : null }}
            </template>
          </el-table-column>
          <el-table-column prop="name" align="center" label="付款">
            <template slot-scope="scope">
              {{ scope.row.itemType == "0" ? scope.row.price : null }}
            </template>
          </el-table-column>
          <el-table-column prop="classes" align="center" label="班次">
          </el-table-column>
          <el-table-column prop="operator" align="center" label="操作人">
          </el-table-column>
          <el-table-column
            prop="updatedTime"
            align="center"
            width="180px"
            label="操作时间"
          >
          </el-table-column>
          <el-table-column prop="voucherNo" align="center" label="凭证号">
          </el-table-column>
          <el-table-column prop="remarks" align="center" label="备注">
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { financeManagementConnectIist } from "_api/orderFrom";
export default {
  name: "RoomBilling",
  data() {
    return {
      tableData: [],
      PlateFrom: [],
      consumption: 0,//联房消费
      payments: 0,//联房付款
    };
  },
  computed: {
    connectRoomId() {
      return this.PlateFrom[0] ? this.PlateFrom[0].connectRoomId : ''
    }
  },
  created() {},
  methods: {
    //联房财务查看接口
    financeManagementConnectIisted() {
      const params = {
        type: 0,
        connectRoomId: this.PlateFrom[0].connectRoomId,
      };
	  var that =this;
      financeManagementConnectIist(params).then((res) => {
        if (res.data.code == 0) {
          this.tableData = res.data.data.filter(el => !el.preAuth && !el.authOver && el.state);
		  // this.tableData.splice(0);
		  // res.data.data.forEach(function(el){
			 //  console.log('是否存储',!el.preAuth && !el.authOver && el.state)
			 //  if(!el.preAuth && !el.authOver && el.state){
				//  that.tableData.push(el); 
			 //  }
		  // });
		  this.tableData = res.data.data;
          this.tableData.forEach((el,index) => {
            // 项目类型 0付款 1消费
            if(el.itemType == 0&&el.state) {
              this.payments = parseFloat(this.payments) + parseFloat(el.price)
            } 
            if(el.itemType == 1&&el.state) {
              this.consumption = parseFloat(this.consumption) + parseFloat(el.price)
            }
          })
		  console.log('表单',this.tableData)
		  console.log('表单',res)
          this.consumption = this.consumption.toFixed(2)
          this.payments = this.payments.toFixed(2)
          this.$message({
            type: "success",
            message: "查看所有联房财务成功",
          });
        }
      });
    },
	roomChaekc(item){
		console.log('shuju',this.PlateFrom)
		const params = {
		  type: 0,
		  connectRoomId: item.connectRoomId,
		};
		this.tableData.splice(0);
		financeManagementConnectIist(params).then((res) => {
		  if (res.data.code == 0) {
		    // this.tableData = res.data.data.filter(el => !el.preAuth && !el.authOver && el.state);
		    this.tableData = res.data.data;
			console.log('数据',this.tableData)
		    this.tableData.forEach((el,index) => {
		      // 项目类型 0付款 1消费
		      if(el.itemType == 0&&el.state) {
		        this.payments = parseFloat(this.payments) + parseFloat(el.price)
		      } 
		      if(el.itemType == 1&&el.state) {
		        this.consumption = parseFloat(this.consumption) + parseFloat(el.price)
		      }
		    })
		    this.consumption = this.consumption.toFixed(2)
		    this.payments = this.payments.toFixed(2)
		    this.$message({
		      type: "success",
		      message: "查看所有联房财务成功",
		    });
		  }
		});
	},
	// 让表格奇数行有背景色
	cellStyle({ row, column, rowIndex, columnIndex }) {
	  // console.log(row);
	  // if (row.state === true) {
	  //   return "background:#eee; color: 'gray'";
	  // }else if(row.authOver){
	  //   return "background:#fff; color: 'gray'";
	  // }
	  if (row.state === true) {
	    return "background:#fff; color: 'gray'";
	  } else {
	    return "background:#F7F7F7; color: 'gray'";
	  }
	},
  },
};
</script>

<style lang="scss" scoped>
.RoomBilling {
  height: 640px;
  .RoomBilling_nav.el-row {
    margin-top: 20px;
    .el-col.left_box {
      max-height: 600px;
      overflow: auto;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      .card {
        position: relative;
        //height: 78px;
        width: 130px;
        float: left;
        border: 1px solid rgb(197, 195, 195);
        padding-top: 5px;
        box-sizing: border-box;
        border-radius: 4px;
        margin-bottom: 15px;

        .house-info {
          padding-right: 20px;
        }
        .radioBox {
          position: absolute;
          top: 3px;
          right: 3px;
          width: 20px;
          height: 20px;
          z-index: 10;
          border-radius: 50%;
          text-align: center;
          line-height: 20px;
          font-size: 13px;
          background-color: #5cb5b8;
          color: #fff;
        }
        p {
          font-size: 13px;
          background-color: #fff;
          padding-left: 5px;
          box-sizing: border-box;
          margin-bottom: 6px;
          span:nth-child(1) {
            display: block;
            width: 5px;
            height: 5px;
            background-color: green;
            border-radius: 50%;
            margin-right: 8px;
          }
        }
        p:nth-child(2) {
          color: gray;
        }
        p:nth-child(3) {
          color: rgb(11, 241, 11);
        }
        p:nth-child(4) {
          display: flex;
          margin-bottom: 0px;
          align-items: center;
          color: green;
          background-color: rgb(194, 245, 194);
        }
      }
    }
    .right_Box.el-col {
      padding-left: 10px;
      box-sizing: border-box;
    }
  }
}
</style>