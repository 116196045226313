import request from '_utils/request'
import qs from 'qs'
//房态页面的接口
//页面右上角的用户信息：数据指标统计
const userModeDate = (params = {}) => (
    request.get('/wineshop/room/statistics/count', {params})
)
//房间状态中间表/获取脏房信息/
const GetPigstyRoom = (params = {}) => (
    request.get('/wineshop/roomState/dirty/getList', {
        params: params,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
    })
)
//房间状态中间表/获取脏房信息/批量清扫
const GetBatchCleaning = (params = {}) => (
    request.get('/wineshop/roomState/dirty/cleans?ids=' +[...params.ids]+'', {
    
    })
)
//付款方式/付款查看
const Getpayment = (params = {}) => (
    request.get('/wineshop/payment_method/type_view', {
        params: qs.stringify(params),
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
    })
)
//班结财务表/班结缴款
const SettlementPayment = (params = {}) => (
    request.get('/wineshop/classesOver/settlementPayment', {params})
    )
//班结财务表/当前班结数据查看
const SettlementPaymentAll = (params = {}) => (
    request.get('/wineshop/classesOver/view?orgId=' + params.orgId+'&'+'userId=' + params.userId, {
    //   query:  params,
    //     headers: {
    //         "Content-Type": "application/x-www-form-urlencoded",
    //     }
    
    })
    )
//房间/房态列表
const RoomStatusList = (params = {}) => (
    request.post('/wineshop/room/view', params)
)
//发票管理/分页列表查询
const invoiceManagementList = (params = {}) => (
    request.post('/wineshop/invoice_management/page', params)
)
//发票管理/修改
const invoiceManagementUpadate = (params = {}) => (
    request.put('/wineshop/invoice_management', params)
)
//夜审/分页列表查询
const NightAuditorList = (params = {}) => (
    request.post('/wineshop/night_auditor/page', params)
)
//夜审/夜审新增财务信息
const NightAuditorAdd = (params = {}) => (
    request.post('/wineshop/night_auditor/add',  
    qs.stringify(params)
    , {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
)
//夜审数据查看
const NightAuditorData = (params = {}) => (
    request.post('/wineshop/night_auditor/page/view', params)
)
//取消维修
const CancelRoom = (params = {}) => (
    request.post('/wineshop/maintenanceRoomRegistration/cancelMaintenance', params)
)
//查看维修
const MonomerRoom = (params = {}) => (
    request.get('/wineshop/maintenanceRoomRegistration/'+ params.id,
   
    // {
    //     params: qs.stringify(params),
    //     headers: {
    //                     'Content-Type': 'application/x-www-form-urlencoded'
    //                 }
    // }
    )
)
//修改维修
const editMonomerRoom = (params = {}) => (
    request.put('/wineshop/maintenanceRoomRegistration', params )
)

// const MonomerRoom = (params = {}) => (
//     request.get('/wineshop/maintenanceRoomRegistration/viewMaintenance',{
//         data: params,
//         headers: {
//             'Content-Type': 'application/x-www-form-urlencoded'
//         }
//     })
// )

//报表/客房报表统计
const StatementLists = (params = {}) => (
    request.post('/wineshop/house_type/roomReportStatistics', params )
)
//班结数据报表
const TeamStatementLists = (params = {}) => (
    request.post('/wineshop/classesOver/reportForms', params )
)
//收款明细报表
const PayeeStatementLists = (params = {}) => (
    request.post('/wineshop/finance_management/collectionStatement', params )
)
//会员充值明细报表
const MemberStatementLists = (params = {}) => (
    request.post('/wineshop/memberChangeAmtRecord/memberChargeDetail', params )
)
//会员充值明细报表导出Excel
const MemberStatementExcel = (params = {}) => (
    request.post('/wineshop/memberChangeAmtRecord/export', params,{
        responseType: 'arraybuffer',
    
      } )
)
//报表/客房报表统计Excel
const StatementExcel = (params = {}) => (
    request.post('/wineshop/house_type/export', params,{
        responseType: 'arraybuffer',
    
      } )
)
//班结数据报表Excel
const TeamStatementExcel = (params = {}) => (
    request.post('/wineshop/classesOver/export', params ,{
        responseType: 'arraybuffer',
    
      })
)
//收款明细报表Excel
const PayeeStatementExcel = (params = {}) => (
    request.post('/wineshop/finance_management/export', params ,{
        responseType: 'arraybuffer',
    
      })
)
//备用金数据列表
const classesStandbyMoneyPage = (params = {}) => (
    request.post('/wineshop/classesStandbyMoney/page', params )
)
//调整班结备用金（即新增数据）
const classesStandbyMoneyAdd = (params = {}) => (
    request.post('/wineshop/classesStandbyMoney/add', params )
)
//获取当前剩余备用金 //
const getStandbyMoney = (params = {}) => (
    request.post('/wineshop/classesStandbyMoney/getStandbyMoney', params )
)
export {
    userModeDate,
    GetPigstyRoom,
    GetBatchCleaning,
    Getpayment,
    SettlementPayment,
    RoomStatusList,
    invoiceManagementList,
    invoiceManagementUpadate,
    NightAuditorList,
    NightAuditorAdd,
    SettlementPaymentAll,
    NightAuditorData,
    CancelRoom,
    MonomerRoom,
	editMonomerRoom,
    StatementLists,
    TeamStatementLists,
    PayeeStatementLists,
    MemberStatementLists,
    MemberStatementExcel,
    StatementExcel,
    TeamStatementExcel,
    PayeeStatementExcel,
    classesStandbyMoneyPage,
    classesStandbyMoneyAdd,
    getStandbyMoney

}