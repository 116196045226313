import request from '_utils/request'
import qs from 'qs'

// 房价管理
// 获取房价配置列表
const getHousePriceList = (params = {}) => request.post('/wineshop/house_price/view?date=' + params.date + '&size=' + params.size, params)

// 更新房价配置
const updateHousePrice = (params = {}) => request.post('/wineshop/house_price/update_date', params)

// 查看周日价配置
const getWeekPriceList = (params = {}) => request.get('/wineshop/house_price/view_week', { params })

// 配置周日价
const updateWeekPrice = (params = {}) => request.post('/wineshop/house_price/update_week', params)

//系统页面之房源设置的接口
//系统设置-房价码
const getHouseList = (params = {}) => (
  request.get('/wineshop/sys_house_price_code/page', { params })
)

//房源设置之楼层设置：添加楼层 
const setWineshop = (params = {}) => (
  request.post('/wineshop/sys_floor', params)
)

//房源设置之楼层设置：修改
const DeleteWineshop = (params = {}) => (
  request.put('/wineshop/sys_floor', params)
)

//房源设置之楼层设置：分页列表查询
const getPageWineshop = (params = {}) => (
  request.post('/wineshop/sys_floor/page', params)
)

//房源设置之房型设置：添加房型
const AddWineshop = (params = {}) => (
  request.post('/wineshop/house_type', params)
)
//房源设置之房型设置：查询单体详情
const UpdateWineshop = (params = {}) => (
  request.get('/wineshop/house_type/detail', { params })
)
//房源设置之房型设置：分页列表查询
const getPageHouseshop = (params = {}) => (
  request.post('/wineshop/house_type/page', params)
)

//房源设置之房型设置: 修改
const getModification = (params = {}) => (
  request.put('/wineshop/house_type', params)
)
//房源设置之房型设置: 删除
const DeleteHouseShop = (params = {}) => (
  request.delete('/wineshop/house_type', { params })
)
//房源设置之房型设置: 房型查看
const AllHouseShop = (params = {}) => (
  request.get('/wineshop/house_type/type_view', { params })
)
//房源设置之房型设置: 楼层查看
const AllFloor = (params = {}) => (
  request.get('/wineshop/sys_floor/type_view', { params })
)
//房源设置之房间设置: 新增
const AddRoom = (params = {}) => (
  request.post('/wineshop/room', params)
)
//房源设置之房间设置: 分页列表查询
const RoomListInquire = (params = {}) => (
  request.post('/wineshop/room/page', params)
)
//房源设置之房间设置: 删除
const DeleteRoomList = (params = {}) => (
  request.delete('/wineshop/room', { params })
)
//房源设置之房间设置: 修改
const UpdateRoomList = (params = {}) => (
  request.put('/wineshop/room', params)
)

//系统设置-操作日志-分页查询
const getOperationList = (params = {}) => (
  request.post('/base/baseOperationLog/page', params)
)
//系统设置-操作日志-删除日志
const OperationDel = (params = {}) => (
  request.delete('/base/baseOperationLog', { params })
)

//系统设置-操作日志-清空日志日志
const Operationclear = (params = {}) => (
  request.delete('/base/baseOperationLog/clear', { params })
)
//根据房间状态统计房数
const roomStatuscout = (params = {}) => (
  request.get('/wineshop/room/count', { params })
)
//房间/房态列表
const roomStatusView = (params = {}) => (
  request.post('/wineshop/room/view', params)
)
//房型
const houseTypeView = (params = {}) => (
  request.get('/wineshop/house_type/type_view', params)
)
//楼层查看
const floorTypeView = (params = {}) => (
  request.get('/wineshop/sys_floor/type_view', params)
)

//报维修
const roommaintenanceadd = (params = {}) => (
  request.post('/wineshop/maintenanceRoomRegistration/add', params)
)

//房间/状态变更
const roomsuoupdate = (params = {}) => (
  request.get('/wineshop/roomState/state/update', { params })
)
//房间操作日志
const roomlogView = (params = {}) => (
  request.post('/wineshop/room/room/operation', params)
)

//添加预定金 
const addDeposit = (params = {}) => (
  request.post('/wineshop/orderReserve/addDeposit', params)
)

//添加预定金 
const updRowHouse = (params = {}) => (
  request.post('/wineshop/orderReserve/updRowHouse', params)
)

//预订单编辑
const updOrderReserve = (params = {}) => (
  request.post('/wineshop/orderReserve/updOrderReserve', params)
)
//修改预订单排房信息
const orderReserveUpdate = (params = {}) => (
  request.post('/wineshop/orderReserve/updRowHouse', params)
)
//取消预订单 
const cancelReserveOrder = (params = {}) => (
  request.post('/wineshop/orderReserve/cancelReserveOrder?reserveId=' + params.reserveId)
)

//退定金 
const refundDeposit = (params = {}) => (
  request.post('/wineshop/orderReserve/refundDeposit?depositId=' + params.depositId)
)

//取消余额支付
const balanceRefund = (params = {}) => (
  request.post('/wineshop/memberChangeAmtRecord/balanceRefund?consumptionType=' + params.consumptionType + '&orderNumber=' + params.orderNumber)
)
//房型修改
const houseTypeViewUpadata = (params = {}) => (
  request.put('/wineshop/house_type', params)
)
//房单恢复为在住   订单信息/房单恢复为在住 
const order_baseOrder_restore = (params = {}) => (
  request.post('/wineshop/order_base/order_restore', params)
)
//房型 /批量更新  /f房型/标配项目保存
const house_typeUpdate_list = (params = {}) => (
  request.post('/wineshop/house_type/shop_update_list', params)
)
//标配项目查看
const houseshopViewlist = (params = {}) => (
  request.post('/wineshop/house_type/shop_view', params)
)
//房型批量更新
const houseTypeUpdateList = (params = {}) => (
  request.post('/wineshop/house_type/update_list', params)
)
//联房入住
const order_baseSaveList = (params = {}) => (
  request.post('/wineshop/order_base/save_list', params)
)
//联房操作
const orderBaseUpdateList = (params = {}) => (
  request.post('/wineshop/order_base/update_list?type=' + params.type, params.orderBase)
)
//必达门卡制卡获取宾客序号
const getGuestNo = (params = {}) => (
  request.get('/wineshop/room/getGuestNo', { params })
)

export {
  getHouseList,
  setWineshop,
  DeleteWineshop,
  getPageWineshop,
  AddWineshop,
  UpdateWineshop,
  getPageHouseshop,
  getModification,
  DeleteHouseShop,
  AddRoom,
  RoomListInquire,
  DeleteRoomList,
  UpdateRoomList,
  getOperationList,
  OperationDel,
  Operationclear,
  AllHouseShop,
  AllFloor,
  roomStatuscout,
  roomStatusView,
  houseTypeView,
  floorTypeView,
  roommaintenanceadd,
  roomsuoupdate,
  roomlogView,
  addDeposit,
  updRowHouse,
  updOrderReserve,
  cancelReserveOrder,
  refundDeposit,
  orderReserveUpdate,
  houseTypeViewUpadata,
  order_baseOrder_restore,
  house_typeUpdate_list,
  houseshopViewlist,
  houseTypeUpdateList,
  order_baseSaveList,
  orderBaseUpdateList,
  getGuestNo,
  balanceRefund,
  getHousePriceList,
  updateHousePrice,
  getWeekPriceList,
  updateWeekPrice,
}
