<template>
  <!-- 会员卡消费 -->
  <div class="MemberConsumer">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="demo-ruleForm"
    >
      <!-- <el-form-item label="房间信息" prop="tableData">
        <el-table :data="ruleForm.tableData" border style="width: 100%">
          <el-table-column prop="id" align="center" label="入住单号">
          </el-table-column>
          <el-table-column prop="name" align="center" label="住客姓名">
          </el-table-column>
          <el-table-column
            prop="consumptionAmount"
            align="center"
            label="消费合计"
          >
          </el-table-column>
          <el-table-column prop="paymentAmount" align="center" label="付款合计">
          </el-table-column>
          <el-table-column prop="totalPreGrant" align="center" label="预授合计">
          </el-table-column>
        </el-table>
      </el-form-item> -->
      <el-form-item label="会员号" prop="number" class="MemberNumber">
        <el-input
        placeholder="请输入完整手机号码搜索会员信息"
        @change="changeMember(ruleForm.number)" v-model="ruleForm.number" style="width: 70%;"></el-input>
        <el-button icon="el-icon-search" @click="memberSearch(ruleForm.number)" ></el-button>
      </el-form-item>
      <el-form-item label="会员信息" prop="name">
        <el-table border :data="ruleForm.tableMember" style="width: 100%">
          <el-table-column prop="name" align="center" label="会员姓名">
          </el-table-column>
          <el-table-column prop="mobile" align="center" label="手机号码">
          </el-table-column>
          <el-table-column
            prop="memberGradeName"
            align="center"
            label="会员等级"
          >
          </el-table-column>
          <el-table-column
            prop="residualBalance"
            align="center"
            label="会员余额"
          >
            <template slot-scope="scope">
				  	<span>￥ {{ scope.row.residualBalance }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item label="本次扣款" style="position: relative;" prop="price">
        <el-input 
        :disabled="true" 
        v-model="price" style="width: 50%" > </el-input>
        <p class="JingBox">
          <img src="../assets/icons/警告 (1).png" alt="" class="JingImg"/>
          建议扣款{{ ruleForm.tableMember.length != 0?ruleForm.tableMember[0].residualBalance:'' }}+
        </p>
      </el-form-item>
      <el-form-item>
        <el-button @click="submitButton('ruleForm')"> 确定 </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { MembershipDetails,membershipDetailsList, memberConsumption } from "_api/member";
import { Getpayment } from "_api/conditionOfHouse";
import { userInfoGet, AddFinance, ListFinance } from "_api/orderFrom";
export default {
  name: "MemberConsumer",
  data() {
    return {
      ruleForm: {
        tableData: [],
        tableMember: [],
        isBlack: false,
        number: null, //会员号
        price: null, //本次扣款
        paymentId: null, //付款方式id
        projectName: null, //项目名称
      },
      consumptionType: null,//消费类型:1为房态,2为商超,3为餐饮,4为娱乐
      price: 0,
      rules: {
        tableData: [
        { required: true, message: '请输入内容', trigger: 'blur' },
        ],
        number: [
        { required: true, message: '请输入完整手机号码', trigger: 'blur' },
        ],
        price:[
        { required: true, message: '请输入扣款金额', trigger: 'blur' },
        ]
      },
      remark: null,
      MemberNumbers: 0,
      member: {
        memberGradeName: null,
        number: null,
        memberId: null,
      },
      current: 1,
      size: 10,
      addMemberss: 0,
      memberId: null,
      paymentFrom: [], //付款方式
      arrivalTimes: null, //当前时间
    };
  },
  created() {},
  methods: {
    //点击确定提交
    submitButton(formName) {
      if(this.isBlack) {
        this.$message({
                type: 'warning',
                message: '该会员已添加进黑名单，请重新输入会员手机号'
              })
      }else{
        if(this.ruleForm.tableMember.length > 0) {
        if(this.price){
          if( parseFloat(this.price) <= parseFloat(this.ruleForm.tableMember[0].residualBalance)) {
            if(parseFloat(this.ruleForm.tableMember[0].residualBalance) == 0) {
              this.$message({
            type: 'error',
            message: `当前会员无可用余额，请先去充值`
          })
            }else{
              this.memberConsumptioned();
            }
            
          }else{
            this.$message({
            type: 'error',
            message: `输入消费金额大于${this.ruleForm.tableMember[0].residualBalance}无法结账`
          })
          this.$emit('MemberPrice', [1])
          }
          
        }else{
          this.$message({
            type: 'error',
            message: '请填写消费金额'
          })
        }
        
      }else{
        this.$message({
          type: 'error',
          message: '无会员信息，请输入会员号'
        })
      }
          
          
      }
    
    },
   //点击搜索按钮触发
   memberSearch(num) {
    this.MemberNumbers = 0
        if(!num) {
          this.$message({
            type: 'error',
            message: '请输入会员号'
          })
        }else{
          this.userInfoGeted(num)
        }
    },
    changeMember(num) {
      this.MemberNumbers = 0
      if(!num) {
        this.ruleForm.tableMember.splice(0)
        
      }else{
        this.userInfoGeted(num)
      }
    },
      // //财务分页列表查询
      // ListFinanceed(row) {
    
      //   const params = {
      //     current: 1,
      //     size: 10000,
      //     model: {
      //       orderId: row.id,
      //       operator: localStorage.getItem("classesId"),
           
      //     },
      //   };
      //   ListFinance(params).then((res) => {
      //     if (res.data.code === 0) {
      //      var arr = res.data.data.records.filter(el => el.state)
      //     this.ruleForm.tableData[0].totalPreGrant = 0
      //     this.ruleForm.tableData[0].consumptionAmount = 0
      //     this.ruleForm.tableData[0].paymentAmount = 0
      //      arr.forEach(el => {
      //       if(!el.authOver && el.preAuth) {
      //         this.ruleForm.tableData[0].totalPreGrant = this.ruleForm.tableData[0].totalPreGrant + parseFloat(el.price)
      //       }
      //       if(el.itemType == 1 && !el.preAuth) {
      //         //消费
      //         this.ruleForm.tableData[0].consumptionAmount = this.ruleForm.tableData[0].consumptionAmount + parseFloat(el.price)
      //       }
      //       if(el.itemType == 0 && !el.preAuth) {
      //         //付款
      //         this.ruleForm.tableData[0].paymentAmount = this.ruleForm.tableData[0].paymentAmount + parseFloat(el.price)
      //       }
      //      })
      //     }
      //   });
    
      // },
 //根据手机号查询数据
 userInfoGeted(num) {
      const params = {
        mobile: num,
      };
      userInfoGet(params)
        .then((res) => {
          console.log(res);
          if (res.data.code === 0) {
              //1是黑名单
              this.isBlack = res.data.data.isBlack == 1?true: false
              if(res.data.data.isBlack != 1){
                this.MemberNumbers = 2
            this.$message({
              type: "success",
              message: "会员信息查询成功",
            });
            this.member = {
              memberGradeName: res.data.data.memberGradeName,
              number: res.data.data.number,
              memberId: res.data.data.id,
            };
            this.MembershipCarded(res.data.data.id);
            // this.ruleForm.clientType = '1'
            this.userinfo.forEach((el) => {
              if (el.mobile == this.member.number) {
                el.memberGradeName = this.member.memberGradeName;
                (el.number = this.member.number),
                  (el.memberId = this.member.id);
              }
            });
            // this.housePriceButton(res.data.data.housePriceCodes)
            if (this.ruleForm.agreementUnitId) {
              this.ruleForm.clientType = "2";
            } else {
              if (this.member.number) {
                this.ruleForm.clientType = "1";
              } else {
                this.ruleForm.clientType = "0";
              }
            }
              }else{
                this.$message({
                type: 'warning',
                message: '该会员已添加进黑名单，请重新输入会员手机号'
              })
              }
           
          }else{
            
          }
        }).catch(() => {
          // console.log(res);
          if(this.MemberNumbers == 0) {
            this.$message({
            type: 'error',
            message: '没有查到该手机号会员，请再次确定'
          })
          this.member = {
            memberGradeName: null,
            number: null,
          };
          this.ruleForm.tableMember.splice(0)
          }
          
        });
    },
     //查看会员信息详情:更据会员号的查看获取会员信息
   MembershipCarded(num) {
      MembershipDetails({ id: num }).then((res) => {
        if (res.data.code === 0) {
          this.ruleForm.tableMember.splice(0);
          this.ruleForm.tableMember.push(res.data.data);
          this.$set(this.ruleForm,this.ruleForm.tableMember,[res.data.data])
          this.ruleForm.number = res.data.data.number;
          this.$store.commit("base/SET_MEMBERDATA", res.data.data);
          this.memberId = num;
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg?res.data.msg:res.data.errorMsg?res.data.errorMsg:'系统繁忙'
          })
        }
      });
    },
     //会员消费接口
     memberConsumptioned() {
      if(this.addMemberss == 1) {
        const params = {
        // "amountMoney": 0,//实收金额
        createdBy: localStorage.getItem("userClassesId"), //创建人
        createdTime: this.arrivalTimes, //创建时间
        currentBalance: parseFloat(
          this.ruleForm.tableMember[0].residualBalance
        ), //当前余额
        memberId: this.memberId, //会员ID
        name: localStorage.getItem("name"), //操作人姓名
        orderId: '', //关联订单号
        orgId: localStorage.getItem("orgid"),
        orgName: localStorage.getItem('orgName'),
        paymentId: this.ruleForm.paymentId,
        paymentName: this.ruleForm.projectName,
        remark: this.remark,
        state: true,
        consumptionType: this.consumptionType,
        // orderNumber: this.ruleForm.tableData[0].id,
        tenantId: localStorage.getItem("TenantId"),
        transferAmount: parseFloat(this.price), //动账金额
        transferType: 1, //动账类型（0入账 1消费）
        userClassesId: localStorage.getItem("userClassesId"), //用户班次id
      };
      this.$emit('MemberPrice', [this.ruleForm,params])
      }else{
        const params = {
        // "amountMoney": 0,//实收金额
        createdBy: localStorage.getItem("userClassesId"), //创建人
        createdTime: this.arrivalTimes, //创建时间
        currentBalance: parseFloat(
          this.ruleForm.tableMember[0].residualBalance
        ), //当前余额
        memberId: this.memberId, //会员ID
        name: localStorage.getItem("name"), //操作人姓名
        orderId: this.ruleForm.tableData[0].id, //关联订单号
        consumptionType: this.consumptionType,
        orderNumber: this.ruleForm.tableData[0].id,
        orgId: localStorage.getItem("orgid"),
        paymentId: this.ruleForm.paymentId,
        paymentName: this.ruleForm.projectName,
        remark: null,
        state: true,
        tenantId: localStorage.getItem("TenantId"),
        transferAmount: parseFloat(this.price), //动账金额
        transferType: 1, //动账类型（0入账 1消费）
        userClassesId: localStorage.getItem("userClassesId"), //用户班次id
      };
      console.log(params);
      memberConsumption(params).then((res) => {
        if (res.data.code == 0) {
          this.$message({
            type: "success",
            message: "会员结账成功",
          });
            this.$emit('MemberPrice', [this.ruleForm,this.price])
        }else{
          this.$message({
            type: "success",
            message: "会员消费失败",
          });
        }
      });
      }
    
    },
   
    //付款方式/付款查看
    Getpaymented() {
      Getpayment().then((res) => {
        if (res.data.code === 0) {
          this.paymentFrom = res.data.data;
          this.paymentFrom.forEach((el) => {
            if (el.name == "微储值") {
              this.ruleForm.paymentId = el.id;
              this.ruleForm.projectName = el.name;
            }
          });
        }
      });
    },
     //获取当前时间
     getNowDate() {
      var date = new Date();

      var sign2 = ":";
      var year = date.getFullYear(); // 年
      var month = date.getMonth() + 1; // 月
      var day = date.getDate(); // 日
      var hour = date.getHours(); // 时
      var minutes = date.getMinutes(); // 分
      var seconds = date.getSeconds(); //秒
      // 给一位数的数据前面加 “0”
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (day >= 0 && day <= 9) {
        day = "0" + day;
      }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }

      this.arrivalTimes =
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        sign2 +
        minutes +
        sign2 +
        seconds;
    },
      // console.log(this.$store.state.user.orderFrom);
    },

    // //会员分页列表查询: 表格
    // membershipDetailsListed(model = {}) {
    //   const params = {
    //     current: this.current,
    //     size: this.size,
    //     model: {
    //       ...model,
    //       state: true,
    //     },
    //   };
    //   membershipDetailsList(params).then((res) => {
    //     if (res.data.code === 0) {
    //       this.ruleForm.tableMember.splice(0);
    //       res.data.data.records.forEach(el => {
    //         this.MembershipCarded(el.id);
    //       })

    //     }
    //   });
    // },
   
};
</script>

<style lang="scss" scoped>
.JingImg{
  width: 13px;
}
.JingBox{
  // display: block;
  width: 200px;
  // margin-left: 10px;
  background-color: rgb(250, 233, 202);
  color: rgb(26, 167, 26);
  position: absolute;
  right: 14%;
  top: 0%;
}
.MemberConsumer {
  .demo-ruleForm {
    .el-form-item.MemberNumber {
      position: relative;
      .el-input {
      }

      .el-button {
        position: absolute;
        top: 0%;
        right: 7%;
        z-index: 10;
      }
    }
    .el-form-item:last-child{
      text-align: right;
      .el-button {
        width: 100px;
        height: 35px;
        color: #fff;
        background-color: #5cb5b8;
        border-radius: 6px;
        padding: 0px 0px;
      }
    }
  }
}
</style>