<template>
  <!-- 批量退房 -->
  <div class="BatchRoom">
    <el-form ref="form" :model="form" label-width="90px">
      <el-form-item label="转入房号" label-width="90px">
        <el-input style="width: 88%" clearable v-model="form.roomName" />
        <el-button icon="el-icon-search"></el-button>
      </el-form-item>
      <el-form-item label-width="0px">
        <p>加收提醒</p>
        <el-table
          :data="overtimeFrom"
          border
          max-height="300px"
          style="width: 100%"
        >
          <el-table-column prop="roomName" align="center" label="房号">
          </el-table-column>
          <el-table-column prop="matchName" align="center" label="住客">
          </el-table-column>
          <el-table-column prop="allPrice" label="已付款" align="center">
            <template slot-scope="scope"
              ><span>￥{{ scope.row.allPrice }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="allSysPRICE" label="已消费" align="center">
            <template slot-scope="scope"
              ><span>￥{{ scope.row.allSysPRICE }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="projectName" label="加收类型" align="center">
          </el-table-column>
          <el-table-column prop="price" label="金额" align="center">
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item label-width="0px">
        <el-input
          v-model="form.remarkss"
          style="width: 70%; height: 35px; margin-right: 10px"
          placeholder="加收/不加收原因"
        ></el-input>
        <el-radio v-model="form.radio" label="1">加收</el-radio>
        <el-radio v-model="form.radio" label="2">不加收</el-radio>
      </el-form-item>
    </el-form>

    <div class="submitButton">
      <el-button @click="submitButton(form.radio)"> 确认退房 </el-button>
    </div>
  </div>
</template>

<script>
import {
  order_baseUpdateOutList,
  financeManagementSaveList,
  financeManagementConnectIist,
} from "_api/orderFrom";
import { consumptionList } from "_api/systemSetting";
export default {
  name: "BatchRoom",
  data() {
    return {
      form: {
        price: null,
        roomName: "",
        radio: "1",
        remarkss: "", //加收、不加收原因
      },
      PlateFrom: [], //批量退房房单订单信息
      dataPlate: {}, //联房退房的参数
      tableData: [], //退房的财务信息
      isDisabled: false,
      consumptionData: [], //消费项目数据
      overtimeFrom: [], //加收的财务数据集合
      reserveDays: null,
    };
  },
  created() {},
  methods: {
    submitButton(num) {
      if (num == 1) {
        //加收
        this.financeManagementSaveListed(this.overtimeFrom);
      } else if (num == 2) {
        //不加收
        this.order_baseUpdateOutListed(this.dataPlate);
      }
    },

    //批量新增财务接口
    financeManagementSaveListed(arr) {
      if(arr && arr.length > 0) {
        const params = [...arr];
      financeManagementSaveList(params).then((res) => {
        if (res.data.code === 0) {
          this.$message({
            type: "success",
            message: "财务入账成功",
          });
          this.order_baseUpdateOutListed(this.dataPlate);
        }
      });
      }else{
        this.order_baseUpdateOutListed(this.dataPlate);
      }
      
    },
    //批量退房接口
    order_baseUpdateOutListed(arr) {
      const params = {
        connectRoomId: arr.connectRoomId, //连房id
        isMasterRoom: true, //是否为连房的主房（1为是，0为否）
        orderId: arr.orderId, //要财务转入的房单id
        orderIdList: arr.orderIdList, //退房房单id
        outRoomTime: arr.outRoomTime, //退房时间
        settleAccountsClasses: arr.settleAccountsClasses, //结账班次
      };
      order_baseUpdateOutList(params).then((res) => {
        if (res.data.code === 0) {
          this.$message({
            type: "success",
            message: "批量退房成功",
          });
          this.$emit("OutListed");
        }
      });
    },
    //联房财务查看接口
    financeManagementConnectIisted() {
      const params = {
        type: false,
        connectRoomId: this.PlateFrom[0].connectRoomId,
      };
      financeManagementConnectIist(params).then((res) => {
        if (res.data.code == 0) {
          var tableData = res.data.data;
          this.overtimeFrom.splice(0);
          console.log(tableData);
          var orderFrom = [];
          this.PlateFrom.forEach((ic) => {
            orderFrom.push({
              orderId: ic.id,
              arr: [],
              allPrice: 0,
              allSysPRICE: 0,
              orderData: { ...ic }, //房单订单信息
              outTime: false, //判断有没有超时
              roomPrice: false,
              type: 0, //0全天房/1钟点房
            });
          });
          var allPrice = 0; //总付款
          var allSysPRICE = 0; //总消费
          res.data.data.forEach((el) => {
            orderFrom.forEach((item) => {
              if (item.orderId == el.orderId) {
                //选中要退房的财务信息
                if (el.itemType == 0) {
              //付款
              item.allPrice = parseFloat(item.allPrice) + parseFloat(el.price);
            } else if (el.itemType == 1) {
              //消费
              item.allSysPRICE = parseFloat(item.allSysPRICE) + parseFloat(el.price);
            }
                item.arr.push(el);
              }
            });
          });
          console.log(orderFrom);
          orderFrom.forEach((el) => {
            var newTime = new Date().getTime();
            var predepartureTime = new Date(
              el.orderData.predepartureTime
            ).getTime();
            if (newTime > predepartureTime) {
              //超时了
              el.outTime = true;
              if (el.orderData.settlementType == 0) {
                //全天房
                el.arr.forEach((ib) => {
                  if (ib.projectName == "全日房费") {
                    el.roomPrice = true;
                  }
                });
                el.type = 0;
              } else if (el.orderData.settlementType == 1) {
                el.arr.forEach((ib) => {
                  if (ib.projectName == "钟点房费") {
                    el.roomPrice = true;
                  }
                });
                el.type = 1;
              }
            } else {
              el.outTime = false;
            }
          });
          orderFrom.forEach((el) => {
            if (!el.roomPrice) {
              //该房单财务没有房费
              if (el.outTime) {
                if (el.type == 0) {
                  //全天房
                  var newTime = new Date().getTime();
                  var predepartureTime = new Date(
                    el.orderData.predepartureTime
                  ).getTime();
                  var days = Math.ceil(
                    ((parseFloat(newTime) - parseFloat(predepartureTime))) /
                      (1 * 24 * 60 * 60 * 1000)
                  );
                  var overtimePrices = (days * parseFloat(el.orderData.roomRealityPrice)).toFixed(2);
                  var overtimePrice =
                    (parseFloat(el.orderData.reserveDays) *
                    parseFloat(el.orderData.roomRealityPrice)).toFixed(2)
                  var paramss = {
                    price: overtimePrices,
                    orderId: el.orderData.id,
                    itemType: "1",
                    state: true,
                    roomId: el.orderData.roomId,
                    roomName: el.orderData.roomName, //房型名称
                    userClassesId: localStorage.getItem("userClassesId"),
                    operator: localStorage.getItem("name"),
                    paymentId: "372735506967953484",
                    projectName: "超时费",
                    entryState: "2",
                    type: "0",
                    allPrice: parseFloat(el.allPrice).toFixed(2) ,
                    allSysPRICE: parseFloat(el.allSysPRICE).toFixed(2),
                    matchName: el.orderData.name,
                    classesId: localStorage.getItem("classesId"),
                    createdName: localStorage.getItem("name"),
                    classes: localStorage.getItem("classes"),
                  };
                  var params = {
                    price: overtimePrice,
                    orderId: el.orderData.id,
                    itemType: "1",
                    state: true,
                    roomId: el.orderData.roomId,
                    matchName: el.orderData.name,
                    roomName: el.orderData.roomName, //房间号
                    userClassesId: localStorage.getItem("userClassesId"),
                    operator: localStorage.getItem("name"),
                    paymentId: "372735506967953484",
                    projectName: "全日房费",
                    entryState: "2",
                    type: "0",
                    allPrice: parseFloat(el.allPrice).toFixed(2) ,
                    allSysPRICE: parseFloat(el.allSysPRICE).toFixed(2),
                    classesId: localStorage.getItem("classesId"),
                    createdName: localStorage.getItem("name"),
                    classes: localStorage.getItem("classes"),
                  };
                  this.consumptionData.forEach((el) => {
                    if (el.name == "全日房费") {
                      params.paymentId = el.id;
                    } else if (el.name == "超时费") {
                      paramss.paymentId = el.id;
                    }
                  });
                  this.overtimeFrom.push(params);
                  this.overtimeFrom.push(paramss);
                } else if (el.type == 1) {
                  //钟点房
                  var newTime = new Date().getTime();
                var predepartureTime = new Date(
                  el.orderData.predepartureTime
                ).getTime();
                var days = Math.ceil(
                  (parseFloat(newTime) - parseFloat(predepartureTime)) /
                    (60 * 60 * 1000)
                );
                  var overtimePrices = (days * parseFloat(el.orderData.reserveDays)).toFixed(2);
                    var paramss = {
                      price: overtimePrices,
                      orderId: el.orderData.id,
                      itemType: "1",
                      state: true,
                      allPrice: parseFloat(el.allPrice).toFixed(2) ,
                    allSysPRICE: parseFloat(el.allSysPRICE).toFixed(2),
                      roomId: el.orderData.roomId,
                      matchName: el.orderData.name,
                      roomName: el.orderData.roomName, //房型名称
                      userClassesId: localStorage.getItem("userClassesId"),
                      operator: localStorage.getItem("name"),
                      paymentId: "372735506967953484",
                      projectName: "超时费",
                      entryState: "2",
                      type: "0",
                      classesId: localStorage.getItem("classesId"),
                      createdName: localStorage.getItem("name"),
                      classes: localStorage.getItem("classes"),
                    };
                    //钟点房无法续住默认取实际价格，没住满钟点时长也按实际价格收
                    var params = {
                      price: el.orderData.roomRealityPrice,
                      orderId: el.orderData.id,
                      itemType: "1",
                      state: true,
                      roomId: el.orderData.roomId,
                      matchName: el.orderData.name,
                      roomName: el.orderData.roomName, //房间号
                      userClassesId: localStorage.getItem("userClassesId"),
                      operator: localStorage.getItem("name"),
                      paymentId: "372735506967953484",
                      projectName: "钟点房费",
                      entryState: "2",
                      type: "0",
                      allPrice: parseFloat(el.allPrice).toFixed(2) ,
                    allSysPRICE: parseFloat(el.allSysPRICE).toFixed(2),
                      classesId: localStorage.getItem("classesId"),
                      createdName: localStorage.getItem("name"),
                      classes: localStorage.getItem("classes"),
                    };
                    this.consumptionData.forEach((el) => {
                      if (el.name == "钟点房费") {
                        params.paymentId = el.id;
                      } else if (el.name == "超时费") {
                        paramss.paymentId = el.id;
                      }
                    });
                    this.overtimeFrom.push(params);
                    this.overtimeFrom.push(paramss);
                }
              } else{
                //没有超时
                if (el.type == 0){
                  //全天房
                  this.getNowDates(new Date(), el.orderData.arrivalTime, el.orderData);
                    var overtimePrice =
                      (parseFloat(this.reserveDays) *
                      parseFloat(el.orderData.roomRealityPrice)).toFixed(2);
                    var params = {
                      price: overtimePrice,
                      orderId: el.orderData.id,
                      itemType: "1",
                      state: true,
                      roomId: el.orderData.roomId,
                      matchName: el.orderData.name,
                      roomName: el.orderData.roomName, //房间号
                      userClassesId: localStorage.getItem("userClassesId"),
                      operator: localStorage.getItem("name"),
                      paymentId: "372735506967953484",
                      projectName: "全日房费",
                      entryState: "2",
                      type: "0",
                      allPrice: parseFloat(el.allPrice).toFixed(2) ,
                    allSysPRICE: parseFloat(el.allSysPRICE).toFixed(2),
                      classesId: localStorage.getItem("classesId"),
                      createdName: localStorage.getItem("name"),
                      classes: localStorage.getItem("classes"),
                    };
                    this.consumptionData.forEach((el) => {
                      if (el.name == "全日房费") {
                        params.paymentId = el.id;
                      }
                    });
                    this.overtimeFrom.push(params);
                } else if(el.type == 1) {
                  //钟点房
                   //钟点房无法续住默认取实际价格，没住满钟点时长也按实际价格收
                   var params = {
                      price: el.orderData.roomRealityPrice,
                      orderId: el.orderData.id,
                      itemType: "1",
                      state: true,
                      roomId: el.orderData.roomId,
                      matchName: el.orderData.name,
                      roomName: el.orderData.roomName, //房间号
                      userClassesId: localStorage.getItem("userClassesId"),
                      operator: localStorage.getItem("name"),
                      paymentId: "372735506967953484",
                      projectName: "钟点房费",
                      entryState: "2",
                      type: "0",
                      allPrice: parseFloat(el.allPrice).toFixed(2) ,
                    allSysPRICE: parseFloat(el.allSysPRICE).toFixed(2),
                      classesId: localStorage.getItem("classesId"),
                      createdName: localStorage.getItem("name"),
                      classes: localStorage.getItem("classes"),
                    };
                    this.consumptionData.forEach((el) => {
                      if (el.name == "钟点房费") {
                        params.paymentId = el.id;
                      } 
                    });
                    this.overtimeFrom.push(params);
                }
              }
            }
          });

          // var newTime = new Date(this.dataPlate.outRoomTime).getTime();
          // var predepartureTime = new Date(
          //   this.PlateFrom[0].predepartureTime
          // ).getTime();
          // if (newTime > predepartureTime) {
          //   //超时了
          //   if (this.PlateFrom[0].settlementType == 0) {
          //     //全天房
          //     orderFrom.forEach((el) => {
          //       el.arr.forEach((ib) => {
          //         if (ib.name == "全日房费") {
          //           el.roomPrice = true;
          //         }
          //       });
          //       el.type = 0;
          //     });
          //     //根据roomPrice 判断有没有房费
          //     orderFrom.forEach((el, index) => {
          //       if (el.roomPrice) {
          //         //有房费
          //         orderFrom.splice(index, 1);
          //       }
          //     });
          //   } else if (this.PlateFrom[0].settlementType == 1) {
          //     //钟点房

          //     orderFrom.forEach((el) => {
          //       el.arr.forEach((ib) => {
          //         if (ib.name == "钟点房费") {
          //           el.roomPrice = true;
          //         }
          //       });
          //       el.type = 1;
          //     });
          //     //根据roomPrice 判断有没有房费
          //     orderFrom.forEach((el, index) => {
          //       if (el.roomPrice) {
          //         //有房费
          //         orderFrom.splice(index, 1);
          //       }
          //     });
          //   }
          //   //根据type判断是全日还是钟点
          //   orderFrom.forEach((it) => {
          //     if (it.type == 0) {
          //       //全日房费
          //       var newTime = new Date(this.dataPlate.outRoomTime).getTime();
          //       var predepartureTime = new Date(
          //         this.PlateFrom[0].predepartureTime
          //       ).getTime();
          //       var days = Math.ceil(
          //         (parseFloat(newTime) - parseFloat(predepartureTime)) /
          //           (1 * 24 * 60 * 60 * 1000)
          //       );

          //       this.PlateFrom.forEach((iz) => {
          //         if (iz.id == it.orderId) {
          //           var overtimePrices = days * parseFloat(iz.roomRealityPrice);
          //           var overtimePrice =
          //             parseFloat(iz.reserveDays) *
          //             parseFloat(iz.roomRealityPrice);
          //           var paramss = {
          //             price: overtimePrices,
          //             orderId: iz.id,
          //             itemType: "1",
          //             state: true,
          //             roomId: iz.roomId,
          //             roomName: iz.roomName, //房型名称
          //             userClassesId: localStorage.getItem("userClassesId"),
          //             operator: localStorage.getItem("name"),
          //             paymentId: "372735506967953484",
          //             projectName: "超时费",
          //             entryState: "2",
          //             type: "0",
          //             matchName: iz.name,
          //             classesId: localStorage.getItem("classesId"),
          //             createdName: localStorage.getItem("name"),
          //             classes: localStorage.getItem("classes"),
          //           };
          //           var params = {
          //             price: overtimePrice,
          //             orderId: iz.id,
          //             itemType: "1",
          //             state: true,
          //             roomId: iz.roomId,
          //             matchName: iz.name,
          //             roomName: iz.roomName, //房间号
          //             userClassesId: localStorage.getItem("userClassesId"),
          //             operator: localStorage.getItem("name"),
          //             paymentId: "372735506967953484",
          //             projectName: "全日房费",
          //             entryState: "2",
          //             type: "0",
          //             classesId: localStorage.getItem("classesId"),
          //             createdName: localStorage.getItem("name"),
          //             classes: localStorage.getItem("classes"),
          //           };
          //           this.consumptionData.forEach((el) => {
          //             if (el.name == "全日房费") {
          //               params.paymentId = el.id;
          //             } else if (el.name == "超时费") {
          //               paramss.paymentId = el.id;
          //             }
          //           });
          //           this.overtimeFrom.push(params);
          //           this.overtimeFrom.push(paramss);
          //         }
          //       });
          //     } else {
          //       //钟点房费
          //       var newTime = new Date(this.dataPlate.outRoomTime).getTime();
          //       var predepartureTime = new Date(
          //         this.PlateFrom[0].predepartureTime
          //       ).getTime();
          //       var days = Math.ceil(
          //         (parseFloat(newTime) - parseFloat(predepartureTime)) /
          //           (60 * 60 * 1000)
          //       );

          //       this.PlateFrom.forEach((iz) => {
          //         if (iz.id == it.orderId) {
          //           var overtimePrices = days * parseFloat(it.reserveDays);
          //           var paramss = {
          //             price: overtimePrices,
          //             orderId: iz.id,
          //             itemType: "1",
          //             state: true,
          //             roomId: iz.roomId,
          //             matchName: iz.name,
          //             roomName: iz.roomName, //房型名称
          //             userClassesId: localStorage.getItem("userClassesId"),
          //             operator: localStorage.getItem("name"),
          //             paymentId: "372735506967953484",
          //             projectName: "超时费",
          //             entryState: "2",
          //             type: "0",
          //             classesId: localStorage.getItem("classesId"),
          //             createdName: localStorage.getItem("name"),
          //             classes: localStorage.getItem("classes"),
          //           };

          //           this.getNowDates(new Date(), iz.arrivalTime, iz);
          //           var overtimePrice =
          //             parseFloat(iz.reserveDays) * parseFloat(iz.hourLength);
          //           var params = {
          //             price: overtimePrice,
          //             orderId: iz.id,
          //             itemType: "1",
          //             state: true,
          //             roomId: iz.roomId,
          //             matchName: iz.name,
          //             roomName: iz.roomName, //房间号
          //             userClassesId: localStorage.getItem("userClassesId"),
          //             operator: localStorage.getItem("name"),
          //             paymentId: "372735506967953484",
          //             projectName: "钟点房费",
          //             entryState: "2",
          //             type: "0",
          //             classesId: localStorage.getItem("classesId"),
          //             createdName: localStorage.getItem("name"),
          //             classes: localStorage.getItem("classes"),
          //           };
          //           this.consumptionData.forEach((el) => {
          //             if (el.name == "钟点房费") {
          //               params.paymentId = el.id;
          //             } else if (el.name == "超时费") {
          //               paramss.paymentId = el.id;
          //             }
          //           });
          //           this.overtimeFrom.push(params);
          //           this.overtimeFrom.push(paramss);
          //         }
          //       });
          //     }
          //   });
          // } else {
          //   //没超时房费
          //   if (this.PlateFrom[0].settlementType == 0) {
          //     //全天房
          //     orderFrom.forEach((el) => {
          //       el.arr.forEach((ib) => {
          //         if (ib.name == "全日房费") {
          //           el.roomPrice = true;
          //         }
          //       });
          //       el.type = 0;
          //     });
          //     //根据roomPrice 判断有没有房费
          //     orderFrom.forEach((el, index) => {
          //       if (el.roomPrice) {
          //         //有房费
          //         orderFrom.splice(index, 1);
          //       }
          //     });
          //   } else if (this.PlateFrom[0].settlementType == 1) {
          //     //钟点房

          //     orderFrom.forEach((el) => {
          //       el.arr.forEach((ib) => {
          //         if (ib.name == "钟点房费") {
          //           el.roomPrice = true;
          //         }
          //       });
          //       el.type = 1;
          //     });
          //     //根据roomPrice 判断有没有房费
          //     orderFrom.forEach((el, index) => {
          //       if (el.roomPrice) {
          //         //有房费
          //         orderFrom.splice(index, 1);
          //       }
          //     });
          //   }
          //   //根据type判断是全日还是钟点
          //   orderFrom.forEach((it) => {
          //     if (it.type == 0) {
          //       //全日房费
          //       this.PlateFrom.forEach((iz) => {
          //         if (iz.id == it.orderId) {
          //           this.getNowDates(new Date(), iz.arrivalTime, iz);
          //           var overtimePrice =
          //             parseFloat(this.reserveDays) *
          //             parseFloat(iz.roomRealityPrice);
          //           var params = {
          //             price: overtimePrice,
          //             orderId: iz.id,
          //             itemType: "1",
          //             state: true,
          //             roomId: iz.roomId,
          //             matchName: iz.name,
          //             roomName: iz.roomName, //房间号
          //             userClassesId: localStorage.getItem("userClassesId"),
          //             operator: localStorage.getItem("name"),
          //             paymentId: "372735506967953484",
          //             projectName: "全日房费",
          //             entryState: "2",
          //             type: "0",
          //             classesId: localStorage.getItem("classesId"),
          //             createdName: localStorage.getItem("name"),
          //             classes: localStorage.getItem("classes"),
          //           };
          //           this.consumptionData.forEach((el) => {
          //             if (el.name == "全日房费") {
          //               params.paymentId = el.id;
          //             }
          //           });
          //           this.overtimeFrom.push(params);
          //         }
          //       });
          //     } else {
          //       //钟点房费
          //       this.PlateFrom.forEach((iz) => {
          //         if (iz.id == it.orderId) {
          //           this.getNowDates(new Date(), iz.arrivalTime, iz);
          //           var overtimePrice =
          //             parseFloat(this.reserveDays) *
          //             parseFloat(iz.roomRealityPrice);
          //           var params = {
          //             price: overtimePrice,
          //             orderId: iz.id,
          //             itemType: "1",
          //             state: true,
          //             roomId: iz.roomId,
          //             matchName: iz.name,
          //             roomName: iz.roomName, //房间号
          //             userClassesId: localStorage.getItem("userClassesId"),
          //             operator: localStorage.getItem("name"),
          //             paymentId: "372735506967953484",
          //             projectName: "钟点房费",
          //             entryState: "2",
          //             type: "0",
          //             classesId: localStorage.getItem("classesId"),
          //             createdName: localStorage.getItem("name"),
          //             classes: localStorage.getItem("classes"),
          //           };
          //           this.consumptionData.forEach((el) => {
          //             if (el.name == "钟点房费") {
          //               params.paymentId = el.id;
          //             }
          //           });
          //           this.overtimeFrom.push(params);
          //         }
          //       });
          //     }
          //   });
          // }

          console.log(this.overtimeFrom);
          this.$message({
            type: "success",
            message: "查看所有联房财务成功",
          });
        }
      });
    },
    //消费项目的分页列表
    consumptionListed(index) {
      const params = {
        current: 1,
        size: 100000,
        model: {},
      };
      consumptionList(params).then((res) => {
        if (res.data.code === 0) {
          this.consumptionData = res.data.data.records;
          if (index) {
            this.getNowDates(
              new Date(),
              this.PlateFrom[index].arrivalTime,
              this.PlateFrom[index]
            );
            if (this.PlateFrom[index].settlementType == 0) {
              this.PlateFrom[index].reserveDays = this.reserveDays;
              console.log(this.consumptionData);
              this.$forceUpdate();
            }
          }
        }
      });
    },
    //获取真实天数
    getNowDates(date, oldData, row) {
      var date1 = date.getTime();
      var oldData1 = new Date(oldData).getTime();
      if (row.settlementType == 0) {
        if (
          parseFloat(date1) - parseFloat(oldData1) <=
          1 * 24 * 60 * 60 * 1000
        ) {
          this.reserveDays = 1 + "天";
        } else if (
          parseFloat(date1) - parseFloat(oldData1) >=
          1 * 24 * 60 * 60 * 1000
        ) {
          let num = Math.ceil(
            (parseFloat(date1) - parseFloat(oldData1)) /
              (1 * 24 * 60 * 60 * 1000)
          );
          this.reserveDays = num + "天";
        }
      } else {
        if (parseFloat(date1) - parseFloat(oldData1) <= 60 * 60 * 1000) {
          this.reserveDays = 1;
        } else {
          let num = Math.ceil(
            (parseFloat(date1) - parseFloat(oldData1)) / (60 * 60 * 1000)
          );
          this.reserveDays = num;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
body .el-table::before {
    z-index: inherit;
}
.BatchRoom {
  position: relative;
  padding-bottom: 20px;
  .el-form {
    .el-form-item {
      margin-bottom: 10px;
    }
  }
  .submitButton {
    position: absolute;
    bottom: -20px;
    right: 0px;
    .el-button {
      width: 110px;
      height: 35px;
      padding: 0px 15px;
      background-color: #5cb5b8;
      color: #fff;
    }
  }
}
</style>