<template>
	<!-- 吧台点餐 -->
	<div class="PrintBill">
		<div class="counter_main">
			<!-- 内容区 -->
			<div class="barCounter_main">
				<!-- 侧边分类栏 :style="{ height: BoxHeight }"-->
				<div class="sidebar">
					<ul>
						<li v-for="(el, index) in ClasssGoodsFrom" :key="index" @click="ChangeClassIfy(index)"
							:class="{ active: li_index == index }">
							<span v-if="el.name.length < 5">
								{{ el.name }}
							</span>
							<el-tooltip v-else class="item" effect="light" placement="top-start">
								<div slot="content">{{ el.name }}</div>
								<span>
									{{
                   el.name ? el.name.length > 5?  el.name.slice(0,5)+'...': el.name:'' 
                }}
								</span>
							</el-tooltip>
						</li>
						<!-- <li>分类1</li>
                <li>分类1</li>
                <li>分类1</li>
                <li>分类1</li>
                <li>分类1</li>
                <li>分类1</li> -->
					</ul>
				</div>
				<!-- 侧边分类栏 -->

				<!-- 商品展示区域 :style="{ height: BoxHeight }"-->
				<div class="commodity_main">
					<!-- 搜索 -->
					<el-input @change="searchIn" v-model="searchInput" clearable placeholder="根据商品名称搜索商品"
						style="width: 88%" class="serchInputs">
						<el-button @click="searchInputButton" slot="append" icon="el-icon-search"
							style="background-color: #5cb5b8;color: #fff"></el-button>
					</el-input>
					<!-- <img
            @click="searchInputButton"
            style=""
            class="searchImg"
            src="../../../../assets/icons/选择 (1).png"
            alt=""
          /> -->
					<!-- 搜索 -->

					<!-- 商品卡片 -->
					<div class="commodity_box">
						<template v-if="ClasssGoodsFrom[li_index]&&ClasssGoodsFrom[li_index].arrFrom">
							<!-- :style="{ 'background-color': el.inventoryType ? '' : 'gray' }" -->
							<div @click="changeCard(el)" 
							v-for="el in ClasssGoodsFrom[li_index].arrFrom" :key="el.id" class="card">
							<el-image fit="scale-down" :src="el.url ? el.url.url : el.url" alt="" class="commodity_img"
								:style="{ 'background-color': el.inventoryType ? '' : 'gray' }" />
							<img src="../../../../assets/icons/选择-勾选.png" alt=""
								:class="{ pitch_on: true, active: li_card.includes(el.id) }" />
							<p class="classNameGood" style="line-height: 15px;">
								<span class="inventoryNumber">库存:{{ el.inventory }}</span>
							</p>
							<p style="line-height: 15px;">{{ el.foodName }} -￥{{ el.price }}</p>
							<div class="outPrice" :style="{ display: el.inventoryType ? 'none' : '' }">
								<span>商品已售空</span>
							</div>
						</div>
						</template>
						
					</div>
				</div>
				<!-- 商品总价格区域:style="{ height: BoxHeight }" -->
				<div class="merchandiseLedger">
					<el-table :data="PurchaseFrom" :cell-style="{ color: '#4C4C4C' }"
						:header-cell-style="{ color: '#4C4C4C' }">
						<!-- background:'linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%)' -->
						<el-table-column prop="foodsName" label="品名" >
						</el-table-column>
						<el-table-column label="数量" prop="quantity">
							<template slot-scope="scope">
								<el-input @change="ChangeInputs(scope.row)" v-model.number="scope.row.quantity" />
							</template>
						</el-table-column>
						<el-table-column prop="price" min-width="120" label="单价">
							<template slot-scope="scope">
								<el-input @change="ChangeInputs(scope.row)" v-model.number="scope.row.price">
									<div slot="prefix"
										style="height: 100%;display: flex;align-items: center;justify-content: center;">
										<span>￥</span>
									</div>
								</el-input>
							</template>
						</el-table-column>
						<el-table-column prop="address" label="操作">
							<template slot-scope="scope">
								<span @click="ChangePRICE(scope.row)" style="color: red; cursor: pointer">
									删除
								</span>
							</template>
						</el-table-column>
						<!-- <el-table-column prop="remark" label="备注">
              <template slot-scope="scope">
                <el-input v-model="scope.row.remark"></el-input>
              </template>
            </el-table-column> -->
					</el-table>
					<div class="aggregateAmount">
            <div>
              <span style="margin-left: 10px">数量合计：</span>
              <span>{{ totalNum }}</span>
            </div>
            <div>
              <span style="margin-left: 10px">总金额：</span>
              <span>￥{{ totalPrice ? totalPrice : "0" }}</span>
            </div>
					</div>
				</div>
				<!-- 商品总价格区域 -->
			</div>
			<!-- 内容区 -->
			<!-- 付款方式 -->
			<!-- <div class="payment_method">
				<ul>
					<li @click="ChangePayment(el)" :class="{ active: el.id == li_Payment }" v-for="el in priceFrom"
						:key="el.id">
						{{ el.name }}
					</li>
				</ul>
			</div> -->
			<!-- 按钮区域 -->
			<div class="sunButton">
				<el-form :inline="true" :model="formInline" class="demo-form-inline" label-width="70px" :rules="rules">
					<el-form-item label="协议挂账" v-if="formInline.paymentName == '协议挂账'">
						<el-select @change="getBarName(formInline.agreementUnitId)" clearable style="width: 120px"
							v-model="formInline.agreementUnitId" placeholder="协议单位">
							<el-option v-for="el in DealFron" :key="el.id" :label="el.name" :value="el.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="挂入房间" v-if="formInline.paymentName == '挂房账'">
						<el-select @change="roomButton(formInline.roomId)" clearable style="width: 120px"
							v-model="formInline.roomId" placeholder="挂账房间">
							<el-option v-for="el in roomTableData" :key="el.id" :label="el.roomName"
								:value="el.roomId"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="备注">
						<el-input v-model="remarks" placeholder="备注信息"></el-input>
					</el-form-item>
					<el-form-item label="客厅：" prop="parlorId">
						<el-select @change="parlorChange(formInline.parlorId)" style="width: 150px"
							v-model="formInline.parlorId" placeholder="请选择客厅">
							<el-option v-for="el in DrawingTable" :key="el.id" :label="el.parlorName"
								:value="el.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label-width="80px" label="当前房间:" v-if="ruleFrom.roomName">{{ruleFrom.roomName}}</el-form-item>
					<el-form-item label="餐桌编号">
						<el-select @change="tableChange(formInline.tableNameId)" clearable style="width: 120px"
							@clear="tableclear" v-model="formInline.tableNameId" placeholder="餐桌编号">
							<el-option v-for="el in DiningTable" :key="el.id" :label="el.tableName"
								:value="el.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="用餐人数">
						<el-input-number style="width:80px;" v-model="formInline.numberPeople" controls-position="right" :min="1" ></el-input-number>
					</el-form-item>

					<el-form-item v-if="formInline.paymentName != '协议挂账' && formInline.paymentName != '挂房账'">
						<el-button class="subnitButton" @click="onSubmit(0)">会员结账</el-button>
						<el-button class="subnitButton" @click="onSubmit(1)">确认</el-button>
					</el-form-item>
				</el-form>

			</div>
			<div class="subButtonss" v-if="formInline.paymentName == '协议挂账' || formInline.paymentName == '挂房账'">
				<el-button class="subnitButton" @click="onSubmit(0)">会员结账</el-button>
				<el-button class="subnitButton" @click="onSubmit(1)">确认</el-button>
			</div>
		</div>

		<el-dialog title="提示" :visible.sync="dialogVisible" width="30%" append-to-body :before-close="handleClose">
			<div class="roomNumber">
				<div @dblclick="DblClickCard(el)" class="card" v-for="el in roomTableData" :key="el.id">
					<span>{{ el.echoMap.roomId }}</span>
					<span> {{ el.name }} - {{ el.gender ? "女" : "男" }}</span>
					<span>
						付款￥{{ el.paymentAmount }} <br />
						消费￥ {{ el.consumptionAmount }}
					</span>
				</div>
				<!-- <div class="card">
                <span>2022</span>
                <span>李四-女</span>
                <span>
                   付款300
                    消费0
                </span>
            </div>
            <div class="card">
                <span>2022</span>
                <span>李四-女</span>
                <span>
                   付款300
                    消费0
                </span>
            </div>
            <div class="card">
                <span>2022</span>
                <span>李四-女</span>
                <span>
                   付款300
                    消费0
                </span>
            </div> -->
			</div>
		</el-dialog>
		<el-dialog title="会员卡结账" :visible.sync="isConsumer" width="600px" class="addMember" destroy-on-close
			append-to-body :before-close="handleClose">
			<member-consumer ref="ConsumerBox" @MemberPrice="MemberPrice"></member-consumer>
		</el-dialog>
	</div>
</template>

<script>
	import {
		repastTablePage,
		repastOrderAdd,
		repastOrderView,
		repastOrderDelete,
		repast_ordersList,
	} from "_api/CateringBackstageApi/ProductNameApi";
	import {
		memberConsumption
	} from "_api/member";
	import {
		paymentList,
		orderFromList
	} from "_api/orderFrom";
	import {
		printertextreceipt
	} from "_api/verify";
	import MemberConsumer from '_components/MemberConsumer.vue';
	// import {
	//   repastfoodsPage,
	//   repastTypePage,
	//   repastTypeUpdate
	// } from "_api/CateringBackstageApi/MerchandiseApi";
	import {
		repastParlorPage,
		repast_foodsList
	} from "_api/CateringBackstageApi/ProductNameApi";
	import {
		BargainingUnitAll
	} from "_api/CateringBackstageApi/ProductNameApi";
	export default {
		name: "PrintBill",
		components: {
			MemberConsumer
		},
		data() {
			return {
				isSelectRoom: false,
				isShow: true,
				isDeal: false,
				li_index: 0,
				BoxHeight: "",
				mobiles: null,
				PaymentHeight: "",
				li_Payment: null,
				createdNumbers: 1,
				li_card: [],
				orderFromData: {},
				clearNums: 0,
				formInline: {
					id: "",
					roomName: null,
					parlorId: null,
					paymentId: null,
					tableNameId: null,
					tableName: null,
					paymentName: null,
					tableInformation: null,
					agreementUnitName: null,
					agreementUnitId: null,
					agreementUnitOrderId: null,
					numberPeople:1,
				},
				repastParlorId: null,
				tableName: null,
				GoodsFrom: [],
				roomFrom: [],
				memberDate: null,
				isConsumer: false,
				searchInput: null,
				dialogVisible: false,
				isDeal: false,
				printerTextId: '',
				isCanZhuo: false,
				ruleFrom: this.$store.state.user.orderFrom,
				totalPrice: null, //总金额
        totalNum: 0,
				PurchaseFrom: [], //选中的商品数据集合
				priceFrom: [], //付款方式的数据
				ClasssGoodsFrom: [], //侧边商品分类
				AllclassPrice: [], //所有商品
				CommodityFrom: [], //根据分类id对应的商品数据
				tableData: [],
				creatNumbers: 0,
				DrawingTable: [], //客厅数据集合
				remarks: "",
				deleteIndex: null,
				DiningTable: [], //所有的餐桌信息
				roomTableData: [], //所有在住的房间
				Allfootid: [],
				goodNumbers: 0,
				CheckRoom: {
					echoMap: {
						roomId: null,
						houseTypeId: null,
					},
				}, //选中的房间休息
				DealFron: [], //协议单位
				arrivalTimes: null,
				rules: {
					parlorId: [{
						required: true,
						message: '请选择客厅',
						trigger: 'change'
					}],

				}
			};
		},
		created() {
			// this.paymentListed()
			// this.repast_foodsListed()
			// this.repastTablePageed()
			// this.orderFromLists()
			// console.log(this.$store.state.base.li_card);
			this.createdUpdate();
			this.repastParlorPageed()
		},
		methods: {
			//搜索框置空时触发
			searchIn(row) {
				if (!row) {
					console.log('特异点1')
					this.repast_foodsListed();
				}
			},
			//选择客厅时触发
			parlorChange(id, row) {
				console.log('选中',id)
				this.PurchaseFrom.splice(0);
				this.li_card.splice(0);
				this.$set(this.formInline,'tableNameId','')
				this.$set(this.formInline,'tableNameName','')
				if (!id) {
					this.repastTablePageed()
					this.repastParlorId = ''
					console.log('特异点2')
					this.repast_foodsListed()
					this.printerTextId = ''
					this.$set(this.formInline,'parlorName',null)
					this.$set(this.formInline,'parlorId',null)
				} else {
					console.log('循环数据',this.DrawingTable)
					this.DrawingTable.forEach(el => {
						if (el.id == id) {
							this.printerTextId = el.printerTextId
							console.log('循环111数据',this.printerTextId)
							// this.formInline.tableInformation = el.parlorName
							this.$set(this.formInline,'parlorName',el.parlorName)
							this.$set(this.formInline,'tableNameName','/'+el.parlorName)
							this.$set(this.formInline,'parlorId',id)
							console.log('循环', el);
							this.repastParlorId = el.id
							this.repastTablePageed({
								parlorId: id
							})
							console.log('特异点3')
							this.repast_foodsListed()
						}

					})
					if (this.ruleFrom.roomId || this.formInline.tableNameId) {
						this.repastOrderViewed(this.Allfootid);
					}
				}
				console.log('修改后数据',this.formInline)
			},
			//客厅的分页列表
			repastParlorPageed(model = {}) {
				const params = {
					current: 1,
					size: 1000,
					order: 'descending',
					sort: 'sort,createdTime',
					model: {
						state: true,
						...model,
					},
				};
				repastParlorPage(params).then((res) => {
					if (res.data.code === 0) {
						this.DrawingTable = res.data.data.records
						// if(this.DrawingTable.length == 0) {
						//   this.$message({
						//     type: 'error',
						//     message: '客厅无绑定打印机设备，请先去餐桌里的客厅绑定打印机'
						//   })
						// }
					}
				});
			},
			//初始化要归零的数据
			createdUpdate() {
				this.formInline.roomName = null;
				this.formInline.paymentId = null;
				this.formInline.paymentName = null;
				this.li_Payment = "0";
				this.isDeal = false;
				this.isCanZhuo = false;
				console.log(this.li_Payment);
			},
			//挂协议单位时触发
			getBarName(id) {
				console.log(id);
				this.DealFron.forEach((el) => {
					if (el.id == id) {
						this.formInline.agreementUnitName = el.name;
					}
				});
			},
			//协议单位查看
			BargainingUnitAlled() {
				BargainingUnitAll().then((res) => {
					if (res.data.code === 0) {
						this.DealFron = res.data.data;
					}
				});
			},
			//开始时触发的接口
			createdApi() {
				this.orderFromLists();
				this.paymentListed();
				console.log('特异点4')
				this.repast_foodsListed(); //商品列表查看
				// this.repastTablePageed();
			},
			//双击选择挂入房间
			roomButton(roomId) {
				// this.dialogVisible = true
				// this.orderFromLists()
				this.roomTableData.forEach((el) => {
					if (el.roomId == roomId) {
						this.formInline.roomName = el.roomName;
						this.formInline.roomId = el.roomId
						this.formInline.id = el.id;
						this.formInline.agreementUnitOrderId = el.id
						console.log(this.formInline);
					}
				});
				console.log(this.ruleFrom.roomName);
				if (this.formInline.roomName) {
					this.isCanZhuo = true;
				} else {
					this.isCanZhuo = false;
				}
			},
			//获取所有的餐桌
			repastTablePageed(model = {}) {
				const params = {
					current: 1,
					size: 100000,
					model: {
						state: true,
						...model
					},
				};
				repastTablePage(params).then((res) => {
					if (res.data.code === 0) {
						this.DiningTable = res.data.data.records;
						if (this.DiningTable.length == 0) {
							this.$message({
								type: 'error',
								message: '该客厅下暂无餐桌'
							})
							this.formInline.tableNameId = ''
						}
					}
				});
			},
			//分页列表查询: 获取所有在住的订单
			orderFromLists(model = {}) {
				console.log("dsa");
				const params = {
					current: 1,
					size: 1000000,
					model: {
						orderState: 1,
						...model,
					},
				};
				orderFromList(params)
					.then((res) => {
						if (res.data.code === 0) {
							this.roomTableData.splice(0);
							console.log(res.data.data.records);
							res.data.data.records.forEach((el) => {
								if (el.orderState == 1) {
									this.roomTableData.push(el);
								}
							});
							// console.log(this.roomTableData);
							// this.formInline = {
							//     id: undefined,
							//     mobile: undefined,
							//     name: undefined,
							//     roomId: undefined,
							// }
						}
					})
					.catch((err) => {});
			},

			//根据条件搜索商品
			searchInputButton() {
				console.log('特异点5')
				this.repast_foodsListed({
					foodName: this.searchInput
				});
				this.li_index = 0;
			},

			//商品列表查看
			repast_foodsListed(model = {}) {
				const params = {
					current: 1,
					size: 100000,
					model: {
						repastParlorId: this.repastParlorId,
						repastState: 1,
						state: true,
						...model,
						repastState: true,
					},
				};
				console.log('餐桌', this.repastParlorId)
				repast_foodsList(params).then((res) => {
					console.log('返回数据',res)
					if (res.data.code === 0) {
						let arrs = Object.keys(res.data.data.records[0])
						if (arrs && arrs.length > 0) {
							this.creatNumbers++
							if (this.searchInput) {
								var arr = Object.keys(res.data.data.records[0])
								if (arr.length == 0) {
									this.ClasssGoodsFrom.forEach(el => {
										el.arrFrom.splice(0)
									})
								} else {
									let obj = Object.keys(res.data.data.records[0]);
									if (obj != 0) {
										this.AllclassPrice.splice(0);
										this.ClasssGoodsFrom.splice(0);
										this.ClasssGoodsFrom =
											res.data.data.records.length != 0 ? res.data.data.records[0] : [];
										console.log(this.ClasssGoodsFrom);

										var arr = [];
										arr.splice(0);
										var li_card = [];
										li_card.splice(0);
										if (res.data.data.records.length != 0) {
											for (var keys in this.ClasssGoodsFrom) {
												arr.push({
													name: keys,
													id: this.ClasssGoodsFrom[keys][0].foodTypeId,
													arrFrom: [...this.ClasssGoodsFrom[keys]],
												});
												this.ClasssGoodsFrom[keys].forEach((el) => {
													this.AllclassPrice.push(el);
												});
											}

											arr.forEach((item) => {
												item.arrFrom.forEach((el) => {
													console.log(el);
													li_card.push(el.id);
												});
											});
											this.ClasssGoodsFrom = arr;
											if (this.createdNumbers <= 1) {
												this.Allfootid = li_card;
											}
											this.createdNumbers++
											this.repastOrderViewed(this.Allfootid);
											console.log(this.ClasssGoodsFrom); // 对象的key
											console.log(this.AllclassPrice);
										}
									} else {
										this.$message({
											type: "warning",
											message: "吧台点餐无商品，请去餐饮后台添加商品在点餐",
										});
										this.$emit("GETBAR");
									}
								}
							} else {
								let obj = Object.keys(res.data.data.records[0]);
								if (obj != 0) {
									this.AllclassPrice.splice(0);
									this.ClasssGoodsFrom.splice(0);
									this.ClasssGoodsFrom =
										res.data.data.records.length != 0 ? res.data.data.records[0] : [];
									console.log(this.ClasssGoodsFrom);
									if (JSON.stringify(res.data.data.records[0]) != {}) {

									}
									var arr = [];
									arr.splice(0);
									var li_card = [];
									li_card.splice(0);
									if (res.data.data.records.length != 0) {
										for (var keys in this.ClasssGoodsFrom) {
											arr.push({
												name: keys,
												id: this.ClasssGoodsFrom[keys][0].foodTypeId,
												arrFrom: [...this.ClasssGoodsFrom[keys]],
											});
											this.ClasssGoodsFrom[keys].forEach((el) => {
												this.AllclassPrice.push(el);
											});
										}

										arr.forEach((item) => {
											item.arrFrom.forEach((el) => {
												console.log(el);
												li_card.push(el.id);
											});
										});
										this.ClasssGoodsFrom = arr;
										if (this.createdNumbers <= 1) {
											this.Allfootid = li_card;
										}
										this.createdNumbers++
										this.repastOrderViewed(this.Allfootid);
										console.log('商品数据',this.ClasssGoodsFrom); // 对象的key
										console.log(this.AllclassPrice);
									}
								} else {
									this.ClasssGoodsFrom.forEach(el => {
										el.arrFrom.splice(0)
									});
									this.PurchaseFrom.splice(0);
									this.Allfootid.splice(0);
								}
							}

						} else {
							this.AllclassPrice.splice(0);
							this.ClasssGoodsFrom.splice(0);
							this.PurchaseFrom.splice(0);
							this.Allfootid.splice(0);
							if (this.goodNumbers == 0) {
								this.goodNumbers = 1
								if (this.creatNumbers != 0) {
									console.log('特异点6')
									// this.repast_foodsListed({
									// 	repastParlorId: ''
									// });
								} else {
									this.$message({
										type: 'info',
										message: '没有商品展示，请先去餐饮后台，商品页添加商品'
									})
									this.$emit("GETBAR");
								}
							} else {
								this.$message({
									type: 'error',
									message: '无商品或商品已下架'
								})
								this.$emit("GETBAR");
							}


						}

					}else{
						this.PurchaseFrom.splice(0);
						this.Allfootid.splice(0);
					}
				});
			},
			//弹窗关闭时触发
			handleClose(done) {
				done();
			},
			//点击删除清掉该商品
			ChangePRICE(row) {
				let arr = [];
				arr.push(row.foodsId);
				this.repastOrderDeleteed(arr);
				// this.GetGoods()
			},
			//点击商品触发事件
			changeCard(el) {
				console.log(el);

				if (el.inventory != 0) {
					if (this.formInline.parlorId) {
						if (this.li_card.includes(el.id ? el.id : el.foodsId)) {
							this.li_card.forEach((item, index) => {
								if (item == el.id || item == el.foodsId) {
									this.li_card.splice(index, 1);
									this.repastOrderDeleteed([item]);
								}
							})
							console.log(el.id);
							console.log(this.li_card);
							return false;
						} else {
							if (el.id) {
								this.li_card.push(el.id);
								// this.GetGoods()
								this.repastOrderAdded(el);
								var foodsNum = 0;
								// this.PurchaseFrom.forEach(items => {
								//     if(el.id == items.foodsId) {
								//         foodsNum = 1
								//     }
								// })
								// if(foodsNum != 1) {
								//
								// }
							}
						}
					} else {
						this.$message({
							type: "error",
							message: "请先选择客厅",
						});
					}

				} else {
					this.$message({
						type: "error",
						message: "该商品已售空",
					});
				}
			},
			//双击房卡获取房间订单信息
			DblClickCard(row) {
				console.log(row);
				this.dialogVisible = false;
				this.formInline.roomName = row.roomName;
				this.CheckRoom = row;
			},
			//清空
			tableclear(){
				this.$set(this.formInline,'tableNameName','/'+this.formInline.parlorName)
			},
			//选择餐桌编号触发事件
			tableChange(id) {
				if (id) {
					this.DiningTable.forEach((el) => {
						if (el.id == id) {
							// this.formInline.tableInformation = this.formInline.tableInformation + '/' + el
							// 	.tableName;
							this.$set(this.formInline,'tableNameName',el.tableName+'/'+this.formInline.parlorName)
							// this.formInline.tableNameName =  el.tableName+'/'+this.formInline.parlorName
							// this.repastParlorId = el.parlorId
							this.tableName = el.tableName
							this.formInline.tableNameId = el.id
							this.orderFromData = {
								...this.ruleFrom
							}
							this.repastOrderViewed(this.Allfootid)

							// this.ruleFrom = {}
							// this.repast_foodsListed()
							// console.log(el);
						}
					});
				} else {
					this.ruleFrom = this.orderFromData
					var arr = this.formInline.tableInformation.split('/')
					this.formInline.tableInformation = arr[0]
					this.tableName = ''
					this.formInline.tableNameId = ''
					// this.repast_foodsListed()
				}

				console.log(this.formInline.tableNameId);
				console.log(this.formInline.tableInformation);
				// if(this.formInline.tableNameId) {
				//     this.formInline.paymentId = null
				//     this.formInline.paymentName = null
				//     this.isDeal = true
				//     this.li_Payment = null
				//     this.isCanZhuo = false
				//     this.DiningTable.forEach(el => {
				//     console.log(el);
				//     if(el.id === this.formInline.tableNameId) {
				//         this.formInline.tableName = el.tableName
				//         this.formInline.parlorId = el.parlorId
				//     }
				// })
				// }else{
				//     this.isDeal = false
				//     this.isCanZhuo = false
				// }
			},
			//选择付款方式时触发
			ChangePayment(el) {
				// this.formInline.paymentId = el.id
				// this.formInline.paymentName = el.name
				// this.li_Payment = el.id
				if (el.id == this.formInline.paymentId) {
					this.formInline.paymentId = null;
					this.formInline.paymentName = null;
					this.li_Payment = null;
				} else {
					this.formInline.paymentId = el.id;
					this.formInline.paymentName = el.name;
					this.li_Payment = el.id;
				}
			},
			//打印小票接口
			printertextreceipted(id) {
				console.log(this.printerTextId);
				if (this.printerTextId) {
					const params = {
						createdName: localStorage.getItem('name'),
						name: localStorage.getItem("orgName"),
						orderId: id,
						parlorId: this.repastParlorId ? this.repastParlorId : '',
						paymentPrice: '',
						projectDetails: [],
						roomNumber: this.ruleFrom ? this.ruleFrom.roomName : '',
						settleAccountsDate: this.dateTime ? this.dateTime : '',
						tableName: this.formInline.tableInformation ? this.formInline.tableInformation : '',
						totalPrice: this.totalPrice ? this.totalPrice : '',
						type: "1",
					};
					this.PurchaseFrom.forEach(el => {
						params.projectDetails.push({
							date: this.dateTime,
							name: el.foodsName ? el.foodsName : '',
							// name: 'adasdasdfasdadasdasdasdasdadasasdada',
							number: el.quantity ? el.quantity : 0,
							price: el.price ? el.price : 0,
							addBatch: 0,
							totalPrice: (parseFloat(el.price) * parseFloat(el.quantity)).toFixed(2),
							type: "1",
						})
					})

					printertextreceipt(params).then((res) => {
						if (res.data.code === 0) {

						}
					})
				}

			},

			//生成订单
			repast_ordersListed() {
				var floodsIds = [];
				this.getNowDate();
				this.PurchaseFrom.forEach((el) => {
					floodsIds.push(el.foodsId);
				});
				// console.log(this.tableData);
				if (this.formInline.paymentName == "挂房账") {
					//挂账房间

					const params = {
						agreementUnitOrderId: this.formInline.id, //协议挂账房单id
						agreementUnitRoom: this.formInline.roomName, //协议挂账房间
						createdBy: localStorage.getItem("userId"), //创建人ID
						createdName: localStorage.getItem("name"), //接待员工
						foodsId: [...floodsIds], //商品id集合
						houseOrderId: this.ruleFrom.id, //房单id
						mobile: this.ruleFrom.mobile, //手机号码
						name: this.ruleFrom.name, //顾客姓名
						orderState: "1", //订单状态 0:待结账 1:已完成 2:已取消
						orderType: "1", //订单类型 0:手机订单 1:前台订单
						orgId: localStorage.getItem("orgid"),
						parlorId: this.formInline.parlorId ? this.formInline.parlorId : null, //客厅id
						price: parseFloat(this.totalPrice), //合计消费
						remark: null, //备注
						roomId: this.ruleFrom.roomId, //房间id
						roomName: this.ruleFrom.roomName, //房间名称
						settleAccountsClasses: localStorage.getItem("classes"), //结账班次
						tableId: this.formInline.tableNameId ?
							this.formInline.tableNameId :
							null, //餐桌id
						tableInformation: this.formInline.tableInformation ?
							this.formInline.tableInformation :
							null, //餐桌信息
						tenantId: localStorage.getItem("TenantId"), //	租户ID
						paymentId: this.formInline.paymentId,
						paymentPrice: this.totalPrice,
						orderId: this.ruleFrom.id, //关联订单
						numberPeople:this.formInline.numberPeople,
						paymentName: this.formInline.paymentName,
						repastOrderPaymentRelList: [{
							createdBy: localStorage.getItem("userId"),
							// "id": this.ruleFrom.id,
							orderId: this.ruleFrom.id,
							orgId: localStorage.getItem("orgid"),
							paymentId: this.formInline.paymentId,
							paymentName: this.formInline.paymentName,
							price: this.totalPrice,
							sort: 1,
						}, ],
						financeManagement: {
							//财务信息

							classes: localStorage.getItem("classes"), //班次
							classesId: localStorage.getItem("classesId"), //班次id
							createdName: localStorage.getItem("name"), //操作员
							itemType: "1", //项目类型 0付款 1消费
							operator: localStorage.getItem("name"), //操作人
							orderId: this.formInline.id, //关联订单
							orgId: localStorage.getItem("orgid"), //部门ID
							paymentId: this.formInline.paymentId, //付款方式id/消费方式id
							entryType: '2',
							entryState: '2',
							price: this.totalPrice, //金额
							projectName: this.formInline.paymentName, //项目名称
							remarks: "吧台点餐", //备注
							roomId: this.formInline.roomId, //房间id
							roomName: this.formInline.roomName,
							state: true, //状态（1为启用，0为禁用）
							type: 0, //入账类型 0消费 1结算
							userClassesId: localStorage.getItem("classesId"), //用户班次表id
						},
					};
					if (this.remarks) {
						params.remark = this.ruleFrom.remark ?
							this.ruleFrom.remark + "—吧台点餐：" + this.remarks :
							this.remarks
					} else {
						params.remark = this.ruleFrom.remark
					}
					params.remark
					if(params.parlorId){
						this.DrawingTable.forEach(function(value){
							if(value.id==params.parlorId){
								params.tableInformation='/'+value.parlorName;
							}
						});
					}
					if(params.tableId){
						this.DiningTable.forEach(function(value){
							if(value.id==params.tableId){
								if(params.tableInformation){
									params.tableInformation=value.tableName+''+params.tableInformation;
								}else{
									params.tableInformation=value.tableName;
								}
							}
						});
					}
					// console.log('提交数据',params);
					// return false;
					repast_ordersList(params).then((res) => {
						if (res.data.code === 0) {
							this.$emit("GETBAR");
							this.$message({
								type: "success",
								message: "点餐成功",
							});
							this.printertextreceipted(res.data.data.id)
							this.clearNums = 3;
							this.repastOrderDeleteed(floodsIds);
						}
					});
				} else if (this.formInline.paymentName == "协议挂账") {
					//挂协议单位
					const params = {
						agreementUnitId: this.formInline.agreementUnitId, //协议挂账id
						agreementUnitName: this.formInline.agreementUnitName, //协议挂账name
						createdBy: localStorage.getItem("userId"), //创建人ID
						createdName: localStorage.getItem("name"), //接待员工
						foodsId: [...floodsIds], //商品id集合
						houseOrderId: this.ruleFrom.id, //房单id
						mobile: this.ruleFrom.mobile, //手机号码
						name: this.ruleFrom.name, //顾客姓名
						orderState: "1", //订单状态 0:待结账 1:已完成 2:已取消
						orderType: "1", //订单类型 0:手机订单 1:前台订单
						orgId: localStorage.getItem("orgid"),
						parlorId: this.formInline.parlorId ? this.formInline.parlorId : null, //客厅id
						price: parseFloat(this.totalPrice), //合计消费
						remark: null, //备注
						numberPeople:this.formInline.numberPeople,
						roomId: this.ruleFrom.roomId, //房间id
						roomName: this.ruleFrom.roomName, //房间名称
						settleAccountsClasses: localStorage.getItem("classes"), //结账班次
						tableId: this.formInline.tableNameId ?
							this.formInline.tableNameId :
							null, //餐桌id
						tableInformation: this.formInline.tableInformation ?
							this.formInline.tableInformation :
							null, //餐桌信息
						paymentPrice: this.totalPrice,
						tenantId: localStorage.getItem("TenantId"), //	租户ID
						// "paymentId": this.formInline.paymentId,
						// "paymentName": this.formInline.paymentName,
						financeManagement: {},
						repastOrderPaymentRelList: [
							//结算方式组合
							{
								createdBy: localStorage.getItem("userId"),
								// "id": this.ruleFrom.id,
								orderId: this.ruleFrom ? this.ruleFrom.id : '',
								orgId: localStorage.getItem("orgid"),
								paymentId: this.formInline.paymentId,
								paymentName: this.formInline.paymentName,
								price: this.totalPrice,
								sort: 1,
							},
						],
					};
					if (this.remarks) {
						params.remark = this.ruleFrom.remark ?
							this.ruleFrom.remark + "—吧台点餐：" + this.remarks :
							this.remarks
					} else {
						params.remark = this.ruleFrom.remark
					}
					if (this.formInline.paymentId) {
						if(params.parlorId){
							this.DrawingTable.forEach(function(value){
								if(value.id==params.parlorId){
									params.tableInformation='/'+value.parlorName;
								}
							});
						}
						if(params.tableId){
							this.DiningTable.forEach(function(value){
								if(value.id==params.tableId){
									if(params.tableInformation){
										params.tableInformation=value.tableName+''+params.tableInformation;
									}else{
										params.tableInformation=value.tableName;
									}
								}
							});
						}
						// console.log('提交数据',params);
						// return false;
						repast_ordersList(params).then((res) => {
							if (res.data.code === 0) {
								this.$emit("GETBAR");
								this.$message({
									type: "success",
									message: "点餐成功",
								});
								this.printertextreceipted(res.data.data.id)
								this.clearNums = 3;
								this.repastOrderDeleteed(floodsIds);
							}
						});
					} else {
						this.$message({
							type: "error",
							message: "请在付款方式或者挂入房间，任选其一",
						});
					}
				} else {
					//正常结账
					const params = {
						agreementUnitOrderId: null, //协议挂账房单id
						agreementUnitRoom: null, //协议挂账房间
						createdBy: localStorage.getItem("userId"), //创建人ID
						createdName: localStorage.getItem("name"), //接待员工
						foodsId: [...floodsIds], //商品id集合
						// houseOrderId: this.ruleFrom.id, //房单id
						mobile: this.ruleFrom.mobile, //手机号码
						name: this.ruleFrom.name, //顾客姓名
						orderState: "0", //订单状态 0:待结账 1:已完成 2:已取消
						orderType: "1", //订单类型 0:手机订单 1:前台订单
						orgId: localStorage.getItem("orgid"),
						parlorId: this.formInline.parlorId ? this.formInline.parlorId : null, //客厅id
						price: parseFloat(this.totalPrice), //合计消费
						remark: null, //备注
						vipMobile: '',
						paymentPrice: this.totalPrice,
						roomId: this.ruleFrom.roomId, //房间id
						roomName: this.ruleFrom.roomName, //房间名称
						settleAccountsClasses: localStorage.getItem("classes"), //结账班次
						tableId: this.formInline.tableNameId ?
							this.formInline.tableNameId :
							null, //餐桌id
						tableInformation: this.formInline.tableInformation ?
							this.formInline.tableInformation :
							null, //餐桌信息
						tenantId: localStorage.getItem("TenantId"), //	租户ID
						// "paymentId": this.formInline.paymentId,
						// "paymentName": this.formInline.paymentName,
						financeManagement: {
							//财务信息
						},
						repastOrderPaymentRelList: [
							//结算方式组合
							// {
							// 	createdBy: localStorage.getItem("userId"),
							// 	// "id": this.ruleFrom.id,
							// 	orderId: this.ruleFrom.id,
							// 	orgId: localStorage.getItem("orgid"),
							// 	paymentId: this.formInline.paymentId,
							// 	paymentName: this.formInline.paymentName,
							// 	price: this.totalPrice,
							// 	sort: 1,
							// },
						],
					};
					if (this.remarks) {
						params.remark = this.ruleFrom.remark ?
							this.ruleFrom.remark + "—吧台点餐：" + this.remarks :
							this.remarks
					} else {
						params.remark = this.ruleFrom.remark
					}
					// if (this.formInline.paymentId) {
						console.log(params);
						if (this.memberDate) {
							params.vipMobile = this.mobiles
						}
						if(params.parlorId){
							this.DrawingTable.forEach(function(value){
								if(value.id==params.parlorId){
									params.tableInformation='/'+value.parlorName;
								}
							});
						}
						if(params.tableId){
							this.DiningTable.forEach(function(value){
								if(value.id==params.tableId){
									if(params.tableInformation){
										params.tableInformation=value.tableName+''+params.tableInformation;
									}else{
										params.tableInformation=value.tableName;
									}
								}
							});
						}
						// console.log('提交数据',params);
						// return false;
						repast_ordersList(params).then((res) => {
							if (res.data.code === 0) {
								this.$emit("GETBAR");
								this.$message({
									type: "success",
									message: "点餐成功",
								});
								if (this.memberDate) {
									this.memberConsumptioned(res.data.data.id)
								}
								this.printertextreceipted(res.data.data.id)
								this.clearNums = 3;
								this.repastOrderDeleteed(floodsIds);
							}
						});
					// } else {
					// 	this.$message({
					// 		type: "error",
					// 		message: "请在付款方式或者挂入房间，任选其一",
					// 	});
					// }
				}
			},
			clearDates() {
				this.li_card.splice(0);
				this.PurchaseFrom.splice(0);
				this.formInline.agreementUnitName = "";
				this.formInline.roomName = "";
				this.formInline.id = "";
				this.formInline.paymentId = null
				this.formInline.paymentName = null
				this.li_Payment = null
				this.formInline.tableInformation = ''
				this.formInline.agreementUnitId = ''
				this.formInline.roomId = ''
				this.formInline.tableNameId = ''
				this.remarks = ''
				console.log('特异点7')
				this.repast_foodsListed()
			},
			//购物车添加
			repastOrderAdded(el) {
				var params = {
					foodsId: el.id, //商品id
					foodsName: el.foodName, //商品名称
					tableId: this.formInline.tableNameId,
					tableInformation: this.formInline.tableInformation,
					houseOrderId: this.ruleFrom.id, //房单id
					orgId: localStorage.getItem("orgid"),
					parlorId: this.formInline.parlorId, //客厅id
					price: parseFloat(el.price).toFixed(2), //价格
					quantity: el.quantity ? parseFloat(el.quantity) : 1, //数量
					roomId: this.ruleFrom ? this.ruleFrom.roomId : '', //房间id
					roomName: this.ruleFrom ? this.ruleFrom.roomName : '', //房间名称
					sort: el.sort, //排序
					unit: el.unit, //商品单位
					totalPrice: (parseFloat(el.price) * (el.quantity ? parseFloat(el.quantity) : 1)).toFixed(2),
					url: el.url ? el.url.url : el.url,
				};
				if(params.parlorId){
					this.DrawingTable.forEach(function(value){
						if(value.id==params.parlorId){
							params.tableInformation='/'+value.parlorName;
						}
					});
				}
				if(params.tableId){
					this.DiningTable.forEach(function(value){
						if(value.id==params.tableId){
							if(params.tableInformation){
								params.tableInformation=value.tableName+''+params.tableInformation;
							}else{
								params.tableInformation=value.tableName;
							}
						}
					});
				}
				repastOrderAdd(params).then((res) => {
					if (res.data.code === 0) {
						this.$message({
							type: "success",
							message: "餐品添加成功",
						});
						this.repastOrderViewed(this.Allfootid);
					}
				});

				//   var params = {
				//   foodsId: el.id, //商品id
				//   foodsName: el.foodName, //商品名称
				//   houseOrderId: this.ruleFrom.id, //房单id
				//   orgId: localStorage.getItem("orgid"),
				//   parlorId: this.ruleFrom.id, //客厅id
				//   price: parseFloat(el.price).toFixed(2), //价格
				//   quantity: el.quantity? parseFloat(el.quantity):1, //数量
				//   roomId: this.ruleFrom.roomId, //房间id
				//   roomName: this.ruleFrom.roomName, //房间名称
				//   sort: el.sort, //排序
				//   unit: "1", //商品单位
				//   totalPrice: (parseFloat(el.price)* (el.quantity? parseFloat(el.quantity):1)).toFixed(2),
				//   url: el.url ? el.url.url : el.url,
				// };



			},
			//购物车查看
			repastOrderViewed(arrId) {
				var that = this;
				const params = {
					foodsIds: arrId, //商品id
					id: this.ruleFrom ? this.ruleFrom.roomId : this.formInline.tableNameId ? this.formInline
						.tableNameId : '',
				};
				repastOrderView(params).then((res) => {
					this.PurchaseFrom.splice(0);
					this.li_card.splice(0);
					// formInline.parlorId
					console.log('购物车数据',res.data.data);
					console.log('购物车数据',that.formInline.parlorId);
					res.data.data.forEach(function(value){
						console.log('判断',that.formInline.parlorId == value.parlorId)
						console.log('判断',value.parlorId)
						if(that.formInline.parlorId == value.parlorId){
							that.PurchaseFrom.push(value);
						}
					});
					// this.PurchaseFrom = res.data.data;
					this.PurchaseFrom.forEach((el) => {
						this.li_card.push(el.foodsId);
					});
					
					this.GetGoods();
				});
			},
			//购物车清空
			repastOrderDeleteed(arrId) {
				const params = {
					id: this.ruleFrom ? this.ruleFrom.roomId : this.formInline.tableNameId ? this.formInline
						.tableNameId : '',
					foodsIds: arrId, //商品id
				};
				repastOrderDelete(params).then((res) => {
					if (res.data.code === 0) {
						// this.$message({
						//   type: "success",
						//   message: "餐品取消成功",
						// });
						if (this.clearNums == 3) {
							this.clearDates()
						} else {
							this.repastOrderViewed(this.Allfootid);
						}

					}
				});
			},

			//计算消费金额和总金额的方法
			GetGoods() {
				this.totalPrice = 0;
				this.totalNum = 0;
				var consumerMoney = 0;
				this.PurchaseFrom.forEach((el) => {
					console.log(el);
					el.totalPrice = parseFloat(el.price) * parseFloat(el.quantity);
					this.totalPrice =
						parseFloat(el.totalPrice) + parseFloat(this.totalPrice);
					this.totalPrice = this.totalPrice.toFixed(2);
					console.log(el.totalPrice);
          this.totalNum = parseInt(el.quantity) + parseInt(this.totalNum);
				});
				console.log(this.totalPrice);
			},

			//商品数量改变时触发
			ChangeInputs(el) {
				console.log(el);
				var num_inventory = 0;
				var inventory = null;
				this.AllclassPrice.forEach((item) => {
					if (el.foodsId == item.id) {
						if (parseFloat(item.inventory) < parseFloat(el.quantity)) {
							num_inventory = 1;
							inventory = parseFloat(item.inventory);
						}
					}
				});
				if (num_inventory != 1) {
					if (this.formInline.tableNameId) {
						var params = {
							foodsId: el.foodsId, //商品id
							foodsName: el.foodsName, //商品名称
							houseOrderId: this.ruleFrom.id, //房单id
							orgId: el.orgId,
							parlorId: el.parlorId, //客厅id
							price: parseFloat(el.price).toFixed(2), //价格
							quantity: parseFloat(el.quantity), //数量
							roomId: el.roomId, //房间id
							roomName: el.roomName, //房间名称
							tableId: this.formInline.tableNameId,
							tableInformation: this.formInline.tableInformation,
							sort: el.sort, //排序
							unit: el.unit, //商品单位
							totalPrice: (parseFloat(el.price) * parseFloat(el.quantity)).toFixed(2),
							url: el.url,
						};
					} else {
						var params = {
							foodsId: el.foodsId, //商品id
							foodsName: el.foodsName, //商品名称
							houseOrderId: this.ruleFrom.id, //房单id
							orgId: el.orgId,
							parlorId: el.parlorId, //客厅id
							price: parseFloat(el.price).toFixed(2), //价格
							quantity: parseFloat(el.quantity), //数量
							roomId: el.roomId, //房间id
							roomName: el.roomName, //房间名称
							tableId: this.formInline.tableNameId,
							tableInformation: this.formInline.tableInformation,
							sort: el.sort, //排序
							unit: el.unit, //商品单位
							totalPrice: (parseFloat(el.price) * parseFloat(el.quantity)).toFixed(2),
							url: el.url,
						};
					}

					repastOrderAdd(params).then((res) => {
						if (res.data.code === 0) {
							this.repastOrderViewed(this.Allfootid);
						}
					});
				} else {
					this.$message({
						type: "error",
						message: `商品${el.foodsName}库存只有${inventory}`,
					});
					this.PurchaseFrom.forEach((item) => {
						if (el.foodsId == item.foodsId) {
							item.quantity = inventory;
						}
					});
					if (this.formInline.tableNameId) {
						var params = {
							foodsId: el.foodsId, //商品id
							foodsName: el.foodsName, //商品名称
							houseOrderId: this.ruleFrom.id, //房单id
							orgId: el.orgId,
							parlorId: el.parlorId, //客厅id
							price: parseFloat(el.price).toFixed(2), //价格
							quantity: parseFloat(inventory), //数量
							// roomId: el.roomId, //房间id
							// roomName: el.roomName, //房间名称
							tableId: this.formInline.tableNameId,
							tableInformation: this.formInline.tableInformation,
							sort: el.sort, //排序
							unit: el.unit, //商品单位
							totalPrice: (parseFloat(el.price) * parseFloat(el.quantity)).toFixed(2),
							url: el.url,
						};
					} else {
						var params = {
							foodsId: el.foodsId, //商品id
							foodsName: el.foodsName, //商品名称
							houseOrderId: this.ruleFrom.id, //房单id
							orgId: el.orgId,
							parlorId: el.parlorId, //客厅id
							price: parseFloat(el.price).toFixed(2), //价格
							quantity: parseFloat(inventory), //数量
							roomId: el.roomId, //房间id
							roomName: el.roomName, //房间名称
							sort: el.sort, //排序
							unit: el.unit, //商品单位
							totalPrice: (parseFloat(el.price) * parseFloat(el.quantity)).toFixed(2),
							url: el.url,
						};
					}

					repastOrderAdd(params).then((res) => {
						if (res.data.code === 0) {
							this.repastOrderViewed(this.Allfootid);
						}
					});
				}
			},
			//会员结账的自定义事件
			MemberPrice(row) {
				// this.$emit('MemberPrice', [this.ruleForm,params])
				if (row[0] == 1) {
					this.isConsumer = false
				} else {
					this.isConsumer = false
					this.memberDate = row[1]
					this.mobiles = row[0].number
					this.formInline.paymentId = row[1].paymentId
					this.formInline.paymentName = row[1].paymentName
					this.li_Payment = row[1].paymentId
					this.onSubmit(1)
				}

			},
			//会员卡消费接口
			memberConsumptioned(id) {
				memberConsumption({
					...this.memberDate,
					orderId: id,
					consumptionType: 3,
					orderNumber: id,
					remark: `前台吧台点餐会员结账：${this.ruleFrom.id}`
				}).then(res => {
					if (res.data.code === 0) {

					}
				})
			},
			//点击
			onSubmit(num) {
				if (num == 0) {
					//会员消费结账
					if (this.formInline.parlorId) {
						this.isConsumer = true;
						this.$nextTick(() => {
							this.$refs.ConsumerBox.consumptionType = 3
							this.$refs.ConsumerBox.price = this.totalPrice
							this.$refs.ConsumerBox.addMemberss = 1; //进来的放单信息
							this.$refs.ConsumerBox.Getpaymented();
						});

					} else {
						this.$message({
							type: 'error',
							message: '请先选择客厅'
						})
					}

				} else {
					if (this.repastParlorId) {
						// if (this.formInline.roomName || this.formInline.paymentId) {
							if (this.formInline.paymentName == '协议挂账') {
								if (!this.formInline.agreementUnitId) {
									this.$message({
										type: 'error',
										message: '请选择挂入协议单位'
									})
								} else {
									this.repast_ordersListed();
								}
							} else if (this.formInline.paymentName == '挂房账') {
								if (!this.formInline.agreementUnitOrderId) {
									this.$message({
										type: 'error',
										message: '请选择挂入房间'
									})
								} else {
									this.repast_ordersListed();
								}
							} else {
								this.repast_ordersListed();
							}

						// } else {
						// 	this.$message({
						// 		type: "error",
						// 		message: "请选择付款方式或挂入房间",
						// 	});
						// }
					} else {
						this.$message({
							type: 'error',
							message: '请先选择客厅'
						})
					}

				}


			},

			//点击分类侧边切换
			ChangeClassIfy(id) {
				if (this.repastParlorId) {
					this.li_index = id;
				} else {
					this.$message({
						type: 'error',
						message: '请先选择客厅'
					})
				}

			},
			//付款方式查看接口
			paymentListed() {
				paymentList().then((res) => {
					if (res.data.code == 0) {
						this.priceFrom = res.data.data.filter(
							(el) =>
							el.name != "微储值" &&
							el.name != "挂账" &&
							el.name != "餐饮挂账" &&
							el.name != "商超挂账" &&
							el.name != "预授权"
						);
						if (this.priceFrom.length > 14) {
							this.BoxHeight = "480px";
						} else {
							this.BoxHeight = "510px";
							this.PaymentHeight = "88px";
						}
					}
				});
			},
			//点击选择房号的第二行的图标
			offSelector() {
				this.isShow = true;
				this.isSelectRoom = false;
			},
			//点击双击选择弹出弹窗
			selectRoom() {
				this.isShow = false;
				this.isSelectRoom = true;
			},

			//点击确认触发事件
			submitButton() {
				this.$emit("barCounters", [false]);
			},
			//获取当前时间
			getNowDate() {
				var date = new Date();
				var sign2 = ":";
				var year = date.getFullYear(); // 年
				var month = date.getMonth() + 1; // 月
				var day = date.getDate(); // 日
				var hour = date.getHours(); // 时
				var minutes = date.getMinutes(); // 分
				var seconds = date.getSeconds(); //秒
				// 给一位数的数据前面加 “0”
				if (month >= 1 && month <= 9) {
					month = "0" + month;
				}
				if (day >= 0 && day <= 9) {
					day = "0" + day;
				}
				if (hour >= 0 && hour <= 9) {
					hour = "0" + hour;
				}
				if (minutes >= 0 && minutes <= 9) {
					minutes = "0" + minutes;
				}
				if (seconds >= 0 && seconds <= 9) {
					seconds = "0" + seconds;
				}
				this.settingTime = year + "-" + month + "-" + day;
				this.arrivalTimes = year + "-" + month + "-" + day;
			},
		},
	};
</script>

<style lang="scss" scoped>
	body .el-table::before {
		z-index: inherit;
	}

	.subButtonss {
		width: 100%;
		display: flex;
		justify-content: end;

		.subnitButton {
			width: 100px;
			height: 40px;
			background-color: #5cb5b8;
			color: #fff;
			padding: 0px 0px;
		}
	}

	.classNameGood {
		text-align: center;
		margin-bottom: 5px;
		margin-top: 5px;

		.inventoryNumber {}
	}

	.serchInputs {
		position: absolute;
		top: 0%;
		left: 6%;
	}

	.searchImg {
		width: 40px;
		height: 40px;
		// position: absolute;
		position: relative;
		// top: 8.5%;
		top: 2.5%;
		margin-left: 3px;
	}

	.el-dialog {
		.roomNumber {
			width: 100%;
			height: 500px;
			overflow-y: auto;
			margin-top: 10px;
			padding-left: 10px;
			display: flex;
			flex-wrap: wrap;
			padding-right: 10px;
			// justify-content: space-between;
			box-sizing: border-box;

			.card {
				width: 22%;
				height: 138px;
				background: #fafbfc;
				border-radius: 20px;
				display: flex;
				margin-right: 15px;
				padding-left: 17px;
				box-sizing: border-box;
				margin-bottom: 10px;

				flex-direction: column;

				span:nth-child(1) {
					width: 80px;
					height: 30px;
					color: #fff;
					background: #5cb5b8;
					border-radius: 15px;
					text-align: center;
					line-height: 30px;
					margin-top: 15px;
					margin-bottom: 20px;
				}

				span:nth-child(2) {
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #999999;
					margin-bottom: 8px;
				}

				p {
					text-align: left;
				}
			}
		}
	}

	.PrintBill {
		width: 100%;
		max-height: 660px;
		overflow-y: auto;
		// height: 630px;
		// overflow-y: auto;
		background-color: #fff;

		.sunButton {
			// height: 60px;
			width: 100%;
			// height: 50px;
			display: flex;
			align-items: center;
			justify-content: end;
			margin-top: 20px;

			.el-button {
				width: 90px;
				height: 40px;
				background-color: #5cb5b8;
				color: #fff;
				border-radius: 7px;
			}

			.el-button:nth-child(1) {
				background-color: #ffffff;
				color: #999999;
			}

			.el-button:nth-child(3) {
				background-color: #ffffff;
				color: #999999;
			}

			.demo-form-inline {
				width: 100%;
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				justify-content: flex-end;

				.el-form-item:last-child {
					margin-left: 10px;
					text-align: right;

					.subnitButton {
						width: 100px;
						height: 40px;
						background-color: #5cb5b8;
						color: #fff;
						padding: 0px 0px;
					}
				}
			}
		}

		.counter_main {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			// background: linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%);
			border-radius: 5px;

			//内容区css
			.barCounter_main {
				width: 100%;
				display: flex;

				//分类侧边栏
				.sidebar {
					width: 15%;
					text-align: center;
					max-height: 470px;
					overflow-y: auto;

					// background: linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%);
					ul {
						width: 90%;
						display: flex;
						margin-top: -10px;
						flex-direction: column;
						align-items: center;

						li {
							width: 100px;
							height: 40px;
							font-size: 13px;
							background-color: #eefefe;
							color: #5cb5b8;
							margin-bottom: 5px;
							line-height: 40px;
							text-align: center;
							border-radius: 5px;
						}

						li:nth-child(1) {
							margin-top: 10px;
						}

						li.active {
							background-color: #5cb5b8;
							color: #fff;
						}
					}
				}

				//商品展示区域css
				.commodity_main {
					width: 80%;
					max-height: 470px;
					padding: 0px 15px;
					padding-top: 50px;
					box-sizing: border-box;
					position: relative;
					overflow: hidden;

					// background: linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%);
					.commodity_box {
						width: 100%;
						height: 350px;
						overflow-y: auto;
						display: flex;
						flex-wrap: wrap;

						//单个卡片css
						.card {
							width: 20%;
							height: 170px;
							margin-left: 4%;
							padding-bottom: 5px;
							box-sizing: border-box;
							margin-top: 20px;
							position: relative;

							//库存没了的遮罩层
							.outPrice {
								position: absolute;
								top: 0;
								left: 0;
								right: 0;
								bottom: 0;
								// height: 170px;
								height: 120px;
								display: flex;
								background-color: rgba(133, 106, 106, 0.4);
								color: #fff;
								justify-content: center;
								align-items: center;
							}

							//商品图片
							.commodity_img {
								width: 100%;
								height: 120px;
							}

							.pitch_on {
								width: 20px;
								height: 20px;
								display: none;
								position: absolute;
								top: 10px;
								right: 5px;
								z-index: 100;
							}

							.pitch_on.active {
								display: block;
							}

							p {
								padding-left: 5px;
								box-sizing: border-box;
								font-size: 13px;
								text-align: center;
								width: 100%;
							}
						}
					}
				}

				//价格区域
				.merchandiseLedger {
					width: 40%;
					max-height: 470px;
					// overflow: hidden;
					// overflow-y: auto;
					padding-bottom: 60px;
					box-sizing: border-box;
					border-left: 2px solid #e9e9e9;
					position: relative;

					// background: linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%);
					::v-deep .el-table {
						height: 400px;
						overflow-y: auto;
						width: 100%;
						// background: linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%);
					}

					.aggregateAmount {
						width: 90%;
						height: 60px;
						line-height: 60px;
						display: flex;
						// padding-top: 10px;
						box-sizing: border-box;
						// position: absolute;
						// bottom: 0%;
						z-index: 10;
						justify-content: space-between;

						span {
							font-size: 13px;
						}

						span:nth-child(2) {
							color: #5cb5b8;
						}
					}
				}
			}

			.payment_method {
				width: 99%;
				// height: 80px;
				margin-top: 15px;
				background: #ffffff;
				border: 1px solid #ededed;
				border-radius: 8px;
				overflow-y: auto;
				position: relative;

				ul {
					width: 100%;
					display: flex;
					padding-top: 5px;
					box-sizing: border-box;
					flex-wrap: wrap;

					li {
						width: 87px;
						height: 32px;
						background: #ffffff;
						border: 1px solid #e8e8e8;
						border-radius: 8px;
						font-size: 13px;
						line-height: 32px;
						text-align: center;
						margin-right: 7px;
						margin-bottom: 5px;
						color: #999999;
					}

					li:first-child {
						margin-left: 13px;
					}

					li:nth-child(8) {
						width: 100px;
					}

					li.active {
						background-color: #5cb5b8;
						color: #fff;
					}
				}

				//按钮
			}
		}

		.selectRoom {
			width: 100%;
			height: 400px;

			.select_main {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				// background: linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%);
				border-radius: 5px;

				//头部标题
				.membership_title {
					width: 100%;
					height: 30px;
					display: flex;
					padding-top: 10px;
					align-items: center;
					box-sizing: border-box;
					margin-bottom: 10px;

					p {
						flex: 1;
						padding-left: 20px;
						box-sizing: border-box;
						text-align: left;
					}
				}

				.membership_title:nth-child(2) {
					// background: linear-gradient(90deg,#5CB5B8, #F6FEFE);

					p {
						color: #fff;
						padding-left: 40px;
						box-sizing: border-box;
					}
				}

				p {
					width: 100%;
					text-align: left;
					display: flex;
					color: #f4a509;
					padding-left: 50px;
					box-sizing: border-box;
					align-items: center;

					img {
						width: 20px;
						height: 18px;
						margin-right: 10px;
					}
				}
			}
		}
	}
</style>