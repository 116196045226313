import router from "../../router";
const loadView = (name) => {
  return () => import("_views/" + name);
};
export default {
  namespaced: true,
  state: {
    // 侧边导航路由菜单
    asideNavs: [],
    //路由
    Newrouter: [],
    allDATA: localStorage.getItem("DynamicPath")
      ? JSON.parse(localStorage.getItem("DynamicPath"))
      : [],
    Api: null,
    imgUrl: [
      require("_assets/icons/more.png"),
      require("_assets/icons/roomCard.png"),
      require("_assets/icons/make.png"),
      require("_assets/icons/member.png"),
      require("_assets/icons/statement.png"),
      require("_assets/imgs/yule.png"),
      require("_assets/icons/setting.png"),
      require("_assets/icons/meeting.png")
    ], //前台图标
    ImgUrl_active: [
      require("_assets/icons/more_active.png"),
      require("_assets/icons/roomCard_active.png"),
      require("_assets/icons/make_active.png"),
      require("_assets/icons/member_active.png"),
      require("_assets/icons/statement_active.png"),
      require("_assets/imgs/yuleActive.png"),
      require("_assets/icons/setting_active.png"),
      require("_assets/icons/meeting_active.png")
    ],
    CateringImgUrl: [
      //餐饮图标
    ],
    CateringImgUrl_active: [],
    routerUrl: [], //路径集合
    orderReserveId: null, //预订单id
  },
  mutations: {
    //改变Api的值
    SET_API(state, data) {
      state.Api = data;
    },
    SETORDERRESERVEID(state, data) {
      state.orderReserveId = data;
    },
    // 点击所选系统获取对应数据
    authNavs(state, data) {
      state.allDATA = data;
    },
    ASIDENAVS(state, allDATA) {
      //前台的路由
      state.Newrouter.splice(0);
      state.routerUrl.splice(0);
      var nums = 0;
      state.allDATA.forEach((item) => {
        if (item.name == "娱乐") {
          nums = 1;
        }

      });
      if (nums != 1) {
        state.imgUrl.splice(5, 1);
        state.ImgUrl_active.splice(5, 1);
      }
      nums = 0
      state.allDATA.forEach(item => {
        if (item.name == '房态') {
          nums = 2;
        }
      })
      if (nums != 2) {
        state.imgUrl.splice(0, 1);
        state.ImgUrl_active.splice(0, 1);
      }
      nums = 0
      state.allDATA.forEach(item => {
        if (item.name == '房单') {
          nums = 3;
        }
      })
      if (nums != 3) {
        state.imgUrl.splice(1, 1);
        state.ImgUrl_active.splice(1, 1);
      }
      nums = 0
      state.allDATA.forEach(item => {
        if (item.name == '预定') {
          nums = 4;
        }
      })
      if (nums != 4) {
        state.imgUrl.splice(2, 1);
        state.ImgUrl_active.splice(2, 1);
      }
      nums = 0
      state.allDATA.forEach(item => {
        if (item.name == '会员') {
          nums = 5;
        }
      })
      if (nums != 5) {
        state.imgUrl.splice(3, 1);
        state.ImgUrl_active.splice(3, 1);
      }
      nums = 0
      state.allDATA.forEach(item => {
        if (item.name == '统计') {
          nums = 6;
        }
      })
      if (nums != 6) {
        state.imgUrl.splice(4, 1);
        state.ImgUrl_active.splice(4, 1);
      }
      nums = 0
      state.allDATA.forEach(item => {
        if (item.name == '系统') {
          nums = 7;
        }
      })
      if (nums != 7) {
        state.imgUrl.splice(6, 1);
        state.ImgUrl_active.splice(6, 1);
      }
      state.allDATA.forEach((el, index) => {
        state.Newrouter.push({
          path: el.path == "/" ? el.children[0].path : el.path,
          name: el.name,
          meta: {
            icon: state.imgUrl[index],
            activeIcon: state.ImgUrl_active[index],
            noCache: true
          },

          // component:el.component == "LAYOUT"?el.children[0].component == "LAYOUT"?el.children[0].children[0].component:el.children[0].component:el.component
          component: el.component == "LAYOUT" ? (el.children[0].component == "LAYOUT" ? (el.children[0].children[0].component == 'LAYOUT' ? el.children[0].children[0].children[0].component : el.children[0].children[0].component) : el.children[0].component) : el.component
        });
        state.routerUrl.push(el.path == "/" ? el.children[0].path : el.path);
      });
    },
    //商超的后台
    SET_COMMERCIAL(state) {
      state.Newrouter.splice(0);
      state.routerUrl.splice(0);
      state.CateringImgUrl.splice(0)
      state.CateringImgUrl_active.splice(0)
      if (localStorage.getItem("ApplicationId") == 3) {
        //餐饮
        var canyingNums = 0
        state.allDATA.forEach(item => {
          state.CateringImgUrl.push(
            ...[
              require("_assets/imgs/diancan.png"),
              require("_assets/imgs/orderFrom.png"),
              require("_assets/imgs/Goods.png"),
              require("_assets/imgs/class.png"),
              require("_assets/imgs/canzhuo.png"),
              require("_assets/imgs/baobiao.png"),
            ]
          );

          state.CateringImgUrl_active.push(
            ...[
              require("_assets/imgs/1.png"),
              require("_assets/imgs/orderFrom_active.png"),
              require("_assets/imgs/Goods_active.png"),
              require("_assets/imgs/class_active.png"),
              require("_assets/imgs/canzhuo_active.png"),
              require("_assets/imgs/baobiao_active.png"),
            ]
          );
          if (item.name == "点餐") {
            canyingNums = 1;
          }

        })
        if (canyingNums != 1) {
          state.CateringImgUrl_active.splice(0, 1)
          state.CateringImgUrl.splice(0, 1)
        }
        canyingNums = 0
        state.allDATA.forEach(item => {
          if (item.name == "订单") {
            canyingNums = 2;
          }
        })
        if (canyingNums != 2) {
          state.CateringImgUrl_active.splice(1, 1)
          state.CateringImgUrl.splice(1, 1)
        }
        canyingNums = 0
        state.allDATA.forEach(item => {
          if (item.name == "商品") {
            canyingNums = 3;
          }
        })
        if (canyingNums != 3) {
          state.CateringImgUrl_active.splice(2, 1)
          state.CateringImgUrl.splice(2, 1)
        }
        canyingNums = 0
        state.allDATA.forEach(item => {
          if (item.name == "分类") {
            canyingNums = 4;
          }
        })
        if (canyingNums != 4) {
          state.CateringImgUrl_active.splice(3, 1)
          state.CateringImgUrl.splice(3, 1)
        }
        canyingNums = 0
        state.allDATA.forEach(item => {
          if (item.name == "餐桌") {
            canyingNums = 5;
          }
        })
        if (canyingNums != 5) {
          state.CateringImgUrl_active.splice(4, 1)
          state.CateringImgUrl.splice(4, 1)
        }
        canyingNums = 0
        state.allDATA.forEach(item => {
          if (item.name == "报表") {
            canyingNums = 6;
          }
        })
        if (canyingNums != 6) {
          state.CateringImgUrl_active.splice(5, 1)
          state.CateringImgUrl.splice(5, 1)
        }
        state.allDATA.forEach((it, index) => {
          state.Newrouter.push({
            path: it.path,
            name: it.name,
            meta: {
              icon: state.CateringImgUrl[index],
              activeIcon: state.CateringImgUrl_active[index],
              noCache: true
            },
            component: it.children ? it.children[0].component : it.component,
          });
          state.routerUrl.push(it.path);
        });
      } else if (localStorage.getItem("ApplicationId") == 4) {
        //商超
        state.CateringImgUrl.push(
          ...[
            require("_assets/imgs/linshou.png"),
            require("_assets/imgs/Goods.png"),
            require("_assets/imgs/orderFrom.png"),
            require("_assets/imgs/class.png"),
            require("_assets/imgs/baobiao.png"),
          ]
        );

        state.CateringImgUrl_active.push(
          ...[
            require("_assets/imgs/8.png"),
            require("_assets/imgs/Goods_active.png"),
            require("_assets/imgs/orderFrom_active.png"),
            require("_assets/imgs/class_active.png"),
            require("_assets/imgs/baobiao_active.png"),
          ]
        );
        var shangchaoNums = 0
        state.allDATA.forEach(item => {
          if (item.name == "零售") {
            shangchaoNums = 1;
          }
        })
        if (shangchaoNums != 1) {
          state.CateringImgUrl_active.splice(0, 1)
          state.CateringImgUrl.splice(0, 1)
        }
        shangchaoNums = 0
        state.allDATA.forEach(item => {
          if (item.name == "订单") {
            shangchaoNums = 2;
          }
        })
        if (shangchaoNums != 2) {
          state.CateringImgUrl_active.splice(2, 1)
          state.CateringImgUrl.splice(2, 1)
        }
        shangchaoNums = 0
        state.allDATA.forEach(item => {
          if (item.name == "商品") {
            shangchaoNums = 3;
          }
        })
        if (shangchaoNums != 3) {
          state.CateringImgUrl_active.splice(1, 1)
          state.CateringImgUrl.splice(1, 1)
        }
        shangchaoNums = 0
        state.allDATA.forEach(item => {
          if (item.name == "分类") {
            shangchaoNums = 4;
          }
        })
        if (shangchaoNums != 4) {
          state.CateringImgUrl_active.splice(3, 1)
          state.CateringImgUrl.splice(3, 1)
        }
        shangchaoNums = 0
        state.allDATA.forEach(item => {
          if (item.name == "报表") {
            shangchaoNums = 5;
          }
        })
        if (shangchaoNums != 5) {
          state.CateringImgUrl_active.splice(4, 1)
          state.CateringImgUrl.splice(4, 1)
        }
        console.log(state.allDATA);
        state.allDATA.forEach((it, index) => {
          console.log(it.children);
          state.Newrouter.push({
            path: it.path,
            name: it.name,
            meta: {
              icon: state.CateringImgUrl[index],
              activeIcon: state.CateringImgUrl_active[index],
              noCache: true
            },

            // component: it.component == 'LAYOUT'?it.children[0].component:it.component,
            component: it.children ? it.children[0].component : it.component,

          });
          state.routerUrl.push(it.path);
        });
      }
    },
    //品牌端的后台
    SETBRANDSIDE(state) {
      state.Newrouter.splice(0);
      state.routerUrl.splice(0);
      if (localStorage.getItem("ApplicationId") == "366448864552551303") {
        state.allDATA.forEach((el) => {
          if (el.children) {
            el.children.forEach((it) => {
              state.Newrouter.push({
                path: it.path,
                name: it.name,
                meta: {
                  // icon: state.CateringImgUrl[index],
                  // activeIcon: state.CateringImgUrl_active[index],
                  title: el.name,
                  noCache: true
                },
                // component: it.component,
                component: it.children ? it.children[0].component : it.component,
              });
              state.routerUrl.push(it.path);
            });
          }
        });
      }
    },
    INIT_AUTH_ROUTES(state, Newrouter) {
      // 动态添加路由
      /*
        动态添加路由
        router.addRoute('admin', 动态路由)
        {
          path: '/dashBoard',
          name: 'dashBoard',
          component: 'DashBoard'
        }
        component存储的是 字符串 路由组件名字
        变成
        {
          path: '/dashBoard',
          name: 'dashBoard',
          component: () => import('DashBoard')
        }
      */

      console.log(Newrouter);
      Newrouter.forEach((route) => {
        route.component = loadView(route.component);
        // 动态添加到路由上 作为admin二级路由
        router.addRoute("admin", route);
      });
      // 动态添加路由 404不能作为基础路由定义 必须作为动态路由添加
      router.addRoute({
        path: "*",
        component: () => import("_views/NotFound"),
      });
      router.addRoute("admin", {
        path: "/SettlementbyAgreement",
        component: () => import("_views/SettlementbyAgreement"),
      });
    },
  },
};
