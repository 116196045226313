<template>
  <!-- 财务信息 -->
  <div class="FinanceMessage" ref="FinanceMessage">
    <!-- 侧边栏部分 -->
    <div class="sidebar" v-if="currentRoom.orderState">
      <ul>
        <li
          v-if="roomArrActive.includes('yushouquan')"
          :class="{ activate: tab_sideba == 1 }"
          @click="switchoverTabs(1)"
        >
          预授权
        </li>
        <li
          v-if="roomArrActive.includes('chongjianfangfei') ? true : false"
          :class="{ activate: tab_sideba == 4 }"
          @click="switchoverTabs(4)"
        >
          冲减消费
        </li>
        <li
          v-if="roomArrActive.includes('huiyuankaxiaofei') ? true : false"
          :class="{ activate: tab_sideba == 5 }"
          @click="switchoverTabs(5)"
        >
          会员卡消费
        </li>
        <li
          v-if="roomArrActive.includes('ruzhang')"
          :class="{ activate: tab_sideba == 2 }"
          @click="switchoverTabs(2)"
        >
          入账
        </li>
        <li
          v-if="
            roomArrActive.includes('jiezhangtuifang')
              ? currentRoom.connectRoomId
                ? false
                : true
              : false
          "
          :class="{ activate: tab_sideba == 3 }"
          @click="switchoverTabs(3)"
        >
          结帐退房
        </li>
        <li
          v-if="
            roomArrActive.includes('lianfangjiezhang')
              ? currentRoom.connectRoomId
                ? true
                : false
              : false
          "
          :class="{ activate: tab_sideba == 6 }"
          @click="switchoverTabs(6)"
        >
          联房结账
        </li>
        <li
          v-if="roomArrActive.includes('printChinkIn')"
          :class="{ activate: tab_sideba == 7 }"
          @click="
            () => {
              tab_sideba = 7;
              $refs.printRef?.open(1,currentRoom);
            }
          "
        >
          打印入住单
        </li>
      </ul>
    </div>
    <div class="sidebar" v-if="currentRoom.orderState == 0">
      <ul>
		  <li
		    v-if="roomArrActive.includes('ruzhang')?(currentRoom.connectRoomId&&notChecked? true: false): false"
		    :class="{ activate: tab_sideba == 2 }"
		    @click="switchoverTabs(2)"
		  >
		    入账
		  </li>
		<li
		  v-if="
		    roomArrActive.includes('jiezhangtuifang')? (currentRoom.connectRoomId&&notChecked? true: false): false
		  "
		  :class="{ activate: tab_sideba == 3 }"
		  @click="switchoverTabs(3)"
		>
		  结帐退房
		</li>
		<li
		    v-if="roomArrActive.includes('printChinkIn')"
		    :class="{ activate: tab_sideba == 7 }"
		    @click="
		      () => {
		        tab_sideba = 7;
		        $refs.printRef?.open(1,currentRoom);
		      }
		    "
		  >
		    打印入住单
		</li>
        <li
          v-if="roomArrActive.includes('printCheckout')"
          :class="{ activate: tab_sideba == 8 }"
          @click="
            () => {
              tab_sideba = 8;
              $refs.printRef?.open(2,currentRoom);
            }
          "
        >
          打印结账单
        </li>
      </ul>
    </div>
    <!-- 侧边栏部分 -->
    <div class="finance_main" ref="roomlist" :style="{ width: `${offWidth}` }">
      <div
        class="remoteRoom"
        ref="remoteRoom"
        v-if="currentRoom.connectRoomId ? true : false"
      >
        <!-- 标题部分 -->
        <div class="order_title">
          <div class="left_navdata">
            <span>联房信息</span> &nbsp;
            <span
              >账单号：{{ PlateFrom[0].connectRoomId }} ,&nbsp; 共{{
                PlateFrom.length
              }}间,&nbsp; 联房余额：<span
                :style="{
                  color: balancePrice < 0 ? 'red' : 'rgb(97, 240, 14)',
                }"
                >￥{{ balancePrice }}</span
              >
            </span>
          </div>

          <div class="title_icon">
            <el-button
              v-if="roomArrActive.includes('lianfangdanfangjiezhang')"
              @click="oneCheckOut(0)"
              >单房退房</el-button
            >
            <el-button
              v-if="roomArrActive.includes('lianfangpiliangtuifang')"
              @click="oneCheckOut(1)"
              >批量退房</el-button
            >
            <el-button
              v-if="roomArrActive.includes('chakanliangfangzhangdan')"
              @click="oneCheckOut(2)"
              >查看联房账单</el-button
            >
          </div>
        </div>
        <!-- 标题部分 -->
        <!-- <el-row class="cardRoom"> -->
        <!-- 单个信息卡片 -->
        <!-- <div
            :class="{ card: true, actived: Dbl_index == el.id }"
            @dblclick="searchButton(el)"
            @click="cardRoom(el)"
            v-for="el in PlateFrom"
            :key="el.id"
          >
            <p>{{ el.roomName }} &nbsp;-&nbsp; {{ el.houseTypeName }}</p>
            <p>{{ el.name }}</p>
            <ul class="UlPrice">
              <li>消费:{{ el.consumptionAmount }}</li>
              <li>付款:{{ el.paymentAmount }}</li>
            </ul> -->
        <!-- <div class="priceBOX">
								<p class="priceSize">
									消费{{el.consumptionAmount}}</p>
							 <p class="priceSize">付款 {{ el.paymentAmount }}</p>							</div> -->
        <!-- <p
              class="stateUse"
              :style="{
                backgroundColor: el.orderState ? 'rgb(194, 245, 194)' : 'red',
              }"
            >
              <span></span>
              <span>{{ el.orderState ? "在住" : "退房" }}</span> -->
        <!-- <div class="searchOrder">查看</div> -->
        <!-- </p>
            <div class="radioBox">{{ el.isMasterRoom ? "主" : "副" }}</div>
            <img
              class="radioImg"
              :class="{ active: li_Img.includes(el.id) }"
              src="../../../../../../assets/icons/选择-勾选.png"
              alt=""
            />
            <img
              :class="{ DataActive: true, activeted: Dbl_index == el.id }"
              src="../../../../../../assets/icons/选择.png"
              alt=""
            />
          </div>
		  
		  
        </el-row> -->

        <div class="newroomcardbox">
          <div
            class="newcard"
            @dblclick="searchButton(el)"
            @click="cardRoom(el)"
            v-for="el in PlateFrom"
            :key="el.id"
          >
            <div class="newcard-heard">
              <div class="newcard-iconbox">
                {{ el.isMasterRoom ? "主" : "副" }}
              </div>
              <div>{{ el.roomName }} &nbsp;-&nbsp; {{ el.houseTypeName }}</div>
            </div>
            <div class="newmargin">
				<!-- {{ el.name }} -->
				<!-- {{ el.id}}<br/>
				{{Dbl_index}} -->
              {{ el.guestName }}<span v-if="Dbl_index == el.id">(当前房间)</span>
            </div>
            <div class="newmargin">
              消费：{{ el.consumptionAmount || 0 }} 付款：{{
                el.paymentAmount || 0
              }}
            </div>
            <div class="mewcard-footer">
              <div class="dian-box">
                <div class="dian"></div>
                <div>{{ el.orderState ? "在住" : "退房" }}</div>
              </div>
              <div style="margin-right: 10px">
                <img
                  class="newimg"
                  :style="li_Img.includes(el.id) ? '' : 'display:none;'"
                  src="../../../../../../assets/icons/选择-勾选.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="table_pull"
        ref="tableBox"
        :style="{ height: tableBoxHeight }"
      >
        <div class="finance_price" ref="priceTitle">
          <ul>
            <li>
              付款金额：
              <span
                v-if="
                  payment ? (String(payment).length > 10 ? false : true) : true
                "
                style="color: rgb(97, 240, 14)"
                >￥
                {{
                  payment
                    ? payment != 0
                      ? parseFloat(payment).toFixed(2)
                      : 0
                    : "0"
                }}</span
              >
              <el-tooltip
                v-else
                class="item"
                effect="light"
                placement="top-start"
              >
                <div slot="content">￥{{ payment }}</div>
                <span>
                  {{
                    payment
                      ? String(payment).length > 10
                        ? "￥" +
                          String(payment).slice(
                            0,
                            String(consumption - payment)
                              .split("")
                              .indexOf(".") > 10
                              ? String(consumption - payment)
                                  .split("")
                                  .indexOf(",") - 2
                              : 10
                          )
                        : "￥" + String(payment)
                      : null
                  }}
                </span>
              </el-tooltip>
            </li>
            <div></div>
            <li>
              消费金额：

              <span
                v-if="
                  consumption ? (consumption.length > 10 ? false : true) : true
                "
                style="color: red"
                >￥{{
                  consumption ? parseFloat(consumption).toFixed(2) : 0
                }}</span
              >
              <el-tooltip
                v-else
                class="item"
                effect="light"
                placement="top-start"
              >
                <div slot="content" style="color: red">￥{{ consumption }}</div>
                <span>
                  {{
                    consumption
                      ? consumption.length > 10
                        ? "￥" +
                          consumption.slice(
                            0,
                            String(consumption - payment)
                              .split("")
                              .indexOf(".") > 10
                              ? String(consumption - payment)
                                  .split("")
                                  .indexOf(",") - 2
                              : 10
                          )
                        : "￥" + consumption
                      : null
                  }}
                </span>
              </el-tooltip>
            </li>
            <div></div>
            <li>
              平账金额：
              <span
                v-if="
                  String(parseFloat(consumption) - parseFloat(payment))
                    ? String(parseFloat(consumption) - parseFloat(payment))
                        .length < 15
                      ? true
                      : false
                    : false
                "
                >{{
                  currentRoom.orderState
                    ? parseFloat(consumption) - parseFloat(payment) < 0
                      ? "￥-" +
                        parseFloat(
                          String(consumption - payment).slice(1)
                        ).toFixed(2)
                      : "￥" +
                        parseFloat(
                          String(
                            parseFloat(consumption) - parseFloat(payment)
                          ).slice(0, 14)
                        ).toFixed(2)
                    : 0
                }}</span
              >

              <el-tooltip
                v-else
                class="item"
                effect="light"
                placement="top-start"
              >
                <div slot="content" style="color: red">
                  {{
                    parseFloat(consumption) - parseFloat(payment) < 0
                      ? "￥-" +
                        parseFloat(
                          String(consumption - payment).slice(1)
                        ).toFixed(2)
                      : "￥" +
                        parseFloat(
                          String(parseFloat(consumption) - parseFloat(payment))
                        ).toFixed(2)
                  }}
                </div>
                <span>
                  {{
                    parseFloat(consumption) - parseFloat(payment) < 0
                      ? "￥-" +
                        String(consumption - payment)
                          .slice(1)
                          .slice(
                            0,
                            String(consumption - payment)
                              .split("")
                              .indexOf(",") > 5
                              ? String(consumption - payment)
                                  .split("")
                                  .indexOf(",") - 2
                              : 4
                          )
                      : "￥" +
                        String(
                          parseFloat(consumption) - parseFloat(payment)
                        ).slice(
                          0,
                          String(consumption - payment)
                            .split("")
                            .indexOf(",") > 5
                            ? String(consumption - payment)
                                .split("")
                                .indexOf(",") - 2
                            : 4
                        )
                  }}
                </span>
              </el-tooltip>
            </li>
            <div></div>
            <li>
              消费合计：
              <span>{{ consumptiontotal || 0 }}</span>
            </li>
            <div></div>
            <li>
              付款合计：
              <span>{{ paymenttotal || 0 }}</span>
            </li>
          </ul>
        </div>

        <el-table
          :cell-style="cellStyle"
          :height="tableheight"
          border
          style="width: 100%"
          :data="tableData"
          @row-dblclick="rowDblclick"
          :header-cell-style="{ background: '#F7F7F7', color: '#4C4C4C' }"
        >
          <el-table-column
            prop="projectName"
            label="项目名称"
            align="center"
            style="position: relative"
          >
            <template slot-scope="scope">
              <!-- v-if="!scope.row.authOver"  -->
              <p
                v-if="scope.row.state ? false : true"
                style="
                  width: 973px;
                  color: gray;
                  height: 1px;
                  background-color: gray;
                  position: absolute;
                  top: 50%;
                  z-index: 100;
                "
              ></p>
              <!-- {{scope.row.authOver?'预授权完成':scope.row.preAuth?'预授权':scope.row.projectName}} -->
              {{ scope.row.preAuth ? "预授权" : scope.row.projectName }}
            </template>
          </el-table-column>
          <el-table-column label="消费" align="center">
            <template slot-scope="scope">
              {{ scope.row.itemType == "1" ? scope.row.price : "" }}
            </template>
          </el-table-column>
          <el-table-column label="付款" align="center">
            <template slot-scope="scope">
              {{ scope.row.itemType == "0" ? scope.row.price : "" }}
            </template>
          </el-table-column>
          <el-table-column prop="classes" label="班次" align="center">
          </el-table-column>
          <el-table-column prop="operator" label="操作人" align="center">
            <template slot-scope="scope">
              <span>{{
                scope.row.operator
                  ? scope.row.operator == 0
                    ? "微信"
                    : scope.row.operator
                  : ""
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="createdTime"
            label="操作时间"
            align="center"
            width="200"
          >
          </el-table-column>
          <!-- <el-table-column prop="voucherNo" label="凭证号" align="center">
          </el-table-column> -->
          <el-table-column label="备注" align="center" width="200">
            <template slot-scope="scope">
              <span> {{ scope.row.remarks }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="是否结算"
            align="center"
            v-if="currentRoom.orderState"
          >
            <template slot-scope="scope">
              {{ scope.row.state === true ? "未结算" : "结算" }}
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            v-if="!currentRoom.orderState"
          >
            <template slot-scope="scope">
              <span
                @click="putPrice(scope.row)"
                style="color: #5cb5b8; cursor: pointer"
                >编辑</span
              >
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage1"
          :page-size="size"
          layout="total, prev, pager, next"
          :total="totals"
          style="text-align: right"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="财务编辑"
      :visible.sync="isUpdatePricesBox"
      width="400px"
      class="UpdatePricesBox"
      append-to-body
      destroy-on-close
      :before-close="handleClose"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="update_prices"
      >
        <el-form-item label="入账项目" prop="paymentId">
          <el-select
            v-if="ruleForm.itemType == 0"
            v-model="ruleForm.paymentId"
            placeholder="请选择入账项目"
          >
            <!-- payment: [],//付款方式的集合
      bargainingUnit: [],//消费项目集合 -->
            <el-option
              v-for="el in payments"
              :key="el.id"
              :label="el.name"
              :value="el.id"
            ></el-option>
          </el-select>
          <el-select
            v-if="ruleForm.itemType == 1"
            v-model="ruleForm.paymentId"
            placeholder="请选择入账项目"
          >
            <!-- payment: [],//付款方式的集合
      bargainingUnit: [],//消费项目集合 -->
            <el-option
              v-for="el in bargainingUnit"
              :key="el.id"
              :label="el.name"
              :value="el.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button class="ButtonSubmitss" @click="updateDatess">
            确定
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      title="新增预授权"
      :visible.sync="dialogVisible"
      width="600px"
      class="addMember"
      append-to-body
      destroy-on-close
      :before-close="handleClose"
    >
      <pre-authorization
        ref="AuthorizationBox"
        @offPre="offPre"
      ></pre-authorization>
    </el-dialog>
    <el-dialog
      title="预授权完成"
      :visible.sync="isAuthorization"
      width="600px"
      class="addMember"
      append-to-body
      destroy-on-close
      :before-close="handleClose"
    >
      <authorization-pre
        ref="AUTHBOX"
        :rulePrice="rulePrice"
        @isAuthOver="isAuthOver"
      ></authorization-pre>
    </el-dialog>

    <el-dialog
      title="添加入账"
      :visible.sync="isEnter"
      width="500px"
      class="addMember"
      append-to-body
      destroy-on-close
      :before-close="handleClose"
    >
      <addenter ref="EnterBox" @AddenterOff="AddenterOff"></addenter>
    </el-dialog>
    <el-dialog
      title="冲减消费"
      :visible.sync="isDeduct"
      width="600px"
      class="addMember"
      append-to-body
      destroy-on-close
      :before-close="handleClose"
    >
      <deduct-room
        ref="DeductRoomBox"
        @DeductRoomButton="DeductRoomButton"
      ></deduct-room>
    </el-dialog>
    <el-dialog
      title="会员卡消费"
      :visible.sync="isConsumer"
      width="600px"
      class="addMember"
      append-to-body
      destroy-on-close
      :before-close="handleClose"
    >
      <member-consumer
        ref="ConsumerBox"
        @MemberPrice="MemberPrice"
      ></member-consumer>
    </el-dialog>
    <el-dialog
      title="单房退房"
      :visible.sync="isOneCHECKoUT"
      width="600px"
      class="addMember"
      append-to-body
      destroy-on-close
      :before-close="handleClosess"
    >
      <single-room
        ref="SingleRoomBox"
        @SingleCHANGE="SingleCHANGE"
      ></single-room>
    </el-dialog>
    <el-dialog
      title="批量退房"
      :visible.sync="isBatchRoom"
      width="600px"
      class="addMember"
      append-to-body
      destroy-on-close
      :before-close="handleClosess"
    >
      <batch-room ref="BatchRoomBox" @OutListed="OutListed"> </batch-room>
    </el-dialog>
    <el-dialog
      title="联房账单"
      :visible.sync="isBillingDetails"
      width="1100px"
      class="BillingDetailsBox"
      append-to-body
      destroy-on-close
      :before-close="handleClose"
    >
      <room-billing ref="RoomBillingBox"></room-billing>
    </el-dialog>
    <el-dialog
      title="结账"
      :visible.sync="isSetting"
      width="700px"
      class="AccountBoxSs"
      append-to-body
      destroy-on-close
      :before-close="handleClose"
    >
      <settle-accounts ref="AccountsBox" @onSettle="onSettle"></settle-accounts>
    </el-dialog>

    <el-dialog
      title="联房结账"
      :visible.sync="isPeerSetting"
      width="700px"
      class="AccountBoxSs"
      append-to-body
      destroy-on-close
      :before-close="handleClose"
    >
      <peer-settleaccounts
        ref="PeerAccountsBox"
        @onSettle="onSettle"
      ></peer-settleaccounts>
    </el-dialog>
    <outprice-box
      v-if="outPrices"
      ref="outPricesBox"
      :priceFrom="priceFrom"
      @outpriceBox="outpriceBox"
    ></outprice-box>
    <PrintOrderModal
      ref="printRef"
      :roomId="roomId"
      :connectRoomId="connectRoomId"
    />
  </div>
</template>

<script>
import {
  ListFinance,
  UpdateFinance,
  AddFinance,
  financeManagementConnectIist,
  financeManagementSaveList,
  EditdataStatistics,
  orderFromList,
  paymentList,
} from "_api/orderFrom";
import {
  MemberTopUp,
  memberChangemtRecord,
  MembershipDetails,
} from "_api/member";
import { UnitBargainingLook } from "_api/systemSetting";
import Addenter from "./Addenter.vue";
import PreAuthorization from "./PreAuthorization.vue";
import SettleAccounts from "./SettleAccounts.vue";
import AuthorizationPre from "./authorizationPre.vue";
import OutpriceBox from "./outpriceBox.vue";
import DeductRoom from "./DeductRoom.vue";
import MemberConsumer from "./MemberConsumer.vue";
import SingleRoom from "./SingleRoom.vue";
import BatchRoom from "./BatchRoom.vue";
import RoomBilling from "./RoomBilling.vue";
import PeerSettleaccounts from "./PeerSettleaccounts.vue";
import PrintOrderModal from "./PrintOrderModal.vue";

export default {
  name: "FinanceMessage",
  components: {
    PrintOrderModal,
    PreAuthorization,
    SettleAccounts,
    Addenter,
    AuthorizationPre,
    OutpriceBox,
    DeductRoom,
    MemberConsumer,
    SingleRoom,
    BatchRoom,
    RoomBilling,
    PeerSettleaccounts,
  },
  props: ["roomId", "connectRoomId"],
  data() {
    return {
      tab_sideba: 0,
      consume: 0, //总消费金额
      currentPage1: 1, //当前页
      size: 1000,
      totals: 0,
      arrivalTimes: "", //当前时间
      offWidth: this.$store.state.user.orderFrom.orderState ? "85%" : "100%",
      isAuthorization: false,
      tableData: [],
      ruleForm: {},
      isUpdatePricesBox: false,
      dialogVisible: false, //控制新增预授权
      isEnter: false, //控制入账
      isSetting: false, //控制结账
      isDeduct: false, //冲减房费
      isOneCHECKoUT: false, //单房退房
      isConsumer: false, //会员卡消费
      isBatchRoom: false, //批量退房
      isPeerSetting: false, //联房结账
      isROOMS: false, //是否是联房
      li_Imgs: {},
      li_Img: [],
      Li_orderId: "", //激活财务的房单id
      isBillingDetails: false, //联房账单
      rulePrice: {},
      priceOut: "",
      PlateFrom: [], //联房的数据
      row: null, //消费退充需要的数据
      outPrices: false,
      priceFrom: {
        price: null,
        name: null,
        type: null,
      },
      priceUpdate: {},
      CheckRooms: [], //点击选中的房间
      arrTime: "",
      tableDataFrom: [],
      name: null,
      type: null,
      Dbl_index: "",
      payments: [], //付款方式的集合
      bargainingUnit: [], //消费项目集合
      rules: {
        paymentId: [
          { required: true, message: "请选择入账项目", trigger: "blur" },
        ],
      },
      tableheight: 0, //表格高度
      tableBoxHeight: 0,
      MasterArr: {}, //主房信息
      paymentPrice: 0, //付款金额
      balancePrice: null,
      totalPriceed: 0, //消费金额
      RoomIndex: [], //退房id
      currentRoom: {}, //点击进来的房间信息
      roomArrActive: localStorage.getItem("permission")
        ? JSON.parse(localStorage.getItem("permission"))
        : [], //功能权限数据
      AddPriceFrom: [], //批量新增财务数据
	  notChecked:false,//未退房房间ids
    };
  },
  created() {
    // this.ListFinanceed();
    console.log(this.currentRoom);
  },
  mounted() {
    window.addEventListener("resize", this.autoHeight());
    this.autoHeight();
  },
  methods: {
    //高度自适应
    autoHeight() {
      this.setHeight();

      // 初始化的时候，设置高度
      // 改变浏览器窗口大小的时候，设置高度
      window.addEventListener("resize", () => {
        this.setHeight();
      });
    },
    // 设置高度
    setHeight() {
      this.$nextTick(() => {
        if (this.$refs.FinanceMessage) {
          if (this.$refs.remoteRoom) {
            setTimeout(() => {
              let Allheight = this.$refs.FinanceMessage.offsetHeight; //总高度
              // let tableHei = this.$refs.tableBox.offsetHeight;//单房列表容器高度
              let remoteRoomHeight = this.$refs.remoteRoom.offsetHeight; //联房展示区域高度
              let priceHeight = this.$refs.priceTitle.offsetHeight;
              this.tableheight =
                parseFloat(Allheight) -
                parseFloat(priceHeight) -
                parseFloat(remoteRoomHeight) -
                60 +
                "px"; // 这里的X就是顶部栏的高度
            }, 300);
          } else {
            setTimeout(() => {
              let Allheight = this.$refs.FinanceMessage.offsetHeight; //总高度
              // let tableHei = this.$refs.tableBox.offsetHeight;//单房列表容器高度
              let priceHeight = this.$refs.priceTitle.offsetHeight;
              this.tableheight =
                parseFloat(Allheight) - parseFloat(priceHeight) - 100 + "px"; // 这里的X就是顶部栏的高度
            }, 300);
          }

          // console.log('表单元素高度',height);
          // console.log('价格元素高度',priceHeight);

          // if(this.currentRoom.connectRoomId) {
          //   this.tableheight = height - 300 + 'px'; // 这里的X就是顶部栏的高度
          // }else{
          //   this.tableheight = height - 100 + 'px'; // 这里的X就是顶部栏的高度
          // }

          // console.log('高度',this.tableheight)
          // document.getElementsByClassName("el-table__fixed-body-wrapper").style.max-height = "440px";
        }
      });
    },
    SingleCHANGE() {
      this.isOneCHECKoUT = false;
      this.li_Img.splice(0);
      this.li_Imgs = {};
      // this.financeManagementConnectIisted();
      this.orderFromLists({ connectRoomId: this.PlateFrom[0].connectRoomId });
    },
    //点击列表的编辑触发
    putPrice(row) {
      this.isUpdatePricesBox = true;
      this.ruleForm = row;
      this.paymentListed();
      this.UnitBargainingLooked();
    },
    //财务编辑的弹窗的确定事件
    updateDatess() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.ruleForm.itemType == 0) {
            this.payments.forEach((el) => {
              console.log(el);
              console.log(this.ruleForm.paymentId);
              if (el.id == this.ruleForm.paymentId) {
                console.log(el.id);
                this.ruleForm.projectName = el.name;
                console.log(this.ruleForm);
                this.UpdateFinanceedss();
                console.log("312312312312");
              }
            });
          } else if (this.ruleForm.itemType == 1) {
            this.bargainingUnit.forEach((el) => {
              console.log(el);
              console.log(this.ruleForm.paymentId);
              if (el.id == this.ruleForm.paymentId) {
                console.log(el.id);
                this.ruleForm.projectName = el.name;
                this.UpdateFinanceedss();
              }
            });
          }
        } else {
          this.$message({
            type: "error",
            message: "请选择入账项目",
          });
          return false;
        }
      });
    },
    //财务管理表之更新接口
    UpdateFinanceedss() {
      console.log(this.ruleForm);
      const params = {
        ...this.ruleForm,
      };
      console.log(params);
      UpdateFinance(params)
        .then((res) => {
          if (res.data.code === 0) {
            this.$message({
              type: "success",
              message: "编辑成功",
            });
            this.isUpdatePricesBox = false;
            this.ListFinanceed({ orderId: this.Dbl_index });
          }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err.errorMsg,
          });
        });
    },
    //付款方式的查看
    paymentListed() {
      paymentList().then((res) => {
        if (res.data.code === 0) {
          this.payments = res.data.data.filter(
            (el) => el.name != "协议挂账" && el.name != "挂账"
          );
          console.log(this.payment);
        } else {
          this.$message({
            type: "error",
            message: "请求异常",
            onClose: () => {},
          });
        }
      });
    },

    //消费项目的请求
    UnitBargainingLooked() {
      UnitBargainingLook().then((res) => {
        if (res.data.code === 0) {
          this.bargainingUnit = res.data.data;
        } else {
          this.$message({
            type: "error",
            message: "请求异常",
            onClose: () => {},
          });
        }
      });
    },
    //会员卡消费
    MemberPrice() {
      this.isConsumer = false;
      this.ListFinanceed({ orderId: this.Dbl_index });
    },
    //批量退房的自定义事件
    OutListed() {
      this.isBatchRoom = false;
      this.li_Img.splice(0);
      this.li_Imgs = {};
      this.orderFromLists({ connectRoomId: this.PlateFrom[0].connectRoomId });
    },
    handleClosess(done) {
      done();
      this.li_Img.splice(0);
      this.li_Imgs = {};
    },
    //单个退房
    oneCheckOut(num) {
      if (num == 0) {
        //单房退房
        this.getNowDates();
		console.log('选中房间',this.CheckRooms)
        if (this.CheckRooms.length > 1) {
          this.$message({
            type: "error",
            message: "单房退房只能退一间房",
          });
        } else {
          this.getRoomId();
          var arr = this.PlateFrom.filter((el) => el.orderState);
		  
          if (arr.length > 1) {
            if (this.RoomIndex.length == 0) {
              this.$message({
                type: "error",
                message: "请选择要退的房间",
              });
            } else {
              this.PlateFrom.forEach((item) => {
                if (item.isMasterRoom) {
                  this.CheckRooms.forEach((ic, indexs) => {
                    this.$set(this.CheckRooms, indexs, {
                      ...ic,
                      matchName: item.name,
                    });
                  });
                }
              });
              // console.log(this.MasterArr);

              // console.log(this.CheckRooms);
              this.isOneCHECKoUT = true;
              this.$nextTick(() => {
                var arrsFroms = this.PlateFrom.filter(
                  (ts) => !ts.isSelect && ts.orderState
                );
                var matersDAte = this.CheckRooms.filter(
                  (is) => is.isMasterRoom
                );
                if (matersDAte.length > 0) {
                  //判断选中的里面有没有主房
                  var arr = {
                    connectRoomId: this.CheckRooms[0].connectRoomId,
                    outRoomTime: this.arrivalTimes, //退房时间
                    isMasterRoom: this.CheckRooms[0].isMasterRoom, ////是否为连房的主房（1为是，0为否）
                    settleAccountsClasses: this.arrivalTimes + "/" + localStorage.getItem("classes"),
                    orderIdList: this.li_Imgs, //退房id
                    orderId: arrsFroms[0].id, //要财务转入的房单id
                  };
                  this.$refs.SingleRoomBox.dataPlate = arr;
                  this.$refs.SingleRoomBox.form.roomName = arrsFroms[0].roomName;
                  this.$refs.SingleRoomBox.form.projectName = this.CheckRooms[0].settlementType;
                  this.$refs.SingleRoomBox.PlateFrom = this.CheckRooms;
                  this.$refs.SingleRoomBox.consumptionListed();
                  this.$refs.SingleRoomBox.ListFinanceed();
                } else {
                  var arrDate = arrsFroms.filter((el) => el.isMasterRoom);
                  var arr = {
                    connectRoomId: this.CheckRooms[0].connectRoomId,
                    outRoomTime: this.arrivalTimes, //退房时间
                    isMasterRoom: this.CheckRooms[0].isMasterRoom, ////是否为连房的主房（1为是，0为否）
                    settleAccountsClasses: this.arrivalTimes + "/" + localStorage.getItem("classes"),
                    orderIdList: this.li_Imgs, //退房id
                    orderId: arrDate[0].id, //要财务转入的房单id
                  };
                  this.$refs.SingleRoomBox.dataPlate = arr;
                  this.$refs.SingleRoomBox.form.roomName = arrDate[0].roomName;
                  this.$refs.SingleRoomBox.form.projectName = this.CheckRooms[0].settlementType; 
                  this.$refs.SingleRoomBox.PlateFrom = this.CheckRooms;
                  this.$refs.SingleRoomBox.consumptionListed();
                  this.$refs.SingleRoomBox.ListFinanceed();
                }

                // this.$refs.SingleRoomBox.computedPrice()
              });
            }
          }
        }
      } else if (num == 1) {
        //批量退房
        this.getRoomId();

        if (this.RoomIndex.length == 0) {
          if (this.CheckRooms.length == 0) {
            this.$message({
              type: "error",
              message: "请选择要退的房间",
            });
          } else {
            console.log("----------------------------------------");
            // this.$message({
            //   type: "error",
            //   message: "退房不能全退，最少留一间",
            // });
          }
        } else {
          var arr = this.PlateFrom.filter((el) => el.orderState);
          if (arr.length != 1) {
            this.isBatchRoom = true;
            this.$nextTick(() => {
              this.getNowDates();
              var arrsFroms = this.PlateFrom.filter(
                (ts) => !ts.isSelect && ts.orderState
              );
              var matersDAte = this.CheckRooms.filter((is) => is.isMasterRoom);
              if (matersDAte.length > 0) {
                var arr = {
                  connectRoomId: this.CheckRooms[0].connectRoomId,
                  outRoomTime: this.arrivalTimes, //退房时间
                  isMasterRoom: true, ////是否为连房的主房（1为是，0为否）
                  settleAccountsClasses: this.arrivalTimes + "/" + localStorage.getItem("classes"),
                  orderIdList: this.li_Imgs, //退房id
                  orderId: arrsFroms[0].id, //要财务转入的房单id
                };
				console.log('联防数据1',arr);
                this.$refs.BatchRoomBox.dataPlate = arr;
                this.$refs.BatchRoomBox.form.roomName = arrsFroms[0].roomName;
                this.$refs.BatchRoomBox.PlateFrom = this.CheckRooms;
                this.$refs.BatchRoomBox.financeManagementConnectIisted(
                  this.CheckRooms
                );
                this.$refs.BatchRoomBox.consumptionListed();
              } else {
                var materData = arrsFroms.filter((io) => io.isMasterRoom);
                var arr = {
                  connectRoomId: this.CheckRooms[0].connectRoomId,
                  outRoomTime: this.arrivalTimes, //退房时间
                  isMasterRoom: false, ////是否为连房的主房（1为是，0为否）
                  settleAccountsClasses: this.arrivalTimes + "/" + localStorage.getItem("classes"),
                  orderIdList: this.li_Imgs, //退房id
                  orderId: materData[0].id, //要财务转入的房单id
                };
				console.log('联防数据2',arr);
                this.$refs.BatchRoomBox.dataPlate = arr;
                this.$refs.BatchRoomBox.form.roomName = materData[0].roomName;
                this.$refs.BatchRoomBox.PlateFrom = this.CheckRooms;
                this.$refs.BatchRoomBox.financeManagementConnectIisted(
                  this.CheckRooms
                );
                this.$refs.BatchRoomBox.consumptionListed();
              }

              // this.$refs.BatchRoomBox.order_baseUpdateOutListed()
            });
          }
        }
      } else if (num == 2) {
        //查看联房账单
        this.isBillingDetails = true;
        this.$nextTick(() => {
          this.$refs.RoomBillingBox.PlateFrom = this.PlateFrom;
          this.$refs.RoomBillingBox.financeManagementConnectIisted();
        });
      }
    },
    //联房财务查看接口
    financeManagementConnectIisted() {
		console.log('联防信息查询',this.currentRoom)
		console.log('房间数据据',this.PlateFrom);
		var that = this;
	  const params = {
			type: false,
			connectRoomId: undefined,
	  };
	  if(this.currentRoom.connectRoomId){
		params.connectRoomId=this.currentRoom.connectRoomId;
	  }else{
		this.PlateFrom.forEach(function(value){
			if(value.id == that.Dbl_index){
				params.connectRoomId = value.connectRoomId;
			}
		});
	  }
      console.log('提交数据',that.Dbl_index);
      console.log('提交数据',params);
      financeManagementConnectIist(params).then((res) => {
        if (res.data.code == 0) {
          this.tableDataFrom.splice(0);
          this.tableDataFrom = res.data.data.filter((el) => {
            return !el.authOver && el.state;
          });
          this.tableDataFrom.forEach((item) => {
            if (item.itemType == 0) {
              //付款
              this.paymentPrice =
                parseFloat(this.paymentPrice) +
                parseFloat(item.quantity) * parseFloat(item.price);
            } else if (item.itemType == 1) {
              //消费totalPrice
              this.totalPriceed =
                parseFloat(this.totalPriceed) +
                parseFloat(item.quantity) * parseFloat(item.price);
            }
          });
          this.balancePrice = (this.paymentPrice - this.totalPriceed).toFixed(
            2
          );
        }
      });
    },
    //冲减房费自定义事件
    DeductRoomButton(data) {
      this.isDeduct = false;
      this.ListFinanceed({ orderId: this.Dbl_index });
      if (this.currentRoom.connectRoomId) {
        this.financeManagementConnectIisted();
      }
    },
    //获取退房的房单id
    getRoomId() {
      var arr = this.PlateFrom.filter((el) => el.orderState);
	  console.log('房间数据据',this.PlateFrom);
	  console.log('外部订单号',this.currentRoom)
	 
      if (arr.length == this.CheckRooms.length) {
        this.$message({
          type: "error",
          message: "退房不能全退，最少留一间",
        });
      } else {
        this.RoomIndex.splice(0);
        this.PlateFrom.forEach((el) => {
          this.li_Img.forEach((it, index) => {
            if (el.id != it) {
              this.RoomIndex.push(el);
            }
          });
        });
      }
    },
    //点击联房卡片触发
    cardRoom(row) {
      console.log(row);
      if (row.orderState) {
        var num = 0;
        var arrFrom = this.PlateFrom.filter((el) => el.orderState);
        // console.log(arr);
        this.CheckRooms.forEach((el, index) => {
          if (el.id == row.id) {
            console.log("1111111111");
            this.CheckRooms.splice(index, 1);
            this.li_Img.splice(index, 1);
            delete this.li_Imgs[row.id];
            num = 1;
            this.PlateFrom.forEach((itc) => {
              if (itc.id == row.id) {
                itc.isSelect = false;
              }
            });
          }
        });
        if (num != 1) {
          this.CheckRooms.push(row);
          this.li_Img.push(row.id);
          this.li_Imgs[row.id] = row.roomId;
          console.log(this.li_Imgs, "222222222222222222222222");
          console.log(this.li_Img);
          this.PlateFrom.forEach((itc) => {
            if (itc.id == row.id) {
              itc.isSelect = true;
            }
          });
          var arr = this.PlateFrom.filter((el) => el.orderState);
          if (arr.length == this.CheckRooms.length) {
            this.$message({
              type: "error",
              message: "退房不能全退，最少留一间",
            });
          }
        }

        console.log(this.CheckRooms);
      } else {
        this.$message({
          type: "error",
          message: "该房间已退",
        });
      }
    },

    outpriceBox(num) {
		console.log('冲减房费',num)
      if (num[0] == 1) {
        //确定
        if (num[1] == "0") {
          //付款
          this.outPrices = false;
          if (this.priceUpdate.projectName == "微储值") {
            //是会员消费退回掉充值接口
            this.MemberTopUped(num[2]);
          } else {
            this.UpdateFinanceed({ ...this.priceUpdate, price: num[2] });
          }
        } else if (num[1] == "1") {
          //消费
          console.log(num[2]);
          this.AddFinanceed(this.row, num[2],num[3]);
        }
      } else if (num[0] == 0) {
        //取消
        this.outPrices = false;
        this.ListFinanceed({ orderId: this.Dbl_index });
      } else {
        this.outPrices = false;
        this.ListFinanceed({ orderId: this.Dbl_index });
      }
    },
    //会员充值的接口
    MemberTopUped(num) {
      console.log(this.priceUpdate);
      MembershipDetails({ id: this.priceUpdate.voucherNo }).then((rq) => {
        if (rq.data.code === 0) {
          if (rq.data.data.isBlack == 1) {
            //是黑名单
            this.$message({
              type: "warning",
              message: "该会员已添加进黑名单，无法退款",
            });
          } else {
            let par = {
              consumptionType: 1, //消费类型1房态,2商超,3餐饮,4娱乐
              orderNumber: this.priceUpdate.id, //业务订单号（会员消费单号）
            };
            memberChangemtRecord(par).then((res) => {
              if (res.data.code === 0) {
                const params = {
                  memberId: this.priceUpdate.voucherNo,
                  transferAmount: parseFloat(num), //充值金额
                  amountMoney: parseFloat(num), //实收金额
                  paymentId: this.priceUpdate.paymentId, //付款方式id
                  paymentName: this.priceUpdate.projectName,
                  remark: `房单：${this.priceUpdate.orderId};会员退还`, //备注
                  orgId: localStorage.getItem("orgid"),
                  name: localStorage.getItem("name"),
                  state: true,
                  userClassesId: localStorage.getItem("userClassesId"),
                };
                MemberTopUp(params).then((ress) => {
                  if (ress.data.code === 0) {
                    this.$message({
                      type: "success",
                      message: "会员消费退还成功",
                    });
                    this.UpdateFinanceed({
                      ...this.priceUpdate,
                      price: parseFloat(num),
                    });
                    // this.isMessage = false;
                    // this.isBlacklist = false;
                    // this.isConsumotion = false;
                  }
                });
              }
            });
          }
        }
      });
    },
    //财务管理表之新增接口
    AddFinanceed(row, price,remark) {
      const params = {
        price: parseFloat(price) - 2 * parseFloat(price),
        orderId: row.orderId,
        itemType: "1",
        state: true,
        roomId: row.roomId,
        roomName: row.roomName, //房型名称
        userClassesId: localStorage.getItem("userClassesId"),
        operator: localStorage.getItem("name"),
        paymentId: row.paymentId,
        projectName: row.projectName,
        type: "0",
        classesId: localStorage.getItem("classesId"),
        createdName: localStorage.getItem("name"),
        classes: localStorage.getItem("classes"),
        remarks:remark?remark:"手动冲账" + parseFloat(row.price) - 2 * parseFloat(row.price),
      };
      AddFinance(params).then((res) => {
        if (res.data.code === 0) {
          this.outPrices = false;
          this.ListFinanceed({ orderId: this.Dbl_index });
          this.$message({
            type: "success",
            message: "冲账成功",
          });
        }
      });
    },
    //财务管理表之更新接口
    UpdateFinanceed(row) {
      const params = {
        ...row,
        updateState: "1", //状态修改原因（1为退还，2为结账，3为恢复）
        state: false,
        id: row.id,
      };

      params.remark = "手动退还";
      UpdateFinance(params).then((res) => {
        if (res.data.code === 0) {
          this.ListFinanceed({ orderId: this.Dbl_index });
          this.$message({
            type: "success",
            message: "退还成功",
          });
        } else {
          this.$message(res.data.msg);
        }
      });
    },
    //点击退还退还这笔账务
    handleDelete(row) {
      console.log(row);
    },
    //当某一行被双击时会触发该事件
    rowDblclick(row) {
      console.log(row);
      if (parseFloat(row.price) <= 0) {
        this.$message({
          type: "error",
          message: "金额为负数或零，不可操作",
        });
      } else {
        if (!row.authOver && row.preAuth) {
          //是预授权
          this.isAuthorization = true;
          this.$store.commit("base/SET_PRICE", row);
          this.$nextTick(() => {
            this.$refs.AUTHBOX.rulePrices = row;
            this.$refs.AUTHBOX.ruleForm.price = null;
            this.$refs.AUTHBOX.ruleForm.remark = null;
            this.$refs.AUTHBOX.currentRoom = this.currentRoom;
          });
        } else if (row.state) {
          if (!row.preAuth) {
            if (row.itemType == "0") {
              //付款
              this.outPrices = true;
              this.priceUpdate = row;
              this.priceFrom = {
                price: row.price,
                name: row.projectName,
                type: row.itemType,
              };
              this.$nextTick(() => {
                this.$refs.outPricesBox.ruleFrom.price = row.price;
                this.$refs.outPricesBox.row = row;
                this.$refs.outPricesBox.priceNums = true;
              });
            } else if (row.itemType == "1") {
              //消费
              this.row = row;
              this.outPrices = true;
              this.priceUpdate = row;

              this.priceFrom = {
                price: row.price,
                name: row.projectName,
                type: row.itemType,
              };
            }
          }
        }
      }
    },
    //弹窗关闭时触发的事件
    handleClose(done) {
      done();
      this.ListFinanceed({ orderId: this.Dbl_index });
    },
    //结账的自定义事件
    onSettle(num) {
      this.isSetting = false;
      this.isPeerSetting = false;
      if (num[1] == "确认结账") {
        this.$emit("financeBoxs", ["确认结账"]);
      }
    },
    //预授权完成自定义事件
    isAuthOver() {
      this.isAuthorization = false;
      setTimeout(() => {
        this.ListFinanceed({ orderId: this.Dbl_index });
        if (this.currentRoom.connectRoomId) {
          this.financeManagementConnectIisted();
        }
      }, 1000);
    },
    // 让表格奇数行有背景色
    cellStyle({ row, column, rowIndex, columnIndex }) {
      // console.log(row);
      // if (row.state === true) {
      //   return "background:#eee; color: 'gray'";
      // }else if(row.authOver){
      //   return "background:#fff; color: 'gray'";
      // }
      if (row.state === true) {
        return "background:#fff; color: 'gray'";
      } else {
        return "background:#F7F7F7; color: 'gray'";
      }
    },
    //双击联房卡片展示展示对应财务信息
    searchButton(row) {
      console.log(row);
      if (row.orderState) {
        this.Dbl_index = row.id;
        this.currentRoom = row;
        this.ListFinanceed({ orderId: this.Dbl_index });
        this.$message({
          type: "success",
          message: "已切换用户财务信息",
        });
      } else {
        this.$message({
          type: "error",
          message: "该房间已退",
        });
      }
    },
    //分页列表查询
    ListFinanceed(model = {}) {
      if (this.roomArrActive.includes("wineshop:finance:management:page")) {
        const params = {
          current: this.currentPage1,
          size: this.size,
          model: {
            orderId: this.$store.state.user.orderFrom.id,
            operator: localStorage.getItem("classesId"),
            ...model,
          },
        };
        ListFinance(params).then((res) => {
          if (res.data.code === 0) {
            //   res.data.data.records.forEach(el => {
            //     if(el.orderId === this.$store.state.user.orderFrom.id){
            //         this.tableData.push(el)
            //     }
            // })
            // this.totals = this.tableData.length
            // this.$store.commit('base/FINANCE_DATA', res.data.data.records)
            this.Li_orderId = res.data.data.records.orderId;
            this.tableData = res.data.data.records;
            this.totals = Number(res.data.data.total);
          }
        });
      } else {
        this.$message({
          type: "error",
          message: "无查看财务分页数据权限",
        });
      }
      // console.log(this.$store.state.user.orderFrom);
    },

    //控制入账的自定义事件
    AddenterOff(num) {
      this.isEnter = num[0];
      this.ListFinanceed({ orderId: this.Dbl_index });
      if (this.currentRoom.connectRoomId) {
        this.financeManagementConnectIisted();
      }
    },
    //新增预授权的自定义事件
    offPre(num) {
      this.dialogVisible = num[0];
      this.ListFinanceed({ orderId: this.Dbl_index });
      if (this.currentRoom.connectRoomId) {
        this.financeManagementConnectIisted();
      }
    },
    //获取当前时间
    getNowDates() {
      var date = new Date();
      var sign2 = ":";
      var year = date.getFullYear(); // 年
      var month = date.getMonth() + 1; // 月
      var day = date.getDate(); // 日
      var hour = date.getHours(); // 时
      var minutes = date.getMinutes(); // 分
      var seconds = date.getSeconds(); //秒
      // 给一位数的数据前面加 “0”
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (day >= 0 && day <= 9) {
        day = "0" + day;
      }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }

      this.arrivalTimes = year + "-" + month + "-" + day +" " + hour + sign2 + minutes + sign2 + seconds;
    },
    //点击侧边按钮分别触发的功能
    switchoverTabs(num) {
	  var that =this;
      if (num === 1) {
        //预授权
        this.dialogVisible = true;
        this.$store.commit("base/SET_ACCOUNTING", {
          payment: this.payment,
          consumption: this.consumption,
        });
        this.$nextTick(() => {
          this.$refs.AuthorizationBox.tableData.push(this.currentRoom);
          this.$refs.AuthorizationBox.ruleForm = {
            voucherNo: null,
            price: null,
            orderId: null,
            authorizationCode: null,
            bankNumber: null,
          };
          this.$refs.AuthorizationBox.paymentListed();
        });
      } else if (num === 2) {
        //入账
        this.isEnter = true;
        this.$nextTick(() => {
		  if(this.currentRoom.connectRoomId){
			  var obj = {};
			  this.PlateFrom.forEach(function(value){
				  if(value.id==that.Dbl_index){
					  obj = {
						  ...obj,
						  ...value,
					  }
				  }
			  });
			  this.$refs.EnterBox.currentRoom = obj;  
		  }else{
			  this.$refs.EnterBox.currentRoom = this.currentRoom;  
		  }
          this.$refs.EnterBox.createdButton();
          this.$refs.EnterBox.value = null;
        });

        this.$store.commit("base/SET_ACCOUNTING", {
          payment: this.payment,
          consumption: this.consumption,
        });
      } else if (num === 3) {
        //结账退房
        this.getNowDates();
        //判断有没有超时，有提醒并告知超时费用

        var kk = null;
        this.tableData.forEach((item) => {
          if (item.preAuth === true && item.authOver != true) {
            kk = 1;
            return false;
          }
        });
        if (kk == 1) {
          this.$message({
            type: "error",
            message: "此账单有未处理的预授权，请先操作预授权再结账",
          });
        }
        if (kk != 1) {
          this.isSetting = true;
          this.$nextTick(() => {
            this.$refs.AccountsBox.endPriceFrom.splice(0);
			console.log('房间数据',this.currentRoom);
			console.log('房间数据',this.Dbl_index);
			console.log('房间数据',this.PlateFrom);
			if(this.notChecked){
				// PlateFrom
				// currentRoom
				var obj ={};
				this.PlateFrom.forEach(function(value){
					if(value.id==that.Dbl_index){
						obj = {
							...obj,
							...value,
						}
					}
				});
				this.$refs.AccountsBox.formLabelAlign = obj;
			}else{
				this.$refs.AccountsBox.formLabelAlign = this.currentRoom;
			}
			
            this.$refs.AccountsBox.ListFinanceed({ orderId: this.Dbl_index });
            this.$refs.AccountsBox.deadline4 = Date.now() + 1000 * 60 * 5;
            this.$refs.AccountsBox.SettleBox();
          });

          this.$store.commit("base/SET_ACCOUNTING", {
            payment: this.payment,
            consumption: this.consumption,
          });
        }
      } else if (num == 4) {
        //冲减房费
        this.isDeduct = true;
        this.$nextTick(() => {
          this.$refs.DeductRoomBox.ruleForm.tableData.push(this.currentRoom); //进来的放单信息
          // this.$refs.AccountsBox.ListFinanceed({
          //   orderId: this.currentRoom.id,
          // });
          this.$refs.DeductRoomBox.ListFinanceed(this.currentRoom);
          this.$refs.DeductRoomBox.UnitBargainingLooked(); //查看消费项目
          // this.$refs.DeductRoomBox.getNowDates(
          //   this.currentRoom.arrivalTime
          // );
        });
      } else if (num == 5) {
        //会员卡消费
        this.isConsumer = true;
        console.log("数据", this.currentRoom);
        console.log("数据", this.currentRoom.mobile);
        this.$nextTick(() => {
          this.$refs.ConsumerBox.ruleForm.tableData.push(this.currentRoom); //进来的放单信息
          this.$refs.ConsumerBox.ListFinanceed(this.currentRoom);
          this.$refs.ConsumerBox.ruleForm.number = this.currentRoom.mobile;
          this.$refs.ConsumerBox.userInfoGeted(this.currentRoom.mobile);
          // this.$refs.ConsumerBox.userInfoGeted(this.currentRoom.mobile);
          this.$refs.ConsumerBox.Getpaymented();
        });
      } else if (num == 6) {
        //联房结账
        console.log(this.PlateFrom);
        this.AddPriceFrom.splice(0);

        this.tableDataFrom.forEach((item) => {
          if (item.preAuth === true && item.authOver != true) {
            kk = 1;
            return false;
          }
        });
        if (kk == 1) {
          this.$message({
            type: "error",
            message: "联房账单有未处理的预授权，请先操作预授权完成再结账",
          });
        }
        if (kk != 1) {
          this.isPeerSetting = true;
          console.log(this.PlateFrom);
          var arr = this.PlateFrom.filter((el) => {
            return el.isMasterRoom;
          });
          var newArr = this.PlateFrom.filter((el) => {
            return el.orderState;
          });
          console.log(arr);
          console.log(newArr, "12312312");
          this.$nextTick(() => {
            // this.$refs.PeerAccountsBox.AllRooms = this.PlateFrom;
            this.$refs.PeerAccountsBox.AllRooms = newArr;
            this.$refs.PeerAccountsBox.PlateFrom = arr;
            this.$refs.PeerAccountsBox.endPriceFrom.splice(0);
            this.$refs.PeerAccountsBox.financeManagementConnectIisted();
            this.$refs.PeerAccountsBox.SettleBox();
          });
        }
      }

      this.tab_sideba = num;
    },

    //点击切换当前页时触发
    handleCurrentChange(val) {
      this.current = val;
      this.ListFinanceed({ orderId: this.Dbl_index });
    },
    hilarity() {
      this.$message({
        type: "success",
        message: "倒计时已结束",
      });
    },
    //根据房间id和订单id找到对应订单信息
    //分页列表查询
    orderFromLists(model = {}) {
      var params = {
        current: 1,
        size: 10000,
        model: {
          ...model,
        },
      };
	  var that = this;
	  console.log('退房后提交数据',params)
      orderFromList(params).then((res) => {
        if (res.data.code == 0) {
          this.PlateFrom.splice(0);
          this.PlateFrom.push(...res.data.data.records);
		  console.log('退房后赋值数据',this.PlateFrom)
          res.data.data.records.forEach((el) => {
            if (el.isMasterRoom) {
              this.ListFinanceed({ orderId: el.id });
            }
			if(el.orderState){
				//在住
				if(el.roomId == that.currentRoom.roomId){
					that.notChecked=true;
				}
			}
          });
          var num = 0;
          var arrs = this.PlateFrom.filter((el) => el.isMasterRoom);
          this.PlateFrom.forEach((el, index) => {
            if (el.orderState) {
              if (el.isMasterRoom) {
                num = 2;
                this.PlateFrom.splice(index, 1);
              }
            }
          });
          this.PlateFrom.unshift(...arrs);
          // if (num == 2) {
          //   //没有退房的房间里有主房
          // } else {
          //   //没有退房的房间里没有主房
          //   var newArr = res.data.data.records.filter((el) => el.orderState);
          //   var oldArr = res.data.data.records.filter((el) => !el.orderState);
          //   newArr[0].isMasterRoom = true;
          //   this.PlateFrom.splice(0);
          //   this.PlateFrom.push(...newArr);
          //   this.PlateFrom.push(...oldArr);
          //   this.PlateFrom.forEach((it, index) => {
          //     this.$set(this.PlateFrom, index, { ...it });
          //     oldArr.forEach((el) => {
          //       if (el.isMasterRoom) {
          //         this.EditdataStatisticsed(el, 1);
          //       }
          //     });
          //     this.EditdataStatisticsed(newArr[0], 2);
          //   });
          // }
          console.log(this.PlateFrom);
        }
      });
    },
  },
  computed: {
    // 总付款金额
    payment() {
      var num = 0;
      this.tableData.forEach((item) => {
        if (item.state) {
          if (!item.authOver) {
            if (item.itemType == "0") {
              // console.log(item);
              num = parseFloat(item.price) + num;
            }
          }
        }
      });
      return parseFloat(num.toFixed(2));
    },
    //消费
    consumption() {
      var nums = 0;
      this.tableData.forEach((item) => {
        if (item.state) {
          if (!item.authOver) {
            if (item.itemType == "1") {
              // console.log(item);
              nums = parseFloat(item.price) + nums;
            }
          }
        }
      });
      return parseFloat(nums.toFixed(2));
    },
    //消费合计
    consumptiontotal() {
      var nums = 0;
      this.tableData.forEach((item) => {
        if (item.itemType == 1) {
          //消费totalPrice
          if (item.updateState != "1") {
            nums =
              parseFloat(nums) +
              parseFloat(item.quantity) * parseFloat(item.price);
          }
        }
      });
      return parseFloat(nums.toFixed(2));
    },
    //付款合计
    paymenttotal() {
      var nums = 0;
      console.log("合计记录", this.tableData);
      this.tableData.forEach((item) => {
        if (item.itemType == 0) {
          //付款
          if (item.preAuth) {
            if (!item.preAuth) {
              if (item.updateState != "1") {
                nums =
                  parseFloat(nums) +
                  parseFloat(item.quantity) * parseFloat(item.price);
              }
            }
          } else {
            if (item.updateState != "1") {
              nums =
                parseFloat(nums) +
                parseFloat(item.quantity) * parseFloat(item.price);
            }
          }
        }
      });
      return parseFloat(nums.toFixed(2));
    },
  },
};
</script>

<style lang="scss" scoped>
.ButtonSubmitss {
  width: 100px;
  height: 40px;
  background-color: #5cb5b8;
  color: #fff;
  border-radius: 6px;
}
::v-deep .el-dialog__header {
  // background-color: #e8fdfe;
}

::v-deep .el-dialog__body {
  // background-color: #e8fdfe;
  max-height: 650px;
  overflow-y: auto;
}
.searchOrder {
  position: absolute;
  bottom: 0px;
  right: 5%;
  width: 50px;
}
.el-table {
  width: 1035px;

  ::v-deep .el-table__body-wrapper::-webkit-scrollbar {
    width: 10px;
    /*滚动条宽度*/
    height: 13px;
    /*滚动条高度*/
  }

  /*定义滚动条轨道 内阴影+圆角*/
  ::v-deep .el-table__body-wrapper::-webkit-scrollbar-track {
    box-shadow: 0px 1px 3px #fff inset;
    /*滚动条的背景区域的内阴影*/
    border-radius: 10px;
    /*滚动条的背景区域的圆角*/
    background-color: #fff;
    /*滚动条的背景颜色*/
  }

  /*定义滑块 内阴影+圆角*/
  ::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
    box-shadow: 0px 1px 3px #aacde4 inset;
    /*滚动条的内阴影*/
    border-radius: 10px;
    /*滚动条的圆角*/
    background-color: #aacde4;
    /*滚动条的背景颜色*/
  }
}

.FinanceMessage {
  width: 100%;
  height: 500px;
  overflow-y: auto;
  // overflow: hidden;
  padding-right: 10px;
  padding-bottom: 20px;
  box-sizing: border-box;
  display: flex;
  position: relative;

  .BillingDetailsBox {
    ::v-deep .el-dialog__header {
      // background-color: #e8fdfe;
    }

    ::v-deep .el-dialog__body {
      // background-color: #e8fdfe;
      max-height: 650px;
      overflow-y: auto;
    }
  }

  // 侧边栏css
  .sidebar {
    width: 10%;

    ul {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      li {
        width: 110px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        margin-bottom: 15px;
        border-radius: 6px;
        background-color: #e4eff0;
        color: #666666;
        cursor: pointer;
      }

      li.activate {
        color: #fff;
        background-color: #5cb5b8;
        border: none;
      }
    }
  }

  //内容区css
  .finance_main {
    width: 88%;
    height: 470px;
    // overflow-y: auto;
    overflow: hidden;
    border: 1px solid #ededed;
    background-color: #fff;
    border-radius: 8px;
    margin-left: 10px;

    .remoteRoom {
      // width: 100%;
      height: 210px;
      padding-right: 10px;
      border: 1px solid #ededed;

      // 标题部分css
      .order_title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 50px;
        // padding-left: 20px;
        box-sizing: border-box;
        .left_navdata {
          margin-left: 15px;
        }
        .title_icon {
          height: 50px;
          text-align: center;
          line-height: 50px;
          // padding-top: 5px;
          margin-top: 5px;
          box-sizing: border-box;
          .el-button {
            // width: 90px;
            // padding: 0px 10px;
            height: 35px;
            background-color: #5cb5b8;
            color: #fff;
            padding: 0px 10px;
          }
        }

        p {
          color: black;
          flex: 1;
          text-align: left;
        }
      }

      .el-row.cardRoom {
        width: 100%;
        // width: 1035px;
        height: 150px;
        overflow-y: auto;
        padding-left: 23px;
        margin-top: 10px;
        box-sizing: border-box;
        //单个卡片样式
        .card.actived {
          box-shadow: 5px 5px 5px rgb(220, 219, 219),
            -2px -2px 5px rgb(220, 219, 219);
        }
        .card {
          position: relative;
          width: 22%;
          // height: 90px;
          float: left;
          padding-top: 10px;
          padding-bottom: 20px;
          padding-left: 5px;
          padding-right: 5px;
          box-sizing: border-box;
          border-radius: 4px;
          margin-bottom: 20px;
          margin-right: 20px;
          border: 1px solid rgb(209, 204, 204);

          .radioBox {
            position: absolute;
            top: 10px;
            right: 8px;
            width: 20px;
            height: 20px;
            z-index: 10;
            border-radius: 50%;
            text-align: center;
            line-height: 20px;
            font-size: 13px;
            background-color: #5cb5b8;
            color: #fff;
          }

          .radioImg {
            position: absolute;
            display: none;
            bottom: 3px;
            right: 5px;
            width: 15px;
            // height: 20px;
            z-index: 10;
          }
          .DataActive {
            position: absolute;
            display: none;
            width: 15px;
            bottom: 2px;
            right: 19%;
            // width: 20px;
            // height: 20px;
            z-index: 10;
          }
          .DataActive.activeted {
            display: block;
          }
          .radioImg.active {
            display: block;
          }

          p {
            font-size: 13px;
            background-color: #fff;
            padding-left: 5px;
            box-sizing: border-box;
            margin-bottom: 6px;

            span:nth-child(1) {
              display: block;
              width: 5px;
              height: 5px;
              background-color: green;
              border-radius: 50%;
              margin-right: 8px;
            }
          }

          p:nth-child(2) {
            color: gray;
            margin-top: 5px;
          }

          .priceBOX {
            color: rgb(11, 241, 11);
            width: 100%;
            // height: 30px;
            display: flex;
            // background-color: #5cb5b8;
            .priceSize {
              width: 50%;
              height: 30px;
              line-height: 30px;
              // display: flex;
              color: rgb(11, 241, 11);
              border-radius: 0%;
              background-color: #fff;
            }
          }
          .UlPrice {
            width: 100%;
            display: flex;
            // height: 30px;
            li {
              width: 50%;
              // overflow: hidden;
              // height: 30px;
              line-height: 30px;
              color: rgb(11, 241, 11);
            }
          }
          .stateUse {
            width: 100%;
            height: 20px;
            line-height: 20px;
            position: absolute;
            bottom: 0;
            left: 0;
            display: flex;
            margin-bottom: 0px;
            align-items: center;
            color: green;
            background-color: rgb(194, 245, 194);
          }
        }
      }
    }

    // <div class="card">
    //     <p>8203 &nbsp; 大床房</p>
    //       <p>李四</p>
    //       <p>消费600， &nbsp; 付款 600</p>
    //       <p>
    //         <span></span>
    //         <span>在住</span>
    //       </p>
    //       <div class="radioBox">主</div>
    //   </div>
  }

  .table_pull {
    //金额汇总css
    .finance_price {
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      border-bottom: 1px dotted rgb(228, 222, 222);

      ul {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        line-height: 40px;

        li {
          width: 30%;
          text-align: left;
          padding-left: 10px;
          box-sizing: border-box;
          span {
            color: rgb(11, 245, 11);
          }
        }

        div {
          height: 15px;
          border-right: 2px solid rgb(228, 222, 222);
          margin: 0 15px 0 15px;
        }
      }
    }
  }
}
.UpdatePricesBox {
  ::v-deep .el-dialog__body {
    // background-color: #e8fdfe;
    max-height: 630px;
    overflow-y: auto;
    .update_prices {
      width: 100%;
    }
  }
}
.addMember {
  ::v-deep .el-dialog__header {
    // background-color: #e8fdfe;
  }

  ::v-deep .el-dialog__body {
    // background-color: #e8fdfe;
    max-height: 630px;
    overflow-y: auto;
  }
}
.AccountBoxSs {
  ::v-deep .el-dialog__header {
    // background-color: #e8fdfe;
  }

  ::v-deep .el-dialog__body {
    // background-color: #e8fdfe;
    max-height: 680px;
    // overflow-y: auto;
    overflow: hidden;
  }
}
.newroomcardbox {
  width: 100%;
  height: 150px;
  overflow-y: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .newcard {
    width: 220px;
    height: 132px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    background-color: #ffffff;
    border: 1px solid #f1f2f2;
    position: relative;
    border-radius: 11px;
  }
  .newcard-heard {
    margin: 15px 17px 11px;
    display: flex;
    align-items: center;
  }
  .newmargin {
    margin-left: 15px;
    margin-bottom: 15px;
  }
  .newcard-iconbox {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    font-weight: 300;
    background-color: #5cb5b8;
  }
  .mewcard-footer {
    position: absolute;
    width: 100%;
    height: 24px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f5f9f9;
    color: #5cb5b8;
  }
  .dian-box {
    display: flex;
    align-items: center;
    padding-left: 15px;
  }
  .dian {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 5px;
    background-color: #5cb5b8;
  }
  .newimg {
    width: 15px;
    height: 15px;
  }
}
</style>
