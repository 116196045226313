import request from '_utils/request'
//获取图片验证码
const getcode = (params = {}) => (
  request.get('/oauth/anyTenant/captcha',{ params,responseType: 'arraybuffer' })
)
//删除上传图片
const anyUserlogout = (params = {}) => (
  request.post('/oauth/anyUser/logout?token=' + params.token, params)
)
//删除上传图片
const DeteleImg = (params = {}) => (
  request.delete('/base/file', {data:params.ids})
)
//房间导入模版下载
const   Roomexport = (params = {}) => (
  request.post('/wineshop/room/export', params, {
    responseType: 'arraybuffer',
  })
)
//房间:导入Excel
const   RoomImport = (params = {}) => (
  request.post( 
    {
      url: '/wineshop/room/import',
      method: 'POST',
      transformRequest: [function(data, headers) {
        // 去除post请求默认的Content-Type
        delete headers.post['Content-Type']
        return data
      }],
      data: params
    }
  
  )
)
//协议单位导入模版下载
const   agreement_unitexport = (params = {}) => (
  request.post('/wineshop/agreement_unit/export', params, {
    responseType: 'arraybuffer',
  })
)
//房间:导入Excel
const   agreement_unitImport = (params = {}) => (
  request.post( 
    {
      url: '/wineshop/agreement_unit/import',
      method: 'POST',
      transformRequest: [function(data, headers) {
        // 去除post请求默认的Content-Type
        delete headers.post['Content-Type']
        return data
      }],
      data: params
    }
  
  )
)
//会员导入模版
const   memberInformationDownload = (params = {}) => (
  request.post('/wineshop/memberInformation/download', params, {
    responseType: 'arraybuffer',
  })
)
//小票打印机: 分页
const printertextpage = (params = {}) => (
  request.post('/external/printer_text/page', params)
)
//小票打印机: 新增
const printertextAdd = (params = {}) => (
  request.post('/external/printer_text', params)
)
//小票打印机: 修改
const printertextUpdate = (params = {}) => (
  request.put('/external/printer_text', params)
)
//小票打印机: 小票打印
const printertextreceipt = (params = {}) => (
  request.post('/external/printer_text/receipt', params)
)
//添加酒店初始数据
const payment_methodadd_base = (params = {}) => (
  request.post('/wineshop/payment_method/add_base?orgId='+ params.orgId)
)
//添加酒店初始数据:四平
const paymentmethodustaddbase = (params = {}) => (
  request.post('/wineshop/payment_method/must_add_base?orgId='+ params.orgId)
)
export {
  getcode,
  DeteleImg,
  Roomexport,
  RoomImport,
  agreement_unitexport,
  agreement_unitImport,
  memberInformationDownload,
  printertextAdd,
  printertextpage,
  printertextUpdate,
  printertextreceipt,
  payment_methodadd_base,
  paymentmethodustaddbase,
  anyUserlogout
}
