<template>
	<!-- 结账弹窗 -->
	<div class="SettleAccounts" @click="close_page">
		<div class="SettleAccounts_main">
			<!-- 金额的汇总 -->
			<div class="settle_statistics">
				<ul>
					<li>
						未结统计:消费:
						<span v-if="String(consumption).length < 9" style="color: red">￥{{ consumption }}</span>
						<el-tooltip v-else class="item" effect="light" placement="top-start">
							<div slot="content">￥{{ parseFloat(consumption).toFixed(2) }}</div>
							<span>
								{{
                  String(consumption)
                    ? String(consumption).length > 9
                      ? "￥" + String(consumption).slice(0,  String(consumption).split('').indexOf('.') > 9?String(consumption).split('').indexOf('.')-2:9) 
                      : "￥" + String(consumption)
                    : "￥0"
                }}
							</span>
						</el-tooltip>
					</li>
					<div></div>
					<li>
						付款:
						<span v-if="String(payments).length < 9">￥{{ payments }}</span>
						<el-tooltip v-else class="item" effect="light" placement="top-start">
							<div slot="content">￥{{ parseFloat(payments).toFixed(2) }}</div>
							<span>
								{{
                  payment
                    ? String(payments).length > 9
                      ? "￥" + String(payments).slice(0, String(payments).split('').indexOf('.') > 9?String(payments).split('').indexOf('.')-2:9) 
                      : "￥" + String(payments)
                    : "￥0"
                }}
							</span>
						</el-tooltip>
					</li>
					<div></div>
					<li>
						平账金额:
						<span
							v-if="AddPriceFrom[0].BalanceOfAccount.length < 8">￥{{ AddPriceFrom[0].BalanceOfAccount }}</span>
						<el-tooltip v-else class="item" effect="light" placement="top-start">
							<div slot="content">￥{{ AddPriceFrom[0].BalanceOfAccount }}</div>
							<span>
								{{
                 AddPriceFrom[0].BalanceOfAccount
                    ?String(AddPriceFrom[0].BalanceOfAccount).length > 8
                      ? "￥" + (String(AddPriceFrom[0].BalanceOfAccount).slice(0, String(AddPriceFrom[0].BalanceOfAccount).split('').indexOf('.') > 8?String(AddPriceFrom[0].BalanceOfAccount).split('').indexOf('.')-2:8) ) 
                      : "￥" + AddPriceFrom[0].BalanceOfAccount
                    : "￥0"
                }}
							</span>
						</el-tooltip>
					</li>
					<div></div>
					<li>
						已住: <span>{{ parseFloat(formLabelAlign.reserveDays) }} 天</span>
					</li>
				</ul>
			</div>
			<!-- 金额的汇总 -->
			<!-- 客户信息记录 -->
			<div class="customerInformation">
				<el-form :label-position="labelPosition" label-width="80px" :model="formLabelAlign">
					<el-row>
						<el-col :span="12">
							<el-form-item label="顾客姓名">
								<el-input v-model="formLabelAlign.name" :disabled="true"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="订单编号">
								<el-input v-model="formLabelAlign.id" :disabled="true"></el-input>
							</el-form-item>
						</el-col>
					</el-row>

					<el-row>
						<el-col :span="12">
							<el-form-item label="手机号码">
								<el-input v-model="formLabelAlign.mobile" :disabled="true" placeholder="未登记"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="入住时间">
								<el-input v-model="formLabelAlign.arrivalTime" :disabled="true"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<!-- 表格展示 -->
				<div class="table_box">
					<el-table :data="tableData" :max-height="'200px'" border :row-style="{height:'35px'}"
						:cell-style="{padding: '0'}" style="width: 100%; border-radius: 8px">
						<el-table-column prop="projectName" align="center" label="项目名称" style="width: 33%">
						</el-table-column>
						<el-table-column prop="name" label="消费" align="center" style="width: 33%">
							<template slot-scope="scope">
								<span class="checkout-color1">{{
                  scope.row.itemType === "1" ? "￥" + scope.row.price : ""
                }}</span>
							</template>
						</el-table-column>
						<el-table-column prop="address" align="center" label="付款">
							<template slot-scope="scope">
								<span class="checkout-color2">{{
                  scope.row.itemType === "0" ? "￥" + scope.row.price : ""
                }}</span>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
			<!-- 客户信息记录 -->
			<!-- 支付结算 -->
			<div class="payment_settlement">
				<p>
					<span></span>
					支付结算
				</p>

				<el-form :label-position="labelPosition" label-width="80px" :model="formLabelAlign">
					<el-row v-for="(el, index) in AddPriceFrom" :key="index">
						<el-col :span="10">
							<el-form-item :label="
                  AddPriceFrom[index].BalanceOfAccount >= 0
                    ? '应收金额'
                    : '应退金额'
                ">
								<!-- :disabled="AddPriceFrom[index].isDisabled" -->
								<el-input v-model.number="AddPriceFrom[index].BalanceOfAccount" :disabled="index < 1"
									style="font-size: 20px; font-weight: 1000;"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-select clearable v-model="AddPriceFrom[index].paymentId" placeholder="付款方式"
								@change="PriceInput(AddPriceFrom[index].paymentId, index)">
								<el-option v-for="(item, ix) in payment" :key="item.id" :label="item.name"
									:value="item.id"></el-option>
							</el-select>
						</el-col>
						<el-col :span="6" v-if="AddPriceFrom[index].isInput">
							<el-select clearable v-model="AddPriceFrom[index].agreementUnitId" placeholder="协议单位"
								@change="
                  Bargainingputs(AddPriceFrom[index].agreementUnitId, index)
                ">
								<el-option v-for="(item, indexs) in BargainingUnitList" :key="item.id"
									:label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-col>
						<el-col :span="2" v-if="AddPriceFrom.length != 1 && index >= 1">
							<el-button class="DeteleButton" @click="DeleteArr(index)">删除</el-button>
						</el-col>
					</el-row>
				</el-form>
			</div>
			<!-- 支付结算 -->

			<!-- 提交部分 -->
			<div class="ending">
				<div class="ending_left">
					<span> 结账限时： </span>
					<el-statistic ref="statistic" @finish="hilarity" format="mm分ss秒" :value="deadline4" time-indices
						class="statistic_main">
					</el-statistic>
				</div>
				<div class="ending_right">
					<!-- <el-button class="addButton" @click="onSubmit(1)">会员结账</el-button> -->
					<el-button class="addButton" @click="AddSubmit()">增加付款</el-button>
					<el-button class="deleteButton" @click="onSubmit(2)">确认结账</el-button>
				</div>
			</div>
			<!-- 提交部分 -->
		</div>

		<!-- <el-dialog
  title="加收提醒"
  :visible.sync="dialogVisible"
  width="500px"
  append-to-body
  destroy-on-close
  :before-close="handleClose"
  class="dialogBox"
  >
 <div class="addPriceBox">
  <el-form ref="forms" :model="forms" label-width="80px">
    <el-form-item label="加收项目">
      <el-input v-model="forms.projectName" ></el-input>
    </el-form-item>
    <el-form-item label="超时收费">
      <el-input v-model="forms.OvertimeCharge" placeholder="请输入超时费用"></el-input>
    </el-form-item>
    <el-form-item label="加收金额">
      <el-input v-model="forms.price" placeholder="请输入加收金额"></el-input>
    </el-form-item>
    <el-form-item label="是否加收">
      <el-switch
      v-model="forms.isAdd"
      active-text="是"
      inactive-text="否">
    </el-switch>
    </el-form-item>
    </el-form>
 </div>
</el-dialog> -->
		<el-dialog title="会员卡结账" :visible.sync="isConsumer" width="600px" class="addMember" append-to-body
			destroy-on-close :before-close="handleClose">
			<member-consumer ref="ConsumerBox" @MemberPrice="MemberPrice"></member-consumer>
		</el-dialog>
	</div>
</template>

<script>
	import {
		EditdataStatistics,
		AddFinance,
		ListFinance,
		paymentList,
		financeManagementSaveList,
	} from "_api/orderFrom";
	import MemberConsumer from '_components/MemberConsumer.vue';
	import {
		printertextreceipt
	} from "_api/verify";
	import {
		getPageHouseshop,
		getGuestNo
	} from "_api/housingSystem";
	import {
		BargainingUnitAll
	} from "_api/advanceOrder";
	import {
		UnitBargainingLook,
		listCheckOutTime
	} from "_api/systemSetting";
	export default {
		name: "SettleAccounts",
		components: {
			MemberConsumer
		},
		data() {
			return {
				Doo: null,
				fooo: null,
				parBUnssS: 0,
				//结账的参数
				SettleFrom: {
					paymentId: "",
					paymentName: "",
					BalanceOfAccount: this.BalanceOfAccount,
					whetherRely: false, //是否挂账，
					agreementUnitId: null, //协议单位id
					agreementUnitName: null, //协议单位名称
				},
				reserveDays: 0,
				BargainingUnitList: [], //所有协议单位
				bargainingUnit: [], //所有的消费项目
				formLabelAlign: {},
				arrivalTimes: "",
				isInput: false,
				dialogVisible: false,
				payment: [],
				tableData: [],
				current: 1,
				size: 10,
				settingTime: "",
				isConsumer: false,
				AccountAll: this.BalanceOfAccount,
				labelPosition: "right",
				deadline4: Date.now() + 1000 * 60 * 5,
				isFulldayRoomrate: false, //是否有房费
				projectNames: null, //判断入住类型
				FulldayRoomrate: {},
				CheckOuttime: [], //退房时间的分页列表
				forms: {
					CheckOuttimeName: null,
				},
				AddPriceFrom: [{
					BalanceOfAccount: this.BalanceOfAccount, //价格
					fooo: null, //付款方式的下标
					isDisabled: true,
					isInput: false,
					paymentId: null,
					paymentName: null,
				}, ],
				endPriceFrom: [], //超时房费数据集合
				payments: 0, //总付款金额
				consumption: 0, //总消费金额
				BalanceOfAccount: 0, //结账金额
				endNums: 0,
				//打印小票数据
				projectDetails: [],
				tableDataFrom: [],
				paymentPrice: 0,
				totalPrice: 0,
				name: "",
				type: "", //0付款1消费
				lunnum:0,
			};
		},
		created() {
			//  this.ListFinanceed()
		},
		// computed: {
		//   // 总付款金额
		//   payments() {
		//     let num = 0;
		//     this.tableData.forEach((item) => {
		//       if (item.state) {
		//         if (!item.authOver) {
		//           if (item.itemType == "0") {
		//             console.log(item);
		//             num = parseFloat(item.price) + num;
		//           }
		//         }
		//       }
		//     });
		//     return parseFloat(num);
		//   },
		//   //消费
		//   consumption() {
		//     let num = 0;
		//     this.tableData.forEach((item) => {
		//       if (item.state) {
		//         if (!item.authOver) {
		//           if (item.itemType == "1") {
		//             console.log(item);
		//             num = parseFloat(item.price) + num;
		//           }
		//         }
		//       }
		//     });
		//     return parseFloat(num);
		//   },
		//   BalanceOfAccount() {
		//     if (this.consumption < 0) {
		//       this.AddPriceFrom[0].BalanceOfAccount = parseFloat(
		//         this.consumption + this.payments
		//       );
		//       return parseFloat(this.consumption + this.payments);
		//     } else {
		//       this.AddPriceFrom[0].BalanceOfAccount = parseFloat(
		//         this.consumption - this.payments
		//       );
		//       return parseFloat(this.consumption - this.payments);
		//     }
		//   },
		// },
		methods: {
			setRowStyle({
				row,
				rowIndex
			}) {
				return {
					style: {
						height: '200px'
					}
				};
			},
			getPriceNumber() {
				let num = 0;
				this.tableData.forEach((item) => {
					if (item.state) {
						if (!item.authOver && item.state && !item.preAuth) {
							if (item.itemType == "0") {
								console.log(item);
								num = parseFloat(item.price) + num;
							}
						}
					}
				});
				this.payments = parseFloat(num.toFixed(2));
				num = 0;
				this.tableData.forEach((item) => {
					if (item.state) {
						if (!item.authOver && item.state && !item.preAuth) {
							if (item.itemType == "1") {
								console.log(item);
								num = parseFloat(item.price) + num;
							}
						}
					}
				});
				this.consumption = parseFloat(num.toFixed(2));
				if (this.consumption < 0) {
					this.AddPriceFrom[0].BalanceOfAccount = parseFloat(
						this.consumption + this.payments
					).toFixed(2);
					this.AddPriceFrom[0].BalanceOfAccount =
						parseFloat(this.AddPriceFrom[0].BalanceOfAccount).toFixed(2);
					console.log(this.AddPriceFrom[0].BalanceOfAccount);
					return parseFloat(this.consumption + this.payments);
				} else {
					this.AddPriceFrom[0].BalanceOfAccount = parseFloat(
						this.consumption - this.payments
					).toFixed(2);
					this.AddPriceFrom[0].BalanceOfAccount =
						parseFloat(this.AddPriceFrom[0].BalanceOfAccount).toFixed(2);
					console.log(this.AddPriceFrom[0].BalanceOfAccount);
					return parseFloat(this.consumption - this.payments);
				}
			},
			//先财务新增，在订单更新
			SettleBox() {
				this.paymentListed();
				this.getNowDates(new Date(), this.formLabelAlign.arrivalTime);
				console.log(this.$store.state.user.orderFrom);
			},
			handleClose(done) {
				done();
			},
			//批量新增财务接口
			financeManagementSaveListed(el) {
				var params = [];
				if (el) {
					this.AddPriceFrom.forEach((it, index) => {
						if (index != 0) {
							params.push({
								price: it.BalanceOfAccount ? parseFloat(it.BalanceOfAccount).toFixed(2) :
									0,
								orderId: el.id,
								itemType: "0",
								state: true,
								roomId: el.roomId,
								roomName: el.roomName, //房型名称
								userClassesId: localStorage.getItem("userClassesId"),
								operator: localStorage.getItem("name"),
								paymentId: it.paymentId,
								projectName: it.paymentName,
								type: "0",
								classesId: localStorage.getItem("classesId"),
								createdName: localStorage.getItem("name"),
								classes: localStorage.getItem("classes"),
								remark: null,
								entryState: "2",
							});
						}
					});
				}

				if (this.endPriceFrom.length > 0) {
					this.endPriceFrom.forEach((im) => {
						im.entryState = "2";
						params.push(im);
					});
				}
				financeManagementSaveList(params).then((res) => {
					if (res.data.code === 0) {
						this.$message({
							type: "success",
							message: "入账成功",
							onClose: () => {
								// this.EditdataStatisticsed();
								console.log(this.SettleFrom);
							},
						});
						this.EditdataStatisticsed(this.SettleFrom);
						console.log(this.SettleFrom);
					}
				});
			},
			//获取真实天数
			getNowDates(date, oldData, row) {
				var date1 = new Date(date).getTime();
				var oldData1 = new Date(oldData).getTime();
				var newTime = new Date().getTime()
				var predepartureTime = new Date(this.formLabelAlign.predepartureTime).getTime()
				if (row.settlementType == 0) {
					if (parseFloat(date1) - parseFloat(oldData1) <= 1 * 24 * 60 * 60 * 1000) {
						this.formLabelAlign.reserveDays = 1 + "天";
					} else if (
						parseFloat(date1) - parseFloat(oldData1) >=
						1 * 24 * 60 * 60 * 1000
					) {
						if (newTime > predepartureTime) {
							//超时了
							let num = Math.ceil(
								(parseFloat(date1) - parseFloat(oldData1)) / (1 * 24 * 60 * 60 * 1000)
							);
							this.formLabelAlign.reserveDays = num + "天";
						}
					}
				} else {
					if (parseFloat(date1) - parseFloat(oldData1) <= 60 * 60 * 1000) {
						this.reserveDays = '1天';
					} else {
						let num = Math.ceil(
							((parseFloat(date1) - parseFloat(oldData1)) / (60 * 60 * 1000)) / parseFloat(row
								.hourLength)
						);
						this.reserveDays = num + '天';
						console.log(this.reserveDays);
					}
				}

			},

			//房型分页列表的请求接口
			getPageHouseshops() {
				const params = {
					current: 1,
					size: 1000,
					orgId: localStorage.getItem("orgid"),
					model: {
						state: true,
					},
				};
				getPageHouseshop(params).then((res) => {
					if (res.data.code === 0) {
						res.data.data.records.forEach((el) => {
							if (el.houseType == this.formLabelAlign.houseTypeName) {
								this.formLabelAlign.overstepHourPrice = el.overstepHourPrice;
							}
						});
						console.log(res.data.data.records);
					}
				});
			},
			//监听付款方式是不是挂账
			PriceInput(id, it) {
				console.log(id, it);
				if (id) {
					if (it == 0) {
						this.payment.forEach((el) => {
							if (el.id == id) {
								if (el.name == "协议挂账") {
									this.SettleFrom.whetherRely = true;
									this.AddPriceFrom[it].isInput = true;
									this.BargainingUnitAlls();
									this.SettleFrom.paymentName = el.name;
									this.SettleFrom.paymentId = el.id;
									this.AddPriceFrom[it].paymentName = el.name;
									this.AddPriceFrom[it].paymentId = el.id;
								} else {
									this.SettleFrom.paymentName = el.name;
									this.SettleFrom.paymentId = el.id;
									this.SettleFrom.whetherRely = false;
									console.log('22',this.SettleFrom);
									this.AddPriceFrom[it].isInput = false;
									this.AddPriceFrom[it].paymentName = el.name;
									this.AddPriceFrom[it].paymentId = el.id;
								}
							} else {}
						});
					} else {
						this.payment.forEach((el) => {
							if (el.id == id) {
								if (el.name == "协议挂账") {
									this.AddPriceFrom[it].isInput = true;
									this.BargainingUnitAlls();
									this.AddPriceFrom[it].paymentName = el.name;
									this.AddPriceFrom[it].paymentId = el.id;
								} else {
									this.SettleFrom.paymentName = el.name;
									this.SettleFrom.paymentId = el.id;
									this.AddPriceFrom[it].isInput = false;
									this.AddPriceFrom[it].paymentName = el.name;
									this.AddPriceFrom[it].paymentId = el.id;
								}
							}
						});
					}
				} else {
					this.AddPriceFrom[it].isInput = false;
					this.fooo = "";
					this.SettleFrom.paymentName = "";
					this.SettleFrom.paymentId = "";
					this.AddPriceFrom[it].paymentName = "";
					this.AddPriceFrom[it].paymentId = "";
					this.SettleFrom.whetherRely = false;
					
					this.AddPriceFrom[it].agreementName = '';
					this.AddPriceFrom[it].agreementUnitId = '';
					this.SettleFrom.agreementUnitName = '';
					this.SettleFrom.agreementUnitId = '';
				}

				console.log(this.AddPriceFrom);
			},
			//点击协议单位触发
			Bargainingputs(id, index) {
				console.log(id);
				if (id) {
					if (index == 0) {
						this.BargainingUnitList.forEach((el) => {
							if (el.id == id) {
								this.AddPriceFrom[index].agreementName = el.name;
								this.AddPriceFrom[index].agreementUnitId = el.id;
								this.SettleFrom.agreementUnitName = el.name;
								this.SettleFrom.agreementUnitId = el.id;
								this.SettleFrom.whetherRely = true;
							}
						});
					} else {
						this.BargainingUnitList.forEach((el) => {
							if (el.id == id) {
								this.AddPriceFrom[index].agreementName = el.name;
								this.AddPriceFrom[index].agreementUnitId = el.id;
								// this.SettleFrom.whetherRely = true;
							}
						});

					}
				} else if (index == 0 && !id) {
					console.log('协议单位清空吗了');
					this.AddPriceFrom[index].agreementName = "";
					this.AddPriceFrom[index].agreementUnitId = "";
					this.SettleFrom.whetherRely = false;
					this.SettleFrom.agreementUnitName = "";
					this.SettleFrom.agreementUnitId = "";
				}
			},
			//协议单位查看
			BargainingUnitAlls() {
				BargainingUnitAll().then((res) => {
					if (res.data.code === 0) {
						this.BargainingUnitList = res.data.data;
					}
				});
			},
			//获取付款名称
			houtyId() {
				this.payment.forEach((item) => {
					if (this.SettleFrom.paymentId === item.id) {
						console.log(item, "45444444444444");
						this.SettleFrom.paymentName = item.name;
					}
				});
			},

			//获取当前时间
			getNowDate() {
				var date = new Date();
				var sign2 = ":";
				var year = date.getFullYear(); // 年
				var month = date.getMonth() + 1; // 月
				var day = date.getDate(); // 日
				var hour = date.getHours(); // 时
				var minutes = date.getMinutes(); // 分
				var seconds = date.getSeconds(); //秒
				// 给一位数的数据前面加 “0”
				if (month >= 1 && month <= 9) {
					month = "0" + month;
				}
				if (day >= 0 && day <= 9) {
					day = "0" + day;
				}
				if (hour >= 0 && hour <= 9) {
					hour = "0" + hour;
				}
				if (minutes >= 0 && minutes <= 9) {
					minutes = "0" + minutes;
				}
				if (seconds >= 0 && seconds <= 9) {
					seconds = "0" + seconds;
				}
				this.settingTime = year + "-" + month + "-" + day;
				this.arrivalTimes =
					year +
					"-" +
					month +
					"-" +
					day +
					" " +
					hour +
					sign2 +
					minutes +
					sign2 +
					seconds;
			},
			//订单更新
			EditdataStatisticsed() {
				console.log(this.formLabelAlign, "2222222223");
				this.houtyId();
				// this.Bargainingputs(this.Doo)
				this.getNowDate();
				if (this.SettleFrom.whetherRely == true && this.SettleFrom.paymentName == '协议挂账') {
					//有挂账
					const params = {
						agreementUnitId: this.SettleFrom.agreementUnitId, //协议单位id
						arrivalTime: this.formLabelAlign.arrivalTime, //抵店时间
						breakfast: parseFloat(this.formLabelAlign.breakfast), //早餐份数
						clientType: this.formLabelAlign.clientType, //客户类型 0散户 1会员 2协议 3OTA
						otaName:this.formLabelAlign.otaName?this.formLabelAlign.otaName:'',
						createdBy: this.formLabelAlign.createdBy, //创建人ID
						createdTime: this.formLabelAlign.createdTime, //创建时间
						discount: parseFloat(this.formLabelAlign.discount), //	折扣率
						financeManagement: {
							//财务信息
							entryState: "2", //入账类型（1为普通，2为结账）
							agreementName: this.SettleFrom.agreementUnitName, //挂账单位
							agreementUnitId: this.SettleFrom.agreementUnitId, //挂账单位id
							classes: localStorage.getItem("classes"), //班次
							classesId: localStorage.getItem("classesId"), //班次id
							createdName: localStorage.getItem("name"), //操作员
							itemType: "0", //项目类型 0付款 1消费
							operator: localStorage.getItem("name"), //操作人
							orderId: this.formLabelAlign.id, //关联订单
							orgId: localStorage.getItem("orgid"), //部门ID
							paymentId: this.AddPriceFrom[0].paymentId, //付款方式id/消费方式id
							preAuth: true, //是否预授权（0 否 1是）默认0
							price: this.AddPriceFrom[0].BalanceOfAccount, //金额
							projectName: this.AddPriceFrom[0].paymentName, //项目名称
							remarks: this.formLabelAlign.remark, //备注
							roomId: this.formLabelAlign.roomId, //房间id
							state: true, //状态（1为启用，0为禁用）
							type: 1, //入账类型 0消费 1结算
							userClassesId: localStorage.getItem("userClassesId"), //用户班次表id
						},
						outRoomTime: this.arrivalTimes, //退房时间
						hourLength: this.formLabelAlign.hourLength, //钟点时长
						housePriceId: this.formLabelAlign.housePriceId, //房价码ID
						houseTypeId: this.formLabelAlign.houseTypeId, //房型id
						houseTypeName: this.formLabelAlign.echoMap.houseTypeId, //房型名称
						id: this.formLabelAlign.id,
						orderReserveId: this.formLabelAlign.orderReserveId == "暂无" ?
							null :
							this.formLabelAlign.orderReserveId, //预订单id
						orderState: false, //订单状态（1为在住，0为已退房）
						orgId: localStorage.getItem("orgid"),
						// "outRoomTime": this.formLabelAlign.outRoomTime,//退房时间
						paymentId: this.AddPriceFrom[0].paymentId, //付款方式id
						// "predepartureTime": this.formLabelAlign.predepartureTime,//预离时间
						remark: this.formLabelAlign.remark, //备注信息
						// reserveDays: parseFloat(this.formLabelAlign.reserveDays), //预住天数
						// "roomId": this.formLabelAlign.roomId,//房间id
						roomName: this.formLabelAlign.roomName, //房间号
						roomPrice: this.formLabelAlign.roomPrice, //房间门市单价
						roomRealityPrice: this.formLabelAlign.roomRealityPrice, //房价实际单价
						settleAccountsClasses: this.settingTime + "/" + localStorage.getItem("classes"), //结账班次
						settlementType: this.formLabelAlign.settlementType, //入驻类型 0全天房 1钟点房
						state: true, //状态
						whetherRely: true, //是否有挂账（1为是，0为否）
					};
					console.log(
						params.outRoomTime,
						"21333338888888888888888888888888888888888"
					);
					EditdataStatistics(params).then((res) => {
						if (res.data.code === 0) {
							this.$emit("onSettle", [false, "确认结账"]);
							this.$message({
								type: "success",
								message: "结账成功",
							});
							this.$confirm(`是否需要打印小票`, {
									confirmButtonText: "确定",
									cancelButtonText: "取消",
									type: "warning",
								})
								.then(() => {
									this.$message({
										type: "success",
										message: "确定打印小票",
										onClose: () => {
											this.ListFinanceedss(res.data.data, params);
										},
									});
								})
								.catch(() => {
									this.$message({
										type: "error",
										message: "取消打印小票",
										onClose: () => {},
									});
								});
						}
					});
				} else if (this.SettleFrom.whetherRely == false && this.SettleFrom.paymentName != '协议挂账') {
					this.getNowDate();
					const params = {
						agreementUnitId: null, //协议单位id
						arrivalTime: this.formLabelAlign.arrivalTime, //抵店时间
						breakfast: parseFloat(this.formLabelAlign.breakfast), //早餐份数
						clientType: this.formLabelAlign.clientType, //客户类型 0散户 1会员 2协议 3OTA
						otaName:this.formLabelAlign.otaName?this.formLabelAlign.otaName:'',
						createdBy: this.formLabelAlign.createdBy, //创建人ID
						createdTime: this.formLabelAlign.createdTime, //创建时间
						discount: parseFloat(this.formLabelAlign.discount), //	折扣率
						financeManagement: {
							//财务信息
							entryState: "2", //入账类型（1为普通，2为结账）
							agreementName: null, //挂账单位
							agreementUnitId: null, //挂账单位id
							classes: localStorage.getItem("classes"), //班次
							classesId: localStorage.getItem("classesId"), //班次id
							createdName: localStorage.getItem("name"), //操作员
							itemType: "0", //项目类型 0付款 1消费
							operator: localStorage.getItem("name"), //操作人
							orderId: this.formLabelAlign.id, //关联订单
							orgId: localStorage.getItem("orgid"), //部门ID
							paymentId: this.AddPriceFrom[0].paymentId, //付款方式id/消费方式id
							// "preAuth": true,//是否预授权（0 否 1是）默认0
							price: this.AddPriceFrom[0].BalanceOfAccount, //金额
							projectName: this.AddPriceFrom[0].paymentName, //项目名称
							remarks: this.formLabelAlign.remark, //备注
							roomId: this.formLabelAlign.roomId, //房间id
							state: true, //状态（1为启用，0为禁用）
							type: 1, //入账类型 0消费 1结算
							userClassesId: localStorage.getItem("userClassesId"), //用户班次表id
						},
						hourLength: this.formLabelAlign.hourLength, //钟点时长
						housePriceId: this.formLabelAlign.housePriceId, //房价码ID
						houseTypeId: this.formLabelAlign.houseTypeId, //房型id
						houseTypeName: this.formLabelAlign.echoMap.houseTypeId, //房型名称
						id: this.formLabelAlign.id,
						orderReserveId: this.formLabelAlign.orderReserveId == "暂无" ?
							null :
							this.formLabelAlign.orderReserveId, //预订单id
						orderState: false, //订单状态（1为在住，0为已退房）
						orgId: localStorage.getItem("orgid"),
						outRoomTime: this.arrivalTimes, //退房时间
						paymentId: this.AddPriceFrom[0].paymentId, //付款方式id
						// "predepartureTime": this.formLabelAlign.predepartureTime,//预离时间
						remark: this.formLabelAlign.remark, //备注信息
						// reserveDays: parseFloat(this.formLabelAlign.reserveDays), //预住天数
						// "roomId": this.formLabelAlign.roomId,//房间id
						roomName: this.formLabelAlign.roomName, //房间号
						roomPrice: this.formLabelAlign.roomPrice, //房间门市单价
						roomRealityPrice: this.formLabelAlign.roomRealityPrice, //房价实际单价
						settleAccountsClasses: this.settingTime + "/" + localStorage.getItem("classes"), //结账班次
						settlementType: this.formLabelAlign.settlementType, //入驻类型 0全天房 1钟点房
						state: true, //状态
						whetherRely: false, //是否有挂账（1为是，0为否）
					};
					console.log(
						params.outRoomTime,
						"12222222222222222222222222222222222222"
					);

					EditdataStatistics(params).then((res) => {
						if (res.data.code === 0) {
							this.$emit("onSettle", [false, "确认结账"]);
							this.$message({
								type: "success",
								message: "结账成功",
							});
							this.$confirm(`是否需要打印小票`, {
									confirmButtonText: "确定",
									cancelButtonText: "取消",
									type: "warning",
								})
								.then(() => {
									this.$message({
										type: "success",
										message: "确定打印小票",
										onClose: () => {
											this.ListFinanceedss(res.data.data, params);
										},
									});
								})
								.catch(() => {
									this.$message({
										type: "error",
										message: "取消打印小票",
										onClose: () => {},
									});
								});
						}
					});
				}
				console.log(this.formLabelAlign.orderReserveId);
			},
			//清空当前序号
			clearRoomSerial(id) {
				getGuestNo({
					flag: undefined,
					key: 'BT#' + id
				}).then((res) => {
					if (res.data.code == 0) {
						console.log('宾客', res.data.data);
					}
				})
			},
			//财务分页列表查询
			ListFinanceedss(row, ps) {
				const params = {
					current: 1,
					size: 100000,
					model: {
						orderId: this.$store.state.user.orderFrom.id,
						operator: localStorage.getItem("classesId"),
					},
				};
				ListFinance(params).then((res) => {
					if (res.data.code === 0) {
						this.tableDataFrom.splice(0)
						this.projectDetails.splice(0)
						this.tableDataFrom = res.data.data.records.filter((el) => {
							return el.updateState == "2" && !el.preAuth;
						});
						var projectDetailsarr=[];
						console.log(this.tableDataFrom);
						var priceArr = [];
						var Arr = [];
						this.tableDataFrom.forEach((el) => {
							priceArr.push(el.paymentId);
						});
						priceArr = [...new Set(priceArr)];
						priceArr.forEach((is) => {
							var nums = 0;
							var totalPrice = 0;
							this.tableDataFrom.forEach((el) => {
								if (is == el.paymentId) {
									if(el.preAuth){
										if(!el.authOver){
											nums = parseFloat(nums) + parseFloat(el.quantity);
											totalPrice = parseFloat(totalPrice) + parseFloat(el.price);
											this.name = el.projectName;
											this.type = el.itemType;
										}
									}else{
										nums = parseFloat(nums) + parseFloat(el.quantity);
										totalPrice = parseFloat(totalPrice) + parseFloat(el.price);
										this.name = el.projectName;
										this.type = el.itemType;
									}
								}
							});

							this.projectDetails.push({
								name: this.name,
								number: nums,
								totalPrice: parseFloat(totalPrice),
								type: this.type,
							});
							projectDetailsarr.push({
								name: this.name,
								number: nums,
								totalPrice: (parseFloat(totalPrice)).toFixed(2),
								type: this.type,
							})
						});
						console.log(this.projectDetails);
						this.projectDetails.forEach(el => {
							el.totalPrice = el.totalPrice.toFixed(2)
						})
						this.tableDataFrom.forEach((item) => {
							if (item.itemType == 0) {
								//付款
								this.paymentPrice =
									parseFloat(this.paymentPrice) +
									parseFloat(item.quantity) * parseFloat(item.price);
							} else if (item.itemType == 1) {
								//消费totalPrice
								this.totalPrice =
									parseFloat(this.totalPrice) +
									parseFloat(item.quantity) * parseFloat(item.price);
							}
						});
						this.printertextreceipted(row, ps,projectDetailsarr);
					}
				});

				// console.log(this.$store.state.user.orderFrom);
			},
			//打印小票接口
			printertextreceipted(row, ps,projectDetailsarr) {
				if(!projectDetailsarr.length){
					this.lunnum++;
					if(this.lunnum<10){
						this.ListFinanceedss(row, ps);
						return false;
					}
				}
				const params = {
					createdName: this.$store.state.user.orderFrom.echoMap.createdBy,
					name: localStorage.getItem("orgName"),
					orderId: row.id,
					paymentPrice: parseFloat(this.paymentPrice).toFixed(2),
					// projectDetails: [
					// 	// {
					// 	//   date: "11.11",
					// 	//   name: "房费",
					// 	//   number: "1",
					// 	//   price: "",
					// 	//   totalPrice: "60",
					// 	//   type: "0",
					// 	// },
					// 	...this.projectDetails,
					// ],
					projectDetails:projectDetailsarr,
					roomNumber: row.roomName,
					settleAccountsDate: row.updatedTime,
					tableName: "",
					totalPrice: parseFloat(ps.financeManagement.price),
					type: "1",
				};
				params.paymentPrice = 0 //付款合计
				params.totalPrice = 0 //消费合计
				
				projectDetailsarr.forEach(el => {
					if (el.type == 1) {
						//消费
						params.totalPrice = parseFloat(params.totalPrice) + parseFloat(el.totalPrice)
					} else if (el.type == 0) {
						//付款
						params.paymentPrice = parseFloat(params.paymentPrice) + parseFloat(el.totalPrice)
					}
				})
				
				// this.projectDetails.forEach(el => {
				// 	if (el.type == 1) {
				// 		//消费
				// 		params.totalPrice = parseFloat(params.totalPrice) + parseFloat(el.totalPrice)
				// 	} else if (el.type == 0) {
				// 		//付款
				// 		params.paymentPrice = parseFloat(params.paymentPrice) + parseFloat(el.totalPrice)
				// 	}
				// })
				printertextreceipt(params).then((res) => {
					if (res.data.code === 0) {
						this.$message({
							type: 'success',
							message: '小票打印成功'
						})
					} else {
						if (res.data.errorMsg == '空指针异常') {
							this.$message({
								type: 'error',
								message: '未添加打印机或打印机参数配置异常'
							})
						}
					}

				})
			},
			//财务管理表之新增接口
			AddFinanceed(params) {
				AddFinance(params).then((res) => {
					if (res.data.code === 0) {
						this.$message({
							type: "success",
							message: "入账成功",
							onClose: () => {
								this.ListFinanceed();
								// this.Bargainingputs(this.Doo)
							},
						});
					}
				});
			},
			//财务分页列表查询
			ListFinanceed(model = {}) {
				const params = {
					current: this.current,
					size: 10000,
					model: {
						...model,
						state: true,
						orderId: this.$store.state.user.orderFrom.id,
					},
				};
				ListFinance(params).then((res) => {
					if (res.data.code === 0) {
						// this.tableData =
						var newTime = new Date().getTime()
						var predepartureTime = new Date(this.formLabelAlign.predepartureTime).getTime()
						if (newTime > predepartureTime) {
							//超时了
							var price = 0
							if (this.formLabelAlign.settlementType == 0) {
								//全天房
								price = parseFloat(this.formLabelAlign.reserveDays) * parseFloat(this
									.formLabelAlign.roomRealityPrice)
							} else if (this.formLabelAlign.settlementType == 1) {
								//钟点房
								price = parseFloat(this.formLabelAlign.roomRealityPrice)
							}
							this.FulldayRoomrate = {
								//全日房费的新增财务数据
								price: parseFloat(price),
								orderId: this.formLabelAlign.id,
								// authorizationCode: currentRoom.authorizationCode,
								// bankNumber: this.currentRoom.bankNumber,
								itemType: "1",
								state: true,
								roomId: this.formLabelAlign.roomId,
								roomName: this.formLabelAlign.roomName, //房型名称
								userClassesId: localStorage.getItem("userClassesId"),
								operator: localStorage.getItem("name"),
								paymentId: null,
								projectName: null,
								type: "0",
								classesId: localStorage.getItem("classesId"),
								createdName: localStorage.getItem("name"),
								classes: localStorage.getItem("classes"),
								// voucherNo: this.ruleForm.voucherNo,
								remark: this.formLabelAlign.remark,
							};
						} else {
							//，没超时
							this.getNowDates(new Date(), this.formLabelAlign.arrivalTime, this.formLabelAlign)
							var price = 0
							if (this.formLabelAlign.settlementType == 0) {
								//全天房
								price = parseFloat(this.formLabelAlign.reserveDays) * parseFloat(this
									.formLabelAlign.roomRealityPrice)
							} else if (this.formLabelAlign.settlementType == 1) {
								//钟点房
								price = parseFloat(this.formLabelAlign.roomRealityPrice)
							}
							this.FulldayRoomrate = {
								//全日房费的新增财务数据
								price: parseFloat(price),
								orderId: this.formLabelAlign.id,
								// authorizationCode: currentRoom.authorizationCode,
								// bankNumber: this.currentRoom.bankNumber,
								itemType: "1",
								state: true,
								roomId: this.formLabelAlign.roomId,
								roomName: this.formLabelAlign.roomName, //房型名称
								userClassesId: localStorage.getItem("userClassesId"),
								operator: localStorage.getItem("name"),
								paymentId: null,
								projectName: null,
								type: "0",
								classesId: localStorage.getItem("classesId"),
								createdName: localStorage.getItem("name"),
								classes: localStorage.getItem("classes"),
								// voucherNo: this.ruleForm.voucherNo,
								remark: this.formLabelAlign.remark,
							};
						}


						this.getPageHouseshops();
						this.tableData.splice(0);

						res.data.data.records.forEach((el) => {
							if (!el.authOver && !el.preAuth && el.state) {
								this.tableData.push(el);
							}
						});
						// consumption
						this.getPriceNumber();
						this.outDataTable();

						// if(this.endPriceFrom.length > 0) {
						//   this.endPriceFrom.forEach(el => {

						//   })

						// }
						if (this.$store.state.user.orderFrom.settlementType == 0) {
							//全天房
							this.projectNames = "全日房费";
						} else {
							//钟点房
							this.projectNames = "钟点房费";
						}

						var nums = 0;
						console.log(this.projectNames);
						res.data.data.records.forEach((it) => {
							if (it.projectName == this.projectNames) {
								nums = 1;
								// this.isFulldayRoomrate = true
								// this.dialogVisible = true
							}
						});
						this.listCheckOutTimed(); //退房时间接口
						console.log(nums, "13123123123");
						if (nums != 1) {
							//没有房费

							console.log("217831983789123719371232183182321");
							this.UnitBargainingLooked();
						}
					}
				});
			},
			//判断有没有超时
			outDataTable() {
				console.log(this.formLabelAlign);
				var predepartureTime = new Date(
					this.formLabelAlign.predepartureTime
				).getTime();
				var newTime = new Date().getTime();
				if (parseFloat(newTime) > parseFloat(predepartureTime)) {
					//超时了
					if (this.formLabelAlign.settlementType == "0") {
						//全天房
						var days = Math.ceil(
							(parseFloat(newTime) - parseFloat(predepartureTime)) /
							(1 * 24 * 60 * 60 * 1000)
						);
						var outPrice =
							days * parseFloat(this.formLabelAlign.roomRealityPrice);
						console.log(days, outPrice);
						//财务管理表之新增数据
						var params = {
							price: outPrice,
							orderId: this.formLabelAlign.id,
							itemType: "1",
							state: true,
							roomId: this.formLabelAlign.roomId,
							roomName: this.formLabelAlign.roomName, //房型名称
							userClassesId: localStorage.getItem("userClassesId"),
							operator: localStorage.getItem("name"),
							paymentId: "372735506967953484",
							projectName: "超时费",
							entryState: "2",
							type: "0",
							classesId: localStorage.getItem("classesId"),
							createdName: localStorage.getItem("name"),
							classes: localStorage.getItem("classes"),
						};
						this.bargainingUnit.forEach((el) => {
							if (el.name == "超时费") {
								params.paymentId = el.id;
							}
						});
						this.$confirm(
								`该用户留店时间已超出预留时间，是否加收全天房超时费用: ￥${outPrice}`, {
									confirmButtonText: "确定",
									cancelButtonText: "取消",
									type: "warning",
								}
							)
							.then(() => {
								this.$message({
									type: "success",
									message: "增加超时费用打开入账入口",
								});
								this.tableData.push({
									price: params.price,
									itemType: "1",
									projectName: params.projectName,
								});
								console.log(this.tableData);
								this.endPriceFrom.push(params);
								this.consumption =
									parseFloat(this.consumption) + parseFloat(params.price);
								if (this.consumption < 0) {
									this.AddPriceFrom[0].BalanceOfAccount = parseFloat(
										this.consumption + this.payments
									).toFixed(2);
									this.AddPriceFrom[0].BalanceOfAccount =
										parseFloat(this.AddPriceFrom[0].BalanceOfAccount).toFixed(2);
									return parseFloat(this.consumption + this.payments);
								} else {
									this.AddPriceFrom[0].BalanceOfAccount = parseFloat(
										this.consumption - this.payments
									).toFixed(2);
									this.AddPriceFrom[0].BalanceOfAccount =
										parseFloat(this.AddPriceFrom[0].BalanceOfAccount).toFixed(2);
									return parseFloat(this.consumption - this.payments);
								}
							})
							.catch(() => {
								this.$message({
									type: "info",
									message: "已取消增加超时费用操作",
								});
							});
					}
				} else if (this.formLabelAlign.settlementType == "1") {
					//钟点房超时房费
					let days = Math.ceil(
						(parseFloat(newTime) - parseFloat(predepartureTime)) /
						(60 * 60 * 1000)
					);
					console.log(newTime, predepartureTime);
					var outPrice = days * parseFloat(this.formLabelAlign.reserveDays);
					var params = {
						price: outPrice,
						orderId: this.formLabelAlign.id,
						itemType: "1",
						state: true,
						roomId: this.formLabelAlign.roomId,
						roomName: this.formLabelAlign.roomName, //房型名称
						userClassesId: localStorage.getItem("userClassesId"),
						operator: localStorage.getItem("name"),
						paymentId: "372735506967953484",
						projectName: "超时费",
						type: "0",
						entryState: "2",
						classesId: localStorage.getItem("classesId"),
						createdName: localStorage.getItem("name"),
						classes: localStorage.getItem("classes"),
					};
					this.bargainingUnit.forEach((el) => {
						if (el.name == "超时费") {
							params.paymentId = el.id;
						}
					});
					this.$confirm(
							`该用户留店时间已超出预留时间，是否加收全天房超时费用: ￥${outPrice}`, {
								confirmButtonText: "确定",
								cancelButtonText: "取消",
								type: "warning",
							}
						)
						.then(() => {
							this.$message({
								type: "success",
								message: "增加超时费用打开入账入口",

							});
							this.tableData.push({
								price: params.price,
								itemType: "1",
								projectName: params.projectName,
							});
							console.log(this.tableData);
							this.endPriceFrom.push(params);
						})
						.catch(() => {
							this.$message({
								type: "info",
								message: "已取消增加超时费用操作",
							});
						});
				}
			},

			// 点击右上角的图标隐藏弹窗
			close_page(e) {
				console.log(e.target.className);
				console.log(e.target.nodeName);
				if (e.target.className === "el-icon-close") {
					this.$emit("onSettle", [false]);
				}
			},

			//付款方式的查看
			paymentListed() {
				paymentList().then((res) => {
					if (res.data.code === 0) {
						this.payment = res.data.data.filter(
							(el) =>
							el.name != "挂账" &&
							el.name != "微储值" &&
							el.name != "挂房账" &&
							el.name != "餐饮挂账" &&
							el.name != "商超挂账" &&
							el.name != "预授权" &&
							el.name != "微储值"
						);
					}
				});
			},
			//消费项目的请求
			UnitBargainingLooked() {
				UnitBargainingLook().then((res) => {
					if (res.data.code === 0) {
						this.bargainingUnit = res.data.data;
						res.data.data.forEach((el) => {
							if (el.name === this.projectNames) {
								this.FulldayRoomrate.projectName = el.name;
								this.FulldayRoomrate.paymentId = el.id;

								this.tableData.push({
									price: this.FulldayRoomrate.price,
									itemType: "1",
									projectName: this.FulldayRoomrate.projectName,
								});
							}

							this.getPriceNumber();
							console.log(el);
						});
						this.endPriceFrom.push(this.FulldayRoomrate);
						if (!this.FulldayRoomrate.projectName) {
							this.$message({
								type: "error",
								message: `没有${this.projectNames}该项目`,
							});
						}
						this.consumption =
							parseFloat(this.consumption) +
							parseFloat(this.FulldayRoomrate.price);
						if (this.consumption < 0) {
							this.AddPriceFrom[0].BalanceOfAccount = parseFloat(
								this.consumption + this.payments
							).toFixed(2);
							this.AddPriceFrom[0].BalanceOfAccount =
								parseFloat(this.AddPriceFrom[0].BalanceOfAccount).toFixed(2);
							this.BalanceOfAccount = parseFloat(
								this.consumption + this.payments
							).toFixed(2);
							this.BalanceOfAccount = parseFloat(this.BalanceOfAccount).toFixed(2);
						} else {
							this.AddPriceFrom[0].BalanceOfAccount = parseFloat(
								this.consumption - this.payments
							).toFixed(2);
							this.AddPriceFrom[0].BalanceOfAccount =
								parseFloat(this.AddPriceFrom[0].BalanceOfAccount).toFixed(2);
							this.BalanceOfAccount = parseFloat(
								this.consumption - this.payments
							).toFixed(2);
							this.BalanceOfAccount = parseFloat(this.BalanceOfAccount).toFixed(2);
						}
					} else {
						this.$message({
							type: "error",
							message: "请求异常",
							onClose: () => {},
						});
					}
				});
			},
			//倒计时结束后触发事件
			hilarity() {
				this.$emit("onSettle", [false]);
			},
			//会员结账触发的自定义事件
			MemberPrice(row) {
				if (row[0] == 1) {
					this.isConsumer = false
				} else {
					this.isConsumer = false
					this.AddPriceFrom[0].paymentName = row[0].projectName
					this.AddPriceFrom[0].paymentId = row[0].paymentId
					this.SettleFrom.paymentName = row[0].projectName
					this.SettleFrom.paymentId = row[0].paymentId
					this.onSubmit(2)
				}

			},
			//点击确认结账触发事件
			onSubmit(num) {
				if (num == 1) {
					//会员结账
					this.isConsumer = true;
					this.$nextTick(() => {
						this.$refs.ConsumerBox.price = this.AddPriceFrom[0].BalanceOfAccount
						this.$refs.ConsumerBox.ruleForm.tableData.push(this.formLabelAlign); //进来的放单信息
						this.$refs.ConsumerBox.Getpaymented();
					});
				} else if (num == 2) {
					var xieyi = 0;
					this.AddPriceFrom.forEach((el, index) => {
						console.log('测试', index)
						if (el.paymentName == '协议挂账') {
							if (el.BalanceOfAccount < 0) {
								el.paymentId = ''
								this.PriceInput(el.paymentId, index)
								this.$message({
									type: 'error',
									message: '金额为负数无法挂账'
								})
								return false
							}
							if(!el.agreementUnitId){
								xieyi = 1;
								
							}
						}

					});
					if(xieyi == 1){
						this.$message({
							type: 'error',
							message: '请选择协议单位'
						})
						return false
					}
					this.endNums = 1;
					if (this.AddPriceFrom.length > 1) {
						var parNums = 0;
						this.parBUnssS = 0;
						this.AddPriceFrom.forEach((it) => {
							if (!it.paymentId) {
								parNums = 1;
							}
							if (!it.BalanceOfAccount) {
								this.parBUnssS = 2;
							}
						});
						if (this.parBUnssS == 2) {
							this.$message({
								type: "error",
								message: "请填写金额",
							});
						}
						if (parNums != 1 && this.parBUnssS != 2) {
							this.financeManagementSaveListed(this.$store.state.user.orderFrom);
						} else {
							this.$message({
								type: "error",
								message: "请再次确定付款方式是否选择",
							});
						}
					} else {
						if (this.SettleFrom.paymentName || this.SettleFrom.agreementUnitId) {
							this.AddPriceFrom.forEach((it) => {
								if (!it.paymentId) {
									parNums = 1;
								}
							});
							if (parNums != 1) {
								if (this.endPriceFrom.length > 0) {
									console.log(this.endPriceFrom);
									// return false
									this.financeManagementSaveListed();
								} else {
									this.EditdataStatisticsed();
								}
							} else {
								this.$message({
									type: "error",
									message: "请再次确定付款方式是否选择",
								});
							}
						} else {
							this.$nextTick(() => {
								this.$message({
									type: "error",
									message: "请选择付款方式",
								});
							});
						}
					}
				}
				// this.AddPriceFrom[it].paymentName = this.payment[index].name;BalanceOfAccount
				//   this.AddPriceFrom[it].paymentId = this.payment[index].id;
				//this.AddPriceFrom[it].paymentName

			},
			AddSubmit() {
				//增加付款
				this.AddPriceFrom.push({
					BalanceOfAccount: null, //价格
					fooo: null, //付款方式的下标
					Doo: null, //协议单位的下表
					isInput: false,
					paymentId: null,
					paymentName: null,
					isDisabled: false,
					agreementName: null, //挂账单位
					agreementUnitId: null, //挂账单位id
				});
			},
			//删除增加的付款
			DeleteArr(index) {
				this.AddPriceFrom.splice(index, 1);
				console.log(index);
			},
			//退房时间分页列表查询
			listCheckOutTimed() {
				const params = {
					current: 1,
					size: 1000,
					model: {
						orgId: localStorage.getItem("orgid"),
						state: true,
					},
				};

				listCheckOutTime(params).then((res) => {
					if (res.data.code === 0) {
						this.CheckOuttime = res.data.data.records;
						if (this.formLabelAlign.clientType == "0") {
							//散客
							this.forms.CheckOuttimeName = "散客退房时间";
						} else if (this.formLabelAlign.clientType == "1") {
							//会员
							this.forms.CheckOuttimeName = "会员客类退房时间";
						} else if (this.formLabelAlign.clientType == "2") {
							//协议
							this.forms.CheckOuttimeName = "协议客类退房时间";
						} else if (this.formLabelAlign.clientType == "3") {
							//OTA
							this.forms.CheckOuttimeName = "OTA客类退房时间";
						}

						this.CheckOuttime.forEach((el) => {
							if (el.name == this.forms.CheckOuttimeName) {
								this.formLabelAlign.predepartureTime =
									this.formLabelAlign.predepartureTime.substring(0, 10) +
									" " +
									el.checkOutTime;
								// this.GetOvertimeCharge(this.formLabelAlign.predepartureTime);
							}
						});
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	body .el-table::before {
		z-index: inherit;
	}

	.DeteleButton {
		background-color: #fdd4d9;
		border-radius: 8px;
		margin-left: 10px;
		height: 40px;
		border: none;
		color: red;
	}

	.SettleAccounts {
		width: 100%;
		max-height: 680px;
		overflow-y: auto;
		box-sizing: border-box;
		background-color: #fff;

		.dialogBox {
			::v-deep .el-dialog__header {
				// background-color: #e8fdfe;
			}

			::v-deep .el-dialog__body {
				// background-color: #e8fdfe;
				height: 400px;
				overflow-y: auto;

				.addPriceBox {
					height: 400px;
					overflow-y: auto;
				}
			}
		}

		.SettleAccounts_main {
			width: 100%;

			display: flex;
			flex-direction: column;
			align-items: center;
			// background: linear-gradient(177deg, #e8fdfe 0%, #ffffff 100%);
			border-radius: 5px;

			.table_box {
				width: 100%;
				display: flex;
				justify-content: center;
			}

			// 金额的汇总css
			.settle_statistics {
				width: 100%;
				height: 50px;
				display: flex;
				justify-content: center;
				align-content: center;

				ul {
					width: 100%;
					height: 50px;
					display: flex;
					padding-left: 10px;
					align-items: center;

					li {
						margin-right: 15px;

						span {
							color: #5cb5b8;
						}
					}

					div {
						border-right: 2px solid rgb(177, 170, 170);
						height: 10px;
						margin-right: 15px;
					}
				}
			}

			.customerInformation {
				width: 100%;
				padding-top: 20px;
				box-sizing: border-box;
			}

			.payment_settlement {
				width: 100%;
				height: 150px;
				overflow-y: auto;
				margin-top: 20px;
				padding-left: 15px;
				border-radius: 8px;
				box-sizing: border-box;
				background: #ffffff;
				border: 1px solid #ededed;

				p {
					height: 50px;
					line-height: 50px;
					text-align: left;
					display: flex;
					align-items: center;

					span {
						width: 10px;
						height: 10px;
						background-color: #5cb5b8;
						border-radius: 50%;
						margin-right: 10px;
					}
				}
			}

			.ending {
				width: 100%;
				height: 60px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-top: 15px;

				.ending_left {
					display: flex;

					span {
						width: 170px;
						text-align: right;
						color: #999999;
					}

					.statistic_main {
						display: flex;
					}
				}

				.ending_right {
					padding-right: 30px;
					box-sizing: border-box;

					.addButton {
						border-radius: 6px;
						height: 40px;
						width: 120px;
						border: none;
						padding: 0px 0px;
						background-color: #5cb5b8;
						color: #fff;
					}

					.deleteButton {
						background-color: #fdd4d9;
						border-radius: 8px;
						height: 40px;
						width: 120px;
						border: none;
						padding: 0px 0px;
						color: red;
					}
				}
			}
		}
	}

	.checkout-color1 {
		color: #5cb5b8;
	}

	.checkout-color2 {
		color: #ff0000;
	}
</style>