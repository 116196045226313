<template>
  <el-row class="common-head" onselectstart="return false">
    <el-col :span="10" class="head-fl">
      <el-col :span="1">
        <i
            @click="toggleMenu"
            :class="
          $store.state.base.navCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'
        "
        ></i>
      </el-col>
      <el-col :span="23">
        <el-row>
          <p>哈庐酒店管理系统 - {{ currentSubSystemLabel() }}</p>
        </el-row>
        <el-row>
          <p class="org-name">{{ currentOrgName() }}</p>
        </el-row>
      </el-col>
    </el-col>

    <el-col :span="14" class="head-right">
      <el-row class="search_right">
  <el-col :span="10" class="search_input">
        <!-- <el-input
          :style="{'opacity': this.$store.state.user.pathRouth == '/wineshop/sys_floo'?1: 0}"
          placeholder="搜索姓名和房号"
          @change="RoomStatusListrd(input2)"
          suffix-icon="el-icon-search"
          size="mini"
          v-model="input2"
        >
        </el-input> -->

      </el-col>
       <!-- 邮箱和营业时间 -->
      <el-col :span="14" :offset="10" class="message_right" >
        <el-row class="userInfo">
          <el-col class="iconBox" :span="12" >
          </el-col>
          <el-col :span="12" class="mailBox">
          <el-row class="userInforBox">
          <el-col :span="24" class="mailbox2">
            <img class="person-image" src="../../../assets/icons/用户.png" alt=""  />
            <span class="userNameSpan">{{name}}</span>
            <img  src="../../../assets/icons/通知.png" alt="" class="notice-img" @click="drawershow"/>
				    <img
				      src="../../../assets/icons/返回1.png"
				      alt=""
				      @click="logOut"
				      class="logOut"
				    />
          </el-col>
          <el-col :span="24" class="business">
            <img  src="../../../assets/icons/日期.png" alt=""  class="date-image"/>
            <span class="date-label">营业日</span>
            <span class="time-label">{{ overheadSun }} {{timeOnDuty.classes?timeOnDuty.classes:''}}</span>
        </el-col>
          </el-row>
          </el-col>
        </el-row>
      </el-col>
      <!-- 邮箱和营业时间 -->
      </el-row>
      <!-- 搜索框 -->
    </el-col>
    <dash-board v-show="false" ref="DashBoard_BOx"></dash-board>

  </el-row>
</template>

<script>
import { ClassesGet } from "_api/orderFrom";
import {AddOrder} from '_api/index'
import {  RoomStatusList } from "_api/conditionOfHouse";
import DashBoard from '../../DashBoard'

import WebSocketService from '_api/WebSocketService';

export default {
  mixins: [WebSocketService],
  components: {
    DashBoard
  },
  data() {
    return {
      input2: "",
      timeOnDuty: {},//获取该部门当班信息
      overheadSun: '',
      name: localStorage.getItem('name') ,//用户名

	  isSpeaking: false ,// 标记是否正在播放语音
    };
  },
  created() {
    this.ClassesGeted();
    this.initDate()

    // this.AddOrdered()
  },
  methods: {
    // AddOrdered() {
    //   const param = {
    //     orgId: localStorage.getItem('orgid'),
    //     userId: localStorage.getItem('userId'),
    //     userName: localStorage.getItem('name')
    //   }
    //   AddOrder(param).then(res => {

    //   })
    // },
    //房态列表/根据姓名和房号搜素
    RoomStatusListrd(name) {
      const params = {
        codeName: name,
        orgId: localStorage.getItem('orgid')
      }
      RoomStatusList(params).then(res => {
        if(res.data.code === 0){
          var sum = 0
          if(res.data.data.length != 0) {
            this.$message({
          type: 'success',
          message: '搜索成功'
        })
                  sum = sum-1
             this.$store.commit('base/SETSEARCH',{records:res.data.data,num: sum})
             console.log(this.$store.state.base.searchNUM);
             this.$nextTick(() => {
          this.$refs.DashBoard_BOx.getroomview()
         })

          }else{
            this.$message({
          type: 'success',
          message: '没有匹配的数据'
        })
             sum = sum+1
            this.$store.commit('base/SETSEARCH', {records:res.data.data,num: sum})
            console.log(this.$store.state.base.searchNUM);
            this.$nextTick(() => {
          this.$refs.DashBoard_BOx.getroomview()
         })

          }


        }
      })
    },
    currentOrgName() {
      return localStorage.getItem('orgName')
    },
    currentSubSystemLabel() {
      const id = localStorage.getItem('ApplicationId')
      let label = ""
      switch (id) {
        case "1":
          label = "前台管理系统";
          break;
        case "4":
          label = "商超系统";
          break;
        case "3":
          label = "餐饮系统";
          break;
        case "366448864552551303":
          label = "品牌管理系统";
          break;
        default:
          label = "";
      }
      return label;
    },
    //获取该部门当班信息
    ClassesGeted() {
      if(localStorage.getItem('ApplicationId') == 1) {
        ClassesGet({ orgId: localStorage.getItem("orgid") }).then((res) => {
        if (res.data.code === 0) {
          this.timeOnDuty = res.data.data
          localStorage.setItem('classesId', res.data.data.classesId)
          localStorage.setItem('classes', res.data.data.classes)
          localStorage.setItem('userClassesId', res.data.data.id)
          localStorage.setItem('currentUseId',res.data.data.userId)
        }
      });
      }

    },
    toggleMenu() {
      this.$store.commit("base/TOGGLE_MENU");
    },
    //返回系统选择页面
    logOut() {
      // localStorage.removeItem("token");
      // localStorage.removeItem("orgid");
      // localStorage.removeItem("TenantId");
      localStorage.removeItem("ApplicationId");
      localStorage.removeItem("DynamicPath");
      localStorage.removeItem("li_index");
      // localStorage.removeItem("employeeId");
      this.$router.replace('/systematic');
    },
     //当天日期
     mouseenter(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + m + "-" + d;
    },
    initDate() {
      this.overheadSun = this.mouseenter(new Date());
    },

	//消息
	sendMessage() {
	  if (this.socket && this.socket.readyState === WebSocket.OPEN) {
	    const message = 'Hello, WebSocket!'; // 替换为你要发送的消息
	    this.socket.send(message);
	  } else {
	    Message.warning('WebSocket连接未打开');
	  }
	},
	handleMessage(message) {
	  // 处理接收到的消息
	  // const parsedMessage = JSON.parse(message);
	  console.log("------wwf------",message);
	  this.open(JSON.parse(message));
	  // this.open(message);
	},
	open(message) {
		this.$notify({
	      title: '订单来了',
	      dangerouslyUseHTMLString: true,
	      message: '<strong>您有一笔新的 <i>'+ message.bizName+'</i> 订单待处理</strong>'
	    });
		var str='';
		console.log('消息',message);
		// if(message.bizType=='1'){
		// 	str='房间';
		// }else if(message.bizType=='2'){
		// 	str='餐饮';
		// }else if(message.bizType=='3'){
		// 	str='商超';
		// }
		this.speak('您有一笔新的'+message.bizName+'订单待处理');
	},

	async speak(text) {
		console.log('进入')
		if (!this.isSpeaking) { // 如果没有正在播放就进行播放
			this.isSpeaking = true; // 标志为正在播放
			const utterance = new SpeechSynthesisUtterance(text);
				utterance.onend = () => {
				this.isSpeaking = false; // 播放结束后置标志为未播放
			};
			utterance.rate = 0.8;
			utterance.voice = speechSynthesis.getVoices()[0];
			speechSynthesis.speak(utterance);
		} else {
			return ''
		}
	},

	drawershow(){
		this.$emit('drawershow');
	},

  },
};
</script>

<style lang="scss" scoped>

.common-head {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;

  .head-fl {
    width: 100%;
    display: flex;
    align-items: center;
    i {
      margin-right: 10px;
      font-size: 20px;
      cursor: pointer;
    }
    p {
      width: 100%;
      height: 25px;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 25px;
    }
    .org-name {
      font-size: 18px;
      font-weight: 300;
      color: #666666;
    }
  }
  .head-right {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
   .search_right{
    width: 100%;
     height: 100%;
    //line-height: 86px;

   }
   .message_right{
    // display: flex;
    // width: 100%;
     height: 100%;


    .userInfo{
      width: 100%;
      height: 100%;

      .iconBox{
        text-align: end;
        height: 100%;
        line-height: 50px;
        padding-right: 10px;
        box-sizing: border-box;
        position: relative;
        .el-icon-bell{
          position: absolute;
          top: 15px;
          right: -165px;
          font-size: 20px;
        }
      }
      .mailBox{
        height: 100%;

        .userInforBox{
          height: 100%;
          box-sizing:border-box;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .mailbox2{
            width: 100%;
            height: 40%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .person-image {
              height: 26px;
            }

            .userNameSpan{
              padding-left: 6px;
              flex-grow: 1;
			  font-size: 14px;
            }
            .notice-img {
              height: 26px;
            }
            .logOut{
              padding-left: 10px;
              padding-right: 8px;
              height: 26px;
            }
          }

          //}
          .business{
            width: 100%;
            height: 40%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .date-image {
              height: 26px;
            }

            .date-label {
              padding: 6px;
			  font-size: 14px;
            }
            .time-label {
              padding: 6px;
			  font-size: 14px;
            }
          }
        }
  }
    }

   }

  }
}
</style>
