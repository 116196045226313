<template>
  <!-- 维修房取消 -->
  <div class="MaintainUpdata">
    <el-form
      :model="ruleForm"
      ref="ruleForm"
      :inline="true"
      label-width="70px"
      class="demo-ruleForm"
    >
      <el-form-item label-width="80px" label="房号:" prop="roomName">
        <el-input style="width: 135px" v-model="ruleForm.roomName"></el-input>
      </el-form-item>
      <el-form-item label="流水号:" prop="id">
        <el-input style="width: 180px" v-model="ruleForm.id"></el-input>
      </el-form-item>
      <el-form-item label-width="80px" label="开始时间:">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="ruleForm.createdTime"
          type="date"
          style="width: 135px"
          placeholder="选择日期"
          :disabled="true"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item label-width="80px" label="结束时间:">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="ruleForm.endTime"
          type="date"
          :disabled="ruleForm.unlimitedDuration"
          style="width: 135px"
          placeholder="选择日期"
        >
        </el-date-picker>

        <el-checkbox
			v-if="!operateshow"
          style="margin-left: 10px"
          v-model="ruleForm.unlimitedDuration"
          >无限期</el-checkbox
        >
      </el-form-item>

      <el-form-item label-width="80px" label="维修类型:">
        <!-- <el-input v-model="ruleForm.maintenanceType"></el-input> -->
        <el-select
          style="width: 420px"
          v-model="ruleForm.maintenanceType"
          placeholder="请选择"
        >
          <el-option label="坏房" :value="1"></el-option>
          <el-option label="维修房" :value="2"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="原因:" label-width="80px">
        <el-input
          style="width: 420px"
          type="textarea"
          :rows="3"
          v-model="ruleForm.remark"
        >
        </el-input>
      </el-form-item>
    </el-form>

    <el-row style="text-align: right">
      <el-button style="border-radius: 8px" @click="openoperator">
        操作日志
      </el-button>
      <el-tooltip placement="top">
        <div slot="content">
          <div>确定结束此维修吗?此操作不可回撤</div>
          <div class="btu-box">
            <el-button class="subButton" @click="CancelRoomed" type="primary"
              >确定</el-button
            >
            <el-button class="subButton" @click="offOperator" type="primary"
              >取消</el-button
            >
          </div>
        </div>
        <el-button
          style="background: #fdd4d9; border-radius: 8px; color: #d9001b"
        >
          维修结束
        </el-button>
      </el-tooltip>

      <el-button style="border-radius: 8px" @click="offOperator">
        取消
      </el-button>
      <el-button
        style="background: #5cb5b8; border-radius: 8px; color: #fff"
        @click="editoperator"
      >
        保存修改
      </el-button>
    </el-row>

    <operator-table
      ref="operatortable"
      v-if="operateshow"
      @offOperator="colsermen"
    ></operator-table>
  </div>
</template>

<script>
import {
  MonomerRoom,
  CancelRoom,
  editMonomerRoom,
} from "_api/conditionOfHouse";
import { roommaintenanceadd } from "@/api/housingSystem";
import OperatorTable from "./OperatorTable.vue";
export default {
  name: "MaintainUpdata",
  props: {
    // roomInfo: {
    //   type: Object,
    //   required: true,
    // },
  },
  components: {
    OperatorTable,
  },
  data() {
    return {
      ruleForm: {
        endTime: null,
        maintenanceType: null,
        orgId: null,
        remark: '',
        roomId: null,
        state: false,
        unlimitedDuration: false,
        version: 0,
      },
      roomFrom: {},
      ids: null,
      isPull: false,
      rules: {
        name: [
          {
            required: true,
            message: "请输入活动名称",
            trigger: "blur",
          },
          {
            min: 3,
            max: 5,
            message: "长度在 3 到 5 个字符",
            trigger: "blur",
          },
        ],
        region: [
          {
            required: true,
            message: "请选择活动区域",
            trigger: "change",
          },
        ],
        date1: [
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        date2: [
          {
            type: "date",
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
        type: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个活动性质",
            trigger: "change",
          },
        ],
        resource: [
          {
            required: true,
            message: "请选择活动资源",
            trigger: "change",
          },
        ],
        desc: [
          {
            required: true,
            message: "请填写活动形式",
            trigger: "blur",
          },
        ],
      },
      overheadSun: "",
      operateshow: false,
      roomInfo: {},
    };
  },
  created() {
    // this.initDate();
    // this.ruleForm.roomId = this.roomInfo.id;
    // this.MonomerRoomed()
  },
  methods: {
    //查看维修房详情
    MonomerRoomed(room) {
      var that = this;
      console.log(that.$store.state.base.financeData);
      MonomerRoom({ id: this.ids }).then((res) => {
        if (res.data.code === 0) {
          console.log(res.data.data,'213123123123123');
          that.ruleForm = res.data.data;
          that.ruleForm.roomName = room.roomNumber
        } else {
          this.$message('系统繁忙');
        }
      });
    },

    //取消维修接口
    CancelRoomed() {
      // if (this.ruleForm.endTime && !this.ruleForm.unlimitedDuration) {
        console.log(this.ruleForm);
        this.ruleForm.state = false
        const params = {
          ...this.ruleForm,
          // endTime: this.ruleForm.endTime,//结束时间
          // maintenanceType: this.ruleForm.maintenanceType,//维修类型 1坏房 2维修房
          // orgId: localStorage.getItem('orgid'),
          // remark: this.ruleForm.remark,
          // roomId: this.$store.state.base.financeData.roomId,//房间ID
          // state: true,
          // unlimitedDuration: this.ruleForm.unlimitedDuration,//是否无期限
        };
        CancelRoom(params).then((res) => {
          if (res.data.code === 0) {
            this.$emit("offMaintainUpdata", [false]);
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            });
          }
        });
      // } else {
      //   this.$message({
      //     type: "error",
      //     message: "请选择结束时间",
      //   });
      //   return false;
      // }
    },
    //当天日期
    mouseenter(date) {
      console.log(this.$store.state.base.financeData);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + m + "-" + d;
    },
    initDate() {
      this.overheadSun = this.mouseenter(new Date());
    },
    //点击维修结束按钮触发弹窗
    maintainFinish() {
      this.isPull = true;
    },
    //点击右上角图标关闭弹窗
    offOperator() {
      this.$emit("offMaintainUpdata", [false]);
    },
    openoperator() {
      this.operateshow = true;
      this.roomInfo = { id: this.ruleForm.roomId };
      console.log("数据", this.ruleForm);
      this.$nextTick(() => {
        // this.$refs.operatortable.param.model.id = this.ruleForm.roomId;
		this.$refs.operatortable.ids = this.ruleForm.roomId;
		this.$refs.operatortable.roomNumber = this.ruleForm.roomName;
        this.$refs.operatortable.gettableid(this.ruleForm.roomId);
        this.$refs.operatortable.getlist();
      });
    },
    colsermen() {
      this.operateshow = false;
    },
    submitForm(formName) {
      var that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("offMaintain", [false, "确认"]);
          that.ruleForm.orgId = localStorage.getItem("orgid");
          roommaintenanceadd(that.ruleForm).then((res) => {
            if (res.data.code == 0) {
              this.$emit("offMaintainUpdata", [false]);
              that.$message({
                type: "success",
                message: "提交成功",
              });
            } else {
              that.$message.error("提交失败");
            }
            that.offOperator();
            return false;
          });
        } else {
          return false;
        }
      });
    },
    //修改维修
    editoperator() {
      var params = {
        endTime: this.ruleForm.endTime,
        id: this.ruleForm.id,
        maintenanceType: this.ruleForm.maintenanceType,
        orgId: localStorage.getItem("orgid"),
        remark: this.ruleForm.remark,
        roomId: this.ruleForm.roomId,
        state: this.ruleForm.state,
        unlimitedDuration: this.ruleForm.unlimitedDuration,
        version: 0,
      };
      editMonomerRoom(this.ruleForm).then((res) => {
        if (res.data.code == 0) {
          this.$emit("offMaintainUpdata", [false]);
          that.$message({
            type: "success",
            message: "修改成功",
          });
        } else {
          that.$message.error("修改失败");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.MaintainUpdata {
  width: 100%;
  padding-bottom: 20px;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  position: relative;
  // background: linear-gradient(177deg, #e8fdfe 0%, #ffffff 100%);

  .demo-ruleForm {
    width: 100%;
    border-radius: 5px;
  // overflow: hidden;
    .el-form-item {
      .el-date-picker {
      }
      .el-button {
        width: 110px;
        height: 35px;
        display: flex;
        justify-content: center;
        background: #ffffff;
        border-radius: 5px;
        color: #999999;
      }
    }
  }

  .btu-box {
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: end;
   
  }
}
.subButton{
      background-color: #5cb5b8;
      color: #fff;
      width: 50px;
      height: 20px;
      padding: 0px 0px;
      margin-left: 40px;
      margin-top: 5px;
    }
    .subButton:last-child{
      background-color: #fff;
      color: #999999;
      margin-left: 10px;
    }
</style>