<template>
  <!-- 商品购买 -->
  <div class="BarCounter">
    <div class="counter_main">
      <!-- 内容区 -->
      <div class="barCounter_main">
        <!-- 侧边分类栏 :style="{ height: BoxHeight }"-->
        <div class="sidebar" >
          <ul>
            <li
              v-for="el in ClasssGoodsFrom"
              :key="el.id"
              @click="ChangeClassIfy(el.id)"
              :class="{ active: li_index == el.id }"
            >
            <span  v-if="el.merchType.length < 5">
              {{ el.merchType }}
            </span>
            <el-tooltip
              v-else
              class="item"
              effect="light"
              placement="top-start"
            >
              <div slot="content" >{{ el.merchType }}</div>
              <span>
                {{
                   el.merchType ? el.merchType.length > 5?  el.merchType.slice(0,5)+'...': el.merchType:'' 
                }}
              </span>
            </el-tooltip>
             
            </li>
          </ul>
        </div>
        <!-- 侧边分类栏 -->

        <!-- 商品展示区域:style="{ height: BoxHeight }" -->
        <div class="commodity_main" >
          <!-- 搜索 -->
          <el-input
            @change="searchInputButton"
            v-model="searchInput"
            clearable
            placeholder="根据商品名称搜索"
            style="width: 87%"
            class="serchInputs"
          >
          <el-button @click="searchInputButton" slot="append" icon="el-icon-search" style="background-color: #5cb5b8;color: #fff"></el-button>
        </el-input>
          <!-- <img
            class="searchImg"
            src="../../../../assets/icons/选择 (1).png"
            alt=""
          /> -->
          <!-- 搜索 -->

          <!-- 商品卡片 -->
          <div class="commodity_box">
            <div
              @click="changeCard(el)"
              v-for="el in CommodityFrom"
              :key="el.id"
              class="card"
            >
              <el-image fit="scale-down"
                :src="el.url ? el.url.url : null"
                alt=""
                class="commodity_img"
              />
              <img
                src="../../../../assets/icons/选择-勾选.png"
                alt=""
                :class="{ pitch_on: true, active: li_card.includes(el.id) }"
              />
              <p style="margin-top: 5px;line-height: 20px;">
                <span>
                库存： {{ el.inventoryNum }}
                </span>
               </p>
              <p style="margin-top: 5px;line-height: 20px;">{{ el.merchName }}--￥{{ el.sellingPrice }}</p>
              <div
                class="outPrice"
                :style="{ display: el.inventoryNum == 0 ? '' : 'none' }"
              >
                <span>商品已售空</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 商品总价格区域 :style="{ height: BoxHeight }" -->
        <div class="merchandiseLedger" >
          <el-table
            :data="PurchaseFrom"
            
            :cell-style="{ color: '#4C4C4C' }"
            :header-cell-style="{ color: '#4C4C4C' }"
            style="width: 100%"
          >
            <!-- background:'linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%)' -->
            <el-table-column prop="merchName" label="品名" align="center">
            </el-table-column>
            <el-table-column
              label="数量"
              prop="buyNum"
              align="center"
            >
              <template slot-scope="scope">
                <el-input
                  @change="ChangeInputs(scope.row)"
                  v-model.number="scope.row.buyNum"
                />
              </template>
            </el-table-column>
            <el-table-column
              prop="sellingPrice"
              align="center"
              label="单价"
            >
              <template slot-scope="scope">
                <span>￥{{ scope.row.sellingPrice }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              align="center"
              label="操作"
            >
              <template slot-scope="scope">
                <span @click="ChangePRICE(scope.row)" style="color: red">
                  删除
                </span>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="remark" align="center" label="备注">
              <template slot-scope="scope">
                <el-input v-model="scope.row.remark"></el-input>
              </template>
            </el-table-column> -->
          </el-table>
          <div class="aggregateAmount">
            <div>
              <span style="margin-left: 10px">数量合计：</span>
              <span>{{ totalNum }}</span>
            </div>
            <div>
              <span style="margin-left: 10px">总金额：</span>
              <span>￥{{ totalPrice ? totalPrice : "0" }}</span>
            </div>
          </div>
        </div>
        <!-- 商品总价格区域 -->
      </div>
      <!-- 内容区 -->
      <!-- 付款方式 -->
      <div class="payment_method">
        <ul>
          <li
            @click="ChangePayment(el)"
            :class="{ active: el.id == li_Payment }"
            v-for="el in priceFrom"
            :key="el.id"
          >
            {{ el.name }}
          </li>
        </ul>
      </div>

      <!-- 按钮区域 -->
      <el-row class="sunButton">
        <el-form
          :inline="true"
          :model="formInline"
          class="demo-form-inline"
          label-width="100px"
        >
        <el-form-item label="备注">
         <el-input v-model="remarks" placeholder="备注信息"></el-input>
        </el-form-item>
		<el-form-item label="当前房间:" v-if="checkFrom.roomName">{{checkFrom.roomName}}</el-form-item>
        <el-form-item
            label="挂入房间"
            v-if="formInline.paymentName == '挂房账' "
          >
            <!-- ·<el-button 
                @click="roomButton"
                style="width: 150px;color: rgb(122, 118, 118);background-color: rgb(199, 194, 194)">
                   {{ CheckRoom.echoMap.roomId?  `${CheckRoom.echoMap.houseTypeId} /${CheckRoom.echoMap.roomId}` :'点击选择挂入房间' }} 
                </el-button> -->
            <el-select
              @change="roomButton(formInline.roomId)"
              :disabled="isDeal"
              clearable
              style="width:130px"
              v-model="formInline.roomId"
              placeholder="挂账房间"
            >
              <el-option
                v-for="el in roomTableData"
                :key="el.id"
                :label="el.roomName"
                :value="el.roomId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="协议挂账" v-if="formInline.paymentName == '协议挂账'">
            <el-select
              @change="getBarName(formInline.agreementUnitId)"
              clearable
              v-model="formInline.agreementUnitId"
              placeholder="协议单位"
              style="width:130px"
            >
              <el-option
                v-for="el in DealFron"
                :key="el.id"
                :label="el.name"
                :value="el.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button class="subnitButton" @click="onSubmit(1)">会员结账</el-button>
            <el-button class="subnitButton" @click="onSubmit(0)">确认</el-button>
          </el-form-item>
        </el-form>
      </el-row>
    </div>

    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      append-to-body
      :before-close="handleClose"
    >
      <div class="roomNumber">
        <div
          @dblclick="DblClickCard(el)"
          class="card"
          v-for="el in roomTableData"
          :key="el.id"
        >
          <span>{{ el.echoMap.roomId }}</span>
          <span> {{ el.name }} - {{ el.gender ? "女" : "男" }}</span>
          <span>
            付款￥{{ el.paymentAmount }} <br />
            消费￥ {{ el.consumptionAmount }}
          </span>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="会员卡结账"
      :visible.sync="isConsumer"
      width="600px"
      class="addMember"
      destroy-on-close
      append-to-body
      :before-close="handleClose"
    >
      <member-consumer ref="ConsumerBox" @MemberPrice="MemberPrice"></member-consumer>
    </el-dialog>
  </div>
</template>

<script>
import { findPageList } from "_api/CommercialSuperApi/ClassificationGoodsApi";
import { shopMerchList } from "_api/CommercialSuperApi/ProductNameApi";
import { paymentList, orderFromList } from "_api/orderFrom";
import MemberConsumer from '_components/MemberConsumer.vue';
import {
  repastTablePage,
  createShopOrderAdd,
  BargainingUnitAll
} from "_api/CateringBackstageApi/ProductNameApi";
export default {
  name: "BarCounter",
  components: {
    MemberConsumer
  },
  data() {
    return {
      isSelectRoom: false,
      isShow: true,
      li_index: null,
      li_Payment: null,
      li_card: [],
      formInline: {
        id: "",
        roomName: null,
        paymentId: null,
        agreementUnitId: null,
        agreementUnitName: null,
      },
      remarks: '',
      isConsumer: false,
      memberMobile: null,
      GoodsFrom: [],
      roomFrom: [],
      BoxHeight: "",
      PaymentHeight: "",
      checkFrom: {},
      searchInput: null,
      dialogVisible: false,
      clearNums: 0,
      goodsNumberS: 0,
      orderFrom: {},
      ruleFrom: this.$store.state.user.orderFrom,
      totalPrice: null, //总金额
      totalNum: 0,
      PurchaseFrom: [], //选中的商品数据集合
      priceFrom: [], //付款方式的数据
      ClasssGoodsFrom: [], //侧边商品分类
      CommodityFrom: [], //根据分类id对应的商品数据
      tableData: [],
      roomTableData: [], //所有在住的房间
      CheckRoom: {
        echoMap: {
          roomId: null,
          houseTypeId: null,
        },
      }, //选中的房间信息
      arrTime: null,
      isDeal: false,
      DealFron: [], //协议单位
    };
  },
  created() {},
  methods: {
    //开始时触发的接口
    createdApi() {
      this.findPageListed();
      this.paymentListed();
      this.orderFromLists();
      this.BargainingUnitAlled();
    },
    //挂协议单位时触发
    getBarName(id) {
      this.formInline.roomName = ''
      this.DealFron.forEach((el) => {
        if (el.id == id) {
          this.formInline.agreementUnitName = el.name;
        }
      });
    },
    //协议单位查看
    BargainingUnitAlled() {
      BargainingUnitAll().then((res) => {
        if (res.data.code === 0) {
          this.DealFron = res.data.data;
        }
      });
    },
    //挂入房间时触发
    roomButton(name) {
      this.formInline.agreementUnitId = ''
      this.formInline.agreementUnitName = ''
      this.roomTableData.forEach(el => {
        if(el.roomId == name) {
          this.orderFrom = el
        }
      })
     
    },
    //表格点击删除触发事件
    ChangePRICE(el) {
      this.li_card.splice(this.li_card.indexOf(el.id), 1);
      this.PurchaseFrom.forEach((item, index) => {
        if (el.id == item.id) {
          this.PurchaseFrom.splice(index, 1);
          // this.$forceUpdate()
        }
      });
      this.GetGoods();
    },
    //双击房卡获取房间订单信息
    DblClickCard(row) {
      console.log(row);
      this.dialogVisible = false;
      this.CheckRoom = row;
      this.formInline.roomName = row.roomName;
    },
    //根据条件搜索商品
    searchInputButton() {
 
        this.shopMerchListed({
          merchName: this.searchInput,
          merchTypeId: this.li_index,
        });
    
    },
    //分页列表查询
    orderFromLists(model = {}) {
      const params = {
        current: 1,
        size: 1000000,
        model: {
          orderState: 1,
          ...model,
        },
      };
      orderFromList(params).then((res) => {
        if (res.data.code === 0) {
          this.roomTableData.splice(0);
          res.data.data.records.forEach((el) => {
            this.roomTableData.push(el);
          });
          console.log(this.roomTableData);
        }
      });
    },
    //弹窗关闭时触发
    handleClose(done) {
      done();
    },
    //商品分类分页列表接口
    findPageListed(model = {}) {
      const params = {
        current: 1,
        size: 10000,
        model: {
          state: true,
          merchState :1,
          ...model,
        },
      };
      findPageList(params)
        .then((res) => {
          if (res.data.code === 0) {
            this.ClasssGoodsFrom = res.data.data.records;
            this.ChangeClassIfy(this.ClasssGoodsFrom[0].id);
          }
        })
        .catch((err) => {});
    },
    //点击商品触发事件
    changeCard(el) {
      console.log(el);
      if (el.inventoryNum != 0) {
        this.li_card.forEach((item,index) => {
          if(item == el.id) {
            this.goodsNumberS = 2
            this.li_card.splice(index, 1);
          this.PurchaseFrom.splice(index, 1);
          this.GetGoods();
          } 
        })
        if(this.goodsNumberS != 2) {
          this.li_card.push(el.id);
        console.log(this.li_card);
        this.PurchaseFrom.push({
          buyNum: 1,
          remark: "",
          ...el,
        });
        this.GetGoods();
        }else{
          this.goodsNumberS = 0
        }
       
        console.log(this.PurchaseFrom);
      } else {
        this.$message({
          type: "error",
          message: "该商品已售空",
        });
      }

      console.log(this.PurchaseFrom);
    },
    // //协议单位查看
    // BargainingUnitAlled() {
    //   BargainingUnitAll().then((res) => {
    //     if (res.data.code === 0) {
    //       this.DealFron = res.data.data;
    //     }
    //   });
    // },
    //计算消费金额和总金额的方法
    GetGoods() {
      this.totalPrice = 0;
      this.totalNum = 0;
      var consumerMoney = 0;
      this.PurchaseFrom.forEach((el) => {
        console.log(el);
        el.consumerMoney = 0;
        el.consumerMoney = (
          parseFloat(el.sellingPrice) * parseFloat(el.buyNum)
        ).toFixed(2);
        this.totalPrice =
          parseFloat(el.consumerMoney) + parseFloat(this.totalPrice);
        this.totalPrice = this.totalPrice.toFixed(2);
        console.log(el.consumerMoney);
        this.totalNum = parseInt(el.buyNum) + parseInt(this.totalNum);
      });
      console.log(this.totalPrice);
    },
    //选择付款方式时触发
    ChangePayment(el) {
      console.log(this.formInline.roomName);
      console.log(el);
          if (el.id === this.formInline.paymentId) {
            this.formInline.paymentId = null;
            this.formInline.paymentName = null;
            this.li_Payment = null;
          } else {
            this.formInline.paymentId = el.id;
            this.formInline.paymentName = el.name;
            this.li_Payment = el.id;
          }
      

      console.log(el);
      console.log(this.formInline.paymentName);
    },
    //商品数量改变时触发
    ChangeInputs(row) {
      console.log(row);
      //判断有没有库存
      if (parseFloat(row.buyNum) > parseFloat(row.inventoryNum)) {
        //库存不足
        this.$message({
          type: "error",
          message: `商品${row.merchName}库存只有${row.inventoryNum}`,
        });
        this.PurchaseFrom.forEach((el) => {
          if (el.id == row.id) {
            el.buyNum = parseFloat(row.inventoryNum);
          }
        });
      }
      this.GetGoods();
    },
     //会员结账自定义事件
     MemberPrice(row) {
      if(row[0] == 1) {
        this.isConsumer = false
      }else{
        this.isConsumer = false
        this.memberDate = row[1]
        this.memberMobile = row[0].number
      this.formInline.paymentId = row[1].paymentId
              this.formInline.paymentName =  row[1].paymentName
              this.li_Payment = row[1].paymentId
              this.onSubmit(2)
      }
    },
    //点击
    onSubmit(num) {
      if(num == 1) {
        //会员结账
        if(this.totalPrice) {
          this.isConsumer = true;
        this.$nextTick(() => {
          this.$refs.ConsumerBox.addMemberss = 1; //进来的放单信息
          this.$refs.ConsumerBox.price = this.totalPrice
          // this.$set(this.$refs.ConsumerBox.ruleFrom,'price',this.totalPrice)
          this.$refs.ConsumerBox.Getpaymented();
        });
        }else{
          this.$message({
            type: 'error',
            message: '总金额不能为空'
          })
        }
       
      }else{
        console.log(this.formInline.paymentName);
      if (this.formInline.roomName || this.formInline.paymentId) {
        if(this.formInline.paymentName == '协议挂账') {
            if(!this.formInline.agreementUnitId) {
              this.$message({
                type: 'error',
                message: '请选择挂入协议单位'
              })
            }else{
            this.createShopOrderAdded();
          }
        } else if(this.formInline.paymentName == '挂房账') {
          if(!this.formInline.roomId) {
            this.$message({
                type: 'error',
                message: '请选择挂入房间'
              })
          }else{
            this.createShopOrderAdded();
          }
        } else{
          this.createShopOrderAdded();
        }
      } else {
        this.$message({
          type: "error",
          message: "请选择付款方式或挂入房间",
        });
      }
      }
    
    },
    //商品的分页列表
    repastTablePageed(model = {}) {
      const params = {
        current: 1,
        size: 1000000,
        model: {
          state: true,
          ...model,
        },
      };
      repastTablePage(params).then((res) => {
        if (res.data.code === 0) {
          this.GoodsFrom.splice(0);
          this.GoodsFrom = res.data.data.records;
        }
      });
    },

    //获取当前时间
    getNowDate() {
      var date1 = new Date();
      var sign2 = ":";
      var year = date1.getFullYear(); // 年
      var month = date1.getMonth() + 1; // 月
      var day = date1.getDate(); // 日
      var hour = date1.getHours(); // 时
      var minutes = date1.getMinutes(); // 分
      var seconds = date1.getSeconds(); //秒
      // 给一位数的数据前面加 “0”
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (day >= 0 && day <= 9) {
        day = "0" + day;
      }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }

      this.arrTime =
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        sign2 +
        minutes +
        sign2 +
        seconds;
    },
    //创建商品订单
    createShopOrderAdded() {
      console.log(this.CheckRoom);
      this.getNowDate();
      if (this.formInline.paymentName == '挂房账') {
        //挂账

        const params = {
          customerMobile: this.ruleFrom.mobile, //客户手机号
          customerName: this.ruleFrom.name, //客户姓名
          houseOrderId: this.ruleFrom.id, //房单Id
          creditRoomId: this.orderFrom.roomId,//挂账房间ID
          creditRoomName: this.orderFrom.roomName,//挂账房间号
          operator: localStorage.getItem("name"), //操作人
          orderMerchVOList: [
            //订单内商品集合
            // {
            // "buyNum": 0,//购买商品数量
            // "consumerMoney": 0,//消费金额
            // "merchId": 0,//商品Id
            // "merchName": "",//商品名称
            // "merchTypeId": 0,//商品类型ID
            // "price": 0//售价
            // }
          ],
          paymentName: this.formInline.paymentName,
          orderType: 0, //订单类型（0 前台订单 1 手机订单）
          // "paymentId": this.formInline.paymentId,//付款方式Id
          remark:null, //备注
		  roomId:this.orderFrom.roomId,//挂账房间ID
          classes: localStorage.getItem("classes"),
          userClassesId: localStorage.getItem("userClassesId"),
          settleAccountsClasses:
            this.arrTime + "/" + localStorage.getItem("classes"), //结账班次
          classesId: localStorage.getItem("classesId"),
          shopOrderFinanceVO: {
            //（挂账）房单财务对象
            itemType: 1, //项目类型 0付款 1消费
            operator: localStorage.getItem("name"), //操作人
            orderId: this.orderFrom.id, //关联订单
            orgId: localStorage.getItem("orgid"), //部门ID
            paymentId: this.formInline.paymentId, //付款方式id/消费方式id
            projectName: this.formInline.paymentName, //项目名称
            remarks: "商品购买", //备注
            roomId: this.orderFrom.roomId, //房间id
            roomName: this.orderFrom.roomName,
            entryType: '3',
            
          },
          totalPrice: this.totalPrice, //合计金额
        };
        this.PurchaseFrom.forEach((el) => {
          params.orderMerchVOList.push({
            consumerMoney: el.consumerMoney, //消费金额
            merchId: el.id, //商品Id
            merchName: el.merchName, //商品名称
            merchTypeId: el.merchTypeId, //商品类型ID
            price: parseFloat(el.sellingPrice), //售价，
            buyNum: el.buyNum, //购买商品数量
            costPrice: el.costPrice//成本价
          });
        });
        if(this.remarks) {
          params.remark = this.ruleFrom.remark
            ? this.ruleFrom.remark + "—商品购买：" + this.remarks
            : this.remarks
        }else{
          params.remark = this.ruleFrom.remark
        }
        createShopOrderAdd(params).then((res) => {
          if (res.data.code === 0) {
            this.$message({
              type: "success",
              message: "商品购买成功",
            });
            this.formInline.roomId = ''
            this.formInline.paymentName = ''
            this.formInline.paymentId = ''
            this.formInline.roomName = ''
            this.formInline.agreementUnitId = ''
            this.formInline.agreementUnitName = ''
            this.li_Payment = ''
            this.PurchaseFrom.splice(0)
            this.li_card.splice(0)
            this.shopMerchListed({ merchTypeId: this.li_index });
            this.$emit('GETBAR')
          }
        });
      } else if (this.formInline.paymentName == "协议挂账") {
        const params = {
          agreementUnitId: this.formInline.agreementUnitId,
          agreementUnitName: this.formInline.agreementUnitName,
          customerMobile: this.ruleFrom.mobile, //客户手机号
          // customerMobile: '16452434151',
          customerName: this.ruleFrom.name, //客户姓名
          houseOrderId: this.ruleFrom.id, //房单Id
          operator: localStorage.getItem("name"), //操作人
          orderMerchVOList: [
            //订单内商品集合
            // {
            // "buyNum": 0,//购买商品数量
            // "consumerMoney": 0,//消费金额
            // "merchId": 0,//商品Id
            // "merchName": "",//商品名称
            // "merchTypeId": 0,//商品类型ID
            // "price": 0//售价
            // }
          ],
          classes: localStorage.getItem("classes"),
          userClassesId: localStorage.getItem("userClassesId"),
          classesId: localStorage.getItem("classesId"),
          paymentName: null,
          orderType: 0, //订单类型（0 前台订单 1 手机订单）
          paymentId: null, //付款方式Id
          remark:null, //备注
          settleAccountsClasses:
            this.arrTime + "/" + localStorage.getItem("classes"), //结账班次
          shopOrderFinanceVO: {
            //（挂账）房单财务对象
            // "itemType": "",//项目类型 0付款 1消费
            // "operator": "",//操作人
            // "orderId": 0,//关联订单
            // "orgId": localStorage.getItem('orgid'),//部门ID
            // "paymentId": "",//付款方式id/消费方式id
            // "projectName": "",//项目名称
            // "remarks": "",//备注
            // "roomId": 0//房间id
          },
          totalPrice: this.totalPrice, //合计金额
        };
        this.PurchaseFrom.forEach((el) => {
          params.orderMerchVOList.push({
            consumerMoney: el.consumerMoney, //消费金额
            merchId: el.id, //商品Id
            merchName: el.merchName, //商品名称
            merchTypeId: el.merchTypeId, //商品类型ID
            price: parseFloat(el.sellingPrice), //售价，
            buyNum: el.buyNum, //购买商品数量
            costPrice: el.costPrice//成本价
          });
        });
        if(this.remarks) {
          params.remark = this.ruleFrom.remark
            ? this.ruleFrom.remark + "—商品购买：" + this.remarks
            : this.remarks
        }else{
          params.remark = this.ruleFrom.remark
        }
        createShopOrderAdd(params).then((res) => {
          if (res.data.code === 0) {
            this.$message({
              type: "success",
              message: "商品购买成功",
            });
            this.$emit('GETBAR')
            this.formInline.roomId = ''
            this.formInline.paymentName = ''
            this.formInline.paymentId = ''
            this.formInline.roomName = ''
            this.formInline.agreementUnitId = ''
            this.formInline.agreementUnitName = ''
            this.li_Payment = ''
            this.PurchaseFrom.splice(0)
            this.li_card.splice(0)
            this.shopMerchListed({ merchTypeId: this.li_index });
          }
        });
      } else if (
        !this.formInline.roomName ||
        this.formInline.paymentName != "协议挂账"
      ) {
        //直接付

        const params = {
          customerMobile: this.ruleFrom.mobile, //客户手机号
          // customerMobile: '16452434151',
          customerName: this.ruleFrom.name, //客户姓名
          houseOrderId: this.ruleFrom.id, //房单Id
          operator: localStorage.getItem("name"), //操作人
          orderMerchVOList: [
            //订单内商品集合
            // {
            // "buyNum": 0,//购买商品数量
            // "consumerMoney": 0,//消费金额
            // "merchId": 0,//商品Id
            // "merchName": "",//商品名称
            // "merchTypeId": 0,//商品类型ID
            // "price": 0//售价
            // }
          ],
          classes: localStorage.getItem("classes"),
          userClassesId: localStorage.getItem("userClassesId"),
          classesId: localStorage.getItem("classesId"),
          paymentName: this.formInline.paymentName,
          orderType: 0, //订单类型（0 前台订单 1 手机订单）
          paymentId: this.formInline.paymentId, //付款方式Id
          remark:null, //备注
          settleAccountsClasses:
            this.arrTime + "/" + localStorage.getItem("classes"), //结账班次
          shopOrderFinanceVO: {
            //（挂账）房单财务对象
            // "itemType": "",//项目类型 0付款 1消费
            // "operator": "",//操作人
            // "orderId": 0,//关联订单
            // "orgId": localStorage.getItem('orgid'),//部门ID
            // "paymentId": "",//付款方式id/消费方式id
            // "projectName": "",//项目名称
            // "remarks": "",//备注
            // "roomId": 0//房间id
          },
          totalPrice: this.totalPrice, //合计金额
        };
        this.PurchaseFrom.forEach((el) => {
          params.orderMerchVOList.push({
            consumerMoney: el.consumerMoney, //消费金额
            merchId: el.id, //商品Id
            merchName: el.merchName, //商品名称
            merchTypeId: el.merchTypeId, //商品类型ID
            price: parseFloat(el.sellingPrice), //售价，
            buyNum: el.buyNum, //购买商品数量
            costPrice: el.costPrice//成本价
          });
        });
        if(this.remarks) {
          params.remark = this.ruleFrom.remark
            ? this.ruleFrom.remark + "—商品购买：" + this.remarks
            : this.remarks
        }else{
          params.remark = this.ruleFrom.remark
        }
        if(params.paymentName == '微储值') {
          params.vipMobile = this.memberMobile
        }
        createShopOrderAdd(params).then((res) => {
          if (res.data.code === 0) {
            this.$message({
              type: "success",
              message: "商品购买成功",
            });
            this.$emit('GETBAR')
            this.formInline.roomId = ''
            this.formInline.paymentName = ''
            this.formInline.paymentId = ''
            this.formInline.roomName = ''
            this.formInline.agreementUnitId = ''
            this.formInline.agreementUnitName = ''
            this.li_Payment = ''
            this.PurchaseFrom.splice(0)
            this.li_card.splice(0)
            this.shopMerchListed({ merchTypeId: this.li_index });
          }
        });
      }
    },
    //点击分类侧边切换
    ChangeClassIfy(id) {
      this.li_index = id;
      if (this.searchInput) {
        this.shopMerchListed({ merchTypeId: id, merchName: this.searchInput });
      } else {
        this.shopMerchListed({ merchTypeId: id });
      }
    },
    //根据商品分类id查出对应商品接口
    shopMerchListed(model = {}) {
      const params = {
        current: 1,
        size: 100000,
        model: {
          state: true,
          ...model,
        },
      };
      shopMerchList(params).then((res) => {
        if (res.data.code === 0) {
          this.CommodityFrom.splice(0);
          this.CommodityFrom = res.data.data.records;
          //   this.CommodityFrom = res.data.data.records.filter(el => {
          //     return el.inventoryNum != 0
          //   })

          console.log(this.CommodityFrom);
        }
      });
    },
    //付款方式查看接口
    paymentListed() {
      paymentList().then((res) => {
        if (res.data.code == 0) {
          this.priceFrom = res.data.data.filter(el => el.name != '微储值' && el.name != '挂账' && el.name != '餐饮挂账' && el.name != '商超挂账' && el.name != '预授权' );
          // this.priceFrom.push(...this.priceFrom)
          // res.data.data.forEach(el => {
          //   this.priceFrom.push(el)
          // })
          if (this.priceFrom.length > 14) {
            this.BoxHeight = "480px";
          } else {
            this.BoxHeight = "510px";
          }
        }
      });
    },
    //点击选择房号的第二行的图标
    offSelector() {
      this.isShow = true;
      this.isSelectRoom = false;
    },
    //点击双击选择弹出弹窗
    selectRoom() {
      this.isShow = false;
      this.isSelectRoom = true;
    },

    //点击确认触发事件
    submitButton() {
      this.$emit("barCounters", [false]);
    },
  },
};
</script>

<style lang="scss" scoped>
body .el-table::before {
    z-index: inherit;
}
.searchImg {
  width: 40px;
  height: 40px;
  top: 0px;
  margin-left: 3px;
}
.serchInputs{
  position: absolute;
  top: 0%;
  left: 6%;
}
// .el-dialog {
//   .roomNumber {
//     width: 100%;
//     height: 500px;
//     overflow-y: auto;
//     margin-top: 10px;
//     padding-left: 10px;
//     display: flex;
//     flex-wrap: wrap;
//     padding-right: 10px;
//     // justify-content: space-between;
//     box-sizing: border-box;
//     .card {
//       width: 22%;
//       height: 138px;
//       background: #fafbfc;
//       border-radius: 20px;
//       display: flex;
//       margin-right: 15px;
//       padding-left: 17px;
//       box-sizing: border-box;
//       margin-bottom: 10px;

//       flex-direction: column;
//       span:nth-child(1) {
//         width: 80px;
//         height: 30px;
//         color: #fff;
//         background: #5cb5b8;
//         border-radius: 15px;
//         text-align: center;
//         line-height: 30px;
//         margin-top: 15px;
//         margin-bottom: 20px;
//       }
//       span:nth-child(2) {
//         font-size: 16px;
//         font-family: Microsoft YaHei;
//         font-weight: 400;
//         color: #999999;
//         margin-bottom: 8px;
//       }
//       p {
//         text-align: left;
//       }
//     }
//   }
// }
.BarCounter {
  width: 100%;
  max-height: 660px;
  // height: 580px;
  // overflow-y: auto;
  background-color: #fff;
  position: relative;

  .counter_main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    // background: linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%);
    border-radius: 5px;

    //内容区css
    .barCounter_main {
      width: 100%;
      display: flex;
      //分类侧边栏
      .sidebar {
        width: 15%;
        text-align: center;
        max-height: 470px;
        overflow-y: auto;
        // background: linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%);
        ul {
          width: 90%;
          display: flex;
          margin-top: -10px;
          flex-direction: column;
          align-items: center;
          li {
            width: 100%;
            height: 40px;
            font-size: 13px;
            background-color: #eefefe;
            color: #5cb5b8;
            margin-bottom: 5px;
            line-height: 40px;
            text-align: center;
            border-radius: 5px;
          }
          li:nth-child(1) {
            margin-top: 10px;
          }
          li.active {
            background-color: #5cb5b8;
            color: #fff;
          }
        }
      }
      //商品展示区域css
      .commodity_main {
        width: 80%;
        max-height: 470px;
        padding: 0px 15px;
        padding-top: 50px;
        box-sizing: border-box;
        position: relative;
        // overflow-y: auto;
        // background: linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%);
        .commodity_box {
          width: 100%;
          height: 350px;
          overflow-y: auto;
          display: flex;
          flex-wrap: wrap;

          //单个卡片css
          .card {
            width: 20%;
            height: 170px;
            margin-top: 10px;
            margin-left: 4%;
            padding-bottom: 5px;
            box-sizing: border-box;
            position: relative;
            //库存没了的遮罩层
            .outPrice {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              // height: 170px;
              height: 120px;
              display: flex;
              background-color: rgba(133, 106, 106, 0.4);
              color: #fff;
              justify-content: center;
              align-items: center;
            }
            //商品图片
            .commodity_img {
              width: 100%;
              height: 120px;
            }
            .pitch_on {
              width: 20px;
              height: 20px;
              display: none;
              position: absolute;
              top: 10px;
              right: 5px;
              z-index: 100;
            }
            .pitch_on.active {
              display: block;
            }
            p {
              padding-left: 5px;
              box-sizing: border-box;
              font-size: 13px;
              text-align: center;
              width: 100%;
            }
          }
        }
      }

      //价格区域
      .merchandiseLedger {
        width: 40%;
        max-height: 470px;
        // overflow-y: auto;
        // height: 480px;
        border-left: 2px solid #e9e9e9;
        // background: linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%);
        ::v-deep .el-table {
          height: 400px;
          overflow-y: auto;
          // background: linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%);
        }
        .aggregateAmount {
          width: 90%;
          display: flex;
          height: 60px;
          line-height: 60px;
          padding-top: 10px;
          box-sizing: border-box;
          justify-content: space-between;
          span {
            font-size: 13px;
          }
          span:nth-child(2) {
            color: #5cb5b8;
          }
        }
      }
    }
    .payment_method {
      width: 99%;
      // height: 80px;
      margin-top: 15px;
      background: #ffffff;
      border: 1px solid #e9e9e9;
      border-radius: 8px;
      overflow-y: auto;
      ul {
        width: 100%;
        display: flex;
        padding-top: 5px;
        box-sizing: border-box;
        flex-wrap: wrap;
        padding-left: 14px;
        li {
          height: 32px;
          background: #ffffff;
          border: 1px solid #e9e9e9;
          border-radius: 8px;
          font-size: 13px;
          line-height: 32px;
          text-align: center;
          margin-right: 10px;
          margin-bottom: 5px;
          padding-left: 15px;
          padding-right: 15px;

          color: #999999;
        }
        li:nth-child(8) {
          // width: 100px;
        }
        li.active {
          background-color: #5cb5b8;
          color: #fff;
        }
        li:first-child {
          // margin-left: 14px;
        }
      }
    }

    //按钮
    .sunButton {
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: end;
      margin-top: 40px;

      .el-button {
        width: 90px;
        height: 40px;
        background-color: #5cb5b8;
        color: #fff;
        border-radius: 7px;
        padding: 0px 0px;
      }
      .el-button:nth-child(1) {
        background-color: #ffffff;
        color: #999999;
      }
      .el-button:nth-child(3) {
        background-color: #ffffff;
        color: #999999;
      }
      .demo-form-inline {
        width: 100%;
        display: flex;
      align-items: center;
      justify-content: flex-end;
        .el-form-item:last-child {
          margin-left: 10px;
          text-align: right;
          .subnitButton {
            width: 100px;
            height: 40px;
            background-color: #5cb5b8;
            color: #fff;
            padding: 0px 0px;
          }
        }
      }
    }
  }
  .selectRoom {
    width: 100%;
    height: 400px;
    .select_main {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      // background: linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%);
      border-radius: 5px;
      //头部标题
      .membership_title {
        width: 100%;
        height: 30px;
        display: flex;
        padding-top: 10px;
        align-items: center;
        box-sizing: border-box;
        margin-bottom: 10px;

        p {
          flex: 1;
          padding-left: 20px;
          box-sizing: border-box;
          text-align: left;
        }
      }
      .membership_title:nth-child(2) {
        // background: linear-gradient(90deg,#5CB5B8, #F6FEFE);

        p {
          color: #fff;
          padding-left: 40px;
          box-sizing: border-box;
        }
      }
      p {
        width: 100%;
        text-align: left;
        display: flex;
        color: #f4a509;
        padding-left: 50px;
        box-sizing: border-box;
        align-items: center;
        img {
          width: 20px;
          height: 18px;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>