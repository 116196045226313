export default {
  namespaced: true,
  state: {
    // 侧边导航折叠状态
    navCollapse: false,
    financeData:{},//房态点击卡片获取的房间数据
    accounting: {},
    //民族数据
    publicDataFrom: [
      {label: "汉族", text: "汉族", value: "01"},
      {label: "壮族", text: "壮族", value: "02"},
     {label: "满族", text: "满族", value: "03"},
      {label: "回族", text: "回族", value: "04"},
      {label: "苗族", text: "苗族", value: "05"},
      {label: "维吾尔族", text: "维吾尔族", value: "06"},
      {label: "土家族", text: "土家族", value: "07"},
      {label: "彝族", text: "彝族", value: "08"},
      {label: "蒙古族", text: "蒙古族", value: "09"},
      {label: "藏族", text: "藏族", value: "10"},
      {label: "布依族", text: "布依族", value: "11"},
      {label: "侗族", text: "侗族", value: "12"},
      {label: "瑶族", text: "瑶族", value: "13"},
      {label: "朝鲜族", text: "朝鲜族", value: "14"},
      {label: "白族", text: "白族", value: "15"},
      {label: "哈尼族", text: "哈尼族", value: "16"},
      {label: "哈萨克族", text: "哈萨克族", value: "17"},
      {label: "黎族", text: "黎族", value: "18"},
      {label: "傣族", text: "傣族", value: "19"},
      {label: "畲族", text: "畲族", value: "20"},
      {label: "傈僳族", text: "傈僳族", value: "21"},
      {label: "仡佬族", text: "仡佬族", value: "22"},
      {label: "东乡族", text: "东乡族", value: "23"},
      {label: "高山族", text: "高山族", value: "24"},
      {label: "拉祜族", text: "拉祜族", value: "25"},
      {label: "水族", text: "水族", value: "26"},
      {label: "佤族", text: "佤族", value: "27"},
      {label: "纳西族", text: "纳西族", value: "28"},
      {label: "羌族", text: "羌族", value: "29"},
      {label: "土族", text: "土族", value: "30"},
      {label: "仫佬族", text: "仫佬族", value: "31"},
      {label: "锡伯族", text: "锡伯族", value: "32"},
      {label: "柯尔克孜族", text: "柯尔克孜族", value: "33"},
      {label: "达斡尔族", text: "达斡尔族", value: "34"},
      {label: "景颇族", text: "景颇族", value: "35"},
      {label: "毛南族", text: "毛南族", value: "36"},
      {label: "撒拉族", text: "撒拉族", value: "37"},
      {label: "塔吉克族", text: "塔吉克族", value: "38"},
      {label: "阿昌族", text: "阿昌族", value: "39"},
      {label: "普米族", text: "普米族", value: "40"},
      {label: "鄂温克族", text: "鄂温克族", value: "41"},
      {label: "怒族", text: "怒族", value: "42"},
      {label: "京族", text: "京族", value: "43"},
      {label: "基诺族", text: "基诺族", value: "44"},
      {label: "德昂族", text: "德昂族", value: "45"},
     {label: "保安族", text: "保安族", value: "46"},
      {label: "俄罗斯族", text: "俄罗斯族", value: "47"},
      {label: "裕固族", text: "裕固族", value: "48"},
      {label: "乌兹别克族", text: "乌兹别克族", value: "49"},
     {label: "门巴族", text: "门巴族", value: "50"},
     {label: "鄂伦春族", text: "鄂伦春族", value: "51"},
      {label: "独龙族", text: "独龙族", value: "52"},
      {label: "塔塔尔族", text: "塔塔尔族", value: "53"},
      {label: "赫哲族", text: "赫哲族", value: "54"},
      {label: "珞巴族", text: "珞巴族", value: "55"},
     {label: "布朗族", text: "布朗族", value: "56"},
     {label: "其他", text: "其他", value: "57"}
             ],
    gender:[
      {label: "男", text: "男", value: "1"},
      {label: "女", text: "女", value: "2"}
    ],
    //学历集合
    education: [
      {label: "小学", text: "小学", value: "01"},
       {label: "中学", text: "中学", value: "02"},
       {label: "高中", text: "高中", value: "03"},
       {label: "专科", text: "专科", value: "04"},
       {label: "本科", text: "本科", value: "05"},
       {label: "硕士", text: "硕士", value: "06"},
      {label: "博士", text: "博士", value: "07"},
       {label: "博士后", text: "博士后", value: "08"},
       {label: "其他", text: "其他", value: "99"}
    ],
    //职业状态
    Occupational: [
      {label: "在职", text: "在职", value: "10"},
       {label: "离职", text: "离职", value: "20"},
       {label: "请假中", text: "请假中", value: "30"}
    ],
    unitInformation: {},//单个列表数据：用户设置
    addRoomData: {},//点击空房间办理入住，点击的房间信息
    rulePrices: {},//预授权的订单信息
    invoiceUpdate: {},//修改发票开具
    advancePRderOne: {},//双击预定单列表获取的数据
    MemberData: {},//会员详情数据
    li_card: [],//吧台点餐的商品订单
    searchRulefrom: [],//房态搜索返回的数据
    searchNUM: 0,
    HousePriceCode: [],//所有房价码,
    AdvanceAddEnter: [],//预定转入住是的订单信息
    canyingOrderFrom: {},//餐饮订单信息
  },
  mutations: {
    SETCANY_ORDERFROM (state, data) {
      state.canyingOrderFrom = data
    },
    //获取预定转入住的订单信息
    SETAdvanceAddEnter(state, data) {
      console.log(data);
      state.AdvanceAddEnter.splice(0)
      state.AdvanceAddEnter.push([...data])
    },
    //获取所有房价码信息
    SETHousePriceCode(state, data) {
      state.HousePriceCode.splice(0)
      state.HousePriceCode = data
    },
    SET_LICARD(state, data) {
      state.li_card.splice(0)
      state.li_card.push(...data)
    },
    SETSEARCH(state, data) {
      state.searchRulefrom.splice(0)
state.searchRulefrom = data.records
state.searchNUM = data.num
    },
    // 切换侧边导航折叠状态
    TOGGLE_MENU (state) {
      state.navCollapse = !state.navCollapse
    },
    FINANCE_DATA(state, data) {
      
      state.financeData = data
    },
    SET_ACCOUNTING(state, data) {
     
      state.accounting = data
    },
    SET_UNITINFORMATION(state, data) {
      state.unitInformation = data
    },
    SETROOM_DATA(state, data){
      state.addRoomData = data
    },
    SET_PRICE(state, data){
     
      state.rulePrices = data
    }, 
    SET_INVOICE(state, data){
     
      state.invoiceUpdate =data
    },
    SET_ORDERADVANCE(state, data){
   
      state.advancePRderOne = data
    },
    SET_MEMBERDATA(state, data){
      state.MemberData = data
    }
  }
}
