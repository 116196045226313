<template>
  <!-- 联房操作 -->
  <div class="RelevanceOperation" ref="roomlist">
    <el-table
      :data="tableData"
      @row-dblclick="rowDblclick"
      :header-cell-style="{
        background: '#F5FAFA',
        color: '#4C4C4C',
        'text-align': 'center',
      }"
      :max-height="tableheight"
      border
      @selection-change="handleSelectionChange"
    >
      <el-table-column align="center" type="selection" width="55">
      </el-table-column>
      <el-table-column prop="connectRoomId" align="center" label="联单号">
      </el-table-column>
      <el-table-column prop="roomName" align="center" label="房号" width="200">
      </el-table-column>
      <el-table-column align="center" prop="name" label="姓名" width="160">
      </el-table-column>
      <el-table-column
        align="center"
        prop="arrivalTime"
        label="抵店"
        width="200"
      >
      </el-table-column>
      <el-table-column prop="predepartureTime" align="center" label="离店">
      </el-table-column>
      <el-table-column align="center" label="电话" prop="mobile">
      </el-table-column>
      <el-table-column
        align="center"
        prop="echoMap.agreementUnitId"
        label="协议单位"
      >
      </el-table-column>
      <el-table-column align="center" label="付款">
        <template slot-scope="scope">
          <span>￥{{ scope.row.paymentAmount }}</span>
        </template>
      </el-table-column>

      <el-table-column
        fixed="left"
        prop="orderState"
        align="center"
        label="状态"
      >
        <template slot-scope="scope">
          <span style="color: #5cb5b8">
            {{ scope.row.orderState ? "在住" : "已退房" }}
          </span>
        </template>
      </el-table-column>
    </el-table>

    <div class="flootBox">
      <span>已选{{ li_length }}间</span>

      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label-width="0px" style="position: relative">
          <el-input
          clearable
          @change="ChangeInput(formInline.nameNum)"
            placeholder="输入房号、姓名模糊搜索"
            v-model="formInline.nameNum"
          />
          <el-button
            icon="el-icon-search"
            @click="searchInput"
            style="
              position: absolute;
              right: -56px;
              background-color: #5cb5b8;
              color: #fff;
            "
          ></el-button>
        </el-form-item>

        <el-form-item>
          <el-button @click="ensureButton(0)"> 消除联房 </el-button>
          <el-button @click="ensureButton(1)"> 确认联房 </el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 点击确定弹出再次确认弹窗的阴影盒子 -->
    <div class="savePop" @click="consumer_offs" v-if="reConfirm">
      <!-- 弹窗 -->
      <div class="save_main">
        <div class="save_size">
          <p>
            <img src="../../../assets/icons/警告 (1).png" alt="" />
            确认
          </p>
          <span class="size_floot"
            >确认{{ isCancel == 1 ? "联房" : "消除已选的联房" }}吗？</span
          >
        </div>

        <div class="save_button">
          <el-button @click="noDelete" class="cancelButton"> 取消 </el-button>
          <el-button
            @click="areDelete"
            style="background-color: #fdd4d9; color: #d9001b"
            class="submitButton"
          >
            确定
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { orderFromList } from "_api/orderFrom";
import { orderBaseUpdateList } from "_api/housingSystem";
export default {
  name: "RelevanceOperation",
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      formInline: {
        nameNum: null,
      },
      reConfirm: false,
      isCancel: null,
      li_length: 0,
      current: 1,
      size: 100000,
      total: 0,
      tableheight: 0,
    };
  },
  mounted() {
    this.autoHeight();
  },
  methods: {
    //确定
    areDelete() {
      if(this.multipleSelection.length > 0) {
        if (this.isCancel == 1) {
        this.orderBaseUpdateListed(1);
      } else if (this.isCancel == 0) {
        this.orderBaseUpdateListed(0);
      }
      }else{
        this.$message({
          type: 'error',
          message: '请先勾选要联房或取消联房的房间'
        })
      }
     
    },
    //取消
    noDelete() {
      this.reConfirm = false;
      this.rowDblclick();
    },
    ChangeInput(id) {
      if(!id) {
        this.orderFromLists();
      }
    },
    //点击确认和取消按钮和弹窗以外关闭弹窗
    consumer_offs(e) {
      if (e.target.className === "savePop") {
        this.reConfirm = false;
      }
    },
    //点击确定和取消触发事件
    ensureButton(num) {
      if (num === 0) {
        //取消
        this.isCancel = 0;
        this.reConfirm = true;
      } else if (num === 1) {
        //确定
        this.isCancel = 1;
        this.reConfirm = true;
      }
    },
    //联房和取消联房的接口
    orderBaseUpdateListed(num) {
      var orderBase = [];
      var roomIds = [];
      roomIds.splice(0);
      this.multipleSelection.forEach((el) => {
        orderBase.push({
          connectRoomId: el.connectRoomId, //连房id
          id: el.id, //主键
          isMasterRoom: false, //是否为连房的主房（1为是，0为否）
        });
        roomIds.push(el.roomId);
      });
      orderBase[0].isMasterRoom = true;
      var params = {
        orderBase: orderBase,
        type: parseFloat(num), //操作类型（1为确认连房，0为取消连房）
      };
      orderBaseUpdateList(params).then((res) => {
        if (res.data.code === 0) {
          if (num == 1) {
            this.$message({
              type: 'success',
              message: '确定联房成功'
            })
            this.$emit("CancelBOX", [roomIds, res.data.data]);
          } else {
            this.$message({
              type: 'success',
              message: '取消联房成功'
            })
            this.$emit("CancelBOX");
          }
        }
      });
    },
    //模糊搜索触发的事件
    searchInput() {
      if (Number(this.formInline.nameNum)) {
        this.orderFromLists({
          roomName: this.formInline.nameNum,
        });
      } else {
        this.orderFromLists({
          name: this.formInline.nameNum,
        });
      }
    },
    //左侧多选时触发的事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.li_length = this.multipleSelection.length;
      console.log(this.multipleSelection);
    },
    rowDblclick() {},
    //分页列表查询
    orderFromLists(model = {}) {
      const params = {
        current: this.current,
        size: this.size,
        model: {
          orderState: 1,
          ...model,
        },
      };
      orderFromList(params).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.data.records;
          this.total = Number(res.data.data.total);
          console.log(this.tableData);
        }
      });
    },
     //高度自适应
     autoHeight() {
      // 初始化的时候，设置高度
      this.setHeight();
      // 改变浏览器窗口大小的时候，设置高度
      window.addEventListener("resize", () => {
        this.setHeight();
      });
    },
    // 设置高度
    setHeight() {
      this.$nextTick(() => {
        if (this.$refs.roomlist) {
          let height = this.$refs.roomlist.offsetHeight;
          // console.log('元素高度',height);
          this.tableheight = height - 80; // 这里的X就是顶部栏的高度
          // console.log('高度',this.tableheight)
          // document.getElementsByClassName("el-table__fixed-body-wrapper").style.max-height = "440px";
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.RelevanceOperation {
  position: relative;
  height: 625px;
  .flootBox {
    width: 100%;
    height: 50px;
    position: absolute;
    bottom: -10px;
    line-height: 50px;
    color: #5cb5b8;
    z-index: 10;
    .demo-form-inline {
      float: right;
      height: 50px;
      line-height: 50px;
      .el-form-item:last-child {
        margin-left: 66px;
        .el-button {
          background-color: #fff;
          color: #999999;
        }
        .el-button:last-child {
          background-color: #5cb5b8;
          color: #fff;
        }
      }
    }
  }
  //弹窗的阴影盒子
  .savePop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(133, 106, 106, 0.4);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    //阴影里的 删除盒子css
    .save_main {
      width: 500px;
      height: 180px;
      background-color: #fff;
      .save_size {
        width: 100%;
        height: 126px;
        background: linear-gradient(177deg, #e8fdfe 0%, #ffffff 100%);
        border-radius: 5px;
        padding-top: 30px;
        box-sizing: border-box;

        p {
          height: 40px;
          padding-left: 45px;
          box-sizing: border-box;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;

          img {
            width: 15px;
            height: 15px;
          }
        }
        .size_floot {
          padding-left: 80px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #555555;
        }
      }
      .save_button {
        // display: flex;
        // justify-content: end;
        text-align: right;
        padding-right: 20px;
        box-sizing: border-box;
        .el-button {
          margin-left: 20px;
          width: 100px;
        }
      }
    }
  }
}
</style>