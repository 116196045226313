<template>
    <div class="allpage">
      <el-container>
        <!-- 侧边栏 -->
        <el-aside :width="$store.state.base.navCollapse?'60px':'130px'">
        <common-aside/>
      </el-aside>
  <!-- 侧边栏 -->
  <el-container >
    <!-- 头部 -->
    <el-header height="86px">
      <common-header  @drawershow='drawershow'/>
    </el-header>
     <!-- 头部 -->
      <!-- 内容区 -->
    <el-main>
      <router-view></router-view>
    </el-main>
     <!-- 内容区 -->
	 
  </el-container>
   
</el-container>
	 <DrawerInfo v-if="drawer" :childVisible='drawer' @drawershow='drawershow'></DrawerInfo>
    </div>
  </template>
  
  <script>
  import CommonAside from './Components/CommonAside.vue';
  import CommonHeader from './Components/CommonHeader.vue';
  import DrawerInfo from "./Components/DrawerInfo.vue";
    export default {
      name: 'AdminPage',
      components: {
		  CommonAside,
		  CommonHeader,
		  DrawerInfo,
	  },
	  data(){
		  return {
			drawer: false,
		  };
	  },
	  methods:{
		drawershow(){
			console.log('点击');
			// this.$nextTick(() => {
				
			// });
			this.drawer=!this.drawer;
		},
	  },
    }
  </script>
  
  <style lang="scss" scoped>
//   body{
//     *{

// -webkit-touch-callout:none; /*系统默认菜单被禁用*/

// -webkit-user-select:none; /*webkit浏览器*/

// -khtml-user-select:none; /*早期浏览器*/

// -moz-user-select:none;/*火狐*/

// -ms-user-select:none; /*IE10*/

// user-select:none;

// }

// input{

// -webkit-user-select:auto; /*webkit浏览器*/

// }
// .el-table{

// -webkit-user-select:auto; /*webkit浏览器*/

// }
// textarea{

// -webkit-user-select:auto; /*webkit浏览器*/

// }
  // }
  .allpage{
	  overflow: hidden;
  }
  .el-container{
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  .el-aside{
    background-color: #FFFFFF;
    border-right:1px solid #f5f5f5;
   width: 80px;
  
  }
  .el-header{
    border-bottom: 1px solid #F8F8F8;
    overflow: hidden;
  }
  .el-main{
    background-color: #fcfbfb;
    height: 100%;
    overflow: hidden;
    // overflow-y: auto;
  }
}
  </style>