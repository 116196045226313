import { render, staticRenderFns } from "./RoomBilling.vue?vue&type=template&id=02316cae&scoped=true"
import script from "./RoomBilling.vue?vue&type=script&lang=js"
export * from "./RoomBilling.vue?vue&type=script&lang=js"
import style0 from "./RoomBilling.vue?vue&type=style&index=0&id=02316cae&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02316cae",
  null
  
)

export default component.exports