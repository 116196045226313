<template>
  <!-- 预授权完成 -->
  <div class="authorizationPre">
    <div class="PreAuthorization_main">
      <el-form
        :model="ruleForm"
        :rules="rules"
        label-width="100px"
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <el-row>
          <el-form-item label="入住单号：" prop="orderId">
            <el-input
              :disabled="true"
              clearable
              v-model="ruleForm.orderId"
            ></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item style="background-color: #f7d17136">
            <img 
        class="tixingClass"
        src="../../../../../../assets/icons/警告 (1).png" alt="">

        <p style="color: orange">可完成预授权金额:0~ {{ rulePrices.price }}元，金额输入0即撤销此预授权</p>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="金额：" prop="price">
            <el-input clearable v-model.number="ruleForm.price"></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="入账项目：" prop="projectName">
              <el-input
                clearable
                v-model="ruleForm.projectName"
                :disabled="true"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="入账备注" prop="remarks">
            <el-input clearable v-model="ruleForm.remarks"></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="凭证号" prop="voucherNo">
            <el-input
              :disabled="true"
              clearable
              v-model="ruleForm.voucherNo"
            ></el-input>
          </el-form-item>
        </el-row>
        <el-row class="buttonBox">
          <el-button @click="DeleteForm"> 取消 </el-button>
          <el-button @click="submitForm('ruleForm')"> 确认 </el-button>
        </el-row>
      </el-form>
    </div>
  </div>
</template>
  
  <script>
import { AddFinance, UpdateFinance, paymentList } from "_api/orderFrom";
export default {
  name: "authorizationPre",

  data() {
    return {
      ruleForm: {
        voucherNo: this.$store.state.base.rulePrices.voucherNo,
        price: "",
        orderId: this.$store.state.base.rulePrices.orderId,
        itemType: "0",
        projectName: "银行卡",
        authOver: true,
      },
      payment: [],
      currentRoom: {},//房单订单信息
      rulePrices: {}, //this.$store.state.base.rulePrices
      rules: {
        price: [
          { required: true, message: "请输入实际价格", trigger: "blur" },
          {
            pattern: /^(([0-9]|([1-9][0-9]{0,9}))((\.[0-9]{1,2})?))$/,
            message: "整数位最多十位，小数为最多为两位，可以无小数位",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.paymentListed();
  },
  watch: {},
  methods: {
    pageCreated() {
      console.log(this.rulePrices);
      this.ruleForm = {
        voucherNo: this.rulePrices.voucherNo,
        price: "",
        orderId: this.rulePrices.orderId,
        itemType: "0",
        projectName: null,
        paymentId: null,
        authOver: true,
      };
    },
    //付款方式的查看
    paymentListed() {
      paymentList().then((res) => {
        if (res.data.code === 0) {
          res.data.data.forEach((el) => {
            if (el.name == "银行卡") {
              this.ruleForm.projectName = el.name;
              this.ruleForm.paymentId = el.id;
            }
          });
          if (!this.ruleForm.projectName) {
            this.ruleForm.projectName = "入账项目没有银行卡，请添加入账项目";
          }
        } else {
          this.$message({
            type: "error",
            message: "请求异常",
            onClose: () => {},
          });
        }
      });
    },

    //财务管理表之更新接口
    UpdateFinanceed() {
      const params = {
        ...this.rulePrices,
        authOver: true,
        state: false,
        id: this.rulePrices.id,
      };
      params.state = false;
      params.authOver = true;
      // params.projectName = '预授权完成'
      UpdateFinance(params)
        .then((res) => {
          if (res.data.code === 0) {
            this.$message({
              type: "success",
              message: "预授权完成",
            });
            this.$emit("isAuthOver");
          }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err.errorMsg,
          });
        });
    },
    //财务管理表之新增接口
    AddFinanceed() {
      const params = {
        voucherNo: this.ruleForm.voucherNo,
        price: Number(this.ruleForm.price),
        orderId: this.ruleForm.orderId,
        authorizationCode: this.ruleForm.authorizationCode,
        bankNumber: this.ruleForm.bankNumber,
        itemType: "0",
        preAuth: false,
        state: true,
        roomId: this.currentRoom.roomId,
        roomName: this.currentRoom.roomName,
        userClassesId: localStorage.getItem("userClassesId"),
        operator: localStorage.getItem("name"),
        paymentId: this.ruleForm.paymentId,
        projectName: this.ruleForm.projectName,
        type: 1,
        orgId: localStorage.getItem("orgid"),
        classesId: localStorage.getItem("classesId"),
        createdName: localStorage.getItem("name"),
        classes: localStorage.getItem("classes"),
        remarks: this.ruleForm.remarks,
      };
      AddFinance(params).then((res) => {
        if (res.data.code === 0) {
          this.$message({
            type: "success",
            message: "财务入账成功",
          });
          this.UpdateFinanceed();
        }
      });
    },
    //删除
    DeleteForm() {
      this.$emit("isAuthOver");
    },
    //点击右上角图标关闭弹窗
    offOperator() {
      this.$emit("isAuthOver");
    },
    submitForm(formName) {
      if(this.ruleForm.price == 0) {
        this.UpdateFinanceed()
      }else{
        this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.ruleForm.paymentId) {
            if (
              parseFloat(this.rulePrices.price) >=
              parseFloat(this.ruleForm.price)
            ) {
              this.AddFinanceed();
            } else {
              this.$message({
                type: "error",
                message: `超过预授权金额${this.rulePrices.price}请重新填写`,
              });
            }
          } else {
            this.$message({
              type: "error",
              message: "没有银行卡入账项目，请先添加",
            });
          }
        } else {
          this.$message({
            type: "error",
            message: "请输入完整信息",
          });
          return false;
        }
      });
      }
     
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.authorizationPre {
  width: 100%;
  height: 410px;
  background-color: #fff;
  .PreAuthorization_main {
    width: 100%;

    box-sizing: border-box;
    padding: 0 20px 20px 20px;
    // background: linear-gradient(177deg, #e8fdfe 0%, #ffffff 100%);
    border-radius: 5px;

    .demo-ruleForm {
      .buttonBox.el-row {
        text-align: center;
        .el-button {
          width: 100px;
          height: 40px;
          background-color: #fff;
          color: #999999;
          border-radius: 6px;
        }
        .el-button:last-child {
          width: 100px;
          height: 40px;
          background-color: #5cb5b8;
          color: #fff;
          border-radius: 6px;
        }
      }
      .form_item {
        width: 100%;
        text-align: center;
        padding-right: 20px;
        box-sizing: border-box;
      }
    }
  }
}
.tixingClass{
  position: absolute;
  width: 20px;
  height: 20px;
  top: 18%;
  left: -5%;
}
</style>