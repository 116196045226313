export default {
    namespaced: true,
    state: {
        //前台的权限编码
      usePermission: [
        {
            name: '系统',
            coding: ['1'],
            children: [
                {
                    name: '房源设置',
                    coding: ['2'],
                    children: [
                       {
                        name: '楼层',
                        coding: ['sys:floor','sys:floor:page','sys:floor:save','sys:floor:update','sys:floor:handler:delete','sys:floor:type:view']
                       },
                       {
                        name: '房型',
                        coding: ['house:type','house:type:save','house:type:update','house:type:page','house:type:special:settings:save','house:type:special:settings:page','house:type:special:settings:update','house:type:type:view','house:type:roomReportStatistics']
                       },
                       {
                        name: '房间',
                        coding: ['/wineshop/room','room:save','room:page','room:update','room:type:view','room:view','room:count','room:statistics:count','wineshop:room:room:operation','wineshop:room:import']
                       }

                    ]
                },
                {
                    name: '系统设置',
                    coding: ['system'],
                    children: [
                       {
                        name: '协议单位',
                        coding: ['agreement:unit','wineshop:agreement:unit:save','wineshop:agreement:unit:update','wineshop:agreement:unit:page','wineshop:agreement:unit:type:view','wineshop:pending_settlement:update_list','wineshop:pending_settlement:page','wineshop:agreement_unit:import']
                       },
                       {
                        name: '退房时间',
                        coding: ['sys:check:out:time','wineshop:sys:check:out:time:save','wineshop:sys:check:out:time:update','wineshop:sys:check:ut:time:page']
                       },
                       {
                        name: '班次设置',
                        coding: ['sys:classes','wineshop:sys:classes:save','wineshop:sys:classes:update','wineshop:sys:classes:page']
                       },
                       {
                        name: '房价码',
                        coding: ['wineshop:house:price:code','wineshop:house:price:code:save:list','wineshop:house:price:code:update:list','wineshop:house:price:code:page','wineshop:house:price:code:type:view','wineshop:house_price_code:housePriceList']
                       },
                       {
                        name: '付款方式',
                        coding: ['wineshop:payment:method','wineshop:payment:method:save','wineshop:payment:method:update','wineshop:payment:method:page','wineshop:payment:method:type:view']
                       },
                       {
                        name: '消费设置',
                        coding: ['wineshop:consumption','wineshop:consumption:save','wineshop:consumption:update','wineshop:consumption:page','wineshop:consumption:type:view']
                       },
                       {
                        name: '夜审设置',
                        coding: ['wineshop:night:auditor','wineshop:night:auditor:save','wineshop:night:auditor:update','wineshop:night:auditor:page','wineshop:night:auditor:page:view','wineshop:night:auditor:add']
                       },
                       {
                        name: '房型特殊日期价格设置',
                        coding: ['wineshop:special:settings','wineshop:special:settings:save','wineshop:special:settings:update','wineshop:special:settings:page']
                       },
                       {
                        name: '房间状态中间表',
                        coding: ['wineshop:roomState','wineshop:special:settings:dirty:cleans','wineshop:special:settings:state:update','wineshop:special:settings:dirty:getList']
                       },
                       {
                        name: '维修房登记',
                        coding: ['wineshop:maintenanceRoomRegistration','wineshop:maintenanceRoomRegistration:all']
                       },
                       {
                        name: '班结',
                        coding: ['/wineshop/classesOver','classes:over:ALL']
                       },
                       {
                        name: '用户班次',
                        coding: ['user:classes','user:classes:all']
                       },
                       {
                        name: '餐桌',
                        coding: ['repast::table','base:repast:table','qr:code:count']
                       },
                       {
                        name: '客厅',
                        coding: ['repast:parlor','repast:parlor:all']
                       },
                       {
                        name: '商超',
                        coding: ['api:base:shop','base:shopOrder:createShopOrder']
                       },
                       {
                        name: '系统设置',
                        coding: ['sys:pms:setting','sys:pms:setting:config']
                       }

                    ]
                }
            ]
        },
        {
            name: '报表',
            coding: []
        }
      ]
    },
    mutations: {
    
    }
  }