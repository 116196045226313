import { Message } from 'element-ui';

export default {
  data() {
    return {
      socket: null,
	  isSpeaking: false ,// 标记是否正在播放语音
    };
  },
  methods: {
    connectWebSocket() {
        console.log("------------------webSocket");
  //       const host = window.location.host;
		// const protocol = window.location.protocol;
	  const hostlist = window.location;
	  // console.log('dizhi',hostlist);
	  // const host = process.env.VUE_APP_WEB_URL.split('//');
	  const host = window.location.origin.split('//');
      const socketUrl = host[0].indexOf('https')!=-1?'wss':'ws'+'://'+host[1]+'/api/wsMsg/anno/OrderMsg/'+localStorage.getItem('TenantId')+'/'+localStorage.getItem('employeeId'); // 替换为你的WebSocket服务URL
      // const socketUrl ='ws'+'://'+'1.12.68.153:18760'+'/api/wsMsg/anno/OrderMsg/'+localStorage.getItem('TenantId')+'/'+localStorage.getItem('employeeId'); // 替换为你的WebSocket服务URL
      var str1 = host[0].indexOf('https')!=-1?'wss':'ws';
      var str2 ='://'+ host[1]+'/api/wsMsg/anno/OrderMsg/'+localStorage.getItem('TenantId')+'/'+localStorage.getItem('employeeId');
      var str3 = str1+str2;
	  console.log('地址',str3);
	  // console.log('地址',host);
	  // console.log('链接地址',socketUrl);
	  // console.log('配置',process.env)
	  // console.log('配置',process.env.VUE_APP_WEB_URL)
	  this.socket = new WebSocket(str3);

      this.socket.onopen = () => {
        console.log('WebSocket连接已打开');
      };

      this.socket.onmessage = (event) => {
        const message = event.data;
        console.log('收到消息:', message);
        // 在这里处理接收到的消息
        this.handleMessage(message);
      };

      this.socket.onclose = () => {
        console.log('WebSocket连接已关闭');
      };

      this.socket.onerror = (error) => {
        console.error('WebSocket连接发生错误:', error);
        Message.error('WebSocket连接发生错误');
      };
    },
    closeWebSocket() {
      if (this.socket) {
        this.socket.close();
      }
    },
    handleMessage(message) {
      // 在这里编写处理接收到消息的逻辑
      // 可以将消息存储到Vue组件的数据中，或者调用其他方法进行处理
	  console.log('消息消息',JSON.parse(message));
	  this.speak(message.bizName);
    },
	
	async speak(text) {
		if (!this.isSpeaking) { // 如果没有正在播放就进行播放
			this.isSpeaking = true; // 标志为正在播放
			const utterance = new SpeechSynthesisUtterance(text);
				utterance.onend = () => {
				this.isSpeaking = false; // 播放结束后置标志为未播放
			};
			utterance.rate = 0.8;
			utterance.voice = speechSynthesis.getVoices()[0];
			speechSynthesis.speak(utterance);
		} else {
			return ''
		}
	},
	// websocketonmessage(res) {
	// 	const received_msg = JSON.parse(res.data)
	// 	const panelData = received_msg.result.location
	// 	const resultText =  received_msg.result.text
	// 	if(panelData===1){
	// 		this.speak(resultText)
	// 	}else {
	// 		return ''
	// 	}
	// },
	
  },
  mounted() {
    this.connectWebSocket();
  },
  beforeDestroy() {
    this.closeWebSocket();
  }
};