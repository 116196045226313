<template>
  <!-- 办理入住的弹窗 -->
  <!-- 办理入住的表单 -->
  <div class="check_main" v-if="isCheckMain">
    <div class="CheckIn_color">
      <!-- 标题部分 -->
      <!-- <div class="order_title">
          <p>{{ ruleForm.roomName }}-{{ ruleForm.houseTypeName }}</p>
          <div class="title_icon">
            <i
              class="el-icon-close"
              style="color: black; font-size: 25px"
              @click="offBox"
            >
            </i>
          </div>
        </div> -->
      <!-- 标题部分 -->

      <!-- 住客信息表单 -->
      <div class="residentMessage">
        <!-- 标题部分 -->
        <div class="resident_title">
          <p><span></span>住客信息</p>
          <div class="resident_button">
            <el-button
              @click="SetSubject(1)"
              :class="{ active: Subject_index == 1 }"
              >设置主客</el-button
            >
            <el-button
              @click="SetSubject(2)"
              :class="{ active: Subject_index == 2 }"
              >新增客人</el-button
            >
            <el-button
              @click="SetSubject(3)"
              :class="{ active: Subject_index == 3 }"
              >换房</el-button
            >
          </div>
        </div>
        <!-- 标题部分 -->
        <!-- 用户标签，点击标签切换不同用户信息 -->
        <div class="resident_label">
          <ul>
            <li
              v-for="(el, index) in userinfo"
              :key="index"
              :style="{ display: userinfo.lengths == 0 ? 'none' : '' }"
              :class="{
                active: el.isMaster ? true : false,
                starLI: el.isMaster ? false : istableCar == index,
              }"
              @click="dbCustomersTag(index)"
            >
              <span>
                {{ el.name ? el.name : "用户姓名" }}
                <img
                  src="../../../assets/icons/星_收藏_标记.png"
                  alt=""
                  :class="{ active: el.isMaster }"
                />
                <i
                  class="el-icon-close"
                  @click="DeteleAreUser(index)"
                  :class="{
                    active: el.isMaster ? false : istableCar == index,
                  }"
                ></i>
              </span>
            </li>
            <!-- <li
                  v-for="el in ruleForm.companions" :key="el.idCard"
                    :class="{ active: li_active === 2 }"
                    @click="labelClick(el.idCard)"
                  >
                    {{el.name}}
                  </li> -->
          </ul>
        </div>

        <!-- 客户信息表单 -->
        <el-form
          :inline="true"
          :model="userinfo[istableCar]"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <!-- 主客展示 -->

          <el-form-item
            label-width="90px"
            style="position: relative"
            label="姓名"
            prop="name"
          >
            <el-input
              style="width: 110px; font-size: 12px"
			  @change="nameChange"
              v-model.trim="userinfo[istableCar].name"
              placeholder="姓名"
            ></el-input>
            <img
              class="searchIdcard"
              @click="ReadingDataButton(istableCar)"
              src="../../../assets/icons/会员2.png"
              alt=""
            />
          </el-form-item>

          <el-form-item label-width="80px" label="顾客性别" prop="gender">
            <el-select
              style="width: 90px; font-size: 12px"
              clearable
              @change="genderChyange(userinfo[istableCar].gender)"
              v-model="userinfo[istableCar].gender"
              placeholder="性别"
            >
              <el-option label="男" value="0"></el-option>
              <el-option label="女" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label-width="80px" label="证件类型">
            <el-select
              clearable
              style="width: 120px; font-size: 12px"
              v-model="userinfo[istableCar].idType"
              placeholder="请选择证件类型"
            >
              <el-option label="身份证" value="1"></el-option>
              <el-option label="驾驶证" value="2"></el-option>
              <el-option label="军官证" value="3"></el-option>
              <el-option label="通行证" value="4"></el-option>
              <el-option label="护照" value="5"></el-option>
              <el-option label="其他" value="6"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label-width="90px"
            style="font-size: 12px"
            label="证件号码"
            prop="idCard"
          >
            <el-input
              style="font-size: 12px; width: 180px"
              clearable
              @change="idCardButton"
              v-model.trim="userinfo[istableCar].idCard"
              placeholder="证件号码"
            ></el-input>
          </el-form-item>

          <el-form-item
            label-width="90px"
            style="position: relative"
            label="手机号码"
            prop="mobile"
          >
            <el-input
              style="font-size: 12px; width: 140px"
              clearable
              v-model.trim="userinfo[istableCar].mobile"
              placeholder="手机号码"
              @change="mobileButton(userinfo[istableCar].mobile, istableCar)"
            ></el-input>
          </el-form-item>
          <el-form-item label-width="80px" label="会员号" prop="date2">
            <el-input
              style="font-size: 12px; width: 140px"
              :disabled="true"
              v-model="userinfo[istableCar].number"
              placeholder="会员号"
            ></el-input>
          </el-form-item>
          <el-form-item label-width="80px" label="会员等级">
            <el-input
              style="font-size: 12px; width: 90px"
              :disabled="true"
              v-model="userinfo[istableCar].memberGradeName"
              placeholder="会员等级"
            ></el-input>
          </el-form-item>

          <el-form-item label-width="80px" label="顾客生日" prop="birthday">
            <el-date-picker
              style="font-size: 12px; width: 140px"
              v-model="userinfo[istableCar].birthday"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="顾客生日日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <!-- 客户信息表单 -->
      </div>
    </div>
    <!-- 住客信息表单 -->

    <!-- 房单信息表单 -->
    <div class="checkMessage">
      <!-- 标题 -->
      <div class="checkTitle">
        <p>
          <span></span>
          房单信息
        </p>
      </div>
      <!-- 标题 -->

      <div class="checkMessage_main">
        <!-- 入住信息表单 -->
        <el-form
          :inline="true"
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="90px"
          class="demo-ruleForm"
        >
          <el-form-item
            label-width="90px"
            style="position: relative"
            label="抵店时间"
            prop="arrivalTime"
          >
            <el-date-picker
              disabled
              clearable
              style="width: 180px; font-size: 12px"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="ruleForm.arrivalTime"
              type="datetime"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item
            label-width="80px"
            label="入住类型"
            prop="settlementType"
          >
            <el-select
              style="width: 110px"
              clearable
              @change="settlementTypeButton"
              v-model="ruleForm.settlementType"
            >
              <el-option label="全天房" value="0"></el-option>
              <el-option label="钟点房" value="1"></el-option>
              <el-option label="自用房" value="2"></el-option>
              <el-option label="免费房" value="3"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            label-width="70px"
            style="position: relative"
            label="门市价"
            prop="roomPrice"
          >
            <el-input
              clearable
              :disabled="true"
              style="font-size: 12px; width: 120px"
              v-model="ruleForm.roomPrice"
            ></el-input>
          </el-form-item>

          <el-form-item
            label-width="70px"
            style="position: relative"
            label="折扣率"
            prop="discount"
          >
            <el-input
              clearable
              style="font-size: 12px; width: 115px"
              v-model="ruleForm.discount"
              @change="getRoomRealityPrice(1)"
            ></el-input>
          </el-form-item>
          <el-form-item
            label-width="90px"
            label="实际房价"
            prop="roomRealityPrice"
          >
            <el-input
              clearable
              style="font-size: 12px; width: 110px"
              v-model="ruleForm.roomRealityPrice"
              @change="getDiscount"
            ></el-input>
          </el-form-item>
          <el-form-item
            label-width="90px"
            style="position: relative"
            label="预离时间"
            prop="predepartureTime"
          >
            <el-date-picker
              style="width: 180px; font-size: 12px"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="ruleForm.predepartureTime"
              type="datetime"
              @change="LeaveDate"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item
            v-if="this.ruleForm.settlementType != '1'"
            label-width="80px"
            label="预住天数"
            prop="reserveDays"
          >
            <el-input
              style="font-size: 12px; width: 80px"
              @change="ChangereserveDays(ruleForm.reserveDays)"
              v-model="ruleForm.reserveDays"
            ></el-input>
          </el-form-item>

          <el-form-item
            v-if="this.ruleForm.settlementType == '1'"
            label-width="90px"
            label="钟点时长"
            prop="reserveDays"
          >
            <el-input
              clearable
			  @change="changeTime"
              style="font-size: 12px; width: 90px"
              v-model="ruleForm.hourLength"
            >
			<i slot="suffix" style="font-style: normal; margin-right: 10px">小时</i>
			</el-input>
          </el-form-item>

          <el-form-item
            v-if="this.ruleForm.settlementType == '1'"
            label-width="80px"
            label="超时价"
            prop="overstepHourPrice"
          >
            <el-input
              clearable
              style="font-size: 12px; width: 102px"
              v-model="ruleForm.overstepHourPrice"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="this.ruleForm.settlementType != '1'"
            label-width="80px"
            label="明日早餐"
            prop="breakfast"
          >
            <el-input
              clearable
              @change="breakfastButton"
              style="font-size: 12px; width: 125px"
              v-model.number="ruleForm.breakfast"
            ></el-input>
          </el-form-item>
          <el-form-item label-width="90px" label="房价码" prop="housePriceId">
            <el-input
              disabled
              style="width: 209px; font-size: 12px"
              v-model="ruleForm.housePriceName"
            ></el-input>
          </el-form-item>

          <el-form-item label-width="90px" label="客户类型" prop="clientType">
            <el-select
              style="width: 155px; font-size: 12px"
              clearable
              v-model="ruleForm.clientType"
			  @change='clientTypeChange'
            >
              <el-option label="散客" value="0"></el-option>
              <el-option disabled label="会员" value="1"></el-option>
              <el-option disabled label="协议" value="2"></el-option>
              <el-option label="OTA" value="3"></el-option>
            </el-select>
          </el-form-item>
		  
		  <el-form-item label-width="90px" label="OTA名称" prop="clientType" v-if="ruleForm.clientType == '3'">
		    <el-select
		      style="width: 155px; font-size: 12px"
		      clearable
		      v-model="ruleForm.otaName"
		    >
		      <el-option label="美团" value="美团"></el-option>
		      <el-option label="携程" value="携程"></el-option>
		      <el-option label="飞猪" value="飞猪"></el-option>
		      <el-option label="抖音" value="抖音"></el-option>
		    </el-select>
		  </el-form-item>
			<!-- width: 210px" -->
          <el-form-item
            label-width="90px"
            style="position: relative"
            label="会员号"
          >
            <el-input
              :disabled="true"
              placeholder="会员号"
              style="font-size: 12px; width: 120px"
              v-model="member.number"
            ></el-input>
          </el-form-item>
		  <!-- width: 340px -->
          <el-form-item label-width="90px" label="会员等级">
            <el-input
              style="font-size: 12px; width: 210px"
              placeholder="会员等级"
              v-model="member.memberGradeName"
              :disabled="true"
            ></el-input>
          </el-form-item>

          <el-form-item
            label-width="90px"
            label="协议单位"
            prop="agreementUnitId"
          >
		  <!-- width: 335px -->
            <el-select
              clearable
              style="font-size: 12px; width: 210px"
              @change="agreementchange(ruleForm.agreementUnitId)"
              v-model="ruleForm.agreementUnitId"
              placeholder="请选择协议单位"
            >
              <el-option
                v-for="el in BargainingUnitList"
                :key="el.id"
                :label="el.name"
                :value="el.id"
              ></el-option>
            </el-select>
            <!-- <img
                    style="position: absolute; right: -40px; top: 10px"
                    src="../../../assets/icons/编辑.png"
                    alt=""
                  /> -->
          </el-form-item>
          <el-row>
            <el-form-item label-width="90px" label="备注信息" prop="createdBy">
              <el-input
                style="width: 774px"
                type="textarea"
                class="textareaInput"
                :rows="2"
                @change="UpdateDate"
                placeholder="请输入备注信息,200字以内"
                v-model="ruleForm.remark"
              >
              </el-input>
            </el-form-item>
          </el-row>
          <!-- 备注区域 -->
          <!-- <div class="remark">
                <p class="remark_title">备注信息:</p>
               
                  <el-input
                    type="textarea"
                    class="textareaInput"
                    :rows="2"
                    style="font-size: 12px"
                    @change="UpdateDate"
                    placeholder="请输入备注信息,200字以内"
                    v-model="ruleForm.remark">
                  </el-input>
              </div> -->
          <!-- 备注区域 -->
        </el-form>
        <!-- 入住信息表单 -->
      </div>
    </div>
    <!-- 入住信息表单 -->

    <div class="submit_button">
      <el-button @click="offButton">取消</el-button>
      <el-button
        style="background-color: #5cb5b8; color: #fff"
        @click="submitBox('ruleForm')"
        >办理入住</el-button
      >
    </div>
    <!-- 再次确认是否办理入住弹窗 -->
    <!-- <div class="reconfirm_Box" v-if="isReconfirm" @click="clickReconfirm_Box">
      <div class="reconfirm">
        <div class="save_size">
          <p>
            <img src="../../../assets/icons/警告 (1).png" alt="" />
            确认
          </p>
          <span class="size_floot">确认入住吗？</span>
        </div>

        <div class="save_button">
          <el-button class="cancelButton" @click="addRooms(1)">
            取消
          </el-button>
          <el-button
            @click="addRooms(2)"
            style="background-color: #fdd4d9; color: #d9001b"
            class="submitButton"
          >
            确定
          </el-button>
        </div>
      </div>
    </div> -->
    <WhetherDelete v-if="isReconfirm" ref="whetherBoxs" @whetherDelete="addRooms"></WhetherDelete>
    <!-- 再次确认是否办理入住弹窗 -->
    <el-dialog
      title="确认入账"
      class="roomBox"
      :visible.sync="isEnter"
      :before-close="handleClose"
      width="500px"
      append-to-body
    >
      <addenter ref="AddPrice" @AddenterOff="AddenterOff"></addenter>
    </el-dialog>
    
    <el-dialog
      title="更换房间"
      class="roomBox"
      :visible.sync="isRooms"
      width="70%"
      append-to-body
    >
      <change-room ref="RoomBox" :id="ruleForm" @ROOMEMIT="ROOMEMIT"></change-room>
    </el-dialog>

    <!-- 点击删除弹出再次确认弹窗的阴影盒子 -->
    <div class="savePop" v-if="isHint">
      <!-- 弹窗 -->
      <div class="save_main">
        <div class="save_size">
          <p>
            <img src="../../../assets/icons/警告 (1).png" alt="" />
            提示
          </p>
          <span class="size_floot">{{ orderList }}</span>
        </div>

        <div class="save_button">
          <el-button
            @click="areDelete"
            style="background-color: #5cb5b8; color: #fff"
            class="submitButton"
          >
            知道了
          </el-button>
        </div>
      </div>
    </div>
  
  </div>
  <!-- 办理入住的表单 -->
</template>

<script>
import { AdddataStatistics, orderFromList, userInfoGet } from "_api/orderFrom";
import { HousePriceCode } from "_api/member";
import { BargainingUnitAll, advanceOrderList } from "_api/advanceOrder";
import { housingPriceExamine, listCheckOutTime } from "_api/systemSetting";
import { roomsuoupdate ,getHousePriceList } from "_api/housingSystem";
import Addenter from "./orderInformation/components/FinanceMessage/Addenter.vue";
import ChangeRoom from "./orderInformation/ChangeRoom.vue";
import axios from "axios";
import WhetherDelete from "@/components/WhetherDelete.vue";
export default {
  name: "CheckIn",
  components: {
    Addenter,
    ChangeRoom,
    WhetherDelete
  },
  data() {
    return {
      isEnter: false,
      isHint: false,
      roo: null,
      orderFrom: {},
      BargainingUnitList: [], //协议挂账的数据
      DatetableData: [], //退房时间的数据集合
      ruleForm: {
        agreementUnitId: null, //协议单位Id
        arrivalTime: "", //抵店时间
        birthday: "", //生日
        breakfast: "0份", //早餐份数
        clientType: "0", //客户类型 0散户 1会员 2协议
        discount: "1", //折扣率
        gender: "0", //性别 0 男 1 女
        hourLength: 6, //钟点时长
        companions: [],
        idCard: "", //身份证号码
        mobile: "", //手机号码
        name: "", //顾客姓名
        memberId: null, //会员id
        houseTypeName: this.$store.state.base.addRoomData.echoMap.houseTypeId, //房型名称
        predepartureTime: "", //预离时间
        remark: "", //备注信息
        reserveDays: "1天", //预住天数
        roomId: this.$store.state.base.addRoomData.id, //房间id
        roomPrice: this.$store.state.base.addRoomData.housePrice, //门市价
        roomName: this.$store.state.base.addRoomData.roomNumber,
        housePriceId: "", //房价码ID
        housePriceName: null,
        houseTypeId: undefined, //房型id 后面点击卡片获取对应房型房号和价格的信息
        roomRealityPrice: null, //实际房价
        settlementType: "0", //入驻类型 0全天房 1钟点房
        hourLength: null, //钟点时长
        overstepHourPrice: null, //超时价
        agreementUnitName: null,
		otaName:'',//OTA名称
      },
      checkDataFrom: {},//办理入住成功后返回的数据
      orderList: "",
      isRooms: false, //更换房间
      isHostAnd: true, //控制主客和随行人员的信息展示
      //手机号带出的信息
      member: {
        number: "",
        memberGradeName: "",
        memberId: null,
      },
      redio: "0",
      rules: {
        mobile: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern:
              /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        idCard: [
          { required: true, message: "请输入身份证号码", trigger: "blur" },
          {
            pattern:
              /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
            message: "请输入正确的身份证号码",
            trigger: "blur",
          },
        ],
        predepartureTime: [
          { required: true, message: "请选择预离时间", trigger: "blur" },
        ],
        name: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          {
            pattern:
              /^(([a-zA-Z+\.?\·?a-zA-Z+]{2,30}$)|([\u4e00-\u9fa5+\·?\u4e00-\u9fa5+]{2,30}$))/,
            message: "请输入正确的姓名",
            trigger: "blur",
          },
        ],
        roomRealityPrice: [
          { required: true, message: "请输入实际价格", trigger: "blur" },
          {
            pattern: /^(([0-9]|([1-9][0-9]{0,9}))((\.[0-9]{1,2})?))$/,
            message: "整数位最多十位，小数为最多为两位，可以无小数位",
            trigger: "blur",
          },
        ],
        // name: [
        //   { required: true, message: "请输入活动名称", trigger: "blur" },
        //   { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        // ],
        // date1: [{ required: true, message: "请选择日期", trigger: "blur" }],
        // date2: [
        //   { required: true, message: "18位以内，数字或字母", trigger: "blur" },
        //   {
        //     pattern: /^[0-9A-Za-z]{1,18}$/,
        //     message: "18位以内，数字或字母",
        //     trigger: "blur",
        //   },
        // ],
      },
      userinfo: [
        {
          birthday: null, //生日
          createdBy: localStorage.getItem("classesId"), //创建人ID
          gender: null, //性别 0 男 1 女
          createdTime: null, //创建时间
          idCard: null, //身份证号
          // memberId:0,//会员id
          mobile: null, //	手机号码
          name: null, //顾客姓名
          isMaster: true, //是否是主客,
          number: null,
          memberGradeName: null,
          memberId: null,
          idType: "1",
        },
      ],
      istableCar: 0,
      tableData: {},
      Subject_index: 0,
      HousePriceCodeFrom: {}, //房价码数据
      isCheckMain: true, // 控制并办理入住弹窗的显示
      isReconfirm: false, //控制再次确认弹窗的显示
      priceS: "",
      outPrice: "",
      memberPrice: "",
      housetypeinfo: {}, //原本的房间信息
      HintDate: "",
	  chinkPrice:0,//获取设置的平均价
	  currentHouseType:undefined,//fangxing
    };
  },
  // watch: {
  //   "ruleForm.clientType"(newVal) {
  //     this.getNowDate(this.ruleForm.predepartureTime, 2);
  //   },
  // },
  created() {
    this.listCheckOutTimed();
    this.rTime(this.ruleForm.arrivalTime);
    this.HousePriceCodeed();

    this.getRoomRealityPrice();
    this.BargainingUnitAlls();
    this.ChangereserveDays(this.ruleForm.reserveDays);
    this.getDiscount();
    this.ssda();
  },
  // ruleForm.reserveDays
  computed:{
	  reserveDays(){
		  return this.ruleForm.reserveDays;
	  },
  },
  watch:{
	  reserveDays(newval,oldval){
		  console.log('新值',newval)
		  console.log('旧值',oldval)
		  this.getPriceList();
	  },
  },
  
  methods: {
    genderChyange(data) {
      console.log(data);
    },
    ssda() {
      var str = "2023-07-19 08:46:38";
      str = str.slice(10);
      console.log(str);
    },
    //读取身份证的信息的接口
    ReadingDataButton(index) {
      axios({
        method: "POST",
        url: "http://127.0.0.1:19196/readcard&t=0.5676041095624611",
        headers: {
          "Sec-Fetch-Site": "same-origin",
        },
      }).then((res) => {
        console.log(res);
        console.log(index);
        this.userinfo[parseFloat(index)].name = res.data.partyName;
        // this.userinfo[parseFloatparseFloatparseFloat(index)].birthday = res.data.bornDay
        this.userinfo[parseFloat(index)].gender =
          res.data.gender == "男" ? "0" : "1";
        this.userinfo[parseFloat(index)].idCard = res.data.certNumber;
        this.$set(this.userinfo, parseFloat(index), {
          ...this.userinfo[parseFloat(index)],
        });
        this.idCardButton();
      });
    },
    //早餐变动时触发
    breakfastButton() {
      if(Number.isInteger(parseFloat(this.ruleForm.breakfast))) {
        this.ruleForm.breakfast = this.ruleForm.breakfast
        ? parseFloat(this.ruleForm.breakfast) + "份"
        : "0份";
      }else{
        this.$message({
          type: 'error',
          message: '请输入整数'
        })
        this.ruleForm.breakfast = '1份'
      }
     
    },
    settlementTypeButton() {
      if (this.ruleForm.settlementType === "0") {
        //全天房、
        if (this.ruleForm.clientType == "1") {
          //客户类型是会员
          this.mobileButton(this.userinfo[0].mobile, 0);
        } else if (this.ruleForm.clientType == "2") {
          //客户类型是协议单位
          this.agreementchange(this.ruleForm.agreementUnitId);
        } else if (this.ruleForm.clientType == "0") {
          this.getNowDate(new Date());
          this.getDaysBetween(
            this.ruleForm.arrivalTime,
            this.ruleForm.predepartureTime
          );
        }

        // if (this.ruleForm.agreementUnitId) {
        //   this.agreementchange();
        // } else if(this.userinfo[0].mobile) {

        // }
      } else if (this.ruleForm.settlementType === "1") {
        //钟点房
		console.log('超时房费',this.orderFrom.overstepHourPrice)
		this.ruleForm.overstepHourPrice = this.orderFrom.overstepHourPrice
		  ? this.orderFrom.overstepHourPrice
		  : 0;
        if (this.ruleForm.clientType == "1") {
          //客户类型是会员
          this.$set(this.ruleForm,'reserveDays','')
          // this.ruleForm.reserveDays = ''
          this.mobileButton(this.userinfo[0].mobile, 0);
        } else if (this.ruleForm.clientType == "2") {
          //客户类型是协议单位
          this.agreementchange(this.ruleForm.agreementUnitId);
        } else if (this.ruleForm.clientType == "0") {
          this.getNowDate(
            new Date(),
            3,
            this.orderFrom.hourDuration ? this.orderFrom.hourDuration : 0
          );
          this.ruleForm.roomPrice = this.orderFrom.hourPrice
            ? this.orderFrom.hourPrice
            : 0;
          this.ruleForm.roomRealityPrice = this.orderFrom.hourPrice
            ? this.orderFrom.hourPrice
            : 0;
          this.ruleForm.hourLength = this.orderFrom.hourDuration
            ? this.orderFrom.hourDuration
            : 0;
          // this.ruleForm.reserveDays = this.orderFrom.overstepHourPrice
          //   ? this.orderFrom.overstepHourPrice
          //   : 0;
		 
          // console.log(this.ruleForm.reserveDays,this.orderFrom.overstepHourPrice);
        }
        // this.outPrice = this.orderFrom.overstepHourPrice
        // console.log(this.ruleForm.overstepHourPrice);

        // if (this.ruleForm.agreementUnitId) {

        // }
        // this.mobileButton(userinfo[0].mobile, 0)
      } else if (
        this.ruleForm.settlementType === "2" ||
        this.ruleForm.settlementType === "3"
      ) {
        this.ruleForm.roomPrice = "0";
        this.ruleForm.roomRealityPrice = "0";
        this.ruleForm.discount = "100%";
        this.getNowDate(new Date());
        this.getDaysBetween(
          this.ruleForm.arrivalTime,
          this.ruleForm.predepartureTime
        );
      }
      if (this.ruleForm.agreementUnitId) {
        this.agreementchange(this.ruleForm.agreementUnitId);
      }
    },
    //协议变化
    agreementchange(id) {
      if (id) {
        this.ruleForm.clientType = "2";
        if (this.ruleForm.agreementUnitId) {
          if (this.ruleForm.settlementType === "0") {
            //全天房
            this.ruleForm.clientType = "2";
            var number = null;
            this.BargainingUnitList.forEach((el) => {
              if (id == el.id) {
                this.ruleForm.housePriceName = el.housePriceCodeName;
                this.ruleForm.agreementUnitName = el.name;
              }
            });
            var currTime = new Date().getDay();
            this.BargainingUnitList.forEach((el) => {
              if (id == el.id) {
                if (el.housePriceCodeList) {
                  if (el.housePriceCodeList.length > 0) {
                    el.housePriceCodeList.forEach((item) => {
                      if (item.houseTypeName == this.ruleForm.houseTypeName) {
                        if (currTime == 0 || currTime == 6) {
                          //是周牛周日
                          this.ruleForm.roomRealityPrice = item.weekendPrice; //暂时不考虑周末价格
                        } else {
                          this.ruleForm.roomRealityPrice = item.normalPrice; //暂时不考虑周末价格
                        }
                        console.log(currTime);
                        // this.ruleForm.roomRealityPrice = item.normalPrice;
                        this.ruleForm.houseTypeId = item.houseTypeId;
                        this.ruleForm.housePriceId = item.id;
                        // this.ruleForm.roomRealityPrice =
                        this.getDiscount();
                      }
                    });
                  }

                  // this.ruleForm.housePriceId =
                  console.log(this.ruleForm);
                } else {
					if(this.chinkPrice){
						this.ruleForm.roomRealityPrice = this.chinkPrice;
					}else{
						this.ruleForm.roomRealityPrice = this.orderFrom.housePrice
                    ? this.orderFrom.housePrice
                    : 0;
					}
                  this.getDiscount();
                }
              }
            });
            this.getDaysBetween(
              this.ruleForm.arrivalTime,
              this.ruleForm.predepartureTime
            );
          } else if (this.ruleForm.settlementType === "1") {
            //钟点房
            this.ruleForm.clientType = "2";
            this.BargainingUnitList.forEach((el) => {
              if (id == el.id) {
                this.ruleForm.housePriceName = el.housePriceCodeName;
                this.ruleForm.agreementUnitName = el.name;
              }
            });
            this.BargainingUnitList.forEach((el) => {
              if (id == el.id) {
                if (el.housePriceCodeList) {
                  if (el.housePriceCodeList.length > 0) {
                    el.housePriceCodeList.forEach((item) => {
                      if (item.houseTypeName == this.ruleForm.houseTypeName) {
                        this.ruleForm.roomRealityPrice = item.hourlyPrice;
                        this.ruleForm.houseTypeId = item.houseTypeId;
                        this.ruleForm.reserveDays = item.overtimePrice;
                        this.ruleForm.hourLength = parseInt(item.hourLength);
                        this.ruleForm.housePriceId = item.id;
                        this.getDiscount();
                        this.getNowDate(
                          new Date(),
                          3,
                          this.ruleForm.hourLength
                        );
                      }
                    });
                  }
                  // this.ruleForm.housePriceId =
                } else {
                  this.ruleForm.roomRealityPrice = this.orderFrom.hourPrice
                    ? this.orderFrom.hourPrice
                    : 0;
                  this.ruleForm.reserveDays = this.orderFrom.overstepHourPrice
                    ? this.orderFrom.overstepHourPrice
                    : 0;
                  this.ruleForm.hourLength = this.orderFrom.hourDuration
                    ? this.orderFrom.hourDuration
                    : 0;
                  this.getNowDate(new Date(), 3, this.ruleForm.hourLength);
                }
              }
            });
          } else if (
            this.ruleForm.settlementType === "2" ||
            this.ruleForm.settlementType === "3"
          ) {
            this.ruleForm.roomPrice = "0";
            this.ruleForm.roomRealityPrice = "0";
            this.ruleForm.discount = "100%";
            console.log("iguygiugiujhk");
            this.BargainingUnitList.forEach((el) => {
              if (id == el.id) {
                if (el.housePriceCodeList) {
                  if (el.housePriceCodeList.length > 0) {
                    el.housePriceCodeList.forEach((item) => {
                      if (item.houseTypeName == this.ruleForm.houseTypeName) {
                        this.ruleForm.houseTypeId = item.houseTypeId;
                        this.ruleForm.housePriceId = item.id;
                        this.ruleForm.housePriceName = item.name;
                      }
                    });
                  } else {
                    this.ruleForm.housePriceId = "";
                    this.ruleForm.housePriceName = "";
                  }
                } else {
                  this.ruleForm.housePriceId = "";
                  this.ruleForm.housePriceName = "";
                }
              } else {
                this.ruleForm.housePriceId = "";
                this.ruleForm.housePriceName = "";
              }
            });
            console.log(this.ruleForm.housePriceName);
          }
        } else {
          if (this.member.number) {
            this.ruleForm.clientType = "1";
          } else {
            this.ruleForm.clientType = "0";
          }
        }
      } else {
        this.ruleForm.agreementUnitId = "";
        this.ruleForm.agreementUnitName = "";
        if (this.userinfo[0].mobile) {
          this.ruleForm.clientType = "0";
          this.mobileButton(this.userinfo[0].mobile, 0);
        } else {
          this.ruleForm.clientType = "0";
          if (this.ruleForm.settlementType === "0") {
            //全天房、
            if (this.ruleForm.clientType == "1") {
              //客户类型是会员
              this.mobileButton(this.userinfo[0].mobile, 0);
            } else if (this.ruleForm.clientType == "0") {
              this.getDaysBetween(
                this.ruleForm.arrivalTime,
                this.ruleForm.predepartureTime
              );
              this.getDiscount();
              this.getNowDate(new Date());
            }

            // if (this.ruleForm.agreementUnitId) {
            //   this.agreementchange();
            // } else if(this.userinfo[0].mobile) {

            // }
          } else if (this.ruleForm.settlementType === "1") {
            //钟点房
            if (this.ruleForm.clientType == "1") {
              //客户类型是会员
              this.mobileButton(this.userinfo[0].mobile, 0);
            } else if (this.ruleForm.clientType == "0") {
              this.ruleForm.roomPrice = this.orderFrom.hourPrice
                ? this.orderFrom.hourPrice
                : 0;
              this.ruleForm.roomRealityPrice = this.orderFrom.hourPrice
                ? this.orderFrom.hourPrice
                : 0;
              this.ruleForm.hourLength = this.orderFrom.hourDuration
                ? this.orderFrom.hourDuration
                : 0;
              this.ruleForm.reserveDays = this.orderFrom.overstepHourPrice
                ? this.orderFrom.overstepHourPrice
                : 0;
              this.getDiscount();
              console.log(this.orderFrom.hourDuration);
              this.getNowDate(new Date(), 3, this.ruleForm.hourLength);
            }
            // this.outPrice = this.orderFrom.overstepHourPrice
            // console.log(this.ruleForm.overstepHourPrice);

            // if (this.ruleForm.agreementUnitId) {

            // }
            // this.mobileButton(userinfo[0].mobile, 0)
          } else if (
            this.ruleForm.settlementType === "2" ||
            this.ruleForm.settlementType === "3"
          ) {
            this.ruleForm.roomPrice = "0";
            this.ruleForm.roomRealityPrice = "0";
            this.ruleForm.discount = "100%";
            this.ruleForm.housePriceId = "";
            this.ruleForm.housePriceName = "";
          }
        }
      }

      // if (!id) {
      //   this.ruleForm.housePriceId = "";
      //   this.ruleForm.housePriceName = "";
      //   this.ruleForm.agreementUnitName = "";
      //
      // }
    },
    //再次确定弹窗的点击
    clickReconfirm_Box(e) {
      if (e.target.className === "reconfirm_Box") {
        this.isReconfirm = false;
      }
    },
	//姓名变化
	nameChange(){
		if(this.userinfo[this.istableCar].name){
		  // result = str.replace(/(^\s+)|(\s+$)/g,""); 去除字符串前后空格
		  this.userinfo[this.istableCar].name = this.userinfo[this.istableCar].name.replace(/\s/g,""); // 去除字符串全部空格
		}
	},
    //身份证值变动时触发
    idCardButton() {
      this.userinfo[this.istableCar].idCard;
	  if(this.userinfo[this.istableCar].idCard){
	    this.userinfo[this.istableCar].idCard = this.userinfo[this.istableCar].idCard.replace(/\s/g,""); // 去除字符串全部空格
	  }
      let birthday = "";
      if (
        this.userinfo[this.istableCar].idCard != null &&
        this.userinfo[this.istableCar].idCard != ""
      ) {
        if (this.userinfo[this.istableCar].idCard.length == 15) {
          birthday = "19" + this.userinfo[this.istableCar].idCard.substr(6, 6);
          this.userinfo[this.istableCar].birthday = new Date(birthday);
        } else if (this.userinfo[this.istableCar].idCard.length == 18) {
          birthday = this.userinfo[this.istableCar].idCard.substr(6, 8);
          this.userinfo[this.istableCar].birthday = new Date(birthday);
        }
        birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-");

        this.userinfo[this.istableCar].birthday = new Date(birthday);
      }

      var date1 = this.userinfo[this.istableCar].birthday;
      var sign2 = ":";
      var year = date1.getFullYear(); // 年
      var month = date1.getMonth() + 1; // 月
      var day = date1.getDate(); // 日
      var hour = date1.getHours(); // 时
      var minutes = date1.getMinutes(); // 分
      var seconds = date1.getSeconds(); //秒
      // 给一位数的数据前面加 “0”
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (day >= 0 && day <= 9) {
        day = "0" + day;
      }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }
      this.userinfo[this.istableCar].birthday = year + "-" + month + "-" + day;
      return birthday;
    },

    //获取当前时间
    getNowDate(date, num, hourDuration) {
      console.log(hourDuration);
      var hourLength = new Date(
        new Date(date).getTime() + parseFloat(hourDuration) * 60 * 60 * 1000
      );
      var date1 = new Date(date);
      var out = new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000);
      var sign2 = ":";
      var year2 = hourLength.getFullYear(); // 年
      var month2 = hourLength.getMonth() + 1; // 月
      var day2 = hourLength.getDate(); // 日
      var hour2 = hourLength.getHours(); // 时
      var minutes2 = hourLength.getMinutes(); // 分
      var seconds2 = hourLength.getSeconds(); //秒
      var year1 = out.getFullYear(); // 年
      var month1 = out.getMonth() + 1; // 月
      var day1 = out.getDate(); // 日
      var hour1 = out.getHours(); // 时
      var minutes1 = out.getMinutes(); // 分
      var seconds1 = out.getSeconds(); //秒
      var year = date1.getFullYear(); // 年
      var month = date1.getMonth() + 1; // 月
      var day = date1.getDate(); // 日
      var hour = date1.getHours(); // 时
      var minutes = date1.getMinutes(); // 分
      var seconds = date1.getSeconds(); //秒
      // 给一位数的数据前面加 “0”
      if (month2 >= 1 && month2 <= 9) {
        month2 = "0" + month2;
      }
      if (day2 >= 0 && day2 <= 9) {
        day2 = "0" + day2;
      }
      if (hour2 >= 0 && hour2 <= 9) {
        hour2 = "0" + hour2;
      }
      if (minutes2 >= 0 && minutes2 <= 9) {
        minutes2 = "0" + minutes2;
      }
      if (seconds2 >= 0 && seconds2 <= 9) {
        seconds2 = "0" + seconds2;
      }

      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (day >= 0 && day <= 9) {
        day = "0" + day;
      }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }
      if (month1 >= 1 && month1 <= 9) {
        month1 = "0" + month1;
      }
      if (day1 >= 0 && day1 <= 9) {
        day1 = "0" + day1;
      }
      if (hour1 >= 0 && hour1 <= 9) {
        hour1 = "0" + hour1;
      }
      if (minutes1 >= 0 && minutes1 <= 9) {
        minutes1 = "0" + minutes1;
      }
      if (seconds1 >= 0 && seconds1 <= 9) {
        seconds1 = "0" + seconds1;
      }
      if (num === 2) {
        // 客户类型 0散户 1会员 2协议 3OTA
        if (this.ruleForm.clientType == "0") {
          this.DatetableData.forEach((el) => {
            if (el.name == "散客退房时间") {
              this.ruleForm.predepartureTime =
                year + "-" + month + "-" + day + " " + el.checkOutTime;
            }
          });
        } else if (this.ruleForm.clientType == "1") {
          this.DatetableData.forEach((el) => {
            if (el.name == "会员客类退房时间") {
              this.ruleForm.predepartureTime =
                year + "-" + month + "-" + day + " " + el.checkOutTime;
            }
          });
        } else if (this.ruleForm.clientType == "2") {
          this.DatetableData.forEach((el) => {
            if (el.name == "协议客类退房时间") {
              this.ruleForm.predepartureTime =
                year + "-" + month + "-" + day + " " + el.checkOutTime;
            }
          });
        } else if (this.ruleForm.clientType == "3") {
          this.DatetableData.forEach((el) => {
            if (el.name == "OTA客类退房时间") {
              this.ruleForm.predepartureTime =
                year + "-" + month + "-" + day + " " + el.checkOutTime;
            }
          });
        }
        console.log(this.ruleForm.predepartureTime, "1213333331");
      } else if (num === 3) {
        // 客户类型 0散户 1会员 2协议 3OTA
        this.ruleForm.arrivalTime =
          year +
          "-" +
          month +
          "-" +
          day +
          " " +
          hour +
          sign2 +
          minutes +
          sign2 +
          seconds;

        this.ruleForm.predepartureTime =
          year2 +
          "-" +
          month2 +
          "-" +
          day2 +
          " " +
          hour2 +
          sign2 +
          minutes2 +
          sign2 +
          seconds2;
        // this.$store.state.base.addRoomData.overstepHourPrice;
        console.log(this.ruleForm.predepartureTime);
      } else {
        // 客户类型 0散户 1会员 2协议 3OTA
        if (this.ruleForm.clientType == "0") {
          this.DatetableData.forEach((el) => {
            if (el.name == "散客退房时间") {
              this.ruleForm.predepartureTime =
                year1 + "-" + month1 + "-" + day1 + " " + el.checkOutTime;
            }
          });
        } else if (this.ruleForm.clientType == "1") {
          this.DatetableData.forEach((el) => {
            if (el.name == "会员客类退房时间") {
              this.ruleForm.predepartureTime =
                year1 + "-" + month1 + "-" + day1 + " " + el.checkOutTime;
            }
          });
        } else if (this.ruleForm.clientType == "2") {
          this.DatetableData.forEach((el) => {
            if (el.name == "协议客类退房时间") {
              this.ruleForm.predepartureTime =
                year1 + "-" + month1 + "-" + day1 + " " + el.checkOutTime;
            }
          });
        } else if (this.ruleForm.clientType == "3") {
          this.DatetableData.forEach((el) => {
            if (el.name == "OTA客类退房时间") {
              this.ruleForm.predepartureTime =
                year1 + "-" + month1 + "-" + day1 + " " + el.checkOutTime;
            }
          });
        }
        this.ruleForm.arrivalTime =
          year +
          "-" +
          month +
          "-" +
          day +
          " " +
          hour +
          sign2 +
          minutes +
          sign2 +
          seconds;
        this.HintDate = year + "-" + month + "-" + day;
        this.ruleForm.reserveDays = "1天";
        if (
          this.ruleForm.settlementType != "2" &&
          this.ruleForm.settlementType != "3"
        ) {
			if(this.chinkPrice){
				this.ruleForm.roomRealityPrice = this.chinkPrice
			}else{
				 this.ruleForm.roomRealityPrice = this.orderFrom.housePrice
				? this.orderFrom.housePrice
				: 0;
			}
         
        }

        console.log(this.ruleForm.predepartureTime, "1213333331");
      }
	  this.getPriceList();
    },
    //备注信息变动时触发
    UpdateDate() {},

    //房价码查看接口
    HousePriceCodeed() {
      housingPriceExamine({
        houseTypeId: this.$store.state.base.addRoomData.houseTypeId,
      }).then((res) => {
        if (res.data.code === 0) {
          this.HousePriceCodeFrom = res.data.data;
        } else {
          this.$message({
            type: "error",
            message: "接口请求失败",
          });
        }
      });
    },

    //手机号变动
    mobileButton(num, index) {
      console.log(num);
	  if(num){
	    // result = str.replace(/(^\s+)|(\s+$)/g,""); 去除字符串前后空格
	    num = num.replace(/\s/g,""); // 去除字符串全部空格
	  }
	  if(this.userinfo[this.istableCar].mobile){
		this.userinfo[this.istableCar].mobile = this.userinfo[this.istableCar].mobile.replace(/\s/g,""); // 去除字符串全部空格
	  }
      if (!this.ruleForm.agreementUnitId) {
        if (num) {
          this.userInfoGeted(num, index);
        } else {
          if (!this.ruleForm.agreementUnitId) {
            this.ruleForm.clientType = "0";
            if (this.ruleForm.settlementType == "0") {
              //全天房
              this.getNowDate(new Date());
			  if(this.chinkPrice){
				  this.ruleForm.roomRealityPrice = this.chinkPrice
			  }else{
				  this.ruleForm.roomRealityPrice = this.orderFrom.housePrice
                ? this.orderFrom.housePrice
                : 0;
			  }
              
              this.ruleForm.housePriceId = "";
              this.ruleForm.housePriceName = "";
              this.member = {
                number: "",
                memberGradeName: "",
                memberId: null,
              };
              this.getDiscount();
            } else if (this.ruleForm.settlementType == "1") {
              //钟点房
              // this.ruleForm.housePriceId = ''
              // this.ruleForm.housePriceName = ''
              // this.getNowDate(new Date(), 3,this.orderFrom.hourDuration)
              this.ruleForm.roomPrice = this.orderFrom.hourPrice
                ? this.orderFrom.hourPrice
                : 0;
              this.ruleForm.roomRealityPrice = this.orderFrom.hourPrice
                ? this.orderFrom.hourPrice
                : 0;
              this.member = {
                number: "",
                memberGradeName: "",
                memberId: null,
              };
              this.ruleForm.housePriceId = "";
              this.ruleForm.housePriceName = "";
              this.getDiscount();
              this.getNowDate(
                new Date(),
                3,
                this.orderFrom.hourDuration ? this.orderFrom.hourDuration : 0
              );
              this.ruleForm.hourLength = this.orderFrom.hourDuration
                ? this.orderFrom.hourDuration
                : 0;
              this.ruleForm.reserveDays = this.orderFrom.overstepHourPrice
                ? this.orderFrom.overstepHourPrice
                : 0;
              console.log(this.ruleForm.reserveDays);
            } else if (
              this.ruleForm.settlementType === "2" ||
              this.ruleForm.settlementType === "3"
            ) {
              this.ruleForm.roomPrice = "0";
              this.ruleForm.roomRealityPrice = "";
              this.ruleForm.discount = "100%";
              this.member = {
                number: "",
                memberGradeName: "",
                memberId: null,
              };
              this.ruleForm.housePriceId = "";
              this.ruleForm.housePriceName = "";
              this.userinfo[0].memberGradeName = "";
              this.userinfo[0].number = "";
              this.userinfo[0].memberId = "";
            }
          }
          if (num) {
            // if (index == 0) {
            //根据手机号码在订单表和预订单表中筛选arriveDate: this.HintDate
            this.getNowDate(new Date());
            this.orderFromLists({ mobile: num }, num);
            this.advanceOrderListed(
              { orderStatus: "0", mobile: num, arriveDate: this.HintDate },
              num
            );
            // }
          } else {
            this.userinfo[0].number = "";
            this.userinfo[0].memberGradeName = "";
            this.userinfo[0].memberId = "";
          }
        }
      }
      if (num) {
        // if (index == 0) {
        //根据手机号码在订单表和预订单表中筛选arriveDate: this.HintDate
        this.getNowDate(new Date());
        this.orderFromLists({ mobile: num }, num);
        this.advanceOrderListed(
          { orderStatus: "0", mobile: num, arriveDate: this.HintDate },
          num
        );
        // }
      }
    },
    areDelete() {
      this.isHint = false;
    },
    //房单分页列表查询
    orderFromLists(model = {}, num) {
      const params = {
        current: 1,
        size: 3,
        model: {
          orderState: 1,
          ...model,
        },
      };
      orderFromList(params).then((res) => {
        if (res.data.code === 0) {
          if (res.data.data.records.length != 0) {
            res.data.data.records.forEach((el) => {
              if (el.mobile == num) {
                this.isHint = true;
                this.orderList = `在住有同名客人${el.name}，房间号为: ${el.roomName}`;
              }
            });
          }
        } else {
          this.$message({
            type: "error",
            message: res.data.msg,
          });
        }
      });
    },
    //预订单分页列表请求
    advanceOrderListed(midels = {}, num) {
      const params = {
        current: 1,
        size: 5,
        model: {
          ...midels,
        },
      };
      advanceOrderList(params).then((res) => {
        if (res.data.code === 0) {
          if (res.data.data.records.length != 0) {
            res.data.data.records.forEach((el) => {
              if (el.mobile == num) {
                this.isHint = true;
                this.orderList = `今日抵达预定有同名客人:${
                  el.customerName
                }，房间号为: ${
                  el.houseNumber
                    ? el.houseNumber + "" + el.houseNumber
                    : "未排房"
                }`;
                console.log(this.orderList);
              }
            });
          }
        }
      });
    },
    //办理入账弹窗关闭出发事件
    handleClose(done) {
      done();
      this.$emit("offCheck", [false, 1,this.checkDataFrom]);
    },

    //预祝天数变化自动改变预离时间
    ChangereserveDays(data) {
      console.log('赋值天数',data);
      var days = new Date().getTime();

      data = parseFloat(data);
      days = parseFloat(data) * (1 * 24 * 60 * 60 * 1000) + days;
	  this.ruleForm.reserveDays = data + "天";
      // console.log(days, "111111111111111111");
      this.getNowDate(days, 2);

      // console.log(this.ruleForm.predepartureTime);
    },
	//钟点时长变化
	changeTime(){
		// 获取当前时间  
		var currentTime = new Date();
		// 增加3小时  
		currentTime.setHours(currentTime.getHours() + parseInt(this.ruleForm.hourLength) );
		var dateObj = new Date(currentTime);  
		  
		// 获取年、月、日、时、分、秒  
		var year = dateObj.getFullYear();  
		var month = dateObj.getMonth() + 1; // 月份是从0开始的，所以需要加1  
		var day = dateObj.getDate();  
		var hours = dateObj.getHours();  
		var minutes = dateObj.getMinutes();  
		var seconds = dateObj.getSeconds();  
		  
		// 格式化日期为指定格式  
		var formattedDate = year + "-" + this.pad(month, 2) + "-" + this.pad(day, 2) + " " + this.pad(hours, 2) + ":" + this.pad(minutes, 2) + ":" + this.pad(seconds, 2);
		// 输出结果  
		console.log("当前时间：" + currentTime);
		console.log("当前时间：" + formattedDate);
		this.ruleForm.predepartureTime=formattedDate;
	},
	pad(num, size) {  
	  var s = num+"";  
	  while (s.length < size) s = "0" + s;  
	  return s;  
	},
    //预离时间变动自动计算天数
    LeaveDate() {
      this.getDaysBetween(
        this.ruleForm.arrivalTime,
        this.ruleForm.predepartureTime
      );
    },
    //计算时间差
    //date1  开始日期 yyyy-MM-dd
    //date2  结束日期 yyyy-MM-dd
    //如果日期相同 返回一天 开始日期大于结束日期，返回0
    getDaysBetween(date1, date2) {
      if (
        this.ruleForm.settlementType === "0" ||
        this.ruleForm.settlementType === "2" ||
        this.ruleForm.settlementType === "3"
      ) {
        //全天
		// console.log('时间1',date1)
		// console.log('时间2',date2)
		date1 = date1.substring(0,10);
		date2 = date2.substring(0,10);
        var startDate = Date.parse(date1);
        var endDate = Date.parse(date2);

        var days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000);
        // console.log(Math.ceil(days));
        if (Math.ceil(days)) {
			if(Math.ceil(days)==1){
				return (this.ruleForm.reserveDays = 1 + "天");
			}else{
				return (this.ruleForm.reserveDays = (Math.ceil(days)) + "天");
			}
        } else {
          return (this.ruleForm.reserveDays = "1天");
        }
      } else if (this.ruleForm.settlementType === "1") {
        //钟点
        var startDate = Date.parse(date1);
        var endDate = Date.parse(date2);

        var days = (endDate - startDate) / (60 * 60 * 1000);
        if (Math.ceil(days)) {
          return (this.ruleForm.hourLength = Math.ceil(days) );
        } else {
          return (this.ruleForm.hourLength = 3);
        }
      }
    },
    //计算折扣率discount
    getDiscount() {
      if (
        parseFloat(
          (
            parseFloat(this.ruleForm.roomRealityPrice) /
            parseFloat(this.ruleForm.roomPrice)
          ).toFixed(2)
        )
      ) {
        this.ruleForm.discount =
          parseFloat(
            (
              parseFloat(this.ruleForm.roomRealityPrice) /
              parseFloat(this.ruleForm.roomPrice)
            ).toFixed(2)
          ) *
            100 +
          "%";
        let arr = this.ruleForm.discount.split("");
        let index = arr.indexOf(".") + 2;
        if (arr.length - index > 4) {
          arr.splice(arr.indexOf(".") + 3);
          arr = arr.join("") + "%";
          // console.log(arr);
          this.ruleForm.discount = arr;
        }
        console.log(arr, index);
        // console.log(arr.length - index > 2);
      } else {
        this.ruleForm.discount = "0%";
      }

      // console.log(this.ruleForm.discount);
    },
    //折扣率变动后实际房价变动str.substr(str.length - 1, 1)

    getRoomRealityPrice(num) {
      
      var arr = this.ruleForm.discount.split("");
      let index = arr.indexOf("%");
      if (arr.indexOf("%") != -1) {
        let str = parseFloat(this.ruleForm.discount.substr(0, arr.indexOf("%")));
        if(Number.isInteger(str)) {
          console.log(str);
        str = parseFloat(str) / 100;
        this.ruleForm.roomRealityPrice = parseFloat(
          str * parseFloat(this.ruleForm.roomPrice)
        );
        }else{
        this.$message({
          type: 'error',
          message: '折扣率请输入整数'
        })
        this.$set(this.ruleForm,'discount', parseInt(str) + '%')
        this.getRoomRealityPrice()
        }
      
      } else {
        let str = parseFloat(this.ruleForm.discount);
        if (parseFloat(str * parseFloat(this.ruleForm.roomPrice))) {
          this.ruleForm.roomRealityPrice = parseFloat(
            str * parseFloat(this.ruleForm.roomPrice)
          );
        } else {
          this.ruleForm.roomRealityPrice = 0;
        }
        this.getDiscount();
      }
      // console.log(index);
      // let str = this.ruleForm.discount.substr(0, 2)
      // console.log(str);
      // str = parseFloat(str)/100
      // this.ruleForm.roomRealityPrice = (str * parseFloat(this.ruleForm.roomPrice))+ '.00'
    },
    //确定入账的自定义事件
    AddenterOff() {
      this.isEnter = false;
      this.isCheckMain = false;
      this.$emit("offCheck", [false, 1,this.checkDataFrom]);
    },
    //再次确认的确定和取消事件
    addRooms(num) {
      if (num === 0) {
        this.isReconfirm = false;
        //  this.$emit('offCheck',[false])
      } else {
        this.AdddataStatisticsed();
      }
    },
    //住客信息的按钮事件
    SetSubject(num) {
      this.Subject_index = num;
      var that = this;
      if (num === 1) {
        //设置主客
        this.userinfo.forEach((el) => {
          el.isMaster = false;
        });

        this.userinfo[this.istableCar].isMaster = true;
        let arr = this.userinfo[this.istableCar];
        this.userinfo.splice(this.istableCar, 1);
        this.userinfo.unshift(arr);
        this.istableCar = 0;
        this.userinfo.forEach(function (item, index) {
          if (item.isMaster) {
            that.userInfoGeted(item.mobile, index);
          }
        });
        this.$forceUpdate();
      } else if (num === 2) {
        //新增客人
        var nums = null;
        this.userinfo.forEach((el) => {
          if (el.name && el.mobile && el.idCard) {
          } else {
            nums = 0;
          }
        });
        if (nums == 0) {
          this.$message("请先完整输入当前住客信息");
        } else {
          console.log(this.userinfo);
          this.isHostAnd = false;
          this.userinfo.push({
            birthday: null, //生日
            createdBy: localStorage.getItem("classesId"), //创建人ID
            gender: "0", //性别 0 男 1 女
            createdTime: this.ruleForm.arrivalTime, //创建时间
            idCard: null, //身份证号
            // memberId:0,//会员id
            isMaster: false, //是否是主客
            mobile: null, //	手机号码
            name: null, //顾客姓名
            idType: "1",
          });
          this.istableCar = this.userinfo.length - 1;
        }

        console.log(this.userInfo);
      } else if (num === 3) {
        //换房
        this.isRooms = true;
        this.$nextTick(() => {
		  console.log('记录',this.ruleForm)
          this.$refs.RoomBox.isRoom = 1;
        });
      }
    },
    DeteleAreUser(index) {
      // console.log('删除用户',index);
      this.userinfo.splice(index, 1);
      this.istableCar = 0;
    },
    //换房的自定义事件
    ROOMEMIT(row) {
      this.isRooms = false;
      if (row.id) {
        this.ruleForm = {
			...this.ruleForm,
          agreementUnitId: null, //协议单位Id
          arrivalTime: "", //抵店时间
          houseTypeName: row.echoMap.houseTypeId, //房型名称
          predepartureTime: "", //预离时间
          remark: "", //备注信息
          reserveDays: "1天", //预住天数
          roomId: row.id, //房间id
          roomPrice: row.housePrice, //门市价
          roomName: row.roomNumber,
          houseTypeId: row.houseTypeId, //房型id 后面点击卡片获取对应房型房号和价格的信息
        };
        this.orderFrom = row;
        this.$message({
          type: "success",
          message: "换房成功",
        });
      } else {
        this.$message({
          type: "error",
          message: "取消换房操作",
        });
      }
	  
	  var str = row.roomNumber + "-" + row.echoMap.houseTypeId;
	  this.orderFrom = row;
	  this.housetypeinfo = row;
	  this.outPrice = row.overstepHourPrice;
	  this.priceS = row.housePrice;
	  this.memberPrice = row.housePrice;
	  //房间id
	  this.currentHouseType = row.houseTypeId;
	  this.ruleForm.houseTypeName = row.echoMap.houseTypeId;
	  // this.ruleForm.roomId = row.id;
	  // this.ruleForm.roomPrice = row.housePrice;
	  // this.ruleForm.roomName = row.roomNumber;
	  this.getNowDate(new Date());
	  this.$emit("changetitle", str); 
      console.log(this.ruleForm);
    },
    //协议单位查看
    BargainingUnitAlls() {
      BargainingUnitAll().then((res) => {
        if (res.data.code === 0) {
          this.BargainingUnitList = res.data.data;
        } else {
          this.$message(res.data.errorMsg);
        }
      });
    },
    //点击客户标签切换用户信息
    dbCustomersTag(index) {
      this.istableCar = index;
      // this.ruleForm.name= this.userinfo[index].name
      // this.ruleForm.birthday = this.userinfo[index].birthday;
      //   this.ruleForm.gender = this.userinfo[index].gender;
      //   this.ruleForm.idCard = this.userinfo[index].idCard;
      //   this.ruleForm.mobile = this.userinfo[index].mobile;
      //   this.member.number = this.userinfo[index].number
      //   this.member.memberGradeName=this.userinfo[index].memberGradeName
    },

    //办理入住的新增接口
    AdddataStatisticsed() {
      var companions = [];
      var numq = 0;
      var obj = {};
      console.log(this.userinfo, "1312313212");
      this.userinfo.forEach((el) => {
        el.gender = el.gender == "1" ? "1" : "0";
      });
      if (this.userinfo.length != 0) {
        var arr = this.userinfo.filter((el, index) => {
          return el.isMaster === true;
        });
        var companionsed = this.userinfo.filter((el, index) => {
          return el.isMaster === false;
        });
        companionsed.forEach((its) => {
          its.gender = its.gender == "1" ? true : false;
        });
        arr.forEach((its) => {
          its.gender = its.gender == "1" ? true : false;
        });
        // console.log(arr);
        // console.log(companionsed, "111111112121212121");
        const params = {
          agreementUnitId: this.ruleForm.agreementUnitId, //协议单位Id
          agreementUnitName: this.ruleForm.agreementUnitName,
          arrivalTime: this.ruleForm.arrivalTime, //抵店时间
          birthday: arr[0].birthday, //生日
          memberId: this.userinfo[0].memberId, //会员idthis.member.memberId
          houseTypeName: this.orderFrom.echoMap.houseTypeId, //房型名称
          breakfast: parseFloat(this.ruleForm.breakfast), //早餐份数
          clientType: this.ruleForm.clientType, //客户类型 0散户 1会员 2协议
          discount: parseFloat(this.ruleForm.discount), //折扣率
          gender: arr[0].gender == "1" ? true : false, //性别 0 男 1 女
          hourLength: Number(this.ruleForm.hourLength), //钟点时长
          companions: [...companionsed],
          orderReserveId: null, //预订单id
          idCard: arr[0].idCard, //身份证号码
          idType: arr[0].idType,
          mobile: arr[0].mobile, //手机号码
          name: arr[0].name, //顾客姓名
		  guestMobile:arr[0].mobile, //手机号码
		  guestName:arr[0].name, //手机号码
          predepartureTime: this.ruleForm.predepartureTime, //预离时间
          remark: this.ruleForm.remark, //备注信息
          reserveDays: parseFloat(
            this.ruleForm.settlementType == "0"
              ? parseFloat(this.ruleForm.reserveDays)
              : parseFloat(this.ruleForm.reserveDays)
          ), //预住天数/超出钟点价格
          hourLength: parseFloat(
            this.ruleForm.settlementType == "1"
              ? parseFloat(this.ruleForm.hourLength)
              : null
          ), //钟点时长
          overstepHourPrice: parseFloat(this.ruleForm.overstepHourPrice), //超时价
          roomId: this.orderFrom.id, //房间id
          roomName: this.orderFrom.roomNumber,
          roomPrice: this.orderFrom.housePrice
            ? parseFloat(this.orderFrom.housePrice)
            : 0, //门市价
          housePriceId: this.ruleForm.housePriceId, //房价码ID
          housePriceName: this.ruleForm.housePriceName, //房价码Name
          houseTypeId: this.orderFrom.houseTypeId, //房型id 后面点击卡片获取对应房型房号和价格的信息
          roomRealityPrice: parseFloat(this.ruleForm.roomRealityPrice)
            ? parseFloat(this.ruleForm.roomRealityPrice)
            : 0, //实际房价
          settlementType: this.ruleForm.settlementType, //入驻类型 0全天房 1钟点房
          orgId: localStorage.getItem("orgid"),
		  otaName:this.ruleForm.otaName?this.ruleForm.otaName:'',
        };
        // console.log(params, "2222222222222222222222");
		console.log('提交数据',params)
		// return false;
        AdddataStatistics(params).then((res) => {
          if (res.data.code === 0) {
            // this.isCheckMain = false;
            this.isReconfirm = false;
            this.isEnter = true;
            // console.log(res.data.data);
            this.$message({
              type: "success",
              message: "办理入住成功",
            });
            //id是订单id   房间id   roomId
            this.checkDataFrom = res.data.data
            this.ruleForm.housePriceId = "";
            this.ruleForm.housePriceName = "";

            this.$store.commit("user/SetOrderId", res.data.data);
            this.$nextTick(() => {
              this.$store.commit("user/SetOrderId", res.data.data);
              this.$refs.AddPrice.createdButton();
              this.$refs.AddPrice.currentRoom = res.data.data;
            });
            // this.roomsuoupdateed();
          }
        });
      }
      //没有同行人员
      if (this.userinfo.length === 0) {
        const params = {
          agreementUnitId: this.ruleForm.agreementUnitId, //协议单位Id
          arrivalTime: this.ruleForm.arrivalTime, //抵店时间
          birthday: this.ruleForm.birthday, //生日
          breakfast: Number(this.ruleForm.breakfast), //早餐份数
          clientType: this.ruleForm.clientType, //客户类型 0散户 1会员 2协议
          discount: parseFloat(this.ruleForm.discount), //折扣率
          gender: this.ruleForm.gender, //性别 0 男 1 女
          roomName: this.orderFrom.roomNumber,
          memberId: this.member.memberId, //会员id
          houseTypeName: this.orderFrom.echoMap.houseTypeId, //房型名称
          hourLength: Number(this.ruleForm.hourLength), //钟点时长
          companions: [],
          orderReserveId: null, //预订单id
          idCard: this.ruleForm.idCard, //身份证号码
          idType: this.userinfo[0].idType,
          mobile: this.ruleForm.mobile, //手机号码
          name: this.ruleForm.name, //顾客姓名
          predepartureTime: this.ruleForm.predepartureTime, //预离时间
          remark: this.ruleForm.remark, //备注信息
          reserveDays: parseFloat(this.ruleForm.reserveDays), //预住天数
          roomId: this.orderFrom.id, //房间id
          roomPrice: this.orderFrom.housePrice
            ? parseFloat(this.orderFrom.housePrice)
            : 0, //门市价
          housePriceId: Number(this.ruleForm.housePriceId), //房价码ID
          houseTypeId: this.orderFrom.houseTypeId, //房型id 后面点击卡片获取对应房型房号和价格的信息
          roomRealityPrice: parseFloat(this.ruleForm.roomRealityPrice)
            ? parseFloat(this.ruleForm.roomRealityPrice)
            : 0, //实际房价
          settlementType: this.ruleForm.settlementType, //入驻类型 0全天房 1钟点房
          orgId: localStorage.getItem("orgid"),
        };
		console.log('提交数据',params)
		// return false;
        AdddataStatistics(params).then((res) => {
          if (res.data.code === 0) {
            // this.isCheckMain = false;
            this.isReconfirm = false;
            this.isEnter = true;
            console.log(res.data.data);
            this.$nextTick(() => {
              this.$store.commit("user/SetOrderId", res.data.data);
              this.$refs.AddPrice.createdButton();
            });
            this.roomsuoupdateed();
          }
        });
      }
    },
    //根据手机号查询数据
    userInfoGeted(num, index) {
      const params = {
        mobile: num,
      };
      userInfoGet(params)
        .then((res) => {
          if (res.data.code === 0) {
            if (index == 0) {
              console.log(res);
              this.member = {
                memberGradeName: res.data.data.memberGradeName,
                number: res.data.data.number,
                memberId: res.data.data.id,
              };
              //当前客户信息
              this.ruleForm.clientType = "1";
              this.userinfo[index].memberGradeName =
                this.member.memberGradeName;
              this.userinfo[index].number = this.member.number;
              this.userinfo[index].memberId = this.member.memberId;
              console.log(this.ruleForm.clientType);
              console.log(this.ruleForm.agreementUnitId);
              console.log(this.userinfo[index], "2131231");
              console.log(this.member, "222222");
              if (this.ruleForm.clientType != "2") {
                if (!this.ruleForm.agreementUnitId) {
                  if (this.userinfo[index].isMaster) {
                    if (this.ruleForm.agreementUnitId) {
                      // if(this.ruleForm.clientType != '3') {
                      //   this.ruleForm.clientType = "2";
                      // }
                      this.ruleForm.clientType = "2";
                    } else {
                      this.ruleForm.clientType = "1";
                    }
                    console.log("13131231");
                    //房价码
                    if (this.ruleForm.settlementType == "0") {
                      //全天房的房价码

                      if (
                        res.data.data.housePriceCodes &&
                        res.data.data.housePriceCodes.length > 0
                      ) {
                        res.data.data.housePriceCodes.forEach((el, index) => {
                          if (el.houseTypeId == this.orderFrom.houseTypeId) {
                            var currTime = new Date().getDay();
                            console.log(currTime);
                            if (currTime == 0 || currTime == 6) {
                              //是周牛周日
                              this.ruleForm.roomRealityPrice = el.weekendPrice; //暂时不考虑周末价格
                            } else {
                              this.ruleForm.roomRealityPrice = el.normalPrice; //暂时不考虑周末价格
                            }

                            this.ruleForm.housePriceName = el.name;
                            this.ruleForm.housePriceId = el.id;
                            this.getDiscount();
                            this.getDaysBetween(
                              this.ruleForm.arrivalTime,
                              this.ruleForm.predepartureTime
                            );
                          }
                        });
                      } else {
                        this.ruleForm.housePriceName = "";
                        this.ruleForm.housePriceId = "";
						if(this.chinkPrice){
							this.ruleForm.roomRealityPrice = this.chinkPrice
						}else{
							this.ruleForm.roomRealityPrice = this.orderFrom
                          .housePrice
                          ? this.orderFrom.housePrice
                          : 0;
						}
                        
                        this.getDiscount();
                        this.getNowDate(new Date());
                        this.getDaysBetween(
                          this.ruleForm.arrivalTime,
                          this.ruleForm.predepartureTime
                        );
                      }
                    } else if (this.ruleForm.settlementType == "1") {
                      //钟点房的房价码
                      if (
                        res.data.data.housePriceCodes &&
                        res.data.data.housePriceCodes.length > 0
                      ) {
                        res.data.data.housePriceCodes.forEach((el, index) => {
                          if (el.houseTypeId == this.orderFrom.houseTypeId) {
                            this.ruleForm.roomRealityPrice = el.hourlyPrice; //暂时不考虑周末价格
                            this.ruleForm.housePriceName = el.name;
                            this.ruleForm.housePriceId = el.id;
                            this.ruleForm.hourLength = parseInt(el.hourLength);
                            this.ruleForm.reserveDays = el.overtimePrice;
                            this.getNowDate(new Date(), 3, el.hourLength);
                            this.getDiscount();
                          }
                        });
                      } else if (
                        this.ruleForm.settlementType == "2" ||
                        this.ruleForm.settlementType == "3"
                      ) {
                        this.ruleForm.roomPrice = "0";
                        this.ruleForm.roomRealityPrice = "0";
                        this.ruleForm.reserveDays = this.orderFrom.overstepHourPrice;
                        this.ruleForm.hourLength = this.orderFrom.hourDuration
                        this.ruleForm.discount = "100%";
                        if (res.data.data.housePriceCodes) {
                          if (res.data.data.housePriceCodes.length > 0) {
                            res.data.data.housePriceCodes.forEach(
                              (el, index) => {
                                if (
                                  el.houseTypeId == this.orderFrom.houseTypeId
                                ) {
                                  this.ruleForm.housePriceName = el.name;
                                  this.ruleForm.housePriceId = el.id;
                                }
                              }
                            );
                          }
                        } else {
                          this.ruleForm.housePriceName = "";
                          this.ruleForm.housePriceId = "";
                          this.ruleForm.reserveDays = this.orderFrom.hourPrice;
                        }
                      }else{
                        this.ruleForm.reserveDays = this.orderFrom.overstepHourPrice;
                        this.ruleForm.hourLength = this.orderFrom.hourDuration
                      }
                    }
                  }
                }
              }
            }

            console.log(res, "sadasdasydaskjsdgha");
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.code) {
            // if (err.errorMsg == "该合员用户台被加人黑名单") {
            if (
              err.errorMsg != "会员信息不存在" ||
              err.msg != "会员信息不存在"
            ) {
            }
            this.member = {
              number: "",
              memberGradeName: "",
              memberId: null,
            };
            console.log("21312312321");
            this.userinfo[index].memberGradeName = undefined;
            this.userinfo[index].number = undefined;
            this.userinfo[index].memberId = undefined;
            if (!this.ruleForm.agreementUnitId) {
              if (this.ruleForm.settlementType == "0") {
                if (this.userinfo[index].isMaster) {
                  this.ruleForm.clientType = "0";
                  this.ruleForm.housePriceName = "";
                  this.ruleForm.housePriceId = "";
				  if( this.chinkPrice){
					  this.ruleForm.roomRealityPrice =  this.chinkPrice
				  }else{
					this.ruleForm.roomRealityPrice = this.orderFrom.housePrice
                    ? this.orderFrom.housePrice
                    : 0;  
				  }
                  
                  this.getNowDate(new Date());
                  this.getDiscount();
                }
              } else if (this.ruleForm.settlementType == "1") {
                //钟点房
                this.userinfo[index].memberGradeName = undefined;
                this.userinfo[index].number = undefined;
                this.userinfo[index].memberId = undefined;
                this.ruleForm.housePriceName = "";
                this.ruleForm.housePriceId = "";
                this.getNowDate(
                  new Date(),
                  3,
                  this.orderFrom.hourDuration ? this.orderFrom.hourDuration : 0
                );
              } else if (
                this.ruleForm.settlementType == "2" ||
                this.ruleForm.settlementType == "3"
              ) {
                this.ruleForm.roomPrice = "0";
                this.ruleForm.roomRealityPrice = "0";
                this.ruleForm.discount = "100%";
                this.ruleForm.housePriceName = "";
                this.ruleForm.housePriceId = "";
                this.userinfo[index].memberGradeName = undefined;
                this.userinfo[index].number = undefined;
                this.userinfo[index].memberId = undefined;
              }
            } else {
            }
          }
        });
    },

    rTime(date) {
      var json_date = new Date(date).toJSON();
      this.ruleForm.arrivalTime = new Date(
        new Date(json_date) + 8 * 3600 * 1000
      )
        .toISOString()
        .replace(/T/g, " ")
        .replace(/\.[\d]{3}Z/, "");
    },
    offButton() {
      this.$emit("offCheck", [false]);
    },
    offBox() {
      this.$emit("offCheck", [false]);
    },
    close_page(e) {
      console.log(e.target.className);
      console.log(e.target.nodeName);

      if (e.target.className === "el-icon-close") {
        this.$emit("offCheck", [false]);
      }
      // else if (e.target.innerText === "办理入住") {
      //   this.isReconfirm = true;
      // }
    },
    submitBox(formName) {
      console.log(this.userinfo);
      this.$refs.ruleForm.validate((valid) => {
        console.log(valid);
        if (valid) {
          var nums = 0;
          this.userinfo.forEach((el) => {
            if (el.mobile && el.name && el.idCard) {
            } else {
              nums = 1;
            }
          });
          if (nums != 1) {
            this.isReconfirm = true;
            this.$nextTick(() => {
              this.$refs.whetherBoxs.numsAdd = 1
            })
          } else {
            this.$message({
              type: "error",
              message: "请填写姓名、手机号、身份证信息",
            });
          }
        } else {
          this.$message({
            type: "error",
            message: "请按照正确格式填写信息",
          });
          return false;
        }
      });
    },
    //退房时间分页列表查询
    listCheckOutTimed() {
      const params = {
        current: 1,
        size: 100000,
        model: {
          orgId: localStorage.getItem("orgid"),
          state: true,
        },
      };
      listCheckOutTime(params).then((res) => {
        if (res.data.code === 0) {
          this.DatetableData = res.data.data.records;
          this.getNowDate(new Date());
        } else {
          this.$message.error("请求异常");
        }
      });
    },
	//客户类型切换
	clientTypeChange(){
		console.log('客户类型更改',this.ruleForm.clientType)
		this.ruleForm.otaName='';
	},
	//获取价格配置修正
	getPriceList(){
		//ruleForm.clientType
		var that = this;
		getHousePriceList({
			date:(this.ruleForm.arrivalTime||'').substring(0,10),
			size:parseInt(this.ruleForm.reserveDays),
		}).then((res) => {
			console.log('价格区间',res)
			if(res.data.code==0){
				var obj = res.data.data;
				var price = 0;
				for (let key in obj) {
				    // 过滤掉继承属性
				    if (obj.hasOwnProperty(key)) {
				        console.log(key);
						if(obj[key]&&obj[key].length){
							obj[key].forEach(function(value){
								if(value.houseType == that.currentHouseType){
									price = price + parseFloat(value.price);
								}
							});
						}
				    }
				}
				if(parseInt(that.ruleForm.reserveDays)){
					that.chinkPrice = price /parseInt(that.ruleForm.reserveDays);
					that.chinkPrice = parseFloat( that.chinkPrice.toFixed(2));
				}
				console.log('pingjunjiage',that.chinkPrice)
				console.log('房型',that.currentHouseType)
			}
			//全日房费
			if(this.ruleForm.settlementType === "0"){
				// this.ruleForm.roomPrice = "0";//市场价
				// this.ruleForm.roomRealityPrice = "0";//实际房价
				if(that.ruleForm.clientType=="0"){
					//散客
					if(that.chinkPrice){
						this.ruleForm.roomRealityPrice = that.chinkPrice;
					}
				}else if(that.ruleForm.clientType=="1"){
					//会员
					console.log('会员信息',this.userinfo)
					if(this.userinfo&&this.userinfo[0].mobile){
						const params = {
						  mobile: this.userinfo[0].mobile,
						};
						userInfoGet(params)
						  .then((info) => {
						    if (info.data.code === 0) {
						      
						        console.log(info);
						        this.member = {
						          memberGradeName: info.data.data.memberGradeName,
						          number: info.data.data.number,
						          memberId: info.data.data.id,
						        };
						        //当前客户信息
						       if (this.ruleForm.agreementUnitId) {
						         this.ruleForm.clientType = "2";
						       } else {
						         this.ruleForm.clientType = "1";
						       }
						        this.userinfo[0].memberGradeName =
						        this.member.memberGradeName;
						        this.userinfo[0].number = this.member.number;
						        this.userinfo[0].memberId = this.member.memberId;
						        if (this.ruleForm.clientType != "2") {
						          if (!this.ruleForm.agreementUnitId) {
						            if (this.userinfo[0].isMaster) {
						              console.log("13131231");
						              //房价码
						              if (this.ruleForm.settlementType == "0") {
						                //全天房的房价码
						
						                if (
						                  info.data.data.housePriceCodes &&
						                  info.data.data.housePriceCodes.length > 0
						                ) {
						                  info.data.data.housePriceCodes.forEach((el, index) => {
						                    if (el.houseTypeId == this.orderFrom.houseTypeId) {
						                      var currTime = new Date().getDay();
						                      console.log(currTime);
						                      if (currTime == 0 || currTime == 6) {
						                        //是周牛周日
						                        this.ruleForm.roomRealityPrice = el.weekendPrice; //暂时不考虑周末价格
						                      } else {
						                        this.ruleForm.roomRealityPrice = el.normalPrice; //暂时不考虑周末价格
						                      }
						
						                      this.ruleForm.housePriceName = el.name;
						                      this.ruleForm.housePriceId = el.id;
						                      this.getDiscount();
						                      this.getDaysBetween(
						                        this.ruleForm.arrivalTime,
						                        this.ruleForm.predepartureTime
						                      );
						                    }
						                  });
						                } else {
						                  this.ruleForm.housePriceName = "";
						                  this.ruleForm.housePriceId = "";
										  if(this.chinkPrice){
											this.ruleForm.roomRealityPrice = this.chinkPrice
										  }else{
											this.ruleForm.roomRealityPrice = this.orderFrom.housePrice? this.orderFrom.housePrice:0;
										  }
						                  
						                  this.getDiscount();
						                  this.getDaysBetween(
						                    this.ruleForm.arrivalTime,
						                    this.ruleForm.predepartureTime
						                  );
						                }
						              } else if (this.ruleForm.settlementType == "1") {
						                //钟点房的房价码
						                if (
						                  info.data.data.housePriceCodes &&
						                  info.data.data.housePriceCodes.length > 0
						                ) {
						                  info.data.data.housePriceCodes.forEach((el, index) => {
						                    if (el.houseTypeId == this.orderFrom.houseTypeId) {
						                      this.ruleForm.roomRealityPrice = el.hourlyPrice; //暂时不考虑周末价格
						                      this.ruleForm.housePriceName = el.name;
						                      this.ruleForm.housePriceId = el.id;
						                      this.ruleForm.hourLength = parseInt(el.hourLength);
						                      this.ruleForm.reserveDays = el.overtimePrice;
											  console.log('触发',this.ruleForm.reserveDays)
						                      this.getDiscount();
						                    }
						                  });
						                } else if (
						                  this.ruleForm.settlementType == "2" ||
						                  this.ruleForm.settlementType == "3"
						                ) {
						                  this.ruleForm.roomPrice = "0";
						                  this.ruleForm.roomRealityPrice = "0";
						                  this.ruleForm.reserveDays = this.orderFrom.overstepHourPrice;
										  console.log('触发1',this.ruleForm.reserveDays)
						                  this.ruleForm.hourLength = this.orderFrom.hourDuration
						                  this.ruleForm.discount = "100%";
						                  if (info.data.data.housePriceCodes) {
						                    if (info.data.data.housePriceCodes.length > 0) {
						                      info.data.data.housePriceCodes.forEach(
						                        (el, index) => {
						                          if (
						                            el.houseTypeId == this.orderFrom.houseTypeId
						                          ) {
						                            this.ruleForm.housePriceName = el.name;
						                            this.ruleForm.housePriceId = el.id;
						                          }
						                        }
						                      );
						                    }
						                  } else {
						                    this.ruleForm.housePriceName = "";
						                    this.ruleForm.housePriceId = "";
						                    this.ruleForm.reserveDays = this.orderFrom.hourPrice;
											console.log('触发2',this.ruleForm.reserveDays)
						                  }
						                }else{
						                  this.ruleForm.reserveDays = this.orderFrom.overstepHourPrice;
						                  this.ruleForm.hourLength = this.orderFrom.hourDuration
										  console.log('触发3',this.ruleForm.reserveDays)
						                }
						              }
						            }
						          }
						        }
						    }
						  })
						  .catch((err) => {
						    console.log(err);
						    if (err.code) {
						      // if (err.errorMsg == "该合员用户台被加人黑名单") {
						      if (
						        err.errorMsg != "会员信息不存在" ||
						        err.msg != "会员信息不存在"
						      ) {
						      }
						      this.member = {
						        number: "",
						        memberGradeName: "",
						        memberId: null,
						      };
						      console.log("21312312321");
						      this.userinfo[0].memberGradeName = undefined;
						      this.userinfo[0].number = undefined;
						      this.userinfo[0].memberId = undefined;
						      if (!this.ruleForm.agreementUnitId) {
						        if (this.ruleForm.settlementType == "0") {
						          if (this.userinfo[0].isMaster) {
						            this.ruleForm.clientType = "0";
						            this.ruleForm.housePriceName = "";
						            this.ruleForm.housePriceId = "";
										  if( this.chinkPrice){
											  this.ruleForm.roomRealityPrice =  this.chinkPrice
										  }else{
											this.ruleForm.roomRealityPrice = this.orderFrom.housePrice
						              ? this.orderFrom.housePrice
						              : 0;  
										  }
						            
						            this.getDiscount();
						          }
						        } else if (this.ruleForm.settlementType == "1") {
						          //钟点房
						          this.userinfo[0].memberGradeName = undefined;
						          this.userinfo[0].number = undefined;
						          this.userinfo[0].memberId = undefined;
						          this.ruleForm.housePriceName = "";
						          this.ruleForm.housePriceId = "";
						         
						        } else if (
						          this.ruleForm.settlementType == "2" ||
						          this.ruleForm.settlementType == "3"
						        ) {
						          this.ruleForm.roomPrice = "0";
						          this.ruleForm.roomRealityPrice = "0";
						          this.ruleForm.discount = "100%";
						          this.ruleForm.housePriceName = "";
						          this.ruleForm.housePriceId = "";
						          this.userinfo[0].memberGradeName = undefined;
						          this.userinfo[0].number = undefined;
						          this.userinfo[0].memberId = undefined;
						        }
						      } else {
						      }
						    }
						  });
					}
				}else if(that.ruleForm.clientType=="2"){
					//协议
					if (this.ruleForm.agreementUnitId) {
					  this.ruleForm.clientType = "2";
					  if (this.ruleForm.agreementUnitId) {
					    if (this.ruleForm.settlementType === "0") {
					      //全天房
					      this.ruleForm.clientType = "2";
					      var number = null;
					      this.BargainingUnitList.forEach((el) => {
					        if (id == el.id) {
					          this.ruleForm.housePriceName = el.housePriceCodeName;
					          this.ruleForm.agreementUnitName = el.name;
					        }
					      });
					      var currTime = new Date().getDay();
					      this.BargainingUnitList.forEach((el) => {
					        if (id == el.id) {
					          if (el.housePriceCodeList) {
					            if (el.housePriceCodeList.length > 0) {
					              el.housePriceCodeList.forEach((item) => {
					                if (item.houseTypeName == this.ruleForm.houseTypeName) {
					                  if (currTime == 0 || currTime == 6) {
					                    //是周牛周日
					                    this.ruleForm.roomRealityPrice = item.weekendPrice; //暂时不考虑周末价格
					                  } else {
					                    this.ruleForm.roomRealityPrice = item.normalPrice; //暂时不考虑周末价格
					                  }
					                  console.log(currTime);
					                  // this.ruleForm.roomRealityPrice = item.normalPrice;
					                  this.ruleForm.houseTypeId = item.houseTypeId;
					                  this.ruleForm.housePriceId = item.id;
					                  // this.ruleForm.roomRealityPrice =
					                  this.getDiscount();
					                }
					              });
					            }
					
					            // this.ruleForm.housePriceId =
					            console.log(this.ruleForm);
					          } else {
										if(this.chinkPrice){
											this.ruleForm.roomRealityPrice = this.chinkPrice;
										}else{
											this.ruleForm.roomRealityPrice = this.orderFrom.housePrice
					              ? this.orderFrom.housePrice
					              : 0;
										}
					            this.getDiscount();
					          }
					        }
					      });
					      this.getDaysBetween(
					        this.ruleForm.arrivalTime,
					        this.ruleForm.predepartureTime
					      );
					    } else if (this.ruleForm.settlementType === "1") {
					      //钟点房
					      this.ruleForm.clientType = "2";
					      this.BargainingUnitList.forEach((el) => {
					        if (id == el.id) {
					          this.ruleForm.housePriceName = el.housePriceCodeName;
					          this.ruleForm.agreementUnitName = el.name;
					        }
					      });
					      this.BargainingUnitList.forEach((el) => {
					        if (id == el.id) {
					          if (el.housePriceCodeList) {
					            if (el.housePriceCodeList.length > 0) {
					              el.housePriceCodeList.forEach((item) => {
					                if (item.houseTypeName == this.ruleForm.houseTypeName) {
					                  this.ruleForm.roomRealityPrice = item.hourlyPrice;
					                  this.ruleForm.houseTypeId = item.houseTypeId;
					                  this.ruleForm.reserveDays = item.overtimePrice;
									  console.log('触发4',this.ruleForm.reserveDays)
					                  this.ruleForm.hourLength = parseInt(item.hourLength);
					                  this.ruleForm.housePriceId = item.id;
					                  this.getDiscount();
					                  this.getNowDate(
					                    new Date(),
					                    3,
					                    this.ruleForm.hourLength
					                  );
					                }
					              });
					            }
					            // this.ruleForm.housePriceId =
					          } else {
					            this.ruleForm.roomRealityPrice = this.orderFrom.hourPrice
					              ? this.orderFrom.hourPrice
					              : 0;
					            this.ruleForm.reserveDays = this.orderFrom.overstepHourPrice
					              ? this.orderFrom.overstepHourPrice
					              : 0;
								  console.log('触发5',this.ruleForm.reserveDays)
					            this.ruleForm.hourLength = this.orderFrom.hourDuration
					              ? this.orderFrom.hourDuration
					              : 0;
					          }
					        }
					      });
					    } else if (
					      this.ruleForm.settlementType === "2" ||
					      this.ruleForm.settlementType === "3"
					    ) {
					      this.ruleForm.roomPrice = "0";
					      this.ruleForm.roomRealityPrice = "0";
					      this.ruleForm.discount = "100%";
					      console.log("iguygiugiujhk");
					      this.BargainingUnitList.forEach((el) => {
					        if (id == el.id) {
					          if (el.housePriceCodeList) {
					            if (el.housePriceCodeList.length > 0) {
					              el.housePriceCodeList.forEach((item) => {
					                if (item.houseTypeName == this.ruleForm.houseTypeName) {
					                  this.ruleForm.houseTypeId = item.houseTypeId;
					                  this.ruleForm.housePriceId = item.id;
					                  this.ruleForm.housePriceName = item.name;
					                }
					              });
					            } else {
					              this.ruleForm.housePriceId = "";
					              this.ruleForm.housePriceName = "";
					            }
					          } else {
					            this.ruleForm.housePriceId = "";
					            this.ruleForm.housePriceName = "";
					          }
					        } else {
					          this.ruleForm.housePriceId = "";
					          this.ruleForm.housePriceName = "";
					        }
					      });
					      console.log(this.ruleForm.housePriceName);
					    }
					  } else {
					    if (this.member.number) {
					      this.ruleForm.clientType = "1";
					    } else {
					      this.ruleForm.clientType = "0";
					    }
					  }
					} else {
					  this.ruleForm.agreementUnitId = "";
					  this.ruleForm.agreementUnitName = "";
					  if (this.userinfo[0].mobile) {
					    this.ruleForm.clientType = "0";
					    this.mobileButton(this.userinfo[0].mobile, 0);
					  } else {
					    this.ruleForm.clientType = "0";
					    if (this.ruleForm.settlementType === "0") {
					      //全天房、
					      if (this.ruleForm.clientType == "1") {
					        //客户类型是会员
					        this.mobileButton(this.userinfo[0].mobile, 0);
					      } else if (this.ruleForm.clientType == "0") {
					        this.getDaysBetween(
					          this.ruleForm.arrivalTime,
					          this.ruleForm.predepartureTime
					        );
					        this.getDiscount();
					      }
					
					      // if (this.ruleForm.agreementUnitId) {
					      //   this.agreementchange();
					      // } else if(this.userinfo[0].mobile) {
					
					      // }
					    } else if (this.ruleForm.settlementType === "1") {
					      //钟点房
					      if (this.ruleForm.clientType == "1") {
					        //客户类型是会员
					        this.mobileButton(this.userinfo[0].mobile, 0);
					      } else if (this.ruleForm.clientType == "0") {
					        this.ruleForm.roomPrice = this.orderFrom.hourPrice
					          ? this.orderFrom.hourPrice
					          : 0;
					        this.ruleForm.roomRealityPrice = this.orderFrom.hourPrice
					          ? this.orderFrom.hourPrice
					          : 0;
					        this.ruleForm.hourLength = this.orderFrom.hourDuration
					          ? this.orderFrom.hourDuration
					          : 0;
					        this.ruleForm.reserveDays = this.orderFrom.overstepHourPrice
					          ? this.orderFrom.overstepHourPrice
					          : 0;
					        this.getDiscount();
					      }
					      // this.outPrice = this.orderFrom.overstepHourPrice
					      // console.log(this.ruleForm.overstepHourPrice);
					
					      // if (this.ruleForm.agreementUnitId) {
					
					      // }
					      // this.mobileButton(userinfo[0].mobile, 0)
					    } else if (
					      this.ruleForm.settlementType === "2" ||
					      this.ruleForm.settlementType === "3"
					    ) {
					      this.ruleForm.roomPrice = "0";
					      this.ruleForm.roomRealityPrice = "0";
					      this.ruleForm.discount = "100%";
					      this.ruleForm.housePriceId = "";
					      this.ruleForm.housePriceName = "";
					    }
					  }
					}
				}else if(that.ruleForm.clientType=="3"){
					//OTA
					if(that.chinkPrice){
						this.ruleForm.roomRealityPrice = that.chinkPrice;
					}
				}
			}
		});
	},
  },
};
</script>

<style lang="scss" scoped>
.searchIdcard {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 3%;
}
.demo-ruleForm {
  padding-left: 20px;
  box-sizing: border-box;
  .el-form-item {
    margin-bottom: 10px;
    ::v-deep .el-input__inner {
      height: 30px;
      margin-bottom: -10px;
    }
  }
}
.roomBox {
  ::v-deep .el-dialog__header {
    // background-color: #e8fdfe;
  }

  ::v-deep .el-dialog__body {
    // background: linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%);
  }
}
// .CheckIn {
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: rgba(133, 106, 106, 0.4);
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   z-index: 100;
//办理去住弹窗css
.check_main {
  width: 100%;
  // height: 630px;
  // overflow-y: auto;
  overflow-x: hidden;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  .CheckIn_color {
    width: 100%;
    // background: linear-gradient(177deg, #e8fdfe 0%, #ffffff 100%);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // 标题部分css
    .order_title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 50px;
      border-bottom: 1px solid #ededed;

      .title_icon {
        width: 50px;
        height: 50px;
        text-align: center;
        line-height: 50px;
      }

      p {
        color: black;
        flex: 1;
        text-align: left;
        padding-left: 30px;
      }
    }
    //住客信息表单css
    .residentMessage {
      width: 95%;
      // height: 230px;
      padding-bottom: 20px;
      border-radius: 8px;
      border: 1px solid #ededed;

      //标题css
      .resident_title {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 20px;
        padding-right: 15px;

        box-sizing: border-box;
        p {
          display: flex;
          align-items: center;
          span {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #5cb5b8;
            margin-right: 5px;
          }
        }
        .resident_button {
          padding-top: 20px;
          padding-right: 10px;
          box-sizing: border-box;
          .el-button {
            width: 100px;
            height: 35px;
            line-height: 35px;
            padding: 0px 20px;
            box-sizing: border-box;
            background: #ffffff;
            border: 1px solid #ededed;
            border-radius: 4px;
            color: #999999;
          }
          .el-button.active {
            background-color: #5cb5b8;
            color: #fff;
          }
        }
      }
      //用户标签css
      .resident_label {
        width: 100%;
        height: 60px;

        ul {
          flex: 1;
          height: 60px;
          display: flex;
          overflow-y: auto;
          padding-left: 10px;
          box-sizing: border-box;
          align-items: center;
          li {
            width: 90px;
            height: 35px;
            text-align: center;
            line-height: 35px;
            border-radius: 4px;
            flex-shrink: 0;
            // box-sizing: border-box;
            background-color: #f4f4f4;
            color: #666666;
            margin-right: 20px;
            cursor: pointer;
            position: relative;

            span {
              position: relative;

              img {
                display: none;
              }
              img.active {
                display: block;
                position: absolute;
                top: 2%;
                left: -19px;
              }
              .el-icon-close {
                display: none;
                position: absolute;
                color: #fff;
                top: 2%;
                right: -19px;
                font-size: 15px;
              }
              .el-icon-close.active {
                display: block;
              }
            }
          }
          li:first-child {
            margin-left: 12px;
          }
          li.active {
            padding: 0 0px 0 16px;
            background-color: #5cb5b8;
            color: #fff;
          }
          li.starLI {
            padding: 0 15px 0 16px;
            background-color: #5cb5b8;
            color: #fff;
          }
        }
      }
      //用户信息表单css
    }
  }

  //入住信息表单css
  .checkMessage {
    width: 95%;
    margin-top: 20px;
    border-radius: 8px;
    box-sizing: border-box;
    border: 1px solid #ededed;
  }
  //标题css
  .checkTitle {
    width: 100%;
    height: 50px;
    line-height: 50px;
    padding-left: 20px;
    box-sizing: border-box;
    margin-bottom: 10px;
    border-bottom: 1px solid #ededed;
    p {
      display: flex;
      align-items: center;
      span {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #5cb5b8;
        margin-right: 5px;
      }
    }
  }

  //表单css
  .checkMessage_main {
    padding-bottom: 10px;
    box-sizing: border-box;
    height: 270px;
    width: 100%;
    overflow: hidden;
    // overflow-y: auto;
    //备注框css
    .remark {
      width: 100%;
      padding-right: 10px;
      padding-left: 30px;
      padding-bottom: 20px;
      box-sizing: border-box;
      display: flex;

      .remark_title {
        width: 80px;
        height: 35px;
        font-size: 15px;
        box-sizing: border-box;
      }
      .remark_main {
        flex: 1;
        height: 80px;
        border: 1px solid #ededed;
        border-radius: 10px;
        color: gray;
        span {
          display: block;
          margin-top: 10px;
          margin-left: 20px;
        }
        input {
          width: 99%;
          height: 30px;
          border: none;
        }
      }
      button {
        color: #5cb5b8;
        width: 80px;
        height: 25px;
        margin-top: 10px;
        border: 1px dotted #5cb5b8;
        background-color: #fff;
      }
    }
  }

  .submit_button {
    width: 100%;
    height: 60px;
    line-height: 60px;
    // display: flex;
    // align-items: center;
    padding-right: 50px;
    box-sizing: border-box;
    // justify-content: end;
    text-align: right;
    // border-top: 1px solid rgb(248, 241, 241);
    .el-button {
      width: 100px;
      height: 35px;
      padding: 0px 20px;
      line-height: 35px;
      border-radius: 4px;
    }
  }
}
.reconfirm_Box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(133, 106, 106, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  //再次确认弹窗
  .reconfirm {
    width: 500px;
    height: 180px;
    background-color: #fff;
    .save_size {
      width: 100%;
      height: 126px;
      // background: linear-gradient(177deg, #e8fdfe 0%, #ffffff 100%);
      border-radius: 5px;
      padding-top: 30px;
      box-sizing: border-box;

      p {
        height: 40px;
        padding-left: 45px;
        box-sizing: border-box;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;

        img {
          width: 15px;
          height: 15px;
        }
      }
      .size_floot {
        padding-left: 80px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #555555;
      }
    }
    .save_button {
      display: flex;
      justify-content: end;
      padding-right: 40px;
      box-sizing: border-box;
      .el-button {
        margin-left: 20px;
        width: 100px;
      }
    }
  }
}
// }
//弹窗的阴影盒子
.savePop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(133, 106, 106, 0.4);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  //阴影里的 删除盒子css
  .save_main {
    width: 500px;
    height: 180px;
    background: linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%);
    .save_size {
      width: 100%;
      height: 126px;
      background: linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%);
      border-radius: 5px;
      padding-top: 30px;
      box-sizing: border-box;

      p {
        height: 40px;
        padding-left: 45px;
        box-sizing: border-box;
        font-size: 16px;
        font-family: Microsoft YaHei;
        background: linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%);
        font-weight: 400;
        color: #333333;

        img {
          width: 15px;
          height: 15px;
        }
      }
      .size_floot {
        padding-left: 43px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #555555;
      }
    }
    .save_button {
      // display: flex;
      // justify-content: end;
      text-align: right;
      padding-right: 20px;
      box-sizing: border-box;
      .el-button {
        margin-left: 20px;
        width: 100px;
        margin-bottom: 10px;
      }
    }
  }
}
</style>