import { render, staticRenderFns } from "./UpdateMember.vue?vue&type=template&id=31a09c04&scoped=true"
import script from "./UpdateMember.vue?vue&type=script&lang=js"
export * from "./UpdateMember.vue?vue&type=script&lang=js"
import style0 from "./UpdateMember.vue?vue&type=style&index=0&id=31a09c04&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31a09c04",
  null
  
)

export default component.exports