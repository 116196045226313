<template>
  <!-- 编辑会员信息 -->
  <div class="UpdateMember">
    <!-- 选择的会员等级和用户信息 -->
    <div class="membership_message">
      <!-- 警告提示 -->
      <p>
        <img src="../../../assets/icons/警告 (1).png" alt="">
        会员手机号是识别会员身份的唯一信息，请认真填写。
      </p>
      <!-- 警告提示 -->
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-row>
          <el-col :span="11">
            <el-form-item :disabled="true" label="会员等级"  style="position: relative;">
              <el-select @change="memberGradeIdButton(ruleForm.memberGradeName)"
              style="width: 100%;"
              :disabled="true"
              v-model="ruleForm.memberGradeName"
                placeholder="请选择会员等级">
                <el-option v-for="item in membershipFrom" :key="item.id" :label="item.memberGradeName"
                  :value="item.memberGradeName"></el-option>

              </el-select>
            </el-form-item>
            <span class="memberCardBox" style="">请选择要开卡的会员等级</span>
          </el-col>

          <el-col :span="11">

            <!-- <el-form-item label="是否赠送" prop="isGift" >
              <el-select
              :disabled="true"
              v-model="ruleForm.isGift" 
              style="position: relative;width: 100%;"
              @change="whetherOnff(ruleForm.isGift)" placeholder="是否赠送会员">
                <el-option label="是" value="0"></el-option>
                <el-option label="否" value="1"></el-option>

              </el-select>

            </el-form-item>
            <span
            class="stateGive"
              style="">是否赠送会员，赠送会员不收取开卡费用</span> -->
              <el-form-item label="顾客姓名" prop="name" >
              <el-input v-model="ruleForm.name"
              style="position: relative;width: 100%;" >

              </el-input>
              <!-- <img src="../../../assets/icons/24gl-printer.png" alt=""
                style="width:22px; height: 22px; position: absolute; top:10px;right:3px;"> -->
            </el-form-item>
          </el-col>
          <el-col :span="11">
         
          </el-col>

        </el-row>


        <el-row>
          <el-col :span="11">
            <el-form-item label="手机号码" prop="mobile">
              <el-input v-model="ruleForm.mobile" style="width: 100%;"></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="11">
            <el-form-item label="会员性别" prop="gender">
              <el-select v-model="ruleForm.gender" placeholder="请选择" style="width: 100%;">
                <el-option label="男" value="0"></el-option>
                <el-option label="女" value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
		  
		  <el-col :span="11">
		    <el-form-item label="身份证号码" prop="idCard">
		      <el-input v-model="ruleForm.idCard" :disabled="true" style="width: 100%;"></el-input>
		    </el-form-item>
		  </el-col>
        </el-row>
		<div style="width: 100%;text-align: center;display: flex;align-items: center;justify-content: center;">
			<el-button
			@click="UpdateButton"
			style="background-color: #5CB5B8; color: #fff;">
			  保存
			</el-button>
		</div>
        <!-- <el-row>
         
          
          <el-col :span="8" :offset="8"  class="subMitboss">
            <el-form-item style="width: 100%;text-align: center;display: flex;align-items: center;justify-content: center;">
              <el-button
              @click="UpdateButton"
              style="background-color: #5CB5B8; color: #fff;">
                保存
              </el-button>
            </el-form-item>
          </el-col>
        </el-row> -->
        <!-- <el-row>
        
            <el-form-item label="住址" prop="address">
              <el-input v-model="ruleForm.address" placeholder="请输入地址" style="width: 91%;"></el-input>
            </el-form-item>
       

        </el-row> -->
        <!-- <el-row style="text-align: center;"> 
          <el-form-item style="width: 100%;text-align: center;">
              <el-button
              @click="UpdateButton"
              style="margin-right: 90px;background-color: #5CB5B8; color: #fff;width: 100px;height: 40px;">
                保存
              </el-button>
            </el-form-item>
        </el-row> -->
      </el-form>
    </div>
    <!-- 选择的会员等级和用户信息 -->
  </div>
</template>

<script>
import {UpadateMember, LevelOfMembershipAll} from '_api/member'
export default {
  name: "UpdateMember",
  data() {
    return {
      ruleForm: {
        memberGradeName: null, //会员等级名称integer(int64)
        memberGradeId: '',//会员id
        activateTime: '',//开卡日期string(date-time)
        address: null,//住址
        // creditCardAmount: '',//开卡金额number
        // customerId: '1',//客户id
        gender: null,//性别
        idCard: null,//身份证
        isGift: null,//是否赠予 0是 1否
        mobile: null,//手机号
        name: null,//用户姓名
          
      },
      rules: {},
      membershipFrom: [],//会员等级的数据
    }
  },
  created() {
this.LevelOfMembershipAlled()
console.log(this.$store.state.base.MemberData);
  },
  methods: {
    //点击保存修改会员信息
    UpdateButton() {
      const params = {
        gender: this.ruleForm.gender == '0'?false: true,//性别
        id: this.$store.state.base.MemberData.id,
        idCard: this.ruleForm.idCard,//身份证
        isGift: Number(this.ruleForm.isGift) ,//赠送会员
        memberGradeId: this.ruleForm.memberGradeId,//会员等级id
        memberGradeName: this.ruleForm.memberGradeName,//会员等级名称
        memberId: this.$store.state.base.MemberData.memberId,//会员id
        mobile: this.ruleForm.mobile,//手机号
        name: this.ruleForm.name,//会员姓名
        address: this.ruleForm.address,//住址
      }
      UpadateMember(params).then(res => {
        if(res.data.code === 0) {
          this.$message({
            type: 'success',
            message: '会员信息修改成功'
          })
          this.$emit('updataMember', false)
        }else{
          this.$message(res.data.msg)
        }
      })
    },
      //查看所有的会员等级
      LevelOfMembershipAlled() {
                console.log(this.ruleForm.activateTime);
                LevelOfMembershipAll({tenantId: localStorage.getItem('TenantId'), orgId: localStorage.getItem('orgid')}).then(res => {
                    if(res.data.code === 0){
                       this.membershipFrom = res.data.data
                       this.membershipFrom.forEach(el => {
                          
                       })
                    }
                }) 
            },
    //根据会员名称获取会员id
    memberGradeIdButton(el) {
            
            this.membershipFrom.forEach(item => {
                if (item.memberGradeName == el) {
                    console.log(item);
                    this.ruleForm.memberGradeId = item.id
                }
            })



        },     
  }
};
</script>

<style lang="scss" scoped>
.subMitboss.el-col{
display: flex;
justify-content: end;
.el-form-item{
  .el-button{
    width: 100px;
    height: 35px;
    padding: 0px 0px;
  }
}
}
.memberCardBox{
  font-size: 13px;
  color: #B2B1B1;
  position: absolute;
  top:45px;
  left: 16%;
}
.stateGive{
  font-size: 13px;
  color: #B2B1B1;
  position: absolute;
  top:45px;
  right: 4%;
}
.UpdateMember {
  width: 100%;
  max-height: 350px;
  margin-top: -60px;
  // background: linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%);
  .membership_message{
    width: 100%;
    max-height: 320px;
    margin-top: 20px;
    display: flex;
    border-radius: 8px;
    flex-direction: column;
    align-items: center;
    //警告提示语css
   p{
    width: 90%;
    height: 40px;
    line-height: 40px;
    display: flex;
    border-radius: 8px;
    margin-top: 10px;
    align-items: center;
    color: #F4A509;
    margin-bottom: 20px;
    font-size: 14px;
    box-sizing: border-box;
    img{
        width: 18px;
        height: 16px;
        margin-right: 5px;
    }
   }
   .demo-ruleForm{
    width: 100%;
   
   }
}
}
</style>