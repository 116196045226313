import request from '_utils/request'
import qs from 'qs'
//商品分页列表
const shopMerchList = (params = {}) => (
    request.post ('/base/shopMerch/findPage', params)
)
//商品添加
const shopMerchAdd = (params = {}) => (
    request.post ('/base/shopMerch/add', params)
)
//修改商品信息
const shopMerchUpdate = (params = {}) => (
    request.post ('/base/shopMerch/update', params)
)
//根据id查看商品详情
const shopMerchdetails = (params = {}) => (
    request.get('/base/shopMerch/details/'+  params.id
    )
)
//根据id删除商品信息
const shopMerchDetele = (params = {}) => {
  return  request.delete('/base/shopMerch/del/'+  params.id,
	  {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	  }
    )
}
   

//分类汇总
const shopMerchsubtotals = () => (
    request.get('/base/shopMerchType/subtotals/shopMerch'
    )
)
//获取所有商品分类
const shopMerchName = (params = {}) => (
    request.post ('/base/shopMerchType/list', params)
)
export{
    shopMerchDetele,
    shopMerchdetails,
    shopMerchAdd,
    shopMerchList,
    shopMerchUpdate,
    shopMerchsubtotals,
    shopMerchName
}