<template>
  <!-- 预授权 -->
  <div class="PreAuthorization">
    <div class="PreAuthorization_main">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <el-form-item label="房间信息" prop="roominfo">
          <el-table
            border
            class="ruleForm_From"
            :data="tableData"
            style="width: 100%"
          >
            <el-table-column
              prop="id"
              label="入住单号"
              align="center"
              width="110"
            >
            </el-table-column>
            <el-table-column
              prop="name"
              label="住客姓名"
              align="center"
              width="110"
            >
            </el-table-column>
            <el-table-column
              prop="consumptionAmount"
              label="消费合计"
              align="center"
              width="110"
            >
            <template slot-scope="scope">
              <span>
                {{ parseFloat(scope.row.consumptionAmount).toFixed(2) }}
              </span>
            </template>
            </el-table-column>
            <el-table-column
              prop="paymentAmount"
              label="付款合计"
              align="center"
              width="110"
            >
            <template slot-scope="scope">
              <span>
                {{ parseFloat(scope.row.paymentAmount).toFixed(2) }}
              </span>
            </template>
            </el-table-column>
            <el-table-column
              prop="totalPreGrant"
              label="预授合计"
              align="center"
            >
            <template slot-scope="scope">
              <span>
                {{ parseFloat(scope.row.totalPreGrant).toFixed(2) }}
              </span>
            </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>

      <el-form
        :model="ruleForm"
        :rules="rules"
        label-width="100px"
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <el-row>
          <el-col :span="15">
            <el-form-item label="银行卡号">
              <el-input clearable v-model="ruleForm.bankNumber"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item label="授权码">
              <el-input
                clearable
                v-model="ruleForm.authorizationCode"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="预授金额" prop="price">
          <el-input clearable v-model="ruleForm.price"></el-input>
        </el-form-item>
        <el-form-item label="凭证号">
          <el-input
            clearable
            style="position: relative"
            v-model="ruleForm.voucherNo"
          ></el-input>
          <!-- <i
            class="el-icon-close"
            style="
              font-size: 20px;
              position: absolute;
              color: gray;
              right: 5px;
              top: 10px;
            "
          ></i> -->
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            clearable
            v-model="ruleForm.remarks"
          ></el-input>
          <!-- <i
            class="el-icon-close"
            style="
              font-size: 20px;
              position: absolute;
              color: gray;
              right: 5px;
              top: 10px;
            "
          ></i> -->
        </el-form-item>
        <el-form-item class="form_item">
          <el-button @click="submitForm('ruleForm')"> 确认 </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { AddFinance, paymentList } from "_api/orderFrom";
export default {
  name: "PreAuthorization",
  props: {},
  data() {
    return {
      ruleForm: {
        voucherNo: "",
        price: "",
        orderId: "",
        projectName: null,
        paymentId: null,
        authorizationCode: "",
        bankNumber: "",
        remarks: '',
      },
      tableData: [],
      rules: {
        roominfo: [
          { required: true, message: "请输入银行卡", trigger: "blur" },
        ],
        bankNumber: [
          { required: true, message: "请输入银行卡", trigger: "blur" },
          {
            pattern: /^[0-9]{9,18}$/,
            message: "请输入正确银行卡号",
            trigger: "blur",
          },
        ],
        authorizationCode: [
          { required: true, message: "请输入授权码", trigger: "blur" },
        ],
        price: [
          { required: true, message: "请输入实际价格", trigger: "blur" },
          {
            pattern: /^(([0-9]|([1-9][0-9]{0,9}))((\.[0-9]{1,2})?))$/,
            message: "整数位最多十位，小数为最多为两位，可以无小数位",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.paymentListed();
  },
  methods: {
    //付款方式的查看
    paymentListed() {
      paymentList().then((res) => {
        if (res.data.code === 0) {
          res.data.data.forEach((el) => {
            if (el.name == "银行卡") {
              this.ruleForm.projectName = el.name;
              this.ruleForm.paymentId = el.id;
            }
          });
          if (!this.ruleForm.projectName) {
            this.ruleForm.projectName = "入账项目没有银行卡，请添加名为银行卡的付款方式";
            this.$message({
              type: "error",
              message: "入账项目没有银行卡，请添加名为银行卡的付款方式",
              onClose: () => {
                this.$emit("offPre", [false]);
              },
            });
          }
        } else {
          this.$message({
            type: "error",
            message: "请求异常",
            onClose: () => {},
          });
        }
      });
    },

    //财务管理表之新增接口
    AddFinanceed() {
      const params = {
        voucherNo: this.ruleForm.voucherNo,
        price: Number(this.ruleForm.price),
        orderId: this.tableData[0].id,
        authorizationCode: this.ruleForm.authorizationCode,
        bankNumber: this.ruleForm.bankNumber,
        userClassesId: localStorage.getItem("userClassesId"),
        itemType: "0",
        preAuth: true,
        state: true,
        projectName: this.ruleForm.projectName,
        paymentId: this.ruleForm.paymentId,
        operator: localStorage.getItem("name"),
        roomId: this.tableData[0].roomId,
        roomName: this.tableData[0].roomName,
        type: this.tableData[0].type,
        classes: localStorage.getItem("classes"),
        classesId: localStorage.getItem("classesId"),
        createdName: localStorage.getItem("name"),
        remarks: this.ruleForm.remarks,
      };
      AddFinance(params).then((res) => {
        if (res.data.code === 0) {
          (this.ruleForm = {
            voucherNo: null,
            price: null,
            orderId: null,
            authorizationCode: null,
            bankNumber: null,
          }),
            this.$emit("offPre", [false]);
          this.$message({
            type: "success",
            message: "预授权成功",
          });
        }
      });
    },

    //点击右上角图标关闭弹窗
    offOperator() {
      this.$emit("offPre", [false, 2]);
      this.ruleForm = {
        voucherNo: null,
        price: null,
        orderId: null,
        authorizationCode: null,
        bankNumber: null,
      };
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.AddFinanceed();
        } else {
          this.$message({
            type: "error",
            message: "请输入完整信息",
          });
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.PreAuthorization {
  width: 100%;
  // height: 420px;
  background-color: #fff;
  .PreAuthorization_main {
    width: 100%;

    box-sizing: border-box;
    padding: 0 20px 0px 20px;
    // background: linear-gradient(177deg, #e8fdfe 0%, #ffffff 100%);
    border-radius: 5px;

    .demo-ruleForm {
      .ruleForm_From {
        border-radius: 8px;
      }
      .form_item {
        width: 100%;
        text-align: center;
        padding-right: 20px;
        box-sizing: border-box;

        .el-button {
          width: 150px;
          height: 40px;
          background-color: #5cb5b8;
          color: #fff;
          border: none;
        }
      }
    }
  }
}
</style>