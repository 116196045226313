<template>
  <!-- 会员开卡页面 -->
  <div class="MembershipCard" :style="{'margin-top':addNumss == 1? '-30px':addNumss == 2?'-70px':'-30px',}">
    <div class="member_main">
      <!-- 选择的会员等级和用户信息 -->
      <div class="membership_message">
        <!-- 警告提示 -->
        <p>
          <img src="../assets/icons/警告 (1).png" alt="" />
          会员手机号是识别会员身份的唯一信息，请认真填写。
        </p>
        <!-- 警告提示 -->
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForms"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-row>
            <el-col :span="11">
              <el-form-item
                label="会员等级"
                prop="memberGradeId"
                style="position: relative; margin-bottom: 40px"
              >
                <el-select
                clearable
                  @change="memberGradeIdButton(ruleForm.memberGradeId)"
                  style="width: 100%"
                  v-model="ruleForm.memberGradeId"
                  placeholder="请选择会员等级"
                >
                  <el-option
                    v-for="item in membershipFrom"
                    :key="item.id"
                    :label="item.memberGradeName"
                    :value="item.id"
                  ></el-option>
                </el-select>
                <span
                  style="
                    font-size: 13px;
                    color: #b2b1b1;
                    position: absolute;
                    top: 40px;
                    left: 10px;
                  "
                  >请选择要开卡的会员等级</span
                >
              </el-form-item>
            </el-col>

            <el-col :span="11">
              <el-form-item
                label="是否赠送"
                prop="isGift"
                style="position: relative; margin-bottom: 40px"
              >
                <el-select
                clearable
                  v-model="ruleForm.isGift"
                  style="width: 100%"
                  @change="whetherOnff(ruleForm.isGift)"
                  placeholder="是否赠送"
                >
                  <el-option label="是" value="0"></el-option>
                  <el-option label="否" value="1"></el-option>
                </el-select>
                <span
                  style="
                    font-size: 13px;
                    color: #b2b1b1;
                    position: absolute;
                    top: 40px;
                    left: -10%;
                  "
                  >是否赠送会员，赠送会员不收取开卡费用</span
                >
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="11">
              <el-form-item label="顾客姓名" prop="name">
                <el-input
                clearable
                placeholder="请输入姓名"
                  v-model="ruleForm.name"
                  style="position: relative; width: 100%"
                >
                </el-input>
                <img
                  @click="cardButton"
                  src="../assets/icons/24gl-printer.png"
                  class="SearchButton"
                  alt=""
                  style=""
                />
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="手机号码" prop="mobile">
                <el-input
                clearable
                placeholder="请输入手机号码"
                  v-model="ruleForm.mobile"
                  style="width: 100%"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item label="会员性别" prop="gender">
                <el-select
                clearable
                  v-model="ruleForm.gender"
                  placeholder="请选择性别"
                  style="width: 100%"
                >
                  <el-option label="男" value="0"></el-option>
                  <el-option label="女" value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="身份证号码" prop="idCard">
                <el-input
                clearable
                placeholder="请填写身份证号"
                  v-model="ruleForm.idCard"
                  style="width: 100%"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-form-item label="住址" prop="address">
              <el-input
              clearable
                v-model="ruleForm.address"
                placeholder="请输入地址"
                style="width: 90%"
              ></el-input>
            </el-form-item>
          </el-row>
        </el-form>
      </div>
      <!-- 选择的会员等级和用户信息 -->
    </div>
    <!-- 根据开的卡片，付款 -->
    <div class="membership_payment">
      <p>
        当前选择的是“{{
          ruleForm.memberGradeName
            ? ruleForm.memberGradeName
            : "请选择会员名称"
        }}”会员，开卡费用“{{
          ruleForm.creditCardAmount ? ruleForm.creditCardAmount : 0
        }}”元， {{ ruleForm.isGift == "0" ? "赠送会员,不收取开卡费用" : '请选择收款方式' }}
        <!-- 赠送会员，请选择收款方式。 -->
      </p>
      <el-form
        :inline="true"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        style="position: relative;"
        class="demo-ruleForm"
      >
        <el-form-item
          label="开卡金额"
          prop="creditCardAmount"
          class="priceInput"
        >
          <el-input
          clearable
          style="width: 150px;position: relative;"
            :disabled="true"
            v-model="ruleForm.creditCardAmount"
          >
        </el-input>
       
        </el-form-item>

        <el-form-item
          label="收款方式"
          prop="paymentMethodId"
          class="paymentInput"
        >
          <el-select
          clearable
          :disabled="ruleForm.isGift?ruleForm.isGift == 0?true:false:false"
            @change="paymentButton(ruleForm.paymentMethodId)"
            v-model="ruleForm.paymentMethodId"
            placeholder="请选择付款方式"
          >
            <el-option
              v-for="item in paymentFrom"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="备注信息:" style="margin-top: 10px;">
          <el-input
            type="textarea"
            class="textareaInput"
            :rows="2"
            style="width: 510px;"
            @change="UpdateDate"
            placeholder="请输入备注信息,200字以内"
            v-model="ruleForm.remark"
          >
          </el-input>
        </el-form-item>
        <span
          class="priceMessage"
          >会员卡开卡金额（在会员等级中设置）</span
        >
      </el-form>

      <!-- 备注区域 -->
      <!-- <div class="remark">
                  <p class="remark_title">备注信息:</p>
                 
                  
                </div> -->
      <!-- 备注区域 -->
    </div>
    <!-- 根据开的卡片，付款 -->
    <div class="membership_submit">
      <el-button @click="submitForm">保存</el-button>
    </div>
  </div>
</template>

<script>
import { membershipDetailsAdd, LevelOfMembershipAll } from "_api/member";
import { Getpayment } from "_api/conditionOfHouse";
import axios from "axios";
export default {
  name: "MembershipCard",
  data() {
    return {
      value: "",
      ruleForm: {
        activateCost: "", //开卡金额number
        memberGradeId: "", //会员等级id integer(int64)
        memberGradeName: "", //会员名称
        activateId: "", //开卡记录表idinteger(int64)
        activateTime: "", //开卡日期string(date-time)
        address: "", //住址
        creditCardAmount: "", //开卡金额number
        // customerId: '1',//客户id
        gender: null, //性别
        idCard: null, //身份证
        isGift: "", //是否赠予 0是 1否
        mobile: null, //手机号
        name: null, //用户姓名
        paymentMethodId: "", //收款方式integer(int64)
        paymentMethodName: "",
        remark: "", //备注
        state: true,
        addNumss: 0,
      },
      addNumss: 0,
      memberClass: "",
      paymentFrom: [], //付款方式的数据
      membershipFrom: [], //会员等级的数据
      rules: {
        mobile: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern:
              /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        isGift: [
          { required: true, message: "请选择是否赠送", trigger: "blur" },
        ],
        idCard: [
          { required: false, message: "请输入身份证号码", trigger: "blur" },
          {
            pattern:
              /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
            message: "请输入正确的身份证号码",
            trigger: "blur",
          },
        ],
        name: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          {
            pattern:
              /^(([a-zA-Z+\.?\·?a-zA-Z+]{2,30}$)|([\u4e00-\u9fa5+\·?\u4e00-\u9fa5+]{2,30}$))/,
            message: "请输入正确的姓名",
            trigger: "blur",
          },
        ],
        gender: [{ required: true, message: "请选择性别", trigger: "blur" }],
        memberGradeId: [
          { required: true, message: "请选择会员等级", trigger: "blur" },
        ],
        creditCardAmount: [
          { required:true, message: "请选择开卡金额", trigger: "blur" },
        ],
        paymentMethodId: [
          { required: true, message: "请选择付款方式", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.initDate();
    this.Getpaymented();
    this.LevelOfMembershipAlled();
  },
  methods: {
    //用refs调用这个方法
    createdBox() {
      this.initDate();
      // this.Getpaymented()
      // this.LevelOfMembershipAlled()
    },
    //点击图标读取身份证信息
    cardButton() {
      this.ReadingDataButton();
    },
    //读取身份证的信息的接口
    ReadingDataButton(index) {
      axios({
        method: "POST",
        url: "http://127.0.0.1:19196/readcard&t=0.5676041095624611",
        headers: {
          "Sec-Fetch-Site": "same-origin",
        },
      }).then((res) => {
        console.log(res);
        this.ruleForm.name = res.data.partyName;
        // this.userinfo[parseFloatparseFloatparseFloat(index)].birthday = res.data.bornDay
        this.ruleForm.gender = res.data.gender == "男" ? "0" : "1";
        this.ruleForm.idCard = res.data.certNumber;
        this.ruleForm.address = res.data.certAddress;
        // this.idCardButton();
      });
    },
    UpdateDate() {},
    paymentButton(id) {
      this.paymentFrom.forEach((el) => {
        if (el.id == id) {
          this.ruleForm.paymentMethodName = el.name;
        }
      });
    },
    //新增会员的接口
    membershipDetailsAdded() {
      const params = {
        personnel: localStorage.getItem("name"),
        memberGradeId: this.ruleForm.memberGradeId, //会员等级id integer(int64)
        activateTime: this.ruleForm.activateTime, //开卡日期string(date-time)
        address: this.ruleForm.address, //住址
        creditCardAmount: Number(this.ruleForm.creditCardAmount), //开卡金额number
        // customerId: '1',//客户id
        gender: this.ruleForm.gender === "0" ? 0 : 1, //性别
        idCard: this.ruleForm.idCard, //身份证
        isGift: this.ruleForm.isGift === "0" ? 0 : 1, //是否赠予 0是 1否
        mobile: this.ruleForm.mobile, //手机号
        orgName: localStorage.getItem("orgName"),
        name: this.ruleForm.name, //用户姓名
        paymentName: this.ruleForm.paymentMethodName,
        paymentMethodId: this.ruleForm.paymentMethodId, //收款方式integer(int64)
        remark: this.ruleForm.remark, //备注
        userClassesId: localStorage.getItem("userClassesId"),
      };
      membershipDetailsAdd(params)
        .then((res) => {
          if (res.data.code === 0) {
            this.$emit("offInquiry");
            this.$message({
              type: "success",
              message: "会员开卡成功",
            });
          }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err.errorMsg,
          });
        });
    },

    memberGradeIdButton(el) {
      this.memberClass = el;
      this.membershipFrom.forEach((item) => {
        if (item.id == el) {
          console.log(item);
          this.ruleForm.memberGradeName = item.memberGradeName;
          this.ruleForm.creditCardAmount = item.activateCost;
          this.ruleForm.isGift = "1";
        }
      });
    },
    whetherOnff(num) {
      console.log("--------------------", num);
      if (num == "0") {
        this.ruleForm.creditCardAmount = "0";
      } else {
        this.memberGradeIdButton(this.memberClass);
      }
    },
    //查看所有的会员等级
    LevelOfMembershipAlled() {
      console.log(this.ruleForm.activateTime);
      LevelOfMembershipAll({
        tenantId: localStorage.getItem("TenantId"),
        orgId: localStorage.getItem("orgid"),
      }).then((res) => {
        if (res.data.code === 0) {
          this.membershipFrom = res.data.data;
        }
      });
    },
    //付款方式/付款查看
    Getpaymented() {
      var that = this;
      Getpayment().then((res) => {
        console.log("数据", res);
        if (res.data.code === 0) {
          // this.paymentFrom = res.data.data;
          this.paymentFrom.splice(0);
          this.paymentFrom = res.data.data.filter(el =>  el.name != '协议挂账' && el.name != '美团预付' && el.name != '飞猪预付' && el.name != '携程预付' &&el.name != '抖音预付' && el.name != '挂房账' && el.name != '挂账' &&el.name != '微储值' && el.name != '挂房账' && el.name != '餐饮挂账' && el.name != '商超挂账' && el.name != '预授权' );
       
        }
      });
    },
    //当天日期
    mouseenter(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + m + "-" + d;
    },
    initDate() {
      this.ruleForm.activateTime = this.mouseenter(new Date());
    },
    // 点击右上角的图标隐藏弹窗
    close_page() {
      this.$emit("offInquiry");
    },
    //点击提交表单进行验证
    submitForm(formName) {
      this.$refs.ruleForms.validate((valid) => {
        if (valid) {
          if(this.ruleForm.isGift == 0) {
            this.membershipDetailsAdded();
          }else if(this.ruleForm.isGift == 1) {
            if(this.ruleForm.paymentMethodId) {
            this.membershipDetailsAdded();
          }else{
            this.$message({
          type:'error',
          message: '付款方式未选择'
        })
          }
          }
         
        } else {
          this.$message({
            type: "error",
            message: "请按照正确格式填写信息",
          });
          return false;
        }
      });
    },
    //点击重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
.priceMessage{
 font-size: 13px;
  color: #b2b1b1;
  position: absolute;
  top: 35%;
  left: 10%;
}
.SearchButton {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 10px;
  right: 10px;
}
.MembershipCard {
  width: 100%;
  max-height: 650px;

  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-direction: column;
  background-color: #fff;
  
  overflow: hidden;
  z-index: 1000;
  // background: linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%);
  .member_main {
    width: 100%;
    // background: linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;

    //选择的会员等级和用户信息
    .membership_message {
      width: 90%;
      height: 320px;
      margin-top: 20px;
      display: flex;
      border-radius: 8px;
      flex-direction: column;
      align-items: center;

      //警告提示语css
      p {
        width: 90%;
        height: 40px;
        line-height: 40px;
        display: flex;
        border-radius: 8px;
        margin-top: 10px;
        align-items: center;
        color: #f4a509;
        margin-bottom: 20px;
        font-size: 14px;
        box-sizing: border-box;

        img {
          width: 18px;
          height: 16px;
          margin-right: 5px;
        }
      }

      .demo-ruleForm {
        width: 100%;
      }
    }
  }

  //根据开的卡片，付款的css
  .membership_payment {
    width: 90%;
    margin-top: 20px;
    border-radius: 8px;
    padding-left: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
    border: 1px solid #ededed;
    border-radius: 8px;

    p {
      width: 100%;
      height: 30px;
      line-height: 30px;
      margin-top: 20px;
      font-size: 14px;
      margin-bottom: 20px;
    }

    //备注框css
    .remark {
      width: 100%;
      padding-right: 10px;
      box-sizing: border-box;
      display: flex;

      .remark_title {
        width: 100px;
        height: 35px;
        font-weight: bolder;
        font-size: 13px;
        line-height: 2px;
        box-sizing: border-box;
        .textareaInput {
          border-radius: 9px;
        }
      }
      .remark_main {
        flex: 1;
        height: 80px;
        border: 1px dotted gray;
        border-radius: 10px;
        color: gray;
        span {
          display: block;
          margin-top: 10px;
          margin-left: 20px;
        }
        ::v-deep .el-input__inner {
          height: 52px;
          background: linear-gradient(177deg, #e8fdfe 0%, #ffffff 100%);
          // 设置字号
          font-size: 14px;
          border: none;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          // 设置输入字体的颜色
          color: #2c2c2c;
        }
      }
      button {
        color: #5cb5b8;
        width: 80px;
        height: 25px;
        margin-top: 10px;
        border: 1px dotted #5cb5b8;
        background-color: #fff;
      }
    }
  }

  .membership_submit {
    width: 100%;
    height: 60px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    .el-button {
      width: 150px;
      height: 40px;
      background-color: #5cb5b8;
      color: #fff;
      border-radius: 6px;
    }
  }
}
.el-form {
  .el-form-item:last-child {
    width: 100%;
    ::v-deep .el-textarea__inner {
      width: 284%;
    }
  }
  .priceInput.el-form-item {
    ::v-deep .el-input__inner {
      width: 116%;
    }
  }
  .paymentInput.el-form-item {
    width: 50%;
    ::v-deep .el-input__inner {
      width: 100%;
    }
    ::v-deep .el-input__icon {
    }
  }
}
</style>