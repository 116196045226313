<template>
  <!-- 冲减房费 -->
  <div class="DeductRoom">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="demo-ruleForm"
    >
      <el-form-item label="房间信息">
        <el-table border :data="ruleForm.tableData" style="width: 100%">
          <el-table-column prop="id" align="center" label="入住单号">
          </el-table-column>
          <el-table-column prop="name" align="center" label="住客姓名">
          </el-table-column>
          <el-table-column prop="reserveDays" align="center" label="已住天数">
          </el-table-column>
          <el-table-column
            prop="consumptionAmount"
            align="center"
            label="消费合计"
          >
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item  label-width="100px" style="position: relative;background-color: #f7d17136">
        <img 
        class="tixingClass"
        src="../../../../../../assets/icons/警告 (1).png" alt="">

        <p style="color: orange">请输入冲账金额，默认为负数</p>
       
      </el-form-item>
      <el-form-item label="冲账金额：" label-width="100px" prop="price">
        <el-input v-model="ruleForm.price"> </el-input>
      </el-form-item>
      <el-form-item label="冲账原因：" label-width="100px">
        <el-input v-model="ruleForm.remark" placeholder="请输入冲账原因">
        </el-input>
      </el-form-item>
      <el-form-item style="text-align: right">
        <el-button class="NuttonSubmit" @click="submitButton('ruleForm')"> 确定 </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { AddFinance, ListFinance } from "_api/orderFrom";
import { UnitBargainingLook } from "_api/systemSetting";
export default {
  name: "DeductRoom",
  data() {
    return {
      ruleForm: {
        tableData: [],
        price: null,
        paymentId: null, //付款方式id
        projectName: null, //项目名称
      },
      rules: {
        price: [{ required: true, message: "请输入冲账金额", trigger: "blur" }],
      },
      bargainingUnit: [], //消费项目的数据
    };
  },
  created() {},
  methods: {
    //点击确定触发
    submitButton(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.AddFinanceed();
        } else {
          this.$message({
            type:'error',
            message: '请按照要求填写信息'
          })
          return false;
        }
      });
    },
      //财务分页列表查询
      ListFinanceed(row) {
    
    const params = {
      current: 1,
      size: 10000,
      model: {
        orderId: row.id,
        operator: localStorage.getItem("classesId"),
      },
    };
    ListFinance(params).then((res) => {
      if (res.data.code === 0) {
       var arr = res.data.data.records.filter(el => el.state)
       console.log('ghghfchgf');
       this.ruleForm.tableData[0].consumptionAmount = 0
       arr.forEach(el => {
        if(el.itemType == 1 && !el.preAuth) {
          //消费
          this.ruleForm.tableData[0].consumptionAmount = parseFloat(this.ruleForm.tableData[0].consumptionAmount)  + parseFloat(el.price)
        }
        this.ruleForm.tableData[0].consumptionAmount = parseFloat(this.ruleForm.tableData[0].consumptionAmount).toFixed(2)
      
       })
       console.log(row);
    this.getNowDates(row.arrivalTime)
      }
    });
  
  },
    //获取真实天数
    getNowDates(oldData) {
      var date1 = new Date().getTime();
      var oldData1 = new Date(oldData).getTime();
      console.log(oldData);
      if (parseFloat(date1) - parseFloat(oldData1) <= 1 * 24 * 60 * 60 * 1000) {
        this.ruleForm.tableData[0].reserveDays = 1 + "天";
      } else if (
        parseFloat(date1) - parseFloat(oldData1) >=
        1 * 24 * 60 * 60 * 1000
      ) {
        let num = Math.ceil(
          (parseFloat(date1) - parseFloat(oldData1)) / (1 * 24 * 60 * 60 * 1000)
        );
        this.ruleForm.tableData[0].reserveDays = num + "天";
      }
      console.log( this.ruleForm.tableData[0].reserveDays);
    },
    //财务管理表之新增接口
    AddFinanceed() {
      const params = {
        price:
          parseFloat(this.ruleForm.price) - 2 * parseFloat(this.ruleForm.price), //金额
        orderId: this.ruleForm.tableData[0].id, //关联订单
        authorizationCode: this.ruleForm.tableData[0].authorizationCode,
        // bankNumber: this.$store.state.user.orderFrom.bankNumber,
        itemType: "1",
        state: true,
        roomId: this.ruleForm.tableData[0].roomId,
        roomName: this.ruleForm.tableData[0].roomName, //房型名称
        userClassesId: localStorage.getItem("userClassesId"),
        operator: localStorage.getItem("name"),
        paymentId: this.ruleForm.paymentId,
        projectName: this.ruleForm.projectName, //项目名称
        type: "0",
        classesId: localStorage.getItem("classesId"),
        createdName: localStorage.getItem("name"),
        classes: localStorage.getItem("classes"),
        remarks: this.ruleForm.remark?this.ruleForm.remark:undefined,
      };
      AddFinance(params).then((res) => {
        if (res.data.code === 0) {
          this.$emit("AddenterOff", false);
          this.$message({
            type: "success",
            message: "费用冲减成功",
          });
          this.$emit('DeductRoomButton', true)
        }
      });
    },
    //消费项目的请求
    UnitBargainingLooked() {
      UnitBargainingLook().then((res) => {
        if (res.data.code === 0) {
          this.bargainingUnit = res.data.data;
          this.bargainingUnit.forEach((el) => {
            if (el.name == "房费调整") {
              this.ruleForm.paymentId = el.id;
              this.ruleForm.projectName = el.name;
            }
          });
        }
      });
    },
    // //获取真实天数
    // getNowDates(date, oldData) {
    //   var date1 = new Date(date).getTime();
    //   var oldData1 = new Date(oldData).getTime();
    //   if (parseFloat(date1) - parseFloat(oldData1) <= 1 * 24 * 60 * 60 * 1000) {
    //     this.ruleForm.tableData[0].reserveDays = 1 + "天";
    //   } else if (
    //     parseFloat(date1) - parseFloat(oldData1) >=
    //     1 * 24 * 60 * 60 * 1000
    //   ) {
    //     let num = Math.ceil(
    //       (parseFloat(date1) - parseFloat(oldData1)) / (1 * 24 * 60 * 60 * 1000)
    //     );
    //     this.ruleForm.tableData[0].reserveDays = num + "天";
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.NuttonSubmit{
  width: 100px;
  height: 40px;
  padding: 0px 0px;
  color: #fff;
  background-color: #5cb5b8;
}
.tixingClass{
  position: absolute;
  width: 20px;
  height: 20px;
  top: 18%;
  left: -5%;
}
.DeductRoom {
}
</style>