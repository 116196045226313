import request from '_utils/request'
import qs from 'qs'
// 用户登录
const userLogin = (params = {}) => (
  request.post('/oauth/anyTenant/login', params,
 { 
  headers: {
    isLoading:false//不用使用加载动画loading
  }
}
  )
)

//获取所有的应用权限
const findMyApplication = (params = {}) => (
  request.get('system/anyone/findMyApplication', {params})
)

//根据对应应用id获取下面的内容
const getApplicationId = (params = {}) => (
  request.get('/oauth/anyone/visible/router', {params})
)
//
const getVisible = (params = {}) => (
  request.get('/oauth/anyone/visible/resource', {params})
)
//新增部门当班信息
const AddOrder = (params = {}) => (
  request.get('/wineshop/userClasses/addUserClasses', {
    query: params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
)
//获取该部门当班信息
const CurrentClass = (params = {}) => (
  request.get('/oauth/anyone/visible/resource/' + params.orgId)
)

const getUserInfoById = (params = {}) => (
  request.get('/oauth/anyone/getUserInfoById?userId=' + params.userId)
)

const gethotelinfo = (params = {}) => (
  request.get('/base/base_hotel_address/detail?id=' + params.id)
)



export {
  userLogin,
  findMyApplication,
  getApplicationId,
  getVisible,
  AddOrder,
  getUserInfoById,
  gethotelinfo,
}
