<template>
    <!-- 联房入住 -->
    <div class="RelevanceCheck">
    <el-row class="houseTypeCard">
        <el-col :span="3" class="leftCardButton">
            <ul class="leftLI">
                <li
                :class="{'active': li_index == index}"
                 v-for="(item, index) in houselist" :key="item.id"
                @click="lichange(index)"
                > 
                <span v-if=" item.houseType?item.houseType.length > 5?false:true:true">{{ item.houseType }}</span>
                <el-tooltip
              v-else
              class="item"
              effect="light"
              placement="top-start"
            >
              <div slot="content" >{{ item.houseType }}</div>
              <span>
                {{
                   item.houseType? item.houseType.length > 5?  item.houseType.slice(0,5)+'...':item.houseType:'' 
                }}
              </span>
            </el-tooltip>
                </li>
            </ul>
        </el-col>
        <el-col :span="20" :offset="0.5" class="rightCard">
           <!-- 卡片区域 -->
           <div  class="cartlist">
            <div  @click="radioCard(item)"
            v-for="(item) in houselist[this.li_index]['room']" :key="item.id"
                :class="{ 'change_cards': true, 'active': true }">
                <div class="change_Price">
                    <span>{{item.roomNumber}}</span>
                    <span>
                       {{ item.echoMap.houseTypeId }}
                    </span>
                </div>
               
                    <img :class="{'active': isImg.includes(item.id)}" src="../../../assets/icons/选择-勾选.png" alt="">
               
            </div>
           
            <!-- 卡片区域 -->
        </div>

        </el-col>
    </el-row>

    <el-row>
        <el-col :span="11">
            <!-- 勾选到的房间展示 -->
            <el-table
            border
            max-height="240px"
      :data="tableData"
      style="width: 100%">
      <el-table-column
        prop="roomNumber"
        label="已选房号"
        >
      </el-table-column>
      <el-table-column
      align="center"
        prop="echoMap.houseTypeId"
        label="房型"
       >
      </el-table-column>
      <el-table-column
        prop="housePrice"
        label="门市价"
        align="center"
       >
      </el-table-column>
      <el-table-column
        label="实际房价"
        align="center"
      >
        <template slot-scope="scope" >
         <input type="text"  
         v-if="!form.allPrice"
         v-model="scope.row.roomRealityPrice"
         style="width:80px;height: 30px;border:1px  solid rgb(187, 186, 186) ; "
         />
         <span 
         v-else
         style=" display: block;width:80px;height: 30px;line-height: 30px; border:1px solid rgb(216, 214, 214);">
        {{ form.allPrice }}  
        </span>
        </template>
      </el-table-column>
      <el-table-column
      align="center"
        label="操作">
        <template slot-scope="scope">
          <span
          @click="radioCard(scope.row)"
          style="color: red;cursor: pointer;">删除</span>
        </template>
      </el-table-column>
    </el-table>
        </el-col>

        <el-col :span="12" :offset="1">
            <!-- 表单操作 -->
            <el-form class="demo-form"  :inline="true" :rules="rules"  ref="form" :model="form" label-width="80px">
                <el-form-item  style="width:90px;height: 30px;margin-left: 10px;" label-width="0px">
                    <el-select @change="agreementUnitOrMember(agreementUnit)" v-model="agreementUnit" style="width:110px;border: none;" >
                        <el-option
                            label="协议单位"
                            value="1">
                        </el-option>
                   <el-option
                     label="个人会员"
                     value="0">
                   </el-option>
                 </el-select>
                 
                    </el-form-item>
                    <el-form-item  style="width:150px;height: 30px;margin-left: 10px;" >
                        <el-input v-model="nameMobile" :placeholder="placeName" clearable></el-input>
                    </el-form-item>

                    <el-form-item style="margin-bottom: -13px;"  label-width="5px">
                        <el-button 
                        class="searchButtonBox"
                        style="" icon="el-icon-search" @click="bargainingClick()"></el-button>
                    </el-form-item> <br><br>

                    <el-form-item label-width="80px" label="姓名" prop="name">
                     <el-input style="width:160px;" v-model="form.name"></el-input>
                    </el-form-item>
                    <el-form-item   label-width="50px" label="客类">
                        <el-select disabled v-model="form.clientType" style="width:80px;" >
                        <el-option
                            label="散户"
                            value="0">
                        </el-option>
                        <el-option
                          label="会员"
                          value="1">
                        </el-option>
                        <el-option
                          label="协议"
                          value="2">
                        </el-option>
                        <el-option
                          label="OTA"
                          value="3">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item   label-width="80px" label="入住类型">
                        <el-select
                        @change="settlementTypeButton"
                        v-model="form.settlementType" style="width:90px;" >
                        <el-option
                            label="全天房"
                            value="0">
                        </el-option>
                   <el-option
                     label="钟点房"
                     value="1">
                   </el-option>
                   <el-option label="自用房" value="2"></el-option>
              <el-option label="免费房" value="3"></el-option>
                 </el-select>
                    </el-form-item>
                    <el-form-item  label-width="80px" label="协议单位">
                      <el-input :disabled="true" @change="GuestClass" v-model="form.agreementUnitName" style="width:130px;"></el-input>
                    </el-form-item>
                    <el-form-item   label-width="80px" label="销售员工">
                      <el-input v-model="form.className" :disabled="true" style="width:60px;"></el-input>
                    </el-form-item>
                    <el-form-item  label-width="80px"  label="会员号">
                      <el-input v-model="form.number"  disabled style="width:110px;"></el-input>
                    </el-form-item>
                    <el-form-item  label-width="80px" label="抵店时间">
                        <el-date-picker
                        style="width:173px;"
                        v-model="form.arrivalTime"
                        type="datetime"
                        disabled
                        value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="选择日期时间">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item 
                    v-if="form.settlementType != '1'"
                    label-width="80px" label="预住天数">
                      <el-input @change="ChangereserveDays(parseFloat(form.reserveDays))" v-model="form.reserveDays" style="width:60px;"></el-input>
                    </el-form-item>
                    <el-form-item
                    v-else
                    label-width="75px" label="钟点时长">
                      <el-input v-model="form.hourLength" style="width:70px;"></el-input>
                    </el-form-item>
                    <el-form-item  label-width="75px" label="会员等级">
                      <el-input @change="GuestClass" v-model="form.memberGradeName" style="width:73px;"  disabled></el-input>
                    </el-form-item>
                    <el-form-item label-width="80px" label="预离时间">
                        <el-date-picker
                        style="width:173px;"
                        @change="LeaveDate()"
                        v-model="form.predepartureTime"
                        type="datetime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="选择日期时间">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item  label-width="80px" label="房价码">
                      <el-input v-model="form.housePriceName" style="width:218px;"  disabled></el-input>
                    </el-form-item>
                    <el-form-item label-width="80px" label="统一房价">
                      <!-- form.settlementType -->
                      <el-input 
                      clearable
                      :disabled="form.settlementType == 2 || form.settlementType == 3"
                      @change="allPriceChange(form.allPrice)"
                      v-model="form.allPrice" 
                      style="width:80px;"></el-input>
                    </el-form-item>
                    <el-form-item  label-width="80px" label="手机号码" prop="mobile">
                      <el-input @change="userInfoGeted(form.mobile)" v-model="form.mobile" style="width:160px;" ></el-input>
                    </el-form-item>  
                    <el-form-item  label-width="80px" label="明日早餐">
                      <el-input v-model="form.breakfast" style="width:62px;" ></el-input>
                    </el-form-item>
                  
            </el-form>
        </el-col>
    </el-row>
    <div class="flootButton">
    <div class="buttonRight">
      <span >已选{{tableData.length}}间</span>
    <el-button @click="CheckinRooom(1)">联房入住</el-button>
    <el-button @click="CheckinRooom(0)">取消</el-button>
    </div>
    </div>

    <el-dialog
  title="协议单位查询（双击选择）"
  :visible.sync="dialogVisible"
  width="1100px"
  append-to-body
  destroy-on-close
  :before-close="handleClose">
 <div class="SearchBargaining" ref="roomlist">
  <el-row style="text-align: center;">
    <el-input style="width: 400px;" 
    @change="searchButton(searchInput)"
    placeholder="请输入协议单位名称" v-model="searchInput" class="input-with-select">
    <el-button class="searchNameBox" slot="append" icon="el-icon-search" @click="searchButton(searchInput)"></el-button>
  </el-input>
  </el-row>
    <el-row>
        <el-col :span="15" :offset="1">
            <el-table
            border
            :height="tableheight"
            @row-click="oneClick"
            @row-dblclick="dbClickRoom"
      :data="BargainingTable"
      style="width: 100%">
      <el-table-column
        prop="name"
        label="协议名称"
        >
      </el-table-column>
      <el-table-column
      align="center"
        prop="canCharge"
        label="是否挂账"
       >
       <template slot-scope="scope">
        {{ scope.row.canCharge?'是':'否' }}
       </template>
      </el-table-column>
      <el-table-column
        prop="housePriceCodeName"
        label="房价码"
        align="center"
       >
      </el-table-column>
      <el-table-column
        label="未结挂账"
        align="center"
      >
        <template slot-scope="scope" >
       <span>￥{{ scope.row.waitReceptionPrice }}</span>
        </template>
      </el-table-column>
      <el-table-column
      align="center"
      prop="remarks"
        label="备注">
       
      </el-table-column>
    </el-table>
    <el-pagination
            style="text-align: right;"
            @size-change="handleSizeChange"
          :current-page.sync="currentPage1"
          @current-change="handleCurrentChange"
          :page-sizes="sizes"
          :page-size="size"
          layout="total, prev, pager, next,sizes"
          :total="total"
        >
        </el-pagination>
        </el-col>


        <el-col :span="7" :offset="1">
        <div class="rightBox">
            <ul>
                <li> {{clickFrom.Name }} &nbsp;  &nbsp;  房价码明细</li>
                <li v-for="el in clickFrom.correspondingPrice" :key="el.id">
                 【{{ el.houseTypeName }}】 ￥{{ el.normalPrice }} [周末价 {{ el.weekendPrice }}] <br>
               <span>[钟点房 {{ el.hourlyPrice }}/ {{ parseFloat(el.hourLength) }}小时]</span>  
                </li>
            </ul>
        </div>
        </el-col>
    </el-row>
 </div>
</el-dialog>

    </div>
</template>

<script>
import { listBargainingUnit } from "_api/systemSetting";
import { order_baseSaveList } from "_api/housingSystem";
import { userInfoGet } from "_api/orderFrom";
export default {
  name: "RelevanceCheck",
  data() {
    return {
      isImg: [], //点击卡片获取房间id
      AsiderHoomtype: [], //
      tableData: [
        // {
        //     "houseTypeId": null,//房型id
        //     "houseTypeName": null,//房型名称
        //     "roomId": null,//房间id
        //     "roomName": null,//房间号
        //     "roomPrice": null,//门市价
        //     "roomRealityPrice": null//实际房价
        // }
      ],
      searchInput: "",
      tableheight: 0,
      form: {
        name: null, //顾客姓名
        clientType: "0", //客户类型 0散户 1会员 2协议 3OTA
        breakfast: 1, //早餐份数
        connectRoomId: null, //连房id
        memberId: null, //会员id
        number: null, //会员号
        memberGradeName: null, //会员等级名称
        mobile: null, //手机号码
        remark: null, //备注信息
        predepartureTime: null, //预离时间
        reserveDays: "1天", //预住天数
        hourLength: "3小时", //钟点时长
        settlementType: "0", //入驻类型 0全天房 1钟点房
        housePriceId: null, //房价码ID
        housePriceName: null, //房价码名称
        arrivalTime: null, //抵店时间
        agreementUnitId: null, //协议单位id
        agreementUnitName: null, //协议单位名称
        allPrice: null, //统一房价
        orgId: localStorage.getItem("orgid"), //部门ID
        connectRoom: [], //联房的房间
        className: null,
      },
      dialogVisible: false,
      agreementUnit: "1", //默认是协议单位,0会员
      nameMobile: null, //筛选条件
      houselist: [], //房型的数据
      relevanTable: [], //所有干净房间
      checkRoomFrom: [], //选中的房间
      li_index: 0, //点击房型获取的下标
      BargainingTable: [], //协议单位里的表格数据
      currentPage1: 1,
      sizes: [10, 20, 50, 100, 150, 200],
      size: 10,
      total: 0,
      inputState: false,
      HousePriceCode: this.$store.state.base.HousePriceCode, //所有房价码信息
      clickFrom: {}, //协议列表被点击后的数据
      dbClickRoomData: {}, //协议单位双击后获取的数据
      placeName: "",
      member: {
        memberGradeName: null,
        number: null,
        memberId: null,
      },
      rules: {
        mobile: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern:
              /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        name: [{ required: true, message: "请输入客户姓名", trigger: "blur" }],
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    agreementUnitOrMember(num) {
      this.nameMobile = ''
    },
    //预祝天数变化自动改变预离时间
    ChangereserveDays(data) {
      console.log(data);
      var days = new Date().getTime();

      data = parseFloat(data);
      days = parseFloat(data) * (1 * 24 * 60 * 60 * 1000) + days;
      // console.log(days, "111111111111111111");
      this.getNowDate(days, 2);

      this.form.reserveDays = data + "天";
      // console.log(this.ruleForm.predepartureTime);
    },
    CheckinRooom(num) {
      if (num == 0) {
        //取消
        this.$emit("RelevanBOXS", [0]);
      } else if (num == 1) {
        //联房入住
        if (this.tableData.length > 0) {
          this.$refs.form.validate((valid) => {
            if (valid) {
              var num = null;
              this.tableData.forEach((el) => {
                if (!el.roomRealityPrice) {
                  num = 1;
                }
              });
              if (num == 1) {
                this.$message({
                  type: "error",
                  message: "请输入房间实际价格或者填写统一价格",
                });
              } else {
                this.order_baseSaveListed();
              }
            } else {
              this.$message({
                type: "error",
                message: "请填写用户信息",
              });
              return false;
            }
          });
        } else {
          this.$message({
            type: "error",
            message: "请选择要联房的房间",
          });
        }
      }
    },
    //协议单位的搜素输入框
    searchButton(input) {
      if (input) {
        this.RoomListInquired({ name: input });
      } else {
        this.RoomListInquired();
      }
    },
    inputChange() {
      if (this.searchInput) {
        this.RoomListInquired({ name: input });
      } else {
        this.RoomListInquired();
      }
    },
    settlementTypeButton() {
      if (this.form.settlementType == "0") {
        //全天房
        this.getNowDate(this.form.arrivalTime, 2);
      } else if (this.form.settlementType === "1") {
        //钟点房
        this.getNowDate(this.form.arrivalTime, 3);
      }else if (this.form.settlementType === "2") {
        //自用房
        this.getNowDate(this.form.arrivalTime, 2);
        this.$set(this.form,'allPrice',0)
        this.allPriceChange(0)
      }else if (this.form.settlementType === "3") {
        //免费房
        this.getNowDate(this.form.arrivalTime, 2);
        this.$set(this.form,'allPrice',0)
        this.allPriceChange(0)
       
      }
    },
    //判断是协议还是会员
    GuestClass() {
      if (this.form.agreementUnitName) {
        //有协议单位
        this.form.clientType = "2";
      } else if (this.form.memberGradeName) {
        if (this.form.agreementUnitName) {
          this.form.clientType = "2";
        } else if (this.form.memberGradeName) {
          this.form.clientType = "1";
        }
      } else {
        this.form.clientType = "0";
      }
    },
    //联房入住接口
    order_baseSaveListed() {
      var newArr = this.tableData[0];
      if (
        parseFloat(
          (
            parseFloat(newArr.roomRealityPrice) / parseFloat(newArr.housePrice)
          ).toFixed(2)
        )
      ) {
        var discount =
          parseFloat(
            (
              parseFloat(newArr.roomRealityPrice) /
              parseFloat(newArr.housePrice)
            ).toFixed(2)
          ) *
            100 +
          "%";
      } else {
        var discount = "0%";
      }
      var params = {
        agreementUnitId: this.form.agreementUnitId, //协议单位id
        agreementUnitName: this.form.agreementUnitName, //协议单位名称
        arrivalTime: this.form.arrivalTime, //抵店时间
        // "birthday": "",//生日
        breakfast: parseFloat(this.form.breakfast), //早餐份数
        clientType: this.form.clientType, //客户类型 0散户 1会员 2协议 3OTA
        connectRoom: [
          //连房房间
        ],
        connectRoomId: newArr.connectRoomId, //连房id
        discount: parseFloat(discount), //折扣率
        // "gender": "",//性别 0 男 1 女
        // "hourLength": 0,//钟点时长
        housePriceId: this.form.housePriceId, //房价码ID
        houseTypeId: newArr.houseTypeId, //房型id
        houseTypeName: newArr.echoMap.houseTypeId, //房型名称
        // "idCard": "",//身份证号码
        isMasterRoom: true, //是否为连房的主房（1为是，0为否）
        memberId: this.form.memberId, //会员id
        mobile: this.form.mobile, //手机号码
        name: this.form.name, //顾客姓名
        // "orderReserveId": 0,//预订单id
        orgId: localStorage.getItem("orgid"), //部门ID
        predepartureTime: this.form.predepartureTime, //预离时间
        remark: this.form.remark, //备注信息
        reserveDays:
          this.form.settlementType == "0"
            ? parseFloat(this.form.reserveDays)
            : null, //预住天数
        roomId: newArr.id, //房间id
        roomName: newArr.roomNumber, //房间号
        roomPrice: newArr.housePrice, //门市价
        roomRealityPrice: parseFloat(newArr.roomRealityPrice), //实际房价
        settlementType: this.form.settlementType, //入驻类型 0全天房 1钟点房
        hourLength:
          this.form.settlementType == "1"
            ? parseFloat(this.form.hourLength)
            : null,
      };
      if (this.form.allPrice) {
        this.tableData.splice(0, 1);
        this.tableData.forEach((el, indexs) => {
          params.connectRoom.push({
            houseTypeId: el.houseTypeId, //房型id
            houseTypeName: el.echoMap.houseTypeId, //房型名称
            roomId: el.id, //房间id
            roomName: el.roomNumber, //房间号
            roomPrice: parseFloat(el.housePrice), //门市价
            roomRealityPrice: parseFloat(this.form.allPrice),
          });
          console.log(
            el,
            "sadadasdasshdgsadaskjdsakjdhjkahjsashdsajkdasdhasjdjsah"
          );
        });
      } else {
        this.tableData.splice(0, 1);
        this.tableData.forEach((el, indexs) => {
          params.connectRoom.push({
            houseTypeId: el.houseTypeId, //房型id
            houseTypeName: el.echoMap.houseTypeId, //房型名称
            roomId: el.id, //房间id
            roomName: el.roomNumber, //房间号
            roomPrice: parseFloat(el.housePrice), //门市价
            roomRealityPrice: parseFloat(el.roomRealityPrice),
          });
        });
      }

      order_baseSaveList(params).then((res) => {
        if (res.data.code === 0) {
          this.$message({
            type: "success",
            message: "联房入住成功",
          });
          this.$emit("RelevanBOXS", res.data.data);
        }
      });
    },
    //协议单位的双击事件
    dbClickRoom(row) {
      console.log(row);
      // this.getNowDate(new Date())
      this.HousePriceCode.forEach((el) => {
        if (el.number == row.housePriceCodeNumber) {
          this.clickFrom = { ...el, Name: row.name };
        }
      });
      console.log(this.clickFrom);
      this.dbClickRoomData = row;
      (this.form.clientType = "2"), //客户类型 0散户 1会员 2协议 3OTA
        (this.form.breakfast = 1), //早餐份数
        (this.form.housePriceId = this.clickFrom.id), //房价码ID
        (this.form.housePriceName = this.clickFrom.name), //房价码名称
        (this.form.agreementUnitId = row.id), //协议单位id
        (this.form.agreementUnitName = row.name), //协议单位名称
        (this.dialogVisible = false);
        this.nameMobile = ''
    },
    //点击表单的搜索按钮，是协议单位时弹出弹窗，反之是会员发送查看会员请求
    bargainingClick(row) {
      let num = this.agreementUnit;
      if (num == "1") {
        //协议单位
        this.dialogVisible = true;
        this.autoHeight();
        this.placeName = "请选择协议单位";
        this.searchInput = this.nameMobile;
        this.searchButton(this.searchInput)
        this.inputChange();
      } else if (num == "0") {
        //会员发送请求
        this.placeName = "请输入手机号码";
        if (!this.nameMobile) {
          this.$message({
            type: "error",
            message: "请输入手机号为搜索条件",
          });
        } else {
          this.userInfoGeted(this.nameMobile);
        }
      }
    },
    //根据手机号查询数据
    userInfoGeted(num) {
      const params = {
        mobile: num,
      };
      userInfoGet(params)
        .then((res) => {
          if (res.data.code === 0) {
            this.member = {
              memberGradeName: res.data.data.memberGradeName,
              number: res.data.data.number,
              memberId: res.data.data.id,
            };
            this.form.number = res.data.data.number;
            this.form.mobile = num;
            this.form.memberId = res.data.data.id;
            this.form.memberGradeName = res.data.data.memberGradeName;
            if (!this.form.agreementUnitName) {
              //没有协议单位
              this.form.clientType = "1";
              if (res.data.data.housePriceCodes.length != 0) {
                this.form.housePriceName =
                  res.data.data.housePriceCodes[0].name;
                this.form.housePriceId = res.data.data.housePriceCodes[0].id;
              }
            } else {
              this.form.clientType = "2";
            }

            // this.ruleForm.clientType = '1'
            this.userinfo.forEach((el) => {
              if (el.mobile == this.member.number) {
                el.memberGradeName = this.member.memberGradeName;
                (el.number = this.member.number),
                  (el.memberId = this.member.id);
              }
            });
            // this.housePriceButton(res.data.data.housePriceCodes)
            //       if(this.ruleForm.agreementUnitId){
            // 	this.ruleForm.clientType='2';
            // }
            // res.data.data.housePriceCodes.forEach((el, index) => {
            //   if(el.houseTypeId == this.orderFrom.houseTypeId) {
            //     this.ruleForm.roomRealityPrice = el.weekendPrice
            //     this.getDiscount()
            //   }
            // })
          }
        })
        .catch((err) => {
          this.member = {
            memberGradeName: res.data.data.memberGradeName,
            number: res.data.data.number,
            memberId: res.data.data.id,
          };
          this.form.number = res.data.data.number;
          this.form.mobile = num;
          this.form.memberId = res.data.data.id;
          this.form.memberGradeName = res.data.data.memberGradeName;
          if (!this.form.agreementUnitName) {
            //没有协议单位
            this.form.clientType = "1";
            if (res.data.data.housePriceCodes.length != 0) {
              this.form.housePriceName = res.data.data.housePriceCodes[0].name;
              this.form.housePriceId = res.data.data.housePriceCodes[0].id;
            }
          } else {
            this.form.clientType = "2";
          }

          // this.ruleForm.clientType = '1'
          this.userinfo.forEach((el) => {
            if (el.mobile == this.member.number) {
              el.memberGradeName = this.member.memberGradeName;
              (el.number = this.member.number), (el.memberId = this.member.id);
            }
          });
          // this.housePriceButton(res.data.data.housePriceCodes)
          //       if(this.ruleForm.agreementUnitId){
          // 	this.ruleForm.clientType='2';
          // }
          // res.data.data.housePriceCodes.forEach((el, index) => {
          //   if(el.houseTypeId == this.orderFrom.houseTypeId) {
          //     this.ruleForm.roomRealityPrice = el.weekendPrice
          //     this.getDiscount()
          //   }
          // })

          // }
        })
        .catch((err) => {
          if (err.errorMsg != "会员信息不存在" || err.msg != "会员信息不存在") {
          }
          this.member = {
            memberGradeName: null,
            number: null,
          };
        });
    },
    //查看协议单位的弹窗关闭时触发
    handleClose(done) {
      done();
    },
    //协议单位列表行点击触发
    oneClick(row) {
      this.clickFrom = { ...row };

      this.HousePriceCode.forEach((el) => {
        if (el.number == row.housePriceCodeNumber) {
          this.clickFrom = { ...el, Name: row.name };
        }
      });
    },
    //协议单位分页变化触发
    handleCurrentChange(val) {
      this.currentPage1 = val;
      this.inputChange();
    },
    handleSizeChange(val) {
      this.size = val;
      this.inputChange();
    },
    //协议单位分页列表查询
    RoomListInquired(model = {}) {
      const params = {
        current: this.currentPage1,
        size: this.size,
        model: {
          state: true,
          orgId: localStorage.getItem("orgid"),
          ...model,
        },
      };
      listBargainingUnit(params).then((res) => {
        if (res.data.code === 0) {
          console.log(res);
          this.BargainingTable = res.data.data.records;
          this.total = Number(res.data.data.total);
          this.clickFrom = {
            ...this.BargainingTable[0],
            Name: this.BargainingTable[0].name,
          };
        }
      });
    },

    //点击房间卡片触发效果
    radioCard(row) {
    
        if (!this.isImg.includes(row.id)) {
        this.isImg.push(row.id);
        row.roomRealityPrice = null;
        this.tableData.push(row);
        if(this.form.allPrice == 0 || this.form.allPrice) {

          this.allPriceChange(this.form.allPrice)
        }
      } else {
        this.isImg.forEach((el, index) => {
          if (el == row.id) {
            this.isImg.splice(index, 1);
            this.tableData.splice(index, 1);
          }
        });
      }
      
      
      console.log(this.tableData);
    },
    //统一房价
    allPriceChange(price) {
      if(!price) {
        if(price != 0) {
          this.inputState = false
          this.tableData.forEach((el,index) => {
          el.roomRealityPrice = 0;
          this.$set(this.tableData, index,{...el,roomRealityPrice: 0})
        });
        }
      }
      if(price != '0' && price) {
        
        if (Number(price)) {
          this.inputState = true
        this.tableData.forEach((el) => {
          el.roomRealityPrice = this.form.allPrice;
        });
        console.log(this.tableData);
      } else {
        this.$message({
          type: "error",
          message: "请输入纯数字",
        });
        this.form.allPrice = null;
        this.tableData.forEach((el) => {
          el.roomRealityPrice = this.form.allPrice;
        });
      }
      }
    
    },
    //点击侧边房型触发的事件
    lichange(num) {
      this.li_index = num;
    },
    //初始化把数据洗下
    createdHouseType() {
      this.houselist.forEach((el) => {
        el["room"] = [];
      });
      this.houselist.forEach((el) => {
        this.relevanTable.forEach((it) => {
          if (it.houseTypeId == el.id) {
            el["room"].push(it);
          }
        });
      });
      if (this.houselist[0].houseType != "全部") {
        this.houselist.unshift({
          houseType: "全部",
          room: [...this.relevanTable],
        });
      }

      console.log(this.houselist);
      console.log(this.relevanTable, "3123131");
    },
    //预离时间变动自动计算天数
    LeaveDate() {
      this.getDaysBetween(this.form.arrivalTime, this.form.predepartureTime);
    },
    getDaysBetween(date1, date2) {
      if (this.form.settlementType === "0") {
        //全天
        var startDate = Date.parse(date1);
        var endDate = Date.parse(date2);

        var days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000);
        if (Math.ceil(days)) {
          return (this.form.reserveDays = Math.ceil(days) + "天");
        } else {
          return (this.form.reserveDays = "1天");
        }
      } else if (this.form.settlementType === "1") {
        //钟点
        var startDate = Date.parse(date1);
        var endDate = Date.parse(date2);

        var days = (endDate - startDate) / (60 * 60 * 1000);
        if (Math.ceil(days)) {
          return (this.form.hourLength = Math.ceil(days) + "小时");
        } else {
          return (this.form.hourLength = "3小时");
        }
      }
    },
    //获取当前时间
    getNowDate(date, num) {
      var date1 = new Date(date);
      var hourLength = new Date(new Date(date).getTime() + 3 * 60 * 60 * 1000);
      var out = new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000);
      var sign2 = ":";
      var year2 = hourLength.getFullYear(); // 年
      var month2 = hourLength.getMonth() + 1; // 月
      var day2 = hourLength.getDate(); // 日
      var hour2 = hourLength.getHours(); // 时
      var minutes2 = hourLength.getMinutes(); // 分
      var seconds2 = hourLength.getSeconds(); //秒
      var year1 = out.getFullYear(); // 年
      var month1 = out.getMonth() + 1; // 月
      var day1 = out.getDate(); // 日
      var hour1 = out.getHours(); // 时
      var minutes1 = out.getMinutes(); // 分
      var seconds1 = out.getSeconds(); //秒
      var year = date1.getFullYear(); // 年
      var month = date1.getMonth() + 1; // 月
      var day = date1.getDate(); // 日
      var hour = date1.getHours(); // 时
      var minutes = date1.getMinutes(); // 分
      var seconds = date1.getSeconds(); //秒
      // 给一位数的数据前面加 “0”
      if (month2 >= 1 && month2 <= 9) {
        month2 = "0" + month2;
      }
      if (day2 >= 0 && day2 <= 9) {
        day2 = "0" + day2;
      }
      if (hour2 >= 0 && hour2 <= 9) {
        hour2 = "0" + hour2;
      }
      if (minutes2 >= 0 && minutes2 <= 9) {
        minutes2 = "0" + minutes2;
      }
      if (seconds2 >= 0 && seconds2 <= 9) {
        seconds2 = "0" + seconds2;
      }

      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (day >= 0 && day <= 9) {
        day = "0" + day;
      }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }
      if (month1 >= 1 && month1 <= 9) {
        month1 = "0" + month1;
      }
      if (day1 >= 0 && day1 <= 9) {
        day1 = "0" + day1;
      }
      if (hour1 >= 0 && hour1 <= 9) {
        hour1 = "0" + hour1;
      }
      if (minutes1 >= 0 && minutes1 <= 9) {
        minutes1 = "0" + minutes1;
      }
      if (seconds1 >= 0 && seconds1 <= 9) {
        seconds1 = "0" + seconds1;
      }

      if (num === 2) {
        this.form.predepartureTime =
          year +
          "-" +
          month +
          "-" +
          day +
          " " +
          hour +
          sign2 +
          minutes +
          sign2 +
          seconds;
      } else if (num === 3) {
        this.form.arrivalTime =
          year +
          "-" +
          month +
          "-" +
          day +
          " " +
          hour +
          sign2 +
          minutes +
          sign2 +
          seconds;
        this.form.predepartureTime =
          year2 +
          "-" +
          month2 +
          "-" +
          day2 +
          " " +
          hour2 +
          sign2 +
          minutes2 +
          sign2 +
          seconds2;
      } else {
        this.form.predepartureTime =
          year1 +
          "-" +
          month1 +
          "-" +
          day1 +
          " " +
          hour1 +
          sign2 +
          minutes1 +
          sign2 +
          seconds1;
        this.form.arrivalTime =
          year +
          "-" +
          month +
          "-" +
          day +
          " " +
          hour +
          sign2 +
          minutes +
          sign2 +
          seconds;
      }
    },
    //高度自适应
    autoHeight() {
      // 初始化的时候，设置高度
      this.setHeight();
      // 改变浏览器窗口大小的时候，设置高度
      window.addEventListener("resize", () => {
        this.setHeight();
      });
    },
    // 设置高度
    setHeight() {
      this.$nextTick(() => {
        if (this.$refs.roomlist) {
          let height = this.$refs.roomlist.offsetHeight;
          // console.log('元素高度',height);
          this.tableheight = height - 120; // 这里的X就是顶部栏的高度
          console.log(this.tableheight);
          // console.log('高度',this.tableheight)
          // document.getElementsByClassName("el-table__fixed-body-wrapper").style.max-height = "440px";
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.input-with-select {
  margin-bottom: 20px;
}
.searchButtonBox {
  width: 50px;
  height: 30px;
  margin-top: 5px;
  padding: 0px 15px;
  background-color: #5cb5b8;
  color: #fff;
  margin-left: -10px;
}
.searchNameBox {
  background-color: #5cb5b8;
  color: #fff;
}
::v-deep .el-dialog__header {
  // background-color: #e8fdfe;
  height: 40px;
  font-size: 16px;
  padding-bottom: 0px;
  margin-bottom: 10px;
}
::v-deep .el-dialog__body {
  padding-top: 0px;
  max-height: 600px;
  overflow-y: auto;
  box-sizing: border-box;
  // background-color: #e8fdfe;
  .SearchBargaining {
    width: 100%;
    height: 600px;
    // height: 660px;
  }
}
.el-form {
  // height: 200px;
  // overflow-y: auto;
  .el-form-item {
    margin-bottom: 15px;
    // margin-right: 10px;
    ::v-deep .el-input__inner {
      height: 30px;
      // margin-bottom: -10px;
    }
  }
}
.RelevanceCheck {
  max-height: 680px;
  overflow: hidden;
  .houseTypeCard {
    height: 250px;
    overflow: hidden;
    width: 100%;
    margin-bottom: 20px;
    .leftCardButton {
      display: flex;
      padding-top: 20px;
      box-sizing: border-box;
      justify-content: center;

      .leftLI {
        height: 250px;
        width: 150px;
        text-align: center;
        overflow-y: auto;
        li {
          width: 110px;
          height: 35px;
          text-align: center;
          line-height: 35px;
          margin-bottom: 15px;
          border-radius: 4px;
          background-color: #e4eff0;
          color: #666666;
          cursor: pointer;
        }
        li.active {
          background-color: #5cb5b8;
          color: #fff;
        }
      }
    }
    .rightCard {
      height: 250px;
      padding-top: 20px;
      box-sizing: border-box;
      // overflow-y: auto;
      .cartlist {
        width: 100%;
        height: 250px;
        padding-left: 35px;
        box-sizing: border-box;
        overflow-y: auto;
        //卡片部分css
        .change_cards {
          cursor: pointer;
          width: 130px;
          height: 80px;
          color: #999999;
          display: flex;
          float: left;
          font-size: 13px;
          margin-right: 20px;
          margin-bottom: 20px;
          border-radius: 4px;
          background-color: #8de098;
          position: relative;

          .change_Price {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding-left: 10px;
            box-sizing: border-box;
            justify-content: space-around;
          }

          img {
            opacity: 0;
            width: 24px;
            height: 24px;
            position: absolute;
            top: 7px;
            right: 7px;
          }
          img.active {
            opacity: 1;
          }
        }
      }
    }
  }
  .flootButton {
    // margin-top: 10px;
    width: 100%;
    height: 60px;
    // line-height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      color: #5cb5b8;
    }
    .buttonRight {
      width: 100%;
      // background-color: #8de098;
      margin-top: 10px;
      padding-right: 12px;
      height: 50px;
      line-height: 50px;
      box-sizing: border-box;
      .el-button {
        width: 110px;
        height: 35px;
        padding: 0 15px;
        float: right;
        margin-top: 10px;
        background-color: #5cb5b8;
        color: #fff;
        border-radius: 4px;
      }
      .el-button:first-child {
        background-color: #fff;
        color: #999999;
      }
    }
  }
}
.rightBox {
  max-height: 600px;
  overflow-y: auto;
  ul {
    width: 100%;
    height: 500px;
    li {
      height: 60px;

      span {
        margin-top: 10px;
        display: block;
      }
    }
    li:nth-child(1) {
      height: 20px;
    }
  }
}
</style>