import router from '../../router/index'
const loadView = (name) => {
  return () => import('_views/' + name)
}
export default {
  namespaced: true,
  state: {
    userInfo: localStorage.getItem('userInfo')
      ? JSON.parse(localStorage.getItem('userInfo'))
      : {},
    token: localStorage.getItem('token') || '',
    role: localStorage.getItem('role') || '',
    clientId: 'yunlv_web_pro',  //客户端id&秘钥
    clientSecret: 'yunlv_web_pro_secret', //客户端id&秘钥
    orderId: '',//订单id
    orderFrom: {},//订单信息
    pathRouth: '',
    DynamicPath: localStorage.getItem('DynamicPath')
    ? JSON.parse(localStorage.getItem('DynamicPath'))
    : [],//动态路由路径
    newrouter: localStorage.getItem('newrouter')
    ? JSON.parse(localStorage.getItem('newrouter'))
    : [],//前台的动态路由
    CommercialRouter: localStorage.getItem('CommercialRouter')
    ? JSON.parse(localStorage.getItem('CommercialRouter'))
    : [],//商超后台的路由
    SETTINGNAME: localStorage.getItem('ApplicationId'),//所点击对应的权限
    newAsaide: [],
    ClosingDateFrom: {},//餐饮后台的订单页的结账传的数据
    orgId: null,

  },
  mutations: {
    STATE_ORGID(state, data) {
      state.orgId = data
    },
    SETCLOSING(state, data){
      state.ClosingDateFrom = data

    },
    USER_LOGOUT (state) {
      // 退出登录
      localStorage.removeItem('userInfo')
      localStorage.removeItem('token')
      localStorage.removeItem('role')
      // 请求上次的navTab
      sessionStorage.removeItem('navTabs')
    },
    SetOrderId(state, orderFrom) {
      state.orderFrom = orderFrom
    },
    SET_PATH(state, data){
      state.pathRouth = data
    },
    //对数据进行处理
    DYNAMICPATH(state) {
     localStorage.removeItem('DynamicPath')
     localStorage.removeItem('ApplicationId')
     
    },
  
   
  }
}
