<template>
  <!-- 操作日志 -->
  <div class="operator" >
    <!-- 操作日志表格 -->
    <div class="OperatorTable"  ref="OperatorTablebox">
      <div class="operator_main">
        <!-- 头部标题部分 -->
        <div class="membership_title" ref='OperatorTableboxCAOZUO'>
          <p>操作日志</p>
          <i
            class="el-icon-close"
            style="font-size: 25px"
            @click="offOperator"
          ></i>
        </div>
        <!-- 头部标题部分 max-height="400px"-->

        <el-table
          border
          :height="tableheight"
		  :max-height="tableheight"
          :data="tableData"
          class="table_operator"
          style="width: 100%"
        >
          <el-table-column
            align="center"
            prop="orderId"
            label="单号"
            width="180"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="echoMap.createdBy"
            label="操作者"
            width="180"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="echoMap.roomId"
            label="房号"
            width="180"
          >
          </el-table-column>

          <el-table-column
            align="center"
            prop="createdTime"
            label="时间"
            width="180"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="operationContent"
            label="操作内容"
          >
            <!-- <template>
							<p><span>修改房态空</span>=> 维修, 22</p>
						</template> -->
          </el-table-column>
        </el-table>
        <div class="pagination_floot">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="param.current"
            :page-size="size"
            layout="total, prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 操作日志表格 -->
  </div>
</template>

<script>
import { roomlogView } from "@/api/housingSystem";
export default {
  name: "OperatorTable",
  // props: {
  //     roomInfo: {
  //         type: Object,
  //         required: true
  //     }
  // },
  data() {
    return {
      current: 1,
      size: 10,
      tableData: [],
      currentPage1: 5,
      isTable: false, //操作日志的弹窗
      param: {
        current: 1,
        size: 10,
        sort: "createdTime",
        model: {
          state: true,
          id: undefined,
        },
        extra: {},
      },
      total: 0,
      ids: null, 
      roomNumber: null,
	  tableheight:0,
    };
  },
  created() {
    // this.param.model.id=this.roomInfo.id;
    // this.getlist();
  },
  mounted() {
    this.autoHeight();
  },
  methods: {
    gettableid(id) {
      this.param.model.id = id;
    },
    getlist() {
      // var that = this;
      const params = {
        current: this.current,
        size: this.size,
        sort: "createdTime",
        model: {
          state: true,
          id: this.ids,
          // roomNumber: this.roomNumber
        },
        extra: {},
      }
      roomlogView(params).then((res) => {
        if (res.data.code == 0) {
          this.tableData.splice(0)
          this.tableData = res.data.data.records;
          // this.param.current = res.data.data.current;
          this.total = parseFloat(res.data.data.total) ;
          console.log(this.total,'adasdasdsasssssssssssssssssss');
        } else {
          this.$message.error("查询失败");
        }
        // this.offOperator();
        return false;
      });
    },
    //点击右上角图标关闭弹窗
    offOperator() {
      this.$emit("offOperator");
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.param.current = val;
      this.getlist();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.current = val
      this.getlist();
    },
	
	autoHeight() {
	  // 初始化的时候，设置高度
	  this.setHeight();
	  // 改变浏览器窗口大小的时候，设置高度
	  window.addEventListener("resize", () => {
	    this.setHeight();
	  });
	},
	// 设置高度
	setHeight() {
	  this.$nextTick(() => {
	    if (this.$refs.OperatorTablebox) {
	      setTimeout(() => {
	        let height = this.$refs.OperatorTablebox.offsetHeight;
			let height1= this.$refs.OperatorTableboxCAOZUO.offsetHeight;
	      // console.log('元素高度',height);
	      this.tableheight = height-height1-60; // 这里的X就是顶部栏的高度
		  console.log('高度',this.tableheight)
	      },300);
	    }
	  });
	},
	
  },
};
</script>

<style lang="scss" scoped>
.operator {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index:10;
  background-color: rgba(133, 106, 106, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  
  .OperatorTable {
    width: 1100px;
    // height: 500px;
    height: 650px;
    overflow-y: auto;
    background-color: #fff;
    padding: 0px 10px;
    box-sizing: border-box;
    .operator_main {
      width: 100%;
      height: 286px;
      // background: linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%);
      border-radius: 5px;

      //头部标题部分css
      .membership_title {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding-right: 10px;

        p {
          flex: 1;
          padding-left: 20px;
          box-sizing: border-box;
          text-align: left;
        }
      }

      .table_operator {
        margin-top: 15px;
      }

      .pagination_floot {
        text-align: right;
        width: 100%;
      }
    }
  }
}
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
          // width: 10px; /*滚动条宽度*/
          height: 10px; /*滚动条高度*/
        }
        /*定义滚动条轨道 内阴影+圆角*/
        ::v-deep .el-table__body-wrapper::-webkit-scrollbar-track {
          box-shadow: 0px 1px 3px #fff inset; /*滚动条的背景区域的内阴影*/
          border-radius: 10px; /*滚动条的背景区域的圆角*/
          background-color: #fff; /*滚动条的背景颜色*/
        }
        /*定义滑块 内阴影+圆角*/
        ::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
          box-shadow: 0px 1px 3px #aacde4 inset; /*滚动条的内阴影*/
          border-radius: 10px; /*滚动条的圆角*/
          background-color: #aacde4; /*滚动条的背景颜色*/
        }
        // ::v-deep .el-scrollbar__wrap::-webkit-scrollbar{
        //   width: 13px; 
        //   height: 13px;
        // }
		::v-deep .el-table__fixed-left{
			height:calc(100% - 13px);
      // width: calc(100% - 13px);
      
			overflow: hidden;
		}
   ::v-deep .el-table__body-wrapper {
  &::-webkit-scrollbar { // 滚动条高 解决行未对齐
    height: 10px;
    // width: 0px;
    // padding-right: 10px;
    // width: 10px;
  }
}
::v-deep .el-table__fixed-body-wrapper .el-table__body {
  padding-bottom: 10px; // 滚动条高度
  
}
</style>