import request from '_utils/request'
import qs from 'qs'
//商超后台
//商品分类分页列表
const findPageList = (params = {}) => (
    request.post ('/base/shopMerchType/findPage', params)
)
//修改商品分类
const findPageupdate = (params = {}) => (
    request.post('/base/shopMerchType/update', params,
    )
)

//添加商品分类
const findPageAdd = (params = {}) => (
    request.post ('/base/shopMerchType/add', params,
    )
)
//删除商品分类
const findPageDetele = (params = {}) => (
    request.delete('/base/shopMerchType/del/'+ params.id
   
    ) 
)
//根据分类id查看详情
const findPagedetails = (params = {}) => (
    request.get('/base/shopMerchType/details/'+  params.id
    )
)
//库存记录日志分页
const shopInventoryRecord = (params = {}) => (
    request.post ('/base/shopInventoryRecord/page/shopInventory', params,
    )
)
//入-出 库存
const shopInventoryRecordFrom = (params = {}) => (
    request.post ('/base/shopInventoryRecord/add/inventory', params,
    )
)
export{
    findPageList,
    findPageupdate,
    findPageDetele,
    findPagedetails,
    findPageAdd,
    shopInventoryRecord,
    shopInventoryRecordFrom
}