<template>
  <div class="common-aside" onselectstart="return false">
    <div class="logo">
		<!-- ../../../assets/icons/LOGO.png -->
      <img src="../../../assets/imgs/logo1.png" alt="" 
      class="img_log" @click=" iconButton ">
    </div>
    <el-menu
      :default-active="$route.path"
      class="el-menu-vertical-demo"
      :collapse="$store.state.base.navCollapse"
      router
      background-color="#FFFFFF"
      text-color="#999999"
      active-text-color="#5CB5B8"
    >
    <el-submenu 
    :index="el.name"
    v-if=" ids == '366448864552551303'"
    v-for="(el,index) in newrouter" :key="el.path"
    >
        <template slot="title">
          <!-- <i class="el-icon-location"></i> -->
          <span>{{ el.name }}</span>
        </template>
        <el-menu-item-group
        @click="imgActive(index)"
        >
          <el-menu-item :index="it.path" 
          v-for="(it, indexs) in el.metaArr" :key="indexs">{{ it.name }}</el-menu-item>
        </el-menu-item-group>
        <!-- <el-menu-item-group title="分组2">
          <el-menu-item index="1-3">选项3</el-menu-item>
        </el-menu-item-group>
        <el-submenu index="1-4">
          <template slot="title">选项4</template>
          <el-menu-item index="1-4-1">选项1</el-menu-item>
        </el-submenu> -->
      </el-submenu>
  <el-menu-item
  v-if=" ids != '366448864552551303'"
        v-for="(el,index) in newrouter" :key="el.path"
        @click="imgActive(index)"
        :index="el.path">
          <img :src="el.meta.icon" alt="" v-if="imgUrl[li_index] != el.path">
          <img :src="el.meta.activeIcon" alt="" v-else>
          <span slot="title">{{ el.name }}</span>
        </el-menu-item>
  </el-menu>
  <!-- require('../../../assets/icons/' + ) -->

   
            <div class="outsubButton" v-show="!$store.state.base.navCollapse">
              <el-button 
              class="OutLogin"
              @click="logOut"
              style="">
                退出
              </el-button>
              <!-- <img
              style="position: absolute; top: 13px;right: 30px;"
              src="../../../assets/icons/右箭头.png"
              alt=""
             
            class="logOut"
            /> -->
            </div>
            <!-- <el-col :span="24">
              <span>退出</span>
            </el-col> -->
       
  </div>
</template>

<script>
import { Loading} from 'element-ui';
var loading;
import{ anyUserlogout} from '_api/verify'
export default {
  name: 'CommonAside',
  data() {
    return{
      ids: localStorage.getItem('ApplicationId'),
      newrouter: this.$store.state.permission.Newrouter,
      CommercialRouter: this.$store.state.user.CommercialRouter,
      imgUrl: this.$store.state.permission.routerUrl,
      li_index: localStorage.getItem('li_index')?localStorage.getItem('li_index'):0,
    }
  },
  created() {
    console.log(this.$store.state.permission.Newrouter);
   this.Getnewrouter()
  },
  watch: {
    // id: {
    //   handler(newVal, oldVal) {
    //     if(newVal) {
    //       this.imgUrl = null
    //       this.imgUrl = localStorage.getItem('repath')
    //     }
    //   },
    //   immediate: true
    // }
  },
 
  methods: {
    imgActive(index) {
      setTimeout(() => {
  this.li_index = index
      localStorage.setItem("li_index", index)
      localStorage.removeItem('Path')
      },300)
    
    },
    //对路由数据进行二次处理
    Getnewrouter() {
      if(this.ids == '366448864552551303'){
        
        var arr = [
        {
          name: '首页',
          metaArr: []
          },
        {
          name: '门店管理',
          metaArr: []
          },
          {
          name: '财务管理',
          metaArr: []
          },
          {
          name: '会员管理',
          metaArr: []
          },
          {
            name: '用户反馈',
            metaArr: []
          }
         
      ]
     
      this.$store.state.permission.Newrouter.forEach(el => {
        console.log(el,'asdasdassdasdasd');
        arr.forEach(it => {
          if(el.meta.title == it.name) {
              it.metaArr.push(el)
          }
        })
        
        })
        this.newrouter.splice(0)
        this.newrouter = arr
      
      console.log(this.newrouter);
      console.log(this.$store.state.permission.Newrouter);
      console.log(arr);
      }
     
     
    },
      //退出登录
      logOut() {
        anyUserlogout({token: localStorage.getItem('token')}).then(res => {
          if(res.data.code == 0) {
            console.log("sdsa");
      localStorage.removeItem("token");
      localStorage.removeItem("orgid");
      localStorage.removeItem("TenantId");
      localStorage.removeItem("ApplicationId");
      localStorage.removeItem("DynamicPath");
      localStorage.removeItem("li_index");
      localStorage.removeItem("employeeId");
      this.$router.replace({ path: '/login'});
          }
        })
     
    },
    iconButton() {
      // localStorage.removeItem("token");
      // localStorage.removeItem("orgid");
      // localStorage.removeItem("TenantId");
      localStorage.removeItem("ApplicationId");
      localStorage.removeItem("DynamicPath");
      localStorage.removeItem("li_index");
      // localStorage.removeItem("employeeId");
     this.$store.commit('user/DYNAMICPATH')
      this.$router.push({path:'/systematic'})
      localStorage.removeItem('li_index')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-menu{
	border-right: none;
}
.outsubButton{
  width: 100%;
  position: absolute;
  text-align: center;
  bottom: 7%;
  .OutLogin{
  background: #F8FEFE;
  width: 90px;
  height: 35px;
  color: #5CB5B8;
  // margin-right: 30%;
  // position: relative;
  // text-align: left;
  padding: 0px 0px;
  box-sizing: border-box;
  border: 1px solid #E6F1F1;
  border-radius: 5px;
  // padding: 0px 0px;
}
}

.common-aside {
  height: 100%;
  position: relative;
  border-right: none;
 .el-row{
  position: absolute;
  bottom: 50px;
  left: 30%;
  .el-col{
    display: flex;
    justify-content: center;
    span{
      color: #fff;
      margin-top: 10px;
    }
  }

 }
  .logo {
    height: 100px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    .img_log{
      width: 90px;
      //height: 60px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 55px;
      text-align: center;
      padding-left: 10px;
      box-sizing: border-box;

    }
  
  }
  .el-menu-vertical-demo, .el-menu{
    width: 100%;
    box-sizing: border-box;
    ::v-deep .el-menu-item.is-active {
      background: linear-gradient(87deg, #FFFFFF 0%, #DDFDFE 100%) ;
    color: #5CB5B8 !important;
    box-sizing: border-box;
    border-right: 3px solid #5CB5B8 ;
  }
  ::v-deep .el-submenu__title {
    &:hover {
      background: linear-gradient(87deg, #FFFFFF 0%, #DDFDFE 100%) !important;
      box-sizing: border-box;
    }
  }
  .el-menu-item {
    &:hover {
      background: linear-gradient(87deg, #FFFFFF 0%, #DDFDFE 100%) !important;
      box-sizing: border-box;
 
    }
    img{
      width: 18px;
      height: 18px;
      margin-right: 10px;
      box-sizing: border-box;
    }
  }
  }
 
}
</style>
