import Vue from 'vue'
import VueRouter from 'vue-router'
import Admin from '_views/Admin'
import store from '../store'
import {
	isLogin,
	isApplicationId,
} from '../utils/index'


Vue.use(VueRouter)
console.log(store.state.user.DynamicPath, '122222222211111111111111');
var childrens = []
var num = null
var ids = localStorage.getItem('ApplicationId')
console.log(store.state.user.newrouter, '11111111111111111');

var paths = localStorage.getItem('repath')
console.log(store.state.user.newrouter, '111111112222222222211');
console.log('cccc', childrens)
const routes = [{
		path: '/',
		name: 'admin',
		component: Admin,
		meta: {
			noCache: true
		},
		children: [{
				path: '/',
				redirect: paths
			},

		]
	},
	{
		path: '/systematic',
		name: 'SystematicSelection',
		meta: {
			roles: ['a', 'b', 'c'],
			noCache: true
		},
		component: () => import('_views/SystematicSelection')
	},
	{
		path: '/login',
		name: 'login',
		meta: {
			roles: '*',
			noCache: true
		},
		component: () => import('_views/Login')
	},
	{
		path: '/screen', //大屏
		component: () => import('@/views/screen/screen'),
		hidden: false
	},
	
	// {
	//   path: '*',
	//   name: 'notFound',
	//   meta: {
	//     roles: '*'
	//   },
	//   component: () => import('_views/NotFound')
	// }
]
console.log(routes, '212222222222221111111111111');
const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

// 登录鉴权
router.beforeEach((to, from, next) => {
	console.log('本地数据',isApplicationId())
	console.log(to.path)
	if (to.path !== '/login') {
		// 判断是否登录
		if (isLogin()) {
			next()
			// if(isApplicationId()){
			// 	next('/systematic')
			// }else{
			// 	next()
			// }
		} else {
			// 没有登录
			// 去登录去
			next('/login')
		}
	} else {
		next()
		// if(isApplicationId()){
		// 	next('/systematic')
		// }else{
		// 	next()
		// }
	}

})
router.afterEach((to, from) => {
	console.log(to);
	console.log(from);
	if (to.path == '/login') {
		localStorage.removeItem('li_index')
	}
	store.commit('user/SET_PATH', to.path)
	
	if(!isApplicationId() && isLogin() && to.path != "/systematic"){
		// next('/systematic')
		router.replace("/systematic");
	}
	console.log(store.state.user.pathRouth);
})
router.onError((error) => {
	const pattern = /Loading chunk (\d)+ failed/g;
	const isChunkLoadFailed = error.message.match(pattern);
	if (isChunkLoadFailed) {
		// 用路由的replace方法，并没有相当于F5刷新页面，失败的js文件并没有从新请求，会导致一直尝试replace页面导致死循环，而用 location.reload 方法，相当于触发F5刷新页面，虽然用户体验上来说会有刷新加载察觉，但不会导致页面卡死及死循环，从而曲线救国解决该问题
		location.reload();
		// const targetPath = $router.history.pending.fullPath;
		// $router.replace(targetPath);
	}
})

export default router