<template>
  <!-- 更换房间页面 -->
  <div class="ChangeRoom" @click="close_page">
    <div class="changeRoom_main">
      <div class="information_content">
        <!-- 侧边栏部分 -->
        <div class="sidebar">
          <ul>
            <li
              v-for="item in newhouseTypeId"
              :key="item.id"
              :class="{ activate: tab_sideba == item.houseTypeId }"
              @click="switchoverTabs(item.houseTypeId)"
            >
              {{ item.echoMap.houseTypeId }}[{{ item.num }}]
            </li>
          </ul>
        </div>
        <!-- 侧边栏部分 -->

        <!-- 内容区域 -->
        <div class="order_main">
          <div class="change_main">
            <!-- 卡片区域 -->
            <div class="cartlist">
              <div
                v-for="el in AsiderHoomtype"
                :key="el.id"
                @click="radioCard(el)"
                :class="{ change_cards: true, active: isImg === el.id }"
              >
                <div class="change_Price">
                  <span>{{ el.roomNumber }}</span>
                  <span>
                    {{ el.echoMap.houseTypeId }}
                  </span>
                </div>
                <div class="room_price">
                  <span>￥{{ el.housePrice }}</span>
                  <img src="../../../../assets/icons/选择.png" alt="" />
                </div>
              </div>
              <!-- 卡片区域 -->
            </div>

            <!-- 图片 -->

            <!-- 换房原因填写 -->
            <div class="demo_Form">
              <span>换房原因：</span>
              <el-input
                v-model="ruleForm.remark"
                placeholder="请输入换房原因"
                style="width: 300px"
              >
              </el-input>
            </div>
            <!-- 换房原因填写 -->
          </div>
        </div>
        <!-- 内容区域 -->
      </div>
      <div class="form_submit">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          :inline="true"
          label-width="100px"
          class="demo-ruleForms"
        >
          <el-form-item label="新房号" prop="roomNumber">
            <el-input :disabled="true" v-model="ruleForm.roomNumber"></el-input>
          </el-form-item>

          <el-form-item label="新房价" prop="housePrice">
            <el-input   @change="priceChange(ruleForm.housePrice)" v-model="ruleForm.housePrice">
              <div slot="prefix" style="height: 100%;display: flex;align-items: center;justify-content: center;">
				  	<span>￥</span>
				  </div>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="submitForm">换房</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { RoomStatusList } from "_api/conditionOfHouse";
import { EditdataStatistics, userInfoGet } from "_api/orderFrom";
import { housingPriceExamine } from "_api/systemSetting";
import { BargainingUnitAll } from "_api/advanceOrder";
export default {
  name: "ChangeRoom",
  props: {
    id: {
      type: Object,
    },
  },
  data() {
    return {
      tab_sideba: 5,
      isImg: 0,
      ruleForm: {
        remark: "",
        roomNumber: "",
        housePrice: "",
        houseTypeId: 0,
        roomId: 0,
        discount: "",
        houseTypeName: null,
        reserveDays: null,
        hourLength: null,
        roomPrice: null,
      },
      isRoom: null,
      cardFrom: {},
      mobileNumberss: 0,
      orderFrom: {}, //房单订单信息
      roomFroms: {}, //办理入住传的数据
      roomPriceFrom: [], //会员和协议单位的房价码数据
      tabFrom: [],
      BargainingUnitList: [],
      Addnum: 0,
      newhouseTypeId: [],
      AsiderHoomtype: [],
      realHousePrice: 0,
      parentFrom: [],

      rules: {
        housePrice: [
          { required: true, message: "请输入实际价格", trigger: "blur" },
          {
            pattern:  /^0(\.[0-9]{1,2})*$|^[1-9]\d{0,7}(\.\d{1,2})*$/, //正则校验不用字符串
            message: "请输数字,且长度最长为8位整数加小数位最长2位",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.RoomStatusListed();
  },
  methods: {
    createdApi() {
      if (this.Addnum > 1) {
        this.RoomStatusListed();
      }
    },
    // //点击卡片选中对应房型下的房号
    radioCard(el) {
      console.log(el);
      this.cardFrom = el;
      console.log(this.id);
      this.roomFroms = el;
      this.isImg = el.id;
      this.ruleForm.roomNumber = el.roomNumber;
      this.ruleForm.roomName = el.roomNumber;
      this.ruleForm.roomId = el.id
      this.ruleForm.houseTypeId = el.houseTypeId
      this.ruleForm.houseTypeName = el.echoMap.houseTypeId
      if(this.id.settlementType === "0") {
        if (this.id.housePriceId) {
        if (this.id.clientType == 1) {
          //会员
          this.userInfoGeted(el);
        } else if (this.id.clientType == 2) {
          //协议单位
          this.BargainingUnitAlls(el);
        }
      } else {
        this.ruleForm.housePrice = el.housePrice;
        this.ruleForm.roomPrice = el.housePrice;
        this.realHousePrice = el.housePrice;
        this.ruleForm.text = el.remark;
      }
      }else if(this.id.settlementType === "1") {
        //钟点房只改动钟点价，不考虑房价码和钟点时长
        this.ruleForm.housePrice = el.hourPrice;
        this.ruleForm.roomPrice = el.hourPrice;
        this.realHousePrice = el.hourPrice;
        this.ruleForm.text = el.remark;
      }
    
    },
    //协议单位查看
    BargainingUnitAlls(row) {
      BargainingUnitAll().then((res) => {
        if (res.data.code === 0) {
          this.BargainingUnitList = res.data.data;
          res.data.data.forEach((el) => {
            if (this.id.agreementUnitId == el.id) {
              if (this.id.settlementType === "0") {
                //全天房
                //判断有没有房价码
                if (el.housePriceCodeList) {
                  if (el.housePriceCodeList.length > 0) {
                    el.housePriceCodeList.forEach((item) => {
                      var currTime = new Date().getDay();
                  if (item.houseTypeName == row.echoMap.houseTypeId) {
                    if(currTime == 0 || currTime == 6) {
                      //是周牛周日
                      this.ruleForm.roomRealityPrice = item.weekendPrice; //暂时不考虑周末价格
                      this.ruleForm.housePrice = item.normalPrice
                      this.ruleForm.roomPrice = item.normalPrice
                      this.realHousePrice = item.normalPrice
                    }else{
                      this.ruleForm.roomRealityPrice = item.normalPrice; //暂时不考虑周末价格
                      this.ruleForm.housePrice =  item.normalPrice
                      this.ruleForm.roomPrice = item.normalPrice
                      this.realHousePrice = item.normalPrice
                      console.log(item.normalPrice);
                    }
                    // this.ruleForm.roomRealityPrice = item.normalPrice;
                    this.getDiscount(this.ruleForm.roomRealityPrice,this.ruleForm.roomPrice)

                  }
                });
                  }else{
                    this.ruleForm.housePrice =  this.cardFrom.housePrice;
        this.realHousePrice = this.cardFrom.housePrice;
                    this.ruleForm.text = this.ruleForm.remark;
        this.getDiscount(this.realHousePrice,this.ruleForm.roomPrice)
                  }
                } else{
                  this.ruleForm.housePrice =  this.cardFrom.housePrice;
        this.realHousePrice = this.cardFrom.housePrice;
                    this.ruleForm.text = this.ruleForm.remark;
        this.getDiscount(this.realHousePrice,this.realHousePrice)
                }
              } 
            }
          });
        } else {
          this.$message(res.data.errorMsg);
        }
      });
    },
    //根据手机号查询数据
    userInfoGeted(row) {
      const params = {
        mobile: this.id.mobile,
      };

      userInfoGet(params)
        .then((res) => {
          if (res.data.code === 0) {
            //房价码
            this.mobileNumberss = 2;
            if (this.id.settlementType == "0") {
              //全天房的房价码
              if (res.data.data.housePriceCodes.length > 0) {
                res.data.data.housePriceCodes.forEach((el, index) => {
                  if (el.houseTypeId == row.houseTypeId) {
                    var currTime = new Date().getDay();
                    console.log(currTime);
                    if (currTime == 0 || currTime == 6) {
                      //是周牛周日
                      this.ruleForm.housePrice = el.weekendPrice; //暂时不考虑周末价格
                      this.realHousePrice = el.weekendPrice;
                      this.ruleForm.roomPrice = el.weekendPrice;
                    } else {
                      this.ruleForm.housePrice = el.normalPrice; //暂时不考虑周末价格
                      this.ruleForm.roomPrice = el.normalPrice;
                      this.realHousePrice = el.normalPrice;
                    }
                    this.ruleForm.text = this.ruleForm.remark;
                  }
                });
                this.getDiscount(this.realHousePrice,this.ruleForm.roomPrice)
              } else {
                this.ruleForm.housePrice =  this.cardFrom.housePrice;
                this.ruleForm.roomPrice = this.cardFrom.housePrice;
                this.realHousePrice = this.cardFrom.housePrice;
                this.ruleForm.text = this.cardFrom.remark;
                this.getDiscount(this.realHousePrice,this.ruleForm.roomPrice)
              }
            }
          }
        })
        .catch((err) => {
          if (this.mobileNumberss != 2) {
           if (this.id.settlementType == "0") {
              //全天房
              this.ruleForm.housePrice =  this.cardFrom.housePrice;
              this.realHousePrice = this.cardFrom.housePrice;
              this.ruleForm.text = this.cardFrom.remark;
              this.getDiscount(this.realHousePrice,this.ruleForm.roomPrice)
            }
          } else {
            this.mobileNumberss = 0;
          }
        });
    },
    //计算折扣率 real:实际价，rack：门市价
    // getDiscountRate(real, rack) {
    //     return this.ruleFrom.discount = parseInt(real/rack)
    // },
    priceChange(row) {
      if(row) {
        this.getDiscount(row,this.ruleForm.roomPrice)
      }
    },
    //计算折扣率discount
    getDiscount(newPrice,oldPrice) {
      if (
        parseFloat(
          (
            parseFloat(newPrice) /
            parseFloat(oldPrice)
          ).toFixed(2)
        )
      ) {
        this.ruleForm.discount =
          parseFloat(
            (
              parseFloat(newPrice) /
              parseFloat(oldPrice)
            ).toFixed(2)
          ) *
            100 +
          "%";
        let arr = this.ruleForm.discount.split("");
        let index = arr.indexOf(".") + 2;
        if (arr.length - index > 4) {
          arr.splice(arr.indexOf(".") + 3);
          arr = arr.join("") + "%";
          console.log(arr);
          this.ruleForm.discount = arr;
        }
        console.log(arr, index);
        console.log(arr.length - index > 2);
      } else {
        this.ruleForm.discount = "0%";
      }

      console.log(this.ruleForm.discount);
    },
    //更换房间/订单更新接口
    EditdataStatisticsed() {
      const params = {
        houseTypeId: this.ruleForm.houseTypeId,
        discount: parseFloat(this.ruleForm.discount) ,
        roomPrice:  this.realHousePrice,
        houseTypeName: this.ruleForm.houseTypeName,
        roomRealityPrice: parseFloat(this.ruleForm.housePrice) ,
        roomId: this.ruleForm.roomId,
        roomName: this.ruleForm.roomName,
        remark: this.ruleForm.remark,
        id: this.id.id,
      };
      EditdataStatistics(params).then((res) => {
        if (res.data.code === 0) {
          this.tabFrom.splice(0);

          this.$message({
            type: "success",
            message: "换房成功",
          });
          this.$emit("offChangeRoom", [
            false,
            "submit",
            {
              roomPrice: this.realHousePrice,
              discount: parseFloat(this.ruleForm.discount),
              roomRealityPrice: this.ruleForm.housePrice, //实际价格
			  roomName: this.ruleForm.roomName,
            },
          ]);
        }
      });
    },

    //房态列表接口
    RoomStatusListed() {
      const params = {
        orgId: localStorage.getItem("orgid"),
        statusList: ["1_3"],
      };
      RoomStatusList(params).then((res) => {
        if (res.data.code === 0) {
          // res.data.data.forEach((el) => {
          //     el.
          // })
          this.tabFrom = res.data.data;
          this.houseTypeSetNumber(this.tabFrom);
          this.switchoverTabs(this.newhouseTypeId[0].houseTypeId);
        }
      });
    },

    //对房型进行去重和计数
    houseTypeSetNumber(arr) {
      //房型筛选去重
      var newArr = [];
      var numArr = [];
      var obj = {};
      var uum = 0;
      arr.forEach((i) => {
        newArr.some((item) => item.houseTypeId == i.houseTypeId)
          ? ""
          : newArr.push(i);
      });

      for (let i = 0; i < newArr.length; i++) {
        for (let j = 0; j < arr.length; j++) {
          if (arr[j].houseTypeId === newArr[i].houseTypeId) {
            uum = ++uum;
            (obj.houseTypeId = newArr[i].houseTypeId),
              (obj.echoMap = {
                houseTypeId: arr[j].echoMap.houseTypeId,
              }),
              (obj.num = uum);
          }
        }

        uum = 0;
        numArr.push(obj);
        obj = {};
      }
      this.newhouseTypeId = numArr;
    },
    //点击侧边栏切换不同的房型数据
    switchoverTabs(id) {
      this.tab_sideba = id;
      this.AsiderHoomtype = [];
      this.tabFrom.forEach((el) => {
        if (el.houseTypeId === id) {
          this.AsiderHoomtype.push(el);
        }
      });
      console.log(this.AsiderHoomtype);
    },
    // 点击右上角的图标隐藏弹窗
    close_page(e) {
      if (e.target.className === "el-icon-close") {
        this.$emit("offChangeRoom", [false, "icon"]);
      }
      // this.$emit("offChangeRoom")
    },
    //点击提交表单进行验证
    submitForm(formName) {
      if (this.isRoom) {
        console.log("211111111112222222222", this.roomFroms);
        this.$emit("ROOMEMIT", this.roomFroms);
        this.isRoom = null;
      } else {
        this.EditdataStatisticsed();
      }
    },
    
  },
};
</script>

<style lang="scss" scoped>
.ChangeRoom {
  width: 100%;
  height: 640px;
  overflow: hidden;
  background-color: #fff;

  .changeRoom_main {
    width: 100%;
    height: 199px;
    // background: linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%);
    border-radius: 5px;

    // 标题部分css
    .order_title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 40px;

      .title_icon {
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 50px;
      }

      p {
        color: black;
        flex: 1;
        text-align: left;
        padding-left: 20px;
        box-sizing: border-box;
      }
    }

    .information_content {
      display: flex;
      width: 100%;
      padding-right: 20px;
      padding-top: 20px;
      box-sizing: border-box;

      // 侧边栏css
      .sidebar {
        width: 150px;
        max-height: 500px;
        overflow-y: auto;
        ul {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;

          li {
            width: 140px;
            height: 35px;
            border-radius: 8px;
            line-height: 35px;
            background-color: #eefefe;
            color: #5cb5b8;
            text-align: center;
            font-size: 14px;
            margin-bottom: 15px;
          }

          li.activate {
            background-color: #5cb5b8;
            color: #fff;
          }
        }
      }

      .order_main {
        flex: 1;

        .change_main {
          width: 100%;
          height: 500px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .cartlist {
            width: 100%;
            height: 500px;
            padding-left: 10px;
            box-sizing: border-box;
            overflow-y: auto;
            //卡片部分css
            .change_cards {
              width: 180px;
              height: 100px;
              color: #999999;
              display: flex;
              float: left;
              font-size: 15px;
              margin-right: 10px;
              margin-bottom: 10px;
              border-radius: 4px;
              background-color: #f3f3f3;

              .change_Price {
                width: 50%;
                display: flex;
                flex-direction: column;
                padding-left: 10px;
                box-sizing: border-box;
                justify-content: space-around;
              }

              .room_price {
                flex: 1;
                padding: 20px 10px 0 0;
                box-sizing: border-box;
                text-align: right;

                img {
                  display: none;
                }
              }
            }
          }

          .change_cards.active {
            background-color: #d7f5f5;
            color: #5cb5b8;

            .room_price {
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              padding: 10px 10px 0 0;
              box-sizing: border-box;
              text-align: right;
              position: relative;

              img {
                display: block;
                position: absolute;
                bottom: 15px;
                right: 15px;
              }
            }
          }

          img {
            width: 20px;
            height: 20px;
          }

          .demo_Form {
            text-align: right;
            padding-bottom: 10px;
            padding-right: 32px;
            margin-top: 20px;
            box-sizing: border-box;
            .el-form-item {
            }
          }
        }
      }
    }

    //最后确认房间号和价格，在提交区域css
    .form_submit {
      width: 100%;
      height: 70px;

      .demo-ruleForms {
        width: 100%;
        height: 70px;
        padding-top: 10px;
        text-align: right;
        box-sizing: border-box;
        padding-right: 10px;
        .el-form-item {
          margin-right: 30px;
          .el-button {
            width: 100px;
            height: 40px;
            border-radius: 6px;
            background-color: #5cb5b8;
            color: #fff;
          }
          .el-form-item:last-child {
            margin-right: 0px;
            margin-left: 20px;
          }
        }
        ::v-deep .el-input__inner {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          // 设置输入字体的颜色
          color: #db1212;
        }
      }
    }
  }
}
</style>