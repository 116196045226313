<template>
  <!-- 添加入帐 -->
  <div class="Addenter">
    <div class="addEnter_main">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="入账金额:" prop="price">
          <el-input
            clearable
            v-model="ruleForm.price"
            placeholder="请输入入账金额"
          ></el-input>
        </el-form-item>
        <el-form-item label="入账项目:" prop="value">
          <el-cascader
            :clearable="true"
            v-model="ruleForm.value"
            style="width: 100%"
            @change="handleChange"
            :options="options"
            :show-all-levels="false"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="挂账单位"  v-if="isGua" prop="agreementUnitId" :rules="{
              required: isGua?true:false, message: '协议单位不能为空', trigger: 'blur'
            }"> 
          <el-select
          @change="ButtonagreementUnitId(ruleForm.agreementUnitId)"
          v-model="ruleForm.agreementUnitId" placeholder="请选择挂账单位">
            <el-option
                v-for="el in BargainingUnitList"
                :key="el.id"
                :label="el.name"
                :value="el.id"
              ></el-option>
                </el-select>
        </el-form-item>
        <el-form-item label="入账备注" prop="remark">
          <el-input
            clearable
            v-model="ruleForm.remark"
            placeholder="备注输入内容不超过50字"
          ></el-input>
        </el-form-item>
        <el-form-item label="凭证号">
          <el-input
            :clearable="true"
            v-model="ruleForm.voucherNo"
            placeholder="请输入凭证号"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="enterButton">
        <el-button @click="subMitButton('ruleForm')" type="primary">
          确认入账
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { AddFinance, paymentList, bargainingUnitList } from "_api/orderFrom";
import { UnitBargainingLook } from "_api/systemSetting";
import { BargainingUnitAll } from "_api/advanceOrder";
export default {
  name: "Addenter",
  data() {
    return {
      ruleForm: {
        itemType: "0", //项目类型 0付款 1消费
        operator: "", //操作人
        orderId: "", //关联订单
        voucherNo: "", //凭证号
        bankNumber: "",
        authorizationCode: "",
        price: "",
        remark: "",
        paymentId: "", //付款方式id
        projectName: "", //项目名称
        projectId: "", //项目Id
        value: [],
      },
      orderFrom: { ...this.$store.state.user.orderFrom },
      rules: {
        price: [
          { required: true, message: "请输入入账价金额", trigger: "blur" },
          {
            pattern: /^(([0-9]|([1-9][0-9]{0,9}))((\.[0-9]{1,2})?))$/,
            message: "整数位最多十位，小数为最多为两位，可以无小数位",
            trigger: "blur",
          },
        ],
        value: [{ required: true, message: "请选择入账项目", trigger: "blur" }],
      },
      bargainingUnit: [], //消费项目数据
      payment: [], //付款方式数据
      restaurants: [],
      currentRoom: {}, //点击进来的订单信息
      state1: "",
      current: 1,
      size: 10,
      isGua: false,
      isProject: false, //控制入账项目的下拉框的显示隐藏
      isPay: false,
      isDis: false,
      Enternum: 0,
      BargainingUnitList: [],
      options: [
        {
          value: "0",
          label: "付款方式",
          children: [],
        },
        {
          value: "1",
          label: "消费项目",
          children: [],
        },
      ],
    };
  },
  created() {
    this.paymentListed();
    this.UnitBargainingLooked();
    this.CreatedENterData();
  },
  methods: {
    //预定转入住时传过来的订单信息
    CreatedENterData() {
      let arr = this.$store.state.base.AdvanceAddEnter;
      this.Enternum = 0;

      arr[0].forEach((items) => {
        if (items.isMasterRoom) {
          this.Enternum = 1;
          this.currentRoom = { ...items };
          console.log(this.currentRoom, "是联房");
        }
      });
      if (this.Enternum == 0) {
        this.currentRoom = { ...arr[0][0] };
        console.log(this.currentRoom, "不是联房");
      }
      console.log(arr);
    },
    //选协议单位时触发
    ButtonagreementUnitId(id) {
      this.BargainingUnitList.forEach(el => {
        if(el.id == id) {
          this.ruleForm.agreementName = el.name
        }
      })
    },
    //协议单位查看
    BargainingUnitAlls() {
      BargainingUnitAll().then((res) => {
        if (res.data.code === 0) {
          this.BargainingUnitList = res.data.data;
        } else {
          this.$message('系统繁忙');
        }
      });
    },
    createdButton() {
      // this.bargainingUnitListed()
      this.ruleForm = {
        itemType: "0", //项目类型 0付款 1消费
        operator: null, //操作人
        orderId: null, //关联订单
        voucherNo: null, //凭证号
        bankNumber: null,
        authorizationCode: null,
        price: null,
        remark: null,
        paymentId: null, //付款方式id
        paymentName: null, //付款方式名称
        projectName: null, //项目名称
        projectId: null, //项目Id
        value: [],
        agreementUnitId: null,
        agreementName: null,
      };
      this.isPay = false;
      this.isDis = false;
    },
    //入账项目值变动触发
    handleChange(val) {
      console.log(val,this.ruleForm.value);
      var Guaid = ''
      var xieyiId = ''
      this.payment.forEach(el => {
        if(el.name == '协议挂账') {
          xieyiId = el.id
        }
        
      })
	  this.bargainingUnit.forEach(el => {
        if(el.name == '协议挂账') {
          xieyiId = el.id
        }
        
      })
      if(val[0] == 0) {
        if( val[1] == xieyiId) {
          // if(this.ruleForm.price)
          this.BargainingUnitAlls()
          this.isGua = true
        }else{
          this.isGua = false
        }
      }else{
		  this.isGua = false
	  }

    },
    paymentBox(num) {
      if (num === 1) {
        if (this.ruleForm.projectId != "") {
          this.bargainingUnit.forEach((el) => {
            if (el.id == this.ruleForm.projectId) {
              this.ruleForm.projectName = el.name;
            }
          });
          this.isPay = true;
          this.ruleForm.itemType = "1";
        } else {
          this.isPay = false;
        }
      } else if (num == 2) {
        if (this.ruleForm.paymentId) {
          this.payment.forEach((el) => {
            if (el.id == this.ruleForm.paymentId) {
              this.ruleForm.projectName = el.name;
            }
          });
          this.isDis = true;
          this.ruleForm.itemType = "0";
        } else {
          this.isDis = false;
        }
      }
    },

    //付款方式的查看
    paymentListed() {
      paymentList().then((res) => {
        if (res.data.code === 0) {
          this.payment = res.data.data.filter(el => el.name != '挂账' &&el.name != '微储值' && el.name != '挂房账' && el.name != '餐饮挂账' && el.name != '商超挂账' && el.name != '预授权' );
          console.log(this.payment);
          this.payment.forEach((el) => {
            this.options[0].children.push({
              value: el.id,
              label: el.name,
            });
            console.log(this.options, "1212212121221");
          });
        } else {
          this.$message({
            type: "error",
            message: "请求异常",
            onClose: () => {},
          });
        }
      });
    },

    //消费项目的请求
    UnitBargainingLooked() {
      UnitBargainingLook().then((res) => {
        if (res.data.code === 0) {
          this.bargainingUnit = res.data.data;
          this.bargainingUnit.forEach((el) => {
            this.options[1].children.push({
              value: el.id,
              label: el.name,
            });
          });
        } else {
          this.$message({
            type: "error",
            message: "请求异常",
            onClose: () => {},
          });
        }
      });
    },
    houtyId() {
      //   if(this.value[0] == '0') {
      //     //付款
      //     this.payment.forEach((item) => {
      //     if (this.value[1] === item.id) {
      //       this.ruleForm.paymentName = item.name;
      //     }
      //   });
      //   } else if(this.value[1] == '1') {
      //     //消费
      //     this.bargainingUnit.forEach((el) => {
      //     if (this.value[1] == el.id) {
      //       this.ruleForm.projectName = el.name;
      //     }
      //   });
      //   }
      if (this.options[parseFloat(this.ruleForm.value[0])].value == "0") {
        //付款

        let arr = this.options[parseFloat(this.ruleForm.value[0])].children;
        arr.forEach((el) => {
          if (el.value == this.ruleForm.value[1]) {
            this.ruleForm.projectName = el.label;
          }
        });
        console.log(this.ruleForm.projectName, "付款");
      } else if (
        this.options[parseFloat(this.ruleForm.value[0])].value == "1"
      ) {
        //消费
        let arr = this.options[parseFloat(this.ruleForm.value[0])].children;
        arr.forEach((el) => {
          if (el.value == this.ruleForm.value[1]) {
            this.ruleForm.projectName = el.label;
          }
        });
        console.log(this.ruleForm.projectName, "消费");
      }
    },

    //财务管理表之新增接口
    AddFinanceed() {
      this.houtyId();
      console.log("数据", this.currentRoom);
      const params = {
        price: parseFloat(this.ruleForm.price),
        orderId: this.currentRoom.id,
        authorizationCode: this.currentRoom.authorizationCode,
        bankNumber: this.currentRoom.bankNumber,
        itemType: this.ruleForm.value[0],
        state: true,
        agreementName: this.ruleForm.agreementName,
        agreementUnitId: this.ruleForm.agreementUnitId,
        roomId: this.currentRoom.roomId,
        roomName: this.currentRoom.roomName, //房型名称
        userClassesId: localStorage.getItem("userClassesId"),
        operator: localStorage.getItem("name"),
        paymentId: this.ruleForm.value[1],
        projectName: this.ruleForm.projectName,
        type: "0",
        classesId: localStorage.getItem("classesId"),
        createdName: localStorage.getItem("name"),
        classes: localStorage.getItem("classes"),
        voucherNo: this.ruleForm.voucherNo,
        remarks: this.ruleForm.remark,
      };
	  if(params.projectName!='协议挂账'){
		params.agreementName = null
		params.agreementUnitId = null
	  }
      AddFinance(params).then((res) => {
        if (res.data.code === 0) {
          this.$emit("AddenterOff", false);
          this.$message({
            type: "success",
            message: "入账成功",
          });
        }
      });
    },
    //确定入账
    subMitButton(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          console.log("sadadqadasdasdasa");
          // if (this.ruleForm.projectName ) {
          //   this.AddFinanceed();
          // } else {
          //   this.$message({
          //     type: "error",
          //     message: "请输入完整信息,消费和付款请选其一",
          //   });
          // }
          this.AddFinanceed();
        } else {
          console.log("错了");
          this.$message({
            type: "error",
            message: "请按要求输入信息",
          });
          return false;
        }
      });
    },
  },
};
</script>
<style>
</style>
<style lang="scss" scoped>
.Addenter {
  width: 100%;
  // height: 280px;
  margin-top: -25px;
  // overflow-y: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  // overflow-y: auto;
  // background: linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%);

  .addEnter_main {
    width: 100%;
    // height: 186px;
    // background: linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%);
    border-radius: 5px;

    .demo-ruleForm {
      width: 100%;
      .el-form-item {
        width: 100%;
        .el-select {
          width: 100%;
          .el-option {
            width: 100%;
            .paymentFrom {
              width: 100%;
              .fkfs {
                width: 100%;
                height: 300px;
                p {
                  width: 100%;
                  text-align: left;
                }
                .payment_method {
                  width: 100%;
                  ul {
                    width: 100%;
                    li {
                      width: 110px;
                      height: 40px;
                      border-radius: 7px;
                      border: 1px solid gray;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    //确认提交按钮css
    .enterButton {
      width: 100%;
      height: 60px;
      // background-color: bisque;
      text-align: center;
      // line-height: 60px;
      .el-button {
        width: 100px;
        height: 40px;
        background-color: #5cb5b8;
        border-radius: 6px;
      }
    }
  }
  .addProject {
    width: 100%;
    height: 170px;
    overflow-y: auto;
    position: absolute;
    padding-top: 15px;
    box-sizing: border-box;
    top: 160px;
    left: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid rgb(228, 222, 222);

    .paymentMethod {
      width: 100%;
      margin-bottom: 20px;
      ul {
        width: 100%;
        li {
          width: 40px;
          height: 15px;
          font-size: 13px;
          float: left;
          text-align: center;
          margin-top: 10px;
          margin-right: 5px;
          border: 1px solid gray;
        }
      }

      p {
        width: 100%;
        height: 25px;
        text-align: left;
        line-height: 25px;
        font-size: 13px;
        border-bottom: 1px solid rgb(228, 222, 222);
      }
    }
  }
}
</style>