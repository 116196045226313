<template>
  <!-- 打印票据弹窗 -->
  <div class="PrintBill">
    <div class="titleName">
      <span>{{ orgName }}</span>
    </div>
    <div class="cashierBox">
      <span class="personBox">收银：</span><span>{{ this.$store.state.user.orderFrom.echoMap.createdBy }}</span>
    </div>
    <div class="cashierBox">
      <span class="personBox">房间：</span>
      <span>{{ this.$store.state.user.orderFrom.roomName }}</span>
    </div>
    <div class="cashierBox">
      <span class="personBox">结账时间：</span>
      <span class="TimeDate">{{ this.$store.state.user.orderFrom.outRoomTime }}</span>
    </div>
    <div class="financeBox">
      <el-row>
        <el-col :span="7" style="text-align: center;margin-top: 10px;">项目</el-col>
        <el-col :span="7" style="text-align: center;margin-top: 10px;">数量</el-col>
        <el-col :span="7" style="text-align: center;margin-top: 10px;">金额</el-col>
      </el-row>
      <br>
      <div
      class="navTable"
      v-for="(el, index) in totalPriceFrom" :key="index" style="width: 100%">
        <el-col :span="7" style="text-align: center;margin-top: 10px;">{{
          el.name
        }}</el-col>
        <el-col :span="7" style="text-align: center;margin-top: 10px;">{{ el.number }}</el-col>
        <el-col :span="7" style="text-align: center;margin-top: 10px;">{{ el.totalPrice }}</el-col>
      </div>
     
    </div>
    <!-- <br>
    <br> -->
    <div class="cashierBox" style="margin-top: 10px;">
        <span class="personBox">消费合计: {{ totalPrice }}</span>
    </div>
    
    <div class="financeBox" style="width: 100%">
      <el-row>
        <el-col :span="7" style="text-align: center;margin-top: 10px;">项目</el-col>
        <el-col :span="7" style="text-align: center;margin-top: 10px;">数量</el-col>
        <el-col :span="7" style="text-align: center;margin-top: 10px;">金额</el-col>
      </el-row>
      <br>
      <div 
      class="navTable"
      v-for="(el, index) in paymentPriceFrom" 
      :key="index" style="width: 100%">
        <el-col :span="7" style="text-align: center;margin-top: 10px;">{{
          el.name
        }}</el-col>
        <el-col :span="7" style="text-align: center;margin-top: 10px;">{{ el.number }}</el-col>
        <el-col :span="7" style="text-align: center;margin-top: 10px;">{{ el.totalPrice }}</el-col>
      </div>
     
    </div> 
    <!-- <br><br><br><br> -->
    <div class="cashierBox" style="margin-top: 10px;">
      <span class="personBox">付款合计: {{paymentPrice  }}</span>
    </div>
    <div class="cashierBox">
      <span class="personBox">签字:</span>
    </div>
    <div class="submitButton">
      <el-button @click="printertextreceipted">打印票据</el-button>
    </div>
    
  </div>
</template>

<script>
import { printertextreceipt } from "_api/verify";
import { ListFinance,financeManagementConnectIist } from "_api/orderFrom";
export default {
  name: "PrintBill",
  data() {
    return {
      orgName: localStorage.getItem("orgName"),
      ruleFrom: {}, //订单信息
      tableData: [],
      projectDetails: [],
      totalPrice: 0,//消费合计
      totalPriceFrom: [],
      paymentPrice: 0,//付款合计
      paymentPriceFrom: [],
      name: '',
      type: '',//0付款1消费
    };
  },
  created() {
    this.createdData();
  },
  mounted() {
    console.log(this.$store.state.user.orderFrom);
  },
  methods: {
    createdData() {
      this.ruleFrom = this.$store.state.user.orderFrom;
    },
    //打印小票接口
    printertextreceipted() {
      const params = {
        createdName: this.$store.state.user.orderFrom.echoMap.createdBy,
        name: localStorage.getItem("orgName"),
        orderId: this.$store.state.user.orderFrom.id,
        paymentPrice: this.paymentPrice,
        projectDetails: [
          // {
          //   date: "11.11",
          //   name: "房费",
          //   number: "1",
          //   price: "",
          //   totalPrice: "60",
          //   type: "0",
          // },
          ...this.projectDetails
      
        ],
        roomNumber: this.$store.state.user.orderFrom.roomName,
        settleAccountsDate: this.$store.state.user.orderFrom.outRoomTime,
        tableName: "",
        totalPrice: this.totalPrice,
        type: "1",
      };
      params.paymentPrice = 0//付款合计
      params.totalPrice = 0//消费合计
      this.projectDetails.forEach(el => {
        if(el.type == 1) {
          //消费
          params.totalPrice = parseFloat(params.totalPrice) + parseFloat(el.totalPrice)
        }else if(el.type == 0) {
          //付款
          params.paymentPrice = parseFloat(params.paymentPrice) + parseFloat(el.totalPrice)
        }
      })
      console.log(params,'12121');
      console.log(this.projectDetails);
      printertextreceipt(params).then((res) => {
        if(res.data.code === 0) {
          this.$message({
            type: 'success',
            message: '小票打印成功'
          })
          this.$emit('PrintBosss')
        }
      });
    },
    //财务分页列表查询
    ListFinanceed(model = {}) {
      const params = {
        current: 1,
        size: 100000,
        model: {
          orderId: this.$store.state.user.orderFrom.id,
          operator: localStorage.getItem("classesId"),
          ...model,
        },
      };
      ListFinance(params).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.data.records.filter((el) => {
            return el.updateState == "2";
          });
          var priceArr = []
          var Arr = []
          this.tableData.forEach((el) => {
              priceArr.push(el.paymentId)
          });
          priceArr = [...new Set(priceArr)]
          priceArr.forEach(is => {
            var nums = 0
          var totalPrice = 0
            this.tableData.forEach(el => {
              if(is == el.paymentId) {
                nums = parseFloat(nums) + parseFloat(el.quantity)
                totalPrice = parseFloat(totalPrice) + parseFloat(el.price)
                this.name = el.projectName
                this.type = el.itemType 
              }
            })
            // this.projectDetails.splice(0)
            this.projectDetails.push({
              name: this.name,
              number: nums,
              totalPrice: parseFloat(totalPrice),
              type: this.type,
            });
            if(this.type == 1) {
              //消费
              this.totalPriceFrom.push({
                name: this.name,
              number: nums,
              totalPrice: parseFloat(totalPrice),
              type: this.type,
              })
            }else if(this.type == 0) {
              this.paymentPriceFrom.push({
                name: this.name,
              number: nums,
              totalPrice: parseFloat(totalPrice),
              type: this.type,
              })
            }
          })
          console.log(this.projectDetails);
          this.tableData.forEach(item => {
            if(item.itemType == 0) {
              //付款
              this.paymentPrice = parseFloat(this.paymentPrice) + (parseFloat(item.quantity) * parseFloat(item.price))
            }else if(item.itemType == 1) {
              //消费totalPrice
              this.totalPrice = parseFloat(this.totalPrice) + (parseFloat(item.quantity) * parseFloat(item.price))
            }
          })
        }
      });

      // console.log(this.$store.state.user.orderFrom);
    },
 //联房财务查看接口
 financeManagementConnectIisted() {
      const params = {
        type: false,
        connectRoomId: this.ruleFrom.connectRoomId,
      };
      financeManagementConnectIist(params).then((res) => {
        if (res.data.code == 0) {
          this.tableData.splice(0)
          this.tableData = res.data.data.filter((el) => {
            return el.updateState == "2";
          });
          var priceArr = []
          var Arr = []
          this.tableData.forEach((el) => {
              priceArr.push(el.paymentId)
          });
          priceArr = [...new Set(priceArr)]
          priceArr.forEach(is => {
            var nums = 0
          var totalPrice = 0
            this.tableData.forEach(el => {
              if(is == el.paymentId) {
				if(el.preAuth){
					if(!el.authOver){
						nums = parseFloat(nums) + parseFloat(el.quantity);
						totalPrice = parseFloat(totalPrice) + parseFloat(el.price);
						this.name = el.projectName;
						this.type = el.itemType;
					}
				}else{
					nums = parseFloat(nums) + parseFloat(el.quantity);
					totalPrice = parseFloat(totalPrice) + parseFloat(el.price);
					this.name = el.projectName;
					this.type = el.itemType;
				}
              }
            })
            // this.projectDetails.splice(0)
            this.projectDetails.push({
              name: this.name,
              number: nums,
              totalPrice: parseFloat(totalPrice),
              type: this.type,
            });
            if(this.type == 1) {
              //消费
              this.totalPriceFrom.push({
                name: this.name,
              number: nums,
              totalPrice: parseFloat(totalPrice),
              type: this.type,
              })
            }else if(this.type == 0) {
              this.paymentPriceFrom.push({
                name: this.name,
              number: nums,
              totalPrice: parseFloat(totalPrice),
              type: this.type,
              })
            }
          })
          console.log(this.projectDetails);
          this.tableData.forEach(item => {
            if(item.itemType == 0) {
              //付款
              this.paymentPrice = parseFloat(this.paymentPrice) + (parseFloat(item.quantity) * parseFloat(item.price))
            }else if(item.itemType == 1) {
              //消费totalPrice
              this.totalPrice = parseFloat(this.totalPrice) + (parseFloat(item.quantity) * parseFloat(item.price))
            }
          })


        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
 .personBox{
      font-weight: bolder;
    }
.PrintBill {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 450px;
  overflow-y: auto;
  .titleName{
    text-align: center;
    font-size: 18px;
  }
  .cashierBox{
    width: 100%;
    text-align: left;
    font-size: 14px;
    margin-bottom: 10px;
   .TimeDate{
    margin-top: 10px;
   }
  }
  .financeBox{
    margin-top: 10px;
    border-top: 1px dashed gray;
    padding-top: 10px;
    box-sizing: border-box;
  }
  .submitButton{
    text-align: center;
    margin-bottom: -10px;
    .el-button{
      background-color: #5cb5b8;
    color: #fff;
    }
   
  }
}
</style>