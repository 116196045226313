<template>
  <div class="print">
    <el-dialog
      title="客房打印单"
      :visible.sync="visibleRef"
      width="1000px"
      :before-close="close"
      append-to-body
    >
      <div class="print-main">
        <div class="print-title">
          {{ orderInfo.orgName }} - {{ orderType === 1 ? "入住单" : "结账单" }}
        </div>
        <div class="mb-20">
          <span class="mr-50 print-label"
            >打印时间：{{ orderInfo.printTime }}</span
          >
          <span class="print-label">操作人：{{ orderInfo.operator }}</span>
        </div>
        <el-form inline>
          <el-row>
            <el-col :span="8">
              <el-form-item label="客人姓名:">
                <el-input v-model="customerInfo.name" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="电话:">
                <el-input v-model="customerInfo.mobile" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="证件号码:">
                <el-input v-model="customerInfo.idCard" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-table
          class="mb-10"
          ref="orderTableRef"
          :data="orderInfoList"
          @selection-change="computeMoney"
          row-key="id"
        >
          <el-table-column label="订单信息">
            <el-table-column type="selection"></el-table-column>
            <el-table-column prop="houseTypeName" label="房型名称-房间号">
              <template slot-scope="{ row }">
                <div>{{ row.houseTypeName + "-" + row.roomName }}</div>
              </template>
            </el-table-column>
            <el-table-column label="入住信息" width="400">
              <template slot-scope="{ row }">
                <div>
                  {{
                    `${row.arrivalTime} ~ ${row.predepartureTime}，${
                      row.orderState ? "已入住" : "已退房"
                    }`
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="间夜" prop="reserveDays"></el-table-column>
            <el-table-column label="房费/间" prop="roomRealityPrice">
              <template slot-scope="{ row }">
                <el-input-number
                  size="small"
                  :value="row.roomRealityPrice"
                  :controls="false"
                  @change="
                    (value) => {
                      row.roomRealityPrice = value;
                    }
                  "
                  :precision="2"
                ></el-input-number>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
        <el-table
          class="mb-10"
          ref="otherPayRef"
          :data="otherPayList"
          @selection-change="computeMoney"
          row-key="id"
        >
          <el-table-column label="消费明细">
            <el-table-column type="selection"></el-table-column>
            <el-table-column
              label="项目名称"
              prop="projectName"
            ></el-table-column>
            <el-table-column label="数量" prop="quantity"></el-table-column>
            <el-table-column label="金额" prop="price">
              <template slot-scope="{ row }">
                <el-input-number
                  size="small"
                  :value="row.price"
                  :controls="false"
				  @blur="computeMoney"
                  @change="
                    (value) => {
                      row.price = value;
                    }
                  "
                  :precision="2"
                ></el-input-number>
              </template>
            </el-table-column>
			<el-table-column label="备注" prop="remarks">
				<template slot-scope="{ row }">
				  <el-input
				    size="small"
				    v-model="row.remarks"
				    ></el-input>
				</template>
			</el-table-column>
            <el-table-column
              label="消费时间"
              prop="createdTime"
            ></el-table-column>
          </el-table-column>
        </el-table>
        <div class="print-label my-20">
          订单金额：应收：{{ orderInfo.receiveMoney }}元，实收：{{
            orderInfo.actualReceiveMoney
          }}元，{{
            orderType === 2 ? "待支付：" + orderInfo.waitPay + "，" : ""
          }}已付押金：{{ orderInfo.deposit }}元
        </div>
        <div class="mb-20">
          <el-input v-model="orderInfo.remark" />
        </div>
        <div class="flex items-center mb-20">
          <span class="print-label">客栈地址：</span>
          <el-input v-model="customerInfo.address" />
        </div>
        <div>
          <span class="print-label">客人签字：</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="handlePreview">预览</el-button>
      </span>
    </el-dialog>
    <PrintOrderPreview ref="previewRef" :orderType="orderType" />
  </div>
</template>

<script>
import {
  orderFromList,
  financeManagementConnectIist,
  ListFinance,
} from "@/api/orderFrom";
import dayjs from "dayjs";
import PrintOrderPreview from "./PrintOrderPreview.vue";

export default {
  name: "PrintOrderModal",
  components: {
    PrintOrderPreview,
  },
  props: ["roomId", "connectRoomId"],
  data() {
    return {
      visibleRef: false,
      orderInfo: {
        orgName: localStorage.getItem("orgName") || "",
        printTime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        operator: "",
        remark: "",
        receiveMoney: 0,
        actualReceiveMoney: 0,
        deposit: 0,
        waitPay: 0,
      },
      customerInfo: {
        name: "",
        mobile: "",
        idCard: "",
        address: "",
      },
      orderInfoList: [],
      otherPayList: [],
      orderType: 1,
    };
  },
  methods: {
    computeMoney() {
	  console.log('变化')
      const selectedOrderList = this.$refs.orderTableRef?.selection || [];
      // const selectedOrderList = [];
      const selectedOtherPayList = this.$refs.otherPayRef?.selection || [];
	  console.log('选中数据',selectedOrderList)
	  console.log('选中数据1',selectedOtherPayList)
      const orderMoneyTotal = selectedOrderList.reduce((prev, cur) => {
        return prev + parseInt(cur.reserveDays) * parseFloat(cur.roomRealityPrice);
      }, 0);

      let consumeTotal = 0,
        paymentTotal = 0,
        depositTotal = 0;
      selectedOtherPayList.forEach((item) => {
        if (item.itemType == "1") {
          consumeTotal += parseFloat(item.price);
        }
        if (item.itemType == "0") {
          paymentTotal += parseFloat(item.price);
        }
        if (item.projectName == "押金") {
          depositTotal += parseFloat(item.price);
        }
      });
	  if(selectedOtherPayList.length){
		  this.orderInfo.receiveMoney = (consumeTotal).toFixed(2);
	  }else{
		  this.orderInfo.receiveMoney = (orderMoneyTotal + consumeTotal).toFixed(2);
	  }
      this.orderInfo.actualReceiveMoney = paymentTotal.toFixed(2);
      this.orderInfo.deposit = depositTotal.toFixed(2);
      this.orderInfo.waitPay = (
        this.orderInfo.receiveMoney - this.orderInfo.actualReceiveMoney
      ).toFixed(2);
    },
    async open(orderType = 1,orderInfo) {
	  var that = this;
      this.orderType = orderType;
      this.visibleRef = true;
	  this.$refs.orderTableRef?.selection.splice(0);
	  this.$refs.otherPayRef?.selection.splice(0);
	  console.log('订单信息',orderInfo);
	  console.log('订单信息',this.connectRoomId);
      const res = await orderFromList({
        current: 1,
        model: orderInfo.connectRoomId
          ? {
              connectRoomId: orderInfo.connectRoomId,
            }
          : {
              id: this.$store.state.user.orderFrom.id,
              // roomId: this.roomId,
              // orderState: 1,
            },
        size: 50,
      });
      const orderList = res.data.data?.records || [];
      this.orderInfoList = orderList;
      let masterRoom = orderList.find((item) => item.isMasterRoom);
      if (!masterRoom && orderList.length) {
        masterRoom = orderList[0];
      }
      if (masterRoom) {
        this.orderInfo.operator = masterRoom.echoMap?.createdBy;
        this.orderInfo.remark = masterRoom.remark;

        this.customerInfo.name = masterRoom.guestName;
        this.customerInfo.mobile = masterRoom.guestMobile;
        this.customerInfo.idCard = masterRoom.idCard;
      }
	  console.log('打印数据',masterRoom)
      const api = orderInfo.connectRoomId
        ? financeManagementConnectIist
        : ListFinance;

      const params = orderInfo.connectRoomId
        ? {
            connectRoomId: orderInfo.connectRoomId,
            type: false,
          }
        : {
            current: 1,
            size: 100000,
            model: {
              orderId: this.$store.state.user.orderFrom.id,
              operator: localStorage.getItem("classesId"),
            },
          };

      const payListRef = await api(params);
      // this.otherPayList =
      //   payListRef.data.data?.records || payListRef.data.data || [];
		var arr = payListRef.data.data?.records || payListRef.data.data || [];
		this.otherPayList.splice(0);
		var a = 1;
		var dayprice = {id:undefined,price:0,quantity:0};
		var houseprice = {id:undefined,price:0,quantity:0};
		arr.forEach(function(value){
			if(value.projectName!='钟点房费'&&value.projectName!='全日房费'){
				that.otherPayList.push(value);
			}else{
				if(value.projectName=='全日房费'){
					if(!dayprice.id){
						dayprice ={
							...value
						};
					}else{
						dayprice.quantity++;
						dayprice.price = parseFloat(dayprice.price) + parseFloat(value.price);
					}
				}else if(value.projectName=='钟点房费'){
					if(!houseprice.id){
						houseprice = {
							...value,
						}
					}else{
						houseprice.quantity++;
						houseprice.price = parseFloat(houseprice.price) + parseFloat(value.price);
					}
				}
			}
		});
		if(dayprice.id){
			that.otherPayList.push(dayprice);
		}
		if(houseprice.id){
			that.otherPayList.push(houseprice);
		}
    },
    close() {
      this.visibleRef = false;
    },
    handlePreview() {
      const selectedOrderList = this.$refs.orderTableRef?.selection || [];
      const selectedOtherPayList = this.$refs.otherPayRef?.selection || [];

      this.$refs.previewRef?.open(
        this.orderInfo,
        this.customerInfo,
        selectedOrderList,
        selectedOtherPayList
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.print {
  &-title {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  &-label {
    font-size: 18px;
    // color: #999;
    color: #000;
    white-space: nowrap;
  }
}
</style>
