<template>
  <!-- 续住弹窗 -->
  <div class="StayOver" @click="close_page">
    <div class="stayOver_main">
      <el-form
        :rules="rules"
        ref="formLabelAlign"
        :label-position="labelPosition"
        label-width="80px"
        inline-message
        :model="formLabelAlign"
        class="demo-ruleForms"
      >
        <el-form-item label="订单编号">
          <el-input :disabled="true" v-model="formLabelAlign.id"></el-input>
        </el-form-item>
        <el-row>
          <el-col :span="7" style="margin-right: 10px">
            <el-form-item
              style="width: 200px"
              label="订房单价"
              prop="paymentAmount"
            >
              <el-input clearable v-model="formLabelAlign.paymentAmount"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item prop="reserveDays">
              <div class="addnum">
                <img
                  @click="deleteNum"
                  src="../../../../assets/icons/减.png"
                  alt=""
                />
                <p>{{ formLabelAlign.reserveDays }}天</p>
                <img
                  @click="addNum"
                  src="../../../../assets/icons/加号2-fill.png"
                  alt=""
                />
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="入账项目" prop="region">
          <el-select
          clearable
            @change="paymentChange(formLabelAlign.paymentId)"
            v-model="formLabelAlign.paymentId"
            placeholder="请选择入账方式"
            style="width: 100%"
          >
            <el-option
              v-for="el in priceFrom"
              :key="el.id"
              :label="el.name"
              :value="el.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="挂账单位"
          v-if="this.formLabelAlign.paymentName == '协议挂账'"
        >
          <el-select
          style="width: 100%"
          clearable
            @change="ButtonagreementUnitId(formLabelAlign.agreementUnitId)"
            v-model="formLabelAlign.agreementUnitId"
            placeholder="请选择挂账单位"
          >
            <el-option
              v-for="el in BargainingUnitList"
              :key="el.id"
              :label="el.name"
              :value="el.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
          clearable
            v-model="formLabelAlign.remark"
            placeholder="请输入备注信息"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="stay_payment">
        <!-- <el-button>扫码支付</el-button>
        <el-button>储蓄卡</el-button> -->
        <el-button @click="submitForm">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  AddFinance,
  UpdateFinance,
  EditdataStatistics,
  paymentList,
} from "_api/orderFrom";
import { BargainingUnitAll } from "_api/advanceOrder";
export default {
  name: "StayOver",
  props: {
    ruleForm: {
      type: Object,
    },
  },
  data() {
    return {
      labelPosition: "right",
      formLabelAlign: {
        ...this.ruleForm,
        reserveDays: 1,
        paymentId: null,
        paymentName: null,
        paymentAmount: "",
        remark: "",
      },
      dateFrom: {},
      BargainingUnitList: [],
      priceFrom: [], //付款方式的数据
      predepartureTime: "", //预离时间
      reserveDays: 0, //预住天数
      rules: {
        name: [
          {
            required: true,
            message: "请确定房间价格",
            trigger: "blur",
          },
        ],
        region: [
          {
            required: true,
            message: "请填写入账方式",
            trigger: "blur",
          },
        ],
        reserveDays: [
          {
            required: true,
            message: "请输入整数",
            trigger: "blur",
          },
          {
            pattern: /^[0-9]*[1-9][0-9]*$/,
            message: "请输入整数",
            trigger: "blur",
          },
        ],
        paymentAmount: [
          { required: true, message: "请输入实际价格", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    // this.AddFinanced()
    this.addtimeday(1);
    // this.paymentListed()
  },
  mutations() {
    console.log(this.formLabelAlign);
  },
  methods: {
    //财务管理表之新增接口
    AddFinanceed() {
      const params = {
        agreementName: this.formLabelAlign.agreementUnitName,
        agreementUnitId: this.formLabelAlign.agreementUnitId,
        price: parseFloat(this.formLabelAlign.paymentAmount),
        orderId: this.dateFrom.id,
        authorizationCode: this.$store.state.user.orderFrom.authorizationCode,
        // bankNumber: this.$store.state.user.orderFrom.bankNumber,
        itemType: "0",
        state: true,
        roomId: this.dateFrom.roomId,
        roomName: this.dateFrom.roomName,
        userClassesId: localStorage.getItem("userClassesId"),
        operator: localStorage.getItem("name"),
        paymentId: this.formLabelAlign.paymentId,
        projectName: this.formLabelAlign.paymentName,
        type: "0",
        classesId: localStorage.getItem("classesId"),
        createdName: localStorage.getItem("name"),
        classes: localStorage.getItem("classes"),
        // voucherNo:this.ruleForm.voucherNo,
        remark: this.formLabelAlign.remark,
      };
      console.log(parseFloat(this.formLabelAlign.reserveDays));
      AddFinance(params).then((res) => {
        if (res.data.code === 0) {
          this.formLabelAlign.paymentId = null;
          this.$message({
            type: "success",
            message: "续住财务入账成功",
          });
        } else {
          this.$message({
            type: "error",
            message: "系统繁忙",
          });
        }
      });
    },
    //选择付款方式时触发
    paymentChange(id) {
      if(id) {
        this.priceFrom.forEach((el) => {
        if (el.id == id) {
          this.formLabelAlign.paymentName = el.name;
          
        }
      });
      console.log(this.formLabelAlign.paymentName);
      if(this.formLabelAlign.paymentName != '协议单位'){
        this.formLabelAlign.agreementUnitId = ''
        this.formLabelAlign.agreementUnitName = ''
          }
      }else{
        this.formLabelAlign.paymentName = ''
        this.formLabelAlign.agreementUnitId = ''
        this.formLabelAlign.agreementUnitName = ''
      }
      
    },
    ButtonagreementUnitId(id) {
      if(id) {
        this.BargainingUnitList.forEach((el) => {
        if (el.id == id) {
          this.formLabelAlign.agreementUnitName = el.name;
        }
      });
      }else{
        this.formLabelAlign.agreementUnitName = ''
      }
  
    },
    //付款方式查看接口
    paymentListed() {
      paymentList().then((res) => {
        if (res.data.code == 0) {
          this.priceFrom = res.data.data.filter(
            (el) =>
              el.name != "挂账" &&
              el.name != "微储值" &&
              el.name != "挂房账" &&
              el.name != "餐饮挂账" &&
              el.name != "商超挂账" &&
              el.name != "预授权"
          );
        }
      });
    },
    //date时间转yyyyMMddHHmmss
    formatDate(date) {
      //date为时间戳
      console.log(date);
      var date = new Date(date);
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + " " + h + m + s;
    },
    //计算预离时间 ，day为增加的天数
    addtimeday(day) {
      console.log(day);
      day = parseFloat(day);
      console.log(day);
      var dateTime = new Date(this.dateFrom.predepartureTime).getTime() + (parseFloat(day) *24*60*60*1000 );
      dateTime = new Date(dateTime);
      console.log("数据", dateTime);
      var day = this.formatDate(dateTime);
      console.log("天数", day);
      this.predepartureTime = day;
      this.addday(day);
    },
    //计算预住天数
    addday(day) {
      var end = new Date(day);
      var start = new Date(this.dateFrom.arrivalTime);
      var newDay = end - start;
      var D = parseFloat(newDay / (24 * 60 * 60 * 1000));
      this.reserveDays = Math.ceil(D);
      console.log(D);
    },

    //订单信息更新
    editorderinfo() {
      this.addtimeday(this.formLabelAlign.reserveDays)
      const params = {
        reserveDays: parseFloat(this.reserveDays),
        arrivalTime: this.formLabelAlign.predepartureTime,
        predepartureTime: this.predepartureTime,
        id: this.formLabelAlign.id,
        paymentId: this.formLabelAlign.paymentId,
        remark: this.formLabelAlign.remark,
        state: true,
        houseTypeName: this.formLabelAlign.houseTypeName,
        houseTypeId: this.formLabelAlign.houseTypeId,
      };
      console.log(params);
      EditdataStatistics(params).then((res) => {
        if (res.data.code === 0) {
          this.$message({
            type: "success",
            message: "续住成功",
          });
          this.AddFinanceed();
          this.$emit("offInquiry", [
            false,
            "确定",
            {
              reserveDays: this.reserveDays,
              predepartureTime: this.predepartureTime,
            },
          ]);
          this.formLabelAlign.reserveDays = 1;
        }
      });
      // .catch((err) => {
      //   this.$message({
      //     type: "error",
      //     message: "续住失败",
      //   });
      // });
    },
    //协议单位查看
    BargainingUnitAlls() {
      BargainingUnitAll().then((res) => {
        if (res.data.code === 0) {
          this.BargainingUnitList = res.data.data;
        } else {
          this.$message("系统繁忙");
        }
      });
    },
    //减少天数
    deleteNum() {
      if (this.formLabelAlign.reserveDays > 1) {
        this.formLabelAlign.reserveDays--;
        this.formLabelAlign.paymentAmount =
          parseFloat(this.formLabelAlign.reserveDays) *
          parseFloat(this.dateFrom.roomRealityPrice);
        this.addtimeday(this.formLabelAlign.reserveDays);
      } else {
        this.$message({
          type: "error",
          message: "续住天数最少为1天",
        });
      }
    },
    //增加天数
    addNum() {
      this.formLabelAlign.reserveDays++;
      this.addtimeday(this.formLabelAlign.reserveDays);
      this.formLabelAlign.paymentAmount =
        parseFloat(this.formLabelAlign.reserveDays) *
        parseFloat(this.dateFrom.roomRealityPrice);
    },
    // 点击右上角的图标关闭弹窗点击确认关闭
    close_page(e) {
      if (e.target.className === "el-icon-close") {
        this.$emit("offInquiry", [false]);
      } else if (e.target.innerText === "确定") {
        // this.$emit("offInquiry", [false, '确定'])
        // this.editorderinfo()
      } else if (e.target.innerText === "储蓄卡") {
        this.$emit("offInquiry", [false, "储蓄卡"]);
      } else if (e.target.innerText === "扫码支付") {
        this.$emit("offInquiry", [false, "扫码支付"]);
      }
    },
    //点击加减改变天数
    handleChange(value) {
      console.log(value);
    },
    //点击提交表单进行验证
    submitForm(formName) {
      if (this.formLabelAlign.paymentId) {
        this.editorderinfo();
      } else {
        this.$message({
          type: "error",
          message: "请选择入账项目",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.StayOver {
  width: 100%;
  // height: 280px;
  // overflow-y: auto;
  background-color: #fff;
  position: relative;

  .stayOver_main {
    width: 100%;
    // height: 316px;
    // background: linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%);
    border-radius: 5px;

    .demo-ruleForms {
      width: 100%;
      padding: 10px 20px 0px 20px;
      box-sizing: border-box;

      .addnum {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;

        img {
          width: 22px;
          height: 22px;
        }

        p {
          width: 196px;
          height: 40px;
          background: #ffffff;
          border: 1px solid #ededed;
          border-radius: 8px;
          font-size: 15px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #272727;
          text-align: center;
          margin-left: 10px;
          margin-right: 10px;
        }
      }
    }

    .stay_payment {
      width: 100%;
      // height: 70px;
      display: flex;
      justify-content: end;
      padding-right: 20px;
      margin-top: 10px;
      // align-items: center;
      box-sizing: border-box;

      // margin-top: 20px;
      .el-button {
        width: 100px;
        height: 40px;
        background: #ffffff;
        border: 1px solid #ededed;
        border-radius: 6px;
        color: #999999;
        padding: 0px 0px;
      }
      .el-button:last-child {
        background-color: #5cb5b8;
        color: #fff;
      }
    }
  }
}
</style>