const version = require('../package.json').version
const versionStorage = localStorage.getItem('version')
import Vue from "vue";
import { Base64 } from "js-base64";
import "element-ui/lib/theme-chalk/index.css";
import BaiduMap from "vue-baidu-map";
import "./flexible";
import './global.scss'
import {
  Aside,
  Header,
  Main,
  Container,
  Menu,
  Submenu,
  MenuItemGroup,
  MenuItem,
  Input,
  Avatar,
  Button,
  Badge,
  Row,
  Col,
  Table,
  TableColumn,
  pagination,
  RadioGroup,
  Form,
  FormItem,
  RadioButton,
  Option,
  Select,
  DatePicker,
  Switch,
  Radio,
  InputNumber,
  MessageBox,
  Message,
  Statistic,
  Autocomplete,
  Tooltip,
  Timeline,
  TimelineItem,
  Card,
  Dialog,
  Upload,
  TimeSelect,
  Checkbox,
  Cascader,
  DropdownMenu,
  Dropdown,
  Link,
  Tree,
  DropdownItem,
  Loading,
  Tabs,
  TabPane,
  Image,
  CheckboxGroup,
  CheckboxButton,
  Popover,
  Notification,
  Drawer,
  TimePicker, Empty,
} from "element-ui";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/reset.css";
import Print from "vue-print-nb";
import { VueJsonp } from 'vue-jsonp'
Vue.use(VueJsonp) // $jsonp被挂载到vue原型上,可直接使用vm.$jsonp()
// import {VueJsonp} from 'vue-jsonp'

// if(store.state.permission.Newrouter.length != 0) {
//   store.commit('permission/INIT_AUTH_ROUTES', store.state.permission.Newrouter)
// }
// console.customLog('最新版本', 'v' + version)
// console.customLog('缓存版本', 'v' + versionStorage)
// if (version != versionStorage) {
//   localStorage.clear()
//   localStorage.setItem('version', version)
//   console.customLog('版本不一致，清除缓存中')
//   //location.reload() 方法用来刷新当前页面。该方法只有一个参数，当值为 true 时，将强制浏览器从服务器加载页面资源，
//   //当值为 false 或者未传参时，浏览器则可能从缓存中读取页面。
//   setTimeout(() => {
//     window.location.reload(true)
//   }, 1000)
// }
const { alert, confirm, prompt } = MessageBox;
Vue.prototype.$alert = alert;
Vue.prototype.$confirm = confirm;
Vue.prototype.$prompt = prompt;
Vue.prototype.$message = Message;
Vue.prototype.$notify = Notification;
Vue.use(Print);
Vue.use(Container)
  .use(Aside)
  .use(Header)
  .use(Main)
  .use(Menu)
  .use(Submenu)
  .use(MenuItemGroup)
  .use(MenuItem)
  .use(Input)
  .use(Avatar)
  .use(Button)
  .use(Badge)
  .use(Row)
  .use(Col)
  .use(Table)
  .use(TableColumn)
  .use(pagination)
  .use(RadioGroup)
  .use(Form)
  .use(FormItem)
  .use(RadioButton)
  .use(Option)
  .use(Select)
  .use(DatePicker)
  .use(Switch)
  .use(Radio)
  .use(InputNumber)
  .use(Statistic)
  .use(Autocomplete)
  .use(Tooltip)
  .use(Timeline)
  .use(TimelineItem)
  .use(Card)
  .use(Dialog)
  .use(TimeSelect)
  .use(Checkbox)
  .use(Cascader)
  .use(Upload)
  .use(Link)
  .use(DropdownMenu)
  .use(Dropdown)
  .use(Tree)
  .use(DropdownItem)
  .use(Option)
  .use(Loading)
  .use(RadioGroup)
  .use(RadioButton)
  .use(Dropdown)
  .use(DropdownItem)
  .use(DropdownMenu)
  .use(Upload)
  .use(Tabs)
  .use(TabPane)
  .use(Image)
  .use(Popover)
  .use(TimePicker)
  // .use(Notification)
  .use(CheckboxGroup)
  .use(CheckboxButton)
  .use(Drawer)
  .use(Empty);

Vue.use(Base64);
// Vue.use(VueJsonp)
Vue.config.productionTip = false;
Vue.directive("title", {
  inserted: function(el, binding) {
    document.title = el.dataset.title;
  },
});

Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: "9yLbBU1ARqoc0GVGz0Ereml8B2baGzWK",
});
new Vue({
  router,
  store,
  created() {
    // 页面刷新时，取缓存，再次动态添加权限路由
    const ids = localStorage.getItem("ApplicationId");
    const authRoutes = localStorage.getItem("DynamicPath")
      ? JSON.parse(localStorage.getItem("DynamicPath"))
      : [];
    if (ids == "1") {
      //前台
      if (authRoutes.length != 0) {
        store.commit("permission/authNavs", []);
        store.commit("permission/authNavs", authRoutes);
        store.commit("permission/ASIDENAVS");
        const Newrouter = store.state.permission.Newrouter;
        store.commit("permission/INIT_AUTH_ROUTES", Newrouter);
      }
    } else if (ids == "4") {
      if (authRoutes.length != 0) {
        store.commit("permission/authNavs", []);
        store.commit("permission/authNavs", authRoutes);
        store.commit("permission/SET_COMMERCIAL");
        const Newrouter = store.state.permission.Newrouter;
        store.commit("permission/INIT_AUTH_ROUTES", Newrouter);
      }
    } else if (ids == "3") {
      if (authRoutes.length != 0) {
        store.commit("permission/authNavs", []);
        store.commit("permission/authNavs", authRoutes);
        store.commit("permission/SET_COMMERCIAL");
        const Newrouter = store.state.permission.Newrouter;
        store.commit("permission/INIT_AUTH_ROUTES", Newrouter);
      }
    } else if (ids == "366448864552551303") {
      //品牌端后台
      if (authRoutes.length != 0) {
        store.commit("permission/authNavs", []);
        store.commit("permission/authNavs", authRoutes);
        store.commit("permission/SETBRANDSIDE");
        const Newrouter = store.state.permission.Newrouter;
        store.commit("permission/INIT_AUTH_ROUTES", Newrouter);
      }
    }
  },
  render: (h) => h(App),
}).$mount("#app");

