<template>
  <div class="Maintain_box" @click="ClickMaintain">
    <div class="Maintain" >
      <div class="maintain_main">
        <!-- 头部标题部分 -->
       <!-- <div class="membership_title">
          <p>维修房登记</p>
          <i
            class="el-icon-close"
            style="font-size: 25px"
            @click="offOperator"
          ></i>
        </div> -->
        <!-- 头部标题部分 -->
        <!-- :rules="rules" -->
        <el-form
          :model="ruleForm"
          ref="ruleForm"
          :inline="true"
          label-width="80px"
          class="demo-ruleForm"
        >
          <el-form-item label="房号:" prop="roomName">
            <el-input
              style="width: 130px"
              v-model="ruleForm.roomName"
            ></el-input>
          </el-form-item>
          <el-form-item label="维修类型:" prop="maintenanceType">
            <el-select
              style="width: 170px"
              v-model="ruleForm.maintenanceType"
              placeholder="请选择维修类型"
            >
              <el-option label="坏房" :value="1"></el-option>
              <el-option label="维修房（可售）" :value="2"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="开始时间:">
            <el-date-picker
              style="width: 130px"
              value-format="yyyy-MM-dd"
              v-model="overheadSun"
              type="date"
              placeholder="选择日期"
              :disabled="true"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间:">
            <!-- <el-row>
                  <el-col :span="10"> -->
            <el-date-picker
              style="width: 130px"
              value-format="yyyy-MM-dd"
              v-model="ruleForm.endTime"
              type="date"
              :disabled="ruleForm.unlimitedDuration"
              placeholder="选择日期"
            >
            </el-date-picker>
            <!-- </el-col>
                  <el-col :span="10" :offset="4"> -->
            <el-checkbox
              style="margin-left: 10px"
              v-model="ruleForm.unlimitedDuration"
              >无限期</el-checkbox
            >
            <!-- </el-col>
                </el-row> -->
          </el-form-item>
          <el-form-item label="原因:">
			  <!-- 450 -->
            <el-input
              type="textarea"
              :rows="3"
              style="width: 420px"
              v-model="ruleForm.remark"
            >
            </el-input>
          </el-form-item>
        </el-form>

        <div class="form_item">
          <el-button
            style="background: #5cb5b8; border-radius: 4px; color: #fff"
            @click="submitForm('ruleForm')"
          >
            确认
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { roommaintenanceadd } from "@/api/housingSystem";
export default {
  name: "Maintain",
  props: {
    roomInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      ruleForm: {
        endTime: undefined,
        maintenanceType: undefined,
        orgId: undefined,
        remark: '',
        roomId: undefined,
        roomName: undefined,
        state: true,
        unlimitedDuration: false,
        version: 0,
      },
      isPull: false,
      rules: {
        name: [
          {
            required: true,
            message: "请输入活动名称",
            trigger: "blur",
          },
          {
            min: 3,
            max: 5,
            message: "长度在 3 到 5 个字符",
            trigger: "blur",
          },
        ],
        region: [
          {
            required: true,
            message: "请选择活动区域",
            trigger: "change",
          },
        ],
        date1: [
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        date2: [
          {
            type: "date",
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
        type: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个活动性质",
            trigger: "change",
          },
        ],
        resource: [
          {
            required: true,
            message: "请选择活动资源",
            trigger: "change",
          },
        ],
        desc: [
          {
            required: true,
            message: "请填写活动形式",
            trigger: "blur",
          },
        ],
      },
      overheadSun: "",
    };
  },
  created() {
    
  },
  methods: {
	datehandle(){
		this.initDate();
		this.ruleForm.roomName = this.roomInfo.roomNumber;
		this.ruleForm.roomId = this.roomInfo.id;
	},
    //当天日期
    mouseenter(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + m + "-" + d;
    },
    initDate() {
      this.overheadSun = this.mouseenter(new Date());
      var dateTime = new Date();
      dateTime = dateTime.setDate(dateTime.getDate() + 1);
      dateTime = new Date(dateTime);
      this.ruleForm.endTime = this.mouseenter(dateTime);
    },
    //点击维修结束按钮触发弹窗
    maintainFinish() {
      this.isPull = true;
    },
    //点击右上角图标关闭弹窗
    offOperator() {
      this.$emit("offMaintain", [false]);
    },
    ClickMaintain(e) {
      if (e.target.className === "Maintain_box") {
        this.$emit("offMaintain", [false]);
      }
    },
    submitForm(formName) {
      var that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          that.ruleForm.orgId = localStorage.getItem("orgid");
          console.log(that.ruleForm);
          roommaintenanceadd(that.ruleForm).then((res) => {
            if (res.data.code == 0) {
              that.$message({
				message: '操作成功！',
                type: "success",
                onClose: () => {
                  this.$emit("offMaintain", [false, "确认"]);
                },
              });
            } else {
              that.$message.error("提交失败");
            }
            that.offOperator();
            return false;
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.Maintain_box {
  // position: fixed;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
  // background-color: rgba(133, 106, 106, 0.4);
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // z-index: 100;
  .Maintain {
    
    padding-bottom: 20px;
    box-sizing: border-box;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    position: relative;

    .maintain_main {
      width: 100%;
      // background: linear-gradient(177deg, #e8fdfe 0%, #ffffff 100%);
      border-radius: 5px;

      .membership_title {
        width: 100%;
        height: 30px;
        display: flex;
        padding-top: 10px;
        align-items: center;
        box-sizing: border-box;
        margin-bottom: 10px;

        p {
          flex: 1;
          padding-left: 20px;
          box-sizing: border-box;
          text-align: left;
        }
      }

      .form_item {
        width: 100%;
        justify-content: center;
        padding-right: 10px;
        box-sizing: border-box;
        display: flex;

        .el-button {
          width: 110px;
          height: 40px;
          display: flex;
          justify-content: center;
          background: #ffffff;
          border-radius: 5px;
          color: #999999;
        }
      }
    }
  }
}
</style>