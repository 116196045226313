<template>
	<!-- 更换房间页面 -->
	<div class="ChangeRoom" @click="close_page">
		<div class="changeRoom_main">
			<div class="information_content">
				<!-- 侧边栏部分 -->
				<div class="sidebar">
					<ul>
						<!-- { activate: tab_sideba == item.houseTypeId } -->
						<li v-for="item in newhouseTypeId" :key="item.id"
							:class="tab_sideba == item.houseTypeId?'activate':''"
							@click="switchoverTabs(item.houseTypeId)">
							{{ item.echoMap.houseTypeId }}[{{ item.num }}]
						</li>

					</ul>
				</div>
				<!-- 侧边栏部分 -->

				<!-- 内容区域 -->
				<div class="order_main">
					<div class="change_main">
						<!-- 卡片区域 -->
						<div class="cartlist">
							<div v-for="el in AsiderHoomtype" :key="el.id" @click="radioCard(el)"
								:class="{ change_cards: true, active: isImg === el.id }">
								<div class="change_Price">
									<span>{{ el.roomNumber }}</span>
									<span>
										{{ el.echoMap.houseTypeId }}
									</span>
								</div>
								<div class="room_price">
									<span>￥{{ el.housePrice }}</span>
								</div>
							</div>
							<!-- 卡片区域 -->
						</div>

						<!-- 图片 -->

						<!-- 换房原因填写 -->
						<!-- <div class="demo_Form">
              <span>换房原因：</span>
              <el-input
                v-model="ruleForm.remark"
                placeholder="请输入换房原因"
                style="width: 300px"
              >
              </el-input>
            </div> -->
						<!-- 换房原因填写 -->
					</div>
				</div>
				<!-- 内容区域 -->
			</div>
			<div class="form_submit">
				<el-form :model="roomcard" :rules="rules" ref="roomcard" :inline="true" label-width="100px"
					class="demo-ruleForms">
					<el-form-item label="酒店标识:" prop="sign">
						<el-input :disabled="true" v-model="roomcard.sign"></el-input>
					</el-form-item>
					<!-- <el-form-item label="卡号:" prop="cardNo">
						<el-input :disabled="true" v-model="roomcard.cardNo"></el-input>
					</el-form-item> -->
					<el-form-item label="锁号:" prop="value1">
						<el-input :disabled="true" v-model="roomcard.lockBytes"></el-input>
					</el-form-item>
					<!-- <el-form-item label="客人代:" prop="dai">
						<el-input :disabled="true" v-model="roomcard.dai"></el-input>
					</el-form-item> -->
					<el-form-item label="退房时间:" prop="outtime">
						<el-date-picker
						  v-model="roomcard.outtime"
						  format="yyyy-MM-dd HH:mm"
						  type="datetime"
						  placeholder="选择日期时间"
						></el-date-picker>
					</el-form-item>
					<el-form-item label="是否可开反锁" prop="lLock">
					  <el-radio-group v-model="roomcard.lLock">
					    <el-radio :label="1">可以开反锁</el-radio>
					    <el-radio :label="0">不能开反锁</el-radio>
					  </el-radio-group>
					</el-form-item>
					<el-form-item>
						<el-button @click="submitForm">制卡</el-button>
						<el-button @click="carddata(2)">读卡</el-button>
						<el-button @click="carddata(3)" type="danger">销卡</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
		
		<!-- <el-dialog
		  title="制房卡"
		  :destroy-on-close="true"
		  :visible.sync="cardshow"
		  center
		  width="75%"
		> 
		<roomCard></roomCard>
		</el-dialog> -->
		<el-dialog
		  title="房卡数据"
		  :visible.sync="cardshow"
		  :modal='false'
		  class="card-zindex"
		  center
		  width="20%"
		> 
		<el-form  label-width="100px" class="demo-ruleForm">
		  <el-form-item label="房卡类型"  >
		    <!-- <el-input  v-model="cardinfo.cardTypeName" autocomplete="off"></el-input> -->
			{{cardinfo.cardTypeName}}
		  </el-form-item>
		  <el-form-item label="房卡数据"  >
		    <!-- <el-input  v-model="cardinfo.cardHexStr" autocomplete="off"></el-input> -->
			{{cardinfo.cardHexStr}}
		  </el-form-item>
		  <el-form-item label="离店时间"  >
		    <!-- <el-input v-model="checktime"></el-input> -->
			{{checktime}}
		  </el-form-item>
		</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import {
		RoomStatusList
	} from "_api/conditionOfHouse";
	import {
		EditdataStatistics
	} from "_api/orderFrom";
	import {gethotelinfo} from "_api/index"
	import roomCard from "./roomCard.vue"
	import axios from "axios";
	export default {
		name: "MakeCard",
		// props: {
		// 	id: {
		// 		type: String,
		// 	},
		// },
		components: {
		  roomCard,
		},
		data() {
			return {
				tab_sideba: 5,
				isImg: 0,
				ruleForm: {
					remark: "",
					roomNumber: "",
					housePrice: "",
					houseTypeId: 0,
					roomId: 0,
					discount: "",
					houseTypeName: null,
				},
				isRoom: null,
				roomFroms: {}, //办理入住传的数据
				tabFrom: [],
				Addnum: 0,
				newhouseTypeId: [],
				AsiderHoomtype: [],
				realHousePrice: 0,
				parentFrom: [],

				rules: {
					housePrice: [{
							required: true,
							message: "请输入实际价格",
							trigger: "blur"
						},
						{
							pattern: /^(([0-9]|([1-9][0-9]{0,9}))((\.[0-9]{1,2})?))$/,
							message: "整数位最多十位，小数为最多为两位，可以无小数位",
							trigger: "blur",
						},
					],
				},

				//制门卡
				roomcard: {
					sign: undefined,
					// sign: 132627,
					cardNo: 0,//卡号
					value1: undefined,
					value2: undefined,
					value3: undefined,
					value4: undefined,
					outtime: undefined, //退房时间
					dai: 0,//客人代
					lLock: 1, //可以开反锁
					cardHexStr: undefined,
					lockBytes:undefined,//锁号
					// lockBytes:'04526175',//锁号
				},
				roomrules: {
					sign: [{
						required: true,
						message: "请先设置酒店标识",
						trigger: "blur"
					}],
					cardNo: [{
							required: true,
							message: "请输入卡号",
							trigger: "blur"
						},
						{
							min: 0,
							max: 15,
							message: "长度在 0 到 15 个字符",
							trigger: "blur",
						},
					],
					// value1: [{
					// 		required: true,
					// 		message: "请输入栋号",
					// 		trigger: "blur"
					// 	},
					// 	{
					// 		type: "number",
					// 		min: 0,
					// 		max: 99,
					// 		message: "大小在0-99",
					// 		trigger: "blur",
					// 	},
					// ],
					// value2: [{
					// 		required: true,
					// 		message: "请输入层号",
					// 		trigger: "blur"
					// 	},
					// 	{
					// 		type: "number",
					// 		min: 0,
					// 		max: 99,
					// 		message: "大小在0-99",
					// 		trigger: "blur",
					// 	},
					// ],
					// value3: [{
					// 		required: true,
					// 		message: "请输入房间编号",
					// 		trigger: "blur"
					// 	},
					// 	{
					// 		type: "number",
					// 		min: 0,
					// 		max: 99,
					// 		message: "大小在0-99",
					// 		trigger: "blur",
					// 	},
					// ],
					// value4: [{
					// 		required: true,
					// 		message: "请输入第二房间编号",
					// 		trigger: "blur"
					// 	},
					// 	{
					// 		type: "number",
					// 		min: 0,
					// 		max: 99,
					// 		message: "大小在0-99",
					// 		trigger: "blur",
					// 	},
					// ],
					outtime: [{
						required: true,
						message: "请输入退房时间",
						trigger: "blur"
					}, ],
					dai: [{
						required: true,
						message: "请输入客人代",
						trigger: "blur"
					}],
				},
				cardshow:false,
				hotelinfo:{},
				checktime:undefined,
				cardinfo:{
					cardTypeName:undefined,
					cardHexStr:undefined,
				},
			};
		},
		created() {
			// this.RoomStatusListed();
			// this.gethotel();
		},
		methods: {
			gethotel(){
				console.log('orgid',localStorage.getItem("orgid"))
				gethotelinfo({id:localStorage.getItem("orgid")}).then((res) => {
					console.log('酒店数据',res);
					if(!res.data.data.dlsCoID){
						this.$message({
							message: "请联系品牌负责人设置该酒店房卡标识",
							type: "warning",
						});
						this.$emit('makeclose');//关闭弹窗
					}
					this.hotelinfo={
						...res.data.data
					};
					this.roomcard.sign=res.data.data.dlsCoID;
				});
			},
			createdApi() {
				if (this.Addnum > 1) {
					this.RoomStatusListed();
				}
			},
			// //点击卡片选中对应房型下的房号
			radioCard(el) {
				console.log(el);
				this.roomFroms = el;
				this.isImg = el.id;
				this.ruleForm.roomNumber = el.roomNumber;
				this.ruleForm.housePrice = "￥" + el.housePrice;
				this.realHousePrice = el.housePrice;
				this.ruleForm.houseTypeId = el.houseTypeId;
				this.ruleForm.roomId = el.id;
				this.ruleForm.text = el.remark;
				this.ruleForm.houseTypeName = el.echoMap.houseTypeId;
				this.roomcard.lockBytes=el.lockBytes;
				if(!el.lockBytes){
					this.$message({
						message: "请设置房间锁号",
						type: "warning",
					});
					return false;
				}
				
			},
			//计算折扣率 real:实际价，rack：门市价
			// getDiscountRate(real, rack) {
			//     return this.ruleFrom.discount = parseInt(real/rack)
			// },
			checkroominfo(houseTypeId,roomId){
				var that=this;
				this.tab_sideba = houseTypeId;
				const params = {
					orgId: localStorage.getItem("orgid"),
					statusList: [],//"1_3"
				};
				RoomStatusList(params).then((res) => {
					if (res.data.code === 0) {
						// res.data.data.forEach((el) => {
						//     el.
						// })
						this.tabFrom.splice(0);
						if(res.data.data){
							res.data.data.forEach(function(value){
								that.tabFrom.push(value);
							});
						}
						// this.tabFrom = res.data.data;
						this.houseTypeSetNumber(this.tabFrom);
						this.switchoverTabs(houseTypeId);
						this.AsiderHoomtype.forEach(function(value){
							if(value.id==roomId){
								that.radioCard(value);
							}
						});
					}
				});
				
				// this.$set(this,'tab_sideba',houseTypeId);
				// console.log('赋值房型',houseTypeId)
				// this.AsiderHoomtype.splice(0);
				// this.tabFrom.forEach((el) => {
				// 	if (el.houseTypeId === houseTypeId) {
				// 		this.AsiderHoomtype.push(el);
				// 	}
				// });
				// this.AsiderHoomtype.forEach(function(value){
				// 	if(value.id==roomId){
				// 		that.radioCard(value);
				// 	}
				// });
			},

			//房态列表接口
			RoomStatusListed() {
				const params = {
					orgId: localStorage.getItem("orgid"),
					statusList: [],//"1_3"
				};
				var that=this;
				RoomStatusList(params).then((res) => {
					if (res.data.code === 0) {
						// res.data.data.forEach((el) => {
						//     el.
						// })
						this.tabFrom.splice(0);
						if(res.data.data){
							res.data.data.forEach(function(value){
								that.tabFrom.push(value);
							});
						}
						// this.tabFrom = res.data.data;
						this.houseTypeSetNumber(this.tabFrom);
						this.switchoverTabs(this.newhouseTypeId[0].houseTypeId);
					}
				});
			},

			//对房型进行去重和计数
			houseTypeSetNumber(arr) {
				//房型筛选去重
				var that=this;
				var newArr = [];
				var numArr = [];
				var obj = {};
				var uum = 0;
				arr.forEach((i) => {
					newArr.some((item) => item.houseTypeId == i.houseTypeId) ?
						"" :
						newArr.push(i);
				});

				for (let i = 0; i < newArr.length; i++) {
					for (let j = 0; j < arr.length; j++) {
						if (arr[j].houseTypeId === newArr[i].houseTypeId) {
							uum = ++uum;
							(obj.houseTypeId = newArr[i].houseTypeId),
							(obj.echoMap = {
								houseTypeId: arr[j].echoMap.houseTypeId,
							}),
							(obj.num = uum);
						}
					}

					uum = 0;
					numArr.push(obj);
					obj = {};
				}
				this.newhouseTypeId.splice(0);
				numArr.forEach(function(item){
					that.newhouseTypeId.push(item);
				})
				// this.newhouseTypeId = numArr;
			},
			//点击侧边栏切换不同的房型数据
			switchoverTabs(id) {
				console.log('今日',id)
				this.tab_sideba = id;
				this.AsiderHoomtype.splice(0);
				this.tabFrom.forEach((el) => {
					if (el.houseTypeId === id) {
						this.AsiderHoomtype.push(el);
					}
				});
				console.log(this.AsiderHoomtype);
			},
			// 点击右上角的图标隐藏弹窗
			close_page(e) {
				if (e.target.className === "el-icon-close") {
					this.$emit("offChangeRoom", [false, "icon"]);
				}
			},
			//点击提交表单进行验证
			submitForm() {
				// this.cardshow=true;
				this.carddata(1);
				// this.makedoorcard();
			},
			formattedDate(timestamp) {
			  const date = new Date(timestamp);
			  const year = date.getFullYear();
			  const month = (date.getMonth() + 1).toString().padStart(2, "0");
			  const day = date.getDate().toString().padStart(2, "0");
			  const hour = date.getHours().toString().padStart(2, "0");
			  const minute = date.getMinutes().toString().padStart(2, "0");
			  const second = date.getSeconds().toString().padStart(2, "0");
			  const formattedDate = `${year}${month}${day}${hour}${minute}`;
			  return formattedDate;
			},
			//制卡
			makedoorcard() {
				if(!this.roomcard.outtime){
					this.$message({
						message: "请填写退房时间",
						type: "warning",
					});
					return false;
				}
				if(!this.roomcard.lockBytes){
					this.$message({
						message: "请设置房间锁号",
						type: "warning",
					});
					return false;
				}
				this.$refs["roomcard"].validate((valid) => {
					if (valid) {
						if (!this.roomcard.cardHexStr) {
							this.$message({
								message: "请先读取卡片数据",
								type: "warning",
							});
							return false;
						}
						var timestr1 = this.formattedDate(
							new Date(this.roomcard.outtime).getTime()
						); //new Date(this.roomcard.outtime).format('yyMMddHHmm')
						var timestr2 = this.formattedDate(new Date().getTime());
						timestr1 = timestr1.substring(2, timestr1.length);
						timestr2 = timestr2.substring(2, timestr2.length);
						var a1 =
							this.roomcard.value1 <= 9 ?
							"0" + this.roomcard.value1 :
							this.roomcard.value1;
						var a2 =
							this.roomcard.value2 <= 9 ?
							"0" + this.roomcard.value2 :
							this.roomcard.value2;
						var a3 =
							this.roomcard.value3 <= 9 ?
							"0" + this.roomcard.value3 :
							this.roomcard.value3;
						var a4 =
							this.roomcard.value4 <= 9 ?
							"0" + this.roomcard.value4 :
							this.roomcard.value4;
						var data = {
							a: 1,
							// dlsCoID: parseFloat(
							//   1 + "" + localStorage.getItem("orgid").substr(-5, 5)
							// ), //酒店标识，可以从现有卡片读取
							dlsCoID: parseFloat(this.roomcard.sign),
							cardNo: parseFloat(this.roomcard.cardNo), //同一分钟内发卡最多16张，每发一张卡加1
							dai: parseFloat(this.roomcard.dai), //客人代，0--255，用于后卡覆盖前卡，一般情况下固定为0
							lLock: parseFloat(this.roomcard.lLock), //反锁标志，1能开反锁，0不能开反锁
							pdoors: 1, //公共门标志，1能开公共门，0不能开
							dBytes: timestr2, //发卡时间10位数字字符串，包含年月日时分，各占两位 例：'0905201345'表示2009/05/20 13:45
							eBytes: timestr1, //退房时间10位数字字符串，包含年月日时分，各占两位
							lockBytes: this.roomcard.lockBytes, //
							cardHexStr: this.roomcard.cardHexStr, //返回的卡数据字符串
						};
						// console.log('发送时间',data);
						// return false;
						axios({
							method: "POST",
							url: "http://127.0.0.1:8099/usbpro/guestCard",
							// url: 'http://192.168.2.27:8099/usbpro/guestCard',
							headers: {
								// 'Sec-Fetch-Site': 'same-origin',
								"Access-Control-Allow-Origin": "*",
							},
							data: data,
						}).then((res) => {
							console.log("纸卡数据", res);
							if (res.data.code == "000000") {
								this.$message({
									message: "操作成功",
									type: "success",
								});
							} else {
								this.$message({
									message: "请重试",
									type: "warning",
								});
							}
						});
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
			//销卡
			destruction() {
				if (!this.roomcard.sign) {
					this.$message({
						message: "请设置酒店标识",
						type: "warning",
					});
					return false;
				}
				var data = {
					// dlsCoID: parseFloat(
					//   1 + "" + localStorage.getItem("orgid").substr(-5, 5)
					// ), //酒店标识，可以从现有卡片读取
					dlsCoID: parseFloat(this.roomcard.sign),
				};
				axios({
					method: "POST",
					url: "http://127.0.0.1:8099/usbpro/cardErase?dlsCoID=" + data.dlsCoID,
					// url: 'http://192.168.2.27:8099/usbpro/cardErase?dlsCoID='+data.dlsCoID,
					headers: {
						// 'Sec-Fetch-Site': 'same-origin',
						"Access-Control-Allow-Origin": "*",
					},
					// data:data,
				}).then((res) => {
					console.log("销卡", res);
					if (res.data.code == "000000") {
						this.$message({
							message: res.data.msg || "操作成功",
							type: "success",
						});
					} else {
						this.$message({
							message: res.data.msg || "请重试",
							type: "warning",
						});
					}
				});
			},
			//鸣叫
			chirp() {
				axios({
					method: "POST",
					url: "http://127.0.0.1:8099/usbpro/buzzer",
					// url: 'http://192.168.2.27:8099/usbpro/buzzer',
					headers: {
						// 'Sec-Fetch-Site': 'same-origin',
						"Access-Control-Allow-Origin": "*",
					},
					data: {
						a: 1,
						b: 10,
					},
				}).then((res) => {
					console.log(res);
				});
			},
			//获取卡片数据
			carddata(type) {
				axios({
					method: "POST",
					url: "http://127.0.0.1:8099/usbpro/readCard",
					// url: 'http://192.168.2.27:8099/usbpro/readCard',
					headers: {
						// 'Sec-Fetch-Site': 'same-origin',
						"Access-Control-Allow-Origin": "*",
					},
				}).then((res) => {
					console.log("卡片数据", res);
					if (res.data.code == "000000") {
						this.roomcard.cardHexStr = res.data.data.cardHexStr;
						// this.$alert(res.data.data.cardHexStr, "卡片数据", {
						// 	confirmButtonText: "确定",
						// 	callback: (action) => {
						// 		// this.$message({
						// 		// 	type: 'info',
						// 		// 	message: `action: ${ action }`
						// 		// });
						// 	},
						// });
						if(type==1){
							this.makedoorcard();
						}else if(type==2){
							this.cardType();
						}else if(type==3){
							this.destruction();
						}
						
					} else {
						this.$message({
							message: res.data.msg || "请重试",
							type: "warning",
						});
					}
				});
			},
			//卡片类型
			cardType() {
				axios({
					method: "POST",
					url: "http://127.0.0.1:8099/usbpro/getCardTypeByCardDataStr",
					// url: 'http://192.168.2.27:8099/usbpro/getCardTypeByCardDataStr',
					headers: {
						// 'Sec-Fetch-Site': 'same-origin',
						"Access-Control-Allow-Origin": "*",
					},
				}).then((res) => {
					console.log("卡片类型", res);
					if (res.data.code == "000000") {
						this.cardinfo ={ ...res.data.data};
						console.log('卡片数据',this.cardinfo);
						this.cardinfo.cardTypeName=res.data.data.cardTypeName;
						this.checkOut();
						// this.$alert(
						// 	res.data.data.cardTypeName + ":<br/>" + res.data.data.cardHexStr,
						// 	"卡片类型", {
						// 		confirmButtonText: "确定",
						// 		callback: (action) => {
						// 			// this.$message({
						// 			// 	type: 'info',
						// 			// 	message: `action: ${ action }`
						// 			// });
						// 		},
						// 	}
						// );
					} else {
						this.$message({
							message: res.data.msg || "请重试",
							type: "warning",
						});
					}
				});
			},
			lengthCutting(str, num) {
				let strArr = [];
				for (let i = 0; i < str.length; i += num)
					strArr.push(str.slice(i, i + num));
				return strArr;
			},
			//离店
			checkOut() {
				if (!this.roomcard.sign) {
					this.$message({
						message: "请填写酒店标识",
						type: "warning",
					});
					return false;
				}
				var data = {
					// dlsCoID: parseFloat(
					//   1 + "" + localStorage.getItem("orgid").substr(-5, 5)
					// ), //酒店标识，可以从现有卡片读取
					dlsCoID: parseFloat(this.roomcard.sign),
				};
				axios({
					method: "POST",
					url: "http://127.0.0.1:8099/usbpro/getGuestETimeByCardDataStr?dlsCoID=" +
						data.dlsCoID,
					// url: 'http://192.168.2.27:8099/usbpro/getGuestETimeByCardDataStr?dlsCoID='+data.dlsCoID,
					headers: {
						// 'Sec-Fetch-Site': 'same-origin',
						"Access-Control-Allow-Origin": "*",
					},
					// data:data,
				}).then((res) => {
					console.log("获取离店时间", res);
					if (res.data.code == "000000") {
						// this.cardinfo = res.data.data;
						var time = this.lengthCutting(res.data.data.ETime, 2);
						var str = "";
						time.forEach(function(value, index) {
							if (index == 0) {
								str = value + "年";
							} else if (index == 1) {
								str = str + value + "月";
							} else if (index == 2) {
								str = str + value + "日";
							} else if (index == 3) {
								str = str + value + "时";
							} else if (index == 4) {
								str = str + value + "分";
							}
						});
						this.checktime=str;
						this.cardshow=true;
						// this.$alert(str, "离店时间", {
						// 	confirmButtonText: "确定",
						// 	callback: (action) => {
						// 		// this.$message({
						// 		// 	type: 'info',
						// 		// 	message: `action: ${ action }`
						// 		// });
						// 	},
						// });
					} else {
						this.$message({
							message: res.data.msg || "请重试",
							type: "warning",
						});
					}
				});
			},
			//卡锁号
			locknumber() {
				if (!this.roomcard.sign) {
					this.$message({
						message: "请填写酒店标识",
						type: "warning",
					});
					return false;
				}
				var data = {
					// dlsCoID: parseFloat(
					//   1 + "" + localStorage.getItem("orgid").substr(-5, 5)
					// ), //酒店标识，可以从现有卡片读取
					dlsCoID: parseFloat(this.roomcard.sign),
				};
				axios({
					method: "POST",
					url: "http://127.0.0.1:8099/usbpro/getGuestLockNoByCardDataStr?dlsCoID=" +
						data.dlsCoID,
					// url: 'http://192.168.2.27:8099/usbpro/getGuestLockNoByCardDataStr?dlsCoID='+data.dlsCoID,
					headers: {
						// 'Sec-Fetch-Site': 'same-origin',
						"Access-Control-Allow-Origin": "*",
					},
				}).then((res) => {
					console.log("获取卡锁号", res);
					if (res.data.code == "000000") {
						this.$alert(res.data.data.lockNo, "卡锁号", {
							confirmButtonText: "确定",
							callback: (action) => {
								// this.$message({
								// 	type: 'info',
								// 	message: `action: ${ action }`
								// });
							},
						});
					} else {
						this.$message({
							message: res.data.msg || "请重试",
							type: "warning",
						});
					}
				});
			},

		},
	};
</script>

<style lang="scss" scoped>
	.ChangeRoom {
		width: 100%;
		height: 550px;
		background-color: #fff;

		.changeRoom_main {
			width: 100%;
			height: 199px;
			// background: linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%);
			border-radius: 5px;

			// 标题部分css
			.order_title {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				height: 40px;

				.title_icon {
					width: 40px;
					height: 40px;
					text-align: center;
					line-height: 50px;
				}

				p {
					color: black;
					flex: 1;
					text-align: left;
					padding-left: 20px;
					box-sizing: border-box;
				}
			}

			.information_content {
				display: flex;
				width: 100%;
				padding-right: 20px;
				padding-top: 20px;
				box-sizing: border-box;

				// 侧边栏css
				.sidebar {
					width: 150px;
					height: 300px;
					overflow-y: auto;
					ul {
						width: 100%;
						height: 100%;
						display: flex;
						flex-direction: column;
						align-items: center;

						li {
							width: 140px;
							height: 35px;
							border-radius: 8px;
							line-height: 35px;
							background-color: #eefefe;
							color: #5cb5b8;
							text-align: center;
							font-size: 14px;
							margin-bottom: 15px;
						}

						li.activate {
							background-color: #5cb5b8;
							color: #fff;
						}
					}
				}

				.order_main {
					flex: 1;

					.change_main {
						width: 100%;
						height: 400px;
						display: flex;
						flex-direction: column;
						justify-content: space-between;

						.cartlist {
							width: 100%;
							height: 400px;
							padding-left: 10px;
							box-sizing: border-box;
							overflow-y: auto;

							//卡片部分css
							.change_cards {
								width: 180px;
								height: 100px;
								color: #999999;
								display: flex;
								float: left;
								font-size: 15px;
								margin-right: 10px;
								margin-bottom: 10px;
								border-radius: 4px;
								background-color: #f3f3f3;

								.change_Price {
									width: 50%;
									display: flex;
									flex-direction: column;
									padding-left: 10px;
									box-sizing: border-box;
									justify-content: space-around;
								}

								.room_price {
									flex: 1;
									padding: 20px 10px 0 0;
									box-sizing: border-box;
									text-align: right;

									img {
										display: none;
									}
								}
							}
						}

						.change_cards.active {
							background-color: #d7f5f5;
							color: #5cb5b8;

							.room_price {
								// flex: 1;
								// display: flex;
								// flex-direction: column;
								// justify-content: space-between;
								// padding: 10px 10px 0 0;
								// box-sizing: border-box;
								// text-align: right;
								// position: relative;

								// img {
								//   display: block;
								//   position: absolute;
								//   bottom: 15px;
								//   right: 15px;
								// }
							}
						}

						img {
							width: 20px;
							height: 20px;
						}

						.demo_Form {
							text-align: right;
							padding-bottom: 10px;
							padding-right: 32px;
							margin-top: 20px;
							box-sizing: border-box;

							.el-form-item {}
						}
					}
				}
			}

			//最后确认房间号和价格，在提交区域css
			.form_submit {
				width: 100%;
				height: 70px;

				.demo-ruleForms {
					width: 100%;
					height: 70px;
					padding-top: 10px;
					text-align: right;
					box-sizing: border-box;
					padding-right: 10px;

					.el-form-item {
						margin-right: 30px;

						.el-button {
							width: 100px;
							height: 40px;
							border-radius: 6px;
							background-color: #5cb5b8;
							color: #fff;
						}

						.el-form-item:last-child {
							margin-right: 0px;
							margin-left: 20px;
						}
					}

					::v-deep .el-input__inner {
						font-size: 14px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						// 设置输入字体的颜色
						color: #db1212;
					}
				}
			}
		}
	}
	.card-zindex{
		z-index: 222;
	}
</style>