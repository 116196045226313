import { render, staticRenderFns } from "./moreFurther.vue?vue&type=template&id=74ea1a80&scoped=true"
import script from "./moreFurther.vue?vue&type=script&lang=js"
export * from "./moreFurther.vue?vue&type=script&lang=js"
import style0 from "./moreFurther.vue?vue&type=style&index=0&id=74ea1a80&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74ea1a80",
  null
  
)

export default component.exports