<template>
  <div class="print">
    <el-dialog
      title="客房打印单"
      :visible.sync="visibleRef"
      width="1000px"
      :before-close="close"
      append-to-body
    >
      <div class="print-area" id="print-area">
        <div class="print-title">
          {{ orderInfo.orgName }} - {{ orderType === 1 ? "入住单" : "结账单" }}
        </div>
        <div class="mb-20">
          <span class="mr-50 print-label"
            >打印时间：{{ orderInfo.printTime }}</span
          >
          <span class="print-label">操作人：{{ orderInfo.operator }}</span>
        </div>
        <el-form inline>
          <el-row>
            <el-col :span="8">
              <el-form-item label="客人姓名:">
                {{ customerInfo.name }}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="电话:">
                {{ customerInfo.mobile }}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="证件号码:">
                {{ customerInfo.idCard }}
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-table class="mb-10" ref="orderTableRef" :data="orderInfoList">
          <el-table-column label="订单信息">
            <el-table-column prop="houseTypeName" label="房型名称-房间号">
              <template slot-scope="{ row }">
                <div>{{ row.houseTypeName + "-" + row.roomName }}</div>
              </template>
            </el-table-column>
            <el-table-column label="入住信息" width="400">
              <template slot-scope="{ row }">
                <div>
                  {{
                    `${row.arrivalTime} ~ ${row.predepartureTime}，${
                      row.orderState ? "已入住" : "已退房"
                    }`
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="间夜" prop="reserveDays"></el-table-column>
            <el-table-column
              label="房费/间"
              prop="roomRealityPrice"
            ></el-table-column>
          </el-table-column>
        </el-table>
        <el-table class="mb-10" ref="otherPayRef" :data="otherPayList">
          <el-table-column label="消费明细">
            <el-table-column
              label="项目名称"
              prop="projectName"
            ></el-table-column>
            <el-table-column label="数量" prop="quantity"></el-table-column>
            <el-table-column label="金额" prop="price"> </el-table-column>
			<el-table-column label="备注" prop="remarks"></el-table-column>
            <el-table-column
              label="消费时间"
              prop="createdTime"
            ></el-table-column>
          </el-table-column>
        </el-table>
        <div class="print-label my-20">
          订单金额：应收：{{ orderInfo.receiveMoney }}元，实收：{{
            orderInfo.actualReceiveMoney
          }}元，{{
            orderType === 2 ? "待支付：" + orderInfo.waitPay + "，" : ""
          }}已付押金：{{ orderInfo.deposit }}元
        </div>
        <div class="mb-20">
          {{ orderInfo.remark }}
        </div>
        <div class="flex items-center mb-20">
          <span class="print-label">客栈地址：</span>
          {{ customerInfo.address }}
        </div>
        <div>
          <span class="print-label">客人签字：</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="handlePrint">打印</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import PrintJs from "print-js";
import html2canvas from "html2canvas";

export default {
  name: "PrintOrderModal",
  props: ["orderType"],
  data() {
    return {
      visibleRef: false,
      orderInfo: {
        orgName: "",
        printTime: "",
        operator: "",
        remark: "",
        receiveMoney: 0,
        actualReceiveMoney: 0,
        deposit: 0,
        waitPay: 0,
      },
      customerInfo: {
        name: "",
        mobile: "",
        idCard: "",
        address: "",
      },
      orderInfoList: [],
      otherPayList: [],
    };
  },
  methods: {
    open(orderInfo, customerInfo, orderInfoList, otherPayList) {
      this.visibleRef = true;
      this.orderInfo = orderInfo;
      this.customerInfo = customerInfo;
      this.orderInfoList = orderInfoList;
      this.otherPayList = otherPayList;
    },
    close() {
      this.visibleRef = false;
    },
    handlePrint() {
      html2canvas(document.querySelector("#print-area"), {
        scale: 2,
      }).then((canvas) => {
        PrintJs(canvas.toDataURL("image/jpeg", 0.8), "image");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
*{
	font-weight: 800;
	color:#000;
}
.print {
	
  &-title {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  &-label {
    font-size: 18px;
    // color: #999;
    color: #000;
    white-space: nowrap;
  }
}
::v-deep .el-table--border, .el-table--group{
	border: 1px solid black !important;
}
::v-deep .el-table td.el-table__cell div{
	font-weight: 800;
	color:#000;
}
::v-deep .el-form--inline .el-form-item__label{
    color:#000;
	font-weight: 800;
}
::v-deep .el-form--inline .el-form-item__content{
	color:#000;
	font-weight: 800;
}
::v-deep .el-table th {
  border: 1px solid black !important;
  border-right: none !important;
  border-bottom: none !important;
}

::v-deep .el-table td {
  border: 1px solid black !important;
  border-right: none !important;
}
::v-deep .el-table--border .el-table__cell:first-child .cell{
	color:#000;
	font-weight: 800;
}
::v-deep .el-table th.el-table__cell>.cell {
	color:#000;
	font-weight: 800;
}
::v-deep .el-table__empty-block{
	border: 1px solid black !important;
	border-top: none !important;
}
</style>
