<template>
  <!-- 自定义票据 -->
  <div class="CustomBill" @click="close_page">
    <div class="customBill_main">
      <el-form
        class="demo_from"
        :label-position="labelPosition"
        label-width="80px"
        :model="formLabelAlign"
      >
        <el-form-item label="订单编号">
          <el-input :disabled="true" v-model="formLabelAlign.id"></el-input>
        </el-form-item>
        <el-form-item label="酒店">
          <el-input v-model="formLabelAlign.orgName"></el-input>
        </el-form-item>
        <el-form-item label="客人姓名">
          <el-input v-model="formLabelAlign.name"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="formLabelAlign.mobile"></el-input>
        </el-form-item>
        <el-form-item label="房型">
          <el-input v-model="formLabelAlign.houseTypeName"></el-input>
        </el-form-item>
        <el-form-item label="入店时间">
          <el-input v-model="formLabelAlign.createdTime"></el-input>
        </el-form-item>
        <el-form-item label="离店时间">
          <el-input v-model="formLabelAlign.updatedTime"></el-input>
        </el-form-item>
        <el-form-item label="房费">
          <el-input v-model="formLabelAlign.roomRealityPrice"></el-input>
        </el-form-item>
        <el-form-item label="打印时间">
          <el-input v-model="formLabelAlign.updatedTime"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="formLabelAlign.remark"></el-input>
        </el-form-item>
      </el-form>
      <div class="custom_submit">
        <el-button @click="xiaopiaoButton">预览打印内容</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomBill",
  data() {
    return {
      labelPosition: "right",
      formLabelAlign: {
        orgName: localStorage.getItem("orgName"),
        ...this.$store.state.user.orderFrom,
      },
    };
  },
  created() {
    console.log("信息", this.$store.state.user.orderFrom);
  },
  methods: {
    // 点击右上角的图标隐藏弹窗
    close_page(e) {
      if (e.target.className === "el-icon-close") {
        this.$emit("customBill", [false]);
      } 
    },
  
    //点击预览小票打印按钮触发
    xiaopiaoButton() {
      this.$emit("customBill", [false, "预览"]);
      
    }
  },
};
</script>

<style lang="scss" scoped>
.CustomBill {
  width: 100%;
  height: 670px;
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: #fff;
  .customBill_main {
    width: 100%;
    // background: linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%);
    border-radius: 5px;
    ::v-deep .demo_from {
      padding: 0 40px 0px 20px;
      box-sizing: border-box;
      // background: linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%);
    }
    .custom_submit {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 0px;
      justify-content: center;
      .el-button {
        background-color: #5cb5b8;
        color: #fff;
        width: 150px;
        height: 40px;
        border-radius: 6px;
      }
    }
  }
}
</style>