<template>
  <!-- 更换房间页面 -->
  <div class="ChangeRoom" @click="close_page">
    <el-row>
      <p class="firstBoxsss">
        <span>已排房间数：</span>
		<span style="margin-right: 10px;color: green;">{{ houseindex.length }}</span>
        <span
          >待排房间数：</span
        >
		<span style="color: red;">{{
            parseFloat(limit) - parseFloat(houseindex.length)
          }}</span>
      </p>
    </el-row>
    <div class="cardBoxs">
      <div
        class="room-item"
        v-for="(item, index) in houselist"
        @click="houseclick(item.id)"
        :key="item.id"
        :class="{
          'room-item-check': houselist.lengths != 0,
          active: houseindex.indexOf(item.id) !== -1,
        }"
      >
        <div class="room-item-listtop">{{ item.roomNumber }}</div>
        <div class="room-item-listbottom">
          <div>空</div>

          <div>干净</div>
        </div>
      </div>
    </div>

    <div class="fooerbtu-box">
      <div class="cencle-btu" @click="removeButton">关闭</div>
      <div class="show-btu" @click="tipshow">确定选中</div>
    </div>
  </div>
</template>

<script>
import { advanceOrderHoutyType } from "@/api/advanceOrder";
export default {
  name: "emptyroom",
  data() {
    return {
      houselist: [],
      houseindex: [],
      isActive: 0,
      limit: 1,
    };
  },
  created() {
    // this.getroomview();
    console.log(this.houselist);
  },
  methods: {
    advanceOrderHoutyTypeed() {
      advanceOrderHoutyType().then((res) => {});
    },
    close_page(e) {
      console.log(e.target.className);
      // console.log(e.target.nodeName);
      if (
        e.target.className == "el-dialog__close el-icon el-icon-close" ||
        e.target.className == "cencle-btu"
      ) {
        this.$emit("closemodel", false);
      }
    },
    houseclick(id) {
      // this.isActive = id
      console.log("限制", this.limit);
      console.log("限制", this.houseindex);
      console.log("限制", this.houselist);
      var a = 0;
      var arr = [];
      this.houselist.forEach(function (value, itemindex) {
        if (id == value.id) {
          if (value.checkIn) {
            a = 1;
          }
        }
        if (value.checkIn) {
          arr.push(value.id);
        }
      });
      if (this.houseindex.indexOf(id) !== -1) {
        if (a != 1) {
          this.houseindex.splice(this.houseindex.indexOf(id), 1);
        } else {
          this.$message({
            message: "当前房间已经入住",
            type: "warning",
          });
        }
      } else {
        if (this.houseindex.length >= this.limit) {
          var index = -1;
          this.houseindex.forEach(function (item, itemindex) {
            if (arr.indexOf(item) === -1) {
              index = itemindex;
            }
          });
          if (index != -1) {
            this.houseindex.splice(index, 1);
            this.houseindex.push(id);
          }
        } else {
          this.houseindex.push(id);
        }
      }
    },
    tipshow() {
      var item = [];
      var that = this;
      this.houselist.forEach(function (value) {
        if (that.houseindex.indexOf(value.id) !== -1) {
          item.push(value);
        }
      });
      // console.log(item);
      this.$emit("getroomdata", item
      ); //选中的房间id
      // this.$message({
      // 	type: 'success',
      // 	message: '排房成功'
      // })
    },
    removeButton() {
      this.$emit("getroomdata");
    },
	//顺序
	dataorder(){
		this.houselist.sort(this.sortBy('roomNumber',1));
		if(this.houselist&&this.houselist.length&&this.limit<this.houseindex.length){
			var arr = [];
			this.houselist.forEach((item)=>{
				if( this.houseindex.indexOf(item.id)!=-1 ){
					//选中数据在当前时间段不存在
					arr.push(item.id);
				}
			});
			this.houseindex = arr;
		}
	},
	//attr：根据该属性排序；rev：升序1或降序-1，不填则默认为1
	sortBy(attr,rev){
	    if( rev==undefined ){ rev=1 }else{ (rev)?1:-1; }
	    return function (a,b){
	        a=parseFloat(a[attr].replace(/[^\d.]/g, ""))?parseFloat(a[attr].replace(/[^\d.]/g, "")):0;
	        b=parseFloat(b[attr].replace(/[^\d.]/g, ""))?parseFloat(b[attr].replace(/[^\d.]/g, "")):0;
			// console.log('shuju',a,b);
	        if(a<b){ return rev*-1}
	        if(a>b){ return rev* 1 }
	        return 0;
	    }
	},
  },
};
</script>

<style lang="scss" scoped>
.firstBoxsss {
	font-size: 18px;
	color: #5cb5b8;
	margin-bottom: 10px;
}
.ChangeRoom {
  width: 100%;
  height: 500px;
  background-color: #fff;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  padding: 10px;
  overflow-y: auto;
  position: relative;
}
.cardBoxs {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  overflow-y: auto;
  .room-item {
    border-radius: 5px;
    width: 100px;
    height: 70px;
    margin-right: 5px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: #1890ff;
    cursor: pointer;
  }

  .room-item-check {
    background-color: #b6d3ee;
    color: #fff;
  }
  .room-item-check.active {
    background-color: #5cb5b8;
    color: #fff;
  }
  .room-item-listbottom {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 10px;

    div {
      margin-left: 4px;
    }
  }

  .room-item-listtop {
    text-align: center;
    font-size: 20px;
  }
}

.fooerbtu-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  right: 20px;
}

.cencle-btu,
.show-btu {
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 10px;
  color: #fff;
  margin-right: 10px;
}

.cencle-btu {
  cursor: pointer;
  width: 80px;
  height: 40px;
  background-color: #fdd4d9;
  color: #d9001b;
}

.show-btu {
  cursor: pointer;
  background-color: #5cb5b8;
  color: #fff;
}
</style>