<template>
  <!-- 发票开具 -->
  <div class="IssueAnInvoice">
    <el-row class="IssueAnInvoice_box" @click="close_off">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
      <el-row>
    <el-form-item label="订单编号:" prop="orderId" >
          <el-input clearable v-model="ruleForm.orderId" :disabled='!ordershow' placeholder="订单编号"></el-input>
        </el-form-item>
    </el-row>
    <el-row>
     <el-form-item label="发票No:" prop="number">
          <el-input clearable v-model="ruleForm.number" placeholder="发票No"></el-input>
        </el-form-item>
</el-row>
<el-row>
     <el-form-item label="单位/客户：" prop="unit">
          <el-input clearable v-model="ruleForm.unit" placeholder="单位/客户"></el-input>
        </el-form-item>
</el-row>
<el-row>
    <el-form-item label="开票金额：" prop="price">
          <el-input clearable v-model="ruleForm.price" placeholder="开票金额"></el-input>
        </el-form-item>
</el-row>
<el-row>
    <el-form-item label="开票类型：" prop="type">
          <el-select clearable v-model="ruleForm.type" placeholder="请选择开票类型" style="width: 100%">
            <el-option label="增值税普通发票" value="0"></el-option>
            <el-option label="增值税专用发票" value="1"></el-option>
            <el-option label="增值税电子发票" value="2"></el-option>
          </el-select>
        </el-form-item>
</el-row>
<el-row>
    <el-form-item label="抬头类型：" prop="upType">
          <el-select 
          @change="upTypeChange(ruleForm.upType)"
          clearable v-model="ruleForm.upType" placeholder="请选择抬头类型" style="width: 100%">
            <el-option label="企业单位" value="0"></el-option>
            <el-option label="个人/非企业单位" value="1"></el-option>
          </el-select>
        </el-form-item>
</el-row>
<el-row v-if="ruleForm.upType == 0">
     <el-form-item label="公司名称:" prop="name">
          <el-input clearable v-model="ruleForm.name" placeholder="公司名称"></el-input>
        </el-form-item>
</el-row>
<el-row v-if="ruleForm.upType == 0">
     <el-form-item label="公司税号:" prop="dutyParagraph">
          <el-input clearable v-model="ruleForm.dutyParagraph" placeholder="公司税号"></el-input>
        </el-form-item>
</el-row>
<el-row>
     <el-form-item label="接收方式:" prop="way">
      <el-select @change="" clearable v-model="ruleForm.way" placeholder="请选择接收方式" style="width: 100%">
            <el-option label="电子邮箱" value="0"></el-option>
            <el-option label="快递收货" value="1"></el-option>
          </el-select>
        </el-form-item>
</el-row>
<el-row v-if=" FromDates == 2?true:ruleForm.way == 0">
     <el-form-item  label="电子邮箱:" prop="email">
          <el-input clearable v-model="ruleForm.email" placeholder="电子邮箱"></el-input>
        </el-form-item>
</el-row>
<el-row v-if=" FromDates == 2?false:ruleForm.way == 1">
  <el-form-item label="收货地址:" prop="email">
  
    <el-col :span="5">
      <el-input clearable v-model="ruleForm.userName" placeholder="姓名"></el-input>
    </el-col>
    <el-col :span="7" :offset="2">
      <el-input clearable v-model="ruleForm.mobile" placeholder="手机号"></el-input>
    </el-col>
    <el-col :span="7" :offset="2">
      <el-input clearable v-model="ruleForm.site" placeholder="详细地址"></el-input>
    </el-col>
          
  </el-form-item>
</el-row>
<el-row >
     <el-form-item label="备注:" prop="remark">
          <el-input clearable v-model="ruleForm.remark" placeholder="备注"></el-input>
        </el-form-item>
</el-row>
        
       

        
        
    
      </el-form>
      <el-row class="enterButton" >
  
  <el-button @click="submitForm('ruleForm')"> 确定开票 </el-button>

 </el-row>
    </el-row>
  </div>
</template>

<script>
import { AddInvoice, UpdateInvoice } from "_api/orderFrom";
export default {
  name: "IssueAnInvoice",
  props: {
    FromDates: Number
  },
  data() {
    return {
      ruleForm: {
		orderId:undefined,
      },
      FromDate: {...this.$store.state.base.invoiceUpdate}, //传来的数据
      rules: {
        number: [
             { required: true, message: '请输入发票编号', trigger: 'blur' },
             {
            pattern:  /^[a-z0-9A-Z]+$/, //正则校验不用字符串
            message: "请输纯数字或大小写英文字母",
            trigger: "blur",
          },
        ],
        unit: [
        { required: true, message: '请输入单位/客户名称', trigger: 'blur' }
        ],
        price: [
        { required: true, message: '请输入开票金额', trigger: 'blur' },
        {
            pattern:  /^0(\.[0-9]{1,2})*$|^[1-9]\d{0,7}(\.\d{1,2})*$/, //正则校验不用字符串
            message: "请输数字,且长度最长为8位整数加小数位最长2位",
            trigger: "blur",
          },
        ],
        type: [
        { required: true, message: '请输入开票类型', trigger: 'blur' }
        ],
        orderId: [
        { required: true, message: '请输入订单编号', trigger: 'blur' },
        {
            pattern:  /^[0-9]*$/, //正则校验不用字符串
            message: "请输纯数字",
            trigger: "blur",
          },
        ],
        upType: [
        { required: true, message: '请选择抬头类型', trigger: 'blur' },
        ],
        way: [
        { required: true, message: '请选择接收方式', trigger: 'blur' },//接收方式
        ],
        email: [
        { required: true, message: '请输入电子邮箱信息或收货地址信息', trigger: 'blur' },//电子邮箱
        ]
      },
	  ordershow:true,
    };
  },
  created() {
    this.judgeOperator() 
    console.log(this.$store.state.base.invoiceUpdate);
	console.log('订单信息',this.$store.state.user.orderFrom);
	if(this.$store.state.user.orderFrom){
		this.ruleForm.orderId=this.$store.state.user.orderFrom.id;
		if(this.$store.state.user.orderFrom.id){
			this.ordershow=false;
		}
	}
  },
  methods: {
    //抬头切换，判断哪些必填
    upTypeChange(type) {
      this.$set(this.ruleForm,'upType',type)
      if(type == 0) {
        //企业单位
        this.$set(this.rules,'number',[
             { required: true, message: '请输入发票编号', trigger: 'blur' },
             {
            pattern:  /^[a-z0-9A-Z]+$/, //正则校验不用字符串
            message: "请输纯数字或大小写英文字母",
            trigger: "blur",
          },
        ])
        this.$set(this.rules,'price',[
        { required: true, message: '请输入开票金额', trigger: 'blur' },
        {
            pattern:  /^0(\.[0-9]{1,2})*$|^[1-9]\d{0,7}(\.\d{1,2})*$/, //正则校验不用字符串
            message: "请输数字,且长度最长为8位整数加小数位最长2位",
            trigger: "blur",
          },
        ])
        this.$set(this.rules,'type',[
        { required: true, message: '请输入开票类型', trigger: 'blur' }
        ])
        this.$set(this.rules,'unit', [
        { required: true, message: '请输入单位/客户名称', trigger: 'blur' }
        ])
        this.$set(this.rules,'orderId',[
        { required: true, message: '请输入订单编号', trigger: 'blur' },
        {
            pattern:  /^[0-9]*$/, //正则校验不用字符串
            message: "请输纯数字",
            trigger: "blur",
          },
        ])
        this.$set(this.rules,'upType',[
        { required: true, message: '请选择抬头类型', trigger: 'blur' },
        ])
        this.$set(this.rules,'way',[
        { required: true, message: '请选择接收方式', trigger: 'blur' },//接收方式
        ])
        this.$set(this.rules,'email',[
        { required: true, message: '请输入电子邮箱信息或收货地址信息', trigger: 'blur' },//电子邮箱
        ])
        this.$set(this.rules,'name', [
        { required: true, message: '请输入公司名称', trigger: 'blur' },
        ])
        this.$set(this.rules,'dutyParagraph', [
        { required: true, message: '请输入公司税号', trigger: 'blur' },
        {
            pattern:  /^[a-z0-9A-Z]+$/, //正则校验不用字符串
            message: "请输纯数字或大小写英文字母",
            trigger: "blur",
          },
        ])
      
      }else if(type == 1) {
        //个人/非企业单位
        this.$set(this.rules,'number',[
             { required: true, message: '请输入发票编号', trigger: 'blur' },
             {
            pattern:  /^[a-z0-9A-Z]+$/, //正则校验不用字符串
            message: "请输纯数字或大小写英文字母",
            trigger: "blur",
          },
        ])
        this.$set(this.rules,'price',[
        { required: true, message: '请输入开票金额', trigger: 'blur' },
        {
            pattern:  /^0(\.[0-9]{1,2})*$|^[1-9]\d{0,7}(\.\d{1,2})*$/, //正则校验不用字符串
            message: "请输数字,且长度最长为8位整数加小数位最长2位",
            trigger: "blur",
          },
        ])
        this.$set(this.rules,'type',[
        { required: true, message: '请输入开票类型', trigger: 'blur' }
        ])
        this.$set(this.rules,'unit', [
        { required: true, message: '请输入单位/客户名称', trigger: 'blur' }
        ])
        this.$set(this.rules,'orderId',[
        { required: true, message: '请输入订单编号', trigger: 'blur' },
        {
            pattern:  /^[0-9]*$/, //正则校验不用字符串
            message: "请输纯数字",
            trigger: "blur",
          },
        ])
        this.$set(this.rules,'upType',[
        { required: true, message: '请选择抬头类型', trigger: 'blur' },
        ])
        this.$set(this.rules,'way',[
        { required: true, message: '请选择接收方式', trigger: 'blur' },//接收方式
        ])
        this.$set(this.rules,'email',[
        { required: true, message: '请输入电子邮箱信息或收货地址信息', trigger: 'blur' },//电子邮箱
        ])
     
      }
      this.$set(this.ruleForm,"dutyParagraph",this.ruleForm.dutyParagraph)
        this.$set(this.ruleForm,"email",this.ruleForm.email)
        this.$set(this.ruleForm,"name",this.ruleForm.name)
        this.$set(this.ruleForm,"number",this.ruleForm.number)
        this.$set(this.ruleForm,"price",this.ruleForm.price)
        this.$set(this.ruleForm,"remark",this.ruleForm.remark)
        this.$set(this.ruleForm,"type",this.ruleForm.type)
        this.$set(this.ruleForm,"unit",this.ruleForm.unit)
        this.$set(this.ruleForm,"upType", this.ruleForm.upType)
        this.$set(this.ruleForm,"way",this.ruleForm.way)
        this.$set(this.ruleForm,"userName",this.ruleForm.way == 1?this.ruleForm.userName +'/'+ this.ruleForm.mobile + '/'+ this.ruleForm.site:null)
        // this.$set(this.ruleForm,"mobile",'')
        // this.$set(this.ruleForm,"orderId",this.$store.state.base.invoiceUpdate.orderId)
    },
    //点击确定提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        console.log(valid);
        if(valid) {
if (this.FromDates === 2 ){
          //修改
          console.log(this.FromDates);
            this.UpdateInvoiceed()
          
        }else{
           //新增
           console.log(this.FromDates);
            this.AddInvoiced()
           
        }
        } else {
          this.$message({
            type: 'error',
            message: '请按要求填写信息'
          })
        }
        });
        
    },
    //判断是修改还是新增
    judgeOperator() {
      this.$nextTick(() => {
         if (this.FromDates === 2) {
        //修改发票
        // this.ruleForm = {
          // price: this.$store.state.base.invoiceUpdate.price, //开票金额
          // remark: this.$store.state.base.invoiceUpdate.remark, //备注
          // type: this.$store.state.base.invoiceUpdate.type, //开票类型 0增值税普通发票 1增值税专用发票 2增值税电子发票
          // unit: this.$store.state.base.invoiceUpdate.unit, //单位/客户,
          // createdTime: this.$store.state.base.invoiceUpdate.createdTime, //创建时间
          // orderId: this.ruleForm.orderId, //订单id
          // orderId: this.$store.state.base.invoiceUpdate.orderId, //订单id
          // number: this.$store.state.base.invoiceUpdate.number, //发票No
          // id: this.$store.state.base.invoiceUpdate.id,
          // "email": this.$store.state.base.invoiceUpdate.email,//电子邮箱
          // orgId: localStorage.getItem("orgid"),
          // "userName": this.ruleForm.way == 1?this.ruleForm.userName +'/'+ this.ruleForm.mobile + '/'+ this.ruleForm.site:null,//客户姓名
          // "mobile": null,//手机号
          // "site": null,//地址
          // "name": this.$store.state.base.invoiceUpdate.name,//公司名称
          // "dutyParagraph": this.$store.state.base.invoiceUpdate.dutyParagraph,//公司税号	
          // upType: this.$store.state.base.invoiceUpdate.upType,////抬头类型 0企业单位 1个人/非企业单位
          // "way": this.$store.state.base.invoiceUpdate.way,//接收方式 0电子邮箱 1快递收货
        // };
        this.$set(this.ruleForm,"dutyParagraph",this.$store.state.base.invoiceUpdate.dutyParagraph)
        this.$set(this.ruleForm,"email",this.$store.state.base.invoiceUpdate.email)
        this.$set(this.ruleForm,"name",this.$store.state.base.invoiceUpdate.name)
        this.$set(this.ruleForm,"number",this.$store.state.base.invoiceUpdate.number)
        this.$set(this.ruleForm,"price",this.$store.state.base.invoiceUpdate.price)
        this.$set(this.ruleForm,"remark",this.$store.state.base.invoiceUpdate.remark)
        this.$set(this.ruleForm,"type",this.$store.state.base.invoiceUpdate.type)
        this.$set(this.ruleForm,"unit",this.$store.state.base.invoiceUpdate.unit)
        this.$set(this.ruleForm,"upType", this.$store.state.base.invoiceUpdate.upType)
        this.$set(this.ruleForm,"way",this.$store.state.base.invoiceUpdate.way)
        this.$set(this.ruleForm,"userName",this.ruleForm.way == 1?this.ruleForm.userName +'/'+ this.ruleForm.mobile + '/'+ this.ruleForm.site:null)
        // this.$set(this.ruleForm,"mobile",'')
        this.$set(this.ruleForm,"id",this.$store.state.base.invoiceUpdate.id)
        this.$set(this.ruleForm,"orderId",this.$store.state.base.invoiceUpdate.orderId)
        this.$set(this.ruleForm,"orgId",this.$store.state.base.invoiceUpdate.orgId)
      } else{
        this.$set(this.ruleForm,"dutyParagraph",'')
        this.$set(this.ruleForm,"email",'')
        this.$set(this.ruleForm,"name",'')
        this.$set(this.ruleForm,"number",'')
        this.$set(this.ruleForm,"price",'')
        this.$set(this.ruleForm,"remark",'')
        this.$set(this.ruleForm,"type",'')
        this.$set(this.ruleForm,"unit",'')
        this.$set(this.ruleForm,"upType",'')
        this.$set(this.ruleForm,"way",'')
        this.$set(this.ruleForm,"userName",'')
        this.$set(this.ruleForm,"mobile",'')
        this.$set(this.ruleForm,"site",'')
        this.$set(this.ruleForm,"orderId",this.ruleForm.orderId)
        this.$set(this.ruleForm,"orgId",localStorage.getItem("orgid"))

        // this.ruleForm = {
        //   "dutyParagraph": null,//公司税号	
        //   "email": null,//电子邮箱
        //   "name": null,//公司名称
        //   "number": null,//发票编号
        //   "price": null,//开票金额
        //   "remark": null,//备注
        //   "type": null,//开票类型 0增值税普通发票 1增值税专用发票 2增值税电子发票
        //   "unit": null,//单位/客户
        //   "upType": null,//抬头类型 0企业单位 1个人/非企业单位
        //   "way": null,//接收方式 0电子邮箱 1快递收货
        //   "userName": null,//客户姓名
        //   "mobile": null,//手机号
        //   "site": null,//地址
        //   "orderId": this.ruleForm.orderId,//订单id
        //   "orgId": localStorage.getItem("orgid"),//	部门ID
        // };
      }
      })
     
    },

    //发票更新接口
    UpdateInvoiceed() {
      const params = {
        "createdBy": localStorage.getItem('userId'),//创建人ID
        "dutyParagraph": this.ruleForm.dutyParagraph,//公司税号	
          "name": this.ruleForm.name,//公司名称
          "number":  this.ruleForm.number,//发票编号
          "price": parseFloat(this.ruleForm.price),//开票金额
          "remark": this.ruleForm.remark,//备注
          "state": true,
          "type": this.ruleForm.type,//开票类型 0增值税普通发票 1增值税专用发票 2增值税电子发票
          "unit": this.ruleForm.unit,//单位/客户
          "upType": this.ruleForm.upType,//抬头类型 0企业单位 1个人/非企业单位
          "way": this.ruleForm.way,//接收方式 0电子邮箱 1快递收货
          "email": this.ruleForm.way == 0? this.ruleForm.email:this.ruleForm.way == 1?this.ruleForm.userName +'/'+ this.ruleForm.mobile + '/'+ this.ruleForm.site:null,//电子邮箱
          "orderId":this.ruleForm.orderId,//订单id
          "orgId": localStorage.getItem("orgid"),//	部门ID
          "id": this.$store.state.base.invoiceUpdate.id
      };
      UpdateInvoice(params).then((res) => {
        if (res.data.code === 0) {
            this.ruleForm = {
          price: "", //开票金额
          remark: "", //备注
          type: "0", //开票类型 0增值税普通发票 1增值税专用发票 2增值税电子发票
          unit: "", //单位/客户,
          createdTime: "", //创建时间
          orderId: "", //订单id
          number: "", //发票No
          orgId: localStorage.getItem("orgid"),
        };
        this.$message({
            type: 'success',
            message: '发票信息更新成功'
          })
        this.$emit('offAninvoBox')
        }

      });
    },
    //发票新增接口
    AddInvoiced() {
      const params = {
        "createdBy": localStorage.getItem('userId'),//创建人ID
          "dutyParagraph": this.ruleForm.dutyParagraph,//公司税号	
          "name": this.ruleForm.name,//公司名称
          "number":  this.ruleForm.number,//发票编号
          "price": parseFloat(this.ruleForm.price),//开票金额
          "remark": this.ruleForm.remark,//备注
          "state": true,
          "type": this.ruleForm.type,//开票类型 0增值税普通发票 1增值税专用发票 2增值税电子发票
          "unit": this.ruleForm.unit,//单位/客户
          "upType": this.ruleForm.upType,//抬头类型 0企业单位 1个人/非企业单位
          "way": this.ruleForm.way,//接收方式 0电子邮箱 1快递收货
          "email": this.ruleForm.way == 0? this.ruleForm.email:this.ruleForm.way == 1?this.ruleForm.userName +'/'+ this.ruleForm.mobile + '/'+ this.ruleForm.site:null,//电子邮箱
          "orderId": this.ruleForm.orderId,//订单id
          "orgId": localStorage.getItem("orgid"),//	部门ID
      };
    
      
      AddInvoice(params).then((res) => {
        if (res.data.code === 0) {
            this.ruleForm = {
          price: "", //开票金额
          remark: "", //备注
          type: "0", //开票类型 0增值税普通发票 1增值税专用发票 2增值税电子发票
          unit: "", //单位/客户,
          createdTime: "", //创建时间
          orderId: "", //订单id
          number: "", //发票No
        };
        this.$message({
            type: 'success',
            message: '开具发票信息成功'
          })
        this.$emit('offAninvoBox')
        }
      });
    },
    //点击左上角图标和确认触发的事件
    close_off(e) {
      console.log(e.target.className, e.target.innerText);
      if (e.target.className === "el-icon-close") {
        this.$emit('offAninvoBox')
      } else if (e.target.innerText === "确定开票") {
        // this.$emit("issueAninvoice", [false]);
      
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.IssueAnInvoice {
  width: 100%;
  height: 470px;
  // background-color: #e8fdfe;
  position: relative;
  box-sizing: border-box;
  .IssueAnInvoice_box {
    width: 100%;
    // background-color: #e8fdfe;
    border-radius: 5px;
    //头部标题部分css
 
    .demo-ruleForm {
     height: 440px;
     overflow-y: auto;
    }
    //确认提交按钮css
    .enterButton {
      width: 100%;
      height: 60px;
      // margin-top: 7px;
      text-align: center;
      
      .el-button {
        width: 100px;
        height: 40px;
        padding: 0px 0px;
        background-color: #5cb5b8;
        border-radius: 6px;
        color: #fff;
      }
    }
  }
}
</style>