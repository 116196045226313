import request from '_utils/request'
import qs from 'qs'

//消息分页查询
const infopage = (params ={}) => {
 return  request.post('/wineshop/SysMasssage/sysMsgList', params);
}

//消息关联的角色 
const inforole = (params ={}) => {
 return  request.post('/wineshop/SysMasssage/sysMsgRole?messageId='+params.messageId, {});
}

//消息相关联角色
const editinforole = (params ={}) => {
 return  request.post('wineshop/SysMasssage/updSysMsgRole', params);
}

//角色 base/baseRole/page
const inforolelist = (params ={}) => {
 return  request.post('/base/orgRole/page', params);
}

//获取当天消息记录  
const todayinfo = (params ={}) => {
 return  request.post('/wineshop/SysMasssage/getMsgRecordPage', params);
}

export{
    infopage,
	inforole,
	inforolelist,
	editinforole,
	todayinfo,
}