import axios from "axios";
import router from "@/router";
import store from "@/store";
import {
	getToken,
	removeUserInfo
} from "./index";
import {
	Base64
} from "js-base64";
import {
	Message,
	Loading
} from "element-ui";
const request = axios.create({
	baseURL: process.env.VUE_APP_BASEURL,
	timeout: 600000,
});
//开始加载动画
var loading;
var loadingNum = 0; //初始化接口数量
export function startLoading() {
	loading = Loading.service({
		lock: true, //是否锁定
		text: "Loading...", //加载中需要显示的文字
		background: "rgba(0,0,0,0)", //背景颜色
	});
}
//结束加载动画
export function endLoading() {
	loading.close();
}

request.interceptors.request.use(
	function(config) {
		// 请求拦截器中 请求中携带token 接口校验
		// console.log(config);
		// if(config.url == '/oauth/anyTenant/captcha' && )
		if (config.url == '/wineshop/payment_method/add_base' || config.url ==
			'/wineshop/payment_method/must_add_base') {
			var orgid = store.state.user.orgId
		} else {
			var orgid = localStorage.getItem("orgid");
		}
		const ApplicationId = localStorage.getItem("ApplicationId");
		config.headers.ApplicationId = ApplicationId;
		config.headers.Authorization = `${Base64.encode(
      `${store.state.user.clientId}:${store.state.user.clientSecret}`
    )}`;
		const token = getToken();


		const TenantId = localStorage.getItem("TenantId");
		if( config.url.indexOf('/wineshop/data_board/hotel_operations?TenantId=')==-1&&config.url.indexOf('/wineshop/data_board/all?startDate')==-1 ){
			config.headers.CurrentDeptId = orgid;
		}
		config.headers.TenantId = TenantId;
		config.headers['Access-Control-Allow-Origin'] = '*';
		if (token) {
			config.headers.token = token;
		}
		//    查看发送请求的接口有没有设置isLoading

		loadingNum++; //记录需要加载动画的接口数量
		startLoading(); //请求时的加载动画

		return config;
	},
	function(error) {
		// 当出现异常的时候计数-1
		loadingNum--;
		endLoading();
		Message.closeAll()
		Message({
			type: "error",
			message: error.response.data.msg,
		});
		return Promise.reject(error);
	}
);

request.interceptors.response.use(
	function(res) {
		/*
	  后端接口检验token 过期 返回 401
	  检验没有token 返回403 （没有权限 没有登录）
	*/

		if (
			res.request.responseType === "blob" ||
			res.request.responseType === "arraybuffer"
		) {
			loadingNum--;
			endLoading(); //结束加载动画
			return res.data;
		}
		if (res.data.code != 0) {
			Message.closeAll();
			Message({
				type: "error",
				message: res.data.msg ? res.data.msg : res.data.errorMsg,
			});
		}

		// if(res.data.code == '403') {
		// 	Message({
		// 		type: 'error',
		// 		message: res.data.msg
		// 	})
		// }

		loadingNum--;

		endLoading(); //结束加载动画

		return res;
	},
	function(error) {
		console.log(error);
		// && error.response.data.msg == "空指针异常"
		if (error.config.url == "/external/printer_text/receipt") {
			Message.closeAll()
			Message({
				type: 'error',
				message: '未添加打印机或打印机参数配置异常'
			});
			endLoading(); //结束加载动画
			return Promise.reject(error.response.data);
			return false
		} else {
			if (error.response.data.msg) {
				if (error.config.url != '/wineshop/memberInformation/query/phone') {
					if (error.config.url.indexOf('/applet/pay/wxPayRefund?orderId') == -1) {
						console.log('错误信息1')
						Message.closeAll()
						Message({
							type: "error",
							message: error.response.data.msg,
						});
					}
				}
				loadingNum--;

				endLoading(); //结束加载动画
			} else if (error.response.data.errorMsg) {
				if (error.config.url != '/wineshop/memberInformation/query/phone') {
					if (error.config.url.indexOf('/applet/pay/wxPayRefund?orderId') == -1) {
						console.log('错误信息2')
						Message.closeAll()
						Message({
							type: "error",
							message: error.response.data.errorMsg,
						});
					}
				}
				loadingNum--;

				endLoading(); //结束加载动画
			} else {
				Message.closeAll()
				Message({
					type: 'error',
					message: '系统繁忙'
				})
			}
		}

		loadingNum--;

		endLoading(); //结束加载动画
		Message.closeAll()
		switch (error.response.data.code) {

			case 40009:
				// token过期
				// console.log("ssssssssssssssssssssssssssssssssss");
				store.commit("user/USER_LOGOUT");
				Message({
					type: "error",
					message: "登录状态过期请重新登录",
					duration: 1500,
					onClose: () => {
						router.replace("/login");
						removeUserInfo();
						localStorage.removeItem("li_index");
						console.log("gffjhgkjgkugjh,登录状态过期请重新登录");
					},
				});
				break;
			case 40003:
				// 用户未登录
				Message({
					type: "error",
					message: "用户未登录，请登录访问",
					duration: 1500,
					onClose: () => {
						router.replace("/login");
						localStorage.removeItem("li_index");
					},
				});
				break;
			case 4000:
				// 用户未登录
				Message({
					type: "error",
					message: "无效的基本身份验证令牌，请重新登录",
					duration: 1500,
					onClose: () => {
						router.replace("/login");
						removeUserInfo();
					},
				});
				break;
			case 40001:
				// 用户未登录
				Message({
					type: "error",
					message: "会话超时，请重新登录",
					duration: 1500,
					onClose: () => {
						router.replace("/login");
						removeUserInfo();
						localStorage.removeItem("li_index");
					},
				});
				break;

			default:
				if (error.config.url != '/wineshop/memberInformation/query/phone') {
					if (error.config.url.indexOf('/applet/pay/wxPayRefund?orderId')== -1) {
						console.log('错误信息', error)
						if(error.response.data.msg=='仅支持英文(a-zA-Z)数字(0-9)和下划线(_)，且至少包含2项'){
							Message({
								type: "error",
								message: error.response.data.msg,
								duration: 1500,
								onClose: () => {},
							});
						}else{
							Message({
								type: "error",
								message: error.response.data.errorMsg,
								duration: 1500,
								onClose: () => {},
							});
						}
					}
				}
				if (error.response.data.errorMsg.indexOf('token出错') != -1 || error.response.data.msg.indexOf(
						'token出错') != -1) {
					router.replace("/login");
					removeUserInfo();
				}
				break;
		}
		return Promise.reject(error.response.data);
	}
);

export default request;