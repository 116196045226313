<template>
  <!-- 夜审 -->
  <div class="nigntAuditor" ref="nigntAuditor">
	  <!-- height="380px" -->
    <el-table
    @row-dblclick="rowDblclick"
      :data="tableData"
      border
	  :height="tableheight"
      :header-cell-style="{ color: '#4C4C4C' }"
      :cell-style="cellStyle"
      style="width: 100%"
    >
      <el-table-column
        prop="checkInRoomName"
        label="入住房间"
        align="center"
        width="180"
      >
      </el-table-column>
      <el-table-column prop="name" label="姓名" align="center" width="80">
      </el-table-column>
      <el-table-column prop="breakfast" label="早餐" align="center" width="80">
      </el-table-column>
      <el-table-column prop="preAuthPrice" label="预授权" align="center" width="90">
      </el-table-column>
      <el-table-column
        prop="paymentPrice"
        label="付款"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="consumptionPrice"
        label="消费"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="arrivalTime"
        label="入住时间"
        align="center"
        width="180"
      >
      </el-table-column>
      <el-table-column
        prop="roomRealityPrice"
        align="center"
        label="房价"
      
      >
        <template slot-scope="scope">
          ￥{{ scope.row.roomRealityPrice }}
        </template>
      </el-table-column>
      <el-table-column
        prop="reserveDays"
        align="center"
        label="天数"
      >
      </el-table-column>
      <el-table-column
        prop="numberPeople"
        align="center"
        label="人数"
      >
      </el-table-column>
      <el-table-column
        prop="createdByName"
        align="center"
        label="接待员工"
      >
      </el-table-column>
      <el-table-column prop="type" align="center" label="类型" >
        <template slot-scope="scope">
          <span>全天房</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="current"
      :page-size="size"
      style="text-align: right; margin-top: 20px"
      layout="total, prev, pager, next"
      class="paginationStyle"
      :total="total"
    >
    </el-pagination>
    <div class="submitButton" style="margin-top: 20px">
      <el-button @click="remoteButton"> 取消 </el-button>
      <el-button @click="submitButton"> 夜审 </el-button>
    </div>
  </div>
</template>

<script>
import { NightAuditorAdd, NightAuditorData } from "_api/conditionOfHouse";
export default {
  name: "nigntAuditor",
  data() {
    return {
      current: 1,
      size: 6,
      total: 0,
      tableFrom: [],
      AddFrom: {},
      currentPage1: 1,
      tableData: [],
	  tableheight: 0, //表格高度
    };
  },
  // created() {
  //   this.NightAuditorListed();
  // },
  mounted() {
    this.autoHeight();
  },
  methods: {
    //取消
    remoteButton() {
      this.$emit("OffNightAuditor");
      this.$message({
            type:'error',
            message: '取消夜审操作'
          })
    },
     //当某一行被双击时会触发该事件
     rowDblclick(row) {
      this.$emit("OffNightAuditor",row);
    },
    //夜审新增财务信息
    NightAuditorAdded() {
      const params = {
        orgId: localStorage.getItem("orgid"),
        userId: localStorage.getItem("userId"),
      };
      NightAuditorAdd(params).then((res) => {
        if (res.data.code === 0) {
          this.$emit("OffNightAuditor");
          this.$message({
            type:'success',
            message: '夜审成功'
          })
        } else {
          this.$message(res.data.msg);
        }
      });
    },
    submitButton() {
      if(this.tableData.length > 0) {
        this.NightAuditorAdded();
      } else{
        this.$message({
          type: 'error',
          message: '当前无夜审数据，请稍后再试'
        })
      }
      
    },
    //夜审分页数据查看
    NightAuditorListed(model = {}) {
      const params = {
        current: this.current,
        size: this.size,
        model: {
          ...model,
          state: true,
          orgId: localStorage.getItem("orgid"),
          userId: localStorage.getItem("userId"),
        },
      };
      NightAuditorData(params).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.data.records;
          this.total = Number(res.data.data.total);
        } else {
          this.$message(res.data.errorMsg);
        }
      });
    },
    handleSizeChange() {},
    handleCurrentChange(val) {
      this.current = val;
      this.NightAuditorListed();
    },
    // 让表格奇数行有背景色
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex % 2 == 1) {
        return " color: '#4C4C4C'";
      } else {
        return "color: '#4C4C4C'";
      }
    },
	
	autoHeight() {
	  // 初始化的时候，设置高度
	  this.setHeight();
	  // 改变浏览器窗口大小的时候，设置高度
	  window.addEventListener("resize", () => {
	    this.setHeight();
	  });
	},
	// 设置高度
	setHeight() {
	  this.$nextTick(() => {
	    if (this.$refs.nigntAuditor) {
	      let height = this.$refs.nigntAuditor.offsetHeight;
		  if(height<=200){
			  this.tableheight = height -40;
		  }else if(height<=350){
			  this.tableheight = height -100;
		  }else if(height<=400){
			  this.tableheight = height -40;
		  }else{
			  this.tableheight = height -140;
		  }
	      // this.tableheight = height -180; // 这里的X就是顶部栏的高度
	      // console.log('高度',this.tableheight)
	      // document.getElementsByClassName("el-table__fixed-body-wrapper").style.max-height = "440px";
	    }
	  });
	},
	
  },
};
</script>

<style lang="scss" scoped>
.el-table {
  ::v-deep .el-table__body-wrapper::-webkit-scrollbar {
    width: 10px; /*滚动条宽度*/
    height: 13px; /*滚动条高度*/
  }
  /*定义滚动条轨道 内阴影+圆角*/
  ::v-deep .el-table__body-wrapper::-webkit-scrollbar-track {
    box-shadow: 0px 1px 3px #fff inset; /*滚动条的背景区域的内阴影*/
    border-radius: 10px; /*滚动条的背景区域的圆角*/
    background-color: #fff; /*滚动条的背景颜色*/
  }
  /*定义滑块 内阴影+圆角*/
  ::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
    box-shadow: 0px 1px 3px #aacde4 inset; /*滚动条的内阴影*/
    border-radius: 10px; /*滚动条的圆角*/
    background-color: #aacde4; /*滚动条的背景颜色*/
  }
}

.nigntAuditor {
  // background-color: #e8fdfe;
  margin-top: 15px;
  padding-right: 10px;
  height: 100%;
  .paginationStyle {
    ::v-deep .btn-prev {
      // background-color: #e8fdfe;
    }
    .el-pager {
      // background-color: #e8fdfe;
      ::v-deep .number,
      .el-icon more btn-quicknext el-icon-more {
        // background-color: #e8fdfe;
      }
    }
    ::v-deep .btn-next {
      //   background-color: #e8fdfe;
    }
  }
  .submitButton {
    width: 100%;
    height: 50px;
    text-align: right;
    padding-right: 5px;
    box-sizing: border-box;
    line-height: 70px;
    
    .el-button {
      width: 100px;
      height: 40px;
      background-color: #fff;
      color: #999999;
      border-radius: 6px;
    }
    .el-button:last-child {
      background-color: #5cb5b8;
      color: #fff;
    }
  }
}
</style>