import request from '_utils/request'
import qs from 'qs'

const repastInventoryList = (params = {}) => (
    request.post('/base/repast_inventory_record/page', params)
)
//分页列表查询:订单
const repastorderPage = (params = {}) => (
    request.post('/base/repast_order/page', params)
)
//查看数据统计:订单
const repastorderviewIist = () => (
    request.get('/base/repast_order/view_list')
)
//订单修改接口
const repastorderviewUpdate = (params = {}) => (
    request.put('/base/repast_order', params)
)
//付款方式查看

const parmentApi = (params = {}) => (
    request.get('/wineshop/payment_method/type_view', {
        params: qs.stringify(params),
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
    })
)
 //协议单位查看
 const BargainingUnitAll = (params = {}) => (
    request.get('/wineshop/agreement_unit/type_view',
    {
        headers: {
           'Content-Type': 'application/x-www-form-urlencoded' ,
          
        },
        params:  qs.stringify(params)
    }
    ))
    //挂账房单新增财务
    const saveAgreementList = (params = {}) => (
        request.post('/base/repast_order/save_agreement', params)
    )
//营业收入统计分页
const reportFormsList = (params = {}) => (
    request.post('/base/report_forms/income_page', params)
)
//营业收入统计导出
const reportFormsExport = (params = {}) => (
    request.post('/base/report_forms/income_export', params,{
        responseType: 'arraybuffer',
    
      })
)
//餐饮商品销售统计分页
const GetGoodsList = (params = {}) => (
    request.post('/base/report_forms/asle_page', params)
)
//餐饮商品销售统计导出
const GetGoodsExport = (params = {}) => (
    request.post('/base/report_forms/asle_export', params,{
        responseType: 'arraybuffer',
    
      })
)
//餐饮商品库存统计分页
const inventoryPage = (params = {}) => (
    request.post('/base/report_forms/inventory_page', params)
)
//餐饮商品库存统计分页
const inventoryExport = (params = {}) => (
    request.post('/base/report_forms/inventory_export', params,{
        responseType: 'arraybuffer',
    
      })
)
//餐桌：分页列表查询
const repastTablePage = (params = {}) => (
    request.post('/base/repastTable/page', params)
)
//餐桌： 客厅汇总
const repastParlorCount = (params = {}) => (
    request.get('/base/repastParlor/count?orgId=' + params.orgId )
)
//客厅分页列表
const repastParlorPage = (params = {}) => (
    request.post('/base/repastParlor/page' , params )
)
//添加客厅
const repastParlorAdd = (params = {}) => (
    request.post('/base/repastParlor' , params )
)
//修改客厅
const repastParlorUpadate = (params = {}) => (
    request.put('/base/repastParlor' , params )
)
//添加餐桌
const repastTableAdd = (params = {}) => (
    request.post('/base/repastTable' , params )
)
//修改餐桌
const repastTableUpdate = (params = {}) => (
    request.put('/base/repastTable' , params )
)
//获取二维码
const twoDimensional = (params = {}) => (
    request.get('/base/qr/code/count?orgId=' + params.orgId +'&&' + "id=" + params.id ,
    {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        responseType: 'arraybuffer'
    }
    )
)
//创建商品订单
const createShopOrderAdd = (params = {}) => (
    request.post('/base/shopOrder/createShopOrder' , params )
)
//商品列表查看
const repast_foodsList = (params = {}) => (
    request.post('/base/repast_foods/app_page' , params )
)
//购物车添加商品
const repastOrderAdd = (params = {}) => (
    request.post('/base/repast_order/add_article' , params )
)
//购物车查看
const repastOrderView = (params = {}) => (
    request.post(`/base/repast_order/view_article?id=`+ params.id, params.foodsIds)
)
//购物车清空
const repastOrderDelete = (params = {}) => (
    request.post(`/base/repast_order/delete_article?id=`+ params.id, params.foodsIds)
)
//生成订单（点击结算按钮）
const repast_ordersList = (params = {}) => (
    request.post('/base/repast_order/save_order' , params )
)
// //餐饮后台生成订单（点击结算按钮）
// const CateringOrdersList = (params = {}) => (
//     request.post('/base/repast_order/save_order' , params )
// )
//餐饮订单：修改
const CateringOrdersUpadate = (params = {}) => (
    request.put('/base/repast_order' , params )
)
//新增客厅餐饮商品分类
const repastParlorTypeRelSave_list = (params = {}) => (
    request.post('/base/repastParlorTypeRel/save_list' , params )
)
//新增客厅餐饮商品分类:分页列表查询
const repastParlorTypeRelPage = (params = {}) => (
    request.post('/base/repastParlorTypeRel/page' ,params )
)
//餐饮待结算订单加菜
const repastParlorTypeRelSaveList = (params = {}) => (
    request.post('/base/repast_order/add_batch' , params )
)
//餐饮待结算订单退菜
const repast_orderOut_batch = (params = {}) => (
    request.post('/base/repast_order/out_batch' , params )
)
//餐桌状态列表查看
const repastTableState = (params = {}) => (
    request.post('/base/repastTable/state_page' , params )
)
export {
    repastInventoryList,
    repastorderPage,
    repastorderviewIist,
    reportFormsList,
    reportFormsExport,
    GetGoodsList,
    GetGoodsExport,
    inventoryPage,
    inventoryExport,
    repastTablePage,
    repastParlorCount,
    repastParlorPage,
    repastParlorAdd,
    repastParlorUpadate,
    repastTableAdd,
    repastTableUpdate,
    repastorderviewUpdate,
    twoDimensional,
    parmentApi,
    BargainingUnitAll,
    saveAgreementList,
    createShopOrderAdd,
    repast_foodsList,
    repastOrderAdd,
    repastOrderView,
    repastOrderDelete,
    repast_ordersList,
    CateringOrdersUpadate,
    repastParlorTypeRelSave_list,
    repastParlorTypeRelPage,
    repastParlorTypeRelSaveList,
    repast_orderOut_batch,
	repastTableState,
}