<template>
  <!-- 锁房弹窗 -->
  <div class="LockSheet_box">
    <div class="LockSheet" v-if="!roomInfo.status.includes(6)">
      <div class="lockMain">
        <!-- 头部标题部分 -->
        <div class="membership_title">
          <p>{{ roomInfo.status.includes(6) ? "解锁房间" : "锁房" }}</p>
          <i
            class="el-icon-close"
            style="font-size: 25px"
            @click="offOperator"
          ></i>
        </div>
        <!-- 头部标题部分 -->
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="原因:" prop="remark" >
			  <!-- 400 -->
            <el-input
              type="textarea"
              :rows="3"
              style="width: 90%"
              v-model="ruleForm.remark"
            >
            </el-input>
          </el-form-item>
          <el-form-item class="form_item" style="text-align: right;">
            <el-button @click="submitForm('ruleForm')"> 确认 </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="save_main" v-if="roomInfo.status.includes(6)">
          <div class="save_size">
            <p>
              <img src="../../../../assets/icons/警告 (1).png" alt="">
            确认
          </p>
          <span class="size_floot">是否解除当前锁定房间状态？</span>
          </div>
         
          <div class="save_button">
            <el-button
            @click="noDelete"
            class="cancelButton">
              取消
            </el-button>
            <el-button  
            @click="submitForm(2)"
            style="background-color: #FDD4D9;color: #D9001B;"
            class="submitButton"
            >
              确定
            </el-button>
          </div>
        </div>
  </div>
</template>

<script>
import { roomsuoupdate } from "@/api/housingSystem";
export default {
  name: "LockSheet",
  props: {
    roomInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      ruleForm: {
        id: undefined,
        remark: '',
        state: this.roomInfo.status.includes(6) ? 7 : 6,
      },
      rules: {
        textarea: [
          {
            required: false,
            message: "请输入锁房原因",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.ruleForm.id = this.roomInfo.id;
    console.log(this.roomInfo);
  },
  methods: {
    //点击右上角图标关闭弹窗
    offOperator() {
      this.$emit("offSheet", [false]);
    },
    noDelete() {
      this.$emit("offSheet", [false]);
    },
    submitForm(formName) {
      if(formName == 2) {
        //解锁房间
        var that = this;
        const params = {
            id: that.ruleForm.id,
            remark: that.ruleForm.remark,
            state: that.ruleForm.state,
          };
        
            
            roomsuoupdate(params).then((res) => {
              if (res.data.code == 0) {
                that.$message({
                  type: "success",
                  message: "操作成功！",
                });
                this.$emit("offSheet", [false, this.ruleForm]);
              } else {
              }
            });
      }else{
        var that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // that.ruleForm.orgId= localStorage.getItem('orgid');
          const params = {
            id: that.ruleForm.id,
            remark: that.ruleForm.remark,
            state: that.ruleForm.state,
          };
          if (params.remark) {
            this.$emit("offSheet", [false, this.ruleForm]);
            roomsuoupdate(params).then((res) => {
              if (res.data.code == 0) {
                that.$message({
                  type: "success",
                  message: "操作成功！",
                });
              } else {
              }
              that.offOperator();
            });
          } else {
            this.$message.error({
              type: "error",
              message: "请输入原因",
            });
          }
        } else {
          return false;
        }
      });
      }
    
    },
  },
};
</script>

<style lang="scss" scoped>
.LockSheet_box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(133, 106, 106, 0.4);
  display: flex;
  z-index:10;
  justify-content: center;
  align-items: center;

  .LockSheet {
    width: 600px;
    height: 200px;
    padding-bottom: 20px;
    box-sizing: border-box;
    background-color: #fff;

    .lockMain {
      width: 100%;
      height: 186px;
      // background: linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%);
      border-radius: 5px;

      //头部标题部分css
      .membership_title {
        width: 100%;
        height: 30px;
        display: flex;
        padding-top: 10px;
        align-items: center;
        box-sizing: border-box;
        margin-bottom: 10px;

        p {
          flex: 1;
          padding-left: 20px;
          box-sizing: border-box;
          text-align: left;
        }
      }

      .demo-ruleForm {
        .form_item {
          width: 100%;
          text-align: center;
          padding-right: 20px;
          box-sizing: border-box;

          .el-button {
            width: 100px;
            height: 35px;
            background-color: #5cb5b8;
            color: #fff;
            padding: 0px 0px;
            box-sizing: border-box;
            border: none;
          }
        }
      }
    }
  }
   //阴影里的 删除盒子css
   .save_main{
      width: 500px;
      height: 180px;
      background-color: #fff;
      .save_size{
        width: 100%;
        height: 126px;
        background: linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%);
        border-radius: 5px;
        padding-top: 30px;
        box-sizing: border-box;
       
        p{
          height: 40px;
          padding-left: 45px;
          box-sizing: border-box;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        
          img{
            width: 15px;
            height: 15px;
          }
        }
        .size_floot{
          padding-left: 43px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #555555;
      }
    }
      .save_button{
        // display: flex;
        // justify-content: end;
        text-align: right;
        padding-right: 20px;
        box-sizing: border-box;
        .el-button{
          margin-left: 20px;
          width: 100px;
        }
      }
    }
}
</style>