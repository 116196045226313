<template>
  <div class="FinalStatement">
    <el-row class="financeBoxss">
      <el-col :span="16">
        <div class="title">
          <p>云旅PMS酒店</p>
          <p style="margin-top: 10px;">班结财务报表</p>
          <!-- <p class="priceSize">挂账金额： ￥{{ preAuthorizationPrice }}</p> -->
        </div>
        <p
          v-if="className.length === 0"
          style="text-align: center; margin-top: 10px; margin: bottom 10px"
        >
          暂无数据
        </p>
        <div class="morningShift" >
         
          <ul  class="priceBox" v-for="(it, oi) in className" :key="oi">
            <p class="sizeButton">【{{ it }}】</p>
            <li v-for="(el, index) in tableFrom[it]" :key="index">
              {{ el.paymentName }} <span>￥{{ el.price }}</span>
            </li>
           
            <li class="priceTotal">
              小计 <span>￥{{ allPrice[oi]?allPrice[oi]:0 }}</span>
            </li >

            <li v-show="oi == fromLengths">
              总计 <span>￥{{ pricesAll }}</span>
            </li>
          </ul>
        </div>
        <!-- <div class="middleShift">
      <p>【2023-03-09中班】</p>
      <ul>
        <li>现金 <span>$2255.00</span></li>
        <li>挂账 <span>$2255.00</span></li>
        <li>微信 <span>$2255.00</span></li>
        <li>支付宝 <span>$2255.00</span></li>
        <li>小计 <span>$2255.00</span></li>
       
      </ul>
    </div> -->
      </el-col>
      <el-col :span="6" :offset="2" class="right_box">
        <div class="navBox">
          <p>预授权金额</p>
          <br />
          <span></span
          ><span
            >{{ preAuthorizationPrice ? preAuthorizationPrice : 0 }}/元
            <!-- <i class="el-icon-search" style="color: #5cb5b8"></i> -->
          </span>
        </div>
        <div class="navBox">
          <p>接班备用金</p>
          <br />
          <span></span
          ><span
            >{{ currentMoney ? currentMoney : 0 }}/元
            <i @click="reserveButton" class="el-icon-setting"></i>
          </span>
        </div>
      </el-col>
    </el-row>
    <el-row class="submitButton">
      <el-button @click="submitFrom"> 班结缴款 </el-button>
    </el-row>

    <el-dialog
      title="备用金抽屉"
      append-to-body
      destroy-on-close
      :visible.sync="isReserve"
      width="700px"
      class="dialogReserve"
      :before-close="ReserveClose"
    >
      <reserve-box ref="reserveREF" @reserveBOX="reserveBOX"></reserve-box>
    </el-dialog>


    <div class="savePop" v-if="isTAN">
        <!-- 弹窗 -->
        <div class="save_main">
          <div class="save_size">
            <p>
              <img src="../../../assets/icons/警告 (1).png" alt="">
              确定进行交班缴款吗?
          </p>
          <span class="size_floot">请前台停止办理入住、退房结账等操作，</span><br> <br>  
          <span class="size_floot" >并核对账务等待班结完成，此操作不可撤销!</span>
          </div>
         
          <div class="save_button">
            <el-button
            @click="areDelete(0)"
            class="cancelButton">
              取消
            </el-button>
            <el-button  
            @click="areDelete(1)"
            style=""
            class="cancelButton"
            >
              确定
            </el-button>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import {
  SettlementPayment,
  SettlementPaymentAll,
  getStandbyMoney,
} from "_api/conditionOfHouse";
import ReserveBox from "./ReserveBox.vue";
export default {
  name: "FinalStatement",
  components: {
    ReserveBox,
  },
  data() {
    return {
      ruleFrom: {},
      tableFrom: [], //当前班结数据
      className: [], //班次
      newData: "", //时间
      fromLengths: 0,
      isTAN: false,
      allPrice: [], //小计
      pricesAll: 0, //总计
      currentMoney: null,
      isReserve: false,
      preAuthorizationPrice: null,
    };
  },
  created() {
    // this.settlementPaymentAlled()
  },
  methods: {
    areDelete(num) {
      if(num == 0) {
        //取消
        this.$message({
            type: 'error',
            message: '班结缴款操作已取消'
          })
        this.isTAN = false
      }else{
        this.SettlementPaymented();
      
      }
    },
    //点击备用金旁的设置触发
    reserveButton() {
      this.isReserve = true;
      this.$nextTick(() => {
        this.$refs.reserveREF.classesStandbyMoneyPageed(this.currentMoney);
      });
    },
    ReserveClose(done) {
      done();
      this.settlementPaymentAlled();
    },
    //获取当前剩余备用金
    getStandbyMoneyed() {
      getStandbyMoney().then((res) => {
        if (res.data.code === 0) {
          this.currentMoney = res.data.data.currentMoney;
        }
      });
    },
    //备用金的自定义事件
    reserveBOX() {
      this.isReserve = false
      this.getStandbyMoneyed()
    },
    //点击班结缴纳触发事件
    submitFrom() {
    this.isTAN = true
                   
    },
    //班结缴款
    SettlementPaymented() {
      const params = {
        orgId: localStorage.getItem("orgid"),
        userId: localStorage.getItem("userId"),
      };
      SettlementPayment(params).then((res) => {
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: '班结缴款完成'
          })
          this.$emit("FinalStatementEvent", ["班结缴款"]);
        } else {
          this.$message(res.data.errorMsg);
        }
      });
    },
    //当前班结数据查看
    settlementPaymentAlled() {
      console.log(localStorage.getItem("userId"));
      const params = {
        orgId: localStorage.getItem("orgid"),
        userId: localStorage.getItem("userId"),
      };
      SettlementPaymentAll(params).then((res) => {
        if (res.data.code === 0) {
          this.className.splice(0);
          for (var key in res.data.data) {
            console.log(key.slice(0,10),key.slice(0,10).length);
            console.log(key);
            if (key != "preAuthorizationPrice") {
              this.className.push(key);
            }else{
              //挂账金额
              this.preAuthorizationPrice = res.data.data.preAuthorizationPrice;
            }
          }
          // for (var key in res.data.data) {
          //   if (key != "preAuthorizationPrice"){
          //     if{
          //     this.className.unshift(key);
          //   }
          //   }
          // }
          console.log(this.className);
          var arr = [];
          this.className.forEach((el,index) => {
           if(el != "preAuthorizationPrice") {
            if(el.length > 5) {
              console.log(el);
              arr.push({dateTime :new Date(el.slice(0,10)).getTime() ,indexs: index,key: el })
            }
          
           }
          });
          arr.sort(function (a,b) {
            return (a.dateTime - b.dateTime)
          })
          console.log(arr);
          this.className.splice(0)
          arr.forEach(ek => {
            this.className.push(ek.key)
          })
          // arr.forEach(ib => {
           
          //   this.className.forEach((em, ios) => {
          //     if(em != "preAuthorizationPrice" ) {
          //       if(em.length < 5) {
          //         if(ib.dateTime == new Date(em.slice(0,10)).getTime()){
          //          var mes = this.className[ios]
          //          this.className.splice(ios,1)
          //          this.className.push(mes)
          //         }
          //       }
          //     }
             
          //   })
          // })
          // console.log(this.className);
          this.tableFrom = res.data.data
          // this.tableFrom.push(arr);
          this.fromLengths = this.className.length - 1;
          this.mouseenter(new Date());
          console.log(this.tableFrom);
          console.log(this.fromLengths);
          console.log();
          this.priceFeom(this.className);
        } else {
          this.$message(res.data.errorMsg);
        }
      });
    },
    //计算小计和总计的价格
    priceFeom() {
      var subtotal = 0;
      this.pricesAll = 0;
      console.log(this.className);
      console.log(this.tableFrom);
      this.allPrice.splice(0);
      this.className.forEach(el => {
        if(this.tableFrom[el].length >0) {
          this.tableFrom[el].forEach(item =>{
            subtotal = (parseFloat(item.price) + parseFloat(subtotal)).toFixed(2)
          })
          this.allPrice.push(subtotal);
          subtotal = 0;
        } else{
          this.allPrice.push(0);
        }
      })
      this.allPrice.forEach((el) => {
        this.pricesAll = parseFloat(parseFloat(this.pricesAll) + parseFloat(el))
      });
      this.pricesAll = this.pricesAll.toFixed(2)
      console.log(this.allPrice);
      console.log(this.pricesAll);
    },
    //当天日期
    mouseenter(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return (this.newData = y + "-" + m + "-" + d);
    },
  },
};
</script>

<style lang="scss" scoped>
 ::v-deep .el-dialog__header {
    // background-color: #e8fdfe;
    height: 50px;
  }

  ::v-deep .el-dialog__body {
    padding-top: 5px;
    max-height: 600px;
    overflow-y: auto;
    box-sizing: border-box;
    // background-color: #e8fdfe;
  }
.priceBox{
  padding: 0px 40px;
  box-sizing: border-box;
}
.FinalStatement {
  width: 100%;

  ::v-deep .el-dialog__header {
    // background-color: #e8fdfe;
    height: 50px;
  }

  ::v-deep .el-dialog__body {
    padding-top: 5px;
    height: 600px;
    overflow-y: auto;
    box-sizing: border-box;
    // background-color: #e8fdfe;
  }
  .el-row.financeBoxss{
    max-height: 400px;
    overflow-y: auto;
  }
  .right_box {
    .navBox {
      text-align: center;
      .el-icon-setting {
        color: #5cb5b8;
      }
    }
    .navBox:first-child{
      margin-bottom: 20px;
    }
  }
  .title {
    border-bottom: 1px solid gray;
    padding-bottom: 10px;
    box-sizing: border-box;
    p:nth-child(1) {
      text-align: center;
      font-size: 20px;
      color: black;
    }
    .priceSize {
      font-size: 13px;
      width: 100%;
      text-align: end;
      margin-top: 10px;
    }
  }
  .morningShift {
    padding-top: 10px;

    box-sizing: border-box;
    ul {
      width: 100%;
      li {
        width: 100%;
        height: 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          font-weight: bold;
          text-align: left;
        }
      }
      p:first-child{
        margin-bottom: 10px;
      }
      p{
        margin-top: 10px;
      }
      li.priceTotal {
        color: gray;
        border-bottom: 1px dotted gray;
        span {
          font-weight: 100;
        }
      }
      .lipayment {
        border: none;
      }
    }
  }
  .middleShift {
    padding-top: 10px;
    box-sizing: border-box;
    ul {
      width: 100%;
      li {
        width: 100%;
        height: 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          font-weight: bold;
        }
      }
      li:nth-child(5) {
        color: gray;

        span {
          font-weight: 100;
        }
      }
      li:nth-child(6) {
        color: gray;

        span {
          font-weight: 100;
        }
      }
      .lipayment {
        border: none;
      }
    }
  }
  .submitButton {
    width: 100%;
    height: 60px;
    margin-top: 20px;
    margin-bottom: -30px;
    display: flex;
    align-items: center;
    justify-content: center;
    .el-button {
      width: 110px;
      height: 40px;
      border-radius: 4px;
      background-color: #5cb5b8;
      color: #fff;
    }
  }
}
//弹窗的阴影盒子
.savePop{
    position: fixed;
          top:0;
          bottom:0;
          left:0;
          right:0;
          background-color: rgba(133, 106, 106, 0.4);
          z-index: 100;
          display: flex;
          justify-content: center;
          align-items: center;
    //阴影里的 删除盒子css
    .save_main{
      width: 500px;
      height: 180px;
      background-color: #fff;
      .save_size{
        width: 100%;
        height: 126px;
        background: linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%);
        border-radius: 5px;
        padding-top: 30px;
        box-sizing: border-box;
       
        p{
          height: 40px;
          padding-left: 45px;
          box-sizing: border-box;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        
          img{
            width: 15px;
            height: 15px;
          }
        }
        .size_floot{
          padding-left: 43px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #b3b0b0;
      }
    }
      .save_button{
        display: flex;
        justify-content: end;
        text-align: right;
        padding-right: 20px;
        box-sizing: border-box;
        .el-button.cancelButton{
          margin-left: 20px;
          width: 70px;
          height: 30px;
          padding: 0px 0px;
          background-color: #5cb5b8;
          color: #fff;
        }
      }
    }
  }
</style>