<template>
  <!-- 退回和冲减的弹窗 -->
  <div class="outpriceBox" @click="consumer_offs">
    <div class="save_main">
      <div class="save_size">
        <p>
          <img src="../../../../../../assets/icons/警告 (1).png" alt="" />
          确定要{{ priceFrom.type == "0" ? "退回" : "冲减" }} 此笔{{
            priceFrom.type == "0" ? "付款" : "消费"
          }}吗？
        </p>
        <!-- {{priceFrom.price}} -->
        <span class="size_floot">
          请退客人￥- <span v-if="priceNums">{{ ruleFrom.price }}</span>
          <el-input
          v-if="!priceNums"
            v-model="ruleFrom.price"
            placeholder="请输入金额"
          ></el-input>
          {{ priceFrom.name }}</span
        >
      </div>
	  <div class="input-box">
		  <el-input
		  v-if="!priceNums"
		    v-model="ruleFrom.remarks"
		    placeholder="请输入备注"
		  ></el-input>
	  </div>

      <div class="save_button">
        <el-button @click="noDelete" class="cancelButton"> 取消 </el-button>
        <el-button
          @click="areDelete"
          style="background-color: #fdd4d9; color: #d9001b"
          class="submitButton"
        >
          确定
        </el-button>
      </div>
	  <div class="footer-bottom"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "outpriceBox",
  props: ["priceFrom"],
  data() {
    return {
      ruleFrom: {
        price: null,
		remarks:'',
      },
      priceNums: false,
      row: {}
    };
  },
  created() {},
  methods: {
    areDelete() {
      console.log(this.row);
      if (this.row.itemType == "0") {
        //付款
        this.$emit("outpriceBox", [1, "0",this.ruleFrom.price,this.ruleFrom.remarks]);
      } else if (this.priceFrom.type == "1") {
        //消费
        this.$emit("outpriceBox", [1, "1", this.ruleFrom.price,this.ruleFrom.remarks]);
      }
    },
    noDelete() {
      this.$emit("outpriceBox", [0]);
    },
    //点击确认和取消按钮和弹窗以外关闭弹窗
    consumer_offs(e) {
      // this.isConsumotion = false
      console.log(e.target.nodeName);
      console.log(e.target.className);
      if (e.target.className === "outpriceBox") {
        this.$emit("outpriceBox", -1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.outpriceBox {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(133, 106, 106, 0.4);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  .save_main {
    width: 400px;
    min-height: 180px;
    background-color: #fff;
    .save_size {
      width: 100%;
      height: 126px;
      // background: linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%);
      border-radius: 5px;
      padding-top: 30px;
      box-sizing: border-box;

      p {
        height: 40px;
        padding-left: 45px;
        box-sizing: border-box;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;

        img {
          width: 15px;
          height: 15px;
        }
      }
      .size_floot {
        // padding-left: 80px;
        padding-left: 50px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #555555;
        .el-input {
          width: 120px;
          height: 35px;
        }
      }
    }
    .save_button {
      display: flex;
      justify-content: end;
      padding-right: 40px;
      box-sizing: border-box;
      .el-button {
        margin-left: 20px;
        width: 100px;
      }
    }
  }
}
.input-box{
	padding: 5px 50px;
}
.footer-bottom{
	width: 100%;
	height: 10px;
}
</style>