<template>
  <!-- 联房结账弹窗 -->
  <div class="PeerSettleaccounts" @click="close_page">
    <div class="SettleAccounts_main">
      <!-- 金额的汇总 -->
      <div class="settle_statistics">
        <ul>
          <li>
            未结统计:联房消费:
            <span
            style="color: red">￥{{ consumption }}</span>
            <!-- v-if="consumption.length < 9" -->
            <!-- <el-tooltip
              v-else
              class="item"
              effect="light"
              placement="top-start"
            >
              <div slot="content">￥{{ parseFloat(consumption).toFixed(2) }}</div>
              <span>
                {{
                  String(consumption)
                    ? String(consumption).length > 9
                      ? "￥" + String(consumption).slice(0, String(consumption).split('').indexOf('.') > 9?String(consumption).split('').indexOf('.')-2:9) 
                      : "￥" + String(consumption)
                    : "￥0"
                }}
              </span>
            </el-tooltip> -->
          </li>
          <div></div>
          <li>
            联房付款: 
            <!-- v-if="String(payments).length > 9" -->
            <span >￥{{ payments }}</span>
            <!-- <el-tooltip
              v-else
              class="item"
              effect="light"
              placement="top-start"
            >
              <div slot="content">￥{{ parseFloat(payments).toFixed(2) }}</div>
              <span>
                {{
                  payment
                    ? String(payments).length > 9
                      ? "￥" + String(payments).slice(0, String(payments).split('').indexOf('.') > 9?String(payments).split('').indexOf('.')-2:9) 
                      : "￥" + String(payments)
                    : "￥0"
                }}
              </span>
            </el-tooltip> -->
          </li>
          <div></div>
          <li>
            平账金额: 
            <!--  v-if="String(BalanceOfAccount).length < 9" -->
            <span>￥{{ BalanceOfAccount }}</span>
            <!-- <el-tooltip
              v-else
              class="item"
              effect="light"
              placement="top-start"
            >
              <div slot="content">￥{{ parseFloat(BalanceOfAccount).toFixed(2) }}</div>
              <span>
                {{
                  String(BalanceOfAccount)
                    ? String(BalanceOfAccount).length > 9
                      ? "￥" + String(BalanceOfAccount).slice(0, String(BalanceOfAccount).split('').indexOf('.') > 9?String(BalanceOfAccount).split('').indexOf('.')-2:9)
                      : "￥" + String(BalanceOfAccount)
                    : "￥0"
                }}
              </span>
            </el-tooltip> -->
          </li>
          <div></div>
          <li>
            已住: <span>{{ this.PlateFrom[0].reserveDays }}</span>
          </li>
        </ul>
      </div>
      <!-- 金额的汇总 -->
      <!-- 客户信息记录 -->
      <div class="customerInformation">
        <el-form
          :label-position="labelPosition"
          label-width="80px"
          :model="PlateFrom[0]"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item label="顾客姓名">
                <el-input
                  v-model="PlateFrom[0].name"
                  :disabled="true"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="订单编号">
                <el-input v-model="PlateFrom[0].id" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <el-form-item label="手机号码">
                <el-input
                  v-model="PlateFrom[0].mobile"
                  :disabled="true"
                  placeholder="未登记"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="入住时间">
                <el-input
                  v-model="PlateFrom[0].arrivalTime"
                  :disabled="true"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <!-- 表格展示 -->
        <div class="table_box">
          <el-table
            :data="tableData"
            border
            :row-style="{height:'35px'}" :cell-style="{padding: '0'}"
            :max-height="'200px'"
            style="
              width: 100%;
              border-radius: 8px;
            "
          >
            <el-table-column
              prop="projectName"
              align="center"
              label="入账项目"
              style="width: 33%"
            >
            </el-table-column>
            <el-table-column label="项目数量" align="center" style="width: 33%">
              <template slot-scope="scope">
                <span>{{ scope.row.quantity }}笔</span>
              </template>
            </el-table-column>
            <el-table-column prop="address" align="center" label="金额小计">
              <template slot-scope="scope">
                <span>￥{{ scope.row.price }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- 客户信息记录 -->
      <!-- 支付结算 -->
      <div class="payment_settlement">
        <p>
          <span></span>
          支付结算
        </p>

        <el-form
          :label-position="labelPosition"
          label-width="80px"
          :model="formLabelAlign"
        >
          <el-row v-for="(el, index) in AddPriceFrom" :key="index">
            <el-col :span="10">
              <el-form-item
                :label="
                  AddPriceFrom[index].BalanceOfAccount >= 0
                    ? '应收金额'
                    : '应退金额'
                "
              >
                <!-- :disabled="AddPriceFrom[index].isDisabled" -->
                <el-input
                  v-model.number="AddPriceFrom[index].BalanceOfAccount"
                  :disabled="index < 1"
                  style="font-size: 20px; font-weight: 1000"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-select
                clearable
                v-model="AddPriceFrom[index].paymentId"
                placeholder="付款方式"
                @change="PriceInput(AddPriceFrom[index].paymentId, index)"
              >
                <el-option
                  v-for="(item, ix) in payment"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :span="6" v-if="AddPriceFrom[index].isInput">
              <el-select
                clearable
                v-model="AddPriceFrom[index].agreementUnitId"
                placeholder="挂账协议单位"
                @change="Bargainingputs(AddPriceFrom[index].agreementUnitId, index)"
              >
                <el-option
                  v-for="(item, indexs) in BargainingUnitList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :span="2" v-if="AddPriceFrom.length != 1 && index >= 1">
              <el-button class="DeteleButton" @click="DeleteArr(index)"
                >删除</el-button
              >
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 支付结算 -->

      <!-- 提交部分 -->
      <div class="ending">
        <div class="ending_left">
          <span> 结账限时： </span>
          <el-statistic
            ref="statistic"
            @finish="hilarity"
            format="mm分ss秒"
            :value="deadline4"
            time-indices
            class="statistic_main"
          >
          </el-statistic>
        </div>
        <div class="ending_right">
          <!-- <el-button class="deleteButton" @click="onSubmit(1)">会员结账</el-button> -->
          <el-button class="addButton" @click="AddSubmit()">增加付款</el-button>
          <el-button class="deleteButton" @click="onSubmit(2)">确认结账</el-button>
        </div>
      </div>
      <!-- 提交部分 -->
    </div>
    <el-dialog
      title="会员卡结账"
      :visible.sync="isConsumer"
      width="600px"
      class="addMember"
      append-to-body
      destroy-on-close
      :before-close="handleClose"
    >
      <member-consumer ref="ConsumerBox" @MemberPrice="MemberPrice"></member-consumer>
    </el-dialog>
  </div>
</template>
  
  <script>
import {
  EditdataStatistics,
  financeManagementConnectIist,
  ListFinance,
  paymentList,
  financeManagementSaveList,
} from "_api/orderFrom";
import { printertextreceipt } from "_api/verify";
import { BargainingUnitAll } from "_api/advanceOrder";
import { UnitBargainingLook, listCheckOutTime } from "_api/systemSetting";
import { getGuestNo } from "_api/housingSystem";
import MemberConsumer from '_components/MemberConsumer.vue';
export default {
  name: "PeerSettleaccounts",
  components: {
    MemberConsumer
  },
  data() {
    return {
      Doo: null,
      fooo: null,
      //结账的参数
      SettleFrom: {
        paymentId: "",
        paymentName: "",
        BalanceOfAccount: parseFloat(this.BalanceOfAccount).toFixed(2) ,
        whetherRely: false, //是否挂账，
        agreementUnitId: null, //协议单位id
        agreementUnitName: null, //协议单位名称
      },
      BargainingUnitList: [], //所有协议单位
      formLabelAlign: this.$store.state.user.orderFrom,
      arrivalTimes: "",
      isInput: false,
      payment: [],
      tableData: [],
      current: 1,
      size: 10,
      numPays: 0,
      isConsumer: false,
      AccountAll: parseFloat(this.BalanceOfAccount).toFixed(2) ,
      labelPosition: "right",
      PlateFrom: [], //主房信息
      bargainingUnit: null, //消费方式的数据
      CheckOuttime: [], //退房时间的数据
      forms: {
        CheckOuttimeName: null,
        projectName: null,
        paymentId: null,
      },
      AllRooms: [], //所有联房信息
      projectNames: null,
      UpdateRoomDate: [], //财务批量更新的数据
      deadline4: Date.now() + 1000 * 60 * 5,
      AddPriceFrom: [
        {
          BalanceOfAccount:  parseFloat(this.BalanceOfAccount).toFixed(2), //价格
          fooo: null, //付款方式的下标
          isDisabled: true,
          isInput: false,
          paymentId: null,
          paymentName: null,
        },
      ],
      paymentId:'',
      endPriceFrom: [], //超时房费数据集合
      payments: 0, //总付款金额
      consumption: 0, //总消费金额
      BalanceOfAccount: 0, //结账金额
      endNums: 0,
      overtimeFrom: [],
      //打印小票数据
      projectDetails: [],
      tableDataFrom: [],
      paymentPrice: 0,
      reserveDays: '',
      totalPrice: 0,
      name: "",
      type: "", //0付款1消费
	  lunnum:0,
    };
  },
  created() {
    //  this.ListFinanceed()
  },
  // computed: {
  //   // 总付款金额
  //   payments() {
  //     let num = 0;
  //     this.tableData.forEach((item) => {
  //       if (item.state) {
  //         if (!item.authOver) {
  //           if (item.itemType == "0") {
  //             console.log(item);
  //             num = parseFloat(item.price) + num;
  //           }
  //         }
  //       }
  //     });
  //     return parseFloat(num);
  //   },
  //   //消费
  //   consumption() {
  //     let num = 0;
  //     this.tableData.forEach((item) => {
  //       if (item.state) {
  //         if (!item.authOver) {
  //           if (item.itemType == "1") {
  //             console.log(item);
  //             num = parseFloat(item.price) + num;
  //           }
  //         }
  //       }
  //     });
  //     return parseFloat(num);
  //   },
  //   BalanceOfAccount() {
  //     if (this.consumption < 0) {
  //       this.AddPriceFrom[0].BalanceOfAccount = parseFloat(
  //         this.consumption + this.payments
  //       );
  //       return parseFloat(this.consumption + this.payments);
  //     } else {
  //       this.AddPriceFrom[0].BalanceOfAccount = parseFloat(
  //         this.consumption - this.payments
  //       );
  //       return parseFloat(this.consumption - this.payments);
  //     }
  //   },
  // },
  methods: {
    //先财务新增，在订单更新
    SettleBox() {
      this.paymentListed();
      this.getNowDates(new Date(), this.PlateFrom[0].arrivalTime);
      this.UnitBargainingLooked();
    },
    getPriceNumber() {
      let num = 0;
      this.tableData.forEach((item) => {
        if (item.state) {
          if (!item.authOver && item.state && !item.preAuth) {
            if (item.itemType == "0") {
              console.log(item);
              num = parseFloat(item.price) + num;
            }
          }
        }
      });
      this.payments = parseFloat(num);
      num = 0;
      this.tableData.forEach((item) => {
        if (item.state) {
          if (!item.authOver && item.state && !item.preAuth) {
            if (item.itemType == "1") {
              console.log(item);
              num = parseFloat(item.price) + num;
            }
          }
        }
      });
      this.consumption = parseFloat(num);
      if (this.consumption < 0) {
        this.AddPriceFrom[0].BalanceOfAccount = parseFloat(
          this.consumption + this.payments
        );
        return parseFloat(this.consumption + this.payments);
      } else {
        this.AddPriceFrom[0].BalanceOfAccount = parseFloat(
          this.consumption - this.payments
        ).toFixed(2);
        return parseFloat(this.consumption - this.payments);
      }
    },
    //判断有没有超时
    outDataTable() {
      console.log(this.AllRooms);
      
      this.bargainingUnit.forEach(item => {
        if(item.name == "超时费") {
         this.paymentId = item.id
        }
      })
      console.log(this.paymentId);
      this.AllRooms.forEach((el) => {
        if (el.orderState != 0) {
          if (el.settlementType == 0) {
            let predepartureTime = new Date(el.predepartureTime).getTime();
            let newDate = new Date().getTime();
            if (parseFloat(newDate) > parseFloat(predepartureTime)) {
              //全天房超时了
              var dayss = Math.ceil(
                (parseFloat(newDate) - parseFloat(predepartureTime)) /
                  (1 * 24 * 60 * 60 * 1000)
              );
              var priceOut = dayss * parseFloat(el.roomRealityPrice);
              this.endPriceFrom.push({
                price: priceOut,
                orderId: el.id,
                itemType: "1",
                state: true,
                roomId: el.roomId,
                roomName: el.roomName, //房型名称
                userClassesId: localStorage.getItem("userClassesId"),
                operator: localStorage.getItem("name"),
                paymentId: this.paymentId,
                projectName: "超时费",
                type: "0",
                entryState: "2",
                classesId: localStorage.getItem("classesId"),
                createdName: localStorage.getItem("name"),
                classes: localStorage.getItem("classes"),
              });
            } else if (el.settlementType == 1) {
              //钟点房
              let predepartureTime = new Date(el.predepartureTime).getTime();
              let newDate = new Date().getTime();
              if (parseFloat(newDate) > parseFloat(predepartureTime)) {
                //钟点房超时了
                var hour = Math.ceil(
                  ((parseFloat(newDate) - parseFloat(predepartureTime)) /
                    (60 * 60 * 1000))/ parseFloat(el.hourLength)
                );
                var priceOut = hour * parseFloat(el.reserveDays);
                this.endPriceFrom.push({
                  price: priceOut,
                  orderId: el.id,
                  itemType: "1",
                  state: true,
                  roomId: el.roomId,
                  roomName: el.roomName, //房型名称
                  userClassesId: localStorage.getItem("userClassesId"),
                  operator: localStorage.getItem("name"),
                  paymentId: this.paymentId,
                  projectName: "超时费",
                  type: "0",
                  entryState: "2",
                  classesId: localStorage.getItem("classesId"),
                  createdName: localStorage.getItem("name"),
                  classes: localStorage.getItem("classes"),
                });
              }
            }
          }
        }
      });
     
      if(this.endPriceFrom.length !=0) {
        var allPrice = 0;
      this.endPriceFrom.forEach((it) => {
        allPrice = parseFloat(allPrice) + parseFloat(it.price);
      });
      allPrice = parseFloat( allPrice).toFixed(2)
      this.$confirm(
        `该联房用户留店时间已超出预留时间，是否给所有未退房的联房用户加收超时费用:  ￥${allPrice?allPrice:'0.00'}`,
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.$message({
            type: "success",
            message: "增加超时费用打开入账入口",
          });
          this.endPriceFrom.forEach((it) => {
				console.log('添加数据1',{
                  projectName: it.projectName,
                  quantity: 1,
                  price: parseFloat(it.price),
                })
                this.tableData.push({
                  projectName: it.projectName,
                  quantity: 1,
                  price: parseFloat(it.price),
                });
              });
              this.consumption =
                parseFloat(allPrice) + parseFloat(this.consumption);
              if (this.consumption < 0) {
                this.AddPriceFrom[0].BalanceOfAccount = parseFloat(
                  this.consumption + this.payments
                ).toFixed(2);
                return parseFloat(this.consumption + this.payments);
              } else {
                this.AddPriceFrom[0].BalanceOfAccount = parseFloat(
                  this.consumption - this.payments
                ).toFixed(2);
                return parseFloat(this.consumption - this.payments);
              }
          // this.financeManagementConnectIistADS()
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消增加超时费用操作",
          });
		   this.endPriceFrom.splice(0);
          // this.financeManagementConnectIistADS()
        });
      }
      
    },
    
  //联房财务查看接口
  financeManagementConnectIistADS() {
      const params = {
        type: false,
        connectRoomId: this.PlateFrom[0].connectRoomId,
      };
      financeManagementConnectIist(params).then((res) => {
        if (res.data.code == 0) {
          //把预授权和state为false的给筛选下来
          var tableData = res.data.data.filter(el => !el.preAuth && !el.authOver && el.state);
          this.overtimeFrom.splice(0)
          console.log('筛选',tableData);
          var orderFrom = [];
         
          console.log(this.AllRooms);
          this.AllRooms.forEach((ic) => {
            orderFrom.push({
              orderId: ic.id,
              arr: [],
              orderData: {...ic},//房单订单信息
              roomPrice: false,
              outTime: false,//判断有没有超时
              type: 0,//0全天房/1钟点房
            });
          });

          res.data.data.forEach((el) => {
            orderFrom.forEach((item) => {
              // if (item.orderId == el.orderId && el.roomId  == item.orderData.roomId) {
              //   //选中要退房的财务信息
              //   item.arr.push(el);
              // }
                if (item.orderId == el.orderId && el.state ) {
                //选中要退房的财务信息
                item.arr.push(el);
              }
            });
          });

          tableData.forEach(el => {
            if(el.projectName == '全日房费' || el.projectName == '钟点房费'){
              orderFrom.forEach((ik,indexss) => {
                if(ik.orderId == el.orderId) {
                        ik.roomPrice = true
                  orderFrom.splice(indexss,1)
                  return false
                }
              })
            }
          })
          orderFrom.forEach(ol => {
            var newTime = new Date().getTime()
           var predepartureTime = new Date(ol.orderData.predepartureTime).getTime()
           if(newTime > predepartureTime){
            ol.outTime = true//超时了
           }else{
            ol.outTime = false//没超时
           }
          })
          console.log(orderFrom);
          orderFrom.forEach(lm => {
              //没有房费
            if(lm.outTime) {
              //超时了
                  //超时了
            if (lm.orderData.settlementType == 0) {
                lm.type = 0
                  //全日房费
                  //没有房费
                      var newTime = new Date(lm.orderData.arrivalTime).getTime()
           var predepartureTime = new Date(lm.orderData.predepartureTime).getTime()
                var days = Math.ceil(
            (parseFloat(newTime) - parseFloat(predepartureTime)) /
              (1 * 24 * 60 * 60 * 1000)
          );
       
                    // var overtimePrices = days * parseFloat(lm.orderData.roomRealityPrice);
                    var overtimePrice =parseFloat(lm.orderData.reserveDays)  * parseFloat(lm.orderData.roomRealityPrice);
                    var params = {
            price: overtimePrice,
            orderId: lm.orderData.id,
            itemType: "1",
            state: true,
            roomId: lm.orderData.roomId,
            matchName: lm.orderData.name,
            roomName: lm.orderData.roomName, //房间号
            userClassesId: localStorage.getItem("userClassesId"),
            operator: localStorage.getItem("name"),
            paymentId: "372735506967953484",
            projectName: "全日房费",
            entryState: "2",
            type: "0",
            classesId: localStorage.getItem("classesId"),
            createdName: localStorage.getItem("name"),
            classes: localStorage.getItem("classes"),
          };
          this.bargainingUnit.forEach((el) => {
                if (el.name == "全日房费") {
                  params.paymentId = el.id
                } else if(el.name == '超时房费') {
                  // paramss.paymentId = el.id
                }
              });
              this.overtimeFrom.push(params)
              // this.overtimeFrom.push(paramss)
               
              
            }else if (lm.orderData.settlementType == 1) {
              lm.type = 1
                //钟点房费
                  //没有房费
                  var newTime = new Date(lm.orderData.arrivalTime).getTime()
           var predepartureTime = new Date(lm.orderData.predepartureTime).getTime()
                var days = Math.ceil(
            (parseFloat(newTime) - parseFloat(predepartureTime)) /
              ( 60 * 60 * 1000)
          );
                    // var overtimePrices = days * parseFloat(lm.orderData.reserveDays);
                    // this.getNowDatesed(new Date(), lm.orderData.arrivalTime, lm.orderData)
                    var overtimePrice = parseFloat(lm.orderData.roomRealityPrice)
                    var params = {
            price: overtimePrice,
            orderId: lm.orderData.id,
            itemType: "1",
            state: true,
            roomId: lm.orderData.roomId,
            matchName: lm.orderData.name,
            roomName: lm.orderData.roomName, //房间号
            userClassesId: localStorage.getItem("userClassesId"),
            operator: localStorage.getItem("name"),
            paymentId: "372735506967953484",
            projectName: "钟点房费",
            entryState: "2",
            type: "0",
            classesId: localStorage.getItem("classesId"),
            createdName: localStorage.getItem("name"),
            classes: localStorage.getItem("classes"),
          };
          this.bargainingUnit.forEach((el) => {
                if (el.name == "钟点房费") {
                  params.paymentId = el.id
                }else if (el.name == "超时房费") {
                  // paramss.paymentId = el.id
                }
              });
              this.overtimeFrom.push(params)
              // this.overtimeFrom.push(paramss)
            }

            }else{
              //没超时
               //没有超时
            if(lm.orderData.settlementType == 0) {
              //全天房
              lm.type = 0
              this.getNowDatesed(new Date(), lm.orderData.arrivalTime, lm.orderData)
                    console.log(this.reserveDays);
                    var overtimePrice =parseFloat(this.reserveDays)  * parseFloat(lm.orderData.roomRealityPrice);
                    var params = {
            price: overtimePrice,
            orderId: lm.orderData.id,
            itemType: "1",
            state: true,
            roomId: lm.orderData.roomId,
            matchName: lm.orderData.name,
            roomName: lm.orderData.roomName, //房间号
            userClassesId: localStorage.getItem("userClassesId"),
            operator: localStorage.getItem("name"),
            paymentId: "372735506967953484",
            projectName: "全日房费",
            entryState: "2",
            type: "0",
            classesId: localStorage.getItem("classesId"),
            createdName: localStorage.getItem("name"),
            classes: localStorage.getItem("classes"),
          };
          this.bargainingUnit.forEach((el) => {
                if (el.name == "全日房费") {
                  params.paymentId = el.id
                }
              });
              this.overtimeFrom.push(params)
              
            }else if (lm.orderData.settlementType == 1){
              //钟点房
              lm.type = 1
              this.getNowDatesed(new Date(), lm.orderData.arrivalTime, lm.orderData)
                    console.log(this.reserveDays,lm.orderData.roomRealityPrice);
                    var overtimePrice = parseFloat(lm.orderData.roomRealityPrice)
                    var params = {
            price: overtimePrice,
            orderId: lm.orderData.id,
            itemType: "1",
            state: true,
            roomId: lm.orderData.roomId,
            matchName: lm.orderData.name,
            roomName: lm.orderData.roomName, //房间号
            userClassesId: localStorage.getItem("userClassesId"),
            operator: localStorage.getItem("name"),
            paymentId: "372735506967953484",
            projectName: "钟点房费",
            entryState: "2",
            type: "0",
            classesId: localStorage.getItem("classesId"),
            createdName: localStorage.getItem("name"),
            classes: localStorage.getItem("classes"),
          };
          this.bargainingUnit.forEach((el) => {
                if (el.name == "钟点房费") {
                  params.paymentId = el.id
                }
              });
              this.overtimeFrom.push(params)
            }
            }

          })
        
      if(this.overtimeFrom.length != 0) {
        this.$confirm(
        `该联房用户无全天房费/钟点房房费，是否加收？`,
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.$message({
            type: "success",
            message: "确定加收房费",
          });
          var allPrice = 0
           this.UpdateRoomDate.push(...this.overtimeFrom)
            console.log(this.overtimeFrom);
            this.UpdateRoomDate.forEach((im,index) => {
              this.$set(this.UpdateRoomDate,index,{...im})
            })
            this.UpdateRoomDate.forEach((il) => {
        this.tableData.push({
          projectName: il.projectName,
          quantity: 1,
          price: parseFloat(il.price),
        });
		console.log('添加数据2',{
          projectName: il.projectName,
          quantity: 1,
          price: parseFloat(il.price),
        })
        allPrice = parseFloat(allPrice) + parseFloat(il.price);
      });
      this.consumption = parseFloat(allPrice) + parseFloat(this.consumption);
      if (this.consumption < 0) {
        this.AddPriceFrom[0].BalanceOfAccount = parseFloat(
          this.consumption + this.payments
        ).toFixed(2);
        this.BalanceOfAccount = parseFloat(this.consumption + this.payments).toFixed(2);
      } else {
        this.AddPriceFrom[0].BalanceOfAccount = parseFloat(
          this.consumption - this.payments
        ).toFixed(2);
        this.BalanceOfAccount = parseFloat(this.consumption - this.payments).toFixed(2);
      }
          // this.financeManagementConnectIistADS()
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消加收房费操作",
          });
          // this.financeManagementConnectIistADS()
        });
      }
        }
      });
    },
     //获取真实天数
     getNowDatesed(date, oldData, row) {
      var date1 = date.getTime();
      var oldData1 = new Date(oldData).getTime();
      if (row.settlementType == 0) {
        if (
          (parseFloat(date1) - parseFloat(oldData1)) <=
          1 * 24 * 60 * 60 * 1000
        ) {
          this.reserveDays = 1 + "天";
        } else if (
          parseFloat(date1) - parseFloat(oldData1) >=
          1 * 24 * 60 * 60 * 1000
        ) {
          let num = Math.ceil(
            (parseFloat(date1) - parseFloat(oldData1)) /
              (1 * 24 * 60 * 60 * 1000)
          );
          this.reserveDays = num + "天";
        }
      } else {
        if (parseFloat(date1) - parseFloat(oldData1) <= 60 * 60 * 1000) {
          this.reserveDays = 1;
        } else {
          let num = Math.ceil(
            ((parseFloat(date1) - parseFloat(oldData1)) / (60 * 60 * 1000))/ parseFloat(row.hourLength) 
          );
          this.reserveDays = num;
          console.log(this.reserveDays);
        }
      }
    },
    AddSubmit() {
      //增加付款
      console.log("adsdadfds");
      this.AddPriceFrom.push({
        BalanceOfAccount: null, //价格
        fooo: null, //付款方式的下标
        Doo: null, //协议单位的下表
        isInput: false,
        paymentId: null,
        paymentName: null,
        agreementName: null, //挂账单位
        agreementUnitId: null, //挂账单位id
        isDisabled: false,
      });
    },
    //删除增加的付款
    DeleteArr(index) {
      this.AddPriceFrom.splice(index, 1);
    },
    //消费项目的请求bargainingUnit
    UnitBargainingLooked() {
      UnitBargainingLook().then((res) => {
        if (res.data.code === 0) {
          this.bargainingUnit = res.data.data;
          this.listCheckOutTimed();
          this.outDataTable();
        } else {
          this.$message({
            type: "error",
            message: "请求异常",
            onClose: () => {},
          });
        }
      });
    },
    //退房时间分页列表查询
    listCheckOutTimed() {
      const params = {
        current: 1,
        size: 1000,
        model: {
          orgId: localStorage.getItem("orgid"),
          state: true,
        },
      };

      listCheckOutTime(params).then((res) => {
        if (res.data.code === 0) {
          this.CheckOuttime = res.data.data.records;
          this.financeManagementConnectIistADS();
          // if(this.formLabelAlign.clientType == '0') {
          //   //散客
          //   this.forms.CheckOuttimeName = "散客退房时间"

          // }else if(this.formLabelAlign.clientType == '1') {
          //   //会员
          //   this.forms.CheckOuttimeName = "会员客类退房时间"
          // }else if(this.formLabelAlign.clientType == '2') {
          //   //协议
          //   this.forms.CheckOuttimeName = "协议客类退房时间"
          // }else if(this.formLabelAlign.clientType == '3') {
          //   //OTA
          //   this.forms.CheckOuttimeName = "OTA客类退房时间"
          // }
        }
      });
    },

    //获取真实天数
    getNowDates(date, oldData) {
      var date1 = new Date(date).getTime();
      var oldData1 = new Date(oldData).getTime();
      if (parseFloat(date1) - parseFloat(oldData1) <= 1 * 24 * 60 * 60 * 1000) {
        this.PlateFrom[0].reserveDays = 1 + "天";
      } else if (
        parseFloat(date1) - parseFloat(oldData1) >=
        1 * 24 * 60 * 60 * 1000
      ) {
        let num = Math.ceil(
          (parseFloat(date1) - parseFloat(oldData1)) / (1 * 24 * 60 * 60 * 1000)
        );
        this.PlateFrom[0].reserveDays = num + "天";
      }
    },
    //批量新增财务接口
    financeManagementSaveListed(el) {
      el.forEach((it) => {
        it.entryState = "2";
        it.price = it.price?parseFloat(it.price):0
      });
      var params = [...el];
      financeManagementSaveList(params)
        .then((res) => {
          if (res.data.code === 0) {
            this.$message({
              type: "success",
              message: "入账成功",
              onClose: () => {},
            });
            if (this.endNums == 0) {
              this.financeManagementConnectIisted();
            } else {
              if (this.AddPriceFrom.length > 1) {
                this.EditdataStatisticsed();
              }
              if (this.endPriceFrom.length > 0) {
                this.endPriceFrom.splice(0);
                this.UpdateRoomDate.splice(0);
                this.EditdataStatisticsed();
              }
            }

            this.endNums = 0;
          }
        })
        .catch((err) => {
          this.endNums = 0;
        });
    },
    //监听付款方式是不是挂账
    PriceInput(id, it) {
      if(id) {
        if(it == 0) {
          this.payment.forEach(el =>{
          if(el.id == id) {
            if(el.name =='协议挂账') {
              this.SettleFrom.whetherRely = true;
              this.SettleFrom.paymentName = el.name;
        this.SettleFrom.paymentId = el.id;
          this.AddPriceFrom[it].isInput = true;
        this.AddPriceFrom[it].paymentName = el.name;
        this.AddPriceFrom[it].paymentId = el.id;
        this.BargainingUnitAlls();
            }else{
              this.SettleFrom.paymentName = el.name;
        this.SettleFrom.paymentId = el.id;
        this.SettleFrom.whetherRely = false;
        console.log(this.SettleFrom);
        this.AddPriceFrom[it].isInput = false;
        this.AddPriceFrom[it].paymentName = el.name;
        this.AddPriceFrom[it].paymentId = el.id;
            }
         
          }

        })
        }else{
          this.payment.forEach(el =>{
          if(el.id == id) {
            if(el.name == '协议挂账') {
              this.AddPriceFrom[it].isInput = true;
        this.BargainingUnitAlls();
        this.AddPriceFrom[it].paymentName = el.name;
        this.AddPriceFrom[it].paymentId = el.id;
            } else{
              this.SettleFrom.paymentName = el.name;
        this.SettleFrom.paymentId = el.id;
        this.AddPriceFrom[it].isInput = false;
        this.AddPriceFrom[it].paymentName = el.name;
        this.AddPriceFrom[it].paymentId = el.id;
            }
         
          }

        })
        }
      
      }else{
        this.AddPriceFrom[it].isInput = false;
        // this.fooo = ''
        this.SettleFrom.paymentName = ''
        this.SettleFrom.paymentId = ''
        this.AddPriceFrom[it].paymentName = ''
        this.AddPriceFrom[it].paymentId = ''
        this.SettleFrom.whetherRely = false;
      }
      // if (this.payment[index].name == "挂账") {
      //   //是挂账

      //   this.AddPriceFrom[it].isInput = true;
      //   this.BargainingUnitAlls();
      //   console.log(this.$store.state.user.orderFrom);
      //   this.SettleFrom.whetherRely = true;
      //   this.fooo = this.payment[index].name;
      //   this.SettleFrom.paymentId = this.payment[index].id;
      //   this.AddPriceFrom[it].paymentName = this.payment[index].name;
      //   this.AddPriceFrom[it].paymentId = this.payment[index].id;
      // } else {
      //   this.AddPriceFrom[it].isInput = false;
      //   this.fooo = this.payment[index].name;
      //   this.SettleFrom.paymentName = this.payment[index].name;
      //   this.SettleFrom.paymentId = this.payment[index].id;
      //   this.SettleFrom.whetherRely = false;
      //   this.AddPriceFrom[it].paymentName = this.payment[index].name;
      //   this.AddPriceFrom[it].paymentId = this.payment[index].id;
      // }
      // console.log(this.AddPriceFrom);
      // this.AddPriceFrom.forEach((it) => {
      //   if (it.paymentName == "挂账") {
      //     this.AddPriceFrom[it].isInput = true;
      //   }
      // });
    },
    //点击协议单位触发
    Bargainingputs(id, index) {
      if(id) {
        if (index == 0) {
          this.BargainingUnitList.forEach(el => {
            if(id == el.id) {
              this.SettleFrom.whetherRely = true;
              this.SettleFrom.agreementUnitId = el.id
              this.SettleFrom.agreementUnitName = el.name
              this.AddPriceFrom[index].agreementUnitId = el.id
              this.AddPriceFrom[index].agreementName = el.name
            }
          })
      } else {
        this.BargainingUnitList.forEach(el => {
            if(id == el.id) {
              this.AddPriceFrom[index].agreementUnitId = el.id
              this.AddPriceFrom[index].agreementName = el.name
            }
          })
      }
      }else{
        this.AddPriceFrom[index].agreementUnitId = ''
        this.AddPriceFrom[index].agreementName = ''
        if(index == 0) {
          this.SettleFrom.agreementUnitId = ''
              this.SettleFrom.agreementUnitName = ''
        } 
      }
      // if (index == 0) {
      //   this.SettleFrom.whetherRely = true;
      //   this.SettleFrom.agreementUnitId = this.BargainingUnitList[Doo].id;
      //   this.SettleFrom.agreementUnitName = this.BargainingUnitList[Doo].name;
      // } else {
      //   this.AddPriceFrom[index].agreementName =
      //     this.BargainingUnitList[Doo].name;
      //   this.AddPriceFrom[index].agreementUnitId =
      //     this.BargainingUnitList[Doo].id;
      // }
    },
    //协议单位查看
    BargainingUnitAlls() {
      BargainingUnitAll().then((res) => {
        if (res.data.code === 0) {
          this.BargainingUnitList = res.data.data;
        }
      });
    },
    //获取付款名称
    houtyId() {
      this.payment.forEach((item) => {
        if (this.SettleFrom.paymentId === item.id) {
          console.log(item, "45444444444444");
          this.SettleFrom.paymentName = item.name;
        }
      });
    },

    //获取当前时间
    getNowDate() {
      var date = new Date();

      var sign2 = ":";
      var year = date.getFullYear(); // 年
      var month = date.getMonth() + 1; // 月
      var day = date.getDate(); // 日
      var hour = date.getHours(); // 时
      var minutes = date.getMinutes(); // 分
      var seconds = date.getSeconds(); //秒
      // 给一位数的数据前面加 “0”
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (day >= 0 && day <= 9) {
        day = "0" + day;
      }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }

      this.arrivalTimes =
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        sign2 +
        minutes +
        sign2 +
        seconds;
    },
    //订单更新
    EditdataStatisticsed() {
      this.houtyId();
      this.getNowDate();
      if (this.SettleFrom.whetherRely == true) {
        //有挂账
        const params = {
          connectRoomId: this.PlateFrom[0].connectRoomId,
          agreementUnitId: this.SettleFrom.agreementUnitId, //协议单位id
          arrivalTime: this.formLabelAlign.arrivalTime, //抵店时间
          breakfast: parseFloat(this.formLabelAlign.breakfast) , //早餐份数
          clientType: this.formLabelAlign.clientType, //客户类型 0散户 1会员 2协议 3OTA
		  otaName:this.formLabelAlign.otaName?this.formLabelAlign.otaName:'',
          createdBy: this.formLabelAlign.createdBy, //创建人ID
          createdTime: this.formLabelAlign.createdTime, //创建时间
          discount: parseFloat(this.formLabelAlign.discount), //	折扣率
          financeManagement: {
            //财务信息
            entryState: "2", //入账类型（1为普通，2为结账）
            agreementName: this.SettleFrom.agreementUnitName, //挂账单位
            agreementUnitId: this.SettleFrom.agreementUnitId, //挂账单位id
            classes: localStorage.getItem("classes"), //班次
            classesId: localStorage.getItem("classesId"), //班次id
            createdName: localStorage.getItem("name"), //操作员
            itemType: "0", //项目类型 0付款 1消费
            operator: localStorage.getItem("name"), //操作人
            orderId: this.PlateFrom[0].id, //关联订单
            orgId: localStorage.getItem("orgid"), //部门ID
            paymentId: this.SettleFrom.paymentId, //付款方式id/消费方式id
            preAuth: true, //是否预授权（0 否 1是）默认0
            price: this.AddPriceFrom[0].BalanceOfAccount, //金额
            projectName: this.SettleFrom.paymentName, //项目名称
            remarks: this.PlateFrom[0].remark, //备注
            roomId: this.PlateFrom[0].roomId, //房间id
			roomName:this.PlateFrom[0].roomName, //房间号
            state: true, //状态（1为启用，0为禁用）
            type: 1, //入账类型 0消费 1结算
            userClassesId: localStorage.getItem("userClassesId"), //用户班次表id
          },
          outRoomTime: this.arrivalTimes, //退房时间
          hourLength: this.PlateFrom[0].hourLength, //钟点时长
          housePriceId: this.PlateFrom[0].housePriceId, //房价码ID
          houseTypeId: this.PlateFrom[0].houseTypeId, //房型id
          houseTypeName: this.PlateFrom[0].houseTypeName, //房型名称
          id: this.PlateFrom[0].id,
          orderReserveId:
            this.PlateFrom[0].orderReserveId == "暂无"
              ? null
              : this.PlateFrom[0].orderReserveId, //预订单id
          orderState: false, //订单状态（1为在住，0为已退房）
          orgId: localStorage.getItem("orgid"),
          // "outRoomTime": this.formLabelAlign.outRoomTime,//退房时间
          paymentId: this.SettleFrom.paymentId, //付款方式id
          // "predepartureTime": this.formLabelAlign.predepartureTime,//预离时间
          remark: this.PlateFrom[0].remark, //备注信息
          // reserveDays: parseFloat(this.formLabelAlign.reserveDays), //预住天数
          // "roomId": this.PlateFrom[0].roomId,//房间id
          roomName: this.PlateFrom[0].roomName, //房间号
          roomPrice: this.PlateFrom[0].roomPrice, //房间门市单价
          roomRealityPrice: this.PlateFrom[0].roomRealityPrice, //房价实际单价
          settleAccountsClasses:
            this.arrivalTimes + "/" + localStorage.getItem("classes"), //结账班次
          settlementType: this.PlateFrom[0].settlementType, //入驻类型 0全天房 1钟点房
          state: true, //状态
          whetherRely: true, //是否有挂账（1为是，0为否）
        };
        console.log(
          params.outRoomTime,
          "21333338888888888888888888888888888888888"
        );
        EditdataStatistics(params).then((res) => {
          if (res.data.code === 0) {
            this.$emit("onSettle", [false, "确认结账"]);
            this.$message({
              type: "success",
              message: "结账成功",
            });
            this.$confirm(`是否需要打印小票`, {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.$message({
                  type: "success",
                  message: "确定打印小票",
                });
                this.financeManagementConnectIistedsss(res.data.data,params)
              })
              .catch(() => {
                this.$message({
                  type: "error",
                  message: "取消打印小票",
                  onClose: () => {},
                });
              });
          }
        });
      } else if (this.SettleFrom.whetherRely == false) {
        this.getNowDate();
        const params = {
          connectRoomId: this.PlateFrom[0].connectRoomId,
          agreementUnitId: null, //协议单位id
          arrivalTime: this.PlateFrom[0].arrivalTime, //抵店时间
          breakfast: parseFloat(this.PlateFrom[0].breakfast) , //早餐份数
          clientType: this.PlateFrom[0].clientType, //客户类型 0散户 1会员 2协议 3OTA
		  otaName:this.PlateFrom[0].otaName?this.PlateFrom[0].otaName:'',
          createdBy: this.PlateFrom[0].createdBy, //创建人ID
          createdTime: this.PlateFrom[0].createdTime, //创建时间
          discount: parseFloat(this.PlateFrom[0].discount), //	折扣率
          financeManagement: {
            //财务信息
            entryState: "2", //入账类型（1为普通，2为结账）
            agreementName: null, //挂账单位
            agreementUnitId: null, //挂账单位id
            classes: localStorage.getItem("classes"), //班次
            classesId: localStorage.getItem("classesId"), //班次id
            createdName: localStorage.getItem("name"), //操作员
            itemType: "0", //项目类型 0付款 1消费
            operator: localStorage.getItem("name"), //操作人
            orderId: this.PlateFrom[0].id, //关联订单
            orgId: localStorage.getItem("orgid"), //部门ID
            paymentId: this.SettleFrom.paymentId, //付款方式id/消费方式id
            // "preAuth": true,//是否预授权（0 否 1是）默认0
            price: this.AddPriceFrom[0].BalanceOfAccount, //金额
            projectName:this.SettleFrom.paymentName, //项目名称
            remarks: this.PlateFrom[0].remark, //备注
            roomId: this.PlateFrom[0].roomId, //房间id
			roomName: this.PlateFrom[0].roomName, //房间号
            state: true, //状态（1为启用，0为禁用）
            type: 1, //入账类型 0消费 1结算
            userClassesId: localStorage.getItem("userClassesId"), //用户班次表id
          },
          hourLength: this.PlateFrom[0].hourLength, //钟点时长
          housePriceId: this.PlateFrom[0].housePriceId, //房价码ID
          houseTypeId: this.PlateFrom[0].houseTypeId, //房型id
          houseTypeName: this.PlateFrom[0].houseTypeName, //房型名称
          id: this.PlateFrom[0].id,
          orderReserveId:
            this.PlateFrom[0].orderReserveId == "暂无"
              ? null
              : this.PlateFrom[0].orderReserveId, //预订单id
          orderState: false, //订单状态（1为在住，0为已退房）
          orgId: localStorage.getItem("orgid"),
          outRoomTime: this.arrivalTimes, //退房时间
          paymentId: this.SettleFrom.paymentId, //付款方式id
          // "predepartureTime": this.PlateFrom[0].predepartureTime,//预离时间
          remark: this.PlateFrom[0].remark, //备注信息
          // reserveDays: parseFloat(this.PlateFrom[0].reserveDays), //预住天数
          // "roomId": this.PlateFrom[0].roomId,//房间id
          roomName: this.PlateFrom[0].roomName, //房间号
          roomPrice: this.PlateFrom[0].roomPrice, //房间门市单价
          roomRealityPrice: this.PlateFrom[0].roomRealityPrice, //房价实际单价
          settleAccountsClasses:
            this.arrivalTimes + "/" + localStorage.getItem("classes"), //结账班次
          settlementType: this.PlateFrom[0].settlementType, //入驻类型 0全天房 1钟点房
          state: true, //状态
          whetherRely: false, //是否有挂账（1为是，0为否）
        };
        console.log(
          params.outRoomTime,
          "12222222222222222222222222222222222222"
        );

        EditdataStatistics(params).then((res) => {
          if (res.data.code === 0) {
            this.$emit("onSettle", [false, "确认结账"]);
            this.$message({
              type: "success",
              message: "结账成功",
            });
            this.$confirm(`是否需要打印小票`, {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.$message({
                  type: "success",
                  message: "确定打印小票",
                });
                this.financeManagementConnectIistedsss(res.data.data,params)
              })
              .catch(() => {
                this.$message({
                  type: "error",
                  message: "取消打印小票",
                  onClose: () => {},
                });
              });
          }
        });
      }
      console.log(this.formLabelAlign.orderReserveId);
    },
    //打印小票接口
    printertextreceipted(row,ps,projectDetailsarr) {
		if(!projectDetailsarr.length){
			this.lunnum++;
			if(this.lunnum<10){
				this.financeManagementConnectIistedsss(row,ps);
				return false;
			}
		}
      const params = {
        createdName: this.$store.state.user.orderFrom.echoMap.createdBy,
        name: localStorage.getItem("orgName"),
        orderId: row.id,
        paymentPrice: 0,//付款合计
        // projectDetails: [
        //   // {
        //   //   date: "11.11",
        //   //   name: "房费",
        //   //   number: "1",
        //   //   price: "",
        //   //   totalPrice: "60",
        //   //   type: "0",
        //   // },
        //   ...this.projectDetails,
        // ],
         projectDetails:projectDetailsarr,
        roomNumber: row.roomName,
        settleAccountsDate:  row.updatedTime,
        tableName: "",
        totalPrice: 0,//消费合计
        type: "1",
      };
      params.paymentPrice = 0//付款合计
      params.totalPrice = 0//消费合计
      // this.projectDetails.forEach(el => {
      //   if(el.type == 1) {
      //     //消费
      //     params.totalPrice = parseFloat(params.totalPrice) + parseFloat(el.totalPrice)
      //   }else if(el.type == 0) {
      //     //付款
      //     params.paymentPrice = parseFloat(params.paymentPrice) + parseFloat(el.totalPrice)
      //   }
      // })
	  projectDetailsarr.forEach(el => {
	    if(el.type == 1) {
	      //消费
	      params.totalPrice = parseFloat(params.totalPrice) + parseFloat(el.totalPrice)
	    }else if(el.type == 0) {
	      //付款
	      params.paymentPrice = parseFloat(params.paymentPrice) + parseFloat(el.totalPrice)
	    }
	  })
      printertextreceipt(params).then((res) => {
        if(res.data.code === 0) {
          this.$message({
          type: 'success',
          message: '小票打印成功'
        })
        }else{
          if(res.data.errorMsg == '空指针异常') {
            this.$message({
            type: 'error',
            message: '未添加打印机或打印机参数配置异常'
          })
          }
        }
      });
    },
    //联房财务查看接口
    financeManagementConnectIistedsss(row,ps) {
      const params = {
        type: false,
        connectRoomId: this.$store.state.user.orderFrom.connectRoomId,
      };
      financeManagementConnectIist(params).then((res) => {
        if (res.data.code == 0) {
          this.tableDataFrom.splice(0);
          this.projectDetails.splice(0)
          this.tableDataFrom = res.data.data.filter((el) => {
            return el.updateState == "2" && !el.preAuth&&el.state;
          });
          var priceArr = [];
          var Arr = [];
		  var projectDetailsarr=[];
          this.tableDataFrom.forEach((el) => {
            priceArr.push(el.paymentId);
          });
          priceArr = [...new Set(priceArr)];
          priceArr.forEach((is) => {
            var nums = 0;
            var totalPrice = 0;
            this.tableDataFrom.forEach((el) => {
              if (is == el.paymentId) {
                if(el.preAuth){
                	if(!el.authOver){
                		nums = parseFloat(nums) + parseFloat(el.quantity);
                		totalPrice = parseFloat(totalPrice) + parseFloat(el.price);
                		this.name = el.projectName;
                		this.type = el.itemType;
                	}
                }else{
                	nums = parseFloat(nums) + parseFloat(el.quantity);
                	totalPrice = parseFloat(totalPrice) + parseFloat(el.price);
                	this.name = el.projectName;
                	this.type = el.itemType;
                }
              }
            });
            this.projectDetails.push({
              name: this.name,
              number: nums,
              totalPrice: parseFloat(totalPrice),
              type: this.type,
            });
			projectDetailsarr.push({
				name: this.name,
				number: nums,
				totalPrice: (parseFloat(totalPrice)).toFixed(2),
				type: this.type,
			});
          });
          console.log(this.projectDetails);
		  console.log('付款计算前数据',this.tableData)
          this.tableData.forEach((item) => {
            if (item.itemType == 0) {
              //付款
              this.paymentPrice =
                parseFloat(this.paymentPrice) +
                parseFloat(item.quantity) * parseFloat(item.price);
            } else if (item.itemType == 1) {
              //消费totalPrice
              this.totalPrice =
                parseFloat(this.totalPrice) +
                parseFloat(item.quantity) * parseFloat(item.price);
            }
          });

          this.printertextreceipted(row,ps,projectDetailsarr);
        }
      });
    },
    //联房财务查看接口
    financeManagementConnectIisted() {
      const params = {
        // type: true,
        type: false,
        connectRoomId: this.PlateFrom[0].connectRoomId,
      };
      financeManagementConnectIist(params).then((res) => {
        if (res.data.code == 0) {
          this.tableData.splice(0);
          console.log(res,'联房财务信息');
          res.data.data.forEach((el) => {
            if (!el.authOver && !el.preAuth && el.state) {
				console.log('添加数据3',el)
			  var aa = -1;
			  this.tableData.forEach((value,index)=>{
				  if(el.projectName == value.projectName ){
					  aa = index;
				  }
			  });
			  if(aa == -1){
				this.tableData.push(el);  
			  }else{
				this.tableData[aa].price=parseFloat(this.tableData[aa].price) + parseFloat(el.price);
				this.tableData[aa].quantity++; 
			  }
              
            }
          });
		  
          this.getPriceNumber();
          // this.endPriceFrom.forEach((el) => {
          //   this.tableData.push({
          //     projectName: el.projectName,
          //     quantity: 1,
          //     price: parseFloat(el.price),
          //   });
          // });
        }
      });
    },
    // 点击右上角的图标隐藏弹窗
    close_page(e) {
      console.log(e.target.className);
      console.log(e.target.nodeName);
      if (e.target.className === "el-icon-close") {
        this.$emit("onSettle", [false]);
      }
      //  else if (e.target.innerText === "增加付款") {
      //   // this.$emit("onSettle", [false, "增加付款"]);
      // }
    },

    //付款方式的查看
    paymentListed() {
      paymentList().then((res) => {
        if (res.data.code === 0) {
          this.payment = res.data.data.filter(el => el.name != '挂账' && el.name != '微储值' && el.name != '挂房账' && el.name != '餐饮挂账' && el.name != '商超挂账' && el.name != '预授权');
        }
      });
    },
    //倒计时结束后触发事件
    hilarity() {
      this.$emit("onSettle", [false]);
    },
     //会员结账触发的自定义事件
    MemberPrice(row) {
		if(row[0] == 1) {
		  this.isConsumer = false
		}else{
		  this.isConsumer = false
		  this.AddPriceFrom[0].paymentName = row[0].projectName
		  this.AddPriceFrom[0].paymentId = row[0].paymentId
		  this.SettleFrom.paymentName = row[0].projectName
		  this.SettleFrom.paymentId = row[0].paymentId
		  this.onSubmit(2)
		}
    },
    //点击确认结账触发事件
    onSubmit(num) {
      if(num == 1) {
		//会员结账
		this.isConsumer = true;
        this.$nextTick(() => {
          this.$refs.ConsumerBox.price = this.AddPriceFrom[0].BalanceOfAccount
          this.$refs.ConsumerBox.ruleForm.tableData.push(this.formLabelAlign); //进来的放单信息
          this.$refs.ConsumerBox.Getpaymented();
        });
      }else if(num == 2) {
        this.UpdateRoomDate.forEach((item) => {
			this.endPriceFrom.push(item);
		});
		this.endNums = 1;
		this.numPays = 0
		this.AddPriceFrom.forEach(es => {
		  if(es.isInput) {
			//付款方式是协议挂账
			if(es.agreementUnitId && es.paymentId && es.BalanceOfAccount) {
			  
			}else{
			  this.numPays = 2
			}
		  }else{
			if(es.paymentId && es.BalanceOfAccount) {
			 
			}else{
			  this.numPays = 2
			}
		  }
		})
		if(this.numPays == 2) {
			this.$message({
			  type:'error',
			  message: '请填写完全信息'
			})
		}else{
			if (this.SettleFrom.paymentName || this.SettleFrom.agreementUnitId) {
				if (this.AddPriceFrom.length > 1) {
				  var params = [];
				  this.AddPriceFrom.forEach((it, index) => {
					if (index != 0) {
					  params.push({
						price: it.BalanceOfAccount
						  ? parseFloat(it.BalanceOfAccount)
						  : parseFloat(it.price),
						orderId: this.PlateFrom[0].id,
						itemType: "0",
						state: true,
						agreementName: it.agreementName,
						agreementUnitId: it.agreementUnitId,
						roomId: this.PlateFrom[0].roomId,
						roomName: this.PlateFrom[0].roomName, //房型名称
						userClassesId: localStorage.getItem("userClassesId"),
						operator: localStorage.getItem("name"),
						paymentId: it.paymentId,
						projectName: it.paymentName,
						type: "0",
						classesId: localStorage.getItem("classesId"),
						createdName: localStorage.getItem("name"),
						classes: localStorage.getItem("classes"),
						remark: null,
					  });
					}
				  });
				  this.endPriceFrom.forEach((ic) => {
					params.push(ic);
				  });
				  this.financeManagementSaveListed(params);
				} else {
				  if (this.endPriceFrom.length > 0) {
					this.financeManagementSaveListed(this.endPriceFrom);
				  } else {
					this.EditdataStatisticsed();
				  }
				}
			}
		}
      //  
      }
    },
  },
};
</script>
  
  <style lang="scss" scoped>
  body .el-table::before {
    z-index: inherit;
}
.DeteleButton {
  background-color: #fdd4d9;
  border-radius: 8px;
  margin-left: 10px;
  height: 40px;
  border: none;
  color: red;
}
.PeerSettleaccounts {
  width: 100%;
  max-height: 670px;
  overflow-y: auto;
  box-sizing: border-box;
  background-color: #fff;

  .SettleAccounts_main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    // background: linear-gradient(177deg, #e8fdfe 0%, #ffffff 100%);
    border-radius: 5px;

    .table_box {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    // 金额的汇总css
    .settle_statistics {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: center;
      align-content: center;

      ul {
        width: 100%;
        height: 50px;
        display: flex;
        padding-left: 10px;
        align-items: center;

        li {
          margin-right: 15px;

          span {
            color: #5cb5b8;
          }
        }

        div {
          border-right: 2px solid rgb(177, 170, 170);
          height: 10px;
          margin-right: 15px;
        }
      }
    }

    .customerInformation {
      width: 100%;
      padding-top: 20px;
      box-sizing: border-box;
    }

    .payment_settlement {
      width: 100%;
      height: 150px;
      overflow-y: auto;
      margin-top: 20px;
      padding-left: 15px;
      border-radius: 8px;
      box-sizing: border-box;
      background: #ffffff;
      border: 1px solid #ededed;

      p {
        height: 50px;
        line-height: 50px;
        text-align: left;
        display: flex;
        align-items: center;

        span {
          width: 10px;
          height: 10px;
          background-color: #5cb5b8;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
    }

    .ending {
      width: 100%;
      height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 15px;

      .ending_left {
        display: flex;

        span {
          width: 170px;
          text-align: right;
          color: #999999;
        }

        .statistic_main {
          display: flex;
        }
      }

      .ending_right {
        padding-right: 30px;
        box-sizing: border-box;

        .addButton {
          border-radius: 6px;
          height: 40px;
          width: 100px;
          border: none;
          background-color: #5cb5b8;
          color: #fff;
        }

        .deleteButton {
          background-color: #fdd4d9;
          border-radius: 8px;
          height: 40px;
          border: none;
          color: red;
        }
      }
    }
  }
}
</style>