import request from "_utils/request";
import qs from "qs";
//系统页面之系统设置的接口
//协议单位之新增接口
const AddBargainingUnit = (params = {}) =>
  request.post("/wineshop/agreement_unit", params);
//协议单位之修改接口
const updateBargainingUnit = (params = {}) =>
  request.put("/wineshop/agreement_unit", params);
//协议单位之删除接口
const deleteBargainingUnit = (params = {}) =>
  request.delete("/wineshop/agreement_unit", { params });
//协议单位之分页列表查询接口
const listBargainingUnit = (params = {}) =>
  request.post("/wineshop/agreement_unit/page", params);
//协议类型之分页列表查询接口
const agreement_typePage = (params = {}) =>
  request.post("/wineshop/agreement_type/page", params);
//协议类型之新增接口
const agreement_type = (params = {}) =>
  request.post("/wineshop/agreement_type", params);
//协议类型之更新接口
const agreement_typeUpdate = (params = {}) =>
  request.put("/wineshop/agreement_type", params);
  //协议类型之查看接口
const agreement_typeDetele = (params = {}) =>
request.get("/wineshop/agreement_type/type_view", {params});
//退房时间之分页列表查询接口
const listCheckOutTime = (params = {}) =>
  request.post("/wineshop/sys_check_out_time/page", params);
//退房时间之新增接口
const AddCheckOutTime = (params = {}) =>
  request.post("/wineshop/sys_check_out_time", params);
//退房时间之修改接口
const updateCheckOutTime = (params = {}) =>
  request.put("/wineshop/sys_check_out_time", params);
//退房时间之批量更新接口
const updateListSCheckOutTime = (params = []) =>
  request.post("/wineshop/sys_check_out_time/update_list", params);
//退房时间之删除接口
const deleteCheckOutTime = (params = {}) =>
  request.delete("/wineshop/sys_check_out_time", { params });
//班次设置之删除接口
const deleteClasses = (params = {}) =>
  request.delete("/wineshop/sys_classes", { params });
//班次设置之新增接口
const addClasses = (params = {}) =>
  request.post("/wineshop/sys_classes", params);
//班次设置之修改接口
const updateClasses = (params = {}) =>
  request.put("/wineshop/sys_classes", params);
//班次设置之批量修改接口
const updateClasseslist = (params = []) =>
  request.post("/wineshop/sys_classes/update_list", params);
//班次设置之分页列表查询接口
const listClasses = (params = {}) =>
  request.post("/wineshop/sys_classes/page", params);
//房价码之分页列表查询接口
const housingPriceList = (params = {}) => {
  return request.post("/wineshop/house_price_code/page", params);
};
//房价码之新增接口
const housingPriceAdd = (params = {}) => {
  return request.post("/wineshop/house_price_code", params);
};
//房价码之修改接口
const housingPriceUpdate = (params = []) => {
  return request.post("/wineshop/house_price_code/update_list", params);
};
//房价码之删除接口
const housingPriceDelete = (params = {}) => {
  return request.delete("/wineshop/house_price_code", { params });
};
//房价码之房价码查看接口
const housingPriceExamine = (params = {}) => {
  return request.get(
    "/wineshop/house_price_code/type_view?houseTypeId=" + params.houseTypeId,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
};
//房价码之房价码查看接口
const housingPriceExamineed = (params = {}) => {
  return request.get("/wineshop/house_price_code/type_view", {
    params,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};
//房价码之房价码批量增加接口
const housingPriceBatchAdd = (params = {}) => {
  return request.post("/wineshop/house_price_code/save_list", params);
};

//付款方式之分页列表查询接口
const paymentMethodList = (params = {}) =>
  request.post("/wineshop/payment_method/page", params);
//付款方式之新增接口
const paymentMethodAdd = (params = {}) =>
  request.post("/wineshop/payment_method", params);
//付款方式之修改接口
const paymentMethodUpdate = (params = {}) =>
  request.put("/wineshop/payment_method", params);
//付款方式之删除接口
const paymentMethodDalete = (params = {}) =>
  request.delete("/wineshop/payment_method", params);
//消费设置之分页列表查询接口
const consumptionList = (params = {}) => {
  return request.post("/wineshop/consumption/page", params);
};
//消费设置之新增接口
const consumptionAdd = (params = {}) =>
  request.post("/wineshop/consumption", params);

//消费设置之修改接口
const consumptionUpdate = (params = {}) => {
  return request.put("/wineshop/consumption", params);
};
//消费设置之删除接口
const consumptionDelete = (params = {}) => {
  return request.delete("/wineshop/consumption", { params });
};
//夜审之修改接口
const nightAuditorUpdate = (params = {}) =>
  request.put("/wineshop/night_auditor", params);
//夜审之新增接口
const nightAuditorAdd = (params = {}) =>
  request.post("/wineshop/night_auditor", params);
//夜审之分页列表查询接口
const nightAuditorList = (params = {}) =>
  request.post("/wineshop/night_auditor/page", params);

//用户设置之新增接口
const userInforeUpdate = (params = {}) =>
  request.post("/system/defUser", params);
//房型查看
const GetHouseType = () =>
  request.get("/wineshop/house_type/type_view", {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
//消费查看
const UnitBargainingLook = () =>
  request.get("/wineshop/consumption/type_view", {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
const advanceScreen = (params = {}) =>
  request.post("/wineshop/orderReserve/getNumByType", params, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
//文件上传
const uploapData = (params = {}) =>
  request.post("/base/file/anyone/upload", params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

//根据id获取图片地址
const findUrlByBizId = (params = {}) =>
  request.post(
    "/base/file/findUrlByBizId?businessId=" + params.businessId,
    {},
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
//新增查房 酒店系统设置/新增
const AddsysPmsSettings = (params = {}) => {
  return request.post("/wineshop/sysPmsSettings", params);
};
//查房分页列表  酒店系统设置/分页列表查询
const sysPmsSettingsList = (params = {}) => {
  return request.post("/wineshop/sysPmsSettings/page", params);
};
//查房分页列表  酒店系统设置/分页列表查询
const sysPmsSettingsUpdate = (params = {}) => {
  return request.put("/wineshop/sysPmsSettings", params);
};
//酒店点餐时间  酒店系统设置/获取客房点餐时间配置
const getRoomOrderTimeConfiguration = (params = {}) => {
  return request.get("/wineshop/sysPmsSettings/getRoomOrderTimeConfiguration?orgId="+ params.orgId,);
};

//获取酒店查房配置  酒店系统设置/获取酒店查房配置
const getHotelRoomRoundConfiguration = (params = {}) => {
  return request.get(
    "/wineshop/sysPmsSettings/getHotelRoomRoundConfiguration?orgId=" +
      params.orgId,
    {}
  );
};
//获取待查房信息  房间状态中间表/获取待查房信息
const inspectionGetList = (params = {}) =>
  request.get("/wineshop/roomState/inspection/getList?orgId=" + params.orgId);
//批量查房变净房  房间状态中间表/
const inspectionCleans = (params = {}) =>
  request.get(
    "/wineshop/roomState/inspection/cleans?ids=" + [...params.ids] + ""
  );
//脏房批量清扫成待查房   房间状态中间表/
const dirtyInspection = (params = {}) =>
  request.get(
    "/wineshop/roomState/dirty/inspection?ids=" + [...params.ids] + ""
  );
//获取酒店预订须知配置
const bookingNotice = (params = {}) => {
  return request.get("/wineshop/sysPmsSettings/getHotelReservationInformationConfiguration?orgId="+params.orgId, params);
};
export {
  AddBargainingUnit,
  updateBargainingUnit,
  deleteBargainingUnit,
  listBargainingUnit,
  listCheckOutTime,
  AddCheckOutTime,
  updateCheckOutTime,
  deleteCheckOutTime,
  deleteClasses,
  updateClasses,
  updateListSCheckOutTime,
  addClasses,
  listClasses,
  housingPriceList,
  housingPriceAdd,
  housingPriceUpdate,
  housingPriceDelete,
  housingPriceExamine,
  housingPriceBatchAdd,
  consumptionList,
  consumptionAdd,
  consumptionDelete,
  consumptionUpdate,
  nightAuditorUpdate,
  paymentMethodList,
  paymentMethodAdd,
  paymentMethodUpdate,
  paymentMethodDalete,
  userInforeUpdate,
  updateClasseslist,
  GetHouseType,
  nightAuditorAdd,
  nightAuditorList,
  UnitBargainingLook,
  advanceScreen,
  uploapData,
  housingPriceExamineed,
  findUrlByBizId,
  AddsysPmsSettings,
  sysPmsSettingsList,
  sysPmsSettingsUpdate,
  getHotelRoomRoundConfiguration,
  inspectionGetList,
  inspectionCleans,
  dirtyInspection,
  agreement_typeUpdate,
  agreement_type,
  agreement_typePage,
  agreement_typeDetele,
  getRoomOrderTimeConfiguration,
  bookingNotice,
};
