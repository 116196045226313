<template>
  <!-- 发票信息 -->

  <div class="InvoiceMessage">
    <!-- 侧边栏部分 -->
    <div
      class="sidebar"
      v-if="roomArrActive.includes('fapiaokaiju') ? orderStates : false"
    >
      <ul>
        <li @click="switchoverTabs(1)" :class="{ active: li_index == 1 }">
          发票开具
        </li>
      </ul>
    </div>
    <!-- 侧边栏部分 -->
    <div
      class="finance_main"
      ref="PaymentMethod"
      :style="{ width: roomArrActive.includes('fapiaokaiju') ? '90%' : '100%' }"
    >
	<!-- height="400px" -->
      <el-table
        :data="tableData"
		:max-height="tableheight"
        border
        :header-cell-style="{ background: '#F7F7F7', color: '#4C4C4C' }"
      >
        <el-table-column
          prop="number"
          label="订单编号"
          align="center"
          width="190"
        >
        </el-table-column>
        <el-table-column
          prop="number"
          label="发票No"
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="unit"
          label="单位/客户"
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column prop="price" label="开票金额" align="center">
        </el-table-column>
        <el-table-column label="类型" align="center">
          <template slot-scope="scope">
            {{
              scope.row.type === "0"
                ? "增值税普通发票"
                : scope.row.type === "1"
                ? "增值税专用发票"
                : "增值税电子发票"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" align="center">
        </el-table-column>
        <el-table-column
          prop="createdTime"
          label="录入时间"
          align="center"
          width="180"
        >
        </el-table-column>

        <el-table-column align="center"  fixed="right"  label="操作"  width="200px">
          <template slot-scope="scope">
            <span
              v-if="roomArrActive.includes('xiugaifapiaoxinxigongneng')"
              style="
                cursor: pointer;
                border-right: 2px solid #5cb5b8;
                margin-right: 10px;
                padding-right: 10px;
                box-sizing: border-box;
                color: #5cb5b8;
              "
              @click="switchoverTabs(2, scope.row)"
              >修改</span
            >

            <span
              v-if="roomArrActive.includes('shanchufapiaoxinxi')"
              @click="deleteSave(scope.row)"
              style="cursor: pointer; color: #fc200f;padding-right: 10px;"
              >删除</span
            >
			<span @click="checkinfo(scope.row)" style="cursor: pointer; color: #5cb5b8;border-left: 2px solid #5cb5b8;padding-left: 10px;">查看</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="current"
        :page-size="size"
        layout="total, prev, pager, next"
        :total="total"
        style="text-align: right"
      >
      </el-pagination>
    </div>
    <!-- 点击删除弹出删除弹框 -->
    <whether-delete
      v-if="isSave"
      @whetherDelete="whetherDelete"
    ></whether-delete>
    <!-- 点击删除弹出删除弹框 -->
    <el-dialog
      title="发票开具"
      :visible.sync="isSuean"
      :close-on-click-modal="false"
      width="500PX"
      append-to-body
      class="dialogBox"
      :before-close="handleClose"
    >
      <issuean-invoice
        ref="invoiceBox"
        :FromDates="numRoom"
        @offAninvoBox="offAninvoBox"
      ></issuean-invoice>
    </el-dialog>
	
	<el-dialog
	  title="查看"
	  :visible.sync="lookshow"
	  :close-on-click-modal="false"
	  width="500PX"
	  append-to-body
	  class="dialogBox"
	  @close='lookclose'
	>
		<el-form :model="lookinfo" status-icon  label-width="100px" class="demo-ruleForm">
		  <el-form-item label="抬头类型:" prop="upType">
			<el-select clearable v-model="lookinfo.upType" disabled placeholder="请选择抬头类型" style="width: 100%">
			  <el-option label="企业单位" value="0"></el-option>
			  <el-option label="个人/非企业单位" value="1"></el-option>
			</el-select>
		  </el-form-item>
		  <el-form-item label="公司名称:" prop="name" v-if="lookinfo.upType == 0">
			<el-input clearable disabled v-model="lookinfo.name"></el-input>
		  </el-form-item>
		  <el-form-item label="公司税号:" prop="dutyParagraph" v-if="lookinfo.upType == 0">
			<el-input clearable disabled v-model="lookinfo.dutyParagraph"></el-input>
		  </el-form-item>
		  <el-form-item label="接收方式:" prop="way">
			<el-select clearable v-model="lookinfo.way" disabled placeholder="请选择接收方式" style="width: 100%">
				<el-option label="电子邮箱" value="0"></el-option>
				<el-option label="快递收货" value="1"></el-option>
			</el-select>
		  </el-form-item>
		  <el-form-item label="电子邮箱:" prop="email">
			<el-input clearable v-model="lookinfo.email" disabled></el-input>
		  </el-form-item>
		</el-form>
	</el-dialog>
  </div>
</template>

<script>
import WhetherDelete from "@/components/WhetherDelete.vue";
import { ListInvoice, AddInvoice, UpdateInvoice } from "_api/orderFrom";
import IssueanInvoice from "./IssueAnInvoice.vue";
export default {
  name: "InvoiceMessage",
  components: {
    WhetherDelete,
    IssueanInvoice,
  },
  data() {
    return {
      tab_sideba: 1,
      current: 1,
      size: 5,
      total: 0,
      isSuean: false,
      consume: 0, //总消费金额
      currentPage1: 1, //当前页
      isSave: false, //控制删除的弹窗
      FromDate: {},
      tableData: [],
      deteId: 1,
      numRoom: 0,
      li_index: null,
      orderDateFrom: {},
      orderStates: this.$store.state.user.orderFrom.orderState,
      roomArrActive: localStorage.getItem("permission")
        ?JSON.parse(localStorage.getItem("permission"))
        : [], //功能权限数据
	  lookinfo:{},
	  lookshow:false,
	  paraminfo:{},
	  tableheight:500,
    };
  },
 
  mounted() {
    this.autoHeight()
  },
  methods: {
    //  modelSearch(model) {
    //     this.ListInvoiced(model)
    //  },
//高度自适应
autoHeight() {
    this.setHeight();

      // 初始化的时候，设置高度
      // 改变浏览器窗口大小的时候，设置高度
      window.addEventListener("resize", () => {
        this.setHeight();
      });
    },
  // 设置高度
  setHeight() {
      this.$nextTick(() => {
        // console.log('高度',innerHeight)
        if (this.$refs.PaymentMethod) {
          setTimeout(() => {
            let height = this.$refs.PaymentMethod.offsetHeight;
          // console.log('元素高度',height);
          this.tableheight = height - 70; // 这里的X就是顶部栏的高度
          // console.log('高度',this.tableheight)
          },300)
          
        }
      });
    },
    handleClose(done) {
      done();
      this.li_index = null;
      this.ListInvoiced();
    },
    //新增发票的弹窗的自定义事件
    offAninvoBox() {
      this.isSuean = false;
      this.li_index = null;
      this.ListInvoiced();
    },
    //删除弹窗的自定义事件
    // whetherDelete(num) {
    //   if (num == 1) {
    //   }
    // },
    //分页列表
    ListInvoiced(model = {}) {
      if (this.roomArrActive.includes("wineshop:invoice:management:page")) {
        const params = {
          current: this.current,
          size: this.size,
          model: {
            state: true,
            orderId: this.orderDateFrom.id,
            orgId: localStorage.getItem("orgid"),
            ...model,
          },
        };
		this.paraminfo=params;
        ListInvoice(params).then((res) => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.records;
            this.total = parseFloat(res.data.data.total);
          } else {
            this.$message(res.data.errorMsg);
          }
        });
      } else {
        this.$message({
          type: "error",
          message: "无查看发票管理数据的权限",
        });
      }
    },
    //更新的接口
    UpdateInvoiceed(id) {
      const params = {
        id: id.id,
        orderId: this.orderDateFrom.id,
        state: false,
        number: id.number
      };
      UpdateInvoice(params).then((res) => {
        if (res.data.code === 0) {
			this.$message({
				message: '操作成功！',
				type: 'success'
			});
          this.ListInvoiced();
        } else {
          this.$message(res.data.errorMsg);
        }
      });
    },
    //删除弹框返回的信息
    whetherDelete(e) {
      console.log(e);
      if (e === 1) {
        //确认删除
        this.isSave = false;
        this.UpdateInvoiceed(this.deteId);
      } else {
        //不删
        this.isSave = false;
		this.$message('已经取消操作');
      }
    },
    //点击查询按钮触发事件
    addConsumer() {},
    //点击发票开具按钮和表格修改按钮触发的事件
    switchoverTabs(num, id) {
      //新增发票
      this.isSuean = true;
      this.numRoom = num;
      this.li_index = 1;
      if (num === 2) {
        this.$store.commit("base/SET_INVOICE", id);
        this.$refs.invoiceBox.judgeOperator();
      } else {
        this.$store.commit("base/SET_INVOICE", {});
        this.$refs.invoiceBox.judgeOperator();
      }

      console.log(this.$store.state.base.invoiceUpdate);
    },
    //点击删除按钮触发事件
    deleteSave(scope) {
      this.isSave = true;
      this.deteId = scope;
    },
    //更改每页多少条数据时触发
    handleSizeChange(val) {
    },
    //点击切换当前页时触发
    handleCurrentChange(val) {
	  this.ListInvoiced(this.paraminfo);
    },
    hilarity() {
      this.$message({
        type: "success",
        message: "倒计时已结束",
      });
    },
	checkinfo(row){
		console.log('单击',row);
		this.lookinfo=row;
		this.lookshow=true;
	},
	lookclose(){
		this.lookshow=false;
	},
  },
};
</script>

<style lang="scss" scoped>
.el-table {
  ::v-deep .el-table__body-wrapper::-webkit-scrollbar {
    // width: 10px; /*滚动条宽度*/
    height: 13px; /*滚动条高度*/
  }
  /*定义滚动条轨道 内阴影+圆角*/
  ::v-deep .el-table__body-wrapper::-webkit-scrollbar-track {
    box-shadow: 0px 1px 3px #fff inset; /*滚动条的背景区域的内阴影*/
    border-radius: 10px; /*滚动条的背景区域的圆角*/
    background-color: #fff; /*滚动条的背景颜色*/
  }
  /*定义滑块 内阴影+圆角*/
  ::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
    box-shadow: 0px 1px 3px #aacde4 inset; /*滚动条的内阴影*/
    border-radius: 10px; /*滚动条的圆角*/
    background-color: #aacde4; /*滚动条的背景颜色*/
  }
  ::v-deep .el-table__body-wrapper {
  &::-webkit-scrollbar { // 滚动条高 解决行未对齐
    height: 13px;
   
  }
}
::v-deep .el-table__fixed-body-wrapper .el-table__body {
  padding-bottom: 13px; // 滚动条高度
  // padding-right: 10px;
}
}
.dialogBox {
  ::v-deep .el-dialog__header {
    //   background-color: #e8fdfe;
  }
  ::v-deep .el-dialog__body {
    padding-top: 5px;
    box-sizing: border-box;
    //   background-color: #e8fdfe;
  }
}

.InvoiceMessage {
  width: 100%;
  height: 500px;
  display: flex;
  padding: 0 20px 20px 0;
  box-sizing: border-box;

  // 侧边栏css
  .sidebar {
    width: 10%;
    min-width: 150px;
	padding: 10px 10px 10px 10px;
    ul {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      li {
        width: 110px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        margin-bottom: 15px;
        cursor: pointer;
        background-color: #e4eff0;
        color: #666666;
        cursor: pointer;
      }
      li.active {
        background-color: #5cb5b8;
        color: #fff;
      }
    }
  }

  //内容区css
  .finance_main {
    width: 90%;
    height: 100%;
    overflow-y: auto;
    overflow-x: auto;
    border-radius: 8px;
    padding: 10px 10px 10px 10px;
    box-sizing: border-box;
    .el-table {
      width: 100%;
    }
  }
}
</style>