<template>
    
 <!-- 点击删除弹出再次确认弹窗的阴影盒子 -->
 <div class="savePop" @click="consumer_offs"   style="z-index: 1000;">
        <!-- 弹窗 -->
        <div class="save_main">
          <div class="save_size">
            <p>
              <img src="../assets/icons/警告 (1).png" alt="">
            确认
          </p>
          <span v-if="numsAdd == 0" class="size_floot">{{ addNums == 1?'取消':'删除' }} 后不可恢复，确认{{addNums == 1?'取消':'删除'}}吗？</span>
          <span v-else class="size_floot">确认入住吗？</span>
          </div>
         
          <div class="save_button">
            <el-button
            @click="noDelete"
            class="cancelButton">
              取消
            </el-button>
            <el-button  
            @click="areDelete"
            style="background-color: #FDD4D9;color: #D9001B;"
            class="submitButton"
            >
              确定
            </el-button>
          </div>
        </div>
      </div>
  
</template>

<script>
    export default {
        name: 'WhetherDelete',
        data() {
            return{
              addNums: 0,
              numsAdd: 0,
            }
        },
        methods: {
            //确定删除
            areDelete() {
                this.$emit('whetherDelete', 1)
            },
            //不删
            noDelete() {
                this.$emit('whetherDelete', 0)
            },
           //点击确认和取消按钮和弹窗以外关闭弹窗
    consumer_offs(e) {
              // this.isConsumotion = false
              // console.log(e.target.nodeName);
              // console.log(e.target.className);
              // if(e.target.className === "savePop"){
              //   this.$emit('whetherDelete', 0)
              // }
            },
        }
    }
</script>

<style lang="scss" scoped>
//弹窗的阴影盒子
.savePop{
    position: fixed;
          top:0;
          bottom:0;
          left:0;
          right:0;
          background-color: rgba(133, 106, 106, 0.4);
          z-index: 100;
          display: flex;
          justify-content: center;
          align-items: center;
    //阴影里的 删除盒子css
    .save_main{
      width: 500px;
      height: 180px;
      background-color: #fff;
      .save_size{
        width: 100%;
        height: 126px;
        background: linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%);
        border-radius: 5px;
        padding-top: 30px;
        box-sizing: border-box;
       
        p{
          height: 40px;
          padding-left: 45px;
          box-sizing: border-box;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        
          img{
            width: 15px;
            height: 15px;
          }
        }
        .size_floot{
          padding-left: 43px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #555555;
      }
    }
      .save_button{
        // display: flex;
        // justify-content: end;
        text-align: right;
        padding-right: 20px;
        box-sizing: border-box;
        .el-button{
          margin-left: 20px;
          width: 100px;
        }
      }
    }
  }
</style>