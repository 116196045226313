import request  from "@/utils/request";
import qs from 'qs'
//角色设置
//分页列表查询/角色
const RoleManagementList = (params = {}) => {
   return request.post('/base/baseRole/page', params)
}
//新增角色
const AddRoleManagement = (params = {}) => {
    return request.post('/base/baseRole', params)
 }
 //修改角色
const UpdateRoleManagement = (params = {}) => {
   return request.put('/base/baseRole', params)
}
 //根据类型编码查询字典项,并排除指定项/认证服务
 const AnyUserOauth = (params = {}) => {
    return request.post('/oauth/anyUser/dict/findDictMapItemListByKey', params)
 }
 //给角色配置资源
 const baseRoleRoleResource = (params = {}) => {
   return request.post('/base/baseRole/roleResource', params)
}
//查询角色拥有的资源id集合
const baseRoleResourceList = (params = {}) => {
   return request.get('/base/baseRole/resourceList?roleId=' + params.roleId)
}
//查询可用的应用资源列表
const findAvailableApplicationResourceList = (params = {}) => {
   return request.get('/system/defApplication/findAvailableApplicationResourceList')
}
//查询该系统对应的权限
const visibleResource = (params = {}) => {
   return request.get('/oauth/anyone/visible/resource?applicationId=' + params.id + `&_t=${params._t}`)
}
//新增用户当班信息
const userClassesSave = (params = {}) => {
   return request.get('/oauth/oauth/userClasses/save?orgId=' + params.orgId + `&userId=${params.userId}` + '&nickName=' +params.nickName)
}
//前台酒店角色：新增
const baseOrgRole = (params = {}) => {
   return  request.post('/base/orgRole', params)
 }
 //前台酒店角色：编辑
 const baseOrgRoleUpdate = (params = {}) => {
   return  request.put('/base/orgRole', params)
 }
  //前台酒店角色：分页列表
  const baseOrgRolePage = (params = {}) => {
   return  request.post('/base/orgRole/page', params)
 }
 //前台酒店角色：给角色配置资源
 const baseRoleRoleResourcess = (params = {}) => {
   return request.post('/base/orgRole/roleResource', params)
}
//前台酒店角色：查询角色拥有的资源id集合
const baseRoleResourceListss = (params = {}) => {
   return request.get('/base/orgRole/resourceList?roleId=' + params.roleId)
}
//前台酒店角色：删除角色
const DeleteRole = (params = {}) => {
   return request.delete('/base/orgRole',{data:params.ids})
}
//前台酒店角色：删除角色
const DeleteRoles = (params = {}) => {
   return request.delete('/base/baseRole',{data:params.ids})
}
export{
    RoleManagementList,
    AddRoleManagement,
    AnyUserOauth,
    baseRoleRoleResource,
    baseRoleResourceList,
    findAvailableApplicationResourceList,
    visibleResource,
    userClassesSave,
    baseOrgRole,
    baseOrgRoleUpdate,
    UpdateRoleManagement,
    baseOrgRolePage,
    baseRoleRoleResourcess,
    baseRoleResourceListss,
    DeleteRole,
    DeleteRoles
}