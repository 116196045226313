<template>
  <!-- 发票管理 -->
  <div class="invoiceManagement" ref="invoiceManagement">
    <el-form :inline="true" ref="form" :model="ruleFrom" label-width="70px" class="from-flex" >
			<el-form-item label="录入时间">
				<el-date-picker
				  v-model="value1"
				  value-format="yyyy-MM-dd HH:mm:ss"
				  style="width: 230px"
				  type="daterange"
				  range-separator="至"
				  start-placeholder="开始日期"
				  end-placeholder="结束日期"
				>
				</el-date-picker>
			  </el-form-item>
			  <el-form-item label="发票No:">
				<el-input v-model.number="ruleFrom.number" placeholder="发票No"></el-input>
			  </el-form-item>
			  <el-form-item label-width="90px" label="订单编号：">
				<el-input v-model.number="ruleFrom.orderId" placeholder="订单编号"></el-input>
			  </el-form-item>
      <el-form-item>
        <el-button size="mini" @click="subButton(1)"> 查询 </el-button>
        <el-button size="mini" @click="subButton(2)"> 重置 </el-button>
      </el-form-item>
    </el-form>
    <whether-delete @whetherDelete="whetherDelete" v-if="isDelete">
    </whether-delete>
    <!--  -->
    <invoice-message ref="searchBox" :tableheight='tableheight'></invoice-message>
    <!-- 发票开具 -->
    <el-dialog
      title="发票开具"
      :visible.sync="isIssua"
      width="30%"
      append-to-body
      class="dialogBox"
    >
      <IssueAnInvoice ref="Issbox"></IssueAnInvoice>
    </el-dialog>
  </div>
</template>

<script>
import WhetherDelete from "_components/WhetherDelete";
import {
  invoiceManagementList,
  invoiceManagementUpadate,
} from "_api/conditionOfHouse";
import InvoiceMessage from "../components/orderInformation/components/InvoiceMessage/InvoiceMessage.vue";
import IssueAnInvoice from "../components/orderInformation/components/InvoiceMessage/IssueAnInvoice.vue";
export default {
  name: "invoiceManagement",
  components: {
    WhetherDelete,
    InvoiceMessage,
    IssueAnInvoice,
  },
  data() {
    return {
      current: 1,
      size: 6,
      total: 0,
      isDelete: false,
      isIssua: false,
      invoiceManagementTable: [],
      ruleFrom: {
        createdTime: "", //创建时间
        updatedTime: "", //最后修改时间
        number: "", //发票编号
        orderId: "", //订单编号
        orgId: localStorage.getItem("orgid"),
        state: true,
      },
      value1: "",
      updateFrom: {
        number: "231", //发票编号
        orderId: "1231", //订单编号
        unit: "", //单位/客户
        type: "", //开票类型 0增值税普通发票 1增值税专用发票 2增值税电子发票
        state: true,
        orgId: localStorage.getItem("orgid"),
        id: 1,
      },
      addFrom: {
        number: "231", //发票编号
        orderId: "1231", //订单编号
        unit: "", //单位/客户
        type: "", //开票类型 0增值税普通发票 1增值税专用发票 2增值税电子发票
        price: 200, //开票金额
        remark: "", //备注
        state: true,
        orgId: localStorage.getItem("orgid"),
      },
	  //首页来的
	  homesign:false,
	  tableheight: 0, //表格高度
    };
  },
  created() {
    // this.invoiceManagementListed()
    // this.invoiceManagementUpadateed()
  },
  mounted() {
    this.autoHeight();
  },
  methods: {
    Asideinvoice() {
      this.$nextTick(() => {
		this.$refs.searchBox.orderStates = true;  
        this.$refs.searchBox.ListInvoiced();
      });
    },
	//首页进入
	homEnter(){
		this.$nextTick(() => {
			this.$refs.searchBox.orderStates = true;  
			this.$refs.searchBox.ListInvoiced({orderId:null});
		});
		this.homesign=true;
	},
	
    // //分页页数变化触发
    // handleCurrentChange(val) {
    //   this.current = val
    //   this.invoiceManagementListed()
    // },
    // //表格的删除和修改的操作
    // tableButton(row, num) {
    //   if(num === 1){
    //     //删除
    //     this.isDelete = true
    //   }else{

    //   }
    // },
    //查询按钮
    subButton(num) {
      if (num === 1) {
        //查询
        if(this.value1) {
        this.value1[0] = this.value1[0].slice(0,10) +' ' + '01:00:00'
          this.value1[1] = this.value1[1].slice(0,10) +' ' + '23:59:59'
      }
        const params = {
          createdTime: this.value1[0], //创建时间
          updatedTime: this.value1[1], //最后修改时间
          number: this.ruleFrom.number, //发票编号
          orderId:this.homesign?(this.ruleFrom.orderId?this.ruleFrom.orderId:null):this.ruleFrom.orderId, //订单编号
          orgId: localStorage.getItem("orgid"),
          state: true,
        };
        console.log(params);
        this.$refs.searchBox.ListInvoiced(params);
      } else if (num == 2) {
        this.ruleFrom = {
          number: "", //发票编号
          orderId: "", //订单编号
          orgId: localStorage.getItem("orgid"),
          state: true,
        };
        this.value1 = "";
        this.$refs.searchBox.ListInvoiced();
      }
    },

    //发票开具的新增接口

    //分页请求条件查询
	
	//高度自适应
	autoHeight() {
	  // 初始化的时候，设置高度
	  this.setHeight();
	  // 改变浏览器窗口大小的时候，设置高度
	  window.addEventListener("resize", () => {
	    this.setHeight();
	  });
	},
	// 设置高度
	setHeight() {
	  this.$nextTick(() => {
	    if (this.$refs.invoiceManagement) {
	      let height = this.$refs.invoiceManagement.offsetHeight;
	      // console.log('元素高度',height);
	      this.tableheight = height -80; // 这里的X就是顶部栏的高度
	      console.log('高度',this.tableheight)
	      this.$refs.searchBox.tableheight=this.tableheight;
	      // document.getElementsByClassName("el-table__fixed-body-wrapper").style.max-height = "440px";
	    }
	  });
	},
	
  },
};
</script>

<style lang="scss" scoped>
.dialogBox {
  ::v-deep .el-dialog__header {
    // background-color: #e8fdfe;
  }
  ::v-deep .el-dialog__body {
    // background-color: #e8fdfe;
  }
}
.invoiceManagement {
  width: 100%;
  height: 100%;
  // background-color: #e8fdfe;
  .el-form {
    .el-form-item:first-child {
      margin-left: 30px;
    }
    .el-form-item {
    }
    .el-form-item:last-child {
      margin-left: 14%;
      .el-button {
        background-color: #5cb5b8;
        color: #fff;
        border-radius: 6px;
      }
      .el-button:last-child {
        background-color: #fff;
        color: #999999;
        border-radius: 6px;
      }
    }
  }
}



.InvoiceMessage{
	
}
</style>