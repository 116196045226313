import request from "_utils/request";
import qs from "qs";

//分页列表查询
const memberList = (params = {}) => {
  return request.post("/wineshop/sysMemberGrade/page", params);
};
//添加会员等级
const AddmemberList = (params = {}) => {
  return request.post("/wineshop/sysMemberGrade/save", params);
};
//分页查询会员等级列表: 分页等级列表
const gradeMembershipList = (params = {}) =>{
return  request.post("/wineshop/sysMemberGrade/custpage", params);

}

  
//修改会员等级
const updateGradeMembership = (params = {}) =>{
  return request.put("/wineshop/sysMemberGrade/update", params);

}
 
//删除会员等级
const DeleteGradeMembership = (params = {}) =>{
  return request.delete("/wineshop/sysMemberGrade/delete/"+ params.id);

}
 //会员等级表：查询会员等级详情
 const InquireMembership = (params = {}) =>{
  return  request.get("/wineshop/sysMemberGrade/by/" + params.id);
}
//会员明细分页列表
const membershipDetailsList = (params = {}) =>{
  return request.post("/wineshop/memberInformation/custPage", params);
   
}

//会员信息表、会员开卡:添加会员
const membershipDetailsAdd = (params = {}) =>{
return  request.post("/wineshop/memberInformation/add", params);
}
  //查看会员信息详情:会员明细
  const MembershipDetails = (params = {}) =>{
    return  request.get("/wineshop/memberInformation/detail/" + params.id);
  }
 
//房价码查看
const HousePriceCode = (params = {}) => {
  return request.get("/wineshop/house_price_code/type_view", {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    params: qs.stringify(params),
  });
}
  
//入住记录分页:会员明细的表格里的点击查看弹出的弹窗里的入住表格的请求接口
const CheckinRecordList = (params = {}) => {
return  request.post("/wineshop/memberInformation/roomdetaitl/page", params);
}
 
//余额记录分页:会员明细的表格里的点击查看弹出的弹窗里的余额记录表格的请求接口
const BalanceRecordList = (params = {}) => {
  return  request.post("/wineshop/memberInformation/balance/page", params);
}
 
//开卡记录列表:会员明细的表格里的点击查看弹出的弹窗里的开卡记录表格的请求接口
const CardOpeningRecordList = (params = {}) => {
  return request.get("/wineshop/memberInformation/activate/query/"+ params.id);
}

//会员等级表、根据orgId查询会员等级列表： 查看所有的等级
const LevelOfMembershipAll = (params = {}) => {
  return request.get("/wineshop/sysMemberGrade/sysMemberGrade/list/"+ params.tenantId);
}
  //会员等级删除：会员等级表、
  const DeleteMember = (params = {}) => {
    return request.delete("/wineshop/sysMemberGrade/delete/"+ params.id)
  }
  //会员明细：编辑会员信息：更新
  const UpdateMember = (params = {}) => {
    return  request.post("/wineshop/memberInformation/update", params);
    }
  //会员动账记录表:根据创建时间倒序分页列表
  const DetailOfStoredValueList = (params = {}) => {
    return  request.post("/wineshop/memberChangeAmtRecord/creationPage", params);
    }
    //会员动账记录表:储值使用明细分页-本店充值记录

    //会员信息表:发卡统计分页
    const CardIssuingStatisticsList = (params = {}) => {
      return  request.post("/wineshop/memberInformation/tuck/page", params);
      }
      //会员动账记录表/储值使用明细分页-本店充值记录
      const StoredValueList = (params = {}) => {
        return  request.post("/wineshop/memberChangeAmtRecord/list", params);
        }
      //会员信息表:会员合计
      const TobalMembership = (params = {}) => {
        return request.get("/wineshop/memberInformation/memberCount", {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          params: qs.stringify(params),
        });
      }
      //会员充值?会员动账记录表
      const MemberTopUp = (params = {}) => {
        return  request.post("/wineshop/memberChangeAmtRecord/add", params);
        }
        //移出黑名单
        const DeBlacklisted = (params = {}) => (
          request.post('/wineshop/blackRecord/add/black',  
          qs.stringify(params)
          , {
              headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
              }
            })
      )
      //软删除会员信息
      const DeleteMemberShip = (params = {}) => {
        return  request.delete("/wineshop/memberInformation/delete/" + params.id);
        }
        //编辑会员信息
      const UpadateMember = (params = {}) => {
        return request.post('/wineshop/memberInformation/update', params)
      }
      //会员导出
      const memberInformationExport = (params = {}) => (
        request.post('/wineshop/memberInformation/export', params,{
          responseType: 'arraybuffer',
      
        }
        
        )
      )
//会员消费  
const memberConsumption = (params = {}) => {
	return	request.post("/wineshop/memberChangeAmtRecord/member/consumption", params);
}
//根据租户id查看房价码分页列表
const housePriceCodeHousePriceList = (params = {}) => (
  request.post("/wineshop/house_price_code/housePriceList", params)
)
//房价码和会员关系表查分页查看
const sysMemberGrade = (params = {}) => (
  request.post("/wineshop/sysMemberGrade/mem/code/rel", params)
)
//添加会员等级
const sysMemberGradeSave = (params = {}) => (
  request.post("/wineshop/sysMemberGrade/save", params)
)
//修改会员等级
const sysMemberGradeUpdate = (params = {}) => (
  request.put("/wineshop/sysMemberGrade/update", params)
)
//会员余额消费对账(适用于未结账-已结账-门店分页列表)
const balanceReconciliation = (params = {}) => (
  request.post("/wineshop/memberChangeAmtRecord/balance_consumption_reconciliation", params)
)
//会员余额消费批量结账-未结帐转已结账
const balanceBulkClosing = (params = {}) => (
  request.post(`/wineshop/memberChangeAmtRecord/balance_bulk_closing?settlementRemarks=${params.settlementRemarks}` , params.ids)
)
//导出会员消费对账Excel
const memberChangeAmtRecord = (params = {}) =>
request.post("/wineshop/memberChangeAmtRecord/export/balance", params,{
  responseType: "arraybuffer",
});
//取消支付
const memberChangemtRecord = (params = {}) => {
	return	request.post("/wineshop/memberChangeAmtRecord/balanceRefund", qs.stringify(params),
  {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
  }
  );
}

//微信退款
const wxPayRefund = (params = {}) => (
  request.post("/applet/pay/wxPayRefund?orderId="+params.orderId)
)
export {
  memberList,
  AddmemberList,
  gradeMembershipList,
  updateGradeMembership,
  DeleteGradeMembership,
  membershipDetailsList,
  membershipDetailsAdd,
  HousePriceCode,
  CheckinRecordList,
  BalanceRecordList,
  CardOpeningRecordList,
  MembershipDetails,
  LevelOfMembershipAll,
  DeleteMember,
  UpdateMember,
  DetailOfStoredValueList,
  CardIssuingStatisticsList,
  StoredValueList,
  InquireMembership,
  TobalMembership,
  MemberTopUp,
  DeBlacklisted,
  DeleteMemberShip,
  UpadateMember,
  memberInformationExport,
  memberConsumption,
  housePriceCodeHousePriceList,
  sysMemberGrade,
  sysMemberGradeSave,
  sysMemberGradeUpdate,
  balanceReconciliation,
  balanceBulkClosing,
  memberChangeAmtRecord,
  memberChangemtRecord,
  wxPayRefund,
};
