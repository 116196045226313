<template>
  <!-- 会员详情 -->
  <div class="MembershipDetails">
    <!--点击查看弹出弹窗  -->

    <div class="mainFrom">
      <div class="userInfo_from">
        <!-- 姓名  手机号  会员卡 -->
        <div class="exhibiting">
          <div class="exhibiting_main">
            <span class="Garden"></span>
            <span
              v-if="
                MembershipDetails.name
                  ? MembershipDetails.name.length > 3
                    ? false
                    : true
                  : false
              "
            >
              {{
                MembershipDetails.name
                  ? MembershipDetails.name.length > 3
                    ? MembershipDetails.name.slice(0, 3) + "..."
                    : MembershipDetails.name
                  : null
              }}
            </span>
            <el-tooltip
              v-else
              class="item"
              effect="light"
              placement="top-start"
            >
              <div slot="content">{{ MembershipDetails.name }}</div>
              <span>
                {{
                  MembershipDetails.name
                    ? MembershipDetails.name.length > 3
                      ? MembershipDetails.name.slice(0, 3) + "..."
                      : MembershipDetails.name
                    : null
                }}
              </span>
            </el-tooltip>
          </div>
          <div class="exhibiting_main">
            <img src="../../../assets/imgs/mobileImg.png" alt="" />
            {{ MembershipDetails.mobile }}
          </div>
          <div class="exhibiting_main">
            <img src="../../../assets/imgs/shoujiImg.png" alt="" />
            {{ MembershipDetails.number }}
          </div>
          <div class="exhibiting_main">
            <img src="../../../assets/imgs/memberImgs.png" alt="" />
            {{ MembershipDetails.memberGradeName }}
          </div>
        </div>
        <div class="information">
          <div class="information_card">
            <!-- <p style="cursor: pointer">
              <span class="firstSpan">性别：</span>
              {{ MembershipDetails.gender === true ? "女" : "男" }}
            </p> -->
            <p style="cursor: pointer">
              <span class="firstSpan">是否黑名单：</span>
              {{ MembershipDetails.isBlack === 1 ? "是" : "否" }}
            </p>
          <p style="cursor: pointer">
            <span class="firstSpan">累计消费：</span>
            ￥ {{ MembershipDetails.accumulate }}
          </p>
          </div>
          <div class="information_card">
            <p style="color: #fc200f; cursor: pointer">
              <span class="firstSpan" style="color: black">余额：</span>
              ￥ {{ MembershipDetails.residualBalance }}
            </p>
           
            <p style="cursor: pointer">
              <span class="firstSpan">备注：</span>
              <span
                v-if="
                  MembershipDetails.remark
                    ? MembershipDetails.remark.length > 8
                      ? false
                      : true
                    : false
                "
              >
                {{
                  MembershipDetails.remark
                    ? MembershipDetails.remark.length > 8
                      ? MembershipDetails.remark.slice(0, 4) + "..."
                      : MembershipDetails.remark
                    : null
                }}
              </span>
              <el-tooltip
                v-else
                class="item"
                effect="light"
                placement="top-start"
              >
                <div slot="content">{{ MembershipDetails.remark }}</div>
                <span>
                  {{
                    MembershipDetails.remark
                      ? MembershipDetails.remark.length > 8
                        ? MembershipDetails.remark.slice(0, 4) + "..."
                        : MembershipDetails.remark
                      : null
                  }}
                </span>
              </el-tooltip>
            </p>
            <!-- <p style="cursor: pointer">
              <span class="firstSpan">开卡：</span>
              
              <span v-if="MembershipDetails.orgName?MembershipDetails.orgName.length > 5?false:true:null">
                {{ MembershipDetails.orgName?MembershipDetails.orgName.length > 5?MembershipDetails.orgName.slice(0,4) + "...":MembershipDetails.orgName:null }}
              </span>
              <el-tooltip
                v-else
                class="item"
                effect="light"
                placement="top-start"
              >
                <div slot="content">{{ MembershipDetails.orgName }}</div>
                <span>
                  {{
                    MembershipDetails.orgName
                      ? MembershipDetails.orgName.length > 5
                        ? MembershipDetails.orgName.slice(0, 4) + "..."
                        : MembershipDetails.orgName
                      : null
                  }}
                </span>
              </el-tooltip>
            </p> -->
            <!-- <p style="cursor: pointer">
              <span class="firstSpan">住址：</span>
              <span
                v-if="
                  MembershipDetails.address
                    ? MembershipDetails.address.length > 8
                      ? false
                      : true
                    : null
                "
              >
                {{
                  MembershipDetails.address
                    ? MembershipDetails.address.length > 8
                      ? MembershipDetails.address.slice(0, 4) + "..."
                      : MembershipDetails.address
                    : null
                }}
              </span>
              <el-tooltip
                v-else
                class="item"
                effect="light"
                placement="top-start"
              >
                <div slot="content">{{ MembershipDetails.address }}</div>
                <span>
                  {{
                    MembershipDetails.address
                      ? MembershipDetails.address.length > 8
                        ? MembershipDetails.address.slice(0, 4) + "..."
                        : MembershipDetails.address
                      : null
                  }}
                </span>
              </el-tooltip>
            </p> -->
          </div>
          <div class="information_card">
            <!-- <p style="cursor: pointer">
              <span class="firstSpan">赠送会员：</span>
              {{ MembershipDetails.isGift === 1 ? "否" : "是" }}
            </p> -->
            <p style="cursor: pointer">
              <span class="firstSpan">累计入住：</span>
              {{ MembershipDetails.accumulated }}次
            </p>
           
          </div>
        </div>

        <div class="optionButton">
          <el-button
          v-if="roomArrActive.includes('wineshop:memberInformation:update')"
          class="active" @click="updataMembers">编辑资料</el-button>
          <el-button 
          v-if="roomArrActive.includes('wineshop:memberChangeAmtRecord:add')?false:false"
          @click="MemberTopUp">会员充值</el-button>
          <el-button
          v-if="false"
          @click="consumptionopen">会员消费</el-button>
          <el-button
          v-if="roomArrActive.includes('wineshop:blackRecord:add:black')"
          @click="blacklist"
            >{{ MembershipDetails.isBlack === 1 ? "移出黑名单" : "加入黑名单" }}
          </el-button>
        </div>

        <div class="record_table">
          <div class="table_header">
            <ul>
              <div
                style="cursor: pointer"
                :class="{ active: optionButton === 1 }"
                @click="onOption(1)"
              ></div>
              <li
                style="cursor: pointer"
                :class="{ active: optionButton === 1 }"
                @click="onOption(1)"
              >
                入住记录
              </li>
              <div
                style="cursor: pointer"
                :class="{ active: optionButton === 2 }"
                @click="onOption(2)"
              ></div>
              <li
                style="cursor: pointer"
                :class="{ active: optionButton === 2 }"
                @click="onOption(2)"
              >
                余额记录
              </li>
              <!-- <div
                style="cursor: pointer"
                :class="{ active: optionButton === 3 }"
                @click="onOption(3)"
              ></div>
              <li
                style="cursor: pointer"
                :class="{ active: optionButton === 3 }"
                @click="onOption(3)"
              >
                开卡记录
              </li> -->
            </ul>
          </div>

          <!-- 入住记录表格 -->
          <el-row v-if="isCheck">
            <el-table
              :data="MembertableData"
              :header-cell-style="{
                background: '#F7F7F7',
                color: '#4C4C4C',
                'text-align': 'center',
              }"
              style="width: 100%"
            >
              <el-table-column prop="money" label="金额" align="center" width="90px">
              </el-table-column>
              <el-table-column prop="remark" label="备注" align="center">
                <template slot-scope="scope">
                  <span  v-if="
                scope.row.remark
                  ? scope.row.remark.length > 7
                    ? false
                    : true
                  : false
              ">
                    {{ scope.row.remark }}
                  </span>
                  <el-tooltip
              v-else
              class="item"
              effect="light"
              placement="top-start"
            >
              <div slot="content">{{ scope.row.remark }}</div>
              <span>
                {{
                  scope.row.remark
                    ? scope.row.remark.length > 7
                      ? scope.row.remark.slice(0, 7) + "..."
                      : scope.row.remark
                    : null
                }}
              </span>
            </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column prop="createdTime" label="时间" align="center" width="130px">
              </el-table-column>
              <el-table-column
                prop="echoMap.operator"
                label="操作人"
                width="80px"
                align="center"
              >
              </el-table-column>
              <el-table-column prop="operator" label="房间信息" align="center">
                <template slot-scope="scope">
                  {{ scope.row.roomInformation }}
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              :current-page.sync="ViewRecordPage"
              :page-size="ViewRecordSize"
              @current-change="ViewRecordChange"
              layout="total, prev, pager, next"
              :total="ViewRecordTotals"
              style="text-align: right"
            >
            </el-pagination>
          </el-row>

          <!-- 入住记录表格 -->

          <!-- 余额和开卡记录表格 -->
          <el-row v-if="isBalance">
            <el-table
              :data="MembertableData"
              :header-cell-style="{
                background: '#F7F7F7',
                color: '#4C4C4C',
                'text-align': 'center',
              }"
              style="width: 100%"
            >
              <el-table-column
                prop="money"
                label="金额"
                align="center"
                width="100"
              >
              </el-table-column>
             
              <el-table-column
                prop="remark"
                label="备注"
                align="center"
              >
              <template slot-scope="scope">
                  <span  v-if="
                scope.row.remark
                  ? scope.row.remark.length > 7
                    ? false
                    : true
                  : false
              ">
                    {{ scope.row.remark }}
                  </span>
                  <el-tooltip
              v-else
              class="item"
              effect="light"
              placement="top-start"
            >
              <div slot="content">{{ scope.row.remark }}</div>
              <span>
                {{
                  scope.row.remark
                    ? scope.row.remark.length > 7
                      ? scope.row.remark.slice(0, 7) + "..."
                      : scope.row.remark
                    : null
                }}
              </span>
            </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column
                prop="createdTime"
                label="时间"
                align="center"
                width="170"
              >
              </el-table-column>
              <el-table-column
                prop="money"
                label="是否退款"
                align="center"
                width="100"
              >
              <template slot-scope="scope">
                <span>
                  {{ 
                  scope.row.transferType == 0?'/':scope.row.transferType == 1?scope.row.isRefund == 0?'已退款':'未退款':''
                  }}
                </span>
              </template>
              </el-table-column>
              <el-table-column
                prop="echoMap.operator"
                label="操作人"
                align="center"
                width="90"
              >
              </el-table-column>
            </el-table>
            <el-pagination
              :current-page.sync="checkRecordPage"
              :page-size="checkRecordSize"
              @current-change="checkRecordChange"
              layout="total, prev, pager, next"
              :total="checkRecordTotals"
              style="text-align: right"
            >
            </el-pagination>
          </el-row>

          <el-table
            v-if="isBilling"
            :data="MembertableData"
            :header-cell-style="{
              background: '#F7F7F7',
              color: '#4C4C4C',
              'text-align': 'center',
            }"
            style="width: 100%"
          >
            <el-table-column
              :prop="this.isBilling ? 'price' : 'currentBalance'"
              label="金额"
              align="center"
              width="160"
            >
            </el-table-column>
            <el-table-column
              prop="remark"
              label="备注"
              align="center"
              width="220"
            >
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="时间"
              align="center"
              width="210"
            >
            </el-table-column>
            <el-table-column
              prop="echoMap.operator"
              label="操作人"
              align="center"
              width="160"
            >
            </el-table-column>
          </el-table>
          <!-- 余额和开卡记录表格 -->
        </div>
      </div>
    </div>

    <!-- 点击移出黑名单弹出再次确认弹窗的阴影盒子 -->
    <div
      class="savePop"
      v-if="isBlacklist"
      @click="
            (e) => {
              consumer_offs(e, 1);
            }
          "
    >
      <!-- 弹窗 -->
      <div class="save_main">
        <div class="save_size">
          <p>
            <img src="../../../assets/icons/警告 (1).png" alt="" />
            {{
              MembershipDetails.isBlack === 1
                ? " 确认移除黑名单吗？"
                : " 确认加入黑名单吗？"
            }}
          </p>
          <span class="size_floot">
            {{
              MembershipDetails.isBlack === 1
                ? " 移除后会员绑定的证件号将恢复入住功能？ "
                : " 加入后会员绑定的证件号将禁止入住功能？"
            }}
          </span>
        </div>

        <div class="save_button">
          <el-button class="cancelButton"  @click="MoveBlack(0)"> 取消 </el-button>
          <el-button
            style="background-color: #fdd4d9; color: #d9001b"
            class="submitButton"
            @click="MoveBlack(1)"
          >
            {{ MembershipDetails.isBlack === 1 ? " 移出" : " 加入" }}
          </el-button>
        </div>
      </div>
    </div>
    <!-- 点击会员充值弹出弹窗 -->
    <el-dialog
      title="会员充值"
      destroy-on-close
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="400px"
      append-to-body
      :before-close="handleClose"
      class="addMember"
    >
      <div class="addMemberGrade">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ADDPRICE"
          label-width="80px"
          class="demo-ruleForm"
        >
          <el-form-item label="充值金额" prop="transferAmount">
            <el-input v-model="ruleForm.transferAmount"></el-input>
          </el-form-item>
          <el-form-item label="实收金额" prop="amountMoney">
            <el-input v-model.number="ruleForm.amountMoney"></el-input>
          </el-form-item>
          <el-form-item label="付款方式" prop="paymentId">
            <el-select
              style="width: 100%"
              v-model="ruleForm.paymentId"
              @change="paymentButton"
              placeholder="请选择付款方式"
            >
              <el-option
                v-for="(item) in paymentFrom"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="备注" prop="remark">
            <el-input
              type="textarea"
              :autosize="{minRows: 2, maxRows: 2}"
              placeholder="请输入备注信息"
              v-model="ruleForm.remark"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <div class="subMember" style="text-align: right">
          <el-button
            style="
              width: 100px;
              height: 40px;
              background-color: #5cb5b8;
              color: #fff;
              border-radius: 6px;
            "
            @click="buttonAddmomber"
          >
            充值
          </el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 点击会员消费弹出弹窗 -->
    <el-dialog
      title="会员消费"
      destroy-on-close
      :close-on-click-modal="false"
      :visible.sync="consumption"
      width="400px"
      append-to-body
      :before-close="consumptionClose"
      class="addMember"
    >
      <div class="addMemberGrade">
        <el-form ref="ruleForm" label-width="80px" class="demo-ruleForm">
          <el-form-item label="充值金额" prop="residualBalance">
            <el-input
              v-model="MembershipDetails.residualBalance"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="消费金额" prop="transferAmount">
            <el-input-number
              v-model="memberpriceinfo.transferAmount"
              @change="consumechange"
              :min="0"
              label="描述文字"
            ></el-input-number>
          </el-form-item>
          <!-- :max="parseFloat(MembershipDetails.residualBalance)" -->
          <el-form-item label="备注" prop="remark">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="memberpriceinfo.remark"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <div class="subMember" style="text-align: right">
          <el-button
            size="medium"
            style="
              width: 100px;
              height: 40px;
              background-color: #5cb5b8;
              color: #fff;
              border-radius: 6px;
            "
            @click="consumptionsure"
          >
            确认消费
          </el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 编辑会员弹窗 -->
    <el-dialog
      title="编辑会员"
      :close-on-click-modal="false"
      :visible.sync="isUpdateMember"
      class="UpdateMember"
      width="670px"
      append-to-body
      :before-close="handleClose"
    >
      <update-members @updataMember="updataMember" ref="UPDATEBOX"></update-members>
    </el-dialog>
    <!-- 编辑会员弹窗 -->
    <!--点击查看弹出弹窗   -->
  </div>
</template>

<script>
import {
  CheckinRecordList,
  BalanceRecordList,
  CardOpeningRecordList,
  MembershipDetails,
  MemberTopUp,
  DeBlacklisted,
  memberConsumption,
} from "_api/member";
import { Getpayment } from "_api/conditionOfHouse";
import UpdateMembers from "./UpdateMember.vue";
export default {
  name: "MembershipDetails",
  components: {
    UpdateMembers
  },
  data() {
    return {
         //会员明细的表格的数据
      tableData: [],
      rules: {
        transferAmount: [
        { required: true, message: '请填写充值金额', trigger: 'blur' },
        ],
        amountMoney: [
        { required: true, message: '请填写实收金额金额', trigger: 'blur' },
        ],
        paymentId: [
        { required: true, message: '请选择付款方式', trigger: 'blur' },
        ]
      },
      fooo: null,
      paymentFrom: [],
      isShow: false, //控制本店充值的显示
      optionButton: 1, //控制点击查看弹出弹窗里的记录的表格的显示隐藏的参数
      isMessage: false, //控制点击表格中的查看弹出弹窗的显示隐藏
      isCheck: true, //控制点击查看弹出弹窗里的入住记录的表格的显示隐藏
      isBalance: false, //控制点击查看弹出弹窗里的余额记录的表格的显示隐藏
      dialogVisible: false, //控制会员充值弹出弹窗的显示隐藏
      isBlacklist: false, //控制移出黑名单再次确认弹出弹窗的显示隐藏
      isBilling: false, //开卡记录
      isUpdateMember: false, //编辑会员的弹窗
      consumption: false, //会员消费
      checkRecordPage: 1, //余额和开卡的分页
      checkRecordSize: 10, //余额和开卡的分页一页多少条
      checkRecordTotals: 0,
      ViewRecordPage: 1, //入住记录表格分页
      ViewRecordSize: 10, //入住记录表格分页一页多少条
      ViewRecordTotals: 0,
      addRoomCurrent: 1, //入住记录的参数
      addRoomSize: 10,
      memberId: null,//会员id
      MembershipDetails: {}, //点击表格的查看弹出弹窗的数据
       //会员充值的表单数据
       ruleForm: {
        remark: "", //备注
        transferAmount: "", //充值金额
        amountMoney: "", //实收金额
        paymentId: "", //付款方式Id
        paymentName: null,//付款方式名称
      },
           //会员信息弹窗里的表格数据
           MembertableData: [],
      memberpriceinfo: {
        // "amountMoney": 0,
        // "createdBy": 0,
        // "createdTime": "",
        currentBalance: 0, //当前余额
        memberId: 0, //会员ID
        // "orderId": 0,//关联订单号
        orgId: localStorage.getItem("orgid"),
        // "paymentId": 0,
        // "paymentName": "",
        remark: "", //备注
        state: true, //状态（1为启用，0为禁用）
        tenantId: localStorage.getItem("TenantId"), //租户编码
        transferAmount: 0, //动账金额
        transferType: 1, //动账类型（0入账 1消费）
        // "updateTime": "",
        // "updatedBy": 0,
        userClassesId: localStorage.getItem("userClassesId"),
        // "version": 0
      },
      roomArrActive: localStorage.getItem("permission")
        ?JSON.parse(localStorage.getItem("permission")) 
        : [], //功能权限数据
    };
  },
  methods: {
    MoveBlack(num) {
      if(num == 0) {
        this.isBlacklist = false
      } else if(num == 1) {
        this.DeBlacklisteds();
      }
     
    },
     //余额记录、开卡记录
     checkRecordChange(val) {
      console.log(val);
      if (this.tablePage === 2) {
        this.checkRecordPage = val;
        this.BalanceRecordListed();//余额记录分页
        
      } else if (this.tablePage === 3) {
        this.checkRecordPage = val;
        this.CardOpeningRecordListed(); //开卡记录分页
      
      }
    },
     //编辑会员的自定义事件
     updataMember(num) {
      this.isUpdateMember = false;
      this.MembershipCarded(this.memberId);
      if(this.optionButton == 1) {
          this.CheckinRecordListed();//入住记录表的分页
        }else if(this.optionButton == 2) {
          this.BalanceRecordListed()//余额记录分页
        } else if(this.optionButton == 3) {
          this.CardOpeningRecordListed(); //开卡记录分页
        }
    },
    //入住记录表的分页
    ViewRecordChange(val) {
      this.ViewRecordPage = val;
      this.CheckinRecordListed();//入住记录表的分页
    },
    //编辑会员的按钮事件
    updataMembers() {
      this.isUpdateMember = true;
      this.$nextTick(() => {
        this.$refs.UPDATEBOX.ruleForm = {
          ...this.MembershipDetails,
          idCard: this.MembershipDetails.idCard?this.MembershipDetails.idCard != 0?this.MembershipDetails.idCard: '':''
        }
        this.$refs.UPDATEBOX.ruleForm.gender =  this.MembershipDetails.gender?'1': '0'//性别
        this.$refs.UPDATEBOX.ruleForm.isGift = this.MembershipDetails.isGift === 1?'1':'0'//是否赠予 0是 1否
      })
    },
  
    //查看会员信息详情:点击表格的查看获取会员信息
    MembershipCarded(num) {
      MembershipDetails({ id: num }).then((res) => {
        if (res.data.code === 0) {
          this.MembershipDetails = res.data.data;
          this.$store.commit("base/SET_MEMBERDATA", res.data.data);
          this.memberId = num;
        } else {
          this.$message(res.data.errorMsg);
        }
      });
    },
    //点击移除或添加黑名单弹出弹窗
    blacklist() {
      this.isBlacklist = true;
    },

    //移出黑名单
    DeBlacklisteds() {
      if (this.MembershipDetails.isBlack === 1) {
        var params = {
          memberId: this.MembershipDetails.id, //会员id
          isBlack: 0, //是否是黑名单
        };
      } else {
        var params = {
          memberId: this.MembershipDetails.id, //会员id
          isBlack: 1, //是否是黑名单
        };
      }

      DeBlacklisted(params).then((res) => {
        if (res.data.code === 0) {
          this.isSave = false;
          this.isBlacklist = false;
          this.isConsumotion = false;
          this.MembershipCarded(this.memberId);
          if (params.isBlack == 1) {
            //移出黑名单
            this.$message({
              type: "success",
              message: "加入黑名单成功",
            });
          } else {
            this.$message({
              type: "success",
              message: "移出黑名单成功",
            });
          }
          if(this.optionButton == 1) {
          this.CheckinRecordListed();//入住记录表的分页
        }else if(this.optionButton == 2) {
          this.BalanceRecordListed()//余额记录分页
        } else if(this.optionButton == 3) {
          this.CardOpeningRecordListed(); //开卡记录分页
        }
        }
      });
    },
    //点击会员充值弹出弹窗
    MemberTopUp() {
      this.dialogVisible = true;
      this.ruleForm = {
        transferAmount: null,
        amountMoney: null,
        fooo: null,
        remark: null,
      }
      this.Getpaymented();
    },
    //会员充值的付款方式变动时触发
    paymentButton() {
      this.paymentFrom.forEach(el => {
        if(el.id == this.ruleForm.paymentId) {
          this.ruleForm.paymentName = el.name
        }
      })
    },
    //会员充值的接口
    MemberTopUped() {
      const params = {
        memberId: this.MembershipDetails.id,
        transferAmount: Number(this.ruleForm.transferAmount), //充值金额
        amountMoney: Number(this.ruleForm.amountMoney), //实收金额
        paymentId: this.ruleForm.paymentId, //付款方式id
        paymentName: this.ruleForm.paymentName,
        remark: this.ruleForm.remark, //备注
        orgId: localStorage.getItem("orgid"),
        name: localStorage.getItem("name"),
        userClassesId: localStorage.getItem("userClassesId"),
      };
      MemberTopUp(params).then((res) => {
        if (res.data.code === 0) {
          this.dialogVisible = false;
          this.MembershipCarded(this.memberId);
          this.BalanceRecordListed()
          this.onOption(2);
          this.$message({
            type: "success",
            message: "会员充值成功",
          });
          // this.isMessage = false;
          // this.isBlacklist = false;
          // this.isConsumotion = false;
        }
      });
    },
    //点击充值触发发送充值的接口
    buttonAddmomber() {
      this.$refs.ADDPRICE.validate((valid) => {
          if (valid) {
            this.MemberTopUped();
          } else {
          this.$message({
            type: 'error',
            message: '请按要求填写信息'
          })
            return false;
          }
        });
      
    },
    //付款方式/付款查看
    Getpaymented() {
      Getpayment().then((res) => {
        if (res.data.code === 0) {
          this.paymentFrom = res.data.data.filter(el =>  el.name != '协议挂账' && el.name != '美团预付' && el.name != '飞猪预付' && el.name != '携程预付' &&el.name != '抖音预付' && el.name != '挂房账' && el.name != '挂账' &&el.name != '微储值' && el.name != '挂房账' && el.name != '餐饮挂账' && el.name != '商超挂账' && el.name != '预授权' );
        }
      });
    },
    //点击会员充值弹窗以外关闭弹窗
    handleClose(done) {
      done();
      this.MembershipCarded(this.memberId);
    },
    //点击查看弹出会员信息弹窗里的入住记录分页列表请求
    CheckinRecordListed() {
      if(this.roomArrActive.includes('wineshop:memberInformation:roomdetaitl:page')) {
        const params = {
        current: this.ViewRecordPage,
        size: this.checkRecordSize,
        model: {
          memberId: this.memberId,
        },
      };
      CheckinRecordList(params).then((res) => {
        if (res.data.code === 0) {
          this.MembertableData.splice(0);
          this.MembertableData = res.data.data.records;
          // this.ViewRecordPage = 1;
          this.ViewRecordTotals = Number(res.data.data.total);
        }
      })
      }else{
        this.$message({
            type: "error",
            message: "入住记录分页数据查看无权限",
          });
      }
    
    },
    //点击查看弹出会员信息弹窗里的余额记录分页列表请求
    BalanceRecordListed() {
      if(this.roomArrActive.includes('wineshop:memberInformation:balance:page')) {
        const params = {
        current: this.checkRecordPage,
        size: this.checkRecordSize,
        model: {
          memberId: this.memberId,
        },
      };
      BalanceRecordList(params).then((res) => {
        if (res.data.code === 0) {
          this.MembertableData.splice(0);
          this.MembertableData = res.data.data.records;
          this.MembertableData.forEach((el) => {
            if (!el.transferType) {
              el.money = "+" + el.money;
            } else {
              el.money = "-" + el.money;
            }
          });
          this.checkRecordTotals = Number(res.data.data.total);
        } else {
          this.MembertableData.splice(0);
          this.checkRecordTotals = 0;
          this.$message({
            type: "error",
            message: "请求接口失败",
          });
        }
      });
      }else{
        this.$message({
            type: "error",
            message: "余额记录数据查看无权限",
          });
      }
    
    },
    //点击查看弹出会员信息弹窗里的开卡记录分页列表请求
    CardOpeningRecordListed() {
      if(this.roomArrActive.includes('wineshop:memberInformation:activate:query:id')){
        const params = {
        id: this.MembershipDetails.id,
      };
      CardOpeningRecordList(params).then((res) => {
        if (res.data.code === 0) {
          this.MembertableData.splice(0);
          this.MembertableData.push(res.data.data);
          this.checkRecordTotals = res.data.data.total;
        } else {
          this.MembertableData.splice(0);
          this.checkRecordTotals = 0;
          this.$message({
            type: "error",
            message: "请求接口失败",
          });
        }
      });
      }else{
        this.$message({
            type: "error",
            message: "开卡记录分页数据查看无权限",
          });
      }
      
    },
    //点击查看弹出弹窗点击对应记录弹出对应表格
    onOption(num) {
      this.optionButton = num;
      if (num === 1) {
        this.isCheck = true;
        this.isBilling = false;
       
        this.CheckinRecordListed();
        this.isBalance = false;
      } else if (num === 2) {
        this.tablePage = 2;
        this.isCheck = false;
        this.isBalance = true;
        this.isBilling = false;
       
        this.BalanceRecordListed();
      } else if (num === 3) {
        this.tablePage = 3;
        this.isCheck = false;
        this.isBalance = false;
        this.isBilling = true;
       
        this.CardOpeningRecordListed();
      }
    },
     //会员消费关闭弹窗
     consumptionClose() {
      this.consumption = false;
      // this.MembershipCarded(this.memberId);
      // this.BalanceRecordListed()
      if(this.optionButton == 1) {
          this.CheckinRecordListed();//入住记录表的分页
        }else if(this.optionButton == 2) {
          this.BalanceRecordListed()//余额记录分页
        } else if(this.optionButton == 3) {
          this.CardOpeningRecordListed(); //开卡记录分页
        }
    },
    //确定消费事件
    consumptionsure() {
      // "currentBalance": 0,//当前余额
      // "memberId": 0,//会员ID
      if (this.memberpriceinfo.transferAmount <= 0) {
        this.$message({
          message: "消费金额不可小于等于零",
          type: "warning",
        });
        this.memberpriceinfo.transferAmount = 0;
        return false;
      }
      // if(this.memberpriceinfo.transferAmount>parseFloat(this.MembershipDetails.residualBalance)){
      // 	this.$message({
      // 		message: '消费金额不可大于余额',
      // 		type: 'warning'
      // 	});
      // 	this.memberpriceinfo.transferAmount=0;
      // 	return false;
      // }
      this.memberpriceinfo.currentBalance = parseFloat(
        this.MembershipDetails.residualBalance
      ); //当前余额
      this.memberpriceinfo.memberId = this.MembershipDetails.id; //当前余额
      console.log("提交数据", this.memberpriceinfo);
      memberConsumption({
        ...this.memberpriceinfo,
        name: localStorage.getItem("name"),
        userClassesId: localStorage.getItem("userClassesId"),
      })
        .then((res) => {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.consumption = false;
          // optionButton  1是入住记录  2是余额记录  3是开卡记录
          this.MembershipCarded(this.memberId);
         
        if(this.optionButton == 1) {
          this.CheckinRecordListed();//入住记录表的分页
        }else if(this.optionButton == 2) {
          this.BalanceRecordListed()//余额记录分页
        } else if(this.optionButton == 3) {
          this.CardOpeningRecordListed(); //开卡记录分页
        }
          // this.BalanceRecordListed()
          this.memberpriceinfo.transferAmount = null;
          this.memberpriceinfo.remark = null;
        })
       
    },
    consumptionopen() {
      this.consumption = true;
      this.memberpriceinfo.transferAmount = null;
      this.memberpriceinfo.remark = null
    },
    //消费金额改变
    consumechange() {
      if (this.memberpriceinfo.transferAmount < 0) {
        this.$message({
          message: "消费金额不可小于零",
          type: "warning",
        });
        this.memberpriceinfo.transferAmount = 0;
        return false;
      }
      // if(this.memberpriceinfo.transferAmount>parseFloat(this.MembershipDetails.residualBalance)){
      // 	this.$message({
      // 		message: '消费金额不可大于余额',
      // 		type: 'warning'
      // 	});
      // 	this.memberpriceinfo.transferAmount=0;
      // 	return false;
      // }
    },
     //点击确认和取消按钮和弹窗以外关闭弹窗
     consumer_offs(e) {
      if (e.target.className === "savePop") {
        this.isBlacklist = false;
      } 
    },
  }
};
</script>

<style lang="scss" scoped>
.el-table{
  overflow-x: hidden;
}
.MembershipDetails {
  width: 100%;
  height: 650px;
  background: #ffffff;
  border-radius: 5px;
  overflow-y: auto;
  .mainFrom {
    width: 100%;
    // background: linear-gradient(177deg, #e8fdfe 0%, #ffffff 100%);
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    
    box-sizing: border-box;

    p {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: space-between;
      padding-left: 10px;
      padding-right: 10px;
      box-sizing: border-box;
      align-items: center;
    }

    .userInfo_from {
      width: 100%;
      height: 180px;
      border: 1px solid #ededed;
      border-radius: 8px;

      .exhibiting {
        width: 100%;
        height: 60px;
        display: flex;

        .exhibiting_main {
          height: 60px;
          display: flex;
          align-items: center;
          padding-left: 20px;

          .Garden {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            margin-right: 10px;
            background-color: #5cb5b8;
          }

          img {
            width: 22px;
            height: 22px;
            margin-right: 10px;
            background-color: #dfe2e2;
          }
        }
      }

      .information {
        width: 100%;
       max-height: 170px;
        display: flex;

        .information_card {
          width: 30%;
          height: 130px;
          display: flex;
          flex-direction: column;
          align-items: start;

          p {
            display: flex;
            justify-content: start;
            width: 100%;

            .firstSpan {
              width: 50%;
              text-align: right;
            }
          }
        }
      }

      .optionButton {
        width: 100%;
        height: 50px;
        line-height: 50px;
        padding-left: 10px;
        margin-bottom: 8px;
        // background-color: bisque;
        .el-button {
          color: #999999;
          border-radius: 8px;
        }

        .el-button.active {
          background-color: #5cb5b8;
          color: #ffffff;
        }
      }

      .record_table {
        width: 100%;
        height: 350px;
        border-radius: 8px;
        border: 1px solid #ededed;
       ::v-deep .el-table{
          height: 250px;
          overflow-y: auto;
        }
        .table_header {
          width: 100%;
          height: 50px;

          ul {
            width: 100%;
            height: 50px;
            display: flex;
            padding-left: 20px;
            box-sizing: border-box;
            align-items: center;

            li {
              width: 20%;
            }

            li.active {
              color: #5cb5b8;
            }

            div.active {
              width: 8px;
              height: 8px;
              background-color: #5cb5b8;
              border-radius: 50%;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

}
.savePop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(133, 106, 106, 0.4);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;

    //阴影里的 删除盒子css
    .save_main {
      width: 500px;
      height: 180px;
      background-color: #fff;

      .save_size {
        width: 100%;
        height: 126px;
        // background: linear-gradient(177deg, #e8fdfe 0%, #ffffff 100%);
        border-radius: 5px;
        padding-top: 30px;
        box-sizing: border-box;

        p {
          height: 40px;
          padding-left: 45px;
          box-sizing: border-box;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;

          img {
            width: 15px;
            height: 15px;
          }
        }

        .size_floot {
          padding-left: 45px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #555555;
        }
      }

      .save_button {
        display: flex;
        justify-content: end;
        padding-right: 40px;
        box-sizing: border-box;

        .el-button {
          margin-left: 20px;
          width: 100px;
        }
      }
    }
  }
  .UpdateMember{
    ::v-deep .el-dialog__header {
    // background-color: #e8fdfe;
    margin-bottom: 0px;
  }

  ::v-deep .el-dialog__body {
    box-sizing: border-box;
    // background: linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%);
  }
  }
</style>