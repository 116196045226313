<template>
  <div class="dashboard">
    <el-input
        placeholder="搜索姓名和房号"
        @change="RoomStatusListrd(input2)"
        v-model.trim="input2"
        class="searchInput"
    >
      <i slot="suffix" class="el-icon-search"></i>
    </el-input>
    <el-row style="display: flex; height: 100%;">
      <el-col :span="24" class="dashboard_left">
        <!-- 左边的容器 -->
        <!-- 入住率和班结之类的卡片 -->
        <el-row class="right_card">
          <el-col
              class="colServe"
              :xs="8"
              :sm="6"
              :md="4"
              :lg="2"
              :xl="2"
              :style="{}"
          >
            <div class="card_serve" @click="shortcutButton(1)">
              <img src="../../assets/icons/1.png" alt=""/>
              <p>
                <span>班结</span>
              </p>
            </div>
          </el-col>
          <el-col class="colServe" :xs="8" :sm="6" :md="4" :lg="2" :xl="1.5">
            <div class="card_serve" @click="shortcutButton(2)">
              <img src="../../assets/icons/2.png" alt=""/>
              <p>
                <span>夜审</span>
              </p>
            </div>
          </el-col>
          <el-col class="colServe" :xs="8" :sm="6" :md="4" :lg="2" :xl="1.5">
            <!-- <el-tooltip placement="bottom"  effect="light">
  <div slot="content" style="width:80px;height: 40px">
        <p @click=" (e) => {ClickPlate(1,e)}" style="width: 100%;text-align: center;height: 20px;line-height:20px; cursor: pointer;">联房入住</p>
        <p  @click="(e) =>{ClickPlate(2,e)}" style="width: 100%;text-align: center;height: 20px;line-height:20px; cursor: pointer;">联房操作</p>
      </div>
  <div class="card_serve" @click=" (e) => {ClickPlate(0,e)} " >
                <img src="../../assets/icons/2.png" alt="">
                <p>
                  <span>联房</span>
                </p>
               
              </div>
</el-tooltip> -->
            <el-dropdown class="RoomItem" @command="handleCommand">
              <div
                  class="card_serve"
                  @click="
                  (e) => {
                    ClickPlate(0, e);
                  }
                "
              >
                <img src="../../assets/icons/2.png" alt=""/>
                <p>
                  <span>联房</span>
                </p>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="1">
                  <img src="../../assets/imgs/lianfangRoom.png" alt=""/>
                  联房入住
                </el-dropdown-item>
                <el-dropdown-item command="2">
                  <img src="../../assets/imgs/lianfang.png" alt=""/>
                  联房操作
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-col>
          <el-col class="colServe" :xs="8" :sm="6" :md="4" :lg="2" :xl="1.5">
            <div class="card_serve" @click="shortcutButton(3)">
              <img src="../../assets/icons/2.png" alt=""/>
              <p>
                <span>房卡</span>
              </p>
            </div>
          </el-col>
          <el-col class="colServe" :xs="8" :sm="6" :md="4" :lg="2" :xl="1.5">
            <div class="card_serve" @click="shortcutButton(4)">
              <img src="../../assets/icons/6.png" alt=""/>
              <p>
                <span>发票管理</span>
              </p>
            </div>
          </el-col>
          <el-col class="colServe" :xs="8" :sm="6" :md="4" :lg="2" :xl="1.5">
            <div class="card_serve" @click="shortcutButton(5)">
              <img src="../../assets/imgs/addAdvance.png" alt=""/>
              <p>
                <span>新增预定</span>
              </p>
            </div>
          </el-col>
          <el-col
              class="colServe"
              :xs="8"
              :sm="6"
              :md="4"
              :lg="2"
              :xl="1.5"
              v-if="false"
          >
            <div class="card_serve" @click="shortcutButton(6)">
              <img src="../../assets/icons/4.png" alt=""/>
              <p>
                <span>新增会员</span>
              </p>
            </div>
          </el-col>
          <el-col class="colServe" :xs="8" :sm="6" :md="4" :lg="2" :xl="1.5">
            <div class="card_serve" @click="shortcutButton(7)">
              <img src="../../assets/icons/5.png" alt=""/>
              <p>
                <span>批量清扫</span>
              </p>
            </div>
          </el-col>

          <!-- 状态的展示 -->
          <el-col class="colServe" :xs="22" :sm="16" :md="13" :lg="8" :xl="7.5">
            <!-- 这是圆和收益 -->
            <div
                class="circle_earnings"
                @mouseleave="handleMouseLeave"
                @mouseenter="handleMouseEnter"
            >
              <img src="../../assets/icons/椭圆 3.png" alt=""/>
              <!-- <div class="earnings">
                <span
                  >入住率:{{
                    Number(timeOnDuty.occupancyRate)
                      ? (parseFloat(timeOnDuty.occupancyRate) * 100).toFixed(2)
                      : "0"
                  }}%</span
                >

                <p>收益营算:{{ timeOnDuty.roomRate }}</p>
              </div>

              <div class="earningss">
                <p>
                  <span class="dailyPrice"
                    >平均房价:{{ timeOnDuty.averageRoomRate }}</span
                  >
                  <span>RP:{{ timeOnDuty.RP }}</span>
                </p>
                <p class="CheckRecord">
                  <span class="CheckRoom"
                    >今日入住:{{ timeOnDuty.liveIn }}间
                  </span>
                  <span>今日离店:{{ timeOnDuty.checkOut }}间</span>
                </p>
              </div> -->
              <div class="newearningss">
                <div class="newearningssitem">
                  <div>入住率:{{
                      Number(timeOnDuty.occupancyRate)
                          ? (parseFloat(timeOnDuty.occupancyRate) * 100).toFixed(2)
                          : "0"
                    }}%
                  </div>
                  <div>收益营算:{{ timeOnDuty.roomRate }}</div>
                </div>
                <div class="newearningssitem">
                  <div>平均房价:{{ timeOnDuty.averageRoomRate || 0 }}</div>
                  <div>今日入住:{{ timeOnDuty.liveIn || 0 }}间</div>
                </div>
                <div class="newearningssitem">
                  <div>RP:{{ timeOnDuty.RP || 0 }}</div>
                  <div>今日离店:{{ timeOnDuty.checkOut || 0 }}间</div>
                </div>
              </div>
              <!--  -->
              <div class="freeOfcharge" v-if="isMessageFree">
                <div class="iconTitle"></div>
                <p class="firstTitle">不含免费房</p>
                <div class="twoNav">
                  <div>入住率：{{ (parseFloat(timeOnDuty.occupancyRateNotFree) * 100).toFixed(2) + "%" }}</div>
                  <div class="shuline"></div>
                  <div>平均房价：{{ timeOnDuty.averageRoomRateNotFree }}</div>
                  <div class="shuline"></div>
                  <div>RP：{{ timeOnDuty.RPNotFree }}</div>
                </div>
                <!-- <el-row class="twoNav" type="flex" justify="space-between">
                  <el-col >
                    <p>
                      <span>入住率：</span>
                      <span>{{
                        (
                          parseFloat(timeOnDuty.occupancyRateNotFree) * 100
                        ).toFixed(2) + "%"
                      }}</span>
                    </p>
                  </el-col>
				  <el-col ><div class="shuline"></div></el-col>
                  <el-col >
                    <p>
                      <span>平均房价：</span>
                      <span>{{ timeOnDuty.averageRoomRateNotFree }}</span>
                    </p>
                  </el-col>
				   <el-col ><div class="shuline"></div></el-col>
                  <el-col >
                    <p>
                      <span>RP：</span> <span>{{ timeOnDuty.RPNotFree }}</span>
                    </p>
                  </el-col>
                </el-row> -->
                <div class="checkInRate"></div>
              </div>
            </div>
            <!-- 这是圆和收益 -->
          </el-col>
        </el-row>
        <!-- 入住率和班结之类的卡片 -->
        <!-- 刷新按钮 -->
        <el-row class="search_dashboard" onselectstart="return false">
          <!-- 房间状态 -->
          <div class="card_sort">
            <div class="card">
              <div
                  class="card_content"
                  @click="navchange('all')"
                  :style="{ color: roomcolor.all }"
              >
                <p>{{ roomstatus.all }}</p>
                <p>总数量</p>
              </div>
            </div>
            <div class="card">
              <div
                  class="card_content"
                  @click="navchange('liveIn')"
                  :style="{ color: roomcolor.liveIn }"
              >
                <p>{{ roomstatus.liveIn }}</p>
                <p>在店VD</p>
              </div>
            </div>
            <div class="card">
              <div
                  class="card_content"
                  @click="navchange('emptyDirty')"
                  :style="{ color: roomcolor.emptyDirty }"
              >
                <!-- :style="'background-color:' + roomcolor['emptyDirty'] + ';'"> -->
                <p>{{ roomstatus.emptyDirty }}</p>
                <p>空脏VO</p>
              </div>
            </div>
            <div class="card">
              <div
                  class="card_content"
                  @click="navchange('emptyClean')"
                  :style="{ color: roomcolor.emptyClean }"
              >
                <!-- :style="'background-color:' + roomcolor['emptyClean'] + ';'"> -->
                <p>{{ roomstatus.emptyClean }}</p>
                <p>空净VC</p>
              </div>
            </div>
            <div class="card">
              <div
                  class="card_content"
                  @click="navchange('liveInDirty')"
                  :style="{ color: roomcolor.liveInDirty }"
              >
                <!-- :style="'background-color:' + roomcolor['liveInDirty'] + ';'"> -->
                <p>{{ roomstatus.liveInDirty }}</p>
                <p>住脏OD</p>
              </div>
            </div>
            <div class="card">
              <div
                  class="card_content"
                  @click="navchange('liveInClean')"
                  :style="{ color: roomcolor.liveInClean }"
              >
                <!-- :style="'background-color:' + roomcolor['liveInClean'] + ';'"> -->
                <p>{{ roomstatus.liveInClean }}</p>
                <p>住净OC</p>
              </div>
            </div>
            <div class="card">
              <div
                  class="card_content"
                  @click="navchange('repair')"
                  :style="{ color: roomcolor.repair }"
              >
                <!-- :style="'background-color:' + roomcolor['repair'] + ';'"> -->
                <p>{{ roomstatus.repair }}</p>

                <p> 维修OO </p>
              </div>
            </div>
            <div class="card">
              <div
                  class="card_content"
                  @click="navchange('predeparture')"
                  :style="{ color: roomcolor.predeparture }"
              >
                <!-- :style="'background-color:' + roomcolor['predeparture'] + ';'"> -->
                <p>{{ roomstatus.predeparture }}</p>
                <p>预离ED</p>
              </div>
            </div>
            <div class="card">
              <div
                  class="card_content"
                  @click="navchange('arrival')"
                  :style="{ color: roomcolor.arrival }"
              >
                <!-- :style="'background-color:' + roomcolor['arrival'] + ';'"> -->
                <p>{{ roomstatus.arrival }}</p>
                <p>预抵AR</p>
              </div>
            </div>
            <div class="card">
              <div
                  class="card_content"
                  @click="navchange('lock')"
                  :style="{ color: roomcolor.lock }"
              >
                <!-- :style="'background-color:' + roomcolor['lock'] + ';'"> -->
                <p>{{ roomstatus.lock }}</p>
                <p>锁房OO</p>
              </div>
            </div>
            <div class="card">
              <div
                  class="card_content"
                  @click="navchange('hour')"
                  :style="{ color: roomcolor.hour }"
              >
                <!-- :style="'background-color:' + roomcolor['hour'] + ';'"> -->
                <p>{{ roomstatus.hour }}</p>
                <p>钟点房DU</p>
              </div>
            </div>
            <img
                src="../../assets/icons/HTSCIT_刷新.png"
                alt=""
                @click="refreshButton"
            />
          </div>
          <!-- 房间状态 -->
        </el-row>

        <!-- 刷新按钮 -->

        <!-- 房型的筛选和展示 -->
        <div class="screen_out" onselectstart="return false" ref="roomlist">
          <div class="screen">
            <div class="screen_item" ref="RoomHeight">
              <el-col :span="23" class="ROOMBOX">
                <el-row :gutter="20" class="roomBox_nav">
                  <el-col :span="0.5" style="">
                    <p class="roomStateSBox roombuttonBoxss">房型:</p></el-col
                  >
                  <el-col :span="22">
                    <ul style="" class="flex-wrap">
                      <!-- <li></li> -->
                      <li
                          v-if="
                          param.houseTypeId.length == 0 ||
                          param.houseTypeId.length == houselist.length
                        "
                          @click="lichange('houselist', 0, true)"
                          class="allScreen active"
                      >
                        全部
                      </li>

                      <li @click="lichange('houselist', 0, true)" class="allScreen" v-else>
                        全部
                      </li>
                      <!-- <ul class="roomCARDbOX"> -->
                      <li
                          v-for="(item, index) in houselist"
                          :key="item.id"
                          @click="lichange('houselist', index)"
                          :class="
                          param.houseTypeId.indexOf(item.id) !== -1
                            ? 'active'
                            : ''
                        "
                      >
                        {{ item.houseType }}
                      </li>
                      <!-- </ul> -->
                    </ul>
                  </el-col>
                </el-row>
              </el-col>

              <el-col :span="1">
                <div class="icon_isScreen" @click="soliceScreen">
                  <i
                      :class="
                      isScreen ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
                    "
                  >筛选</i
                  >
                </div>
              </el-col>
            </div>

            <div class="screen_item" ref="RoomHeight" v-if="isScreen">
              <el-col :span="24" class="ROOMBOX">
                <el-row :gutter="20" class="roomBox_nav">
                  <el-col :span="0.5" style="">
                    <p class="roomStateSBox">楼层：</p></el-col
                  >
                  <el-col :span="22">
                    <ul style="" class="flex-wrap">
                      <!-- <li></li> -->
                      <li
                          v-if="
                          param.floorId.length == floorlist.length ||
                          param.floorId.length == 0
                        "
                          @click="lichange('floorlist', 0, true)"
                          class="allScreen active"
                      >
                        全部
                      </li>

                      <li @click="lichange('floorlist', 0, true)" class="allScreen" v-else>
                        全部
                      </li>
                      <!-- <ul class="roomCARDbOX"> -->
                      <li
                          v-for="(item, index) in floorlist"
                          :key="item.id"
                          @click="lichange('floorlist', index)"
                          :class="
                          param.floorId.indexOf(item.id) !== -1 ? 'active' : ''
                        "
                      >
                        {{ item.floor_number }}
                      </li>
                      <!-- </ul> -->
                    </ul>
                  </el-col>
                </el-row>
              </el-col>
            </div>

            <div class="screen_item" ref="RoomHeight" v-if="isScreen">
              <el-col :span="24" class="ROOMBOX">
                <el-row :gutter="20" class="roomBox_nav">
                  <el-col :span="0.5" style="">
                    <p class="roomStateSBox">客类：</p></el-col
                  >
                  <el-col :span="22">
                    <ul style="" class="flex-wrap">
                      <!-- <li></li> -->
                      <li
                          class="allScreen active"
                          @click="lichange('userTypelist', 0, true)"
                          v-if="
                          param.clientList.length == userTypelist.length ||
                          param.clientList.length == 0
                        "
                      >
                        全部
                      </li>

                      <li
                          class="allScreen"
                          @click="lichange('userTypelist', 0, true)"
                          v-else
                      >
                        全部
                      </li>
                      <!-- <ul class="roomCARDbOX"> -->
                      <li
                          v-for="(item, index) in userTypelist"
                          :key="item.id"
                          @click="lichange('userTypelist', index)"
                          :class="
                          param.clientList.indexOf(item.value) !== -1 ? 'active' : ''
                        "
                      >
                        {{ item.name }}
                      </li>
                      <!-- </ul> -->
                    </ul>
                  </el-col>
                </el-row>
              </el-col>
            </div>

          </div>

          <!-- 卡片区域 -->
          <!-- :style="{'max-height': isScreen?'350px':'420px',}" -->
          <div
              class="card_fiche"
              @click="setCondition"
          >
            <!-- 点击右下角图标弹出的弹框 -->
            <!-- 1F -->
            <div
                class="card_one"
                v-for="(item, index) in roomlist"
                :key="item.id"
            >
              <p class="cell">{{ item.floor_number }}</p>
              <div class="house_type">
                <!-- 单个卡片的样式 -->
                <!--  -->
                <div
                    class="house_content"
                    v-for="(room, index) in item.list"
                    :key="room.id"
                    :style=" 'background-color:' + roomcolor[room.OrdeerStates?room.status.includes(2)?room.statusstr:'os': room.statusstr] + ';'"
                    @click="roompopbox(room)"
                >
                  <!--@click="(e) => { on_off(1, e) }" 左边区域 -->
                  <div class="conten_left">
                    <div class="supplement">
                      <!-- <span class="date_format text-write">{{ room.roomNumber }}</span>
                    <p class="text-write">{{room.nameGender}}</p> -->
                      <div class="div-left div-margin-bottom">
                        {{ room.roomNumber }}
                      </div>
                      <div class="div-left">{{ room.nameGender }}</div>
                    </div>
                    <div class="card_state">
                      <!-- style="background-color: red;"  -->
                      <div
                          class="sign"
                          v-if="room.status && room.status.indexOf(5) !== -1"
                      >
                        维
                      </div>
                      <div
                          class="sign"
                          v-if="room.status && room.status.indexOf(4) !== -1"
                      >
                        脏
                      </div>
                      <div
                          class="sign"
                          v-if="room.status && room.status.indexOf(6) !== -1"
                      >
                        锁
                      </div>
                      <div
                          class="sign"
                          v-if="room.status && room.status.indexOf(999) !== -1"
                      >
                        钟
                      </div>
                      <!-- OrdeerStates -->
                      <div class="sign" v-if="room.connectRoomId">联</div>
                      <div v-if="room.status.includes(2)?false: room.OrdeerStates" style="color: #fff">预定已排房</div>
                      <!-- <div class="sign" style="background-color: red;">维</div> -->
                    </div>
                    <p v-if="room.status.includes(8)">已清扫，待查房</p>
                  </div>
                  <!-- 右边区域 -->
                  <div class="conten_right">
                    <div class="supplement">
                      <div class="div-right div-margin-bottom">
                        {{ housenamelist[room.houseTypeId] }}
                      </div>
                      <div class="div-right">￥{{room.nameGender?(room.OrdeerStates?room.housePrice:room.roomRealityPrice ):room.housePrice }}</div>
                    </div>
                    <el-dropdown class="item" @command="handleCommand">
                      <img
                          @click.stop='yufang'
                          v-show="
                          room.status &&
                          !(
                            room.status.indexOf(5) !== -1 ||
                            room.status.indexOf(6) !== -1
                          )
                        "
                          src="../../assets/icons/更多 (2).png"
                          alt=""
                          class="iconImg"
                      />
                      <el-dropdown-menu slot="dropdown" @click.stop='yufang'>
                        <el-dropdown-item
                            v-if="!room.status.includes(4)"
                            :command="{ row: room, num: 4, state: '脏房' }"
                            @click="roomstatuschange(room, 4)"
                            style="cursor: pointer; margin-bottom: 5px"
                        >
                          <img src="../../assets/icons/清扫1 拷贝.png" alt=""/>
                          设为脏房
                        </el-dropdown-item>
                        <el-dropdown-item
                            v-show="room.status.includes(4)"
                            :command="{ row: room, num: 3, state: '净房' }"
                            @click="roomstatuschange(room, 3)"
                            style="cursor: pointer; margin-bottom: 5px"
                        >
                          <img src="../../assets/icons/清扫1 拷贝.png" alt=""/>
                          设为净房
                        </el-dropdown-item>
                        <el-dropdown-item
                            v-show="
                            !room.status.includes(2) && !room.status.includes(5)
                          "
                            :command="{ row: room, state: '维修' }"
                            @click="maintainClick(room)"
                            style="cursor: pointer; margin-bottom: 5px"
                        >
                          <img src="../../assets/icons/维修.png" alt=""/>
                          设为维修
                        </el-dropdown-item>
                        <el-dropdown-item
                            v-show="!room.status.includes(2)"
                            :command="{ row: room, state: '锁房' }"
                            @click="clickLock(room)"
                            style="cursor: pointer; margin-bottom: 5px"
                        >
                          <img src="../../assets/icons/锁.png" alt=""/>
                          {{ room.status.includes(6) ? "待解锁" : "设为锁房" }}
                        </el-dropdown-item>

                        <el-dropdown-item
                            style="cursor: pointer"
                            :command="{ row: room, state: '操作日志' }"
                            @click="clickOperator(room)"
                        >
                          <img src="../../assets/icons/日志.png" alt=""/>
                          操作日志
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </div>
                <!-- 单个卡片的样式 -->
              </div>
            </div>
          </div>

          <!-- 卡片区域 -->
        </div>
        <!-- 房型的筛选和展示 -->
      </el-col>
    </el-row>

    <!-- 订单信息弹窗 -->
    <el-dialog
        title="订单信息"
        :visible.sync="isOrders"
        :before-close="handleClose"
        width="1100px"
        :destroy-on-close="true"
    >
      <order-information
          ref="orderFromBox"
          @offInquiry="off_Order"
          :enter="isEnter"
      ></order-information>
    </el-dialog>
    <el-dialog
        :title="checkTitle"
        :close-on-click-modal="false"
        :visible.sync="isCheck"
        :before-close="handleClose"
        width="1000px"
        class="CheckBoxsss"
        :destroy-on-close="true"
    >
      <!-- 办理入住弹窗 -->
      <check-in @offCheck="offCheck" @changetitle='changetitle' ref="CHECKBOX"></check-in>
    </el-dialog>

    <!-- 操作日志的列表 -->
    <operator-table
        v-if="isOperator"
        ref="operatortable"
        @offOperator="offOperator"
    ></operator-table>
    <!-- 操作日志的列表 -->
    <!-- 设为维修的弹窗 -->
    <el-dialog
        title="维修房登记"
        :destroy-on-close="true"
        :visible.sync="isMaintain"
        class="RelevanceBox"
        center
        width="600px"
    >
      <maintain
			ref="maintenance"
          :roomInfo="roomInfo"
          @offMaintain="offMaintain"
      ></maintain>
    </el-dialog>
    <!-- 设为维修的弹窗 -->
    <!-- 设为锁房状态 -->
    <lock-sheet
        v-if="isLock"
        :roomInfo="roomInfo"
        @offSheet="offSheet"
    ></lock-sheet>
    <!-- 如果状态是锁房状态，就弹出一个是否解锁的弹窗 -->
    <div class="whetherLock" v-if="isWhetherLock">
      <div class="whetherLock_main">
        <div class="whetherLock_color">
          <div class="save_size">
            <p>
              <img src="../../assets/icons/警告 (1).png" alt=""/>
              8023锁房提醒
            </p>
            <span class="size_floot"
            >该房间锁定中，请解锁后重试，锁房原因：</span
            >
          </div>
          <div class="save_button">
            <el-button
                @click="areDelete"
                style="background-color: #5cb5b8; color: #fff"
                class="submitButton"
            >
              解锁
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 批量清扫弹窗 -->
    <el-dialog
        title="批量清扫"
        :visible.sync="dialogVisible"
        width="50%"
        :destroy-on-close="true"
        :before-close="handleClose"
        class="addMember"
    >
      <div class="title_Sweep">
        <span @click="SweepButton(1)" :class="{ active: isSweep == 1 }"
        >待清扫</span
        >
        <span v-if="Sweep_index == 1?false:true" @click="SweepButton(2)" :class="{ active: isSweep == 2 }"
        >待查房</span
        >
      </div>
      <div class="addMemberGrade" v-if="isSweep == 1">
        <div class="cardRoomBox">
          <el-col
              :span="5"
              style="margin-right: 10px"
              v-for="el in pigstyRoom"
              :key="el.id"
          >
            <div
                @click="radioCard(el)"
                :class="{
                change_cards: true,
                active:
                  RoomState == 1
                    ? true
                    : RoomState == 0
                    ? false
                    : isImg.includes(el.id),
              }"
            >
              <div class="change_Price">
                <div>{{ el.roomNumber }}</div>
                <div>
                  {{ el.echoMap.houseTypeId }}
                </div>
              </div>
              <div class="room_price">
                <img src="../../assets/icons/选择.png" alt=""/>
              </div>
            </div>
          </el-col>
          <el-row v-if="pigstyRoom.length == 0" class="noneCommodity">
            <span>暂无可清扫房间</span>
          </el-row>
        </div>

        <div class="buttonBox">
          <el-button @click="allroom(1)">全选</el-button>
          <el-button @click="allroom(2)">全不选</el-button>
          <el-button @click="SweepButton(3)">清扫</el-button>
        </div>
      </div>
      <div class="addMemberGrade" v-if="isSweep == 2">
        <div class="cardRoomBox">
          <el-col
              :span="5"
              style="margin-right: 10px"
              v-for="el in pigstyRoom"
              :key="el.id"
          >
            <div
                @click="radioCard(el)"
                :class="{
                change_cards: true,
                active:
                  RoomState == 1
                    ? true
                    : RoomState == 0
                    ? false
                    : isImg.includes(el.id),
              }"
            >
              <div class="change_Price">
                <div>{{ el.roomNumber }}</div>

                <div>
                  {{ el.echoMap.houseTypeId }}
                </div>
              </div>
              <div class="room_price">
                <img src="../../assets/icons/选择.png" alt=""/>
              </div>
              <p class="chaFang">待查房</p>
            </div>
          </el-col>
          <el-row v-if="pigstyRoom.length == 0" class="noneCommodity">
            <span>暂无可查房房间</span>
          </el-row>
        </div>

        <div class="buttonBox">
          <el-button @click="allroom(1)">全选</el-button>
          <el-button @click="allroom(2)">全不选</el-button>
          <el-button @click="ROUNDS(3)">查房清扫</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 批量清扫弹窗 -->
    <!--新增会员弹窗  -->
    <el-dialog
        title="新增会员"
        :visible.sync="isMemberFrom"
        width="800px"
        :destroy-on-close="true"
        :before-close="handleClose"
        class="MemberAdd"
    >
      <membership-card ref="MemBoxs" @offInquiry="MemberEmit"></membership-card>
    </el-dialog>
    <!--新增会员弹窗  -->
    <!--新增预定弹窗  -->
    <el-dialog
        title="订单信息"
        :destroy-on-close="true"
        :visible.sync="isMore"
        :before-close="offChecked"
        width="1100px"
        class="reserveBox"
    >
      <MoreFurther ref="morefurther" @offCheck="offChecked"></MoreFurther>
    </el-dialog>

    <!--新增预定弹窗  -->
    <!-- 夜审弹窗 -->

    <el-dialog
        title="夜审"
        :destroy-on-close="true"
        :visible.sync="isNightAuditor"
        width="70%"
        :before-close="handleClose"
        class="nightBoxss"
    >
      <nignt-auditor
          ref="auditorBox"
          @OffNightAuditor="OffNightAuditor"
      ></nignt-auditor>
    </el-dialog>
    <!-- 夜审弹窗 -->
    <!-- 发票管理 -->
    <!-- :before-close="handleClose" -->
    <el-dialog
        title="发票管理"
        :destroy-on-close="true"
        :visible.sync="isInvoice"
        width="1300px"
        @close="invoiceClose"
        class="addMember"
    >
      <invoice-management ref="ManageMentBox"></invoice-management>
    </el-dialog>
    <!-- 发票管理 -->
    <!-- 班结 -->
    <el-dialog
        title="班结缴款"
        :destroy-on-close="true"
        ref="Handrefs"
        :visible.sync="isTeam"
        center
        width="400px"
        :before-close="handleClose"
    >
      <final-statement
          ref="StatementBox"
          @FinalStatementEvent="FinalStatementEvent"
      ></final-statement>
    </el-dialog>
    <!-- 班结 -->

    <el-dialog
        title="维修房信息更改"
        :destroy-on-close="true"
        :visible.sync="isMaintainUpdate"
        class="MaintainBOX"
        center
        width="580px"
    >
      <maintain-updata
          ref="MaintainBox"
          :roomInfo="roomInfo"
          @offMaintainUpdata="offMaintainUpdata"
      ></maintain-updata>
    </el-dialog>

    <el-dialog
        title="联房入住"
        :destroy-on-close="true"
        :visible.sync="isRelevance"
        class="RelevanceBox"
        center
        width="1200px"
    >
      <relevance-check
          ref="relevanceRef"
          @RelevanBOXS="RelevanBOXS"
      ></relevance-check>
    </el-dialog>
    <el-dialog
        title="联房操作"
        :destroy-on-close="true"
        :visible.sync="RelevanceOperations"
        class="RelevanceBoxss"
        center
        width="1200px"
    >
      <relevance-operation
          ref="RelevanceOperationBox"
          @CancelBOX="CancelBOX"
      ></relevance-operation>
    </el-dialog>
    <el-dialog title="制房卡" :visible.sync="makecardshow" center width="75%">
      <make-card @makeclose="makecardclose" ref="makecard"></make-card>
    </el-dialog>
    <el-dialog
        title="制房卡"
        :visible.sync="newmakecardshow"
        center
        width="75%"
    >
      <new-mack-card
          @makeclose="makecardclose"
          ref="newmakecard"
      ></new-mack-card>
    </el-dialog>
  </div>
</template>

<script>
import orderInformation from "./components/orderInformation/orderInformation.vue";
import CheckIn from "./components/CheckIn.vue";
import OperatorTable from "./components/RoomStatus/OperatorTable.vue";
import Maintain from "./components/RoomStatus/Maintain.vue";
import LockSheet from "./components/RoomStatus/LockSheet.vue";
import MembershipCard from "_components/MembershipCard.vue";
import NigntAuditor from "./shortcutKey/nigntAuditor.vue";
import InvoiceManagement from "./shortcutKey/invoiceManagement.vue";
import FinalStatement from "./shortcutKey/FinalStatement.vue";
import MaintainUpdata from "./components/RoomStatus/MaintainUpdata.vue";
import MoreFurther from "../Reserve/bookPopUp/moreFurther.vue";
import RelevanceCheck from "./shortcutKey/RelevanceCheck.vue";
import {
  roomStatuscout,
  roomStatusView,
  houseTypeView,
  floorTypeView,
  roomsuoupdate,
} from "@/api/housingSystem";
import {gethotelinfo} from "_api/index"
import {RoomStatusList} from "_api/conditionOfHouse";
import {
  getHotelRoomRoundConfiguration,
  inspectionGetList,
  housingPriceList,
  inspectionCleans,
  dirtyInspection,
} from "_api/systemSetting";
import {HousePriceCode} from "_api/member";
import {
  GetPigstyRoom,
  GetBatchCleaning,
  SettlementPayment,
  userModeDate,
} from "_api/conditionOfHouse";
import {advanceOrderList} from "_api/advanceOrder";
import RelevanceOperation from "./shortcutKey/RelevanceOperation.vue";
import MakeCard from "./components/MakeCard.vue";
import NewMackCard from "./components/newMackCard.vue";

export default {
  name: "DashBoard",
  components: {
    orderInformation,
    CheckIn,
    OperatorTable,
    Maintain,
    LockSheet,
    MembershipCard,
    NigntAuditor,
    InvoiceManagement,
    FinalStatement,
    MaintainUpdata,
    MoreFurther,
    RelevanceCheck,
    RelevanceOperation,
    MakeCard,
    NewMackCard,
  },
  data() {
    return {
      checkTitle: null,
      isScreen: false, //点击筛选按钮显示或隐藏
      isOrders: false, //控制订单信息弹窗的显示隐藏
      isCondition: false, //控制点击卡片右下角图标显示或隐藏
      isCheck: false, //控制办理入住的弹窗显示或隐藏
      isOperator: false, //控制操作日志列表弹窗的显示隐藏
      isMaintain: false, //控制设置房间状态为维修弹窗的显示隐藏
      isLock: false, //控制设置房间状态为锁房状态弹窗的显示隐藏
      isWhetherLock: false, //当房间状态为锁房状态时点击会弹出是否解锁的弹窗
      isEnter: false,
      isPlate: false, //
      RelevanceBolean: false,
      isRelevance: false, //联房入住的开关
      RelevanceOperations: false, //联房操作的开关
      isSweep: 1, //待清扫1、待查房2
      isMore: false, //新增预定
      isImg: [],
      RoomState: -1,
      tableheight: 0,
      isTeam: false, //控制班结弹窗的显示隐藏
      isInvoice: false, //控制发票管理弹窗的显示隐藏
      isNightAuditor: false, //控制夜审弹窗的显示隐藏
      dialogVisible: false, //控制批量清扫弹窗的显示隐藏
      isMemberFrom: false, //控制新增会员弹窗的显示隐藏
      isMaintainUpdate: false, //控制维修房信息更改弹窗的显示隐藏
      conditionTop: 0,
      isMessageFree: false,
      activeName: "second",
      widthNum: "9px",
      conditionLeft: 0,
      tab_active: 1,
      input2: null,
      reserveArriveDate: null,
      //批量清扫的数据
      ruleForm: {},
      pigstyRoom: [], //所有脏房信息
      relevanTable: [], //所有的净房信息
      //右上角的用戶数据集合
      timeOnDuty: {},
      rules: {
        memberGradeName: [
          {required: true, message: "文本形式，5字以内", trigger: "blur"},
          {
            pattern: /^[\u4E00-\u9FA5]{1,6}$/,
            message: "文本形式，5字以内",
            trigger: "blur",
          },
        ],
      },
      roomstatus: {
        all: 0, //总房数
        repair: 0, //维修数
        liveInClean: 0, //住净数
        hour: 0, //钟点房数
        arrival: 0, //预抵数
        emptyDirty: 0, //空胜数
        liveInDirty: 0, //住胜数
        predeparture: 0, //预离数
        lock: 0, //锁数
        liveIn: 0, //在店数
        emptyClean: 0, //空净数
      },
      param: {
        clientList: [],
        codeName: undefined,
        floorId: [],
        houseTypeId: [],
        orgId: undefined,
        statusList: [],
      },
      houselist: [], //房型
      housenamelist: {},
      floorlist: [],
      userTypelist: [
        {name: "散户", value: 0},
        {name: "会员", value: 1},
        {name: "协议", value: 2},
      ],
      roomlist: [],
      RelevanceFrom: [], //没有处理过的所有房间信息
      roomcolor: {
        all: "#3d6ca3", //总房数
        repair: "#f2473b", //维修数
        liveInClean: "#5f9bef", //住净数
        hour: "#e596a9", //钟点房数
        arrival: "#5CB5B8", //预抵数
        emptyDirty: "#aeabab", //空脏数
        liveInDirty: "#f9a64d", //住脏数
        predeparture: "#b5a7ff", //预离数
        lock: "#5cb3b8", //锁数
        liveIn: "#5cb5b8", //在店数
        emptyClean: "#1eb45d", //空净数
        os: '#f59a23',//预定房间
      },
      roomsearch: {
        all: [], //总房数
        repair: ["5"], //维修数
        liveInClean: ["2_3"], //住净数
        hour: ["999"], //钟点房数
        arrival: ["997"], //预抵数
        emptyDirty: ["1_4"], //空脏数
        liveInDirty: ["2_4"], //住脏数
        predeparture: ["998"], //预离数
        lock: ["6"], //锁数
        liveIn: ["2"], //在店数
        emptyClean: ["1_3"], //空净数
      },
      roomInfo: {}, //当前操作信息
      orderFromData: [],//预定单数据
      time: null,
      screenWidths: null,
      searchFrom: this.$store.state.base.searchRulefrom,
      Sweep_index: null,
      connectRoomId: null, //联房id
      roomArrActive: localStorage.getItem("permission")
          ? JSON.parse(localStorage.getItem("permission"))
          : [], //功能权限数据
      makecardshow: false,
      newmakecardshow: false, //新制作房卡
    };
  },
  watch: {
    isScreen: {
      handler(newVal, oldval) {
        this.autoHeight();
        console.log(newVal);
      },
      immediate: true,
    },

  },
  created() {
    if (localStorage.getItem("ApplicationId") === "1") {
      this.getfloorlist();
      this.advanceOrderListed()
      this.getroomstatus();
      this.userModeDateed();
      this.gethousetypelist();
      this.getHouseLists();
    }
  },
  mounted() {
    window.addEventListener("resize", this.resizeNutton);
    this.resizeNutton()
  },
  methods: {
    //预订单分页：获取当天的预订单信息
    advanceOrderListed() {
      this.getNowDate()
      const params = {
        current: 1,
        size: 10000,
        model: {
          arriveDate: this.reserveArriveDate
        }
      }
      advanceOrderList(params).then(res => {
        //houseNumber房号
        if (res.data.code === 0) {

          this.orderFromData = res.data.data.records.filter(el => {
            return el.houseNumber && el.orderStatus == 0
          })

          //  forEach(item => {
          //     this.roomlist.forEach((el,index) => {
          //       el.list.forEach(it => {
          //         console.log(item);
          //         if(it.roomNumber == item.houseNumber) {
          //           it.OrdeerStates = true
          //         }else{
          //           it.OrdeerStates = false
          //         }
          //       })
          //       // if(item.houseNumber == )
          //     })
          //   })
        }
      })
    },
    //获取当前时间
    getNowDate() {
      var date1 = new Date();
      var sign2 = ":";
      var year = date1.getFullYear() // 年
      var month = date1.getMonth() + 1; // 月
      var day = date1.getDate(); // 日
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (day >= 0 && day <= 9) {
        day = "0" + day;
      }
      this.reserveArriveDate = year + "-" + month + "-" + day
    },
    //鼠标移入事件：左上角状态展示
    handleMouseEnter() {
      this.isMessageFree = true;
    },
    //鼠标移出事件：左上角状态展示
    handleMouseLeave() {
      this.isMessageFree = false;
    },
    //房价码分页列表查询储存信息
    getHouseLists() {
      const params = {
        current: 1,
        size: 1000000,
        model: {
          orgId: localStorage.getItem("orgid"),
          state: true,
        },
      };
      housingPriceList(params).then((res) => {
        if (res.data.code === 0) {
          this.$store.commit("base/SETHousePriceCode", res.data.data.records);
          // console.log(this.$store.state.base.HousePriceCode);
        }
      });
    },
    //房态卡片的下拉框
    handleCommand(obj) {
      console.log(obj);
      if (obj.state == "脏房" || obj.state == "净房") {
        this.roomstatuschange(obj.row, obj.num);
      } else if (obj.state == "维修") {
        this.maintainClick(obj.row);
      } else if (obj.state == "锁房") {
        this.clickLock(obj.row);
      } else if (obj.state == "操作日志") {
        this.clickOperator(obj.row);
      } else if (obj == "1") {
        this.ClickPlate(1);
      } else if (obj == "2") {
        this.ClickPlate(2);
      }
    },
    //设为脏房和净房 4是脏3是静
    roomstatuschange(room, status) {
      console.log(room.id);
      var that = this;
      var arr = [];
      if (room.status.includes(1)) {
        arr = [];
        var str = "1_" + status;
        var ruleForm = {
          id: room.id,
          remark: "",
          state: status,
        };
      } else if (room.status.includes(2)) {
        var ruleForm = {
          id: room.id,
          remark: "",
          state: status,
        };
      }
      console.log(room);

      var title = "此操作将会把当前房间设置为脏房";
      if (status == 3) {
        //设为净房时触发
        getHotelRoomRoundConfiguration({
          orgId: localStorage.getItem("orgid"),
        }).then((res) => {
          if (res.data.code === 0) {
            this.Sweep_index = res.data.data.state;
            if (this.Sweep_index == 0) {
              title = "此操作将会把当前房间设置为查房";
            } else if (this.Sweep_index == 1 && status == 3) {
              title = "此操作将会把当前房间设置为净房";
            }
            if (this.Sweep_index == 0) {
              this.$confirm(title + "，是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                  .then(() => {
                    roomsuoupdate({
                      id: room.id,
                      remark: "",
                      state: 8,
                    }).then((res) => {
                      if (res.data.code == 0) {
                        this.Sweep_index = 8;
                        that.$message({
                          type: "success",
                          message: "设置成功",
                        });
                        this.getfloorlist();
                        this.getroomstatus();
                      } else {
                        that.$message.error("设置失败");
                      }
                      this.getfloorlist();
                      this.getroomstatus();
                      this.Sweep_index = null;
                      return false;
                    });
                  })
                  .catch(() => {
                    this.$message({
                      type: "info",
                      message: "已取消操作",
                    });
                  });
            } else {
              this.$confirm(title + "，是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                  .then(() => {
                    roomsuoupdate(ruleForm).then((res) => {
                      if (res.data.code == 0) {
                        that.$message({
                          type: "success",
                          message: "设置成功",
                        });
                        this.getfloorlist();
                        this.getroomstatus();
                      } else {
                        that.$message.error("设置失败");
                        this.getfloorlist();
                        this.getroomstatus();
                      }
                      this.Sweep_index = null;
                      return false;
                    });
                  })
                  .catch(() => {
                    this.$message({
                      type: "info",
                      message: "已取消操作",
                    });
                  });
            }
          }
        });
      } else if (status == 4) {
        //设为脏房时触发
        this.$confirm(title + "，是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
            .then(() => {
              roomsuoupdate(ruleForm).then((res) => {
                if (res.data.code == 0) {
                  that.$message({
                    type: "success",
                    message: "设置成功",
                  });
                  this.getfloorlist();
                  this.getroomstatus();
                } else {
                  that.$message.error("设置失败");
                  this.getfloorlist();
                  this.getroomstatus();
                }
                this.Sweep_index = null;
                return false;
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消操作",
              });
            });
      }
    },
    //点击联房触发事件
    ClickPlate(num) {
      if (num == 1) {
        //联房入住
        if (this.RelevanceBolean) {
          this.isRelevance = true;
          this.$nextTick(() => {
            this.$refs.relevanceRef.houselist.splice(0);
            this.$refs.relevanceRef.relevanTable.splice(0);
            this.$refs.relevanceRef.houselist.push(...this.houselist);
            this.$refs.relevanceRef.relevanTable.push(...this.relevanTable);
            this.$refs.relevanceRef.createdHouseType();
            this.$refs.relevanceRef.getNowDate(new Date());
          });
        }
      } else if (num == 2) {
        //联房操作
        this.RelevanceOperations = true;
        this.$nextTick(() => {
          this.$refs.RelevanceOperationBox.orderFromLists();
          // this.$refs.RelevanceOperationBox.relevanTable = this.relevanTable
          // this.$refs.RelevanceOperationBox.createdHouseType()
        });
      }
    },
    //联房操作的自定义事件
    CancelBOX(row) {
      this.RelevanceOperations = false;
      if (row) {
        this.isOrders = true;
        this.getfloorlist();
        this.getroomstatus();
        this.$nextTick(() => {
          this.$refs.orderFromBox.orderFromLists({connectRoomId: row[1]});
          this.$refs.orderFromBox.roomId = row[0][0];
          // this.$refs.orderFromBox.ids = row[0].id
          this.$refs.orderFromBox.AccompanyingGuests.splice(0);
          this.$refs.orderFromBox.li_active = 0;
          this.$refs.orderFromBox.switchoverTabs(1);
          // this.$refs.orderFromBox.switchoverTabs(2)
          // this.$refs.orderFromBox.ruleForm.houseTypeName = row.houseTypeName
          // this.$refs.orderFromBox.EditdataStatisticsed()
        });
      } else {
        setTimeout(() => {
          this.getfloorlist();
          this.getroomstatus();
        }, 1000);
      }
    },
    //联房入住的自定义事件
    RelevanBOXS(row) {
      console.log(row);
      if (row[0] == 0) {
        this.isRelevance = false;
      } else {
        this.isRelevance = false;
        this.isOrders = true;
        this.$nextTick(() => {
          this.$refs.orderFromBox.orderFromLists({
            connectRoomId: row.connectRoomId,
          });
          this.$refs.orderFromBox.roomId = row.roomId;
          this.$refs.orderFromBox.AccompanyingGuests.splice(0);
          this.$refs.orderFromBox.li_active = 0;
          this.$refs.orderFromBox.switchoverTabs(1);
          this.$refs.orderFromBox.ruleForm.houseTypeName = row.houseTypeName;
          // this.$refs.orderFromBox.EditdataStatisticsed()
        });

        console.log(row);
      }
    },
    //房态列表/根据姓名和房号搜素
    RoomStatusListrd(name) {
      const params = {
        codeName: name,
        orgId: localStorage.getItem("orgid"),
      };
      RoomStatusList(params).then((res) => {
        if (res.data.code === 0) {
          if (res.data.data.length != 0) {
            this.$message({
              type: "success",
              message: "搜索成功",
            });
            var that = this;
            that.roomlist.splice(0);
            var arr = [];
            this.floorlist.forEach(function (value, index) {
              that.roomlist.push(value);
              that.roomlist[index]["list"] = [];
              arr.push(value.id);
            });

            that.roomlist.forEach(function (item) {
              res.data.data.forEach(function (value) {
                if (item.id == value.floorId) {
                  value.statusstr = that.roomstatuschuli(value.status);
                  console.log(value.statusstr);

                  item.list.push(value);
                }
              });
            });
            that.roomlist = that.roomlist.filter((el, index) => {
              return el.list.length != 0;
            });
            that.roomlist.forEach((el) => {
              el.list.forEach((row) => {
                if (row.statusstr == "") {
                  if (row.status.includes(8)) {
                    row.statusstr = "emptyDirty";
                  }
                }
              });
            });
            console.log();
          } else {
            this.$message({
              type: "success",
              message: "没有匹配的数据",
            });
            this.roomlist.splice(0);
          }
        }
      });
    },

    //获取酒店查房配置
    getHotelRoomRoundConfigurationed() {
      const params = {
        orgId: localStorage.getItem("orgid"),
      };
      getHotelRoomRoundConfiguration(params).then((res) => {
        if (res.data.code === 0) {
          this.Sweep_index = res.data.data.state;
          if (res.data.data.state == 1) {
            if (this.RoomState === 1) {
              let arr = [];
              this.pigstyRoom.forEach((el) => {
                console.log(el);
                arr.push(el.id);
              });
              this.GetBatchCleaninged(arr);
            } else if (res.data.data.state === 0) {
              this.GetBatchCleaninged();
            } else {
              //多选
              this.GetBatchCleaninged(this.isImg);
            }
          } else if (this.Sweep_index === 0) {
            this.$message({
              type: "success",
              message: "清扫房间请前往待查房页转净房",
            });
            if (this.RoomState === 1) {
              //全选
              let arr = [];
              this.pigstyRoom.forEach((el) => {
                console.log(el);
                arr.push(el.id);
              });
              this.dirtyInspectioned(arr);
            } else {
              //多选

              this.dirtyInspectioned(this.isImg);
            }
          }
        }
      });
    },
    //初始化调用下判断要不要查房
    getHotelRoomRoundConfigurations() {
      const params = {
        orgId: localStorage.getItem("orgid"),
      };
      getHotelRoomRoundConfiguration(params).then((res) => {
        if (res.data.code === 0) {
          this.Sweep_index = res.data.data.state;
        }
      });
    },
    //改变房间状态
    dirtyInspectioned(ids) {
      const params = {
        ids: ids,
      };
      dirtyInspection(params).then((res) => {
        if (res.data.code === 0) {
          this.SweepButton(2);
          this.isImg.splice(0);
          this.Sweep_index = null;
        }
      });
    },

    //切换清扫弹窗的选项卡
    SweepButton(num) {
      if (num == 1) {
        //待清扫页面

        this.isSweep = 1;
        this.GetPigstyRooms();
        getHotelRoomRoundConfiguration({
          orgId: localStorage.getItem("orgid"),
        }).then((res) => {
          if (res.data.code === 0) {
            this.Sweep_index = res.data.data.state;
            if (res.data.data.state == 1) {
              if (this.RoomState === 1) {
                let arr = [];
                this.pigstyRoom.forEach((el) => {
                  console.log(el);
                  arr.push(el.id);
                });
                this.GetBatchCleaninged(arr);
              } else if (res.data.data.state === 0) {
                this.GetBatchCleaninged();
              } else {
                //多选
                this.GetBatchCleaninged(this.isImg);
              }
            } else if (res.data.data.state === 0) {
            }
          }
        });
      } else if (num == 2) {
        //待查房
        if (this.Sweep_index == 1) {
          this.$message({
            type: 'warning',
            message: "查房功能已关闭，如需打开请前往系统页打开",
          });
        } else {
          this.isSweep = 2;
          this.inspectionGetListed();
        }
      } else if (num == 3) {
        //批量清扫
        if (this.pigstyRoom.length == 0) {
          // this.$message({
          //   type: "error",
          //   message: "暂无可清扫房间",
          // });
        } else {
          if (this.RoomState === 1) {
            this.isImg.splice(0);
            this.pigstyRoom.forEach((el) => {
              console.log(el);
              this.isImg.push(el.id);
            });
          } else if (this.RoomState == 0) {
            this.isImg.splice(0);
          }
          if (this.isImg.length != 0) {
            this.getHotelRoomRoundConfigurationed();
          } else {
            //   this.$message({
            //   type: "error",
            //   message: "请选择要清扫房间",
            // });
            //   this.dialogVisible = false;
            // this.isImg.splice(0);
            // this.Sweep_index = null;
            // this.RoomState = 0;
            // this.getfloorlist();
            // this.getroomstatus();
            // this.userModeDateed();
            // this.gethousetypelist();
          }
        }
      }
    },
    ROUNDS() {
      if (this.RoomState === 1) {
        let arr = [];
        this.pigstyRoom.forEach((el) => {
          console.log(el);
          arr.push(el.id);
        });
        this.inspectionCleansed(arr);
      } else if (this.RoomState === 0) {
        this.inspectionCleansed();
      } else {
        //多选
        if (this.isImg.length != 0) {
          this.inspectionCleansed(this.isImg);
        } else {
          this.$message({
            type: "error",
            message: "请选择要清扫的房间",
          });
          // this.dialogVisible = false;
          // this.isImg.splice(0);
          // this.Sweep_index = null;
          // this.RoomState = 0;
          // this.getfloorlist();
          // this.getroomstatus();
          // this.userModeDateed();
          // this.gethousetypelist();
        }
      }
    },
    //获取待查房信息
    inspectionGetListed() {
      inspectionGetList({orgId: localStorage.getItem("orgid")}).then(
          (res) => {
            if (res.data.code === 0) {
              this.pigstyRoom.splice(0);
              this.pigstyRoom = res.data.data;
            }
          }
      );
    },

    //待查房批量清扫
    inspectionCleansed(ids = []) {
      const params = {
        ids: ids,
      };
      inspectionCleans(params).then((res) => {
        if (res.data.code === 0) {
          this.isImg.splice(0);
          this.RoomState = 0;
          this.isSweep = 1;
          this.dialogVisible = false;
          this.getfloorlist();
          this.getroomstatus();
        }
      });
    },
    MemberEmit() {
      this.getfloorlist();
      this.isMemberFrom = false;
      this.getroomstatus();
      this.userModeDateed();
      this.gethousetypelist();
    },
    offChecked() {
      this.isMore = false;
      this.getfloorlist();
      this.getroomstatus();
    },
    clearTime() {
      this.timer = setInterval(() => {
        this.$router.go(0);
        this.clearTime();
      }, 500);
      clearInterval(this.timer);
      this.timer = null;
    },

    refreshButton() {
      //   this.getroomstatus();
      // this.userModeDateed();
      // this.gethousetypelist();
      // this.getfloorlist();
      // this.getroomview();

      // 刷新2023-10-12之前（下面）
      // this.param.houseTypeId.splice(0);
      // this.param.clientList.splice(0);
      // this.param.floorId.splice(0);
      // this.param.statusList = this.roomsearch["all"];
      // this.getroomview();
      //2023-10-12之后
      location.reload();
    },
    // //点击卡片选中对应房型下的房号
    radioCard(el) {
      this.RoomState = -1;
      if (!this.isImg.includes(el.id)) {
        this.isImg.push(el.id);
      } else {
        let index = this.isImg.indexOf(el.id);
        this.isImg.splice(index, 1);
      }
    },

    //批量清扫里的全选和不全选按钮
    allroom(num) {
      if (num === 1) {
        //全选
        this.RoomState = 1;
      } else {
        //全不选
        this.RoomState = 0;
      }
    },
    //房价码查看接口
    HousePriceCodeed() {
      HousePriceCode().then((res) => {
        if (res.data.code === 0) {
          this.HousePriceCodeFrom = res.data.data;
        }
      });
    },
    //维修房信息更改的自定义事件
    offMaintainUpdata(num) {
      this.isMaintainUpdate = false;
      this.getfloorlist();
      this.getroomstatus();
      this.userModeDateed();
      this.gethousetypelist();
    },
    navchange(str) {
      this.param.statusList = this.roomsearch[str];
      this.getroomview();
    },
    userModeDateed() {
      userModeDate({orgId: localStorage.getItem("orgid")}).then((res) => {
        if (res.data.code === 0) {
          this.timeOnDuty = res.data.data;
          console.log(this.timeOnDuty);
        }
      });
    },
    //更具传过来的数字判断触发的是哪个右侧的快捷键
    shortcutButton(num) {
      if (num === 1) {
        //班结
        let currentUseId = localStorage.getItem("currentUseId");
        let useId = localStorage.getItem("userId");
        if (useId == currentUseId) {
          this.isTeam = true;
          this.$nextTick(() => {
            this.$refs.StatementBox.settlementPaymentAlled();
            this.$refs.StatementBox.getStandbyMoneyed();
          });
        } else {
          this.$message({
            type: "error",
            message: "该用户不是当班人员",
          });
        }
      } else if (num === 2) {
        //夜审NightAuditorListed
        this.isNightAuditor = true;
        this.$nextTick(() => {
          this.$refs.auditorBox.NightAuditorListed();
        });
      } else if (num === 3) {
        //房卡
        gethotelinfo({id: localStorage.getItem("orgid")}).then((res) => {
          console.log('酒店数据', res);
          if (res.data.code == 0) {
            if (res.data.data.brandModel.indexOf('BTRW-49')!=-1) {
              //新制作房卡
              this.newmakecardshow = true;
              this.$nextTick(() => {
                this.$refs.newmakecard.RoomStatusListed();
				if(res.data.data.brandModel.indexOf('BTRW-49-A')!=-1){
					this.$refs.newmakecard.brandModel=false;
				}
                this.$refs.newmakecard.gethotel();
              });
            } else {
              //老制卡
              this.makecardshow = true;
              this.$nextTick(() => {
                this.$refs.makecard.RoomStatusListed();
                this.$refs.makecard.gethotel();
              });
            }
          }
        });
      } else if (num === 4) {
        //发票管理
        this.isInvoice = true;
        this.$nextTick(() => {
          this.$store.commit("user/SetOrderId", {});
          this.$refs.ManageMentBox.orderId = undefined;
          // this.$refs.ManageMentBox.Asideinvoice();
          this.$refs.ManageMentBox.homEnter();
        });
      } else if (num === 5) {
        //新增预定
        this.isMore = true;
        var that = this;
        that.$nextTick(() => {
          that.$refs.morefurther.todaytime();
        });
      } else if (num === 6) {
        //新增会员
        this.isMemberFrom = true;
        this.$nextTick(() => {
          this.$refs.MemBoxs.createdBox();
          this.$refs.MemBoxs.ruleForm = {
            activateCost: "", //开卡金额number
            memberGradeId: "", //会员等级id integer(int64)
            memberGradeName: "", //会员名称
            activateId: "", //开卡记录表idinteger(int64)
            activateTime: "", //开卡日期string(date-time)
            address: "", //住址
            creditCardAmount: "", //开卡金额number
            // customerId: '1',//客户id
            gender: null, //性别
            idCard: null, //身份证
            isGift: "", //是否赠予 0是 1否
            mobile: null, //手机号
            name: null, //用户姓名
            paymentMethodId: "", //收款方式integer(int64)
            remark: "", //备注
            state: true,
          };
          this.$refs.MemBoxs.addNumss = 1;
        });
      } else if (num === 7) {
        //批量清扫
        this.dialogVisible = true;
        this.isSweep = 1;
        this.HousePriceCodeed();
        this.GetPigstyRooms();
        this.getHotelRoomRoundConfigurations();
        // this.GetBatchCleaninged();
      }
    },
    //夜审的自定义事件
    OffNightAuditor(row) {
      this.isNightAuditor = false;
      this.getfloorlist();
      this.getroomstatus();
      if (row) {
        this.isOrders = true;
        this.$nextTick(() => {
          this.$refs.orderFromBox.orderFromLists({
            roomId: row.roomId,
            id: row.id,
            orderState: 0,
          });
          this.$refs.orderFromBox.roomId = row.roomId;
          this.$refs.orderFromBox.AccompanyingGuests.splice(0);
          this.$refs.orderFromBox.li_active = 0;
          this.$refs.orderFromBox.switchoverTabs(1);
        });
      }
    },
    //班结的自定义事件
    FinalStatementEvent(num) {
      this.isTeam = false;
      if (num[0] == "班结缴款") {
        this.logOut();
      }
    },

    //退出登录
    logOut() {
      localStorage.removeItem("token");
      localStorage.removeItem("orgid");
      localStorage.removeItem("TenantId");
      localStorage.removeItem("ApplicationId");
      localStorage.removeItem("DynamicPath");
      this.$router.go(0);
    },
    lichange(str, index, all) {
      console.log(str);
      if (str == "houselist") {
        if (all) {
          this.param.houseTypeId.splice(0);
        } else {
          if (this.param.houseTypeId.indexOf(this.houselist[index].id) !== -1) {
            this.param.houseTypeId.splice(
                this.param.houseTypeId.indexOf(this.houselist[index].id),
                1
            );
          } else {
            this.param.houseTypeId.push(this.houselist[index].id);
          }
        }
      } else if (str == "floorlist") {
        if (all) {
          this.param.floorId.splice(0);
        } else {
          if (this.param.floorId.indexOf(this.floorlist[index].id) !== -1) {
            this.param.floorId.splice(
                this.param.floorId.indexOf(this.floorlist[index].id),
                1
            );
          } else {
            this.param.floorId.push(this.floorlist[index].id);
          }
        }
      } else if (str == "userTypelist") {
        if (all) {
          this.param.clientList.splice(0);
        } else {
          if (
              this.param.clientList.indexOf(this.userTypelist[index].value) !== -1
          ) {
            this.param.clientList.splice(
                this.param.clientList.indexOf(this.userTypelist[index].value),
                1
            );
          } else {
            this.param.clientList.push(this.userTypelist[index].value);
          }
          console.log('用户选择', this.param.clientList)
          console.log('用户选择', this.userTypelist)
        }
      }
      this.getroomview();
    },
    //班结缴款
    SettlementPaymented() {
      SettlementPayment({
        orgId: localStorage.getItem("orgid"),
      }).then((res) => {
        if (res.data.code === 0) {
          this.isTeam = false;
        } else {
          this.$message(res.data.errorMsg);
        }
      });
    },
    //批量清扫的接口
    GetBatchCleaninged(ids = []) {
      const params = {
        ids: ids,
      };
      GetBatchCleaning(params).then((res) => {
        if (res.data.code === 0) {
          this.dialogVisible = false;
          this.isImg.splice(0);
          this.Sweep_index = null;
          this.RoomState = 0;
          this.getfloorlist();
          this.getroomstatus();
          this.userModeDateed();
          this.gethousetypelist();
        }
      });
    },
    //获取所有脏房信息接口
    GetPigstyRooms() {
      const params = {
        orgId: localStorage.getItem("orgid"),
      };
      GetPigstyRoom(params).then((res) => {
        if (res.data.code === 0) {
          this.pigstyRoom = res.data.data;
        } else {
          this.$message(res.data.errorMsg);
        }
      });
    },
    //获取房态类型数量
    getroomstatus() {
      roomStatuscout({
        orgId: localStorage.getItem("orgid"),
      }).then((res) => {
        if (res.data.code == 0) {
          this.roomstatus = res.data.data;
          console.log(res.data.data);
        }
      });
    },
    //获取房态列表数据
    getroomview() {
      this.param.orgId = localStorage.getItem("orgid");
      var that = this;
      roomStatusView(this.param).then((res) => {
        console.log("请求数据", res);
        if (res.data.code == 0) {

          that.RelevanceBolean = true;
          that.RelevanceFrom.splice(0);
          that.RelevanceFrom = res.data.data;
          that.roomlist.splice(0);
          that.relevanTable.splice(0);
          res.data.data.forEach((el) => {
            if (el.status[0] == 1 && el.status.includes(3)) {
              that.relevanTable.push(el);
            }
          });
          var arr = [];

          this.floorlist.forEach(function (value, index) {

            that.roomlist.push(value);
            that.roomlist[index]["list"] = [];
            arr.push(value.id);
          });

          that.roomlist.forEach(function (item) {
            res.data.data.forEach(function (value) {
              if (item.id == value.floorId) {
                value.statusstr = that.roomstatuschuli(value.status);
                console.log(value.statusstr);

                item.list.push(value);
              }
            });
          });
          this.roomlist = that.roomlist.filter((el, index) => {
            return el.list.length != 0;
          });
          //  forEach(item => {
          //     this.roomlist.forEach((el,index) => {
          //       el.list.forEach(it => {
          //         console.log(item);
          //         if(it.roomNumber == item.houseNumber) {
          //           it.OrdeerStates = true
          //         }else{
          //           it.OrdeerStates = false
          //         }
          //       })
          //       // if(item.houseNumber == )
          //     })
          //   })
		  console.log('对比数据',this.orderFromData)
          this.orderFromData.forEach(el => {
            that.roomlist.forEach(it => {
              it.list.forEach(item => {
				  if(el.orderStatus==0){
					if (el.houseNumber.indexOf(',')) {
					  let arr = el.houseNumber.split(',')
					  let brr = el.checkInNumber.split(',')
					  arr.forEach(iv => {
						if (iv == item.roomNumber&&brr.indexOf(item.roomNumber)==-1) {
						  item.OrdeerStates = true
						  if(!item.nameGender){
							item.nameGender = el.customerName  
						  }
						}
					  })
					} else {
					  if (item.roomNumber == el.houseNumber) {
						item.OrdeerStates = true
						if(!item.nameGender){
							item.nameGender = el.customerName
						}
					  }
					}  
				  }
              })
            })

          })
          // console.log("房间", res.data.data);
          // console.log("房间数据", that.roomlist);
          // console.log("房间数据", JSON.stringify(that.roomlist) );
          that.roomlist.forEach((el) => {
            el.list.forEach((row) => {
              if (row.statusstr == "") {
                if (row.status.includes(8)) {
                  row.statusstr = "emptyDirty";
                }
              }
            });
          });
          this.input2 = null;

        }
      });
    },
    iisArray(arr) {
      return Object.prototype.toString.call(arr) === "[object Array]";
    },
    roomstatuschuli(arr) {
      // ROOM_EMPTY( code;"1"，desc: "空"),
      // ROOM_LIVE_IN( code:"2"，desc:"在住"),
      // ROOM_CLEAN( code;"3"，desc:"干净"),desc:"脏"),
      // ROOM_DIRTY( code:"4",ROOM_REPAIR( coder"5"，desc;"维修"),
      // ROOM_LOCK( code:"6"，desc:"锁房"),
      // ROOM_UNLOCK( code:"7"，desc:"解锁"),
      //
      var str = "";
      if (!this.iisArray(arr)) {
        str = "all";
      }
      if (arr.indexOf(2) != -1) {
        if (arr.indexOf(4) != -1) {
          str = "liveInDirty";
        } else if (arr.indexOf(3) != -1) {
          str = "liveInClean";
        } else {
          str = "liveIn";
        }
      }
      if (arr.indexOf(1) != -1) {
        if (arr.indexOf(3) != -1) {
          str = "emptyClean";
        }
        if (arr.indexOf(4) != -1) {
          str = "emptyDirty";
        }
      }
      if (arr.indexOf(999) != -1) {
        str = "hour";
      }
      if (arr.indexOf(998) != -1) {
        str = "predeparture";
      }
      if (arr.indexOf(997) != -1) {
        str = "arrival";
      }
      if (arr.indexOf(6) != -1) {
        str = "lock";
      }
      if (arr.indexOf(5) != -1) {
        str = "repair";
      }
      return str;
    },
    //获取房态类型
    gethousetypelist() {
      var that = this;
      houseTypeView().then((res) => {
        if (res.data.code == 0) {
          this.houselist = res.data.data;
          this.housenamelist = {};
          this.houselist.forEach(function (value) {
            that.housenamelist[value.id] = value.houseType;
          });
          // console.log("房型", this.houselist);
          // console.log('房型对象',this.housenamelist)
        }
      });
    },
    //获取楼层
    getfloorlist() {
      floorTypeView().then((res) => {
        console.log("楼层", res);
        if (res.data.code == 0) {
          this.floorlist = res.data.data;

          this.getroomview();
        }
      });
    },
    //解锁房间
    areDelete() {
      this.$emit("whetherDelete", 1);
      this.getfloorlist();
      this.getroomstatus();
    },
    //点击下拉弹框中的设置为锁房的弹窗的自定义事件
    offSheet(e) {
      if (!e[1]) {
        //确认
        this.isLock = false;
      } else {
        //图标
        this.isLock = false;
      }
      setTimeout(() => {
        this.getfloorlist();
        this.getroomstatus();
        this.userModeDateed();
        this.gethousetypelist();
      }, 1000);
    },
    //点击下拉弹框中的设置为锁房弹出弹窗的事件
    clickLock(item) {
      this.isLock = true;
      this.roomInfo = item;
    },
    //点击下拉弹框中的设置为维修的弹窗的自定义事件
    offMaintain(e) {
      if (e[1] === "确认") {
        //确认
        this.isMaintain = false;
      } else {
        //图标
        this.isMaintain = false;
      }
      this.getfloorlist();
      this.getroomstatus();
      this.userModeDateed();
      this.gethousetypelist();
    },
    //点击下拉弹框中的设置为维修触发事件
    maintainClick(item) {
      console.log(item);
      this.isMaintain = true;
      this.roomInfo = item;
	  this.$nextTick(() => {
		  this.$refs.maintenance.datehandle();
	  });
	  
    },
    //点击卡片右下角弹窗的操作日志
    clickOperator(item) {
      console.log(item);
      this.isOperator = true;
      this.$nextTick(() => {
        this.$refs.operatortable.ids = item.id;
        this.$refs.operatortable.roomNumber = item.roomNumber;
        this.$refs.operatortable.gettableid(this.ruleForm.roomId);
        this.$refs.operatortable.getlist();
      });
      this.roomInfo = item;
    },
    //操作日志表格的自定事件
    offOperator() {
      this.isOperator = false;
    },
    // // //点击卡片激活办理入住弹窗
    // CheckOf_off(e) {
    //   if (e.target.nodeName !== "IMG") {
    //     this.isCheck = true;
    //     this.isCondition = false;
    //   }
    // },
    //办理入住弹窗的自定义事件
    offCheck(e) {
      console.log(e);
      //当传过来的第二个参数为1时，就是确定办理入住,然后弹出入账弹窗
      // 打开订单信息弹窗
      if (e[1] === 1) {
        this.isCheck = e[0];
        this.isOrders = true;
        this.$nextTick(() => {
          this.$refs.orderFromBox.orderFromLists({
            roomId: e[2].roomId,
            orderState: 1,
          });
          this.$refs.orderFromBox.roomId = e[2].roomId;
          this.$refs.orderFromBox.AccompanyingGuests.splice(0);
          this.$refs.orderFromBox.li_active = 0;
          this.$refs.orderFromBox.switchoverTabs(1);
          this.$refs.orderFromBox.ruleForm.houseTypeName = e[2].houseTypeName
          // this.$refs.orderFromBox.EditdataStatisticsed()
        });
        // this.getfloorlist();
        // this.getroomstatus();
        // this.userModeDateed();
        // this.gethousetypelist();
      } else {
        this.isCheck = e[0];
        this.getfloorlist();
        this.getroomstatus();
        this.userModeDateed();
        this.gethousetypelist();
      }
    },
    //点击卡片右下角图标触发弹窗设置状态
    setCondition(e) {
      this.isCondition = false;
      console.log(e.target.nodeName);
      if (e.target.nodeName === "IMG") {
        console.log(e.clientX, e.clientY);
        this.conditionTop = e.clientY + 20 + "px";
        this.conditionLeft = e.clientX - 50 + "px";
        this.isCondition = !this.isCondition;
        console.log(this.conditionTop, this.conditionLeft);
      }
    },
    soliceScreen() {
      this.isScreen = !this.isScreen;
    },

    //点击卡片触发事件
    roompopbox(room) {
      this.$store.commit("base/SETROOM_DATA", room);
      this.$store.commit("base/FINANCE_DATA", room);
      console.log(room);
      if (!room.status.includes(2) && room.OrdeerStates) {
		if (room.status.includes(5)) {
		  this.isMaintainUpdate = true;
		  // this.roomInfo=room;
		  this.$nextTick(() => {
		    console.log(room);
		    this.$refs.MaintainBox.ids = room.maintenanceRoomRegistrationId;
		    this.$refs.MaintainBox.MonomerRoomed(room);
		  });
		  //         this.$nextTick(() => {
		  //  // this.refs.MaintainBox.
		  //         })
		  return false;
		} else if (room.status.includes(6)) {
		  this.clickLock(room);
		}else{
			this.$message({
			  type: 'warning',
			  message: '当前房间已预定，并今日抵店'
			})
		}
        return false
      }
      this.roomInfo = room;
      if (room.status.includes(5)) {
        this.isMaintainUpdate = true;
        // this.roomInfo=room;
        this.$nextTick(() => {
          console.log(room);
          this.$refs.MaintainBox.ids = room.maintenanceRoomRegistrationId;
          this.$refs.MaintainBox.MonomerRoomed(room);
        });
        //         this.$nextTick(() => {
        //  // this.refs.MaintainBox.
        //         })
        return false;
      } else if (room.status.includes(6)) {
        this.clickLock(room);
      } else if (room.status.includes(8)) {
        //查房的弹窗
        var title = "此操作将会把当前房间设置为净房";
        this.$confirm(title + "，是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
            .then(() => {
              let arr = [];
              arr.push(room.id);
              this.inspectionCleansed(arr);
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消操作",
              });
            });
      } else if (room.status.includes(1) && room.status.includes(3)) {
        if (!room.status.includes(6)) {
          this.isCheck = true;
          this.$nextTick(() => {
            console.log("房间信息", room);
            this.checkTitle = room.roomNumber + "-" + room.echoMap.houseTypeId;
            this.$refs.CHECKBOX.orderFrom = room;
            this.$refs.CHECKBOX.housetypeinfo = room;
            this.$refs.CHECKBOX.outPrice = room.overstepHourPrice;
            this.$refs.CHECKBOX.priceS = room.housePrice;
            this.$refs.CHECKBOX.memberPrice = room.housePrice;
			//房间id
			this.$refs.CHECKBOX.currentHouseType = room.houseTypeId;
			this.$refs.CHECKBOX.ruleForm.houseTypeName = room.echoMap.houseTypeId;
			this.$refs.CHECKBOX.ruleForm.roomId = room.id;
			this.$refs.CHECKBOX.ruleForm.roomPrice = room.housePrice;
			this.$refs.CHECKBOX.ruleForm.roomName = room.roomNumber;
			this.$refs.CHECKBOX.getNowDate(new Date());
          });
        }
      } else if (room.status.includes(2)) {
        this.isOrders = true;
		console.log('dainji')
        this.$nextTick(() => {
          if (room.connectRoomId) {
            this.$refs.orderFromBox.orderFromLists({
              connectRoomId: room.connectRoomId,
            });
          } else {
            this.$refs.orderFromBox.orderFromLists({
              roomId: room.id,
              orderState: 1,
            });
          }
          this.$refs.orderFromBox.roomId = room.id;
          this.$refs.orderFromBox.AccompanyingGuests.splice(0);
          this.$refs.orderFromBox.li_active = 0;
          this.$refs.orderFromBox.switchoverTabs(1);
          this.$refs.orderFromBox.ruleForm.houseTypeName =
              room.echoMap.houseTypeId;
          // this.$refs.orderFromBox.EditdataStatisticsed()
        });
      }
    },

    //新增会员点击弹窗以外关闭弹窗
    handleClose(done) {
      done();
      this.getfloorlist();
      this.getroomstatus();
      this.userModeDateed();
      this.gethousetypelist();
    },
    //订单信息弹窗的自定义事件
    off_Order(e) {
      this.isOrders = false;
      this.getfloorlist();
      this.getroomstatus();
      this.userModeDateed();
      this.gethousetypelist();
    },
    //发票关闭
    invoiceClose() {
      this.isInvoice = false;
    },
    makecardclose() {
      this.makecardshow = false;
      this.newmakecardshow = false;
    },
    //浏览器宽度变化时触发
    resizeNutton() {
      this.screenWidths = document.body.clientWidth;
      // this.autoHeight();
    },

    //高度自适应
    autoHeight() {
      // 初始化的时候，设置高度
      this.setHeight();
      // 改变浏览器窗口大小的时候，设置高度
      window.addEventListener("resize", () => {
        this.setHeight();
      });
    },
    // 设置高度
    setHeight() {
      this.$nextTick(() => {
        if (this.$refs.roomlist) {
          if (this.$refs.footRulse) {
            setTimeout(() => {
              let height = this.$refs.roomlist.offsetHeight;
              let RoomHeight = this.$refs.RoomHeight.offsetHeight;
              var FlootHeight = this.$refs.footRulse.offsetHeight;
              this.tableheight =
                  parseFloat(height) -
                  parseFloat(RoomHeight) -
                  parseFloat(FlootHeight) -
                  50 +
                  "px";
              // console.log("筛选元素高度", FlootHeight);
            }, 300)

          } else {
            setTimeout(() => {
              let height = this.$refs.roomlist.offsetHeight;
              // console.log("元素高度", height);
              let RoomHeight = this.$refs.RoomHeight.offsetHeight;
              // console.log("房型高度", RoomHeight);
              this.tableheight =
                  parseFloat(height) - parseFloat(RoomHeight) - 120 + "px"; // 这里的X就是顶部栏的高度
              // console.log("高度", this.tableheight);
            }, 300)

          }

          // document.getElementsByClassName("el-table__fixed-body-wrapper").style.max-height = "440px";
        }
      });
    },
    //预防误触
    yufang() {

    },
	//换房更改外部名称
	changetitle(title){
		this.checkTitle = title
	},
  },
};
</script>

<style lang="scss" scoped>
.roomStateSBox {
  width: 100%;
  font-size: 14px;
  color: #666666;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 40px;
  padding-left: 20px;
  // margin-right: 15px;
  // margin-right: -20px;
  box-sizing: border-box;
  background-color: #fff;
}

.roomStateSBox.roombuttonBoxss {
  margin-right: 10px;
}

.titleBosss {
  width: 110px;
}

.CheckBoxsss {
  ::v-deep .el-dialog__body {
    padding-top: 5px;
    max-height: 650px;
    overflow: hidden;
    box-sizing: border-box;
    // background-color: #e8fdfe;
  }

  ::v-deep .el-dialog__header {
    // background-color: #e8fdfe;
  }
}

::v-deep .el-dialog__header {
  // background-color: #e8fdfe;
}

.el-icon-search {
  margin-top: 60%;
  font-size: 20px;
}

::v-deep .el-dialog__body {
  padding-top: 5px;
  max-height: 640px;
  overflow-y: auto;
  box-sizing: border-box;
  // background-color: #e8fdfe;
}

.text-write {
  color: #fff !important;
}

.MaintainBOX {
  ::v-deep .el-dialog__header {
    // background-color: #e8fdfe;
    height: 40px;
    font-size: 16px;
  }

  ::v-deep .el-dialog__body {
    height: 360px;
    // overflow-y: auto;
    overflow-y: auto;
    box-sizing: border-box;
    // background-color: #e8fdfe;
  }
}

.RelevanceBox {
  ::v-deep .el-dialog__header {
    // background-color: #e8fdfe;
    height: 40px;
    font-size: 16px;
    padding-bottom: 0px;
    margin-bottom: 10px;
  }

  ::v-deep .el-dialog__body {
    padding-top: 0px;
    max-height: 800px;
    // overflow-y: auto;
    overflow: hidden;
    box-sizing: border-box;
    // background-color: #e8fdfe;
  }
}

.RelevanceBoxss {
  ::v-deep .el-dialog__header {
    // background-color: #e8fdfe;
    height: 40px;
    font-size: 16px;
    padding-bottom: 0px;
    margin-bottom: 10px;
  }

  ::v-deep .el-dialog__body {
    padding-top: 0px;
    max-height: 670px;
    overflow: hidden;
    box-sizing: border-box;
    // background-color: #e8fdfe;
  }
}

.MemberAdd {
  ::v-deep .el-dialog__header {
    // background-color: #e8fdfe;
    height: 20px;
  }

  ::v-deep .el-dialog__body {
    max-height: 670px;
    margin-top: -10px;
    // overflow-y: auto;
    box-sizing: border-box;
    // background-color: #e8fdfe;
  }
}

.nightBoxss {
  ::v-deep .el-dialog__body {
    height: 630px;
    overflow: hidden;
    padding-top: 0px;
    margin-top: 0;
    box-sizing: border-box;
    // background-color: #e8fdfe;
  }
}

.addMember {
  ::v-deep .el-dialog__header {
    // background-color: #e8fdfe;
    height: 30px;
    margin-bottom: -20px;
  }

  .title_Sweep {
    widows: 100%;
    height: 40px;
    margin-top: 20px;
    text-align: center;

    span {
      padding: 0 20px;
    }

    span.active {
      color: #5cb5b8;
    }
  }

  ::v-deep .el-dialog__body {
    height: 630px;
    overflow-y: auto;
    padding-top: 0px;
    margin-top: 0;
    box-sizing: border-box;
    // background-color: #e8fdfe;
  }

  .addMemberGrade {
    width: 100%;
    height: 540px;
    // overflow-y:auto;
    padding-bottom: 70px;
    box-sizing: border-box;
    position: relative;

    .cardRoomBox {
      width: 100%;
      height: 460px;
      overflow-y: auto;
      //卡片部分css
      .change_cards {
        width: 100%;
        height: 100px;
        color: #999999;
        display: flex;
        float: left;
        font-size: 15px;
        margin-right: 15px;
        margin-bottom: 15px;
        border-radius: 5px;
        position: relative;
        background-color: #f3f3f3;

        .chaFang {
          position: absolute;
          z-index: 10;
          bottom: 40%;
          left: 3%;
          color: red;
        }

        .change_Price {
          width: 60%;
          display: flex;
          flex-direction: column;
          padding-left: 10px;
          box-sizing: border-box;
          justify-content: space-around;

          div {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .room_price {
          flex: 1;
          padding: 20px 10px 0 0;
          box-sizing: border-box;
          text-align: right;

          img {
            display: none;
          }
        }
      }

      .noneCommodity {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          font-size: 20px;
          color: #5cb5b8;
        }
      }

      .change_cards.active {
        background-color: #d7f5f5;
        color: #5cb5b8;

        .room_price {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 10px 10px 0 0;
          box-sizing: border-box;
          text-align: right;
          position: relative;

          img {
            display: block;
            position: absolute;
            bottom: 15px;
            right: 15px;
          }
        }
      }
    }

    .buttonBox {
      position: absolute;
      height: 50px;
      width: 100%;
      text-align: right;
      bottom: -20px;

      .el-button {
        background-color: #fff;
        color: #999999;
        border-radius: 4px;
        width: 100px;
        height: 35px;
        padding: 0px 20px;
      }

      .el-button:last-child {
        background-color: #5cb5b8;
        color: #fff;
        border-radius: 4px;
        width: 100px;
        height: 35px;
      }
    }
  }
}

.OrderaddMember {
  ::v-deep .el-dialog__header {
    // background-color: #e8fdfe;
    // height: 30px;
    // margin-bottom: -20px;
  }

  ::v-deep .el-dialog__body {
    height: 550px;
    overflow-y: auto;
    padding-top: 0px;
    margin-top: 0;
    box-sizing: border-box;
    // background-color: #e8fdfe;
  }
}

.dashboard {
  width: 100%;
  height: 100%;
  margin-top: -10px;

  .searchInput {
    position: fixed;
    width: 540px;
    top: 2.5%;
    right: 18%;
    // z-index: 2;
    ::v-deep .el-input__inner {
      height: 40px;
    }
  }

  .dashboard_left {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    // padding-left: 15px;
    box-sizing: border-box;

    .el-row.right_card {
      width: 100%;
      height: 100px;
      flex-shrink: 0;

      .el-col.colServe {
        background-color: #ffffff;
        margin-right: 9px;
        height: 90%;
        border-radius: 6px;
      }

      .colServe:last-child {
        background-color: #fff;
      }

      .card_serve {
        cursor: pointer;
        height: 100%;
        width: 100%;
        color: #5cb5b8;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        // padding-left: 5px;
        // box-sizing: border-box;

        img {
          width: 25px;
          margin-right: 10px;
          // margin-left: 20px;
        }
      }

      // <el-row class="circle_earnings">
      //     <img src="../../assets/icons/椭圆 3.png" alt="">
      //     <div  class="earnings">
      //       <span>入住率:{{Number(timeOnDuty.occupancyRate)?(Number(timeOnDuty.occupancyRate) *100).toFixed(2):'0' }}%</span>

      //       <p>收益营算:{{ timeOnDuty.roomRate }}</p>
      //     </div>

      //   <div  class="earningss">
      //     <p>
      //       <span class="dailyPrice">平均房价:{{ timeOnDuty.averageRoomRate }}</span>
      //       <span>RP:{{ timeOnDuty.RP }}</span>
      //     </p>
      //     <p class="CheckRecord">
      //       <span class="CheckRoom">今日入住:{{ timeOnDuty.liveIn }}间 </span>
      //       <span>今日离店:{{ timeOnDuty.checkOut }}间</span>
      //     </p>
      //   </div>
      // </el-row>
      .circle_earnings {
        width: 115%;
        height: 100%;
        align-items: center;
        background-color: #f2ffff;
        // justify-content: space-between;
        display: flex;
        position: relative;

        .freeOfcharge {
          position: absolute;
          width: 80%;
          height: 100%;
          z-index: 100;
          top: 60%;
          left: 10%;
          padding-left: 6%;
          padding-right: 6%;
          background: inherit;
          background-color: rgba(92, 181, 184, 0.698039215686275);
          box-sizing: border-box;
          border-width: 1px;
          border-style: solid;
          border-color: rgba(92, 181, 184, 1);
          border-radius: 10px;
          // position: relative;
          .iconTitle {
            border: 10px solid rgba(92, 181, 184, 1);
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-right: 10px solid transparent;
            border-left: 10px solid transparent;
            border-bottom: 14px solid rgba(92, 181, 184, 0.698039215686275);
            position: absolute;
            top: -28.5%;
            left: 47%;
          }

          //状态展示
          .firstTitle {
            height: 50%;
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #fff;
          }

          .twoNav {
            //          .el-col {
            //            display: flex;
            //            height: 25px;
            //            color: #fff;
            //            align-items: center;
            //            // justify-content: space-between;
            //   justify-content: center;
            //            // border-right: 3px solid #fff;
            //            // margin-right: 10px;
            //          }
            // p{
            // 	text-align: center;
            // }
            // .el-col:last-child {
            //   border-right: none;
            //   margin-right: 0px;
            // }
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #fff;

            .twoNavitem {
              display: flex;
              height: 25px;
              color: #fff;
              align-items: center;
              // justify-content: space-between;
              justify-content: center;
            }
          }
        }

        img {
          width: 54px;
          height: 54px;
          margin-left: 10px;
          margin-right: 20px;
        }

        //入住
        .earnings {
          width: 30%;
          color: #5cb5b8;
          text-align: left;
          box-sizing: border-box;
          // margin-left: 30px;
          // margin-right: 20px;

          span {
            display: block;
            margin-bottom: 5px;
          }
        }

        //平均
        .earningss {
          width: 60%;
          color: #5cb5b8;
          text-align: left;

          p {
            display: flex;

            .dailyPrice {
              text-align: left;
              margin-left: -10px;
              display: block;
              margin-bottom: 5px;
              margin-right: 20px;
            }
          }

          p:first-child {
            span {
              display: block;
              text-align: left;
              padding-left: 10px;
              box-sizing: border-box;
            }
          }

          .CheckRecord {
            display: flex;

            .CheckRoom {
              display: block;
              margin-right: 40px;
            }
          }
        }
      }
    }

    // 刷新按钮的css
    .search_dashboard {
      width: 100%;
      height: 120px;
      flex-shrink: 0;
      text-align: right;
      padding-bottom: 10px;
      background: #ffffff;
      // box-shadow: 0px 12px 40px 0px rgba(207, 206, 206, 0.67);
      border-radius: 10px;
      padding-right: 10px;
      box-sizing: border-box;
      display: flex;

      // padding-top: 5px;

      //房间状态的汇总
      .card_sort {
        width: 100%;
        margin-top: 14px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        // background-color: #24e9e9;
        .card {
          width: 5%;
          // height: 100px;
          border-radius: 7px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          // background-color: #24af47;
          .card_content {
            display: flex;
            flex-direction: column;
            align-items: center;
            // justify-content: center;
            // line-height: 30px;
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #fff;
            justify-content: center;
            width: 85px;
            height: 85px;
            border-radius: 50%;
            border: 1px solid #cffbfc;
            background-color: #fff;
            // padding: 0 5px;
            box-sizing: border-box;
            // margin-top: 2px;
            border: 1px solid #c7f8f8;
            // background: #5CB5B8;
            p:first-child {
              // padding-top: 5%;
              font-size: 13px;
              margin-top: -10%;
              margin-bottom: 5px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: black;
              text-align: center;
              box-sizing: border-box;
            }

            P:nth-child(2) {
              width: 75px;
              white-space: nowrap;
              overflow: hidden;
              text-align: center;
              text-overflow: ellipsis;
            }
          }
        }

        .el-button.el-button--primary {
          border-radius: 10px;
          background-color: #f5fefe;
          // margin-top: 40px;
          color: #5cb5b8;
        }
      }
    }

    // 房型的筛选和展示的css样式
    .screen_out {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      //height: 100%;
      // overflow-y: auto;
      width: 100%;
      overflow: hidden;
      background: #ffffff;
      // box-shadow: 0px 12px 40px 0px rgba(207, 206, 206, 0.67);
      border-radius: 24px;
      margin-top: 10px;
      padding-right: 5px;
      box-sizing: border-box;
      padding-left: 10px;
      // overflow-y: auto;
      // 筛选那块的css
      .screen {
        width: 100%;
        padding-bottom: 20px;
        box-sizing: border-box;

        .screen_item {
          width: 100%;
          padding-top: 30px;
          box-sizing: border-box;
          // display: flex;
          // justify-content: space-between;
          // align-items: center;

          .icon_isScreen {
            width: 120%;
            font-size: 14px;
            display: flex;
            color: #5cb5b8;
            justify-content: center;
            align-items: center;
            padding-right: 26px;
            box-sizing: border-box;
          }

          .ROOMBOX {
            .el-row.roomBox_nav {
              .houseTypeNameBox.el-col {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 38px;
                margin-right: 5px;

                span {
                  font-size: 14px;
                  color: #666666;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  margin-left: 10px;
                }
              }

              .el-col.ButtonRomm {
                .el-row {
                  .el-col {
                    .el-button {
                      cursor: pointer;
                      // padding: 0 21px;
                      // width: 44px;
                      height: 38px;
                      border-color: #fff;
                      background: #f5fefe;
                      border-radius: 5px;
                      // margin-left: 20px;
                      font-size: 14px;
                      font-family: Microsoft YaHei;
                      font-weight: 400;
                      color: #5cb5b8;
                      // line-height: 41px;
                      // text-align: center;
                      display: flex;
                      margin-right: 10px;
                      justify-content: center;
                      align-items: center;
                      padding: 5px 10px;
                    }

                    p {
                      width: 58px;
                      height: 25px;
                      font-size: 16px;
                      font-family: Microsoft YaHei;
                      font-weight: 400;
                      color: #666666;
                    }

                    .el-button.active {
                      color: #ffffff;
                      background: #5cb5b8;
                      border-radius: 5px;
                    }

                    .isIcon {
                      font-size: 15px;
                      font-family: Microsoft YaHei;
                      font-weight: 400;
                      color: #24af47;
                    }
                  }

                  .el-col:first-child {
                    .el-button.active {
                      color: #ffffff;
                      background: #5cb5b8;
                      border-radius: 5px;
                    }

                    .allScreen {
                      width: 78px;
                      height: 38px;
                      background: #f5fefe;
                      border-radius: 8px;
                      color: #5cb5b8;
                      padding: 5px 10px;
                    }

                    .el-button {
                      cursor: pointer;
                      // padding: 0 21px;
                      // width: 44px;
                      width: 78px;
                      height: 38px;
                      border-color: #fff;
                      background: #f5fefe;
                      border-radius: 5px;
                      // margin-left: 20px;
                      font-size: 14px;
                      font-family: Microsoft YaHei;
                      font-weight: 400;
                      color: #5cb5b8;
                      // line-height: 41px;
                      // text-align: center;
                      display: flex;
                      margin-right: 10px;
                      margin-left: -20px;
                      justify-content: center;
                      align-items: center;
                      padding: 5px 10px;
                    }
                  }
                }
              }
            }
          }
        }

        // 点击筛选显示或隐藏
        .houseType,
        .roomBox_nav {
          // display: flex;
          // flex-direction: column;

          ul {
            display: flex;
            width: 100%;
            line-height: 38px;
            // height: 38px;
            display: flex;
            justify-content: left;
            // flex-wrap: wrap;
            margin-bottom: 5px;

            span {
              margin-top: 13px;
            }

            .allScreen {
              width: 78px;
              height: 38px;
              background: #f5fefe;
              border-radius: 4px;

              color: #fff;
            }

            li:first-child {
              width: 54px;
              // max-width: 110px;
              height: 38px;
              // margin-right: -20px;
              font-size: 14px;
              // color: #666666;

              background: #f5fefe;
              color: #5cb5b8;

              font-family: Microsoft YaHei;
              font-weight: 400;
              // text-align: left;
              text-align: center;
              margin-left: 10px;

              // box-sizing: border-box;
              // background-color: #fff;
            }

            li {
              cursor: pointer;
              // width: 78px;
              min-width: 78px;
              padding-left: 10px;
              padding-right: 10px;
              height: 38px;
              margin-right: 20px;
              background: #f5fefe;
              margin-top: 2px;
              border-radius: 4px;
              font-size: 14px;
              margin-left: 10px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #5cb5b8;
              line-height: 38px;
              text-align: center;
            }

            li.active {
              height: 38px;
              color: #ffffff;
              line-height: 41px;
              background: #5cb5b8;
              border-radius: 4px;
            }
          }

          ul:nth-child(2) {
            .allScreen {
              width: 78px;
              height: 38px;
              background: #f5fefe;
              border-radius: 4px;
              color: #5cb5b8;
            }

            li:first-child {
              // margin-left: 10px;
              text-align: center;
            }

            li {
              border: none;
              background-color: #fff;
              color: #666666;
            }

            li.active {
              height: 38px;
              color: #ffffff;
              line-height: 41px;
              background: #5cb5b8;
              border-radius: 4px;
            }
          }
        }
      }

      // 卡片展示区域
      .card_fiche {
        width: 100%;
        height: 100%;
        flex-grow: 1;
        // padding-top: 10px;
        overflow: scroll;
        //overflow-y: auto;
        //点击卡片右下角图标弹出弹框css
        .card_window {
          position: fixed;
          z-index: 100;
          color: gray;
          width: 102px;
          box-shadow: 0px 3px 18px 0px rgba(20, 20, 20, 0.56);
          background-color: #fff;
          border: 1px solid gray;

          ul {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;

            li {
              width: 90%;
              height: 30px;
              line-height: 30px;
              text-align: center;
            }
          }
        }

        .card_one {
          display: flex;
          justify-content: end;
          // padding-left: 3%;
          // box-sizing: border-box;
          width: 100%;
          height: auto;
          padding-top: 10px;

          .cell {
            width: 4%;
            max-width: 5%;
            height: 27px;
            background: #ffc01c;
            border-radius: 14px;
            margin-left: 11px;
            text-align: center;
            line-height: 27px;
            color: #fff;
            font-weight: bold;
            text-align: center;
          }

          p {
            width: 130%;
            height: 100%;
          }

          .house_type {
            width: 98%;
            border-radius: 10px;
            padding-left: 13px;
            box-sizing: border-box;
            display: flex;
            flex-wrap: wrap;
            // padding-top: 10px;
            // 卡片的css样式
            .house_content {
              width: 12%;
              height: 110px;
              cursor: pointer;
              // height: 90px;
              margin-bottom: 6px;
              border-radius: 8px;
              margin-right: 6px;
              display: flex;

              // 左边的内容
              .conten_left {
                width: 50%;
                height: 100%;
                padding-top: 10px;
                padding-left: 8px;
                box-sizing: border-box;
                padding-bottom: 5px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                position: relative;
                // span {
                //   font-size: 15px;
                //   font-family: Microsoft YaHei;
                //   font-weight: 400;
                //   color: #666666;
                // }

                // p {
                //   margin-bottom: 13px;
                //   margin-top: 5px;
                //   font-size: 15px;
                //   font-family: Microsoft YaHei;
                //   font-weight: 400;
                //   color: #666666;
                //   line-height: 5px;
                // }

                .card_state {
                  display: flex;
                  // position: relative;
                  box-sizing: border-box;
                  // background-color: #24af47;
                  .sign {
                    // position: absolute;
                    width: 25px;
                    height: 25px;
                    bottom: -7px;
                    border: 1px solid #ffffff;
                    border-radius: 50%;
                    font-size: 15px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #ffffff;
                    text-align: center;
                    line-height: 25px;
                    // margin-bottom: 9px;
                    margin-bottom: 5px;
                    margin-right: 5px;
                  }
                }

                p {
                  width: 200%;
                  color: red;
                  z-index: 10;
                  font-size: 13px;
                  position: absolute;
                  top: 60%;
                  left: 98%;
                }
              }

              // 右边的内容
              .conten_right {
                width: 50%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: end;
                padding: 10px 10px 5px 0px;
                box-sizing: border-box;
                position: relative;

                div {
                  text-align: right;
                  font-size: 14px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #ffffff;
                }

                .item {
                  width: 100%;
                  // position: absolute;
                  // bottom: 25%;
                  display: flex;
                  flex-direction: column;
                  justify-content: end;
                  align-items: flex-end;

                  div {
                    ul {
                      li {
                        margin-bottom: 20px;
                      }
                    }
                  }
                }

                img.iconImg {
                  width: 16px;
                  height: 16px;
                  // position: absolute;
                  // bottom: -15px;
                  // right: 2px;
                }
              }
            }
          }
        }
      }
    }
  }

  // .dashboard_right {
  //   // background-color: #f8f8f8;
  //   padding: 0px 13px 0px 10px;
  //   box-sizing: border-box;
  //   margin-left: 10px;

  //   .status-bar {
  //     height: 180px;
  //     background: #ffffff;
  //     border-radius: 24px;
  //     padding-top: 20px;
  //     padding-left: 10px;
  //     box-sizing: border-box;

  //     .circle_earnings {
  //       height: 100px;
  //       display: flex;

  //       img {
  //         width: 60px;
  //         height: 60px;
  //       }

  //       .earnings {
  //         height: 60px;
  //         flex: 1;
  //         margin-left: 20px;
  //         font-size: 13px;
  //         font-family: Microsoft YaHei;
  //         font-weight: 400;
  //         color: #333333;
  //         display: flex;
  //         flex-direction: column;
  //         justify-content: center;

  //         span {
  //           margin-bottom: 8px;
  //         }
  //       }
  //     }

  //     .earningss {
  //       font-size: 13px;
  //       font-family: Microsoft YaHei;
  //       font-weight: 400;
  //       color: #333333;
  //       display: flex;
  //       flex-direction: column;

  //       p {
  //         display: flex;
  //         justify-content: space-around;
  //       }

  //       div {
  //         margin-top: 10px;
  //         display: flex;
  //         justify-content: space-around;
  //         font-family: Microsoft YaHei;
  //         font-weight: 400;
  //         color: #333333;
  //       }
  //     }
  //   }

  //   .card_serve {
  //     width: 100%;
  //     height: 70px;
  //     cursor: pointer;
  //     background: #ffffff;
  //     border-radius: 15px;
  //     display: flex;
  //     font-size: 14px;
  //     align-items: center;
  //     justify-content: space-between;
  //     padding-left: 20px;
  //     padding-right: 15px;
  //     margin-top: 15px;
  //     box-sizing: border-box;

  //     p {
  //       display: flex;
  //       flex-direction: column;
  //       span{
  //         font-size: 16px;
  //       }
  //       P {
  //         margin-top: 10px;
  //       }
  //     }
  //     img{
  //       width: 38px;
  //       height: 34px;
  //     }
  //   }
  // }

  .whetherLock {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(133, 106, 106, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;

    .whetherLock_main {
      width: 500px;
      height: 180px;
      background-color: #fff;

      .whetherLock_color {
        width: 100%;
        height: 146px;
        // background: linear-gradient(177deg, #e8fdfe 0%, #ffffff 100%);
        border-radius: 5px;

        .save_size {
          width: 100%;
          height: 126px;
          // background: linear-gradient(177deg, #e8fdfe 0%, #ffffff 100%);
          border-radius: 5px;
          padding-top: 30px;
          box-sizing: border-box;

          p {
            height: 40px;
            padding-left: 45px;
            box-sizing: border-box;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;

            img {
              width: 15px;
              height: 15px;
            }
          }

          .size_floot {
            padding-left: 80px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #555555;
          }
        }

        .save_button {
          display: flex;
          justify-content: end;
          padding-right: 40px;
          box-sizing: border-box;

          .el-button {
            margin-left: 20px;
            width: 100px;
          }
        }
      }
    }
  }
}

.supplement {
  width: 100%;
  display: flex;
  align-items: end;
  flex-direction: column;
  color: #fff;

  .div-right {
    width: 100%;
    text-align: right;
  }

  .div-left {
    width: 100%;
    text-align: left;
    font-size: 13px;
  }

  .div-margin-bottom {
    margin-bottom: 5px;
  }
}

.PlateBox {
  background-color: #fff;
  box-shadow: 1px 1px 7px gray;
  bottom: -60px;
  z-index: 20;
  width: 90px;
  height: 70px;
  text-align: center;
  background-color: #5cb5b8;

  p {
    width: 100%;
    height: 30px;
    text-align: center;
    line-height: 35px;
    font-size: 13px;
    cursor: pointer;
  }
}

.BottomTooltip {
  width: 80px;
  display: flex;
  justify-content: center;
  border: 1px solid #fff;

  ul {
    width: 100%;
    height: 100%;
    padding-right: 5px;

    li {
      width: 110%;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background-color: #f7f7f7;
    }
  }
}

// ::v-deep .el-tooltip__popper.is-light{
//   border: 1px solid #0656f7 !important;
// }
.text_tooltip {
  border: 1px solid #0656f7 !important;
  background-color: #24af47;
}

.RoomItem {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .card_serve {
    cursor: pointer;
    height: 100%;
    width: 100%;
    color: #5cb5b8;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 25px;
      margin-right: 10px;
    }
  }
}

.reserveBox {
  ::v-deep .el-dialog__header {
    // background-color: #e8fdfe;
    // height: 30px;
    // margin-bottom: -20px;
  }

  ::v-deep .el-dialog__body {
    max-height: 680px;
    overflow: hidden;
    padding-top: 0px;
    margin-top: 0;
    box-sizing: border-box;
    // background-color: #e8fdfe;
  }

  .check_main {
    // overflow: hidden;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.flex-wrap {
  flex-wrap: wrap;
  // margin-left: 10px;
  width: 100%;
  display: flex;

  .roomCARDbOX {
  }
}

.shuline {
  height: 15px;
  width: 1px;
  background-color: #fff;
}

// .flex-wrap:first-child{
//   margin-left: 10px;
// }

.newearningss {
  width: 80%;
  color: #5cb5b8;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-around;

  .newearningssitem {
    width: 100%;

    div:first-child {
      margin-bottom: 10px;
    }
  }
}
</style>