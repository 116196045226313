import { render, staticRenderFns } from "./ReserveBox.vue?vue&type=template&id=57a76649&scoped=true"
import script from "./ReserveBox.vue?vue&type=script&lang=js"
export * from "./ReserveBox.vue?vue&type=script&lang=js"
import style0 from "./ReserveBox.vue?vue&type=style&index=0&id=57a76649&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57a76649",
  null
  
)

export default component.exports