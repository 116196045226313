import request from '_utils/request'
import qs from 'qs'
// 预定页请求
//预订单分页
const advanceOrderList = (params = {}) => (
request.post('/wineshop/orderReserve/customPage', params)
)
 
    //预定订单信息表、获取可预定房间
const advanceOrder = (params = {}) => (
    request.post('/wineshop/orderReserve/queryAllRoomByState',
    qs.stringify(params)
    , {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
    )
    //预定订单信息表、获取指定房型的可预定房间
const advanceOrderHoutyType = (params = {}) => (
    request.post('/wineshop/orderReserve/getCanReserveRoom',
    qs.stringify(params)
    , {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
    )
    //预定订单信息表、创建预订单
const AddmayAdvance = (params = {}) => (
    request.post('/wineshop/orderReserve/addOrderReserve', params)
    )
    //房间之房态列表
    const houseState = (params = {}) => (
        request.post('/wineshop/room/view', params)
    )
    //协议单位查看
    const BargainingUnitAll = (params = {}) => (
        request.get('/wineshop/agreement_unit/type_view',
        {
            headers: {
               'Content-Type': 'application/x-www-form-urlencoded' 
            },
            params:  qs.stringify(params)
        }
        ))
    //获取房态日历
    const getCondition = (params = {}) => (
        request.post('/wineshop/orderReserve/queryRoomCalendar',
        qs.stringify(params)
    , {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
    )
   //获取远期房态
   const getForwardCondition = (params = {}) => (
    request.post('/wineshop/orderReserve/queryForwardRoom',
    qs.stringify(params)
, {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
)
//预订单数据统计: 侧边栏
const DataStatistics = (params = {}) => (
    request.post('/wineshop/orderReserve/getNumByType',
    qs.stringify(params)
, {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
)
//查看预订单详情
const orderReserveDetail = (params = {}) => (
    request.post('/wineshop/orderReserve/orderReserveDetail?orderReserveId='+params.orderReserveId,)
)

////预订单转入住 
const ReservationAdd = (params = {}) => (
    request.post('/wineshop/orderReserve/reserveToCheckIn', params )
)
export{
    advanceOrderList,
    advanceOrder,
    AddmayAdvance,
    houseState,
    BargainingUnitAll,
    getCondition,
    getForwardCondition,
    DataStatistics,
	orderReserveDetail,
    advanceOrderHoutyType,
    ReservationAdd
}