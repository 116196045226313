<template>
  <!-- 订单信息 -->
  <div class="order_information" @click="on_off" v-if="musiclist">
    <!-- 弹窗 -->

    <!-- 制门卡弹窗 -->
    <div class="order-content" v-if="isTable">
      <div class="order_box">
        <!-- 标题部分 -->
        <!-- <div class="order_title">
          <p>订单信息</p>
          <div class="title_icon">
            <i
              class="el-icon-close"
              style="color: black; font-size: 25px"
              @click="close_page"
            ></i>
          </div>
        </div> -->
        <!-- 标题部分 -->

        <!-- 头部选项卡部分 -->
        <div
          v-if="
            menuDataAside.includes('订单信息') ||
            menuDataAside.includes('财务信息') ||
            menuDataAside.includes('发票信息') ||
            menuDataAside.includes('操作日志')
          "
          class="tab_control"
        >
          <ul>
            <li
              v-if="menuDataAside.includes('订单信息')"
              :class="{ active: tab_active == 1 }"
              @click="switchoverTabs(1)"
            >
              订单信息
            </li>
            <li
              v-if="menuDataAside.includes('财务信息')"
              :class="{ active: tab_active == 2 }"
              @click="switchoverTabs(2)"
            >
              财务信息
            </li>
            <li
              v-if="menuDataAside.includes('发票信息')"
              :class="{ active: tab_active == 3 }"
              @click="switchoverTabs(3)"
            >
              发票信息
            </li>
            <li
              v-if="menuDataAside.includes('操作日志')"
              :class="{ active: tab_active == 4 }"
              @click="switchoverTabs(4)"
            >
              操作日志
            </li>
          </ul>
        </div>
        <!-- 头部选项卡部分 -->

        <el-row class="information_content" v-show="isOrder">
          <!-- 侧边栏部分 -->
          <el-col
            v-if="
              roomArrActive.includes('xuzhu') ||
              roomArrActive.includes('genghuanroom') ||
              roomArrActive.includes('shangpinggoumai') ||
              roomArrActive.includes('bataidiancan') ||
              roomArrActive.includes('zidingyipiaoju') ||
              roomArrActive.includes('huiyuankaikahechakan') ||
              roomArrActive.includes('huiyuankaikahechakan')
            "
            :span="3"
            class="sidebar"
          >
            <ul>
              <li
                v-show="
                  roomArrActive.includes('xuzhu')
                    ? ruleForm.orderState
                      ? ruleForm.settlementType == '1'
                        ? false
                        : true
                      : false
                    : false
                "
                :class="{ activate: tab_sideba == 5 }"
                @click="switchoverTabs(5)"
              >
                续住
              </li>
              <li
                v-show="
                  roomArrActive.includes('genghuanroom')
                    ? ruleForm.orderState
                    : false
                "
                :class="{ activate: tab_sideba == 6 }"
                @click="switchoverTabs(6)"
              >
                更换房间
              </li>
              <li
                v-show="
                  roomArrActive.includes('shangpinggoumai')
                    ? ruleForm.orderState
                    : false
                "
                :class="{ activate: tab_sideba == 10 }"
                @click="switchoverTabs(10)"
              >
                商品购买
              </li>
              <li
                v-show="
                  roomArrActive.includes('bataidiancan')
                    ? ruleForm.orderState
                    : false
                "
                :class="{ activate: tab_sideba == 11 }"
                @click="switchoverTabs(11)"
              >
                吧台点餐
              </li>
              <li
                v-show="
                  roomArrActive.includes('zidingyipiaoju')
                    ? !ruleForm.orderState
                    : false
                "
                :class="{ activate: tab_sideba == 7 }"
                @click="switchoverTabs(7)"
              >
                打印票据
              </li>
              <li
                :class="{ activate: tab_sideba == 8 }"
                @click="switchoverTabs(8)"
              >
                制门卡
              </li>
              <!-- roomArrActive.includes('huiyuankaikahechakan')
                    ? ruleForm.orderState
                    : false -->
              <li
                v-if="false"
                :class="{ activate: tab_sideba == 9 }"
                @click="switchoverTabs(9)"
              >
                {{ ruleForm.isMember ? "查看会员" : "会员开卡" }}
              </li>
              <el-tooltip
                class="item"
                effect="dark"
                content="Top Left 提示文字"
                placement="top-start"
              >
                <div slot="content" class="recoverNav">
                  <img src="../../../../assets/icons/警告 (1).png" alt="" />
                  <span>是否恢复重开结账单</span>
                  <br />
                  <div class="buttonCkick">
                    <el-button
                      @click="renewButton(0)"
                      style="
                        margin-left: 50px;
                        margin-top: 10px;
                        width: 40px;
                        height: 20px;
                        padding: 0px 15px;
                      "
                    >
                      否
                    </el-button>
                    <el-button
                      @click="renewButton(1)"
                      style="
                        width: 40px;
                        height: 20px;
                        padding: 0px 13px;
                        background-color: #5cb5b8;
                        color: #fff;
                      "
                    >
                      是
                    </el-button>
                  </div>
                </div>
                <li
                  v-show="!ruleForm.orderState"
                  class="recoverButton"
                  @click="switchoverTabs(12)"
                >
                  恢复
                </li>
              </el-tooltip>
            </ul>
          </el-col>
          <!-- 侧边栏部分 -->

          <!-- 内容区域 -->
          <el-col :span="21" class="order_main">
            <!-- 房单信息的展示 -->
            <el-row class="tenant_message">
              <el-row class="tenant_size">
                <div class="tenant_left">
                  <div class="size_box"></div>
                  <p class="main_title">房单信息</p>
                </div>
                <div
                  class="tenant_right"
                  v-if="roomArrActive.includes('dingdanxinxibianji')&&ruleForm.orderState"
                >
                  <el-button @click="TenantRightButton(1)" v-if="isTenantRight">
                    编辑
                  </el-button>
                  <el-button @click="TenantRightButton(2)" v-else>
                    保存
                  </el-button>
                </div>
              </el-row>

              <!-- 信息展示与备注 -->
              <el-row class="main_content">
                <el-form
                  :model="ruleForm"
                  :inline="true"
                  :rules="rules"
                  ref="ruleForm"
                  label-width="100px"
                  class="demo-ruleForm"
                >
                  <el-form-item
                    label-width="80px"
                    label="抵店时间"
                    prop="arrivalTime"
                  >
				  <!-- :disabled="isTenantRight" -->
                    <el-input
                      style="width: 180px"
                      :disabled="true"
                      v-model="ruleForm.arrivalTime"
                    ></el-input>
                  </el-form-item>

                  <el-form-item
                    label-width="70px"
                    label="门市价"
                    prop="roomPrice"
                  >
				  <!-- :disabled="isTenantRight" -->
                    <el-input
                      :disabled="true"
                      v-model="ruleForm.roomPrice"
                      style="width: 120px"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label-width="70px"
                    label="折扣率"
                    prop="discount"
                  >
				  <!-- :disabled="isTenantRight" -->
                    <el-input
                      :disabled="true"
                      style="width: 100px"
                      v-model="ruleForm.discount"
                      @change="getRoomRealityPrice"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label-width="80px"
                    label="实际房价"
                    prop="roomRealityPrice"
                  >
                    <el-input
                      style="width: 130px"
                      :disabled="isTenantRight"
                      v-model="ruleForm.roomRealityPrice"
                      @change="getDiscount"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label-width="80px"
                    label="明日早餐"
                    prop="breakfast"
                  >
				  <!-- :disabled="isTenantRight" -->
                    <el-input
                      style="width: 90px"
                      :disabled="true"
                      v-model="ruleForm.breakfast"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label-width="80px"
                    label="预离时间"
                    prop="predepartureTime"
                  >
				  <!-- :disabled="isTenantRight" -->
                    <el-input
                      style="width: 180px"
                      :disabled="true"
                      v-model="ruleForm.predepartureTime"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label-width="80px"
                    label="预住天数"
                    v-if="ruleForm.settlementType != 1"
                    prop="reserveDays"
                  >
				  <!-- :disabled="isTenantRight" -->
                    <el-input
                      style="width: 110px"
                      :disabled="true"
                      v-model="ruleForm.reserveDays"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label-width="80px"
                    label="钟点时长"
                    v-if="ruleForm.settlementType == 1"
                    prop="reserveDays"
                  >
				  <!-- :disabled="isTenantRight" -->
                    <el-input
                      style="width: 110px"
                      :disabled="true"
                      v-model="ruleForm.hourLength"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label-width="70px"
                    label="超时费"
                    v-if="ruleForm.settlementType == 1"
                    prop="reserveDays"
                  >
				  <!-- :disabled="isTenantRight" -->
                    <el-input
                      style="width: 139px"
                      :disabled="true"
                      v-model="ruleForm.reserveDays"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label-width="80px" label="预定员工">
                    <el-input
                      style="width: 126px"
                      :disabled="true"
                      v-model="ruleForm.echoMap.createdBy"
                    ></el-input>
                  </el-form-item>

                  <el-form-item
                    label-width="80px"
                    label="入住类型"
                    prop="settlementType"
                  >
                    <el-select
                      :disabled="true"
                      style="width: 110px"
                      v-model="ruleForm.settlementType"
                      placeholder="请选择入住类型"
                    >
                      <el-option label="全天" value="0"></el-option>
                      <el-option label="钟点房" value="1"></el-option>
                      <el-option label="自用房" value="2"></el-option>
                      <el-option label="免费房" value="3"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="房号" label-width="50px">
                    <el-input
                      style="width: 100px"
                      :disabled="true"
                      v-model="ruleForm.roomName"
                    ></el-input>
                  </el-form-item>

                  <el-form-item
                    label-width="80px"
                    label="客户类型"
                    prop="clientType"
                  >
                    <el-select
                      :style="{
                        width: ruleForm.settlementType != 1 ? '155px' : '200px',
                      }"
                      :disabled="true"
                      v-model="ruleForm.clientType"
                      placeholder="会员"
                    >
                      <el-option label="散户" value="0"></el-option>
                      <el-option label="会员" value="1"></el-option>
                      <el-option label="协议" value="2"></el-option>
                      <el-option label="OTA" value="3"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    label-width="90px"
                    label="OTA名称"
                    prop="clientType"
                    v-if="ruleForm.clientType == '3'"
                  >
                    <el-select
                      style="width: 155px; font-size: 12px"
                      clearable
                      :disabled="true"
                      v-model="ruleForm.otaName"
                    >
                      <el-option label="美团" value="美团"></el-option>
                      <el-option label="携程" value="携程"></el-option>
                      <el-option label="飞猪" value="飞猪"></el-option>
                      <el-option label="抖音" value="抖音"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label-width="80px" label="会员号">
                    <el-input
                      style="width: 171px"
                      :disabled="true"
                      v-model="member.number"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label-width="80px" label="会员等级">
                    <el-input
                      :disabled="true"
                      style="width: 180px"
                      v-model="member.memberGradeName"
                    ></el-input>
                  </el-form-item>

                  <el-form-item label-width="80px" label="协议单位">
                    <el-select
                      :disabled="true"
                      :style="{
                        width: ruleForm.settlementType != 1 ? '210px' : '250px',
                      }"
                      v-model="ruleForm.agreementUnitName"
                      @change="ChangeBargaininged"
                    >
                      <el-option
                        v-for="item in bargaininged"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                    <!-- <div
                          @click="checkBargaining"
                          style="
                            position: absolute;
                            top: -2px;
                            right: -50px;
                            width: 45px;
                            height: 45px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: #e5fcfd;
                          "
                        >
                          <img
                            style="width: 28px; height: 28px"
                            src="../../../../assets/icons/编辑.png"
                            alt=""
                          />
                        </div> -->
                  </el-form-item>
                  <el-form-item label-width="80px" label="房间类型">
                    <el-input
                      :style="{
                        width: ruleForm.settlementType != 1 ? '211px' : '257px',
                      }"
                      :disabled="true"
                      v-model="ruleForm.houseTypeName"
                    ></el-input>
                  </el-form-item>

                  <el-form-item label-width="80px" label="预定信息">
					<el-input
					    :disabled="true"
						v-if="ruleForm.guestName"
					    v-model="ruleForm.guestName"
					    :style="{
					      width: ruleForm.settlementType != 1 ? '150px' : '180px',
					      'margin-right': '5px',
					    }"
					  ></el-input>
                    <el-input
					v-else
                      :disabled="true"
                      v-model="ruleForm.name"
                      :style="{
                        width: ruleForm.settlementType != 1 ? '150px' : '180px',
                        'margin-right': '5px',
                      }"
                    ></el-input>
                    <el-input
                      :disabled="true"
                      style="width: 250px; position: relative"
                      v-model="ruleForm.orderReserveId"
                    >
                    </el-input>
                  </el-form-item>

                  <el-form-item
                    :label-width="
                      ruleForm.settlementType != 1 ? '80px' : '80px'
                    "
                    label="房价码"
                    prop="housePriceId"
                  >
                    <el-input
                      :style="{
                        width: ruleForm.settlementType != 1 ? '287px' : '782px',
                      }"
                      :disabled="true"
                      v-model="ruleForm.housePriceName"
                    ></el-input>
                  </el-form-item>
                  <!-- 备注区域 -->
                  <el-row>
                    <el-form-item label-width="80px" label="备注信息">
                      <el-input
                        style="width: 782px"
                        :disabled="isTenantRight"
                        type="textarea"
                        class="textareaInput"
                        :rows="2"
                        @change="UpdateDate"
                        placeholder="请输入备注信息,200字以内"
                        v-model="ruleForm.remark"
                      >
                      </el-input>
                    </el-form-item>
                  </el-row>

                  <!-- 备注区域 -->
                </el-form>
              </el-row>
            </el-row>
            <!-- 房单信息的展示  -->

            <div class="resident_message">
              <!-- 标题 -->
              <div class="resident_title">
                <div class="title_size">
                  <div class="size_box"></div>
                  <p>住客信息</p>
                </div>

                <span class="size_Button">
                  <el-button
                    :class="{ active: isaddGueed }"
                    @click="addGuest(1)"
                    >设置主客</el-button
                  >
                  <el-button
                    v-if="roomArrActive.includes('Addkehuxinxi')"
                    :class="{ active: isaddGuest }"
                    @click="addGuest(2)"
                    >新增客人</el-button
                  >
                  <el-button
                    v-if="
                      roomArrActive.includes('kehuxxbianji') ? isMessage : false
                    "
                    @click="UpdateMessage(1)"
                    style=""
                    >编辑</el-button
                  >
                  <el-button
                    @click="UpdateMessage(2)"
                    v-if="!isMessage"
                    style=""
                    >保存</el-button
                  >
                </span>
              </div>
              <!-- 标题 -->

              <!-- 用户标签，点击标签切换不同用户信息 -->
              <div class="resident_label">
                <ul>
                  <li
                    v-for="(el, index) in AccompanyingGuests"
                    :key="index"
                    :style="{
                      display: AccompanyingGuests.lengths == 0 ? 'none' : '',
                    }"
                    :class="{
                      active: li_active == index,
                      starLI: li_active == index,
                    }"
                    @click="labelClick(index)"
                  >
                    <img
                      src="../../../../assets/icons/星_收藏_标记.png"
                      alt=""
                      :class="{
                        starImg: true,
                        active: el.isMaster && li_active == index,
                      }"
                      v-if="el.isMaster && li_active == index"
                    />
                    <img
                      src="../../../../assets/imgs/active_index.png"
                      alt=""
                      :class="{
                        imgBox: true,
                        active: el.isMaster && li_active != index,
                      }"
                      v-else
                    />
                    <i
                      class="el-icon-close"
                      @click="DeteleAreUser(index)"
                      :class="{
                        active: el.isMaster ? false : li_active === index,
                      }"
                    ></i>
                    {{ el.name ? el.name : "用户姓名" }}
                  </li>
                  <!-- <el-button 
                  
                  ></el-button> -->
                </ul>
              </div>
              <!-- 用户标签，点击标签切换不同用户信息 -->

              <!-- 根据点击的用户标签显示用户信息 -->
              <div class="user_message">
                <!-- 客户信息表单 -->
                <el-form
                  :model="AccompanyingGuests[li_active]"
                  :rules="rules"
                  ref="ruleForm"
                  :inline="true"
                  label-width="80px"
                  class="demo-ruleForms"
                >
                  <!-- 主客展示 -->
                  <el-form-item
                    style="position: relative; margin-bottom: 5px"
                    label="姓名"
                    prop="name"
                    label-width="90px"
                  >
                    <el-input
                      clearable
					  @change="nameChange"
                      :disabled="isMessage"
                      style="font-size: 12px; width: 90px"
                      v-model.trim="AccompanyingGuests[li_active].name"
                    ></el-input>
                    <img
                      @click="ReadingDataButton(li_active)"
                      class="memberImg"
                      src="../../../../assets/icons/会员2.png"
                      alt=""
                    />
                  </el-form-item>
                  <el-form-item
                    style="margin-bottom: 5px"
                    label="顾客性别"
                    prop="gender"
                  >
                    <el-select
                      clearable
                      style="width: 90px"
                      v-model="AccompanyingGuests[li_active].gender"
                      :disabled="isMessage"
                      placeholder="请选择活动区域"
                    >
                      <el-option label="男" value="0"></el-option>
                      <el-option label="女" value="1"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    style="margin-bottom: 5px"
                    label="证件类型"
                    prop="typeIdCard"
                  >
                    <el-select
                      clearable
                      style="width: 110px"
                      v-model="AccompanyingGuests[li_active].idType"
                      :disabled="true"
                      placeholder="请选择证件类型"
                    >
                      <el-option label="身份证" value="1"></el-option>
                      <el-option label="驾驶证" value="2"></el-option>
                      <el-option label="军官证" value="3"></el-option>
                      <el-option label="通行证" value="4"></el-option>
                      <el-option label="护照" value="5"></el-option>
                      <el-option label="其他" value="6"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    style="font-size: 12px; margin-bottom: 5px"
                    label="证件号码"
                    prop="idCard"
                    label-width="90px"
                  >
                    <el-input
                      clearable
                      :disabled="isMessage"
                      style="font-size: 12px"
                      @change="idCardButton"
                      v-model.trim="AccompanyingGuests[li_active].idCard"
                      placeholder="请输入证件号码"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    style="position: relative; margin-bottom: 5px"
                    label="手机号码"
                    prop="mobile"
                    label-width="90px"
                  >
                    <el-input
                      clearable
                      :disabled="isMessage"
                      style="width: 129px; font-size: 12px"
                      v-model.trim="AccompanyingGuests[li_active].mobile"
                      placeholder="请输入手机号码"
                      @change="
                        mobileInput(AccompanyingGuests[li_active].mobile)
                      "
                    ></el-input>
                  </el-form-item>

                  <el-form-item
                    style="margin-bottom: 5px"
                    label="会员号"
                    prop="date2"
                  >
                    <el-input
                      clearable
                      style="font-size: 12px; width: 140px"
                      :disabled="true"
                      v-model="AccompanyingGuests[li_active].number"
                    ></el-input>
                  </el-form-item>
                  <el-form-item style="margin-bottom: 5px" label="会员等级">
                    <el-input
                      clearable
                      style="font-size: 12px; width: 95px"
                      :disabled="true"
                      v-model="AccompanyingGuests[li_active].memberGradeName"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="顾客生日" prop="birthday">
                    <el-date-picker
                      :disabled="isMessage"
                      style="font-size: 12px; width: 135px"
                      v-model="AccompanyingGuests[li_active].birthday"
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="生日日期"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-form>
                <!-- 客户信息表单 -->
              </div>
              <!-- 根据点击的用户标签显示用户信息 -->
            </div>
            <!-- 住客信息的展示 -->
          </el-col>
          <!-- 内容区域 -->
        </el-row>

        <!-- 财务信息页面 -->
        <finance-message
          v-if="isFinance"
          ref="financeBox"
          @financeBoxs="financeBoxs"
          :roomId="roomId"
          :connectRoomId="connectRoomId"
        ></finance-message>
        <!-- 财务信息页面 -->
        <!-- 发票信息页面 -->
        <invoice-message
          v-if="isInvoice"
          ref="invoiceBox"
          @invoiceEvent="invoiceEvent"
        ></invoice-message>
        <!-- 发票信息页面 -->
        <!-- 操作日志的弹窗 -->
        <operation-log
          v-if="isOperation"
          :rule-form-id="ruleForm.id"
          ref="operationBoxs"
        ></operation-log>
        <!-- 操作日志的弹窗 -->
      </div>

      <!-- 制門卡彈窗 -->
      <div class="doorstop" v-if="isDoorstop">
        <div class="order_box">
          <!-- 标题部分 -->
          <div class="order_title">
            <p>制门卡</p>
            <div class="title_icon">
              <i
                @click="doorstopOff"
                class="el-icon-close"
                style="color: black; font-size: 25px"
              ></i>
            </div>
          </div>
          <!-- 标题部分 -->
          <div class="main">
            <div class="subButton">
              <el-button @click="makedoorcard">制新卡</el-button>
              <el-button @click="destruction">销卡</el-button>
              <el-button @click="chirp">鸣叫</el-button>
              <el-button @click="carddata">卡片数据</el-button>
              <el-button @click="cardType">卡片类型</el-button>
              <el-button @click="checkOut">离店时间</el-button>
              <el-button @click="locknumber">卡锁号</el-button>
            </div>
            <div class="table">
              <!-- <el-table :data="tableData" border style="width: 100%">
                <el-table-column prop="date" label="房号" width="90">
                </el-table-column>
                <el-table-column prop="name" label="锁号" width="90">
                </el-table-column>
                <el-table-column prop="address" label="开始时间" width="180">
                </el-table-column>
                <el-table-column
                  prop="predepartureTime"
                  label="到期时间"
                  width="180"
                >
                </el-table-column>
              </el-table> -->
              <el-form
                :model="roomcard"
                status-icon
                :rules="roomrules"
                ref="roomcard"
                label-width="100px"
                class="demo-ruleForm"
              >
                <el-form-item label="酒店标识" prop="sign">
                  <el-input-number
                    v-model="roomcard.sign"
                    :controls="false"
                    :min="1"
                    :max="999999"
                  ></el-input-number>
                  <span slot="label">
                    酒店标识
                    <el-tooltip placement="top">
                      <div slot="content">最长六位，不能以0开头</div>
                      <i class="el-icon-question"></i>
                    </el-tooltip>
                  </span>
                </el-form-item>
                <el-form-item label="卡号" prop="cardNo">
                  <el-input
                    v-model="roomcard.cardNo"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
                <el-form-item label="锁号">
                  <el-row>
                    <el-col :span="5" :offset="1">
                      <el-form-item prop="value1">
                        <el-input
                          type="lockBytes"
                          v-model.number="roomcard.value1"
                          placeholder="栋号"
                          autocomplete="off"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="5" :offset="1" prop="value2">
                      <el-form-item prop="value2">
                        <el-input
                          type="lockBytes"
                          v-model.number="roomcard.value2"
                          placeholder="层号"
                          autocomplete="off"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="5" :offset="1" prop="value3">
                      <el-form-item prop="value3">
                        <el-input
                          type="lockBytes"
                          v-model.number="roomcard.value3"
                          placeholder="房间编号"
                          autocomplete="off"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="5" :offset="1" prop="value4">
                      <el-form-item prop="value4">
                        <el-input
                          type="lockBytes"
                          v-model.number="roomcard.value4"
                          placeholder="第二房间编号"
                          autocomplete="off"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <div>
                      锁号组成: 栋号2位,层号2位,房间编号2位,第二房间编号2位
                    </div>
                  </el-row>
                  <!-- <el-input type="lockBytes" v-model="roomcard.lockBytes" autocomplete="off"></el-input> -->
                </el-form-item>
                <el-form-item label="退房时间" prop="outtime">
                  <!-- <el-input v-model.number="roomcard.outtime"></el-input> -->
                  <!-- yyyy-MM-dd HH:mm -->
                  <el-date-picker
                    v-model="roomcard.outtime"
                    format="yyyy-MM-dd HH:mm"
                    type="datetime"
                    placeholder="选择日期时间"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item label="客人代" prop="dai">
                  <el-input
                    v-model.number="roomcard.dai"
                    placeholder="0-255"
                  ></el-input>
                </el-form-item>
                <el-form-item label="是否可开反锁" prop="lLock">
                  <el-radio-group v-model.number="roomcard.lLock">
                    <el-radio :label="1">可以开反锁</el-radio>
                    <el-radio :label="0">不能开反锁</el-radio>
                  </el-radio-group>
                </el-form-item>
                <!-- <el-form-item>
			      <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
			      <el-button @click="resetForm('ruleForm')">重置</el-button>
			    </el-form-item> -->
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 制门卡弹窗 -->

    <!-- 会员开卡的表格 -->
    <el-dialog
      :title="ruleForm.isMember ? '查看会员' : '会员开卡'"
      class="roomBox"
      :destroy-on-close="true"
      :before-close="handleClose"
      :visible.sync="isOpen"
      width="830px"
      append-to-body
    >
      <membership-card
        ref="memberBox"
        @offInquiry="close_page"
      ></membership-card>
    </el-dialog>
    <el-dialog
      title="会员详情"
      :visible.sync="isConsumotion"
      class="MemberBOX"
      append-to-body
      width="800px"
      :destroy-on-close="true"
      :before-close="handleClose"
    >
      <MembershipDetailsVue ref="SHIPBOX"></MembershipDetailsVue>
    </el-dialog>
    <!-- 会员开卡的表格 -->
    <!-- 自定义票据 -->
    <el-dialog
      title="自定义小票"
      class="roomBox"
      :before-close="handleClose"
      :visible.sync="isBill"
      width="500px"
      append-to-body
      :destroy-on-close="true"
    >
      <custom-bill @customBill="customBills"></custom-bill>
    </el-dialog>

    <!-- 自定义票据 -->
    <!-- 打印票据弹窗 -->
    <el-dialog
      title="打印票据"
      class="roomBox"
      :destroy-on-close="true"
      :before-close="handleClose"
      :visible.sync="isPrint"
      width="300px"
      append-to-body
    >
      <print-bill @PrintBosss="PrintBosss" ref="PrintBillbOX"></print-bill>
    </el-dialog>

    <!-- 打印票据弹窗 -->
    <!-- 更换房间 -->
    <el-dialog
      title="更换房间"
      class="ChangeRoomBox"
      :destroy-on-close="true"
      :before-close="handleClose"
      :visible.sync="isChange"
      width="825px"
      append-to-body
    >
      <change-room
        ref="ChangeRoomBox"
        :id="ruleForm"
        @offChangeRoom="offChangeRoom"
      ></change-room>
    </el-dialog>

    <!-- 更换房间 -->
    <!-- 商品购买 -->
    <el-dialog
      title="吧台点餐"
      class="BarCoundBox"
      :before-close="handleClose"
      :visible.sync="isCounter"
      width="1200px"
      append-to-body
      :destroy-on-close="true"
    >
      <purchase ref="PURCHBOX" @purchase="purchase" @GETBAR="GETBAR"></purchase>
    </el-dialog>
    <!-- 商品购买 -->
    <!-- 吧台点餐 -->
    <el-dialog
      title="商品购买"
      class="BarCoundBox"
      :before-close="handleClose"
      :visible.sync="isPurchase"
      width="1200px"
      append-to-body
      :destroy-on-close="true"
    >
      <bar-counter
        @barCounters="barCounter"
        ref="BarCounterBox"
        @GETBAR="GETBAR"
      ></bar-counter>
    </el-dialog>

    <!-- 吧台点餐 -->
    <!-- 续住弹窗 -->
    <el-dialog
      title="续住"
      class="roomBox"
      :destroy-on-close="true"
      :before-close="handleClose"
      :visible.sync="isStayOver"
      width="600px"
      append-to-body
    >
      <stay-over
        ref="stayBox"
        @offInquiry="offInquiry"
        @Stay_over="Stay_over"
        :ruleForm="ruleForm"
      ></stay-over>
    </el-dialog>

    <!-- 续住弹窗 -->

    <issuean-invoice
      v-if="isIssue"
      @issueAninvoice="issueAninvoice"
    ></issuean-invoice>
    <!--财务信息页面之添加入账弹窗  -->
    <el-dialog
      title="添加入账"
      :visible.sync="isAddenter"
      width="500px"
      class="addMember"
      append-to-body
      :destroy-on-close="true"
      :before-close="handleClose"
    >
      <addenter ref="EnterBox" @AddenterOff="AddenterOff"></addenter>
    </el-dialog>

    <el-dialog
      title="制房卡"
      :visible.sync="newisDoorstop"
      center
      width="75%"
      append-to-body
    >
      <make-card @makeclose="makecardclose" ref="makecard"></make-card>
    </el-dialog>
    <el-dialog
      title="制房卡"
      :visible.sync="newmakecardshow"
      center
      width="75%"
      append-to-body
    >
      <new-mack-card
        @makeclose="makecardclose"
        ref="newmakecard"
      ></new-mack-card>
    </el-dialog>
  </div>
</template>

<script>
import MembershipCard from "../../../../components/MembershipCard.vue";
import CustomBill from "./CustomBill.vue";
import ChangeRoom from "./ChangeRoom.vue";
import StayOver from "./StayOver.vue";
import FinanceMessage from "./components/FinanceMessage/index.vue";
import PrintBill from "./PrintBill.vue";
import InvoiceMessage from "./components/InvoiceMessage/InvoiceMessage.vue";
import IssueanInvoice from "./components/InvoiceMessage/IssueAnInvoice.vue";
import OperationLog from "./components/OperationLog.vue";
import BarCounter from "./BarCounter.vue";
import Purchase from "./Purchase.vue";
import { order_baseOrder_restore } from "_api/housingSystem";
import { BargainingUnitAll } from "_api/advanceOrder";
import {
  Getbargaining,
  BatchUpdate,
  userInfoGet,
  BookingDetails,
  orderFromList,
  EditdataStatistics,
} from "_api/orderFrom";
import { getRoomOrderTimeConfiguration } from "_api/systemSetting";
import Addenter from "./components/FinanceMessage/Addenter.vue";
import MembershipDetailsVue from "@/views/Member/MemberComponents/MembershipDetails.vue";
import axios from "axios";
import MakeCard from "../../components/MakeCard.vue";
import NewMackCard from "../../components/newMackCard.vue";
import { gethotelinfo } from "_api/index";

export default {
  name: "orderInformation",
  components: {
    MembershipCard,
    CustomBill,
    ChangeRoom,
    StayOver,
    FinanceMessage,
    PrintBill,
    InvoiceMessage,
    IssueanInvoice,
    OperationLog,
    BarCounter,
    Purchase,
    Addenter,
    MembershipDetailsVue,
    MakeCard,
    NewMackCard,
  },
  props: ["orderFlow"],
  data() {
    return {
      li_active: 0,
      tab_active: 1,
      tab_sideba: -1,
      text: "",
      textarea: "",
      value: "",
      isConsumotion: false, //会员详情的弹窗
      isTable: true, //控制制门卡弹窗的显示隐藏
      isStayOver: false, //控制续住弹窗的显示隐藏
      isChange: false, //控制更换房间弹窗的显示隐藏
      isBill: false, //控制自定义票据弹窗的显示隐藏
      isOpen: false, //控制会员开卡弹窗的显示隐藏
      isFinance: false, //控制控制财务信息弹窗的显示隐藏
      isInvoice: false, //控制发票信息弹窗的显示隐藏
      isOperation: false, //控制操作日志弹窗的显示隐藏
      isOrder: true, //控制订单信息弹窗的显示隐藏
      isPrint: false, //控制打印票据弹窗的显示隐藏
      isIssue: false, //控制发票信息弹窗之发票开具弹窗的显示隐藏
      isCounter: false, //控制吧台点餐弹窗的显示隐藏
      isPurchase: false, //控制商品购买弹窗的显示隐藏
      isDoorstop: false, //控制制門卡弹窗的显示隐藏
      newisDoorstop: false, //新的控制制門卡弹窗的显示隐藏
      isMessage: true, //点击住客信息的编辑按钮切换输入框的禁止输入
      musiclist: false,
      isTenantRight: true,
      countervalue1: [], //就餐时间
      startNewDate: "",
      arrivalTimes: "", //当前时间
      isAddenter: false,
      isaddGuest: false, //新增客户的按钮
      isaddGueed: false, //设置主客的按钮

      ruleForm: {
        housePriceName: null,
        housePriceCodeNumber: null,
        createdBy: null,
        echoMap: {
          createdBy: null,
        },
      },
      UpdateFromss: {},
      redio: "身份证",
      tableData: [],
      bargaininged: [],
      ids: null, //订单id或者是房间id
      roomIds: null, //房间id
      roomId: null,
      connectRoomId: null, //联房id
      AccompanyingGuests: [], //同行人员数据
      nameArr: [], //客户姓名集合
      PlateFrom: [], //联房的数据
      isAccompanying: false,
      AccompanyingFrom: {},
      pricessNumber: 0,
      HousePriceCode: [...this.$store.state.base.HousePriceCode], //所有房价码信息
      lengths: 0,
      reserveNums: null,
      reserveNUM: null,
      member: {
        memberGradeName: "",
        number: "",
      },
      guestInformation: {},
      currentRoom: null,
      rules: {
        mobile: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern:
              /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        idCard: [
          { required: true, message: "请输入身份证号码", trigger: "blur" },
          {
            pattern:
              /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
            message: "请输入正确的身份证号码",
            trigger: "blur",
          },
        ],
        name: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          {
            pattern:
              /^(([a-zA-Z+\.?\·?a-zA-Z+]{2,30}$)|([\u4e00-\u9fa5+\·?\u4e00-\u9fa5+]{2,30}$))/,
            message: "请输入正确的姓名",
            trigger: "blur",
          },
        ],
      },
      cardinfo: {
        cardType: undefined,
        cardTypeName: undefined,
        cardHexStr: undefined,
      },
      roomcard: {
        sign: undefined,
        cardNo: undefined,
        value1: undefined,
        value2: undefined,
        value3: undefined,
        value4: undefined,
        outtime: undefined, //退房时间
        dai: undefined,
        lLock: 1, //可以开反锁
        cardHexStr: undefined,
      },
      roomrules: {
        sign: [{ required: true, message: "请输入酒店标识", trigger: "blur" }],
        cardNo: [
          { required: true, message: "请输入卡号", trigger: "blur" },
          {
            min: 0,
            max: 15,
            message: "长度在 0 到 15 个字符",
            trigger: "blur",
          },
        ],
        value1: [
          { required: true, message: "请输入栋号", trigger: "blur" },
          {
            type: "number",
            min: 0,
            max: 99,
            message: "大小在0-99",
            trigger: "blur",
          },
        ],
        value2: [
          { required: true, message: "请输入层号", trigger: "blur" },
          {
            type: "number",
            min: 0,
            max: 99,
            message: "大小在0-99",
            trigger: "blur",
          },
        ],
        value3: [
          { required: true, message: "请输入房间编号", trigger: "blur" },
          {
            type: "number",
            min: 0,
            max: 99,
            message: "大小在0-99",
            trigger: "blur",
          },
        ],
        value4: [
          { required: true, message: "请输入第二房间编号", trigger: "blur" },
          {
            type: "number",
            min: 0,
            max: 99,
            message: "大小在0-99",
            trigger: "blur",
          },
        ],
        outtime: [
          { required: true, message: "请输入退房时间", trigger: "blur" },
        ],
        dai: [{ required: true, message: "请输入客人代", trigger: "blur" }],
      },
      path: localStorage.getItem("Path") ? localStorage.getItem("Path") : "",
      roomArrActive: localStorage.getItem("permission")
        ? JSON.parse(localStorage.getItem("permission"))
        : [], //功能权限数据
      menuDataAside: [], //菜单权限配置
      newmakecardshow: false,
    };
  },
  watch: {
    //初始化的时候就监听这个数据
    // tab_active: {
    //   handler(newVal, oldVal) {
    //     console.log(newVal);
    //     this.switchoverTabs(Number(newVal));
    //   },
    //   immediate: true,
    // },
  },
  created() {
    this.createdDataAside();
    this.createdPath();
    // this.enterAffair(this.enter);
    // this.Getbargaininged();
  },
  methods: {
    //初始化对菜单权限进行筛选
    createdDataAside() {
      var menuArr = JSON.parse(localStorage.getItem("DynamicPath"));
      this.menuDataAside.splice(0);
      menuArr.forEach((el) => {
        if (el.name == "房单") {
          el.children.forEach((item) => {
            this.menuDataAside.push(item.name);
          });
        }
      });
      console.log(this.menuDataAside);
    },
    PrintBosss() {
      this.isPrint = false;
    },
    //刷新保持在当前页面的方法
    createdPath() {
      if (this.path == "订单信息") {
        this.switchoverTabs(1);
      } else if (this.path == "财务信息") {
        this.switchoverTabs(2);
      } else if (this.path == "发票信息") {
        this.switchoverTabs(3);
      } else if (this.path == "操作日志") {
        this.switchoverTabs(4);
      } else {
        // this.addClass(1);
      }
    },
    //协议单位变化触发的联动
    ChangeBargaininged() {
      this.bargaininged.forEach((el) => {
        if (el.id == this.ruleForm.agreementUnitId) {
          // this.ruleForm.housePriceId = el.housePriceCodeName;
          this.ruleForm.housePriceName = el.housePriceCodeName;
          // this.ruleForm.clientType = "2";
          this.ruleForm.agreementUnitId = el.id;
          this.ruleForm.agreementUnitName = el.name;
          // el.housePriceCodeList.forEach((it) => {
          //   if (it.houseTypeId == this.ruleForm.houseTypeId) {
          //     this.ruleForm.roomRealityPrice = it.weekendPrice;
          //   }
          // });
          this.getDiscount();
        }
      });
    },
    //读取身份证的信息的接口
    ReadingDataButton(index) {
      axios({
        method: "POST",
        url: "http://127.0.0.1:19196/readcard&t=0.5676041095624611",
        headers: {
          "Sec-Fetch-Site": "same-origin",
        },
      }).then((res) => {
        console.log(res);
        console.log(index);
        this.AccompanyingGuests[parseFloat(index)].name = res.data.partyName;
        // this.userinfo[parseFloatparseFloatparseFloat(index)].birthday = res.data.bornDay
        this.AccompanyingGuests[parseFloat(index)].gender =
          res.data.gender == "男" ? "0" : "1";
        this.AccompanyingGuests[parseFloat(index)].idCard = res.data.certNumber;
        this.idCardButton();
      });
    },
    //身份证值变动时触发
    idCardButton() {
      this.AccompanyingGuests[this.li_active].idCard;
	  if(this.AccompanyingGuests[this.li_active].idCard){
	    // result = str.replace(/(^\s+)|(\s+$)/g,""); 去除字符串前后空格
	    this.AccompanyingGuests[this.li_active].idCard = this.AccompanyingGuests[this.li_active].idCard.replace(/\s/g,""); // 去除字符串全部空格
	  }
      let birthday = "";
      if (
        this.AccompanyingGuests[this.li_active].idCard != null &&
        this.AccompanyingGuests[this.li_active].idCard != ""
      ) {
        if (this.AccompanyingGuests[this.li_active].idCard.length == 15) {
          birthday =
            "19" + this.AccompanyingGuests[this.li_active].idCard.substr(6, 6);
          this.AccompanyingGuests[this.li_active].birthday = new Date(birthday);
        } else if (
          this.AccompanyingGuests[this.li_active].idCard.length == 18
        ) {
          birthday = this.AccompanyingGuests[this.li_active].idCard.substr(
            6,
            8
          );
          this.AccompanyingGuests[this.li_active].birthday = new Date(birthday);
        }
        birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-");

        this.AccompanyingGuests[this.li_active].birthday = new Date(birthday);
      }

      var date1 = this.AccompanyingGuests[this.li_active].birthday;
      var sign2 = ":";
      var year = date1.getFullYear(); // 年
      var month = date1.getMonth() + 1; // 月
      var day = date1.getDate(); // 日
      var hour = date1.getHours(); // 时
      var minutes = date1.getMinutes(); // 分
      var seconds = date1.getSeconds(); //秒
      // 给一位数的数据前面加 “0”
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (day >= 0 && day <= 9) {
        day = "0" + day;
      }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }
      this.AccompanyingGuests[this.li_active].birthday =
        year + "-" + month + "-" + day;
      // return birthday;
    },

    //计算折扣率discount
    getDiscount() {
      if (
        parseFloat(
          (
            parseFloat(this.ruleForm.roomRealityPrice) /
            parseFloat(this.ruleForm.roomPrice)
          ).toFixed(2)
        )
      ) {
        this.ruleForm.discount =
          parseFloat(
            (
              parseFloat(this.ruleForm.roomRealityPrice) /
              parseFloat(this.ruleForm.roomPrice)
            ).toFixed(2)
          ) *
            100 +
          "%";
      } else {
        this.ruleForm.discount = "0%";
      }

      console.log(this.ruleForm);
    },
    //折扣率变动后实际房价变动str.substr(str.length - 1, 1)

    getRoomRealityPrice() {
      var arr = this.ruleForm.discount.split("");
      let index = arr.indexOf("%");
      if (arr.indexOf("%") != -1) {
        let str = this.ruleForm.discount.substr(0, arr.indexOf("%"));
        console.log(str);
        str = parseFloat(str) / 100;
        this.ruleForm.roomRealityPrice = parseFloat(
          str * parseFloat(this.ruleForm.roomPrice)
        );
      } else {
        let str = parseFloat(this.ruleForm.discount);
        if (parseFloat(str * parseFloat(this.ruleForm.roomPrice))) {
          this.ruleForm.roomRealityPrice = parseFloat(
            str * parseFloat(this.ruleForm.roomPrice)
          );
        } else {
          this.ruleForm.roomRealityPrice = 0;
        }
        this.getDiscount();
      }
      // console.log(index);
      // let str = this.ruleForm.discount.substr(0, 2)
      // console.log(str);
      // str = parseFloat(str)/100
      // this.ruleForm.roomRealityPrice = (str * parseFloat(this.ruleForm.roomPrice))+ '.00'
    },
    //是否恢复为入住
    renewButton(num) {
      if (num == 0) {
        this.$message({
          type: "error",
          message: "取消恢复操作",
        });
      } else {
        let time = parseFloat(new Date(this.ruleForm.outRoomTime).getTime());
        let newTime = parseFloat(new Date().getTime());
        let dayTime = 1 * 24 * 60 * 60 * 1000;
        let outRoomTime = this.ruleForm.updatedTime;
        let id = localStorage.getItem("classes");
        let classId = this.ruleForm.settleAccountsClasses.split("/");
        console.log(id, classId);
        console.log(this.ruleForm.settleAccountsClasses);
        console.log(newTime, "/", time);
        console.log(newTime - time < dayTime);
        console.log(id == classId[1]);
        if (newTime - time < dayTime && id == classId[1]) {
          console.log("adasdasdadas");
          this.order_baseOrder_restoreed();
        } else {
          this.$message({
            type: "error",
            message: "退房时间超过规定时间或不是同一班次，无法恢复为在住状态",
          });
        }
      }
    },
    //房单恢复为在住接口
    order_baseOrder_restoreed() {
      this.getNowDate();
      let outRoomTime = this.ruleForm.updatedTime;
      const params = {
        ...this.ruleForm,
        agreementUnitId: null,
        whetherRely: false,
        breakfast: parseFloat(this.ruleForm.breakfast),
        updatedBy: localStorage.getItem("userClassesId"),
        orderState: true,
        outRoomTime: this.ruleForm.outRoomTime
          ? this.ruleForm.outRoomTime
          : outRoomTime,
        discount: parseFloat(this.ruleForm.discount),
        reserveDays: String(parseFloat(this.ruleForm.reserveDays)),
        orderReserveId:
          this.ruleForm.orderReserveId == "暂无"
            ? null
            : this.ruleForm.orderReserveId,
      };
      order_baseOrder_restore(params).then((res) => {
        if (res.data.code === 0) {
          this.$message({
            type: "success",
            message: "退房恢复在住成功",
          });
		  if(this.ruleForm.connectRoomId){
			  this.orderFromLists({ connectRoomId: this.ruleForm.connectRoomId });
		  }else{
			  this.orderFromLists({ id: this.ruleForm.id });
		  }
        } else {
          this.$message({
            type: "success",
            message: res.data.errorMsg ? res.data.errorMsg : res.data.msg,
          });
        }
      });
    },
    //获取当前时间
    getNowDate() {
      var date1 = new Date();
      var sign2 = ":";
      var year = date1.getFullYear(); // 年
      var month = date1.getMonth() + 1; // 月
      var day = date1.getDate(); // 日
      var hour = date1.getHours(); // 时
      var minutes = date1.getMinutes(); // 分
      var seconds = date1.getSeconds(); //秒
      // 给一位数的数据前面加 “0”
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (day >= 0 && day <= 9) {
        day = "0" + day;
      }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }

      this.ruleForm.updatedTime =
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        sign2 +
        minutes +
        sign2 +
        seconds;
    },
    //吧台点餐和商超购买的自定义事件
    GETBAR() {
      this.isCounter = false;
      this.isPurchase = false;
      // this.orderFromLists({id: this.ids,roomId: this.roomId})
      // this.Getbargaininged();
    },
    //办理入账的自定义事件
    AddenterOff(num) {
      this.isAddenter = false;
      if (this.connectRoomId) {
        this.orderFromLists({ connectRoomId: this.connectRoomId });
      } else {
        this.orderFromLists({
          id: this.ids,
          roomId: this.roomId,
          orderState: 1,
        });
      }
    },
    //对话框关闭时触发
    handleClose(done) {
      done();
      if (this.connectRoomId) {
        this.orderFromLists({ connectRoomId: this.connectRoomId });
      } else {
        this.orderFromLists({
          id: this.ids,
          roomId: this.roomId,
          orderState: 1,
        });
      }
      this.Getbargaininged();
    },

    //备注输入框变化就触发
    inputButtons() {
      this.EditdataStatisticsed();
    },

    //订单更新
    EditdataStatisticsed() {
      this.ruleForm.discount = parseFloat(this.ruleForm.discount);
      this.ruleForm.reserveDays = parseFloat(this.ruleForm.reserveDays);
      this.ruleForm.housePriceId = this.ruleForm.housePriceId;
      this.ruleForm.orderReserveId =
        this.ruleForm.orderReserveId == "暂无"
          ? null
          : this.ruleForm.orderReserveId;
      const params = {
        agreementUnitId: this.ruleForm.agreementUnitId,
        arrivalTime: this.ruleForm.arrivalTime,
        breakfast: parseFloat(this.ruleForm.breakfast),
        clientType: this.ruleForm.clientType,
        discount: this.ruleForm.discount,
        hourLength: this.ruleForm.hourLength,
        housePriceId: this.ruleForm.housePriceId,
        // houseTypeId: this.ruleForm.houseTypeId,
        // houseTypeName: this.ruleForm.echoMap.houseTypeId,
        id: this.ruleForm.id,
        isMasterRoom: this.ruleForm.isMasterRoom,
        orderMobile: this.ruleForm.orderMobile,
        orderReserveId: this.ruleForm.orderReserveId,
        orderState: true,
        orgId: localStorage.getItem("orgid"),
        predepartureTime: this.ruleForm.predepartureTime,
        remark: this.ruleForm.remark,
        reserveDays: this.ruleForm.reserveDays,
        roomPrice: parseFloat(this.ruleForm.roomPrice),
        roomRealityPrice: parseFloat(this.ruleForm.roomRealityPrice),
        settlementType: this.ruleForm.settlementType,
        state: true,
        otaName: this.ruleForm.otaName ? this.ruleForm.otaName : "",
      };

      EditdataStatistics(params)
        .then((res) => {
          if (res.data.code === 0) {
            this.$message({
              type: "success",
              message: "信息更新成功",
            });
            if (this.connectRoomId) {
              this.orderFromLists({ connectRoomId: this.connectRoomId });
            } else {
              this.orderFromLists({
                id: this.ids,
                roomId: this.roomId,
                orderState: 1,
              });
            }

            this.isTenantRight = true;
          } else {
            this.$message({
              type: "error",
              message: res.data.msg ? res.data.msg : "系统繁忙",
            });
          }
        })
        .catch((err) => {
          if (this.connectRoomId) {
            this.orderFromLists({ connectRoomId: this.connectRoomId });
          } else {
            this.orderFromLists({
              id: this.ids,
              roomId: this.roomId,
              orderState: 1,
            });
          }

          this.isTenantRight = true;
        });
    },
    //点击编辑和保存订单更新
    TenantRightButton(num) {
      if (num == 1) {
        //编辑
        this.isTenantRight = false;
        this.UpdateFromss = { ...this.ruleForm };
      } else if (num == 2) {
        //保存
        this.EditdataStatisticsed();
      }
    },
    //计算预祝天数
    getDaysBetween(date1, date2) {
      var startDate = Date.parse(date1);
      var endDate = Date.parse(date2);

      var days = parseFloat(endDate - startDate) / (1 * 24 * 60 * 60 * 1000);
      return (this.ruleForm.reserveDays = Math.ceil(days) + "天");
    },
    //预定转入的入账传参事件
    EnterBoxData(row) {
      this.$store.commit("base/SETAdvanceAddEnter", row);
    },
    //根据房间id和订单id找到对应订单信息
    //分页列表查询
    orderFromLists(model = {}) {
      console.log("dsa");
      // if(model.roomId){
      //   model.orderState = 1
      // }

      var params = {
        current: 1,
        size: 10000,
        model: {
          ...model,
        },
      };
      orderFromList(params).then((res) => {
        if (res.data.code === 0) {
          this.EnterBoxData(res.data.data.records);

          this.PlateFrom.splice(0);

          var nhs = 0;
          res.data.data.records.forEach((es) => {
            if (es.connectRoomId) {
              nhs = 1;
            }
          });
          if ((nhs = 1)) {
            this.PlateFrom = res.data.data.records;
            res.data.data.records.forEach((el, index) => {
              if (el.isMasterRoom) {
                this.PlateFrom.splice(index, 1);
                this.PlateFrom.unshift(el);
                this.connectRoomId = el.connectRoomId;
              }
            });
          }

          console.log(this.PlateFrom);
		  // console.log('赋值判断条件',this.roomId)
          res.data.data.records.forEach((el) => {
            if (el.roomId == this.roomId) {
              if (model.connectRoomId) {
                if (
                  el.roomId == this.roomId &&
                  el.connectRoomId == model.connectRoomId
                ) {
                  this.ruleForm = el;
				  // console.log('赋值1',el)
				  this.ruleForm.breakfast = el.breakfast + '份'
				  this.$store.commit("user/SetOrderId", el);
				  this.AccompanyingGuests.splice(0);
                }
              } else if (model.id) {
                if (el.roomId == this.roomId && el.id == model.id) {
                  this.ruleForm = el;
				  // console.log('赋值2',el)
				  this.ruleForm.breakfast = el.breakfast + '份'
				  this.$store.commit("user/SetOrderId", el);
				  this.AccompanyingGuests.splice(0);
                }
              } else if (model.roomId) {
                if (el.roomId == this.roomId) {
                  this.ruleForm = el;
				  // console.log('赋值3',el)
				  this.ruleForm.breakfast = el.breakfast + '份'
				  this.$store.commit("user/SetOrderId", el);
				  this.AccompanyingGuests.splice(0);
                }
              }
              // this.ruleForm = el;
              // console.log(el);
              // this.ruleForm.breakfast = el.breakfast + '份'
              // this.$store.commit("user/SetOrderId", el);
              // this.AccompanyingGuests.splice(0);

              console.log(this.ruleForm);
              console.log(el);
              // this.$nextTick(() => {
              //     this.$refs.EnterBox.currentRoom = el
              //     console.log(this.$refs.EnterBox.currentRoom,'是联房');
              //     console.log(res.data.data.records);
              //   });
            }
          });
          console.log(this.ruleForm);
          //  this.ruleForm = this.$store.state.user.orderFrom
          // this.ruleForm.gender = this.ruleForm.gender?'1':'0'
          this.ruleForm.idCard = this.ruleForm.idCard
            ? this.ruleForm.idCard != "0"
              ? this.ruleForm.idCard
              : ""
            : "";
          if (this.ruleForm.companions) {
            this.ruleForm.companions.forEach((ic) => {
              ic.gender = ic.gender ? "1" : "0";
              ic.idCard = ic.idCard ? (ic.idCard != "0" ? ic.idCard : "") : "";
            });
          }

          // if (this.ruleForm.agreementUnitId) {
          //   this.ruleForm.clientType = "2";
          // } else if (this.ruleForm.isMember || this.ruleForm.clientType != "0") {
          //   this.ruleForm.clientType = "1";
          // } else {
          //   this.ruleForm.clientType = "0";
          // }

          console.log(this.PlateFrom);
          console.log(this.ruleForm);
          this.ruleForm.housePriceName = null;
          // this.ruleForm.gender = this.ruleForm.gender? "1" : "0";
          this.ruleForm.discount = this.ruleForm.discount
            ? parseFloat(this.ruleForm.discount) + "%"
            : 0 + "%";
          // if (this.ruleForm.housePriceId != "0") {
          //   console.log(this.HousePriceCode);
          //   // this.HousePriceCode.forEach((el) => {
          //   //   if (el.id == this.ruleForm.housePriceId) {
          //   //     el.correspondingPrice.forEach((it) => {
          //   //       if (it.houseTypeId == this.ruleForm.houseTypeId) {
          //   //         this.ruleForm.roomRealityPrice = it.weekendPrice;
          //   //         console.log('----------------------------------------------------');
          //   //         this.ruleForm.housePriceName = el.name;
          //   //       }
          //   //     });
          //   //   }
          //   // });
          //   if(this.ruleForm.agreementUnitId) {
          //     this.bargaininged.forEach(el => {
          //     if(el.id == this.ruleForm.agreementUnitId) {
          //       //判断协议单位中有没有放假吗优惠
          //       if(el.housePriceCodeList && el.housePriceCodeList.length > 0) {

          //       }
          //     }
          //   })
          //   }

          // }
          if (!this.ruleForm.orderReserveId) {
            this.ruleForm.orderReserveId = "暂无";
          }

          // this.getDaysBetween(
          //   this.ruleForm.arrivalTime,
          //   this.ruleForm.predepartureTime
          // );
          this.Getbargaininged();
          // this.mobileInput(this.ruleForm.mobile)
          this.musiclist = true;

          // this.userInfoGeted(this.ruleForm.mobile);
          console.log('出现问题部分',this.ruleForm);
          this.userInfoGeted(this.ruleForm.mobile,1)
          this.roomDataGet(this.ruleForm);
          // this.ruleForm = this.$store.state.user.orderFrom
        }
      });
    },
    financeBoxs(num) {
      this.$emit("offInquiry", [false]);
    },
    //查看协议单位的接口
    Getbargaininged() {
      Getbargaining().then((res) => {
        if (res.data.code === 0) {
          this.bargaininged = res.data.data;
          this.ChangeBargaininged();
          console.log(this.bargaininged);
          // this.ruleForm.agreementUnitName = el.name;
          // this.ruleForm.housePriceCodeNumber = el.housePriceCodeNumber;
          // if(el.housePriceCodeList){
          //   el.housePriceCodeList.forEach((is) => {
          //   if (is.houseTypeId == this.ruleForm.houseTypeId) {
          //     this.ruleForm.roomRealityPrice = is.normalPrice;
          //     this.ruleForm.housePriceId = is.id;
          //     this.ruleForm.housePriceName = is.name
          //   }
          // });
          // }
        }
      });
    },
    //根据手机号查询数据
    userInfoGeted(num, model) {
      const params = {
        mobile: num,
      };
      userInfoGet(params)
        .then((res) => {
          if (res.data.code === 0) {
            if (model) {
              this.member = {
                memberGradeName: res.data.data.memberGradeName,
                number: res.data.data.number,
                memberId: res.data.data.id,
              };

              this.AccompanyingGuests.forEach((el, index) => {
                if (
                  el.mobile === this.AccompanyingGuests[this.li_active].mobile
                ) {
                  el.number = res.data.data.number; //会员号
                  el.memberGradeName = res.data.data.memberGradeName; //会员等级
                  el.memberId = res.data.data.id;
                }
              });
              //房价码
              if (this.ruleForm.clientType == 1) {
                // console.log('啊大大撒大大大大');
                if (res.data.data.housePriceCodes) {
                  // console.log('啊大大撒大大大大');
                  res.data.data.housePriceCodes.forEach((el, index) => {
                    if (el.houseTypeId == this.ruleForm.houseTypeId) {
                      // this.ruleForm.roomRealityPrice = el.normalPrice; //暂时不考虑周末价格
                      this.ruleForm.housePriceName = el.name;
                      // this.ruleForm.housePriceId = el.id;
                      this.getDiscount();
                      console.log(this.ruleForm);
                    }
                  });
                }
              }
            } else {
              // this.AccompanyingGuests[this.li_active].mobile
              this.AccompanyingGuests.forEach((ic) => {
                if (ic.isMaster) {
                  if (num == ic.mobile) {
                    this.member = {
                      memberGradeName: res.data.data.memberGradeName,
                      number: res.data.data.number,
                      memberId: res.data.data.id,
                    };
                  }
                }
              });

              this.AccompanyingGuests.forEach((el, index) => {
                if (
                  el.mobile === this.AccompanyingGuests[this.li_active].mobile
                ) {
                  el.number = res.data.data.number; //会员号
                  el.memberGradeName = res.data.data.memberGradeName; //会员等级
                  el.memberId = res.data.data.id;
                }
              });
            }
          }
        })
        .catch((err) => {
          if (err.errorMsg != "会员信息不存在" || err.msg != "会员信息不存在") {
          }
          this.AccompanyingGuests[this.li_active].memberGradeName = "";
          this.AccompanyingGuests[this.li_active].number = "";
          this.AccompanyingGuests[this.li_active].memberId = "";
          if (this.li_active == 0) {
            this.member = {
              memberGradeName: null,
              number: null,
              memberId: null,
            };
          }
          // this.AccompanyingGuests.forEach((el,index) => {
          //     if (el.mobile === this.AccompanyingGuests[0].mobile) {
          //       el.number = this.member.number; //会员号
          //       el.memberGradeName = this.member.memberGradeName; //会员等级
          //     }
          //   });
        });
    },
    //将主客和随行人员信息进行处理
    roomDataGet(row) {
      // console.log(this.$store.state.user.orderFrom);
      // var row = this.$store.state.user.orderFrom
      this.AccompanyingGuests.splice(0);
      var arrFrom = [];
      if (!row.companions) {
        arrFrom.push({
          createdBy: row.createdBy,
          createdTime: row.createdTime,
          birthday: row.birthday, //生日
          gender: row.gender ? "1" : "0", //性别 0 男 1 女
          idCard: row.idCard ? (row.idCard != 0 ? row.idCard : "") : "", //身份证号码
          isMaster: true, //是否是主客
          typeIdCard: "1",
          idType: row.idType ? row.idType : "1",
          id: row.customerInformationId,
          mobile: row.mobile, //手机号码
          name: row.name, //顾客姓名
          orderBaseId: row.id, //订单id
          orgId: localStorage.getItem("orgid"),
          number: null, //会员号
          memberGradeName: null, //会员等级
          // customerId:
        });
      } else {
        arrFrom.push({
          createdBy: row.createdBy,
          createdTime: row.createdTime,
          birthday: row.birthday, //生日
          gender: row.gender ? "1" : "0", //性别 0 男 1 女
          idCard: row.idCard, //身份证号码
          isMaster: true, //是否是主客
          typeIdCard: "1",
          idType: row.idType ? row.idType : "1",
          id: row.customerInformationId,
          mobile: row.mobile, //手机号码
          name: row.name, //顾客姓名
          orderBaseId: row.id, //订单id
          orgId: localStorage.getItem("orgid"),
          number: null, //会员号
          memberGradeName: null, //会员等级
          // customerId:
        });
        row.companions.forEach((el) => {
          arrFrom.push({
            ...el,
            gender: el.gender ? "1" : "0",
            idCard: el.idCard ? (el.idCard != "0" ? el.idCard : "") : "", //身份证号码
            isMaster: false,
            typeIdCard: "1",
            orderBaseId: this.ruleForm.id,
          });
        });
      }
      this.AccompanyingGuests.push(...arrFrom);
      console.log(this.ruleForm);
      console.log(this.AccompanyingGuests);
    },
    //根据订单号查询预订单信息
    //点击住客信息的编辑按钮
    UpdateMessage(num) {
      this.isMessage = !this.isMessage;
      if (num === 2) {
        //保存
        // this.AccompanyingGuests.push({
        //   birthday: this.ruleForm.birthday, //生日
        //   createdBy: localStorage.getItem("classesId"), //创建人ID
        //   gender: this.ruleForm.gender === '0'?false: true,//性别 0 男 1 女
        //   createdTime: this.ruleForm.arrivalTime, //创建时间
        //   idCard: this.ruleForm.idCard, //身份证号
        //   // memberId:0,//会员id
        //   isMaster : false,
        //   mobile: this.ruleForm.mobile, //	手机号码
        //   name: this.ruleForm.name, //顾客姓名
        //   createdBy: this.ruleForm.createdBy,
        //   orgId: localStorage.getItem('orgid'),
        //   orderBaseId: this.$store.state.user.orderFrom.id,
        // })
        this.isaddGueed = false;
        this.isaddGuest = false;
        var num = null;
        this.AccompanyingGuests.forEach((el, index) => {
          if (
            this.AccompanyingGuests[index].name &&
            this.AccompanyingGuests[index].mobile &&
            this.AccompanyingGuests[index].gender
          ) {
            num = 0;
          } else {
            num = 2;

            return false;
          }
        });
        if (num == 2) {
          this.$message({
            type: "error",
            message: "请把所有客户的信息填完整",
          });
        } else {
          this.BatchUpdateed();
        }
      }
    },
    enterAffair(newVal) {
      this.ruleForm.gender = this.ruleForm.gender ? "1" : "0";
      if (newVal) {
        this.isEnter = true;
      } else {
        this.isEnter = false;
      }
    },

    //有手机号带出会员等级和会员号
    // userInfoGeted(num) {
    //   const params = {
    //     mobile: this.ruleForm.mobile,
    //     // orgId: localStorage.getItem("orgid"),
    //   };
    //   userInfoGet(params).then((res) => {
    //     if (res.data.code === 0) {
    //       console.log(this.AccompanyingGuests);
    //       this.AccompanyingGuests[this.li_active] = {
    //         memberGradeName: res.data.data.memberGradeName,
    //         number: res.data.data.number,
    //       };
    //     }
    //   });
    // },
    //点击新增客人触发事件
    addGuest(num) {
      if (num === 1) {
        //设置主客
        console.log("12222222222222222222");
        var num = null;
        this.AccompanyingGuests.forEach((el, index) => {
          if (
            this.AccompanyingGuests[index].name &&
            this.AccompanyingGuests[index].mobile &&
            this.AccompanyingGuests[index].gender
          ) {
            num = 0;
          } else {
            num = 2;

            return false;
          }
        });
        if (num == 2) {
          this.$message({
            type: "success",
            message: "请把所有客户的信息填完整",
          });
        } else {
          this.AccompanyingGuests.forEach((el, index) => {
            el.isMaster = false;
            this.isaddGueed = true;
            this.isaddGuest = false;

            // el.orderBaseId = null
          });
          this.AccompanyingGuests[this.li_active].isMaster = true;
          let arr = this.AccompanyingGuests[this.li_active];
          this.AccompanyingGuests.splice(this.li_active, 1);
          this.AccompanyingGuests.unshift(arr);
          this.li_active = 0;
          // this.AccompanyingGuests[this.li_active].orderBaseId = this.ruleForm.id
          if (!this.isMessage) {
            this.$message({
              type: "success",
              message: "主客设置成功,请点击保存更新",
            });
          } else {
            var num = null;
            this.AccompanyingGuests.forEach((el, index) => {
              if (
                this.AccompanyingGuests[index].name &&
                this.AccompanyingGuests[index].mobile &&
                this.AccompanyingGuests[index].gender
              ) {
                num = 0;
              } else {
                num = 2;

                return false;
              }
            });
          }

          if (num == 2) {
            this.$message({
              type: "error",
              message: "请把所有客户的信息填完整",
            });
          } else {
            this.BatchUpdateed();
          }
        }
      } else {
        //添加同行人
        console.log("33333333333333333333");
        if (this.AccompanyingGuests[this.AccompanyingGuests.length - 1].name) {
          this.isAccompanying = false;
          this.isMessage = false;
          this.isaddGueed = false;
          this.isaddGuest = true;
          this.AccompanyingGuests.push({
            createdBy: this.ruleForm.createdBy,
            createdTime: this.ruleForm.createdTime,
            birthday: null, //生日
            typeIdCard: "1",
            idType: "1",
            orderBaseId: this.ruleForm.id,
            isMaster: false, //是否是主客
            gender: "0", //性别 0 男 1 女
            idCard: null, //身份证号码
            mobile: null, //手机号码
            name: null, //顾客姓名
            orderId: this.ruleForm.id, //订单id
            orgId: localStorage.getItem("orgid"),
            number: null, //会员号
            memberGradeName: null, //会员等级
          });
          this.li_active = this.AccompanyingGuests.length - 1;
        } else {
          this.$message({
            type: "error",
            message: "请输入用户信息",
          });
        }
        // this.nameArr.push(this.ruleForm.name)
        // }else{
        //   this.$message({
        //     type: 'error',
        //     message: '请输入用户信息'
        //   })
        // }
      }
    },
    //点击客户标签上的图标删除客户信息
    DeteleAreUser(index) {
      this.AccompanyingGuests.splice(index, 1);
      this.li_active = this.AccompanyingGuests.length - 1;
    },
    //手机号变化后触发
    mobileInput(num) {
	  if(this.AccompanyingGuests[this.li_active].mobile){
	    this.AccompanyingGuests[this.li_active].mobile = this.AccompanyingGuests[this.li_active].mobile.replace(/\s/g,""); // 去除字符串全部空格
	  }
	  if(num){
		num = num.replace(/\s/g,""); // 去除字符串全部空格
	  }
      this.userInfoGeted(num);
      // this.Getbargaininged();
      // if(this.li_active != 0) {
      //   this.AccompanyingGuests[this.li_active].id = null;
      // }
    },
	//姓名处理
	nameChange(){
		if(this.AccompanyingGuests[this.li_active].name){
		  this.AccompanyingGuests[this.li_active].name = this.AccompanyingGuests[this.li_active].name.replace(/\s/g,""); // 去除字符串全部空格
		}
	},
    //客户信息/批量更新
    BatchUpdateed() {
      // var arr = this.AccompanyingGuests;
      var arr = JSON.parse(JSON.stringify(this.AccompanyingGuests));
      arr.forEach((el, index) => {
        el.gender = el.gender == "0" ? false : true;
        el.idType = el.typeIdCard;
      });

      const params = [
        // {
        //   birthday: this.ruleForm.birthday,//生日
        //   gender: this.ruleForm.gender === '0'?false: true,//性别 0 男 1 女
        //   idCard: this.ruleForm.idCard,//身份证号码
        //   isMaster: true,//是否是主客
        //   mobile: this.ruleForm.mobile,//手机号码
        //   name: this.ruleForm.name,//顾客姓名
        //   orderBaseId: this.ruleForm.id,//订单id
        //   orgId: localStorage.getItem('orgid')
        // },

        ...arr,
      ];
      console.log(params);
      BatchUpdate(params).then((res) => {
        if (res.data.code === 0) {
          this.$message({
            type: "success",
            message: "客户信息更新成功",
          });

          this.isMessage = true;
          this.li_active = 0;
		  //更新房单住客信息
		  this.updateOrderInfo();
          if (this.connectRoomId) {
            this.orderFromLists({ connectRoomId: this.connectRoomId });
          } else {
            this.orderFromLists({ id: this.ruleForm.id, orderState: 1 });
          }
        } else {
          this.$message({
            type: "error",
            message: res.data.msg,
          });
          this.AccompanyingGuests = [];
          this.AccompanyingFrom = {};
        }
      });
    },
    //点击协议单位旁的图片触发事件
    checkBargaining() {
      console.log("sdada");
      this.Getbargaininged();
    },
    //商品购买的自定义事件
    purchase(e) {
      this.isPurchase = e[0];
      this.isTable = true;
      this.switchoverTabs(8);
    },
    //点击制卡弹窗右上角的关闭图标
    doorstopOff() {
      this.isDoorstop = false;
    },
    //吧台点餐的自定义事件
    barCounter(e) {
      this.handleClose = false;
      this.isTable = true;
      this.switchoverTabs(8);
    },
    //财务信息页面之预授权弹窗的
    offPre(e) {
      if (e[2] === "确认") {
        this.isFinance = true;
        this.switchoverTabs(e[1]);
      } else {
        this.$emit("offInquiry", [false, e[1]]);
      }
    },
    //开具发票弹窗传回来的自定义事件
    issueAninvoice(e) {
      if (e[1] === 3) {
        // 这是点击右上角的图标
        this.isOrder = e[0];
        this.$emit("offInquiry", [false, 3]);
      } else {
        //这是确认提交
        this.isIssue = false;
        this.isTable = true;
        this.isInvoice = true;
        this.switchoverTabs(3);
      }
    },
    //发票信息的自定义事件
    invoiceEvent(e) {
      this.isTable = e[0];
      this.isIssue = true;
    },
    //自定义小票弹窗的自定义事件
    customBills(e) {
      if (e[1] === "预览") {
        this.isBill = e[0];
        this.isPrint = true;
      } else {
        this.$emit("offInquiry", [false, 8]);
      }
    },
    //协议单位查看
    BargainingUnitAlls() {
      BargainingUnitAll().then((res) => {
        if (res.data.code === 0) {
          this.BargainingUnitList = res.data.data;
        } else {
          this.$message(res.data.errorMsg);
        }
      });
    },
    //续住弹窗的自定义事件
    offInquiry(e) {
      if (e[1] === "确定") {
        this.isStayOver = e[0];
        // this.switchoverTabs(8);
        // this.ruleForm.reserveDays = e[2].reserveDays
        // this.ruleForm.predepartureTime = e[2].predepartureTime

        if (this.connectRoomId) {
          this.orderFromLists({ connectRoomId: this.connectRoomId });
        } else {
          this.orderFromLists({
            id: this.ids,
            roomId: this.roomId,
            orderState: 1,
          });
        }

        // this.$store.commit('user/SetOrderId', this.ruleForm)
      } else if (e[1] === "扫码支付") {
        this.isStayOver = e[0];
        // this.switchoverTabs(8);
        if (this.connectRoomId) {
          this.orderFromLists({ connectRoomId: this.connectRoomId });
        } else {
          this.orderFromLists({
            id: this.ids,
            roomId: this.roomId,
            orderState: 1,
          });
        }
      } else if (e[1] === "储蓄卡") {
        this.isStayOver = e[0];
        // this.switchoverTabs(8);
        if (this.connectRoomId) {
          this.orderFromLists({ connectRoomId: this.connectRoomId });
        } else {
          this.orderFromLists({
            id: this.ids,
            roomId: this.roomId,
            orderState: 1,
          });
        }
      } else {
        this.isStayOver = false;
        // this.$emit("offInquiry", [false]);
        if (this.connectRoomId) {
          this.orderFromLists({ connectRoomId: this.connectRoomId });
        } else {
          this.orderFromLists({
            id: this.ids,
            roomId: this.roomId,
            orderState: 1,
          });
        }
      }
    },

    offChangeRoom(num) {
      this.ruleForm.roomRealityPrice = num[2].roomRealityPrice;
      this.ruleForm.discount = num[2].discount;
      this.ruleForm.roomPrice = num[2].roomPrice;
      this.ruleForm.roomName = num[2].roomName;
      this.$store.commit("user/SetOrderId", this.ruleForm);
      this.isChange = false;
      if (this.connectRoomId) {
        this.orderFromLists({ connectRoomId: this.connectRoomId });
      } else {
        this.orderFromLists({
          id: this.ids,
          roomId: this.roomId,
          orderState: 1,
        });
      }
    },

    //续住弹窗的自定义事件
    Stay_over(num) {
      this.isTable = true;
      this.isStayOver = false;
      if (num[1] === "确定") {
        console.log(num[2].reserveDays);
        this.ruleForm.reserveDays = num[2].reserveDays;
        this.ruleForm.predepartureTime = num[2].predepartureTime;
      }
    },
    //备注信息改变触发订单修改接口
    UpdateDate() {
      this.EditdataStatisticsed();
    },
    //点击侧边栏和头部导航切换不同的弹窗
    switchoverTabs(num) {
	  var that =this;
      if (num === 1) {
        //订单信息
        localStorage.setItem("Path", "订单信息");
        this.isDoorstop = false;
        this.isOrder = true;
        this.isFinance = false;
        this.isInvoice = false;
        this.isOperation = false;
        this.tab_active = num;
        // if(this.ruleForm.connectRoomId) {
        //   this.orderFromLists({connectRoomId: this.ruleForm.connectRoomId,orderState: 0})
        // } else {
        //   this.orderFromLists({id: this.ids,roomId: this.roomId,orderState: 1})
        // }
        // this.orderFromLists({id: this.ids,roomId: this.roomId})
      } else if (num === 2) {
        //财务信息
        localStorage.setItem("Path", "财务信息");
        this.isDoorstop = false;
        this.isOrder = false;
        this.isFinance = true;
        this.isInvoice = false;
        this.isOperation = false;
        this.tab_active = num;
        var arrFrom = this.PlateFrom;
        if (this.ruleForm.connectRoomId) {
          this.PlateFrom.forEach((el) => {
            if (el.orderState) {
              this.pricessNumber = 1;
            }
          });
          if (this.ruleForm.connectRoomId&&this.PlateFrom&&this.PlateFrom.length) {
            this.$nextTick(() => {
              this.PlateFrom.forEach((el) => {
                if (el.isMasterRoom) {
                  this.$refs.financeBox.currentRoom = el;
                }
              });
            });
          } else {
            this.$nextTick(() => {
              this.$refs.financeBox.currentRoom = this.ruleForm;
            });
          }
        } else {
          this.$nextTick(() => {
			this.$refs.financeBox.currentRoom = this.ruleForm;
          });
        }

        this.PlateFrom.forEach((it, index) => {
          this.$set(arrFrom, index, { ...it, isSelect: false });
        });
        this.$nextTick(() => {
          if (this.$refs.financeBox == undefined) {
            return;
          }
          this.$refs.financeBox.ListFinanceed();
          this.$refs.financeBox.PlateFrom.splice(0);
          this.$refs.financeBox.PlateFrom = arrFrom;
		  console.log('房间信息xxxxxxxxxx',arrFrom)
		  console.log('房间信息pppp',this.PlateFrom)
		  console.log('房间信息ssssssssssss',this.ruleForm)
		  if(this.ruleForm.connectRoomId&&arrFrom&&arrFrom.length){
			  var arr = false;
			  arrFrom.forEach(function(value){
				  console.log('数据1',value.orderState)
				  console.log('数据1',that.ruleForm.orderState)
				  if(value.orderState&&that.ruleForm.roomId==value.roomId){
					  arr = true;
				  }
			  });
			  console.log('数据',arr);
			  this.$refs.financeBox.notChecked = arr;
		  }
		  this.$refs.financeBox.Dbl_index = this.ruleForm.id;
          this.$refs.financeBox.ListFinanceed({ orderId: this.ruleForm.id });
          this.$refs.financeBox.Dbl_index = this.ruleForm.id;
          this.$refs.financeBox.autoHeight();
          if (this.ruleForm.connectRoomId) {
            this.$refs.financeBox.financeManagementConnectIisted();
          }
          

          //  this.$refs.financeBox.order_baseUpdateOutListed()
        });
      } else if (num === 3) {
        //发票信息
        localStorage.setItem("Path", "发票信息");
        this.isDoorstop = false;
        this.isOrder = false;
        this.isFinance = false;
        this.isInvoice = true;
        this.isOperation = false;
        this.tab_active = num;
        this.$nextTick(() => {
          if (this.$refs.invoiceBox) {
            this.$refs.invoiceBox.orderDateFrom = this.ruleForm;
            this.$refs.invoiceBox.ListInvoiced();
          }
        });
      } else if (num === 4) {
        //操作日志
        localStorage.setItem("Path", "操作日志");
        this.isDoorstop = false;
        this.isOrder = false;
        this.isFinance = false;
        this.isInvoice = false;
        this.isOperation = true;
        this.tab_active = num;
        // this.$nextTick(() => {
        // this.$refs.operationBoxs.ruleForm = this.ruleForm
        // this.$refs.operationBoxs.ListJournaled(this.ruleForm.id);
        // console.log(this.$refs.operationBoxs.ruleForm);
        // });
      } else if (num === 5) {
        //续住
        this.isDoorstop = false;
        this.isStayOver = true;
        this.tab_sideba = num;
        this.$nextTick(() => {
          this.$refs.stayBox.paymentListed();
          console.log("123123213211111111");
          this.$refs.stayBox.formLabelAlign.paymentId = null;
          this.$refs.stayBox.formLabelAlign.reserveDays = 1;
          this.$refs.stayBox.formLabelAlign.roomPrice = null;
          this.$refs.stayBox.formLabelAlign.paymentAmount =
            this.ruleForm.roomRealityPrice;
          this.$refs.stayBox.dateFrom = this.ruleForm;
          this.$refs.stayBox.BargainingUnitAlls();
          console.log(this.$refs.stayBox.dateFrom, "dasdassdas");
        });
      } else if (num === 6) {
        //更换房间

        this.isDoorstop = false;
        this.isChange = true;
        this.tab_sideba = num;
        this.$nextTick(() => {
          this.$refs.ChangeRoomBox.Addnum = 2;
          this.$refs.ChangeRoomBox.orderFrom = this.ruleForm;
          this.$refs.ChangeRoomBox.createdApi();
        });
      } else if (num === 7) {
        //打印票据
        this.isDoorstop = false;
        this.isBill = false;
        this.isPrint = true;
        this.tab_sideba = num;
        this.$nextTick(() => {
          if (this.ruleForm.connectRoomId) {
            this.$refs.PrintBillbOX.financeManagementConnectIisted();
          } else {
            this.$refs.PrintBillbOX.ListFinanceed();
          }
        });
      } else if (num === 8) {
        //制门卡
        this.isTable = true;
        this.isStayOver = false;
        this.isChange = false;
        this.isBill = false;
        this.isOpen = false;
        this.isDoorstop = false;
        // this.newisDoorstop=true;
        this.tab_sideba = num;

        gethotelinfo({ id: localStorage.getItem("orgid") }).then((res) => {
          console.log("酒店数据", res);
          if (res.data.code == 0) {
            //2023-11-22新增 BTRW-49-A的判断情况BTRW-49
            if (res.data.data.brandModel.indexOf("BTRW-49") != -1) {
              //新制作房卡
              this.newmakecardshow = true;
              this.$nextTick(() => {
                this.$refs.newmakecard.RoomStatusListed();
                this.$refs.newmakecard.gethotel();
                if (res.data.data.brandModel.indexOf("BTRW-49-A") != -1) {
                  this.$refs.newmakecard.brandModel = false;
                }
                this.$refs.newmakecard.checkroominfo(
                  this.ruleForm.houseTypeId,
                  this.ruleForm.roomId
                );
              });
            } else {
              this.newisDoorstop = true;
              //老制卡
              this.$nextTick(() => {
                // this.$refs.makecard.RoomStatusListed();
                this.$refs.makecard.gethotel();
                this.$refs.makecard.checkroominfo(
                  this.ruleForm.houseTypeId,
                  this.ruleForm.roomId
                );
              });
            }
          }
        });
      } else if (num === 9) {
        //会员开卡

        if (this.ruleForm.isMember) {
          //会员，弹出会员详情弹窗
          this.isConsumotion = true;
          this.isDoorstop = false;
          this.tab_active = num;
          console.log(this.member, "1231231231");
          this.$nextTick(() => {
            this.$refs.SHIPBOX.memberId = this.member.memberId;
            this.$refs.SHIPBOX.MembershipCarded(this.member.memberId);
            this.$refs.SHIPBOX.CheckinRecordListed();
          });
        } else {
          //开会员，弹出会员开卡弹窗
          this.isOpen = true;
          this.isDoorstop = false;
          this.tab_sideba = num;
          this.$nextTick(() => {
            console.log(this.ruleForm);
            this.$refs.memberBox.ruleForm = { ...this.ruleForm, isGift: "1" };
          });
        }
      } else if (num === 11) {
        //吧台点餐
        // this.$set()
        this.tab_sideba = num;
        this.getRoomOrderTimeConfigurationed();
        // this.$nextTick(() => {
        //   this.$refs.PURCHBOX.BargainingUnitAlled()
        //   this.$refs.PURCHBOX.createdApi()
        //   // this.$refs.BarCounterBox.formInline.paymentId = null;
        //   // this.$refs.BarCounterBox.formInline.paymentName = null;
        //   // this.$refs.BarCounterBox.li_Payment = null;
        //   // this.$refs.BarCounterBox.isDeal = false;
        //   // this.$refs.BarCounterBox.createdApi();
        // });
      } else if (num === 10) {
        //商品购买
        this.tab_sideba = num;
        this.isPurchase = true;
        this.isDoorstop = false;
        this.tab_active = num;
        console.log("商品购买", num);
        console.log("商品购买", this.tab_active);
        this.$nextTick(() => {
          this.$refs.BarCounterBox.checkFrom = this.ruleForm;
          this.$refs.BarCounterBox.formInline.paymentId = null;
          this.$refs.BarCounterBox.formInline.paymentName = null;
          this.$refs.BarCounterBox.li_Payment = null;
          this.$refs.BarCounterBox.isDeal = false;
          this.$refs.BarCounterBox.createdApi();
        });
      } else if (num === 12) {
        //恢复
        this.tab_active = num;
      }
    },
    //获取吧台点餐的就餐时间
    //客房点餐时间配置
    getRoomOrderTimeConfigurationed() {
      getRoomOrderTimeConfiguration({
        orgId: localStorage.getItem("orgid"),
      }).then((res) => {
        if (res.data.code === 0) {
          this.getNowDate();
          if (res.data.data) {
            this.countervalue1.push(res.data.data.startTime);
            this.countervalue1.push(res.data.data.endTime);
            let startTime = parseFloat(
              new Date(
                this.startNewDate +
                  " " +
                  (res.data.data.startTime
                    ? res.data.data.startTime
                    : "00:00:00")
              ).getTime()
            );
            let endTime = parseFloat(
              new Date(
                this.startNewDate +
                  " " +
                  (res.data.data.endTime ? res.data.data.endTime : "23:59:59")
              ).getTime()
            );
          } else {
            let startTime = parseFloat(
              new Date(this.startNewDate + " " + "00:00:00").getTime()
            );
            let endTime = parseFloat(
              new Date(this.startNewDate + " " + "23:59:59").getTime()
            );
          }

          let newTime = parseFloat(new Date(this.arrivalTimes).getTime());
          // if(newTime >= startTime && newTime <= endTime ){
          //可以就餐
          this.isCounter = true;
          this.isDoorstop = false;

          this.$nextTick(() => {
            this.$refs.PURCHBOX.BargainingUnitAlled();
            this.$refs.PURCHBOX.createdApi();
            this.$refs.PURCHBOX.formInline.paymentId = null;
            this.$refs.PURCHBOX.formInline.paymentName = null;
            this.$refs.PURCHBOX.li_Payment = null;
            this.$refs.PURCHBOX.isDeal = false;
            this.$refs.PURCHBOX.isCanZhuo = false;
            this.$refs.PURCHBOX.createdUpdate();
          });
          // }else{
          //   //不在就餐时间
          //   this.$message({
          //     type: 'error',
          //     message: `不在就餐时间范围${res.data.data.startTime}-${res.data.data.endTime}`
          //   })
          // }
        }
      });
    },
    //获取当前时间
    getNowDate() {
      var date = new Date();
      var sign2 = ":";
      var year = date.getFullYear(); // 年
      var month = date.getMonth() + 1; // 月
      var day = date.getDate(); // 日
      var hour = date.getHours(); // 时
      var minutes = date.getMinutes(); // 分
      var seconds = date.getSeconds(); //秒
      // 给一位数的数据前面加 “0”
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (day >= 0 && day <= 9) {
        day = "0" + day;
      }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }

      this.arrivalTimes =
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        "-" +
        minutes +
        "-" +
        "-" +
        seconds;
      this.startNewDate = year + "-" + month + "-" + day;
    },
    //点击用户标签切换用户信息
    labelClick(num) {
      console.log(num, "sdddddddddd");
      this.li_active = num;

      this.ruleForm.name = this.AccompanyingGuests[num].name;
      this.ruleForm.birthday = this.AccompanyingGuests[num].birthday;
      this.ruleForm.gender =
        this.AccompanyingGuests[num].gender === false ? "0" : "1";
      this.ruleForm.idCard = this.AccompanyingGuests[num].idCard;
      this.ruleForm.mobile = this.AccompanyingGuests[num].mobile;
      this.member.number = this.AccompanyingGuests[num].number;
      this.member.memberGradeName =
        this.AccompanyingGuests[num].memberGradeName;
      if (
        this.AccompanyingGuests[num].name &&
        this.AccompanyingGuests[num].mobile
      ) {
        this.isMessage = true;
      } else {
        this.isMessage = false;
      }
    },

    //点击提交表单进行验证
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //点击重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 点击弹窗以外隐藏弹窗
    on_off(e) {
      console.log(e.target.className);
      if (e.target.className === "order_information") {
        this.$emit("offInquiry", [false]);
        this.ruleForm = {};
      }
    },
    // 点击右上角的图标隐藏弹窗
    close_page() {
      this.isOpen = false;
      // this.$emit("offInquiry", [false]);
      this.ruleForm = {};
      if (this.connectRoomId) {
        this.orderFromLists({ connectRoomId: this.connectRoomId });
      } else {
        this.orderFromLists({
          id: this.ids,
          roomId: this.roomId,
          orderState: 1,
        });
      }
    },

    addClient() {
      this.isPersonal = true;
      this.isTable = false;
    },
    formattedDate(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const hour = date.getHours().toString().padStart(2, "0");
      const minute = date.getMinutes().toString().padStart(2, "0");
      const second = date.getSeconds().toString().padStart(2, "0");
      const formattedDate = `${year}${month}${day}${hour}${minute}`;
      return formattedDate;
    },
    //制卡
    makedoorcard() {
      this.$refs["roomcard"].validate((valid) => {
        if (valid) {
          if (!this.roomcard.cardHexStr) {
            this.$message({
              message: "请先读取卡片数据",
              type: "warning",
            });
            return false;
          }
          var timestr1 = this.formattedDate(
            new Date(this.roomcard.outtime).getTime()
          ); //new Date(this.roomcard.outtime).format('yyMMddHHmm')
          var timestr2 = this.formattedDate(new Date().getTime());
          timestr1 = timestr1.substring(2, timestr1.length);
          timestr2 = timestr2.substring(2, timestr2.length);
          var a1 =
            this.roomcard.value1 <= 9
              ? "0" + this.roomcard.value1
              : this.roomcard.value1;
          var a2 =
            this.roomcard.value2 <= 9
              ? "0" + this.roomcard.value2
              : this.roomcard.value2;
          var a3 =
            this.roomcard.value3 <= 9
              ? "0" + this.roomcard.value3
              : this.roomcard.value3;
          var a4 =
            this.roomcard.value4 <= 9
              ? "0" + this.roomcard.value4
              : this.roomcard.value4;
          var data = {
            a: 1,
            // dlsCoID: parseFloat(
            //   1 + "" + localStorage.getItem("orgid").substr(-5, 5)
            // ), //酒店标识，可以从现有卡片读取
            dlsCoID: parseFloat(this.roomcard.sign),
            cardNo: parseFloat(this.roomcard.cardNo), //同一分钟内发卡最多16张，每发一张卡加1
            dai: parseFloat(this.roomcard.dai), //客人代，0--255，用于后卡覆盖前卡，一般情况下固定为0
            lLock: parseFloat(this.roomcard.lLock), //反锁标志，1能开反锁，0不能开反锁
            pdoors: 1, //公共门标志，1能开公共门，0不能开
            dBytes: timestr2, //发卡时间10位数字字符串，包含年月日时分，各占两位 例：'0905201345'表示2009/05/20 13:45
            eBytes: timestr1, //退房时间10位数字字符串，包含年月日时分，各占两位
            lockBytes: a1 + "" + a2 + "" + a3 + "" + a4, //
            cardHexStr: this.roomcard.cardHexStr, //返回的卡数据字符串
          };
          // console.log('发送时间',data);
          // return false;
          axios({
            method: "POST",
            url: "http://127.0.0.1:8099/usbpro/guestCard",
            // url: 'http://192.168.2.27:8099/usbpro/guestCard',
            headers: {
              // 'Sec-Fetch-Site': 'same-origin',
              "Access-Control-Allow-Origin": "*",
            },
            data: data,
          }).then((res) => {
            console.log("纸卡数据", res);
            if (res.data.code == "000000") {
              this.$message({
                message: "操作成功",
                type: "success",
              });
            } else {
              this.$message({
                message: "请重试",
                type: "warning",
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    //销卡
    destruction() {
      if (!this.roomcard.sign) {
        this.$message({
          message: "请填写酒店标识",
          type: "warning",
        });
        return false;
      }
      var data = {
        // dlsCoID: parseFloat(
        //   1 + "" + localStorage.getItem("orgid").substr(-5, 5)
        // ), //酒店标识，可以从现有卡片读取
        dlsCoID: parseFloat(this.roomcard.sign),
      };
      axios({
        method: "POST",
        url: "http://127.0.0.1:8099/usbpro/cardErase?dlsCoID=" + data.dlsCoID,
        // url: 'http://192.168.2.27:8099/usbpro/cardErase?dlsCoID='+data.dlsCoID,
        headers: {
          // 'Sec-Fetch-Site': 'same-origin',
          "Access-Control-Allow-Origin": "*",
        },
        // data:data,
      }).then((res) => {
        console.log("销卡", res);
        if (res.data.code == "000000") {
          this.$message({
            message: res.data.msg || "操作成功",
            type: "success",
          });
        } else {
          this.$message({
            message: res.data.msg || "请重试",
            type: "warning",
          });
        }
      });
    },
    //鸣叫
    chirp() {
      axios({
        method: "POST",
        url: "http://127.0.0.1:8099/usbpro/buzzer",
        // url: 'http://192.168.2.27:8099/usbpro/buzzer',
        headers: {
          // 'Sec-Fetch-Site': 'same-origin',
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          a: 1,
          b: 10,
        },
      }).then((res) => {
        console.log(res);
      });
    },
    //获取卡片数据
    carddata() {
      axios({
        method: "POST",
        url: "http://127.0.0.1:8099/usbpro/readCard",
        // url: 'http://192.168.2.27:8099/usbpro/readCard',
        headers: {
          // 'Sec-Fetch-Site': 'same-origin',
          "Access-Control-Allow-Origin": "*",
        },
      }).then((res) => {
        console.log("卡片数据", res);
        if (res.data.code == "000000") {
          this.roomcard.cardHexStr = res.data.data.cardHexStr;
          this.$alert(res.data.data.cardHexStr, "卡片数据", {
            confirmButtonText: "确定",
            callback: (action) => {
              // this.$message({
              // 	type: 'info',
              // 	message: `action: ${ action }`
              // });
            },
          });
        } else {
          this.$message({
            message: res.data.msg || "请重试",
            type: "warning",
          });
        }
      });
    },
    //卡片类型
    cardType() {
      axios({
        method: "POST",
        url: "http://127.0.0.1:8099/usbpro/getCardTypeByCardDataStr",
        // url: 'http://192.168.2.27:8099/usbpro/getCardTypeByCardDataStr',
        headers: {
          // 'Sec-Fetch-Site': 'same-origin',
          "Access-Control-Allow-Origin": "*",
        },
      }).then((res) => {
        console.log("卡片类型", res);
        if (res.data.code == "000000") {
          this.cardinfo = res.data.data;
          this.$alert(
            res.data.data.cardTypeName + ":/n" + res.data.data.cardHexStr,
            "卡片类型",
            {
              confirmButtonText: "确定",
              callback: (action) => {
                // this.$message({
                // 	type: 'info',
                // 	message: `action: ${ action }`
                // });
              },
            }
          );
        } else {
          this.$message({
            message: res.data.msg || "请重试",
            type: "warning",
          });
        }
      });
    },
    lengthCutting(str, num) {
      let strArr = [];
      for (let i = 0; i < str.length; i += num)
        strArr.push(str.slice(i, i + num));
      return strArr;
    },
    //离店
    checkOut() {
      if (!this.roomcard.sign) {
        this.$message({
          message: "请填写酒店标识",
          type: "warning",
        });
        return false;
      }
      var data = {
        // dlsCoID: parseFloat(
        //   1 + "" + localStorage.getItem("orgid").substr(-5, 5)
        // ), //酒店标识，可以从现有卡片读取
        dlsCoID: parseFloat(this.roomcard.sign),
      };
      axios({
        method: "POST",
        url:
          "http://127.0.0.1:8099/usbpro/getGuestETimeByCardDataStr?dlsCoID=" +
          data.dlsCoID,
        // url: 'http://192.168.2.27:8099/usbpro/getGuestETimeByCardDataStr?dlsCoID='+data.dlsCoID,
        headers: {
          // 'Sec-Fetch-Site': 'same-origin',
          "Access-Control-Allow-Origin": "*",
        },
        // data:data,
      }).then((res) => {
        console.log("获取离店时间", res);
        if (res.data.code == "000000") {
          this.cardinfo = res.data.data;
          var time = this.lengthCutting(res.data.data.ETime, 2);
          var str = "";
          time.forEach(function (value, index) {
            if (index == 0) {
              str = value + "年";
            } else if (index == 1) {
              str = str + value + "月";
            } else if (index == 2) {
              str = str + value + "日";
            } else if (index == 3) {
              str = str + value + "时";
            } else if (index == 4) {
              str = str + value + "分";
            }
          });
          this.$alert(str, "离店时间", {
            confirmButtonText: "确定",
            callback: (action) => {
              // this.$message({
              // 	type: 'info',
              // 	message: `action: ${ action }`
              // });
            },
          });
        } else {
          this.$message({
            message: res.data.msg || "请重试",
            type: "warning",
          });
        }
      });
    },
    //卡锁号
    locknumber() {
      if (!this.roomcard.sign) {
        this.$message({
          message: "请填写酒店标识",
          type: "warning",
        });
        return false;
      }
      var data = {
        // dlsCoID: parseFloat(
        //   1 + "" + localStorage.getItem("orgid").substr(-5, 5)
        // ), //酒店标识，可以从现有卡片读取
        dlsCoID: parseFloat(this.roomcard.sign),
      };
      axios({
        method: "POST",
        url:
          "http://127.0.0.1:8099/usbpro/getGuestLockNoByCardDataStr?dlsCoID=" +
          data.dlsCoID,
        // url: 'http://192.168.2.27:8099/usbpro/getGuestLockNoByCardDataStr?dlsCoID='+data.dlsCoID,
        headers: {
          // 'Sec-Fetch-Site': 'same-origin',
          "Access-Control-Allow-Origin": "*",
        },
      }).then((res) => {
        console.log("获取卡锁号", res);
        if (res.data.code == "000000") {
          this.$alert(res.data.data.lockNo, "卡锁号", {
            confirmButtonText: "确定",
            callback: (action) => {
              // this.$message({
              // 	type: 'info',
              // 	message: `action: ${ action }`
              // });
            },
          });
        } else {
          this.$message({
            message: res.data.msg || "请重试",
            type: "warning",
          });
        }
      });
    },
    makecardclose() {
      this.newisDoorstop = false;
      this.newmakecardshow = false;
    },
	//更新房单信息
	updateOrderInfo(){
		// console.log('房单信息',this.ruleForm);
		// console.log('房单信息',this.AccompanyingGuests);
		const params = {
		  guestMobile:this.AccompanyingGuests[0].mobile, //手机号码
		  guestName:this.AccompanyingGuests[0].name, //手机号码
		  id: this.ruleForm.id,
		};
		
		EditdataStatistics(params)
		  .then((res) => {
		    if (res.data.code === 0) {
		      this.$message({
		        type: "success",
		        message: "信息更新成功",
		      });
		    } else {
		      this.$message({
		        type: "error",
		        message: res.data.msg ? res.data.msg : "系统繁忙",
		      });
		    }
		  });
	},
  },
};
</script>

<style lang="scss" scoped>
.ChangeRoomBox {
  ::v-deep .el-dialog__header {
    // background-color: #e8fdfe;
  }

  ::v-deep .el-dialog__body {
    padding-top: 5px;
    box-sizing: border-box;
    max-height: 660px;
    // overflow-y: auto;
    overflow: hidden;
    // background: linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%);
  }
}
.roomBox {
  ::v-deep .el-dialog__header {
    // background-color: #e8fdfe;
  }

  ::v-deep .el-dialog__body {
    padding-top: 5px;
    box-sizing: border-box;
    max-height: 660px;
    overflow-y: auto;
    // overflow: hidden;
    // background: linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%);
  }
}
.BarCoundBox {
  ::v-deep .el-dialog__header {
    // background-color: #e8fdfe;
  }

  ::v-deep .el-dialog__body {
    padding-top: 5px;
    box-sizing: border-box;
    max-height: 690px;
    overflow: hidden;
    // background: linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%);
  }
}
.order_information {
  width: 100%;
  //制门卡弹窗css

  .order-content {
    // width: 1300px;
    background-color: #fff;
    position: relative;

    //添加卡彈窗
    .doorstop {
      width: 700px;
      height: 500px; //200px
      background-color: #f6f6f6; //#ee7a14
      z-index: 100;
      position: absolute;
      top: 60px; //400px
      left: 150px; //200px
      display: flex;
      flex-direction: column;
      // 标题部分css
      .order_title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 50px;
        padding-left: 20px;
        box-sizing: border-box;

        .title_icon {
          width: 50px;
          height: 50px;
          text-align: center;
          line-height: 50px;
        }

        p {
          color: black;
          flex: 1;
          text-align: left;
        }
      }
      .main {
        width: 100%;
        flex: 1;
        display: flex;
        justify-content: space-between;
        .subButton {
          width: 15%;
          height: 100%;
          display: flex;
          flex-direction: column;
          .el-button:nth-child(1) {
            width: 110px;
            height: 40px;
            background-color: #5cb5b8;
            color: #fff;
            margin-left: 10px;
          }
          .el-button:nth-child(2) {
            width: 110px;
            height: 40px;
            background-color: #f72b08;
            color: #fff;
          }
          .el-button {
            width: 110px;
            height: 40px;
            margin-bottom: 10px;
          }
        }
        .table {
          width: 80%;
          height: 100%;
          padding: 30px;
        }
      }
    }
    .order_box {
      width: 100%;
      height: 550px;
      overflow: hidden;
      // background: linear-gradient(177deg, #e8fdfe 0%, #ffffff 100%);
      border-radius: 5px;
      // 标题部分css
      .order_title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 50px;
        padding-left: 20px;
        box-sizing: border-box;

        .title_icon {
          width: 50px;
          height: 50px;
          text-align: center;
          line-height: 50px;
        }

        p {
          color: black;
          flex: 1;
          text-align: left;
        }
      }

      // 头部选项卡部分css
      .tab_control {
        width: 100%;
        height: 70px;
        margin-bottom: 10px;

        ul {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          padding-left: 30px;
          box-sizing: border-box;

          li {
            width: 90px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            cursor: pointer;
          }

          li.active {
            border-bottom: 2px solid #5cb5b8;
            color: #5cb5b8;
          }
        }
      }

      .information_content {
        display: flex;
        width: 100%;
        height: 450px;
        padding-right: 20px;
        padding-bottom: 60px;
        box-sizing: border-box;
        // overflow-y: auto;
        // 侧边栏css
        .sidebar {
          height: 300px;

          ul {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            li {
              width: 110px;
              height: 35px;
              line-height: 35px;
              text-align: center;
              margin-bottom: 15px;
              border-radius: 6px;
              background-color: #e4eff0;
              color: #666666;
              cursor: pointer;
            }

            li.activate {
              background-color: #5cb5b8;
              color: #fff;
            }
            .recoverButton {
              background-color: red;
              color: #fff;
            }
            //恢复
            .el-tooltip.item {
              .recoverNav {
                width: 160px;
                height: 70px;
                color: #fff;
                .buttonCkick {
                  text-align: right;
                  ::v-deep .el-button {
                    font-size: 12px;
                  }
                  .el-button:first-child {
                  }
                }
              }
            }
          }
        }

        // 基本信息区域
        .order_main {
          flex: 1;
          height: 450px;
          overflow-y: auto;
          //   房单信息的css
          .tenant_message {
            border: 1px solid #ededed;
            border-radius: 8px;
            margin-bottom: 20px;

            .tenant_size {
              display: flex;
              height: 100%;
              // justify-content: space-around;
              .tenant_left {
                width: 50%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .size_box {
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  background-color: #5cb5b8;
                  margin-right: 10px;
                  margin-left: 20px;
                }
                .main_title {
                  height: 60px;
                  line-height: 60px;
                  font-size: 20px;
                  color: #272727;
                }
              }
              .tenant_right {
                width: 50%;
                text-align: right;
                padding-right: 21px;
                padding-top: 20px;
                box-sizing: border-box;
                ::v-deep .el-button {
                  width: 100px;
                  height: 35px;
                  background-color: #5cb5b8;
                  color: #fff;
                  padding: 10px 20px;
                }
              }
            }

            .main_content {
              width: 100%;
              padding-bottom: 20px;
              box-sizing: border-box;
              padding-top: 10px;

              .demo-ruleForm {
                padding-left: 20px;
                box-sizing: border-box;
                .el-form-item {
                  margin-bottom: 5px;
                  margin-right: 10px;
                  ::v-deep .el-input__inner {
                    height: 35px;
                    margin-bottom: -10px;
                  }
                }
              }
              .el-button {
                background-color: #effdfd;
                color: #5cb5b8;
                width: 115px;
                height: 44px;
                border-radius: 8px;
                border: none;
                margin-top: 20px;
                margin-left: 20px;
              }
            }
          }
          //住客信息的展示css
          .resident_message {
            border: 1px solid #ededed;
            border-radius: 8px;
            padding-bottom: 10px;
            box-sizing: border-box;
            height: 235px;
            width: 100%;
            .resident_title {
              width: 100%;
              height: 50px;
              display: flex;
              align-items: center;
              padding: 0 22px 0 20px;
              box-sizing: border-box;
              justify-content: space-between;
              .title_size {
                height: 100%;
                display: flex;
                font-size: 20px;
                color: #272727;
                align-items: center;
                .size_box {
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  background-color: #5cb5b8;
                  margin-right: 10px;
                }
                .size_Button {
                  .el-button:last-child {
                    border-radius: 4px;
                    background-color: #5cb5b8;
                    color: #fff;
                  }
                }
              }
              span {
                padding-top: 20px;
                .el-button {
                  width: 100px;
                  height: 40px;
                  color: #999999;
                  border-radius: 4px;
                }
                .el-button.active {
                  background-color: #5cb5b8;
                  color: #fff;
                }
              }
            }
            //用户标签css
            .resident_label {
              width: 100%;
              height: 60px;

              ul {
                flex: 1;
                height: 60px;
                display: flex;
                overflow-y: auto;
                padding-left: 10px;
                box-sizing: border-box;
                align-items: center;
                li {
                  width: 90px;
                  height: 35px;
                  text-align: center;
                  line-height: 35px;
                  border-radius: 4px;
                  flex-shrink: 0;
                  // box-sizing: border-box;
                  background-color: #f4f4f4;
                  color: #666666;
                  margin-right: 20px;
                  cursor: pointer;
                  position: relative;
                  // padding-right: 10%;
                  // span {
                  //   position: relative;
                  //   top: 1%;
                  //     right: 7%;
                  //   // padding-right: 10%;
                  //   box-sizing: border-box;
                  // .el-icon-close {
                  //   display: none;
                  //   position: absolute;
                  //   color: #fff;
                  //   top: 0%;
                  //   right: 0%;
                  //   font-size: 15px;
                  // }
                  .el-icon-close {
                    display: none;
                    position: absolute;
                    color: #fff;
                    top: 9%;
                    right: 6%;
                    font-size: 15px;
                    z-index: 1000;
                  }
                  .el-icon-close.active {
                    display: block;
                  }

                  .starImg {
                    width: 18px;
                    display: none;
                    position: absolute;
                    top: 19%;
                    left: 8%;
                  }
                  .starImg.active {
                    display: block;
                  }
                  .imgBox {
                    display: none;
                  }
                  .imgBox.active {
                    display: block;
                    position: absolute;
                    top: 19%;
                    left: 8%;
                  }
                  // }
                }
                li:first-child {
                  margin-left: 12px;
                }
                li.active {
                  // padding: 0px 2% 0px 0px;
                  background-color: #5cb5b8;
                  color: #fff;
                }
                li.starLI {
                  color: #fff;
                }
              }
            }
            //根据点击的用户标签显示用户信息css
            .user_message {
              width: 100%;
              height: 100px;
            }
          }
        }
      }
    }
  }

  // 添加同行人员的基本信息的css
}
.MemberBOX {
  ::v-deep .el-dialog__header {
    // background-color: #e8fdfe;
  }

  ::v-deep .el-dialog__body {
    padding-top: 5px;
    height: 640px;
    box-sizing: border-box;
    overflow-y: auto;
    // background: linear-gradient(177deg, #E8FDFE 0%, #FFFFFF 100%);
  }
}
.subnitButton {
  width: 100px;
  height: 40px;
  margin-right: 10px;
  background-color: #5cb5b8;
  color: #fff;
}
.demo-ruleForms {
  padding-right: 20px;
  .el-form-item {
    margin-top: 10px;
    ::v-deep .el-input__inner {
      height: 35px;
      margin-bottom: -10px;
    }
  }
}
.memberImg {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 30%;
  right: 6%;
}
::v-deep .el-input-number .el-input__inner {
  text-align: left;
}
</style>
