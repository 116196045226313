<template>
	<el-drawer title="消息中心" :visible="childVisible_" size="30%" @close="handleClose">
		<div class="info-box" v-for="(item,index) in list"  @click="cardclick(item)">
			<!--  @click="cardclick(index+1)"-->
			<div class="info-heard">
				<div>{{item.messageName}}</div>
				<div>{{item.createdTime}}</div>
			</div>
			<div class="info-footer">{{item.messageDesc}}！</div>
		</div>

		<div class="footer-box" v-if="list.length<total" @click="nextpage">
			点击加载更多
		</div>
	</el-drawer>
</template>

<script>
	import {
		todayinfo
	} from "_api/info";
	import {
		findMyApplication,
		getApplicationId,
		getVisible,
	} from "_api/index";
	import {visibleResource} from '_api/roleManagement'
	export default {
		//传值
		name: 'DrawerInfo',
		props: {
			//父组件传  childVisible值；
			childVisible: {
				type: Boolean,
				default: false, //这里默认为false
			},
		},
		data() {
			return {
				list: [],
				param: {
					"current": 1,
					"extra": {},
					"model": {
						"empId": 0
					},
					"order": "descending",
					"size": 10,
					"sort": "id"
				},
				total: 0,

				findMyAppItems: [],
				name: "10",
			}
		},
		//计算属性
		computed: {
			childVisible_: {
				get() {
					console.log(this.childVisible, 'childVisible')
					return this.childVisible
				},
				//值变化的时候会被调用
				set(v) {
					console.log(v, 'v')
					// this.$emit('drawershow')
				},
			},
		},
		created() {
			this.getlist();
			this.findMyApplicationed();
		},
		methods: {
			//关闭当前抽屉；触发set方法（childVisible_值改变就会调用set）
			handleClose() {
				this.childVisible_ = false
				this.$emit('drawershow')
			},
			getlist() {
				var that = this;
				todayinfo(this.param).then((res) => {
					console.log('数据', res)
					if (this.param.current == 1) {
						this.list.splice(0);
					}
					res.data.data.records.forEach(function(value) {
						that.list.push(value);
					});
					this.total = parseInt(res.data.data.total);
				});
			},
			nextpage() {
				this.param.current++;
				this.getlist()
			},
			// cardclick(index) {
			// 	this.getApplicationIds(index);
			// },
			cardclick(item) {
				var that=this;
				if (item.messageType == 1) {
					//预定
					this.findMyAppItems.forEach(function(value){
						if(value.name.indexOf('前台管理')!=-1){
							that.getApplicationIds(value.id);
						}
					});
				} else if (item.messageType == 2) {
					this.findMyAppItems.forEach(function(value){
						if(value.name.indexOf('餐饮')!=-1){
							that.getApplicationIds(value.id);
						}
					});
				} else if (item.messageType == 3) {
					this.findMyAppItems.forEach(function(value){
						if(value.name.indexOf('商超')!=-1){
							that.getApplicationIds(value.id);
						}
					});
				}
			},
			//获取所用应用权限
			findMyApplicationed() {
				let timeDate = new Date().getTime();
				// name: '10'
				findMyApplication({
					_t: timeDate,
					name: this.name
				}).then((res) => {
					console.log(res.data.data);
					if (res.data.code === 0) {
						this.findMyAppItems = res.data.data;
					}
				});
			},
			//切换权限
			getApplicationIds(id) {
				var that=this;
				if (localStorage.getItem('orgid') == '-1') {
					if (id == '366448864552551303') {
						let timeDate = new Date().getTime();
						localStorage.setItem("ApplicationId", id);
						getApplicationId({
							_t: timeDate,
							applicationId: id
						}).then((res) => {
							console.log(res);
							if (res.data.code === 0) {
								let dataTime = new Date().getTime();
								this.visibleResourceed(dataTime, id)
								if (id == "1") {
									//前台管理系统
									localStorage.setItem("DynamicPath", JSON.stringify(res.data.data));
									this.$store.commit("permission/authNavs", res.data.data);
									this.$store.commit("permission/ASIDENAVS");
									localStorage.setItem(
										"repath",
										this.$store.state.permission.Newrouter[0].path
									);
									const Newrouter = this.$store.state.permission.Newrouter;
									this.$store.commit("permission/INIT_AUTH_ROUTES", Newrouter);
									//  this.$store.commit('user/DYNAMICROUTER', id)
									console.log('路由');
									res.data.data.forEach(function(value){
										if(value.name.indexOf('预定')!=-1){
											that.$router.push({
												path: value.path,
												query: { plan: 'message' } 
											});
										}
									});
								} else if (id == "4") {
									//商超系统
									localStorage.setItem(
										"DynamicPath",
										JSON.stringify(res.data.data[0].children)
									);
									this.$store.commit(
										"permission/authNavs",
										res.data.data[0].children
									);
									this.$store.commit("permission/SET_COMMERCIAL");
									localStorage.setItem(
										"repath",
										this.$store.state.permission.Newrouter[0].path
									);
									const Newrouter = this.$store.state.permission.Newrouter;
									this.$store.commit("permission/INIT_AUTH_ROUTES", Newrouter);
									res.data.data.forEach(function(value){
										if(value.name.indexOf('商超')!=-1){
											if(value.children.length){
												value.children.forEach(function(item){
													if(item.name.indexOf('订单')!=-1){
														that.$router.push({
															path: item.path,
															query: { plan: 'message' } 
														});
														
													}
												});
											}
											
										}
									});
									// this.$router.push({
									// 	path: localStorage.getItem("repath"),
									// });
								} else if (id == "3") {
									//餐饮系统
									localStorage.setItem("DynamicPath", JSON.stringify(res.data.data));
									this.$store.commit("permission/authNavs", res.data.data);
									this.$store.commit("permission/SET_COMMERCIAL");
									localStorage.setItem(
										"repath",
										this.$store.state.permission.Newrouter[0].path
									);
									const Newrouter = this.$store.state.permission.Newrouter;
									this.$store.commit("permission/INIT_AUTH_ROUTES", Newrouter);
									// this.$router.push({
									// 	path: localStorage.getItem("repath"),
									// });
									localStorage.setItem('Message','餐饮')
									res.data.data.forEach(function(value){
										if(value.name.indexOf('订单')!=-1){
											that.$router.push({
												path: value.path,
												query: { plan: 'message' } 
											});
										}
									});
								} else if (id == "366448864552551303") {
									//品牌端后台
									localStorage.setItem("DynamicPath", JSON.stringify(res.data.data));
									this.$store.commit("permission/authNavs", res.data.data);
									this.$store.commit("permission/SETBRANDSIDE");
									localStorage.setItem(
										"repath",
										this.$store.state.permission.Newrouter[0].path
									);
									const Newrouter = this.$store.state.permission.Newrouter;
									this.$store.commit("permission/INIT_AUTH_ROUTES", Newrouter);
									this.$router.push({
										path: localStorage.getItem("repath"),
									});
								}

								console.log(JSON.parse(localStorage.getItem("DynamicPath")));
							}
						});
						getVisible({
							_t: timeDate,
							applicationId: id
						}).then((red) => {
							console.log(red);
						});
					} else {
						this.$message({
							type: 'error',
							message: '请前往品牌端创建门店信息'
						})
					}

				} else {
					let timeDate = new Date().getTime();
					localStorage.setItem("ApplicationId", id);
					getApplicationId({
						_t: timeDate,
						applicationId: id
					}).then((res) => {
						console.log(res);
						if (res.data.code === 0) {
							let dataTimes = new Date().getTime();
							this.visibleResourceed(dataTimes, id)
							if (id == "1") {
								//前台管理系统
								localStorage.setItem("DynamicPath", JSON.stringify(res.data.data));
								this.$store.commit("permission/authNavs", res.data.data);
								this.$store.commit("permission/ASIDENAVS");
								localStorage.setItem(
									"repath",
									this.$store.state.permission.Newrouter[0].path
								);
								const Newrouter = this.$store.state.permission.Newrouter;
								this.$store.commit("permission/INIT_AUTH_ROUTES", Newrouter);
								//  this.$store.commit('user/DYNAMICROUTER', id)
								res.data.data.forEach(function(value){
									if(value.name.indexOf('预定')!=-1){
										that.$router.push({
											path: value.path,
										});
									}
								});
							} else if (id == "4") {
								//商超系统
								localStorage.setItem(
									"DynamicPath",
									JSON.stringify(res.data.data[0].children)
								);
								this.$store.commit(
									"permission/authNavs",
									res.data.data[0].children
								);
								this.$store.commit("permission/SET_COMMERCIAL");
								localStorage.setItem(
									"repath",
									this.$store.state.permission.Newrouter[0].path
								);
								const Newrouter = this.$store.state.permission.Newrouter;
								this.$store.commit("permission/INIT_AUTH_ROUTES", Newrouter);
								res.data.data.forEach(function(value){
									if(value.name.indexOf('商超')!=-1){
										if(value.children.length){
											value.children.forEach(function(item){
												if(item.name.indexOf('订单')!=-1){
													that.$router.push({
														path: item.path,
													});
												}
											});
										}
										
									}
								});
							} else if (id == "3") {
								//餐饮系统
								localStorage.setItem("DynamicPath", JSON.stringify(res.data.data));
								this.$store.commit("permission/authNavs", res.data.data);
								this.$store.commit("permission/SET_COMMERCIAL");
								localStorage.setItem(
									"repath",
									this.$store.state.permission.Newrouter[0].path
								);
								const Newrouter = this.$store.state.permission.Newrouter;
								this.$store.commit("permission/INIT_AUTH_ROUTES", Newrouter);
								res.data.data.forEach(function(value){
									if(value.name.indexOf('订单')!=-1){
										that.$router.push({
											path: value.path,
										});
									}
								});
							} else if (id == "366448864552551303") {
								//品牌端后台
								localStorage.setItem("DynamicPath", JSON.stringify(res.data.data));
								this.$store.commit("permission/authNavs", res.data.data);
								this.$store.commit("permission/SETBRANDSIDE");
								localStorage.setItem(
									"repath",
									this.$store.state.permission.Newrouter[0].path
								);
								const Newrouter = this.$store.state.permission.Newrouter;
								this.$store.commit("permission/INIT_AUTH_ROUTES", Newrouter);
								this.$router.push({
									path: localStorage.getItem("repath"),
								});
							}
							let dataTime = new Date().getTime()

							console.log(JSON.parse(localStorage.getItem("DynamicPath")));
						}
					});
				}
			},
			//获取点击的系统的所有的权限
			visibleResourceed(dataTime,id) {
			  const params = {
			    _t: dataTime,
			    id: id
			  }
			  visibleResource(params).then(res => {
			    if(res.data.code == 0) {
			      console.log('dsadfsddfsdfsd');
			      localStorage.setItem('permission',JSON.stringify(res.data.data.resourceList))
			    }
			  })
			},
			
		},
	}
</script>

<style lang="scss" scoped>
	.info-box {
		width: 80%;
		padding: 10px;
		margin: 10px auto;
		// box-shadow: 0 16px 16px rgba(10, 16, 20, 0.24);
		box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
		border-radius: 10px;
	}

	.info-heard {
		padding: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.info-footer {
		padding: 10px;
	}

	.footer-box {
		width: 80%;
		text-align: center;
		padding: 10px;
		margin: 10px auto;
	}
</style>