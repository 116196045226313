<template>
  <!-- 单房退房 -->
  <div class="SingleRoom">
    <p>转账信息</p>
    <el-table :data="PlateFrom" border style="width: 100%">
      <el-table-column prop="id" align="center" label="入住单号">
      </el-table-column>
      <el-table-column
        prop="matchName"
        align="center"
        label="主客姓名"
        width="180"
      >
        <template slot-scope="scope">
          {{ scope.row.matchName ? scope.row.matchName : null }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="已住天数">
        <template slot-scope="scope">
          {{ scope.row.reserveDays }}
        </template>
      </el-table-column>
    </el-table>
    <p>加收提醒</p>
    <el-form ref="form" :model="form" label-width="90px">
      <el-form-item label="加收项目：">
        <!-- <el-select
          style="width: 100%"
          :disabled="true"
          v-model="form.projectNames"
          placeholder="请输入加收项目"
        > -->

        <!-- <el-option label="全日房费" value="0"></el-option>
          <el-option label="钟点房房费" value="1"></el-option> -->
        <!-- <el-option label="日租房费" value="2"></el-option> -->
        <!-- </el-select> -->
        <el-input :disabled="true" v-model="form.projectNames" />
      </el-form-item>
      <el-form-item label="加收金额：">
        <el-input :disabled="true" v-model="form.price" />
      </el-form-item>
      <el-form-item label="是否加收：">
        <el-switch v-model="isvalue" active-color="#13ce66"> </el-switch>
      </el-form-item>
      <el-form-item label="转入房号" label-width="90px" style="position: relative;">
        <el-input style="width: 88%" clearable v-model="form.roomName" />
        <el-button icon="el-icon-search" class="roomButtons"></el-button>
        <p
          style="
            background-color: rgb(197, 248, 200);
            height: 40px;
            padding-top: 10px;
            box-sizing: border-box;
            padding-left: 10px;
          "
        >
          <span
            style="
              text-align: center;
              line-height: 20px;
              display: block;
              border-radius: 50%;
              width: 20px;
              height: 20px;
              background-color: rgb(18, 138, 24);
            "
          >
            <i style="color: #fff; font-size: 13px" class="el-icon-check"> </i>
          </span>
          <span class="sizeFloot">
            已自动填写转入房号</span
          >
        </p>
      </el-form-item>
    </el-form>
    <div class="submitButton">
      <el-button @click="ConfirmedCheckout"> 确定退房 </el-button>
    </div>
  </div>
</template>

<script>
import {
  order_baseUpdateOutList,
  ListFinance,
  AddFinance,
  financeManagementSaveList,
} from "_api/orderFrom";
import { consumptionList,UnitBargainingLook } from "_api/systemSetting";
export default {
  name: "SingleRoom",
  data() {
    return {
      tableData: [
        {
          name: "sadasd",
        },
      ],
      form: {
        price: null,
        projectName: null, //加收项目
        projectNames: null, //加收项目
        roomName: null,
        projectId: null,
      },
      isvalue: true,
      PlateFrom: [], //联房数据
      dataPlate: {}, //联房退房的参数
      tableData: [], //退房的财务信息
      consumptionData: [],
      overtimeFrom: [],
      isDisabled: false,
      reserveDays: null, //已住天数
	  consumption:{},//消费方式
    };
  },
  created() {},
  methods: {
    //点击确定退出触发
    ConfirmedCheckout() {
      if (this.isvalue) {
        this.financeManagementSaveListed(this.PlateFrom[0]);
      } else {
        this.order_baseUpdateOutListed(this.dataPlate);
      }
    },
    computedPrice() {
      this.form.price =
        this.PlateFrom[0].settlementType == 0
          ? parseFloat(this.PlateFrom[0].roomRealityPrice) *
            parseFloat(this.PlateFrom[0].reserveDays)
          : parseFloat(this.PlateFrom[0].hourLength) *
            parseFloat(this.PlateFrom[0].roomRealityPrice);
    },
    //批量新增财务接口
    financeManagementSaveListed(el) {
      if(this.overtimeFrom && this.overtimeFrom.length > 0) {
        const params = [
			...this.overtimeFrom,
		];
		financeManagementSaveList(params).then((res) => {
			if (res.data.code === 0) {
			  this.$message({
				type: "success",
				message: "入账成功",
				onClose: () => {
				  this.order_baseUpdateOutListed(this.dataPlate);
				},
			  });
			}
		});
      }else{
        this.order_baseUpdateOutListed(this.dataPlate);
      }
    },
	

    //财务分页列表查询
    ListFinanceed(model = {}) {
      console.log(this.$store.state.user.orderFrom);
      const params = {
        current: 1,
        size: 100000,
        model: {
          ...model,
          orderId: this.PlateFrom[0].id,
          operator: localStorage.getItem("classesId"),
        },
      };
      ListFinance(params).then((res) => {
        if (res.data.code === 0) {
          var newTime = new Date().getTime();
          var predepartureTime = new Date(this.PlateFrom[0].predepartureTime).getTime();
          this.getNowDates(new Date(), this.PlateFrom[0].arrivalTime);
          this.tableData = res.data.data.records;
          var orderFrom = {
            orderId: this.PlateFrom[0].id,
            arr: [],
            roomPrice: false, //false没有房费
            type: 0, //0全天房/1钟点房
          };

          //判断有没有超时
          this.overtimeFrom.splice(0);
          if (newTime > predepartureTime) {
            //超时了
            this.form.projectNames = "超时房费";
            if (this.PlateFrom[0].settlementType == "0") {
              //全天房
              var days = Math.ceil(
                (parseFloat(newTime) - parseFloat(predepartureTime)) /
                  (1 * 24 * 60 * 60 * 1000)
              );
              this.tableData.forEach((el) => {
                if (el.projectName == "全日房费") {
                  orderFrom.type = 0;
                  orderFrom.roomPrice = true;
                }
              });
              console.log();
              console.log(parseFloat(newTime),'-----',parseFloat(predepartureTime));
                  console.log(days,'----',this.PlateFrom[0].roomRealityPrice);
              var overtimePrice = days * parseFloat(this.PlateFrom[0].roomRealityPrice);
              var overtimePricess =
                parseFloat(this.PlateFrom[0].reserveDays) *
                parseFloat(this.PlateFrom[0].roomRealityPrice);
                this.form.price = parseFloat(overtimePrice) + parseFloat(overtimePricess)
              if (!orderFrom.roomPrice) {
                //没有房费
                this.form.projectNames = '超时房费 - 全天房费'
                var paramss = {
                  price: overtimePricess,
                  orderId: this.PlateFrom[0].id,
                  itemType: "1",
                  state: true,
                  roomId: this.PlateFrom[0].roomId,
                  matchName: this.PlateFrom[0].name,
                  roomName: this.PlateFrom[0].roomName, //房间号
                  userClassesId: localStorage.getItem("userClassesId"),
                  operator: localStorage.getItem("name"),
                  paymentId: "372735506967953484",
                  projectName: "全日房费",
                  entryState: "2",
                  type: "0",
                  classesId: localStorage.getItem("classesId"),
                  createdName: localStorage.getItem("name"),
                  classes: localStorage.getItem("classes"),
                };
                this.consumptionData.forEach((el) => {
                  if (el.name == "全日房费") {
                    paramss.paymentId = el.id;
                  }
                });
                this.overtimeFrom.push(paramss);
              }else{
                this.form.projectNames = '超时房费'
              }
              //财务管理表之新增数据
              var params = {
                price: overtimePrice,
                orderId: this.PlateFrom[0].id,
                itemType: "1",
                state: true,
                roomId: this.PlateFrom[0].roomId,
                roomName: this.PlateFrom[0].roomName, //房型名称
                userClassesId: localStorage.getItem("userClassesId"),
                operator: localStorage.getItem("name"),
                paymentId: "372735506967953484",
                projectName: "超时费",
                entryState: "2",
                type: "0",
                classesId: localStorage.getItem("classesId"),
                createdName: localStorage.getItem("name"),
                classes: localStorage.getItem("classes"),
              };
              this.consumptionData.forEach((el) => {
                if (el.name == "超时费") {
                  params.paymentId = el.id;
                }
              });
              this.overtimeFrom.push(params);
            } else {
              //钟点房
              var days = Math.ceil(
                (parseFloat(newTime) - parseFloat(predepartureTime)) /
                  (60 * 60 * 1000)
              );
              this.tableData.forEach((el) => {
                if (el.projectName == "钟点房费") {
                  orderFrom.type = 1;
                  orderFrom.roomPrice = true;
                }
              });
              var overtimePrice =
                days * parseFloat(this.PlateFrom[0].reserveDays);
              var overtimePricess =
                parseFloat(this.PlateFrom[0].roomRealityPrice);
                this.form.price = parseFloat(overtimePrice) + parseFloat(overtimePricess)
              //财务管理表之新增数据
              if (!orderFrom.roomPrice) {
                //没有房费
                this.form.projectNames = '超时房费 - 钟点房费'
                var paramss = {
                  price: overtimePricess,
                  orderId: this.PlateFrom[0].id,
                  itemType: "1",
                  state: true,
                  roomId: this.PlateFrom[0].roomId,
                  matchName: this.PlateFrom[0].name,
                  roomName: this.PlateFrom[0].roomName, //房间号
                  userClassesId: localStorage.getItem("userClassesId"),
                  operator: localStorage.getItem("name"),
                  paymentId: "372735506967953484",
                  projectName: "钟点房费",
                  entryState: "2",
                  type: "0",
                  classesId: localStorage.getItem("classesId"),
                  createdName: localStorage.getItem("name"),
                  classes: localStorage.getItem("classes"),
                };
                this.consumptionData.forEach((el) => {
                  if (el.name == "钟点房费") {
                    paramss.paymentId = el.id;
                  }
                });
                this.overtimeFrom.push(paramss);
              }else{
                this.form.projectNames = '超时房费'
              }
              var params = {
                price: overtimePrice,
                orderId: this.PlateFrom[0].id,
                itemType: "1",
                state: true,
                roomId: this.PlateFrom[0].roomId,
                roomName: this.PlateFrom[0].roomName, //房型名称
                userClassesId: localStorage.getItem("userClassesId"),
                operator: localStorage.getItem("name"),
                paymentId: "372735506967953484",
                projectName: "超时费",
                entryState: "2",
                type: "0",
                classesId: localStorage.getItem("classesId"),
                createdName: localStorage.getItem("name"),
                classes: localStorage.getItem("classes"),
              };
              this.consumptionData.forEach((el) => {
                if (el.name == "超时费") {
                  params.paymentId = el.id;
                }
              });
              this.overtimeFrom.push(params);
            }
          } else {
            //没有超时
            //没超时房费
            if (this.PlateFrom[0].settlementType == 0) {
              //全天房
              this.tableData.forEach((el) => {
                if (el.projectName == "全日房费") {
                  orderFrom.type = 0;
                  orderFrom.roomPrice = true;
                }
              });
            } else if (this.PlateFrom[0].settlementType == 1) {
              //钟点房
              this.tableData.forEach((el) => {
                if (el.projectName == "全日房费") {
                  orderFrom.type = 1;
                  orderFrom.roomPrice = true;
                }
              });
            }
            //根据type判断是全日还是钟点
              if(!orderFrom.roomPrice) {
                //没有房费
                if (orderFrom.type == 0) {
              //全日房费
              this.form.projectNames = '全日房费'
              this.getNowDates(
                new Date(),
                this.PlateFrom[0].arrivalTime,
                this.PlateFrom[0]
              );
              var overtimePrice =
                parseFloat(this.reserveDays) *
                parseFloat(this.PlateFrom[0].roomRealityPrice);
                this.form.price = parseFloat(overtimePrice)
              var params = {
                price: overtimePrice,
                orderId: this.PlateFrom[0].id,
                itemType: "1",
                state: true,
                roomId: this.PlateFrom[0].roomId,
                matchName: this.PlateFrom[0].name,
                roomName: this.PlateFrom[0].roomName, //房间号
                userClassesId: localStorage.getItem("userClassesId"),
                operator: localStorage.getItem("name"),
                paymentId: "372735506967953484",
                projectName: "全日房费",
                entryState: "2",
                type: "0",
                classesId: localStorage.getItem("classesId"),
                createdName: localStorage.getItem("name"),
                classes: localStorage.getItem("classes"),
              };
              this.consumptionData.forEach((el) => {
                if (el.name == "全日房费") {
                  params.paymentId = el.id;
                }
              });
              this.overtimeFrom.push(params);
            } else {
              //钟点房费
              this.form.projectNames = '钟点房费'
              this.getNowDates(
                new Date(),
                this.PlateFrom[0].arrivalTime,
                this.PlateFrom[0]
              );
              var overtimePrice =
              parseFloat(this.PlateFrom[0].roomRealityPrice);
              this.form.price = parseFloat(overtimePrice)
              var params = {
                price: overtimePrice,
                orderId: this.PlateFrom[0].id,
                itemType: "1",
                state: true,
                roomId: this.PlateFrom[0].roomId,
                matchName: this.PlateFrom[0].name,
                roomName: this.PlateFrom[0].roomName, //房间号
                userClassesId: localStorage.getItem("userClassesId"),
                operator: localStorage.getItem("name"),
                paymentId: "372735506967953484",
                projectName: "钟点房费",
                entryState: "2",
                type: "0",
                classesId: localStorage.getItem("classesId"),
                createdName: localStorage.getItem("name"),
                classes: localStorage.getItem("classes"),
              };
              this.consumptionData.forEach((el) => {
                if (el.name == "钟点房费") {
                  params.paymentId = el.id;
                }
              });
              this.overtimeFrom.push(params);
            }
              }
       
          }
          // if (this.PlateFrom[0].settlementType == "0") {
          //   //全天房
          //   var num = 1;
          //   this.tableData.forEach((el) => {
          //     if (el.projectName == "全日房费") {
          //       num = 2;
          //     }
          //   });
          //   if (num == 2) {
          //     this.isDisabled = true;
          //   } else {
          //     this.form.price = parseFloat(this.reserveDays)*parseFloat(this.PlateFrom[0].roomRealityPrice)
          //     console.log(this.reserveDays,this.PlateFrom[0].roomRealityPrice);
          //     this.consumptionData.forEach((el) => {
          //       if (el.name == "全日房费") {
          //         this.form.projectName = el.name;
          //         this.form.projectId = el.id;
          //       }
          //     });
          //     console.log(this.form);
          //     if(this.overtimeFrom.length != 0) {
          //         this.form.projectNames = '超时房费 - 全天房费'
          //       }else{
          //         this.form.projectNames = '全天房费'
          //       }

          //     if (!this.form.projectName) {
          //       this.$message({
          //         type: "error",
          //         message: "消费项目中没有全日房费",
          //       });

          //       this.computedPrice()
          //     }
          //   }
          // } else {
          //   //终点房
          //   var num = 1;
          //   this.tableData.forEach((el) => {
          //     if (el.projectName == "钟点房房费") {
          //       num = 2;
          //     }
          //   });
          //   if (num == 2) {
          //     this.isDisabled = true;
          //   } else {
          //     this.isDisabled = false;
          //     this.form.price = parseFloat(this.reserveDays)*parseFloat(this.PlateFrom[0].roomRealityPrice)
          //     this.consumptionData.forEach((el) => {
          //       if (el.name == "钟点房房费") {
          //         this.form.projectName = el.name;
          //         this.form.projectId = el.id;
          //       }
          //     });
          //     if(this.overtimeFrom.length != 0) {
          //         this.form.projectNames = '超时房费 - 钟点房房费'
          //       }else{
          //         this.form.projectNames = '钟点房房费'
          //       }
          //     if (!this.form.projectName) {
          //       this.$message({
          //         type: "error",
          //         message: "消费项目中没有钟点房房费",
          //       });

          //     }
          //   }
          // }
        }
      });
      console.log(this.form);
    },
    AddFinanceed(params) {
      AddFinance(params).then((res) => {
        if (res.data.code === 0) {
          this.$message({
            type: "success",
            message: "入账成功",
          });
        }
      });
    },

    //获取真实天数
    getNowDates(date, oldData) {
      var date1 = date.getTime();
      var oldData1 = new Date(oldData).getTime();
      if (this.PlateFrom[0].settlementType == 0) {
        if (
          parseFloat(date1) - parseFloat(oldData1) <=
          1 * 24 * 60 * 60 * 1000
        ) {
          this.reserveDays = 1 + "天";
        } else if (
          parseFloat(date1) - parseFloat(oldData1) >=
          1 * 24 * 60 * 60 * 1000
        ) {
          let num = Math.ceil(
            (parseFloat(date1) - parseFloat(oldData1)) /
              (1 * 24 * 60 * 60 * 1000)
          );
          this.reserveDays = num + "天";
        }
      } else {
        if (parseFloat(date1) - parseFloat(oldData1) <= 60 * 60 * 1000) {
          this.reserveDays = 1;
        } else {
          let num = Math.ceil(
            (parseFloat(date1) - parseFloat(oldData1)) / (60 * 60 * 1000)
          );
          this.reserveDays = num;
        }
      }
    },
    //消费项目的分页列表
    consumptionListed() {
      const params = {
        current: 1,
        size: 100000,
        model: {},
      };
      consumptionList(params).then((res) => {
        if (res.data.code === 0) {
          this.consumptionData = res.data.data.records;
          this.getNowDates(new Date(), this.PlateFrom[0].arrivalTime);
          if (this.PlateFrom[0].settlementType == 0) {
            this.PlateFrom[0].reserveDays = this.reserveDays;
            console.log(this.consumptionData);
            this.$forceUpdate();
          }
        }
      });
    },
    //联房退房的接口
    order_baseUpdateOutListed(arr) {
      console.log(arr);
      const params = {
        connectRoomId: arr.connectRoomId, //连房id
        isMasterRoom: arr.isMasterRoom, //是否为连房的主房（1为是，0为否）
        orderId: arr.orderId, //要财务转入的房单id
        orderIdList: arr.orderIdList, //退房房单id
        outRoomTime: arr.outRoomTime, //退房时间
        settleAccountsClasses: arr.settleAccountsClasses, //结账班次
      };
      order_baseUpdateOutList(params).then((res) => {
        if (res.data.code === 0) {
          this.$message({
            type: "success",
            message: "单房退房成功",
          });
          this.$emit("SingleCHANGE");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.roomButtons{
  position: absolute;
  right: -1%;
}
.SingleRoom {
  margin-top: -30px;
  position: relative;
  p {
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 16px;
  }
  .submitButton {
    position: absolute;
    bottom: -60px;
    width: 100%;
    height: 50px;
    text-align: right;
    .el-button {
      width: 100px;
      height: 35px;
      background-color: #5cb5b8;
      color: #fff;
      border-radius: 4px;
      padding: 0px 20px;
    }
  }
}
.sizeFloot {
  position: relative;
  bottom: 100%;
  left: 6%;
  color: red;
}
</style>