import Vue from 'vue'
import Vuex from 'vuex'
import base from './modules/base'
import user from './modules/user'
import permission from './modules/permission'
import usePermission from './modules/usePermission'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    base,
    user,
    permission,
    usePermission
  }
})
