import request from '_utils/request'
import qs from 'qs'
//房单和订单弹窗的接口
//订单信息之分页列表查询
const orderFromList = (params ={}) => {
 return  request.post('/wineshop/order_base/page', params)
}
//订单信息之查看数据统计
const dataStatistics = (params = {}) => (
    request.get('/wineshop/order_base/view_list', {params})
)
//订单信息之新增接口：办理入住的弹窗
const AdddataStatistics = (params = {}) => (
    request.post('/wineshop/order_base', params)
)
//订单信息之修改订单信息接口
const  EditdataStatistics = (params = {}) => (
    request.put('/wineshop/order_base', params)
)
//协议单位查看接口
const Getbargaining = (params = {}) => (
    request.get('/wineshop/agreement_unit/type_view', {params})
)
//订单操作记录表之分页列表查询
const ListJournal = (params = {}) => (
    request.post('/wineshop/order_operation_record/page', params)
)
//发票管理之分页列表查询
const ListInvoice = (params = {}) => (
    request.post('/wineshop/invoice_management/page', params)
)
//发票管理之新增
const AddInvoice = (params = {}) => (
    request.post('/wineshop/invoice_management', params)
)
//发票管理之修改
const UpdateInvoice = (params = {}) => (
    request.put('/wineshop/invoice_management', params)
)
//财务管理表之新增接口
const AddFinance = (params = {}) => (
    request.post('/wineshop/finance_management', params)
)
//财务管理表之修改接口
const UpdateFinance = (params = {}) => (
    request.put('/wineshop/finance_management', params)
)
//财务管理表之分页列表接口
const ListFinance = (params = {}) => (
    request.post('/wineshop/finance_management/page', params)
)
//付款方式之分页列表接口
const ListPaymentMethod = (params = {}) => (
    request.post('/wineshop/payment_method/page', params)
)
//协议单位之分页列表接口
const ListBargainingUnit = (params = {}) => (
    request.post('/wineshop/agreement_unit/page', params)
)
//根据手机号带出用户信息
const userInfoGet = (params = {}) => (
    request.get('/wineshop/memberInformation/query/phone', {params})
)
//用户班次表之获取该部门当班信息
const ClassesGet = (params = {}) => (
    request.get('/wineshop/userClasses/getUserClasses', {params})
)
//客户信息、批量更新
const BatchUpdate = (params = []) => (
    request.post('/wineshop/customer_information/update_list', params)
)
//预定订单信息表/查看预订单详情
const BookingDetails = () => (
    request.post('/wineshop/orderReserve/orderReserveDetail', 
    qs.stringify(params)
    , {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )
)
//付款查看
const paymentList = () => (
    request.get('/wineshop/payment_method/type_view', {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
)
//协议单位查看
const bargainingUnitList = () => (
    request.get('/wineshop/agreement_unit/type_view', {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
)
//查询单体详情
const personalFinance = (params = {}) => (
    request.get('/wineshop/finance_management/detail', {
        params: qs.stringify(params),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
)
//联房财务查看
const financeManagementConnectIist = (params = {}) => (
    request.post('/wineshop/finance_management/connect_list?connectRoomId=' + params.connectRoomId + '&type=' +params.type, )
)
//联房退房
const order_baseUpdateOutList = (params = {}) => (
    request.post('/wineshop/order_base/update_out_list', params)
)
//财务批量新增接口
const financeManagementSaveList =(params = []) => (
    request.post('/wineshop/finance_management/save_list', params)
)
export{
    orderFromList,
    dataStatistics,
    AdddataStatistics,
    AddFinance,
    UpdateFinance,
    Getbargaining,
    ListJournal,
    ListInvoice,
    AddInvoice,
    ListFinance,
    ListPaymentMethod,
    ListBargainingUnit,
    userInfoGet,
    ClassesGet,
    EditdataStatistics,
    BatchUpdate,
    BookingDetails,
    paymentList,
    bargainingUnitList,
    personalFinance,
    UpdateInvoice,
    financeManagementConnectIist,
    order_baseUpdateOutList,
    financeManagementSaveList
}