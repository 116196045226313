import { render, staticRenderFns } from "./DeductRoom.vue?vue&type=template&id=b9336bcc&scoped=true"
import script from "./DeductRoom.vue?vue&type=script&lang=js"
export * from "./DeductRoom.vue?vue&type=script&lang=js"
import style0 from "./DeductRoom.vue?vue&type=style&index=0&id=b9336bcc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b9336bcc",
  null
  
)

export default component.exports