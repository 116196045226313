<template>
  <!-- 备用金抽屉 -->
  <div class="ReserveBox">
    <el-table :data="tableData" height="300px" border style="width: 100%">
      <!-- <el-table-column
        prop="id"
        label="序号"
        align="center"
        width="50">
      </el-table-column> -->
      <el-table-column
        prop="currentMoney"
        align="center"
        label="当前备用金"
        width="140"
      >
      </el-table-column>
      <el-table-column prop="adjustPrice" align="center" label="调整金额">
      </el-table-column>
      <el-table-column prop="remark" align="center" label="备注" width="130">
      </el-table-column>
      <el-table-column prop="operator" align="center" label="操作人">
      </el-table-column>
      <el-table-column
        prop="createdTime"
        align="center"
        label="操作时间"
        width="180"
      >
      </el-table-column>
    </el-table>
    <p>
      <span> 备用金：</span> {{ form.currentMoney ? form.currentMoney : 0 }}元
    </p>
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label-width="90px" label="调整金额：">
        <el-input style="width: 120px" v-model="form.adjustPrice"></el-input>
      </el-form-item>
      <el-form-item label-width="90px" label="备注说明：">
        <el-input
          v-model="form.remark"
          placeholder="选填，备注说明调整原因"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="SubnitPrice">确定调整</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  classesStandbyMoneyPage,
  classesStandbyMoneyAdd,
} from "_api/conditionOfHouse";
export default {
  name: "ReserveBox",
  data() {
    return {
      tableData: [],
      current: 1,
      size: 10,
      total: 0,
      form: {
        adjustPrice: null,
        currentMoney: null,
        remark: null,
      },
      arrivalTimes: null,
    };
  },
  created() {},
  methods: {
    classesStandbyMoneyAdded() {
      this.getNowDate();
      let params = {
        adjustPrice: parseFloat(this.form.adjustPrice),
        createdBy: localStorage.getItem("userClassesId"),
        createdTime: this.arrivalTimes,
        currentMoney: this.form.adjustPrice,
        operator: localStorage.getItem("name"),
        orgId: localStorage.getItem("orgid"),
        remark: this.form.remark,
        state: true,
      };
      classesStandbyMoneyAdd(params).then((res) => {
        this.$message({
          type: "success",
          message: "备用金调整成功",
        });
        this.classesStandbyMoneyPageed()
        this.$emit('reserveBOX')
      });
    },
    //备用金数据列表接口
    classesStandbyMoneyPageed(currentMoney) {
      if(currentMoney) {
        this.form.currentMoney = currentMoney;
      }
      let params = {
        current: this.current,
        size: this.size,
        model: {
          operator: localStorage.getItem("name"),
        },
      };
      classesStandbyMoneyPage(params).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.data.records;
        }
      });
    },
    //点击确定触发
    SubnitPrice() {
      this.classesStandbyMoneyAdded();
    },
    //获取当前时间
    getNowDate() {
      var date = new Date();

      var sign2 = ":";
      var year = date.getFullYear(); // 年
      var month = date.getMonth() + 1; // 月
      var day = date.getDate(); // 日
      var hour = date.getHours(); // 时
      var minutes = date.getMinutes(); // 分
      var seconds = date.getSeconds(); //秒
      // 给一位数的数据前面加 “0”
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (day >= 0 && day <= 9) {
        day = "0" + day;
      }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }

      this.arrivalTimes =
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        sign2 +
        minutes +
        sign2 +
        seconds;
    },
  },
};
</script>

<style lang="scss" scoped>
.ReserveBox {
  height: 500px;
  p {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 18px;
    color: red;
    font-weight: bold;
    span {
      font-size: 18px;
      color: black;
    }
  }
  .el-form {
    .el-form-item {
      font-size: 18px;
    }
    .el-form-item:last-child {
      text-align: right;
      .el-button {
        width: 110px;
        height: 40px;
        border-radius: 4px;
        background-color: #5cb5b8;
        color: #fff;
      }
    }
  }
}
</style>